import { connect } from 'react-redux';
import { bindActionCreators, Dispatch, Action } from 'redux';

import { StateType } from 'types';
import { selectTitles } from 'redux/selectors/settingsOverview';
import { deleteTitles, getTitles, setDataEntryPointDetail } from 'redux/actions/settingsOverview';
import { setDrawerBody, setIsDrawerOpen } from 'redux/actions/workspace';

const mapStateToProps = (state: StateType) => ({
  titles: selectTitles(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  actions: bindActionCreators({
    getTitles,
    deleteTitles,
    setDrawerBody,
    setIsDrawerOpen,
    setDataEntryPointDetail,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps);
