import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import {
  isSuperAdmin,
  DRAWERS,
  getRowKey,
  useTableHook,
  getOffset,
  identity,
} from 'utils';
import { CohortOverviewProps } from 'types/cohorts/cohortOverview';
import { composeCohortsDetailsUrl } from 'routes/routeComposers';
import { Table } from '../components';
import { cohortOverviewColumns } from '../utils/constants/columns';

const CohortOverview = (props: CohortOverviewProps) => {
  const {
    cohorts,
    cohortsCount,
    isLoading,
    userType,
  } = props;
  const {
    getCohorts,
    setDrawerBody,
    setIsDrawerOpen,
    getCohortsCount,
  } = props.actions;
  const history = useHistory();

  const { state, setCurrentPage, setLimit } = useTableHook();
  const { limit, currentPage } = state;
  const [searchString, setSearchString] = useState<string>('');

  useEffect(() => {
    getCohortsCount({ searchString });
  }, [searchString]);

  useEffect(() => {
    const query = {
      isPreload: true,
      limit,
      offset: getOffset(limit, currentPage),
      searchString,
    };
    getCohorts({ query });
  }, [limit, currentPage, searchString]);

  const onAddButtonClick = () => {
    setDrawerBody({ drawerBody: DRAWERS.addCohort });
    setIsDrawerOpen({ isDrawerOpen: true });
  }

  return (
    <div className="content-container">
      <Table
        isLoading={isLoading}
        onRow={(record) => ({ onClick: () => history.push(composeCohortsDetailsUrl(record.id)) })}
        showSearchBar
        showSearchField
        leftContent={isSuperAdmin(userType) ? 'add-button' : undefined}
        addButtonText="cohort"
        placeholder="cohorts"
        dataSource={props.isRowTableLayout ? cohorts : []}
        columns={cohortOverviewColumns}
        rowKey={getRowKey(cohorts, 'cohort', 'id')}
        onAddButtonClick={onAddButtonClick}
        currentPage={currentPage}
        limit={limit}
        setLimit={setLimit}
        setCurrentPage={setCurrentPage}
        showCustomPagination
        total={cohortsCount}
        filtersActive={identity.isTruthyString(searchString)}
        setSearchString={setSearchString}
      />
    </div>
  );
}

export default CohortOverview;
