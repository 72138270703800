import React, { useEffect, useMemo } from 'react';

import { RequestOverviewTabsProps } from 'types/requests';
import TabButton from './components/TabButton';
import { requestOverviewTabs } from '../../utils';

import './RequestOverviewTabs.scss';

const RequestOverviewTabs = (props: RequestOverviewTabsProps) => {
  const { userType, unsentCount, filters } = props;

  useEffect(() => {
    props.actions.getRequestsCount();
  }, [filters]);

  const tabs = useMemo(() => requestOverviewTabs(userType, unsentCount), [userType, unsentCount]);

  return (
    <div className="request-overview-tabs">
      {tabs.map((tab) => (
        <TabButton key={tab} unsentCount={unsentCount} value={tab} />
      ))}
    </div>
  );
}

export default RequestOverviewTabs;
