import { connect } from 'react-redux';
import { bindActionCreators, Dispatch, Action } from 'redux';

import { StateType } from 'types';
import { selectSectorOptions, selectStageOptions, selectStartupDetails } from 'redux/selectors/startups';
import {
  getStartupById,
  getStartupSectors,
  getStartupStages,
  updateStartupById,
} from 'redux/actions/startups';
import { selectUserStartupId, selectUserType } from 'redux/selectors/auth';
import { setDrawerBody, setIsDrawerOpen } from 'redux/actions/workspace';

const mapStateToProps = (state: StateType) => ({
  selectedStartup: selectStartupDetails(state),
  sectorOptions: selectSectorOptions(state),
  stageOptions: selectStageOptions(state),
  userStartupId: selectUserStartupId(state),
  userType: selectUserType(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  actions: bindActionCreators({
    getStartupById,
    updateStartupById,
    getStartupSectors,
    getStartupStages,
    setDrawerBody,
    setIsDrawerOpen,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps);
