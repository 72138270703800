import React, { useMemo, useState } from 'react'
import { Button, Col, Row } from 'antd';

import RecipientForm from './components/RecipientForm';
import Title from '../TitleWithActions/components/Title';
import { ActivationLinkRecipientProps, Recipient } from '../../../types/componentTypes/ActivationLinkRecipientProps';

import './ActivationLinkRecipient.scss'

const ActivationLinkRecipient = (props: ActivationLinkRecipientProps) => {
  const {
    recipients,
    onDeleteRecipient,
    onAddRecipient,
    onCheckedHandle,
    onChangeHandle,
    hasCheckbox,
    roleAsText,
  } = props;
  const hasOneRecipient = useMemo(() => recipients.length === 1, [recipients]);
  const [checkedValue, setCheckedValue] = useState<boolean>(false);

  const onChecked = (value: boolean) => {
    setCheckedValue(value);
    onCheckedHandle!(value);
  };

  const initialValues: Recipient = {
    id: recipients.length,
    name: '',
    firstName: '',
    lastName: '',
    role: '',
    email: '',
    phoneNumber: '',
  }

  const AddRecipient = () => {
    onAddRecipient(initialValues)
  }

  return (
    <Row>
      <Col span={24}>
        <Title title="Send Activation link to" />
        { recipients.map((recipient, index) => (
          <RecipientForm
            checkedValue={checkedValue}
            onChangeHandle={onChangeHandle!}
            hasCheckbox={hasCheckbox}
            roleAsText={roleAsText}
            hasDeleteIcon={!hasOneRecipient}
            key={recipient.id.toString()}
            id={index}
            recipient={recipient}
            onDeleteRecipient={onDeleteRecipient}
            onCheckedHandle={onChecked!}
          />
        ))}
        <Button onClick={AddRecipient} className="add_recipient" type="text">
          + ADD RECIPIENT
        </Button>
      </Col>
    </Row>
  );
}

ActivationLinkRecipient.defaultProps = {
  hasCheckbox: false,
  roleAsText: false,
  onChangeHandle: () => {},
};

export default ActivationLinkRecipient;
