import { Avatar } from 'antd';
import React from 'react'

import { IRequestedBy, RequestedByProps } from '../../../../../../types/requestDetailsModal';

import './RequestedBy.scss';

const RequestedBy = (props: RequestedByProps) => {
  const { personDetails, startup: { imageLink: startupImageLink, name: startupName } } = props as IRequestedBy;
  const {
    email, imageLink, name, role, phone,
  } = personDetails;

  return (
    <div className="incentive-requested-by">
      <p className="incentive-requested-by__title">Requested by</p>
      <div className="incentive-requested-by__container">
        <div className="requested-by__info-container">
          <Avatar className="requested-by__person-image" src={imageLink} alt={name} />
          <div className="requested-by__info">
            <h3 className="requested-by__name">{name}</h3>
            <div className="requested-by__company-container">
              <div>
                <Avatar size="small" src={startupImageLink} />
                <span className="incentive-requested-by__company-name">{startupName}</span>
              </div>
              <div className="dot" />
              <span className="incentive-requested-by__role">{role}</span>
            </div>
          </div>
        </div>
        <div className="requested-by__contact-container">
          <span className="incentive-requested-by__email">{email}</span>
          <span className="incentive-requested-by__phone">{phone}</span>
        </div>
      </div>
    </div>
  )
}

export default RequestedBy;
