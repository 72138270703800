import React from 'react';

import { TitleProps } from '../../../../../types/componentTypes/TitleProps';
import Text from '../../../Text';

const Title = (props: TitleProps) => {
  const { title, size } = props;
  return (
    <div className="title-container">
      <Text text={title} size={size} />
    </div>
  );
};

Title.defaultProps = {
  size: 's',
  titleWeight: undefined,
};

export default Title;
