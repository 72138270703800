import { Moment } from 'moment'
import { RangePickerProps } from 'antd/lib/date-picker'
import dayjs from 'dayjs';

import { ordinal } from './numberFormatters';

const DAYS_IN_WEEK = 7;

// etc. (2022-03-22) => 3 -> 22 is 3rd Tuesday of a month
const getDateWeekdayOfMonth = (date: Moment) => Math.floor((date.date() - 1) / DAYS_IN_WEEK) + 1;

export const disabledDateFuture: RangePickerProps['disabledDate'] = (current) => current && current > dayjs().endOf('day');

export const disabledDatePast: RangePickerProps['disabledDate'] = (current) => current && current < dayjs().endOf('day');

// etc. (2022-03-22) => 3rd Tuesday of a month
const formatDateWeekdayOfMonth = (date: Moment, suffix?: boolean) => {
  const count = getDateWeekdayOfMonth(date);
  const dayText = count > 4 ? 'Last' : ordinal(count);

  return `${dayText} ${date.format('dddd')}${suffix ? ' of a month' : ''}`;
}

const formatDateEveryNth = (date: Moment) => `Every ${ordinal(date.date())}`;

const removeTimeFromDate = (date: Moment) => {
  date?.set('hour', 0);
  date?.set('minute', 0);
  date?.set('second', 0);
  date?.set('millisecond', 0);

  return date;
}

const addTimeToDate = (date: Moment, time: Moment) => {
  date.set('hours', time.hours());
  date.set('minutes', time.minutes());

  return date;
}

const getDurationBetweenDatesInMonths = (startDate: Moment, endDate: Moment) => (
  Math.abs(endDate.diff(startDate, 'months'))
);

export {
  getDateWeekdayOfMonth,
  getDurationBetweenDatesInMonths,
  addTimeToDate,
  formatDateEveryNth,
  formatDateWeekdayOfMonth,
  removeTimeFromDate,
}
