import { connect } from 'react-redux';

import { StateType } from 'types';
import { selectUserId, selectUserType } from 'redux/selectors/auth';

const mapStateToProps = (state: StateType) => ({
  userId: selectUserId(state),
  userType: selectUserType(state),
});

export default connect(mapStateToProps);
