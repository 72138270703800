import React from 'react';
import { Col, Row } from 'antd';
import moment from 'moment';

import Overview from '../../../../../components/Overview';
import { NOT_SPECIFIED } from '../../../../utils/constants';
import { ProgramOverviewProps } from '../../../../../../types/programDetails';
import { formatSlashMDY } from '../../../../../utils/constants/formatters';
import { getDurationBetweenDatesInMonths, getLabelBasedOnValueCount } from '../../../../../../utils';

import './ProgramOverview.scss';

const { DataColumn: OverviewDataColumn } = Overview;

const ProgramOverview = (props: ProgramOverviewProps) => {
  const { cohortCount, program } = props;
  const startDate = moment(program.from);
  const endDate = moment(program.to);

  return (
    <div className="program-overview">
      <Overview title="Overview">
        <div className="overview__card-data">
          <OverviewDataColumn label="Type" text={program.type || NOT_SPECIFIED} />
          <OverviewDataColumn label="Number of cohorts" text={cohortCount.toString() || NOT_SPECIFIED} />
        </div>
        <div className="overview__card-data">
          <Col sm={24} md={12}>
            <h4 className="overview__subtitle">Period</h4>
            <Row>
              <OverviewDataColumn label="From date" text={startDate.format(formatSlashMDY) || NOT_SPECIFIED} />
              <OverviewDataColumn label="To date" text={endDate.format(formatSlashMDY) || NOT_SPECIFIED} />
            </Row>
          </Col>
          <Col sm={24} md={12}>
            <h4 className="overview__subtitle">Incentives</h4>
            <Row>
              <OverviewDataColumn label="Amount" text={program.amount ? `AED ${program.amount}` : NOT_SPECIFIED} />
              <OverviewDataColumn
                label="Duration"
                text={getLabelBasedOnValueCount('Month', getDurationBetweenDatesInMonths(startDate, endDate))}
              />
            </Row>
          </Col>
        </div>
      </Overview>
    </div>
  );
};

ProgramOverview.defaultProps = {
  cohortCount: 0,
};

export default ProgramOverview;
