import StartupsDropdownFilters from 'components/StartupsOverview/components/StartupsDropdownFilters';
import RequestFiltersDropdown from 'components/RequestsOverview/components/RequestFiltersDropdown';
import ServicesDropdownFilters from 'components/ServicesAndServiceProviders/components/ServicesDropdownFilters';
import PeopleDropdownFilters from 'components/PeopleOverview/components/PeopleDropdownFilters';

export const items = [
  {
    label: 'Incentive',
    value: 'Incentive',
  },
];

export const allCategories = [
  {
    label: 'User interface',
    value: 'User interface',
  },
  {
    label: 'Strategy',
    value: 'Strategy',
  },
  {
    label: 'Design',
    value: 'Design',
  },
];

export const FILTERS_MAP: { [key: string]: 'people' | 'requests' | 'services' | 'startups' | 'service-providers' } = {
  requests: 'requests',
  services: 'services',
  serviceProviders: 'service-providers',
  startups: 'startups',
  people: 'people',
}

export const filtersDropdown = {
  [FILTERS_MAP.requests]: {
    body: RequestFiltersDropdown,
  },
  [FILTERS_MAP.services]: {
    body: ServicesDropdownFilters,
  },
  [FILTERS_MAP.startups]: {
    body: StartupsDropdownFilters,
  },
  [FILTERS_MAP.people]: {
    body: PeopleDropdownFilters,
  },
};
