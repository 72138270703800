import React from 'react';
import { RiDownloadLine } from 'react-icons/ri';

import { ButtonType } from 'types/componentTypes';
import { downloadFile } from 'components/components/TitleWithActions/utils';

export const downloadButton = (href: string, contentType: string): ButtonType[] => ([
  {
    key: 'download-button-acceptance-letter',
    icon: <RiDownloadLine />,
    onClick: () => downloadFile(href, contentType),
    download: true,
    href,
  },
]);

export default null;
