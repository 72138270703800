/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react'
import classNames from 'classnames';

import { StepItemProps } from 'types/componentTypes/StepsForm';
import { CheckmarkIcon } from 'assets/icons';

import './StepItem.scss';

const StepItem = (props: StepItemProps) => {
  const {
    number, selected, onClick, text, checked,
  } = props;

  return (
    <div
      className={classNames('step-item', selected ? 'step-item--selected' : '')}
      onClick={() => onClick()}
    >
      <div className="step-item__number-circle">
        {checked
          ? <div className="step-item__icon-wrap"><CheckmarkIcon /></div>
          : <span className="step-item__number">{number}</span>}
      </div>
      <p className="step-item__text">{text}</p>
    </div>
  )
}

export default StepItem
