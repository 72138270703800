import { connect } from 'react-redux';
import { bindActionCreators, Dispatch, Action } from 'redux';

import { StateType } from 'types';
import { selectEducation } from 'redux/selectors/settingsOverview';
import { deleteEducation, getEducation, setDataEntryPointDetail } from 'redux/actions/settingsOverview';
import { setDrawerBody, setIsDrawerOpen } from 'redux/actions/workspace';

const mapStateToProps = (state: StateType) => ({
  education: selectEducation(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  actions: bindActionCreators({
    getEducation,
    deleteEducation,
    setDrawerBody,
    setIsDrawerOpen,
    setDataEntryPointDetail,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps);
