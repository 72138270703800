import React, { useState } from 'react';

import { greaterThanZeroOnly, requiredValidation } from 'utils';
import Row from '../../../../components/FormBuilder/components/Row';
import { amountMaxMessage, amountMinMessage } from '../../../utils/constants';
import PriceInput from '../PriceInput';

const RangePriceInput = () => {
  const [amountMax, setAmountMax] = useState('');
  const [amountMin, setAmountMin] = useState('');

  const validatePriceRange = async () => {
    if (parseInt(amountMin, 10) >= parseInt(amountMax, 10)) {
      throw new Error();
    }
  };

  return (
    <Row>
      <PriceInput
        label="Amount From"
        name="amountFrom"
        rules={[requiredValidation, greaterThanZeroOnly, { validator: validatePriceRange, message: amountMinMessage }]}
        onChange={(e) => setAmountMin(e.target.value)}
        value={amountMin}
      />
      <PriceInput
        label="Amount To"
        name="amountTo"
        rules={[requiredValidation, greaterThanZeroOnly, { validator: validatePriceRange, message: amountMaxMessage }]}
        onChange={(e) => setAmountMax(e.target.value)}
        value={amountMax}
      />
    </Row>
  );
};

export default RangePriceInput;
