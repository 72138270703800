import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { DashboardCardsProps } from 'types/componentTypes/Dashboard';
import {
  PeopleIcon,
  RequestIcon,
  StartupIconV2,
  EventIcon,
} from 'assets/icons';
import { RenderIf } from 'components/components';
import REQUEST_STATUS from 'utils/constants/requestStatusTypes';
import { isStartup, isProduction, isEitherAdmin } from 'utils';
import routes from 'routes';
import { CamelCaseStatusType } from 'types/requests';
import DashboardCard from './DashboardCard';

import './DashboardCards.scss';

const DashboardCards = (props: DashboardCardsProps) => {
  const { count, userType } = props;
  const { getDashboardCount, setCurrentTab } = props.actions;

  const history = useHistory();

  useEffect(() => {
    getDashboardCount();
  }, []);

  const onRequestsButtonClick = () => {
    let activeTabKey: CamelCaseStatusType = 'new';
    if (isEitherAdmin(userType) || isStartup(userType)) {
      activeTabKey = isEitherAdmin(userType) ? 'pendingHUB71' : 'quoted';
    }
    setCurrentTab({ currentTab: REQUEST_STATUS[activeTabKey] });
    history.push(routes.requestsUrl);
  }

  return (
    <div className="dashboard-cards__container">
      <DashboardCard
        icon={<RequestIcon />}
        title="Requests waiting for your approval"
        count={count.requests}
        buttonText="View requests"
        onClick={onRequestsButtonClick}
        color="blue"
      />
      <RenderIf condition={!isProduction}>
        <DashboardCard
          icon={<EventIcon />}
          title="Upcoming events"
          count={count.events}
          buttonText="View events"
          onClick={() => history.push(routes.eventsUrl)}
          color="green"
        />
      </RenderIf>
      <DashboardCard
        icon={isStartup(userType) ? <PeopleIcon /> : <StartupIconV2 />}
        title={isStartup(userType) ? 'Team Members' : 'Startups'}
        count={isStartup(userType) ? count.members : count.startups}
        buttonText={`View ${isStartup(userType) ? 'team members' : 'startups'}`}
        onClick={isStartup(userType)
          ? () => history.push(routes.teamMembersUrl)
          : () => history.push(routes.startupsUrl)}
        color="purple"
      />
    </div>
  );
}

export default DashboardCards;
