import { ActivitiesStateType } from '../../types/reduxTypes/StateTypes';

const activitiesDefaultState: ActivitiesStateType = {
  activities: [],
  page: 0,
  size: 3,
  isLoading: false,
  addPeopleOptions: [],
  checkedPeople: [],
  filters: {
    types: [],
    people: [],
    sortBy: null,
  },
}

export default activitiesDefaultState;
