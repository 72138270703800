import React, { useEffect } from 'react';

import { identity } from 'utils';
import routes from 'routes';
import { Details } from 'components/MemberDetails/components';
import { StartupUserProps } from 'types/user';

const StartupUser = (props: StartupUserProps) => {
  const { user } = props;

  useEffect(() => {
    if (!identity.isPositiveNumber(user.id)) {
      props.actions.getMyProfileData();
    }
  }, [user]);

  if (!identity.isPositiveNumber(user.id)) {
    return null;
  }

  return (
    <div className="my-profile">
      <Details user={user} link={routes.editMyProfileUrl} />
    </div>
  );
}

export default StartupUser;
