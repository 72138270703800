import { connect } from 'react-redux';
import { bindActionCreators, Dispatch, Action } from 'redux';

import { StateType } from 'types';
import { selectCohorts } from 'redux/selectors/cohort';
import {
  selectSectorOptions,
  selectStageOptions,
  selectStartupFilters,
} from 'redux/selectors/startups'
import { getCohorts } from 'redux/actions/cohort';
import {
  getStartupInvestmentStages,
  getStartupSectors,
  getStartupStages,
  setStartupFilters,
} from 'redux/actions/startups';

const mapStateToProps = (state: StateType) => ({
  cohorts: selectCohorts(state),
  industriesOptions: selectSectorOptions(state),
  stagesOptions: selectStageOptions(state),
  filters: selectStartupFilters(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  actions: bindActionCreators({
    getCohorts,
    getStartupStages,
    getStartupSectors,
    getStartupInvestmentStages,
    setStartupFilters,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps);
