import React from 'react'
import { useMediaQuery } from 'react-responsive';

import { identity } from 'utils';
import { FormRowsType } from 'types/componentTypes/FormBuilder';
import FormInput from '../FormInput';
import Input from '../../../Input';
import Row from '../Row';
import RenderIf from '../../../RenderIf';

const FormRows = (props: FormRowsType) => {
  const {
    fields, oneColumn, form, layout,
  } = props;

  const shouldTitlesWrap = useMediaQuery({ query: '(max-width: 1000px)' });

  return (
    <>
      {fields.map((row) => (
        <React.Fragment key={row.fields[0].name}>
          <RenderIf condition={identity.isTruthyString(row.heading)}>
            <Input.Heading title={row.heading as string} />
          </RenderIf>
          <RenderIf condition={!shouldTitlesWrap && identity.isFullArray(row.fieldTitles)}>
            <div className="form-builder__titles">
              {row.fieldTitles?.map((title) => <p className="form-builder__field-title" key={title as React.Key}>{title}</p>)}
            </div>
          </RenderIf>
          <Row oneColumn={oneColumn}>
            {row.fields.map((field, idx) => (
              <React.Fragment key={field.name as React.Key}>
                <RenderIf condition={shouldTitlesWrap}>
                  <p className="form-builder__field-title">{(identity.isFullArray(row.fieldTitles) ? row.fieldTitles! : [])[idx]}</p>
                </RenderIf>
                <FormInput
                  placeholder={field.placeholder}
                  rules={field.rules}
                  type={field.type}
                  name={field.name}
                  options={field.options}
                  label={field.label}
                  rows={field.rows}
                  form={form}
                  accept={field.accept}
                  previewType={field.previewType}
                  layout={layout}
                  values={field.values}
                  disabled={field.disabled}
                  defaultFileList={field.defaultFileList}
                  beforeUpload={field.beforeUpload}
                  onChange={field.onChange}
                  required={field.required}
                  componentProps={field.componentProps}
                />
              </React.Fragment>
            ))}
          </Row>
        </React.Fragment>
      ))}
    </>
  )
}

FormRows.defaultProps = {
  layout: 'vertical',
}

export default FormRows;
