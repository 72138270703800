import { identity } from 'utils';
import { queryBuilder } from 'packages/http_client';
import {
  CreateServiceProviderApiRequestPayload,
  CreateServiceProviderPayload,
  EditServiceProviderApiRequestPayload,
  EditServiceProviderPayload,
  GetServiceProvidersListApiResponseType,
  ResponseServiceProviderType,
  ServiceProviderType,
  ParamsType,
} from '../../types';
import { convertServiceCategoriesToString } from './service_converter';

const getContactPersonName = (name: string, type: 'first' | 'last'): string => {
  if (identity.isEmptyString(name)) {
    return '';
  }

  const names = name?.trim().split(' ');
  if (names?.length > 1) {
    return type === 'last' ? names.pop()! : names.slice(0, -1).join(' ');
  }

  return type === 'first' ? name : '';
}

export const convertCreateServiceProviderRequestPayload = (payload: CreateServiceProviderPayload):
CreateServiceProviderApiRequestPayload => ({
  _contact_person: `${payload.firstName} ${payload.lastName}`,
  _contact_person_role: payload.role,
  _description: payload.about,
  _email: payload.email,
  _logo: payload.logo,
  _mobile_number: payload.phoneNumber,
  _name: payload.name,
  _price_list_file: payload.serviceProviderDoc,
  _same_as_contact_person: payload.recipientSameAsContactPerson,
  _type_id: 1, // Fixing this to 1 as only 1 type of service provider will be enabled for now
  _website: payload.websiteLink,
  _agreement_document: payload.agreementDocument,
});

export const convertGetServiceProviderResponse = (serviceProvider?: ResponseServiceProviderType)
: ServiceProviderType => ({
  id: serviceProvider?.id!,
  name: serviceProvider?.name!,
  firstName: getContactPersonName(serviceProvider?.contactPerson!, 'first'),
  lastName: getContactPersonName(serviceProvider?.contactPerson!, 'last'),
  imageLink: serviceProvider?.logo!,
  websiteLink: serviceProvider?.website!,
  role: serviceProvider?.contactPersonRole!,
  phoneNumber: serviceProvider?.mobileNumber!,
  email: serviceProvider?.email!,
  category: convertServiceCategoriesToString(serviceProvider?.categories),
  about: serviceProvider?.description!,
  companyDeck: serviceProvider?.priceListFile!,
  type: serviceProvider?.type?.name! || 'Incentive',
  // TODO: Leaving blank string for now as not important and not yet supported on BE
  howToObtain: '',
  perksDescription: '',
  perksWebLink: '',
  categories: serviceProvider?.categories!,
  agreementDocument: serviceProvider?.agreementDocument,
})

export const convertGetServiceProvidersListResponse = (response: GetServiceProvidersListApiResponseType | undefined)
: ServiceProviderType[] => {
  const data: ServiceProviderType[] = [];
  if (identity.isFullArray(response?.result)) {
    response?.result!.forEach((serviceProvider: ResponseServiceProviderType) => {
      data.push(convertGetServiceProviderResponse(serviceProvider));
    });
  }

  return data;
}

export const convertEditServiceProviderPayload = (
  payload: EditServiceProviderPayload,
): EditServiceProviderApiRequestPayload => ({
  _contact_person: `${payload.firstName} ${payload.lastName}`,
  _contact_person_role: payload.role,
  _description: payload.about,
  _email: payload.email,
  _website: payload.websiteLink,
  _logo: payload.logo,
  _mobile_number: payload.phoneNumber,
});

export const serviceProviderFormatQuery = (query: ParamsType) => {
  const queryRelatedTable = [];
  const joinTables = [];
  const params = queryBuilder({ });
  if (identity.isTruthyNumber(query?.startupId)) {
    queryRelatedTable.push({ tableName: 'service_requests', field: 'startup_id', value: query?.startupId!.toString() });
    joinTables.push({ tableName: 'service_requests', onId: 'id__service_provider_id' });
  }
  params.preload();
  if (identity.isTruthyNumber(query?.startupId)) {
    params.distinct();
  }

  params
    .join(joinTables)
    .queryRelatedTable(queryRelatedTable);
  return params.toObject();
}
