import { connect } from 'react-redux';
import { bindActionCreators, Dispatch, Action } from 'redux';

import { StateType } from 'types';
import {
  updateTemplateById,
} from 'redux/actions/settingsOverview';
import {
  selectActiveSubTab,
  selectTemplateDetail,
} from 'redux/selectors/settingsOverview';

const mapStateToProps = (state: StateType) => ({
  templateDetail: selectTemplateDetail(state),
  activeSubTab: selectActiveSubTab(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  actions: bindActionCreators({
    updateTemplateById,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps);
