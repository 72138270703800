export const GENDERS = [
  { value: 0, label: 'Not specified' },
  { value: 1, label: 'Male' },
  { value: 2, label: 'Female' },
];

export const STARTUP_CAPACITY = [
  { value: 0, label: 'Employee' },
  { value: 1, label: 'Founder' },
];

export const PARTNER_TYPES = [
  { value: 'Corporate', label: 'Corporate' },
  { value: 'Government', label: 'Government' },
  { value: 'VC', label: 'VC' },
];
