/* eslint-disable @typescript-eslint/no-explicit-any, react/jsx-props-no-spreading  */
/*
  NOTE: linter is disabled for this file because we need to pass all props to Antd Dragger. So instead of writing each
  prop line by line, we spread the props inside of Antd Dragger component. It will not be possible for this component
  to receive any not allowed props, since the component itself accepts the same props as AntdDragger without children.
*/
import React, { useMemo, useEffect } from 'react';
import { message, Upload } from 'antd';

import { DraggerUploadProps } from 'types/componentTypes/Input';
import { getImageLink, identity } from 'utils';
import { CsvDocumentIcon, UploadIcon } from 'assets/icons';
import Preview from './components/Preview';
import checkFileType from '../../utils/checkFileType';
import { getFileType } from './components/FilePreviewModal/utils/helpers';
import useDraggerUploadHook from './utils/useDraggerUploadHook';

import '../../Input.scss';

const DraggerUpload = (props: DraggerUploadProps) => {
  // This is temporary until we get the links for uploading files. Subject to change
  const {
    state,
    setFileName,
    setFileContent,
    setFiles,
    setFileType,
    setFileSize,
  } = useDraggerUploadHook();

  const {
    fileName,
    fileType,
    fileContent,
    files,
    fileSize,
  } = state;
  const { defaultFileList } = props;

  useEffect(() => {
    if (identity.isFullArray(defaultFileList)) {
      setFiles({ data: [...defaultFileList!] });
      setFileName({ data: defaultFileList![0].name });
      if (identity.isTruthyString(defaultFileList![0]?.url)) {
        setFileType({ data: defaultFileList![0]?.url!.split('.').pop()! });
      }
      if (identity.isTruthyNumber(defaultFileList![0]?.size) || identity.isZero(defaultFileList![0]?.size)) {
        setFileSize({ data: JSON.stringify(defaultFileList![0]?.size!) });
      }
      if (identity.isTruthyString(defaultFileList![0]?.url) && !identity.isEmptyString(fileContent)) {
        setFileContent({ data: defaultFileList![0]?.url! });
      }
    }
  }, [props.defaultFileList]);

  const onChangeHandler = (res: any) => {
    setFiles({ data: res.fileList });
    setFileName({ data: res.file.name });
    setFileSize({ data: res.file.size });
    setFileType(res.file.type.split('/')[1]);
  };

  const fileSizeMb = useMemo(() => ((Number(fileSize) / (1048576)).toFixed(2)), [fileSize]);
  const uploadType = useMemo(() => props.accept?.replace('.', ' '), [props.accept]);
  const isDocumentType = uploadType === 'document';

  const beforeUploadHandler = (file: any) => {
    const acceptFile = props.accept?.split('.')[1];
    const receivedFileType = file.name.split('.')[1];
    const isMatch = checkFileType(acceptFile!, receivedFileType);
    if (!isMatch) {
      message.error(`${file.name} is not a ${props.accept} file`);
    }
    return isMatch || Upload.LIST_IGNORE;
  }

  if (identity.isEmptyArray(files)) {
    return (
      <Upload.Dragger
        {...props}
        beforeUpload={props.beforeUpload || beforeUploadHandler}
        onChange={onChangeHandler}
        showUploadList={false}
        multiple={false}
        fileList={files}
        className="dragger"
        accept={props.accept}
        onDrop={onChangeHandler}
      >
        <div className="dragger-text-container">
          {props.accept !== '.csv' ? <UploadIcon /> : <CsvDocumentIcon />}
          <p className="dragger-text">{`Upload or drag & drop ${uploadType} ${isDocumentType ? '' : 'file'}`}</p>
        </div>
      </Upload.Dragger>
    )
  }

  return (
    <Preview
      {...props}
      previewType={props.previewType}
      fileName={fileName}
      fileSizeMb={fileSizeMb}
      fileType={fileType}
      beforeUpload={props.beforeUpload || beforeUploadHandler}
      onChange={onChangeHandler}
      showUploadList={false}
      multiple={false}
      fileList={files}
      accept={props.accept}
      onDrop={onChangeHandler}
      buttonsProps={{
        title: fileName,
        fileContent: identity.isFullArray(props.defaultFileList) ? getImageLink(props.defaultFileList![0]?.url!) : (fileType || ''),
        fileType: getFileType(fileType),
        allowChange: props.allowChange,
      }}
      isRoundableButton={props.isRoundableButton}
    />
  );
}

DraggerUpload.defaultProps = {
  previewType: 'text',
}

export default DraggerUpload;
