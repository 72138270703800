import { connect } from 'react-redux';
import { Action, bindActionCreators, Dispatch } from 'redux';

import { selectInvolvementTypes } from 'redux/selectors/people';
import { addMember, getInvolvementTypes, addHub71Employee } from 'redux/actions/people';
import { StateType } from 'types';
import { selectNationalities, selectStartups } from 'redux/selectors/workspace';
import { getNationalities, getStartups } from 'redux/actions/workspace';

const mapStateToProps = (state: StateType) => ({
  startups: selectStartups(state),
  involvementTypes: selectInvolvementTypes(state),
  nationalities: selectNationalities(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  actions: bindActionCreators({
    addMember,
    getStartups,
    addHub71Employee,
    getInvolvementTypes,
    getNationalities,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps);
