import React, { useMemo } from 'react';
import { UploadFile } from 'antd/es/upload/interface';

import { isEitherAdmin, getServicePrice } from 'utils';
import { Input, Overview } from 'components/components';
import { ProposalProps } from 'types/requestDetailsModal';
import { proposalRequirement } from './utils/constants';

import './Proposal.scss';

const Proposal = (props: ProposalProps) => {
  const {
    proposalTitle,
    proposalDocument,
    otherDetails,
    service,
    proposalDuration,
  } = props.proposal;
  const {
    priceFrom, priceTo, offerPrice, priceType,
  } = service;

  const defaultList: UploadFile[] = useMemo(() => ([{
    uid: '-1',
    name: 'document.pdf',
    status: 'done',
    url: proposalDocument,
    thumbUrl: proposalDocument,
    size: 2,
  }]), [proposalDocument]);

  return (
    <Overview title="Proposal">
      <div className="request-proposal__container">
        <span className="request-proposal__requirement">{proposalRequirement}</span>
        <div className="request-proposal__content">
          <div className="request-details__row">
            <Overview.DataColumn text={proposalTitle} label="Title" />
            <Overview.DataColumn text={proposalDuration} label="Duration" />
          </div>
          <Overview.DataColumn text={getServicePrice(offerPrice, priceFrom, priceTo, priceType)} label="Amount" />
          <Overview.DataColumn text={otherDetails} label="Other details" />
          <Input label="Proposal document" layout="vertical">
            <Input.DraggerUpload
              allowChange={isEitherAdmin(props.userType)}
              accept=".pdf"
              defaultFileList={defaultList}
              previewType="image"
              isRoundableButton
            />
          </Input>
        </div>
      </div>
    </Overview>
  );
}

export default Proposal;
