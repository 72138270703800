import { StatCardProps } from '../../../types/componentTypes';

export const mockSubmitOverviewStats: StatCardProps[] = [
  {
    title: 'members',
    count: 0,
  },
  {
    title: 'service providers',
    count: 0,
  },
  {
    title: 'upcoming events',
    count: 0,
  },
];

export default null;
