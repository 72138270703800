import React from 'react'

import { RowProps } from '../../../../../types/componentTypes/FormBuilder';

const Row = (props: RowProps) => {
  const { oneColumn, children } = props;

  return (
    <div className={`form-builder__row${oneColumn ? '--one-column' : ''}`}>
      {children}
    </div>
  )
}

Row.defaultProps = {
  oneColumn: false,
}

export default Row;
