import { connect } from 'react-redux';
import { bindActionCreators, Dispatch, Action } from 'redux';

import { selectCurrentPartnerTab } from 'redux/selectors/partners';
import { setCurrentPartnerTab } from 'redux/actions/partners';
import { selectUserType } from '../../../../redux/selectors/auth';
import { StateType } from '../../../../types';

const mapStateToProps = (state: StateType) => ({
  userType: selectUserType(state),
  currentTab: selectCurrentPartnerTab(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  actions: bindActionCreators({ setCurrentPartnerTab }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps);
