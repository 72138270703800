import { connect } from 'react-redux';
import { bindActionCreators, Dispatch, Action } from 'redux';

import { StateType } from 'types';
import { addService, getServiceCategories, getServiceUnitsList } from '../../redux/actions/services';
import { selectServiceCategories, selectServiceTimeUnits } from '../../redux/selectors/services';

const mapStateToProps = (state: StateType) => ({
  categories: selectServiceCategories(state),
  units: selectServiceTimeUnits(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  actions: bindActionCreators({
    addService,
    getServiceCategories,
    getServiceUnitsList,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps);
