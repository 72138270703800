import { connect } from 'react-redux';

import { selectUser } from 'redux/selectors/auth';
import { StateType } from 'types';

const mapStateToProps = (state: StateType) => ({
  user: selectUser(state),
});

export default connect(mapStateToProps, null);
