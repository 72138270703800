import { AddServiceType } from 'types/services/addService';
import { ServicePriceType } from 'packages/service_repository';
import { identity, isServiceProvider } from 'utils';
import { UserType } from '../../../types/auth';

export * from './changePhotoInLocalStorage';
export * from './changeRequestStatusHelper';
export * from './changeRequestedServiceStatusHelper';

export function buildServicePricePayload(serviceId: number, service: AddServiceType): ServicePriceType {
  const { priceFrom, priceTo, unit } = service;
  const fromPrice = parseInt(priceFrom, 10);
  const toPrice = parseInt(priceTo, 10);
  return {
    fromPrice: identity.isTruthyNumber(fromPrice) ? fromPrice : undefined,
    id: serviceId,
    serviceID: serviceId,
    toPrice: identity.isTruthyNumber(toPrice) ? toPrice : undefined,
    unitID: unit?.id! || 0,
  };
}

export function getLoggedInServiceProviderId(userType: UserType): number | undefined {
  let serviceProviderId: number | undefined;
  if (isServiceProvider(userType.userType)) {
    serviceProviderId = userType.serviceProviderId || 0;
  }

  return serviceProviderId;
}
