import React, { useEffect, useState } from 'react'
import { Checkbox, Col, Row } from 'antd';
import { AiOutlineDelete } from 'react-icons/ai';

import { CheckboxChangeEvent } from 'antd/es/checkbox';
import Title from '../../../TitleWithActions/components/Title';
import Input from '../../../Input';
import { RecipientProps, RecipientType } from '../../../../../types/componentTypes/ActivationLinkRecipientProps';
import { roleOptions } from './constants';
import identity from '../../../../../utils/identity';

import './RecipientForm.scss'

const RecipientForm = (props: RecipientProps) => {
  const {
    id,
    recipient,
    hasDeleteIcon,
    onDeleteRecipient,
    hasCheckbox,
    onChangeHandle,
    roleAsText,
    onCheckedHandle,
    checkedValue,
  } = props;

  const [formValue, setFormValue] = useState(recipient);
  const disableFirstRecipientForm = identity.isFalsy(id) && checkedValue;
  const [checkedRole, setCheckedRole] = useState([]);

  useEffect(() => {
    setFormValue(recipient);
  }, [recipient])

  const onChange = (field: RecipientType, value: string) => {
    setFormValue({ ...formValue, [field]: value });
    onChangeHandle({ id, field, value });
  }

  const onChecked = (e: CheckboxChangeEvent) => {
    onCheckedHandle(e.target.checked);
  };

  const onDeleteHandle = () => {
    if (identity.isFalsy(id)) {
      onCheckedHandle(false);
    }
    onDeleteRecipient(recipient.id);
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onChangeRole = (checkedValues: any) => {
    onChange('role', checkedValues);
    setCheckedRole(checkedValues)
  };

  const typeMenu = (
    <div className="startup-role-dropdown">
      <Input.CheckboxGroup
        name="recipientSameAsContactPerson"
        onChange={onChangeRole}
        options={roleOptions}
        value={checkedRole}
      />
    </div>
  );

  return (
    <Row>
      <Col span={24}>
        <div className="form-header">
          <Title title={`Recipient ${id + 1}`} />
          { hasDeleteIcon ? (
            <div className="delete-icon">
              <AiOutlineDelete onClick={onDeleteHandle} />
            </div>
          ) : null }
        </div>
        <div className="contact__checkbox">
          {identity.isFalsy(id) && hasCheckbox ? (
            <Input>
              <Checkbox checked={checkedValue} onChange={onChecked}>
                Same as contact person
              </Checkbox>
            </Input>
          ) : null }
        </div>
        <Row gutter={12}>
          <Col span={12}>
            <Input label="First Name" layout="vertical">
              <Input.InputField
                type="text"
                name="firstName"
                disabled={disableFirstRecipientForm}
                className="input_fields"
                value={formValue.firstName}
                onChange={(e) => {
                  onChange('firstName', e.target.value);
                }}
              />
            </Input>
          </Col>
          <Col span={12}>
            <Input label="Last Name" layout="vertical">
              <Input.InputField
                type="text"
                name="lastName"
                disabled={disableFirstRecipientForm}
                className="input_fields"
                value={formValue.lastName}
                onChange={(e) => {
                  onChange('lastName', e.target.value);
                }}
              />
            </Input>
          </Col>
        </Row>
        <Row gutter={12}>
          <Col span={12}>
            <Input label="Email" layout="vertical">
              <Input.InputField
                type="text"
                name="email"
                className="input_fields"
                disabled={disableFirstRecipientForm}
                value={formValue.email}
                onChange={(e) => {
                  onChange('email', e.target.value);
                }}
              />
            </Input>
          </Col>
          <Col span={12}>
            <Input label="Phone Number" layout="vertical">
              <Input.InputField
                type="text"
                name="phoneNumber"
                className="input_fields"
                disabled={disableFirstRecipientForm}
                value={formValue.phoneNumber}
                onChange={(e) => {
                  onChange('phoneNumber', e.target.value);
                }}
              />
            </Input>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <Input label="Job Title" layout="vertical">
              {!roleAsText ? (
                <Input.Dropdown
                  overlay={typeMenu}
                >
                  <span className="dropdown-roles">
                    {checkedRole.join(',')}
                  </span>
                </Input.Dropdown>

              ) : (
                <Input.InputField
                  type="text"
                  className="input_fields"
                  name="role"
                  disabled={disableFirstRecipientForm}
                  value={formValue.role}
                  onChange={(e) => {
                    onChange('role', e.target.value);
                  }}
                />
              )}

            </Input>
          </Col>
        </Row>
      </Col>
    </Row>
  )
};

export default RecipientForm;
