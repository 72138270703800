export const removeAddItemToArray = (data: Array<number | string>, item: number | string) => {
  const itemExists = data.find((element) => element === item);

  if (itemExists) {
    return data.filter((element) => element !== item);
  }
  data.push(item);
  return data;
}

export default null;
