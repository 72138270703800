import React from 'react';
import { Card, Col, Row } from 'antd';

import classnames from 'classnames';
import { AddImageIcon } from 'assets/icons';
import { identity } from 'utils';
import { PartnerCardProps } from 'types/componentTypes';
import { RenderIf } from '..';
import DescriptionBox from '../Card/components/DescriptionBox';
import CardInfoRow from '../Card/components/CardInfoRow';
import CardButtons from '../ServiceCard/components/CardButtons';

import '../ServiceCard/ServiceCard.scss';

const PartnerCard = (props: PartnerCardProps) => {
  const {
    dataSource, onGridCardClick, columns, numOfCard,
  } = props;

  const sliceNumber = numOfCard || dataSource?.length;

  return (
    <Row gutter={16}>
      {dataSource?.slice(0, sliceNumber).map((data) => (
        <Col xs={16} sm={14} md={10} xl={8} xxl={6} key={`${data.name}-${data.type}-${data.id}`}>
          <div className="service-card-container">
            <Card
              bordered
              className="service-card category-container"
              onClick={() => onGridCardClick(data)}
            >
              <div className="service-card-container__title-container">
                <p className={classnames(
                  'title',
                  { 'no-image-title' : identity.isEmptyString(data.logo) },
                )}
                >
                  {identity.isEmptyString(data.name) ? 'Not specified' : data.name}
                </p>
                {identity.isTruthyString(data.logo) && data.logo.toString().includes('/media')
                  ? (
                    <img
                      alt={data.name}
                      src={data.logo as string}
                      className="card-image"
                    />
                  )
                  : (
                    <div className="empty-image">
                      <AddImageIcon />
                    </div>
                  )}
              </div>
              <DescriptionBox
                title="Description"
                description={identity.isTruthyString(data.description) ? data.description : 'No description specified for this partner'}
              />
              {columns?.map((column) => (
                <div className="category-container" key={column?.key}>
                  <CardInfoRow data={data} infoRowKey={identity.isTruthyString(column?.key) ? column?.key : 'n/a'} />
                </div>
              )) }
              <RenderIf
                condition={identity.isTruthyString(props.firstButtonTitle)
                || identity.isTruthyString(props.secondButtonTitle)}
              >
                <CardButtons
                  firstButtonAction={props.firstButtonAction!}
                  firstButtonTitle={props.firstButtonTitle}
                  secondButtonAction={props.secondButtonAction!}
                  secondButtonTitle={props.secondButtonTitle}
                  isFirstButtonDisabled={props.isFirstButtonDisabled}
                  isSecondButtonDisabled={props.isSecondButtonDisabled}
                  className={classnames('primary-button md')}
                  data={data}
                />
              </RenderIf>
            </Card>
          </div>
        </Col>
      ))}
    </Row>
  );
}

export default PartnerCard;
