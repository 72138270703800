import React from 'react';
import { Tag } from 'antd';

import { FilterTagProps } from 'types/services';
import { CloseIcon } from 'assets/icons';

import './FilterTag.scss';

const FilterTag = (props: FilterTagProps) => {
  const { prefix, item, handleRemoveFilter } = props;

  return (
    <div className="filter-tag">
      <Tag closable onClose={handleRemoveFilter} className="filter-tag__tag" closeIcon={<CloseIcon />}>
        {`${prefix}: ${item}`}
      </Tag>
    </div>
  )
}

export default FilterTag;
