import React from 'react'
import { Button } from 'antd';

import { ShowMoreButtonProps } from '../../../../types/activities';

import './ShowMoreButton.scss';

const ShowMoreButton = (props: ShowMoreButtonProps) => {
  const { isLoading, onShowMore } = props;
  return (
    <Button className="show-more-btn" type="link" loading={isLoading} onClick={() => onShowMore()}>
      {!isLoading && 'SHOW MORE'}
    </Button>
  )
}

export default ShowMoreButton;
