import React, { useState } from 'react';
import { Button } from 'antd';

import { RenderIf } from '../../../components';
import { ServicesDropdownFilters } from '../../../ServicesAndServiceProviders';

const ServicesFiltersDrawer = () => {
  const [showDropdown, setShowDropdown] = useState<boolean>(false);

  return (
    <div className="filters-overview__filter-button">
      <RenderIf condition={!showDropdown}>
        <Button className="secondary-button" onClick={() => setShowDropdown(true)}>Filter</Button>
      </RenderIf>
      <RenderIf condition={showDropdown}>
        <ServicesDropdownFilters setShowDropdown={setShowDropdown} />
      </RenderIf>
    </div>
  );
}

export default ServicesFiltersDrawer;
