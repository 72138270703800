import { ReactNode } from 'react';

const getLabelValueObject = (label: string | ReactNode, value: string | number) => ({
  label,
  value,
});

export const getPrimitiveLabeledValueObject = (label: string, value: string | number) => ({
  label,
  value,
});

export default getLabelValueObject;
