import React from 'react';
import { Redirect } from 'react-router';
import { Button, Form, Spin } from 'antd';
import { Link } from 'react-router-dom';

import routes from 'routes';
import useQuery from 'utils/helpers/getRequestQueryParams';
import { identity, emailValidation, requiredValidation } from 'utils';
import { ResetPasswordPayload, ResetPasswordProps } from '../../types/auth/resetPassword';
import { Input, ConfirmPassword } from '../components';

import './ResetPassword.scss';

const ResetPassword = (props: ResetPasswordProps) => {
  const { isAuthenticated, isLoading, actions } = props;

  const query = useQuery();
  const uid = query.get('u') || undefined;
  const otp = query.get('otp') || undefined;

  const onFinish = (formData: ResetPasswordPayload) => {
    if (identity.isTruthyString(otp) && identity.isTruthyString(uid)) {
      const { password } = formData;
      actions.getResetPassword({ otp, uid, password });
    } else {
      const { email } = formData;
      actions.getResetPassword({ email, uid: email });
    }
  }

  /* User can only reset password if not able to login - use change password flow if logged in */
  if (isAuthenticated) {
    return <Redirect to={routes.changePasswordUrl} />
  }

  return (
    <Spin spinning={isLoading}>
      <div className="reset-page">
        <Form className="reset-container" onFinish={onFinish} validateTrigger="onSubmit">
          <div className="sub-form">
            <p className="heading">Reset Password</p>
            {(uid && otp) ? (
              <>
                <p className="input-heading">Enter a new password for your account</p>
                <ConfirmPassword />
              </>
            ) : (
              <div className="reset__send-email">
                <p className="input-heading">A link to reset your password will be sent to your email</p>
                <Input layout="vertical" rules={[requiredValidation, emailValidation]} label="Email" name="email">
                  <Input.InputField />
                </Input>
              </div>
            )}
            <div className="reset-page__buttons">
              <Link to={routes.loginUrl} className="button">
                <Button className="secondary-button">Back to login</Button>
              </Link>
              <Button htmlType="submit" className="primary-button button">Reset password</Button>
            </div>
          </div>
        </Form>
      </div>
    </Spin>
  );
}

export default ResetPassword;
