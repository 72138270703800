import { connect } from 'react-redux';
import { Action, bindActionCreators, Dispatch } from 'redux';

import { selectUserType } from 'redux/selectors/auth';
import { StateType } from '../../../../types';
import {
  selectAddedRequestsCount,
  selectCurrentTab,
  selectHighlightRequest,
  selectIsLoading,
} from '../../../../redux/selectors/request';
import { removeHighlight } from '../../../../redux/actions/request';

const mapStateToProps = (state: StateType) => ({
  isLoading: selectIsLoading(state),
  currentTab: selectCurrentTab(state),
  addedRequestsCount: selectAddedRequestsCount(state),
  highlightRequest: selectHighlightRequest(state),
  userType: selectUserType(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  actions: bindActionCreators({ removeHighlight }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps);
