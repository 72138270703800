import React, { useEffect } from 'react';
import { Button } from 'antd';
import classnames from 'classnames';

import { isEitherAdmin } from 'utils';
import { RenderIf, SubTab } from 'components/components';
import { EventTabsProps } from 'types/events/eventsProps';
import { EventState } from 'types/events';
import { EVENT_RSVP_KEYS, EVENT_RSVP_RESPONSE, EVENT_STATUS } from 'packages/events_repository';
import StaticNotification from '../../../components/Notification/StaticNotification'

import './EventTabs.scss';

const EventTabs = (props: EventTabsProps) => {
  const {
    currentTab,
    userType,
    currentSubTab,
    responseCount,
    setOpenFilters,
    filters,
    filtersActive,
  } = props;
  const { setEventTab, setEventSubTab, getEventsResponseCount } = props.actions;

  const handleChangeTab = (data: EventState) => {
    setEventTab({ data });
    setEventSubTab({ data: 'all' });
  }

  useEffect(() => {
    if (!isEitherAdmin(userType)) {
      getEventsResponseCount();
    }
  }, [currentTab, filters]);

  return (
    <div className="event-tabs">
      <div className="event-tabs__container">
        <div className="event-tabs__main">
          {Object.values(EVENT_STATUS).map((tab) => (
            <RenderIf condition={tab !== '-'}>
              <Button
                className={classnames('tab-button-lg', { 'tab-button-active': currentTab === tab })}
                onClick={() => handleChangeTab(tab)}
              >
                <p className="tab-text">{`${tab} events`}</p>
              </Button>
            </RenderIf>
          ))}
        </div>
        <div className={classnames('event-sub-tabs__container', { 'no-event-sub-tabs': isEitherAdmin(userType) })}>
          <RenderIf condition={!isEitherAdmin(userType)}>
            <div className="event-tabs__sub">
              <SubTab
                activeKey={currentSubTab}
                tab={`All (${responseCount.all})`}
                tabKey="all"
                onClick={() => setEventSubTab({ data: 'all' })}
              />
              {Object.values(EVENT_RSVP_RESPONSE).map((subTab, index) => (
                <SubTab
                  tab={`${subTab} (${responseCount[EVENT_RSVP_KEYS[index]]})`}
                  tabKey={subTab}
                  activeKey={currentSubTab}
                  onClick={() => setEventSubTab({ data: subTab })}
                />
              ))}
            </div>
          </RenderIf>
        </div>
        <RenderIf condition={props.filtersActive}>
          <StaticNotification type="warning" message="You are currently viewing filtered items. To view all items clear filters." />
        </RenderIf>
      </div>
      <div className="table-filter-button__container">
        <Button
          className="secondary-button"
          onClick={() => setOpenFilters(true)}
        >
          Filter
        </Button>
        <RenderIf condition={filtersActive}>
          <div className="filter-dot" />
        </RenderIf>
      </div>
    </div>
  );
}

export default EventTabs;
