import React, { useEffect, useMemo, useState } from 'react';
import { Button, Form, Spin } from 'antd';
import { useLocation, useParams } from 'react-router';
import { RcFile } from 'antd/lib/upload';
import classnames from 'classnames';

import { EditServiceProviderPayload } from 'packages/service_repository';
import { identity, isStartup, isEitherAdmin } from 'utils';
import { StartupLogo } from 'components/EditStartup/components';
import {
  EditProfileImage,
  RenderIf,
  TitleWithActions,
  Breadcrumb,
  Input,
  CompanyDeckFile,
} from 'components/components';
import { composeServiceProviderDetailsUrl } from 'routes/routeComposers';
import { EditServiceProviderProps } from 'types/serviceProviders/editServiceProvider';
import routes from 'routes';
import {
  ServiceProviderDetailsForm,
  ContactPersonForm,
  ServiceProviderOffers,
  ServiceProviderPerksForm,
} from './components';

import './EditServiceProvider.scss';

const EditServiceProvider = (props: EditServiceProviderProps) => {
  const {
    serviceProvider, userType, isLoading, userServiceProviderId,
  } = props;
  const { getServiceProviderById, patchServiceProviderDataById } = props.actions;

  const params: { id?: string } = useParams();
  const [logo, setLogo] = useState<string | Blob>(serviceProvider?.imageLink);
  const { id } = params;
  const location = useLocation();
  const isEditCompanyProfile = useMemo(() => (
    identity.isTruthyNumber(userServiceProviderId) && location.pathname === routes.editCompanyProfileUrl
  ), [location, userServiceProviderId]);
  const serviceProviderId = useMemo(() => (
    isEditCompanyProfile ? userServiceProviderId : parseInt(id!, 10)
  ), [id])
  /// Temporarily hiding offers for now
  const showOffer = false;
  const [form] = Form.useForm();

  useEffect(() => {
    getServiceProviderById({ id: serviceProviderId });
  }, [id]);

  useEffect(() => {
    form.setFieldsValue(serviceProvider);
  }, [serviceProvider, form])

  const onSubmit = (data: EditServiceProviderPayload) => {
    const formData = {
      role: data?.role,
      about: data?.about,
      email: data?.email,
      logo: identity.isEmptyString(logo) ? serviceProvider?.imageLink : logo,
      firstName: data?.firstName,
      lastName: data?.lastName,
      websiteLink: data?.websiteLink,
      phoneNumber: data?.phoneNumber,
    }
    patchServiceProviderDataById({ id: serviceProviderId, data: formData });
  };

  const beforeLogoUpload = (file: RcFile) => {
    setLogo(file as Blob);
    return false;
  }

  return (
    <div className="content-container">
      <Spin spinning={isLoading}>
        <div
          className={classnames({
            'edit-service-provider': (isEitherAdmin(userType) || isEditCompanyProfile),
            'service-provider-more-details': !(isEitherAdmin(userType) || isEditCompanyProfile),
          })}
        >
          <Breadcrumb
            navigateTo={isEditCompanyProfile
              ? routes.companyProfileUrl
              : composeServiceProviderDetailsUrl(serviceProviderId)}
            routeName="service provider details"
          />
          <TitleWithActions title={serviceProvider?.name} />
          <div className="logo-container">
            <RenderIf condition={isEitherAdmin(userType) || isEditCompanyProfile}>
              <EditProfileImage imageLink={serviceProvider?.imageLink} beforeLogoUpload={beforeLogoUpload} />
            </RenderIf>
            <RenderIf condition={isStartup(userType)}>
              <StartupLogo
                imageUrl={serviceProvider?.imageLink}
                name="imageLink"
              />
            </RenderIf>
          </div>
          <Form
            className="form-builder  edit-service-provider-form"
            onFinish={onSubmit}
            form={form}
          >
            <ServiceProviderDetailsForm userType={userType} />
            <Input label="Rate Card Document" layout="horizontal" name="companyDeck">
              <CompanyDeckFile fileContent={serviceProvider?.companyDeck} />
            </Input>
            <Input label="Agreement Document" layout="horizontal" name="companyDeck">
              <CompanyDeckFile fileContent={serviceProvider?.agreementDocument} fileName="Agreement Document.pdf" />
            </Input>
            <ContactPersonForm userType={userType} />
            <RenderIf condition={isStartup(userType)}>
              <ServiceProviderPerksForm userType={userType} />
            </RenderIf>
            <RenderIf condition={isEitherAdmin(userType) || isEditCompanyProfile}>
              <>
                <RenderIf condition={showOffer}>
                  <ServiceProviderOffers />
                </RenderIf>
                <Button htmlType="submit" className="primary-button">Submit</Button>
              </>
            </RenderIf>
          </Form>
        </div>
      </Spin>
    </div>
  )
}

export default EditServiceProvider;
