import React from 'react';

import { ExpandableProps } from 'types/componentTypes/ExpandableProps';
import { RowTable } from '../Table/components';
import Collapse from '../Collapse';

import './ExpandableTable.scss';

const ExpandableTable = (props: ExpandableProps) => {
  const { collapseHeader, filters, className } = props;

  return (
    <Collapse header={collapseHeader} className={`expandable-table ${className}`}>
      <div>
        {filters}
        <RowTable
          onRow={props.onRow}
          columns={props.columns}
          dataSource={props.dataSource}
          scroll={{ y: 250 }}
          rowKey={props.rowKey}
        />
      </div>
    </Collapse>
  );
};

export default ExpandableTable;
