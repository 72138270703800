import React, { useState } from 'react'
import { Form } from 'antd'
import { RcFile } from 'antd/lib/upload';

import { Row } from 'components/components/FormBuilder/components';
import DrawerButton from 'components/components/Drawer/components/DrawerButton';
import { scrollToFirstError } from 'components/components/FormBuilder/utils/helpers';
import { PartnerBeType } from 'packages/partner_repository';
import { FormBuilder, Input, RenderIf } from 'components/components';
import {
  addPartnerFields, AddPartnerFieldsParams, addPartnerMouFields, defaultValues, mouSignedValues,
} from './utils/constants';
import { AddPartnerDrawerProps } from '../../types/partners/addPartner';

import './AddPartnerDrawer.scss'

const AddPartnerDrawer = (props: AddPartnerDrawerProps) => {
  const { actions } = props;
  const [form] = Form.useForm();
  const [showMouUpload, setShowMouUpload] = useState(0);
  /* useEffect(() => {
    if (sameAsContact) {
      updateRecipients(sameAsContact)
    }
  }, [changeInEmail, changeInPersonName, changeInPhoneNumber, changeInRole]) */

  const handleSubmit = (data: PartnerBeType) => {
    actions.addNewPartner({ data });
    // TODO: Check with about recipitent activation link
    /* form.resetFields();
    setRecipients(defaultRecipients); */
  }

  /* const onDeleteRecipient = (id: number) => {
    const newArray = recipients.filter((recipient: Recipient) => recipient.id !== id);
    setRecipients(newArray);
  };

  const onChangeHandle = (changedValue: HandleRecipientChangeType) => {
    const newArr = [...recipients];
    newArr[changedValue.id] = { ...newArr[changedValue.id], [changedValue.field]: changedValue.value };
    setRecipients(newArr);
  };

  const onAddRecipient = (recipient: Recipient) => {
    const id = recipients.length;
    setRecipients([...recipients, { ...recipient, id }]);
  };

  const onCheckedHandle = (checked: boolean) => {
    setSameAsContact(checked);
    updateRecipients(checked);
  } */

  const beforeLogoUpload = (file: RcFile) => {
    form.setFieldValue('logo', file as Blob);
    return false;
  }

  const addPartnerFieldsData: AddPartnerFieldsParams = {
    beforeLogoUpload,
  }

  const beforePitchDeckUpload = (file: RcFile) => {
    form.setFieldValue('moU', file as Blob);
    return false;
  }

  return (
    <Form
      form={form}
      className="form-standalone add-new-partner"
      onFinish={handleSubmit}
      onFinishFailed={scrollToFirstError}
    >
      <FormBuilder
        formFragment
        fields={addPartnerFields}
        defaultValues={defaultValues}
      />
      <Row oneColumn>
        <Input label="MoU document signed?" layout="vertical" name="moUSigned" required>
          <Input.Radio
            values={mouSignedValues}
            value={showMouUpload}
            defaultValue={0}
            onChange={(e) => setShowMouUpload(e.target.value)}
          />
        </Input>
        <RenderIf condition={showMouUpload === 1}>
          <Input
            name="moU"
            layout="vertical"
            required
          >
            <Input.DraggerUpload beforeUpload={beforePitchDeckUpload} accept=".pdf" />
          </Input>
        </RenderIf>
      </Row>
      <FormBuilder
        formFragment
        fields={addPartnerMouFields(addPartnerFieldsData)}
        defaultValues={defaultValues}
      />
      {/*
      TODO: Check with client what about recipient activation link
     <div className="activation-link-container">
        <ActivationLinkRecipient
          hasCheckbox
          roleAsText
          recipients={recipients}
          onDeleteRecipient={onDeleteRecipient}
          onAddRecipient={onAddRecipient}
          onCheckedHandle={onCheckedHandle}
          onChangeHandle={onChangeHandle}
        />
  </div> */}
      <DrawerButton>Add Partner</DrawerButton>
    </Form>
  )
}

export default AddPartnerDrawer
