import React, { useEffect, useMemo, useState } from 'react';
import { Image } from 'antd';
import { MdModeEdit } from 'react-icons/md';

import { identity } from 'utils';
import { EditProfileImageProps } from 'types/componentTypes/EditProfileImage';
import Input from '../Input';

import './EditProfileImage.scss';

const EditProfileImage = (props: EditProfileImageProps) => {
  const { imageLink, beforeLogoUpload } = props;
  const [editImage, setEditImage] = useState<boolean>(false);
  const imageUrl = useMemo(() => (identity.isTruthyString(imageLink) ? imageLink.toString() : ''), [imageLink]);
  const isRealImageLink = imageUrl?.includes('/media')!;
  useEffect(() => {
    if (identity.isTruthyString(imageUrl) && isRealImageLink) {
      setEditImage(false);
    } else {
      setEditImage(true);
    }
  }, [imageUrl]);

  return (
    <div className="image-container">
      {editImage ? (
        <Input>
          <Input.UploadImage beforeUpload={(file) => beforeLogoUpload(file, 'imageLink')} />
        </Input>
      ) : (
        <>
          <Image
            src={imageUrl}
            className="image-tag"
            preview={false}
          />
          <div onKeyDown={() => null} className="edit-icon-wrap" onClick={() => { setEditImage(true); }}>
            <MdModeEdit className="edit-icon" size={22} />
          </div>
        </>
      )}
    </div>
  );
}

export default EditProfileImage;
