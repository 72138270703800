import React from 'react';

import SVGWrap from './SVGWrap';

const CsvDocumentIcon = () => (
  <SVGWrap>
    <path d="M6 14H2V2.00002H6V5.00002C6.00077 5.265 6.10637 5.51891 6.29374 5.70628C6.48111 5.89365 6.73502 5.99925 7 6.00002H10V10H11V5.00002C11.0018 4.9343 10.9893 4.86898 10.9634 4.80856C10.9375 4.74813 10.8988 4.69404 10.85 4.65002L7.35 1.15002C7.30599 1.10118 7.2519 1.06248 7.19147 1.03658C7.13104 1.01069 7.06572 0.998211 7 1.00002H2C1.73502 1.00078 1.48111 1.10639 1.29374 1.29376C1.10637 1.48113 1.00077 1.73504 1 2.00002V14C1.00077 14.265 1.10637 14.5189 1.29374 14.7063C1.48111 14.8937 1.73502 14.9993 2 15H6V14ZM7 2.20002L9.8 5.00002H7V2.20002Z" />
    <path d="M14.4286 11L13.8571 14.7143L13.2857 11H12.7143L13.4331 15H14.2811L15 11H14.4286Z" />
    <path d="M11.5714 15H9.85714V14.4286H11.5714V13.2857H10.4286C10.2771 13.2856 10.1318 13.2253 10.0247 13.1182C9.91755 13.011 9.85729 12.8658 9.85714 12.7143V11.5714C9.85729 11.4199 9.91755 11.2747 10.0247 11.1675C10.1318 11.0604 10.2771 11.0002 10.4286 11H12.1429V11.5714H10.4286V12.7143H11.5714C11.7229 12.7144 11.8682 12.7747 11.9753 12.8818C12.0825 12.989 12.1427 13.1342 12.1429 13.2857V14.4286C12.1427 14.5801 12.0825 14.7253 11.9753 14.8325C11.8682 14.9396 11.7229 14.9998 11.5714 15Z" />
    <path d="M9.28571 15H7.57143C7.41993 14.9998 7.27469 14.9396 7.16756 14.8324C7.06043 14.7253 7.00017 14.5801 7 14.4286V11.5714C7.00015 11.4199 7.0604 11.2747 7.16753 11.1675C7.27467 11.0604 7.41992 11.0002 7.57143 11H9.28571V11.5714H7.57143V14.4286H9.28571V15Z" />
  </SVGWrap>
);

export default CsvDocumentIcon;
