import React, { useEffect, useMemo } from 'react';
import { LabeledValue } from 'antd/lib/select';

import { ServiceProviderType } from 'types/serviceProviders';
import { ServiceDetailsFormProps } from 'types/services/addService';
import { isEitherAdmin } from 'utils';
import { serviceProviderTypeOptions } from 'components/AddServiceProviderDrawer/utils/constants';
import { FormBuilder } from 'components/components';
import {
  defaultValues,
  ServiceDetailFormFields,
  AdminServiceDetailFormFields,
  ServiceDetailsParams,
  OfferPriceValues,
} from '../../utils';

const ServiceDetailForm = (props: ServiceDetailsFormProps) => {
  const { serviceProviders, userType, actions: { getServiceProviders } } = props;

  useEffect(() => {
    const query = { isPreload: true };
    getServiceProviders({ query });
  }, []);

  const serviceProvidersOptions: LabeledValue[] = useMemo(() => (
    serviceProviders?.map((item: ServiceProviderType) => ({ label: `${item.name || 'Unknown service provider'}`, value: item.id }))), [serviceProviders]);

  const serviceFiltersData: ServiceDetailsParams = {
    serviceProviders: serviceProvidersOptions,
    serviceProviderType: serviceProviderTypeOptions,
    offerPriceOptions: OfferPriceValues,
  };

  return (
    <FormBuilder
      formFragment
      fields={isEitherAdmin(userType) ? AdminServiceDetailFormFields(serviceFiltersData) : ServiceDetailFormFields}
      defaultValues={defaultValues}
    />
  );
}

export default ServiceDetailForm;
