import { createSelector } from 'reselect';

import { StateType } from 'types';
import { CohortStateType } from 'types/reduxTypes/StateTypes';
import { getDocumentType } from 'components/CohortDetails/components/Requests/utils/helpers';

const selectCohortState = (state: StateType) => state.cohortReducer;

export const selectCohorts = createSelector(
  selectCohortState,
  (state: CohortStateType) => state.cohorts,
);

export const selectCohortData = createSelector(
  selectCohortState,
  (state: CohortStateType) => state.selectedCohort.data,
);

export const selectStartups = createSelector(
  selectCohortState,
  (state: CohortStateType) => state.selectedCohort.startups,
);

export const selectDocumentReviewRequests = createSelector(
  selectCohortState,
  (state: CohortStateType) => state.selectedCohort.data.reviewStartups?.filter((s) => getDocumentType(s) !== ''),
);

export const selectPrograms = createSelector(
  selectCohortState,
  (state: CohortStateType) => state.programs,
);

export const selectIsLoading = createSelector(
  selectCohortState,
  (state: CohortStateType) => state.isLoading,
);

export const selectProgramCohorts = createSelector(
  selectCohortState,
  (state: CohortStateType) => state.programCohorts,
);

export const selectCohortsCount = createSelector(
  selectCohortState,
  (state: CohortStateType) => state.cohortsCount,
);
