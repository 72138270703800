import React from 'react'

import { getLabelValueObject } from 'utils';
import { AddMemberFieldsCreatorParams, AddStartupMemberFormProps } from 'types/people';
import { addMemberFields, defaultValues } from '../../utils/constants';
import { FormBuilder } from '../../../components';
import { startupSelectColumns } from '../../../utils/constants/columns';
import StartupSelectTitle from '../StartupSelectTitle';

const AddStartupMemberForm = (props: AddStartupMemberFormProps) => {
  const {
    form, startups, involvementTypes, beforeHeadshotUpload, nationalities, isFounder,
  } = props;

  const nationalityOptions = nationalities?.map(
    (country) => getLabelValueObject(country.name, country.id),
  );

  const memberFieldsCreateData: AddMemberFieldsCreatorParams = {
    involvementTypes,
    beforeHeadshotUpload,
    nationalityOptions,
    startupDrawerProps: {
      form,
      columns: startupSelectColumns,
      dataSource: startups,
      options: startups.map((item) => ({ label: item.name || 'Not specified', value: item.id })),
      drawerTitle: <StartupSelectTitle />,
      filterBy: 'name',
      rowKeyName: 'id',
    },
    isFounder,
  };

  return (
    <FormBuilder
      formFragment
      fields={addMemberFields(memberFieldsCreateData)}
      defaultValues={defaultValues}
    />
  )
}

export default AddStartupMemberForm;
