import {
  all,
  call,
  put,
  select,
  takeLatest,
} from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';

import { createPartnerMember } from 'packages/people_repository';
import { PartnerMemberType } from 'packages/people_repository/types/partner_member_type';
import config from 'assets/config';
import {
  types,
  setPartners,
  setIsLoading,
  setPartnerDetails,
  setPartnerTeamMembers,
  setPartnerRequestById,
  getPartners as getPartnersAction,
} from 'redux/actions/partners';
import { setDestroyDrawerOnClose, setIsDrawerLoading, setIsDrawerOpen } from 'redux/actions/workspace';
import identity from 'utils/identity';
import { DataPayload, IdPayload } from 'types/reduxTypes/ActionTypes';
import {
  getPartnerById,
  PartnerType,
  updatePartnerById,
  getPartnerMembersById,
  getPartnerRequestById,
  getPartners,
  PartnerTypeType,
  NUMBER_TO_PARTNER_TYPE,
  PARTNER_TYPE_TO_NUMBER,
  createPartnerGetInTouchRequest,
  CreateGetInTouchPartnerPayload,
  getInTouchPartnerRequest,
  PartnerBeType,
  createPartner,
} from 'packages/partner_repository';
import { ParamsPayload } from 'packages/http_client';
import { SendNewGetInTouchPartnerRequestPayload } from 'types/reduxTypes/ActionTypes/PartnersTypes';
import history from 'history_instance';
import routes from 'routes';
import { createServiceRequestComment } from 'packages/service_request_repository/api';
import { CreateServiceRequestCommentType } from 'packages/service_request_repository/types/service_request_comment';
import { UserType } from 'types/auth';
import { StartupType } from 'packages/startup_repository';
import { selectUser, selectUserStartup } from 'redux/selectors/auth';
import { setCurrentTab } from 'redux/actions/request';
import { selectCurrentPartnerTab } from '../../selectors/partners';
import showNotification from '../../../services/utils/showNotification';

function * handleGetPartners(action: PayloadAction<ParamsPayload>) {
  const { limit, offset } = action.payload;
  yield put(setIsLoading({ isLoading: true }));
  const partnerType: PartnerTypeType = yield select(selectCurrentPartnerTab);
  let type;
  switch (partnerType) {
    case NUMBER_TO_PARTNER_TYPE[1]:
    case NUMBER_TO_PARTNER_TYPE[2]:
    case NUMBER_TO_PARTNER_TYPE[3]: {
      type = PARTNER_TYPE_TO_NUMBER[partnerType];
      break;
    }
    case 'all':
    default: {
      type = undefined;
      break;
    }
  }
  const query = {
    isPreload: true, type, limit, offset,
  };
  const { data, error, status } = yield call(getPartners, query);

  if (error) {
    showNotification('error', error, status);
  } else {
    yield put(setPartners({ data }));
  }
  yield put(setIsLoading({ isLoading: false }));
}

function * addNewPartnerHandle(action: PayloadAction<DataPayload<PartnerBeType>>) {
  yield put(setIsDrawerLoading({ isLoading: true }));

  const { data } = action.payload;

  const { error } = yield call(createPartner, data);
  if (!identity.isFalsy(error)) {
    showNotification(error, true, error.status);
  } else {
    showNotification('Partner successfully added.');
    const query: ParamsPayload = {
      isPreload: true,
      limit: config.TABLE_DEFAULT_LIMIT,
      currentPage: 1,
      offset: 0,
    };
    yield put(getPartnersAction(query));
    yield put(setDestroyDrawerOnClose({ destroyDrawerOnClose: true }));
    yield put(setIsDrawerOpen({ isDrawerOpen: false }));
  }
  yield put(setIsDrawerLoading({ isLoading: false }));
}

function * handleGetPartnerById(action: PayloadAction<IdPayload>) {
  yield put(setIsLoading({ isLoading: true }));

  const { id } = action.payload;
  const { error, httpStatus, data } = yield call(getPartnerById, id);

  if (identity.isObjWithChildren(error)) {
    showNotification(error?.message, true, httpStatus);
  } else {
    yield put(setPartnerDetails({ data }));
  }

  yield put(setIsLoading({ isLoading: false }));
}

function * handleUpdateById(action: PayloadAction<DataPayload<PartnerType>>) {
  yield put(setIsLoading({ isLoading: true }));

  const { data } = action.payload;
  const { error, httpStatus } = yield call(updatePartnerById, data.id, data);

  if (identity.isObjWithChildren(error)) {
    showNotification(error?.message, true, httpStatus);
  } else {
    showNotification(`${data.name} successfully updated`);
  }

  yield put(setIsLoading({ isLoading: false }));
}

function * handleGetPartnerTeamMembersById(action: PayloadAction<IdPayload>) {
  yield put(setIsLoading({ isLoading: true }));

  const { id } = action.payload;
  const { error, httpStatus, data } = yield call(getPartnerMembersById, id);

  if (identity.isObjWithChildren(error)) {
    showNotification(error?.message, true, httpStatus);
  } else {
    yield put(setPartnerTeamMembers({ members: data }));
  }

  yield put(setIsLoading({ isLoading: false }));
}

function * handleSendGetInTouchPartnerRequest(action: PayloadAction<SendNewGetInTouchPartnerRequestPayload>) {
  const { error, httpStatus, data: id } = yield call(createPartnerGetInTouchRequest, action.payload);
  if (identity.isObjWithChildren(error)) {
    showNotification(error?.message, true, httpStatus);
  } else {
    showNotification('request sent', id);
    yield put(setIsDrawerOpen({ isDrawerOpen: false }));
  }
}
function * getInTouchHandle(action: PayloadAction<CreateGetInTouchPartnerPayload>) {
  yield put(setIsDrawerLoading({ isLoading: true }));
  const user: UserType = yield select(selectUser);
  const startup: StartupType = yield select(selectUserStartup);
  const organization = startup?.name;

  const { error, data } = yield call(getInTouchPartnerRequest, action.payload);

  if (!identity.isFalsy(error)) {
    showNotification(error, true, error.status);
  } else {
    const payload: CreateServiceRequestCommentType = {
      message: action.payload.additionalComments,
      createdById: Number(user.userId),
      organization,
      partnerRequestId: data[0],
    }

    const { error: commentError } = yield call(createServiceRequestComment, payload);
    if (!identity.isFalsy(commentError)) {
      showNotification(commentError, true, commentError.status);
    } else {
      yield put(setDestroyDrawerOnClose({ destroyDrawerOnClose: true }));
      yield put(setIsDrawerOpen({ isDrawerOpen: false }));
      yield put(setCurrentTab({ currentTab: 'pending HUB71' }));
      history.push(routes.requestsUrl);
    }
  }
  yield put(setIsDrawerLoading({ isLoading: false }));
}

function * handleGetPartnerRequestById(action: PayloadAction<IdPayload>) {
  yield put(setIsLoading({ isLoading: true }));

  const { id } = action.payload;
  const { error, httpStatus, data } = yield call(getPartnerRequestById, id);

  if (identity.isObjWithChildren(error)) {
    showNotification(error?.message, true, httpStatus);
  } else {
    yield put(setPartnerRequestById({ data }));
  }

  yield put(setIsLoading({ isLoading: false }));
}

function * handleAddNewPartnerMember(action: PayloadAction<DataPayload<PartnerMemberType>>) {
  yield put(setIsDrawerLoading({ isLoading: true }));

  const { data } = action.payload;

  const { error } = yield call(createPartnerMember, data.partnerID!, data);
  if (!identity.isFalsy(error)) {
    showNotification(error, true, error.status);
  } else {
    showNotification('Partner member successfully added.');
    yield put(setDestroyDrawerOnClose({ destroyDrawerOnClose: true }));
    yield put(setIsDrawerOpen({ isDrawerOpen: false }));
  }
  yield put(setIsDrawerLoading({ isLoading: false }));
}

export default function * eventsSagas() {
  yield all([
    takeLatest(types.GET_ALL_PARTNERS, handleGetPartners),
    takeLatest(types.ADD_PARTNER, addNewPartnerHandle),
    takeLatest(types.GET_PARTNER_BY_ID, handleGetPartnerById),
    takeLatest(types.UPDATE_PARTNER_BY_ID, handleUpdateById),
    takeLatest(types.GET_PARTNER_TEAM_MEMBERS, handleGetPartnerTeamMembersById),
    takeLatest(types.SEND_PARTNER_GET_IN_TOUCH_REQUEST, handleSendGetInTouchPartnerRequest),
    takeLatest(types.GET_IN_TOUCH, getInTouchHandle),
    takeLatest(types.GET_PARTNER_REQUEST_BY_ID, handleGetPartnerRequestById),
    takeLatest(types.ADD_PARTNER_MEMBER, handleAddNewPartnerMember),
  ]);
}
