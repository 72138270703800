import React, { useEffect, useCallback } from 'react';

import { ServiceSubCategoryType } from 'packages/service_repository';
import { identity, DRAWERS } from 'utils';
import { SubCategoriesProps } from 'types/settingsOverview';
import { getSubCategoriesColumns } from '../../../../utils/constants/columns';
import SettingsTable from '../../SettingsTable';

const SubCategoriesOverview = (props: SubCategoriesProps) => {
  const {
    subCategories,
    onAddNew,
  } = props;
  const {
    getAllServiceSubCategories,
    deleteServiceSubCategoryById,
    setDrawerBody,
    setIsDrawerOpen,
    setServiceSubCategoryDetails,
    setIsMainCategory,
    setCategoryID,
  } = props.actions;

  useEffect(() => {
    if (identity.isEmptyArray(subCategories)) {
      getAllServiceSubCategories({});
    }
  }, []);

  const onDelete = useCallback((id: number) => {
    deleteServiceSubCategoryById({ id });
  }, []);

  const onUpdate = useCallback((data: ServiceSubCategoryType) => {
    onAddNew!({ isEdit: true });
    setDrawerBody({ drawerBody: DRAWERS.editCategory });
    setIsDrawerOpen({ isDrawerOpen: true });
    setServiceSubCategoryDetails({ data })
    setIsMainCategory({ data: false });
    setCategoryID({ data: 2 });
  }, []);

  return (
    <SettingsTable
      dataSource={subCategories}
      columns={getSubCategoriesColumns(onDelete, onUpdate)}
    />
  );
}

export default SubCategoriesOverview;
