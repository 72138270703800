import { connect } from 'react-redux';
import { Action, bindActionCreators, Dispatch } from 'redux';

import { StateType } from 'types';
import { selectIsRowTableLayout } from 'redux/selectors/workspace';
import { setTableLayout } from 'redux/actions/workspace';

const mapStateToProps = (state: StateType) => ({
  isRowTableLayout: selectIsRowTableLayout(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  actions: bindActionCreators({ setTableLayout }, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps);
