import httpClient, { ApiResponseType, HttpRequestConfig } from 'packages/http_client';
import { GetSearchType, GetSearchTypeApiReponse } from './types';
import { SEARCH_API } from './utils/constants';

const searchApi = (params: GetSearchType) => {
  const config: HttpRequestConfig = { params };
  const url = SEARCH_API;
  return httpClient.get<ApiResponseType<GetSearchTypeApiReponse>>(url, config);
}

export const search = async (params: GetSearchType) => {
  const response = await searchApi(params);
  const { data, error, httpStatus } = response;
  return { error, httpStatus, data: data?.result! };
}

export default null;
