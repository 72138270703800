import {
  all, call, put, takeLatest,
} from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';

import { getNationalities } from 'packages/country_repository';
import { getStartupsList } from 'packages/startup_repository';
import { identity } from 'utils';
import showNotification from 'services/utils/showNotification';
import { GetSearchType } from 'packages/search_repository/types';
import { search } from 'packages/search_repository/api';
import { getServiceProvidersCount, getServiceProvidersList, getServicesCount } from 'packages/service_repository'
import { getPartners } from 'packages/partner_repository';
import {
  setNationalities,
  setNavigationSearchResults, setServicesSPCount,
  setStartups,
  setWorkspacePartners,
  setWorkspaceServiceProviders,
  types,
} from '../../actions/workspace'

function * handleGetSearchData(action: PayloadAction<GetSearchType>) {
  const { q } = action.payload;
  const { data, error, httpStatus } = yield call(search, { q });

  if (identity.isObjWithChildren(error)) {
    showNotification(error?.message, true, httpStatus);
  } else {
    yield put(setNavigationSearchResults({ data }));
  }
}

function * handleGetNationalities() {
  const { data, error, httpStatus } = yield call(getNationalities);
  if (identity.isObjWithChildren(error)) {
    showNotification(`Something went wrong: ${error.messsage}`, true, httpStatus);
  } else {
    yield put(setNationalities({ data }));
  }
}

function * handleGetStartups() {
  const { data, error, httpStatus } = yield call(getStartupsList);
  if (identity.isObjWithChildren(error)) {
    showNotification(`Something went wrong: ${error.message}`, true, httpStatus);
  } else {
    yield put(setStartups({ data }));
  }
}

function * handleGetServiceProviders() {
  const { data, error, httpStatus } = yield call(getServiceProvidersList, {});

  if (identity.isObjWithChildren(error)) {
    showNotification(`Something went wrong: ${error.message}`, true, httpStatus);
  } else {
    yield put(setWorkspaceServiceProviders({ data }));
  }
}

function * handleGetPartners() {
  const { data, error, httpStatus } = yield call(getPartners);

  if (identity.isObjWithChildren(error)) {
    showNotification(`Something went wrong: ${error.message}`, true, httpStatus);
  } else {
    yield put(setWorkspacePartners({ data }));
  }
}

function * handleGetServicesSpCount() {
  const { servicesCount: sCount, error, httpStatus } = yield call(getServicesCount, {});
  const {
    data: spCount, error: spError, httpStatus: spHttpStatus,
  } = yield call(getServiceProvidersCount, {});

  let servicesCount = 0;
  let serviceProvidersCount = 0;

  if (identity.isObjWithChildren(error)) {
    showNotification(`Something went wrong: ${error.message}`, true, httpStatus);
  } else {
    servicesCount = sCount;
  }

  if (identity.isObjWithChildren(spError)) {
    showNotification(`Something went wrong: ${spError.message}`, true, spHttpStatus);
  } else {
    serviceProvidersCount = spCount[0].id__count;
  }

  yield put(setServicesSPCount({ serviceProvidersCount, servicesCount }));
}

export default function * workSpaceSagas() {
  yield all([
    takeLatest(types.GET_SEARCH_RESULT, handleGetSearchData),
    takeLatest(types.GET_NATIONALITIES, handleGetNationalities),
    takeLatest(types.GET_ALL_STARTUPS, handleGetStartups),
    takeLatest(types.GET_WORKSPACE_SERVICE_PROVIDERS, handleGetServiceProviders),
    takeLatest(types.GET_WORKSPACE_PARTNERS, handleGetPartners),
    takeLatest(types.GET_WORKSPACE_SERVICES_SP_COUNT, handleGetServicesSpCount),
  ]);
}
