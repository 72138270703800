import { connect } from 'react-redux';
import { bindActionCreators, Dispatch, Action } from 'redux';

import {
  selectEventAttendees,
  selectEventCategories, selectEventDetails, selectEventId, selectEventRSVP,
} from 'redux/selectors/events';
import { StateType } from 'types';
import {
  getEventById, updateEventById, getEventRSVP, getEventCategories, getEventAttendeesByEventId,
} from 'redux/actions/events';
import { selectUserType } from 'redux/selectors/auth';
import { selectUsers } from 'redux/selectors/users';
import { getUsers } from 'redux/actions/users';

const mapStateToProps = (state: StateType) => ({
  event: selectEventDetails(state),
  id: selectEventId(state),
  eventRVSP: selectEventRSVP(state),
  userType: selectUserType(state),
  eventCategories: selectEventCategories(state),
  users: selectUsers(state),
  eventAttendees: selectEventAttendees(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  actions: bindActionCreators({
    getEventById,
    updateEventById,
    getEventRSVP,
    getEventCategories,
    getUsers,
    getEventAttendeesByEventId,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps);
