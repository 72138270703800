import { PayloadAction } from '@reduxjs/toolkit';

import { DataPayload } from 'types/reduxTypes/ActionTypes';
import { ServicesFiltersType } from 'types/services';
import { types } from './actions';

const initialState: ServicesFiltersType = {
  serviceProviderId: [],
  categoryId: [],
  priceTypeId: [],
}

const handleSetOfferPrice = (state: ServicesFiltersType, payload: DataPayload<0 | 1 | 2>) => ({
  ...state, priceTypeId: [payload.data],
});

const handleSetCategories = (state: ServicesFiltersType, payload: DataPayload<number[]>) => ({
  ...state, categoryId: payload.data,
});

const handleSetServiceProviders = (state: ServicesFiltersType, payload: DataPayload<number[]>) => ({
  ...state, serviceProviderId: payload.data,
});

const handleSetFilters = (state: ServicesFiltersType, payload: ServicesFiltersType) => payload;

const reducer = (
  state: ServicesFiltersType,
  action: PayloadAction<DataPayload<number[]> | object | ServicesFiltersType>,
) => {
  const { payload, type } = action;
  switch (type) {
    case types.SET_OFFER_PRICE: {
      return handleSetOfferPrice(state, <DataPayload<0 | 1 | 2>>payload);
    }
    case types.SET_CATEGORIES: {
      return handleSetCategories(state, <DataPayload<number[]>>payload);
    }
    case types.SET_SERVICE_PROVIDERS: {
      return handleSetServiceProviders(state, <DataPayload<number[]>>payload);
    }
    case types.SET_FILTERS: {
      return handleSetFilters(state, <ServicesFiltersType>payload);
    }
    case types.RESET_FILTERS: {
      return initialState;
    }
    default: {
      return state;
    }
  }
}

export { initialState, reducer };
