import { defaultServiceProvider, partnerDef } from 'redux/utils';
import { API_ROOT } from '../../http_client';
import {
  Hub71Employee,
  PartnerMemberType,
  SPMemberType,
  UserType,
} from '../types';

export const USERS_API = `${API_ROOT}/user`;
export const PARTNER_MEMBER_API = `${API_ROOT}/partnermember`;
export const SP_MEMBER_API = `${API_ROOT}/serviceprovidermember`;

export const defaultUser: UserType = {
  active: false,
  admin: false,
  email: '',
  firstName: '',
  id: 0,
  lastName: '',
  photo: undefined,
  remote_access: false,
  user_group_id: 0,
  username: '',
}

export const defaultPartnerMember: PartnerMemberType = {
  email: '',
  firstName: '',
  id: 0,
  jobTitle: '',
  lastName: '',
  mobile: '',
  partner: partnerDef,
  partnerId: 0,
  phone: '',
  photo: '',
  user: defaultUser,
  userID: 0,
}

export const defaultHub71Employee: Hub71Employee = {
  hub71Role: '',
  email: '',
  photo: '',
  designation: '',
  userID: 0,
  firstName: '',
  id: 0,
  lastName: '',
}

export const defaultSPMember: SPMemberType = {
  email: '',
  firstName: '',
  id: 0,
  jobTitle: '',
  lastName: '',
  mobile: '',
  phone: '',
  photo: '',
  serviceProvider: defaultServiceProvider,
  serviceProviderId: 0,
  user: defaultUser,
  userID: 0,
}
