import { connect } from 'react-redux';
import { bindActionCreators, Dispatch, Action } from 'redux';

import { StateType } from '../../types';
import { selectCohortData, selectIsLoading } from '../../redux/selectors/cohort';
import { getCohortById, patchCohortById } from '../../redux/actions/cohort';

const mapStateToProps = (state: StateType) => ({
  selectedCohort: selectCohortData(state),
  isLoading: selectIsLoading(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  actions: bindActionCreators({
    getCohortById,
    patchCohortById,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps);
