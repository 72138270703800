import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { composePeopleDetailsUrl } from 'routes/routeComposers';
import { TeamMembersProps } from 'types/teamMembers';
import { PeopleType } from 'types/people';
import {
  DRAWERS,
  getOffset,
  getRowKey,
  useTableHook,
} from 'utils';
import config from 'assets/config';
import { Table, gridPaginationOptions, paginationOptions } from '../components';
import { peopleOverviewColumns } from '../utils/constants/columns';
import { FILTERS_MAP } from '../components/SearchBar/utils/constants';

const TeamMembers = (props: TeamMembersProps) => {
  const {
    teamMembers,
    actions,
    startupId,
    teamMembersCount,
    isLoading,
    isRowTableLayout,
    filtersActive,
    filters,
  } = props;

  const history = useHistory();

  const {
    state,
    setLimit,
    setCurrentPage,
  } = useTableHook();

  const { limit, currentPage } = state;

  useEffect(() => {
    setCurrentPage(1);
  }, [filters]);

  useEffect(() => {
    let limitDefault;
    if (!isRowTableLayout) {
      limitDefault = paginationOptions.includes(limit) ? config.GRID_TABLE_DEFAULT_LIMIT : limit;
    } else {
      limitDefault = gridPaginationOptions.includes(limit) ? config.TABLE_DEFAULT_LIMIT : limit;
    }
    setLimit(limitDefault);
    setCurrentPage(1);
  }, [isRowTableLayout]);

  useEffect(() => {
    if ((isRowTableLayout && paginationOptions.includes(limit))
      || (!isRowTableLayout && gridPaginationOptions.includes(limit))) {
      const offset = getOffset(limit, currentPage);
      actions.getStartupTeamMembers({
        id: startupId, limit, offset, filters,
      });
      actions.getTeamMembersCount(filters);
    }
  }, [startupId, limit, currentPage, filters, isRowTableLayout]);

  const addTeamMember = () => {
    actions.setDrawerBody({ drawerBody: DRAWERS.addMemberStartup });
    actions.setIsDrawerOpen({ isDrawerOpen: true });
  }

  const openPeopleDetails = (record: PeopleType) => history.push(composePeopleDetailsUrl(record.id, 'startup'));

  return (
    <div className="team-members content-container">
      <Table
        isLoading={isLoading}
        onRow={(record) => ({ onClick: () => openPeopleDetails(record) })}
        onGridCardClick={openPeopleDetails}
        showSearchBar
        leftContent="add-button"
        addButtonText="member"
        placeholder="people"
        dataSource={teamMembers}
        columns={peopleOverviewColumns}
        showDisplayButtons
        onAddButtonClick={addTeamMember}
        rowKey={getRowKey(teamMembers, 'members-overview-team-member', 'id')}
        setLimit={setLimit}
        setCurrentPage={setCurrentPage}
        currentPage={currentPage}
        limit={limit}
        showCustomPagination
        total={teamMembersCount}
        filterButtonType="dropdown"
        filterDropdownElements={FILTERS_MAP.people}
        filtersActive={filtersActive}
      />
    </div>
  );
}

export default TeamMembers;
