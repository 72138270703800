import React from 'react'

import { Button } from 'antd';
import { ArrowLeftIcon } from 'assets/icons';
import { RenderIf } from 'components/components';
import { StepsProps } from 'types/componentTypes/StepsForm';
import { identity } from 'utils';
import StepItem from '../StepItem';

import './Steps.scss';

const Steps = React.memo((props: StepsProps) => {
  const {
    steps,
    backButtonText,
    selected,
    onSelect,
    onBack,
  } = props;

  return (
    <div className="steps">
      <RenderIf condition={identity.isTruthyString(backButtonText)}>
        <Button
          className="steps__back"
          onClick={onBack}
          icon={<ArrowLeftIcon />}
          type="ghost"
        >
          {`Back to ${backButtonText}`}
        </Button>
      </RenderIf>
      <div className="steps__items">
        {steps.map((item, idx) => (
          <StepItem
            key={item.title}
            number={idx + 1}
            selected={selected === idx}
            text={item.title}
            onClick={() => onSelect!(idx)}
            checked={selected > idx}
          />
        ))}
      </div>
    </div>
  )
});

export default Steps;
