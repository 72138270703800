import produce from 'immer';

import { PartnerType as Partner } from 'packages/partner_repository';
import {
  PartnersActionType,
  SetCurrentPartnerTabPayload,
  SetPartnerRequestPayload,
} from 'types/reduxTypes/ActionTypes/PartnersTypes';
import { defaultPartnersState } from 'redux/utils/defaultStates';
import { types } from 'redux/actions/partners';
import { DataPayload, IsLoadingPayload } from 'types/reduxTypes/ActionTypes';
import { PartnersStateType } from 'types/reduxTypes/StateTypes';
import { SetPartnerTeamMembersPayload } from 'types/partners/addPartner';

const defaultState: PartnersStateType = defaultPartnersState;

const handleSetPartners = (state: PartnersStateType, payload: DataPayload<Partner[]>) => {
  state.partners = payload.data;
  return state;
}

const handleSetCurrentPartnerTab = (state: PartnersStateType, payload: SetCurrentPartnerTabPayload) => {
  state.currentTab = payload.currentTab;
  state.filtersActive = state.currentTab !== 'all';
  return state;
}

const handleSetPartnerDetails = (state: PartnersStateType, payload: DataPayload<Partner>) => {
  state.details.partner = payload.data;
  return state;
}
const handleSetPartnerData = (state: PartnersStateType, payload: DataPayload<Partner>) => {
  state.partnerData = payload.data;
  return state;
}

const handleSetIsLoading = (state: PartnersStateType, payload: IsLoadingPayload) => {
  state.isLoading = payload.isLoading;
  return state;
}

const handleSetPartnerTeamMembers = (state: PartnersStateType, payload: SetPartnerTeamMembersPayload) => {
  state.details.members = payload.members;
  return state;
}

const handleSetPartnerRequestById = (state: PartnersStateType, payload: SetPartnerRequestPayload) => {
  state.details.request = payload.data;
  return state;
}
// eslint-disable-next-line default-param-last
export default produce((state: PartnersStateType = defaultState, action: PartnersActionType) => {
  const { type, payload } = action;
  switch (type) {
    case types.SET_PARTNER_DATA: {
      return handleSetPartnerData(state, <DataPayload<Partner>>payload);
    }
    case types.SET_ALL_PARTNERS: {
      return handleSetPartners(state, <DataPayload<Partner[]>>payload);
    }
    case types.SET_CURRENT_PARTNER_TAB: {
      return handleSetCurrentPartnerTab(state, <SetCurrentPartnerTabPayload>payload);
    }
    case types.SET_PARTNER_DETAILS: {
      return handleSetPartnerDetails(state, <DataPayload<Partner>>payload);
    }
    case types.SET_IS_PARTNER_LOADING: {
      return handleSetIsLoading(state, <IsLoadingPayload>payload);
    }
    case types.SET_PARTNER_TEAM_MEMBERS: {
      return handleSetPartnerTeamMembers(state, <SetPartnerTeamMembersPayload>payload);
    }
    case types.SET_PARTNER_REQUEST_BY_ID: {
      return handleSetPartnerRequestById(state, <SetPartnerRequestPayload>payload);
    }
    case types.RESET_PARTNER: {
      return defaultState;
    }
    default: {
      return state;
    }
  }
});
