import React, { useEffect, useState, useMemo } from 'react'
import {
  Form,
  RadioChangeEvent,
} from 'antd';
import moment, { Moment } from 'moment';
import { LabeledValue } from 'antd/lib/select';
import { RcFile } from 'antd/lib/upload';

import { resetFiltersEvent } from 'components/Events/components/FiltersDrawer/utils/constants';
import { UserType } from 'packages/user_repository/types/users';
import DrawerButton from 'components/components/Drawer/components/DrawerButton';
import { scrollToFirstError } from 'components/components/FormBuilder/utils/helpers';
import { AddEventDrawerProps } from 'types/events/eventsProps';
import DateTimeFromTo from 'components/components/DateTimeFromTo';
import { CreateEventPayload, EventCategory, EVENT_STATUS_TO_NUMBER } from 'packages/events_repository';
import identity from 'utils/identity';
import {
  addEventMiddleFields,
  addEventTopFields,
  EventMiddleFieldsParams,
  EventTopFieldsParams,
  FREQUENCY,
  frequencyOptions,
  getMonthlyOptions,
} from './utils/constants';
import Input from '../components/Input';
import TimeFromTo from './components/TimeFromTo';
import FormBuilder from '../components/FormBuilder';
import Row from '../components/FormBuilder/components/Row';
import RadioTags from '../components/Input/components/RadioTags';
import RenderIf from '../components/RenderIf';
import AddButtonDrawer from '../components/AddButtonDrawer';
import { addPeopleColumns } from '../utils/constants/columns';

import './AddEventDrawer.scss';

const AddEventDrawer = (props: AddEventDrawerProps) => {
  const [frequency, setFrequency] = useState(1);
  const [form] = Form.useForm();

  const { actions, eventCategories, users } = props;

  const onFrequencyChange = (e: RadioChangeEvent) => {
    setFrequency(e.target.value);
    form.setFieldValue('startingDate', undefined);
    form.setFieldValue('endingDate', undefined);
    form.setFieldValue('startingTime', undefined);
    form.setFieldValue('endingTime', undefined);
  }

  const onInitialDateChange = (date: Moment | null) => {
    form.setFieldValue('monthlyOccurance', getMonthlyOptions(date as Moment)[0]);
  }

  const onSubmit = (data: CreateEventPayload) => {
    const formData = {
      ...data,
      endingTime: moment(data?.endingTime).utc().format('YYYY-MM-DD HH:mm:ss'),
      startingTime: moment(data?.startingTime).utc().format('YYYY-MM-DD HH:mm:ss'),
      // Status 1 is planned, and status 2 is completed
      status: EVENT_STATUS_TO_NUMBER.upcoming,
    }
    actions.addEvent({ data: formData });
  }

  useEffect(() => {
    if (identity.isEmptyArray(eventCategories)) {
      actions.getEventCategories();
    }
  }, [eventCategories])

  useEffect(() => {
    if (identity.isEmptyArray(users)) {
      const query = {
        isPreload: true,
        userGroup: [1, 2, 3, 4],
      };
      actions.getUsers({ query })
    }
  }, [users])

  const evetCategoriesOptions: LabeledValue[] | undefined = useMemo(() => (
    eventCategories?.map((item: EventCategory) => ({ label: `${item.name || 'Unknown category'}`, value: item.id }))), [eventCategories]);

  const beforeLogoUpload = (file: RcFile) => {
    form.setFieldValue('image', file as Blob);
    return false;
  }
  const eventsTopFieldsData: EventTopFieldsParams = {
    eventCategories: evetCategoriesOptions!,
  };

  const eventsMiddleFieldsData: EventMiddleFieldsParams = {
    beforeLogoUpload,
  }

  return (
    <div className="add-event-drawer">
      <Form
        className="form-standalone"
        form={form}
        onFinish={onSubmit}
        validateTrigger="onSubmit"
        initialValues={{ frequency }}
        onFinishFailed={scrollToFirstError}
      >
        <FormBuilder formFragment fields={addEventTopFields(eventsTopFieldsData!)} />
        <Row oneColumn>
          <Input name="frequency" label="Frequency" layout="vertical" required>
            <RadioTags
              options={frequencyOptions}
              value={frequency}
              onChange={onFrequencyChange}
              defaultValue={frequencyOptions[0]}
            />
          </Input>
        </Row>
        <RenderIf condition={frequency === FREQUENCY.daily}>
          <TimeFromTo />
        </RenderIf>
        <RenderIf condition={frequency !== FREQUENCY.daily}>
          <DateTimeFromTo required onChange={onInitialDateChange} />
        </RenderIf>
        <Row oneColumn>
          <AddButtonDrawer
            form={form}
            name="user_id"
            drawerTitle="Invite people"
            columns={addPeopleColumns}
            buttonText="people"
            label="Invite people"
            dataSource={users}
            options={users.map(({ firstName, lastName, id }: UserType) => ({ label: `${firstName} ${lastName}`, value: id }))}
            rowKeyName="id"
            placeholder="people"
            eventName={resetFiltersEvent}
            filterBy="firstName"
          />
        </Row>
        <FormBuilder formFragment fields={addEventMiddleFields(eventsMiddleFieldsData)} />
        <DrawerButton>Add event</DrawerButton>
      </Form>
    </div>
  )
}

export default AddEventDrawer;
