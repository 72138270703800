import { connect } from 'react-redux';
import { bindActionCreators, Dispatch, Action } from 'redux';

import { setDrawerBody, setIsDrawerOpen } from 'redux/actions/workspace';
import { deleteDraftRequest, setRequestId, setPartnerRequestId } from 'redux/actions/request';
import { setPartnerRequestById } from 'redux/actions/partners';

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  actions: bindActionCreators({
    setIsDrawerOpen,
    setRequestId,
    setDrawerBody,
    setPartnerRequestId,
    deleteDraftRequest,
    setPartnerRequestById,
  }, dispatch),
});

export default connect(null, mapDispatchToProps);
