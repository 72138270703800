import { PayloadAction } from '@reduxjs/toolkit';
import {
  all, call, put, select, takeEvery, takeLatest,
} from 'redux-saga/effects';

import { staticParams } from 'components/Performance/utils/constants';
import { getStatusInParamsByRole } from 'components/Performance/utils/helpers';
import history from 'history_instance';
import { count, queryBuilder } from 'packages/http_client';
import {
  GetPerformanceParams, PerformanceTypePayload, REPORT_STATUS_TO_NUMBER,
} from 'packages/performance_repository';
import {
  createPerformanceReport,
  deletePerformanceReportById,
  getPerformanceReportById,
  getPerformanceRepostsList,
  updatePerformanceReportById,
} from 'packages/performance_repository/api';
import {
  getCounts,
  getPerformanceReports,
  removePerformanceReportById,
  resetAll,
  resetCounts,
  setActiveTab,
  setCountByStatus,
  setIsLoading,
  setPerformanceReportById,
  setPerformanceReports,
  types,
} from 'redux/actions/performance';
import { setIsDrawerLoading, setIsDrawerOpen } from 'redux/actions/workspace';
import { selectUser, selectUserNotice } from 'redux/selectors/auth';
import {
  selectActiveTab,
  selectLimit,
  selectOffset,
  selectPerformanceReportId,
} from 'redux/selectors/performance';
import showNotification from 'services/utils/showNotification';
import routes from 'routes';
import { UserType } from 'types/auth';
import { DataPayload, IdPayload } from 'types/reduxTypes/ActionTypes';
import { identity } from 'utils';
import { selectIsOpen } from 'redux/selectors/workspace';
import { resetUserNotice } from 'redux/actions/auth';
import { clearNoticeFromLocal } from 'packages/local_storage';
import { NoticeType } from 'packages/authentication_repository';

function * handleGetPerformanceReports(action: PayloadAction<GetPerformanceParams>) {
  yield put(setIsLoading({ isLoading: true }));

  const params: GetPerformanceParams = action.payload;

  const { error, httpStatus, data } = yield call(getPerformanceRepostsList, params);

  if (identity.isObjWithChildren(error)) {
    showNotification(error?.message, httpStatus);
  } else {
    yield put(setPerformanceReports({ data }))
  }

  yield put(setIsLoading({ isLoading: false }));
}

function * handleGetCounts(action: PayloadAction<GetPerformanceParams>) {
  yield put(setIsLoading({ isLoading: true }));

  const params = queryBuilder(action.payload)
    .select([count('id'), 'status'])
    .groupBy('status')
    .toObject();

  const { error, httpStatus, data } = yield call(getPerformanceRepostsList, params);

  if (identity.isObjWithChildren(error)) {
    showNotification(error?.message, true, httpStatus);
    yield put(setIsLoading({ isLoading: false }));
  }

  yield put(resetCounts());

  for (const item of data) {
    const payload = { count: item.id__count, status: item.status }
    yield put(setCountByStatus(payload));
  }

  const allCount: number = data.reduce((sum: number, item: { id__count: number }) => sum + item.id__count, 0);
  yield put(setCountByStatus({ count: allCount, status: 'All' }));

  yield put(setIsLoading({ isLoading: false }));
}

function * handleDeletePerformanceReportById(action: PayloadAction<IdPayload>) {
  yield put(setIsLoading({ isLoading: true }));
  const { id } = action.payload;

  const { error, httpStatus } = yield call(deletePerformanceReportById, id);

  if (identity.isObjWithChildren(error)) {
    showNotification(error?.message, true, httpStatus);
  } else {
    yield put(removePerformanceReportById({ id }))
  }

  yield put(setIsLoading({ isLoading: false }));
}

function * handleGetPerformanceReportsById(action: PayloadAction<IdPayload>) {
  yield put(setIsDrawerLoading({ isLoading: true }));
  yield put(setIsLoading({ isLoading: true }));

  const { id } = action.payload;

  const { error, httpStatus, data } = yield call(getPerformanceReportById, id);

  if (identity.isObjWithChildren(error)) {
    showNotification(error?.message, httpStatus);
  } else {
    yield put(setPerformanceReportById({ data }))
  }

  yield put(setIsDrawerLoading({ isLoading: false }));
  yield put(setIsLoading({ isLoading: false }));
}

function * handleUpdatePerformanceReportsById(action: PayloadAction<DataPayload<PerformanceTypePayload>>) {
  const isDrawerOpen: boolean = yield select(selectIsOpen);
  const activeTab:string = yield select(selectActiveTab);
  const user:UserType = yield select(selectUser);

  if (isDrawerOpen) {
    yield put(setIsDrawerLoading({ isLoading: true }));
  } else {
    yield put(setIsLoading({ isLoading: true }));
  }

  const [id, limit, offset]: number[] = yield all([
    select(selectPerformanceReportId),
    select(selectLimit),
    select(selectOffset),
  ])
  const { data } = action.payload;

  const { error, httpStatus } = yield call(updatePerformanceReportById, id, data);

  if (identity.isObjWithChildren(error)) {
    showNotification(error?.message, httpStatus);
  } else {
    const params: GetPerformanceParams = {
      ...staticParams,
      $limit: limit,
      $offset: offset,
      status: REPORT_STATUS_TO_NUMBER[activeTab!],
      status__in: getStatusInParamsByRole(user.userType),
      startup_id: Number(user.startupId) || undefined,
    }

    const countParams: GetPerformanceParams = {
      startup_id: Number(user.startupId) || undefined,
      status__in: getStatusInParamsByRole(user.userType),
    }

    if (isDrawerOpen) {
      yield put(getCounts(countParams));
      yield put(getPerformanceReports(params));
      yield put(setActiveTab({ data: 'All' }));
    } else {
      history.push(routes.performanceUrl);
    }
  }

  if (isDrawerOpen) {
    yield put(setIsDrawerLoading({ isLoading: false }));
    yield put(setIsDrawerOpen({ isDrawerOpen: false }));
  } else {
    yield put(setIsLoading({ isLoading: true }));
  }
}

function * handleCreatePerformanceReport(action: PayloadAction<DataPayload<PerformanceTypePayload>>) {
  yield put(setIsLoading({ isLoading: true }));
  const { data } = action.payload;

  const { error, httpStatus } = yield call(createPerformanceReport, data);

  if (identity.isObjWithChildren(error)) {
    showNotification(error?.message, true, httpStatus);
    yield put(setIsLoading({ isLoading: false }));
    return;
  }
  yield put(resetAll());
  const notice: NoticeType = yield select(selectUserNotice);

  if (identity.isTruthyString(notice?.level)) {
    yield put(resetUserNotice());
    clearNoticeFromLocal();
  }
  history.push(routes.performanceUrl);
  yield put(setIsLoading({ isLoading: false }));
}

export default function * performanceSaga() {
  yield all([
    takeLatest(types.UPDATE_PERFORMANCE_REPORT_BY_ID, handleUpdatePerformanceReportsById),
    takeLatest(types.GET_PERFORMANCE_REPORTS, handleGetPerformanceReports),
    takeLatest(types.GET_COUNTS_PERFORMANCE, handleGetCounts),
    takeEvery(types.DELETE_PERFORMANCE_REPORT_BY_ID, handleDeletePerformanceReportById),
    takeLatest(types.GET_PERFORMANCE_REPORT_BY_ID, handleGetPerformanceReportsById),
    takeEvery(types.CREATE_PERFORMANCE_REPORT, handleCreatePerformanceReport),
  ]);
}
