import React from 'react';

import './NoDataContainer.scss';

const NoDataContainer = () => (
  <div className="noDataContainer">
    <h3 className="noDataContainer__title">no data</h3>
  </div>
);

export default NoDataContainer;
