import { LabeledValue } from 'antd/lib/select';

import { getFormFieldBuilder } from 'components/components/FormBuilder/utils/FormFieldBuilder';
import { inputTypes } from 'components/components/FormBuilder/utils/constants';
import requiredValidation from '../../../../../../../../../utils/constants/validationRules';

export type ServiceSubCategoryParams = {
  categories: LabeledValue[],
}

export const categoryOptions: LabeledValue[] = [
  { value: 1, label: 'Main category' },
  { value: 2, label: 'Sub-category' },
];

export const AddNewSubCategoryFormFileds = ({ categories }: ServiceSubCategoryParams) => [
  {
    fields: [
      getFormFieldBuilder('serviceCategoryID', 'Choose parent category', inputTypes.select)
        .withOptions(categories)
        .withPlaceholder('Parent category')
        .required()
        .withRules([requiredValidation])
        .build(),
    ],
  },
  {
    fields: [
      getFormFieldBuilder('name', 'Name of the category')
        .withPlaceholder('eg. Name')
        .required()
        .withRules([requiredValidation])
        .build(),
    ],
  },
]
