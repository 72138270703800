import produce from 'immer';

import { ProgramType } from 'packages/cohort_repository';
import { DataPayload, IsLoadingPayload } from 'types/reduxTypes/ActionTypes';
import { SetActiveTabPayload, SetEmailTemplatesPayload, SettingsOverviewActionType } from 'types/reduxTypes/ActionTypes/SettingsOverviewTypes';
import { SettingsOverviewStateType } from 'types/reduxTypes/StateTypes';
import { StartupStatusType } from 'packages/startup_repository';
import { DataEntryPointType } from 'packages/settings_repository';
import { EmailTemplateType } from 'types/settingsOverview';
import { types } from '../../actions/settingsOverview';
import { settingsOverviewDefaultState } from '../../utils/defaultStates/SettingsOverviewDefaults';

const defaultState: SettingsOverviewStateType = settingsOverviewDefaultState;

const handleSetEmailTemplates = (state: SettingsOverviewStateType, payload: SetEmailTemplatesPayload) => {
  state.emailTemplates = payload.emailTemplates;
  return state;
}

const handleSetActiveTab = (state: SettingsOverviewStateType, payload: SetActiveTabPayload) => {
  state.activeTab = payload.tab;
  return state;
}

const handleSetActiveSubTab = (state: SettingsOverviewStateType, payload: SetActiveTabPayload) => {
  state.activeSubTab = payload.tab;
  return state;
}

const handleSetDeleteIds = (state: SettingsOverviewStateType, payload: DataPayload<number[]>) => {
  state.deleteIds = payload.data;
  return state;
}

const handleSetProgramTypes = (state: SettingsOverviewStateType, payload: DataPayload<ProgramType[]>) => {
  state.programTypes = payload.data;
  return state;
}

const handleSetIsLoading = (state: SettingsOverviewStateType, payload: IsLoadingPayload) => {
  state.isLoading = payload.isLoading;
  return state;
}

const handleSetStartupStages = (state: SettingsOverviewStateType, payload: DataPayload<StartupStatusType[]>) => {
  state.startupStages = payload.data;
  return state;
}

const handleSetEducation = (state: SettingsOverviewStateType, payload: DataPayload<DataEntryPointType[]>) => {
  state.education = payload.data;
  return state;
}

const handleSetTitles = (state: SettingsOverviewStateType, payload: DataPayload<DataEntryPointType[]>) => {
  state.titles = payload.data;
  return state;
}

const handleSetProgramTypeDetails = (state: SettingsOverviewStateType, payload: DataPayload<ProgramType>) => {
  state.details.programType = payload.data;
  return state;
}

const handleResetDetails = (state: SettingsOverviewStateType) => {
  state.details = {};
  return state;
}

const handleSetIncentiveTemplates = (state: SettingsOverviewStateType, payload: SetEmailTemplatesPayload) => {
  state.incentiveTemplates = payload.emailTemplates;
  return state;
}

const handleSetWorkOrderTemplates = (state: SettingsOverviewStateType, payload: SetEmailTemplatesPayload) => {
  state.workOrderTemplates = payload.emailTemplates;
  return state;
}

const handleSetTemplateDetail = (state: SettingsOverviewStateType, payload: DataPayload<EmailTemplateType>) => {
  state.details.template = payload.data;
  return state;
}

const handleSetDataEntryPointDetail = (state: SettingsOverviewStateType, payload: DataPayload<DataEntryPointType>) => {
  state.details.dataEntryPoint = payload.data;
  return state;
}

// eslint-disable-next-line default-param-last
export default produce((state: SettingsOverviewStateType = defaultState, action: SettingsOverviewActionType) => {
  const { type, payload } = action;
  switch (type) {
    case types.SET_DATA_ENTRY_POINT_DETAIL: {
      return handleSetDataEntryPointDetail(state, <DataPayload<DataEntryPointType>>payload);
    }
    case types.SET_TEMPLATE_DETAIL: {
      return handleSetTemplateDetail(state, <DataPayload<EmailTemplateType>>payload);
    }
    case types.SET_WORK_ORDER_TEMPLATES: {
      return handleSetWorkOrderTemplates(state, <SetEmailTemplatesPayload>payload);
    }
    case types.SET_INCENTIVE_TEMPLATES: {
      return handleSetIncentiveTemplates(state, <SetEmailTemplatesPayload>payload);
    }
    case types.SET_EMAIL_TEMPLATES: {
      return handleSetEmailTemplates(state, <SetEmailTemplatesPayload>payload);
    }
    case types.SET_ACTIVE_TAB_SETTINGS: {
      return handleSetActiveTab(state, <SetActiveTabPayload>payload);
    }
    case types.SET_ACTIVE_SUB_TAB_SETTINGS: {
      return handleSetActiveSubTab(state, <SetActiveTabPayload>payload);
    }
    case types.SET_DELETE_IDS: {
      return handleSetDeleteIds(state, <DataPayload<number[]>>payload);
    }
    case types.SET_PROGRAM_TYPES: {
      return handleSetProgramTypes(state, <DataPayload<ProgramType[]>>payload);
    }
    case types.SET_IS_LOADING: {
      return handleSetIsLoading(state, <IsLoadingPayload>payload);
    }
    case types.SET_PROGRAM_TYPE_DETAILS: {
      return handleSetProgramTypeDetails(state, <DataPayload<ProgramType>>payload);
    }
    case types.SET_STARTUP_STAGES: {
      return handleSetStartupStages(state, <DataPayload<StartupStatusType[]>>payload);
    }
    case types.SET_EDUCATION: {
      return handleSetEducation(state, <DataPayload<DataEntryPointType[]>>payload);
    }
    case types.SET_TITLES: {
      return handleSetTitles(state, <DataPayload<DataEntryPointType[]>>payload);
    }
    case types.RESET_SETTINGS_DETAILS: {
      return handleResetDetails(state);
    }
    case types.RESET_SO: {
      return defaultState;
    }
    default: {
      return state;
    }
  }
});
