export default class FormField {
  constructor(name: string, label: string, type?: string) {
    this.name = name;
    this.label = label;
    this.type = type;
  }

      name: string;

      label: string;

      type?: string;
}

export const createFormField = (name: string, label: string, type?: string) => new FormField(name, label, type);
