import produce from 'immer';

import getLabelValueObject from 'utils/helpers/getLabelValueObject';
import { InvolvementType } from 'packages/people_repository';
import { PeopleActionType, ActiveTabPayload, PeopleCountPayload } from 'types/reduxTypes/ActionTypes/PeopleTypes';
import { DataPayload, IsLoadingPayload } from 'types/reduxTypes/ActionTypes';
import { LabeledValue } from 'types';
import { MemberType } from 'types/memberDetails';
import { checkPeopleFiltersActive, identity } from 'utils';
import { PeopleStateType } from 'types/reduxTypes/StateTypes';
import { PeopleFilters } from 'types/people';
import { foundersDefaultState } from '../../utils';
import { types } from '../../actions/people';

const defaultState: PeopleStateType = foundersDefaultState;

const handleSetFounders = (state: PeopleStateType, payload: DataPayload<MemberType[]>) => {
  state.founders = payload.data;
  state.filtersActive = checkPeopleFiltersActive(state.filters);
  return state;
}

const handleSetTeamMembers = (state: PeopleStateType, payload: DataPayload<MemberType[]>) => {
  state.teamMembers = payload.data;
  state.filtersActive = checkPeopleFiltersActive(state.filters);
  return state;
}

const handleSetAllMembers = (state: PeopleStateType, payload: DataPayload<MemberType[]>) => {
  state.allMembers = payload.data;
  state.filtersActive = checkPeopleFiltersActive(state.filters);
  return state;
}

const handleSetActiveTab = (state: PeopleStateType, payload: ActiveTabPayload) => {
  state.activeTab = payload.activeTab;
  return state;
}

const handleSetPeopleCount = (state: PeopleStateType, payload: PeopleCountPayload) => {
  const { count } = state;
  if (identity.isZero(payload.founders) || identity.isTruthyNumber(payload.founders)) {
    count.founders = payload?.founders!;
  }
  if (identity.isZero(payload.teamMembers) || identity.isTruthyNumber(payload.teamMembers)) {
    count.teamMembers = payload?.teamMembers!;
  }
  if (identity.isZero(payload.all) || identity.isTruthyNumber(payload.all)) {
    count.all = payload?.all!;
  }
  state.count = count;
  return state;
}
const handleSetPeopleFilters = (state: PeopleStateType, payload: DataPayload<PeopleFilters>) => {
  state.filters = payload.data;
  state.filtersActive = checkPeopleFiltersActive(state.filters);
  return state;
}

const handleSetIsLoading = (state: PeopleStateType, payload: IsLoadingPayload) => {
  state.isLoading = payload.isLoading;
  return state;
}

const handleSetInvolvementTypes = (state: PeopleStateType, payload: DataPayload<InvolvementType[]>) => {
  state.involvementTypes = payload.data?.map(
    (involvementType) => getLabelValueObject(involvementType.name, involvementType.id),
  );
  return state;
}

const handleSetPeopleSkillsRoles = (state: PeopleStateType, payload: DataPayload<LabeledValue[]>, field: 'roles' | 'skills') => {
  state[field] = payload.data;
  return state;
}

// eslint-disable-next-line default-param-last
export default produce((state: PeopleStateType = defaultState, action: PeopleActionType) => {
  const { type, payload } = action;
  switch (type) {
    case types.SET_INVOLVEMENT_TYPES: {
      return handleSetInvolvementTypes(state, <DataPayload<InvolvementType[]>>payload);
    }
    case types.SET_FOUNDERS: {
      return handleSetFounders(state, <DataPayload<MemberType[]>>payload);
    }
    case types.SET_TEAM_MEMBERS: {
      return handleSetTeamMembers(state, <DataPayload<MemberType[]>>payload);
    }
    case types.SET_ACTIVE_TAB: {
      return handleSetActiveTab(state, <ActiveTabPayload>payload);
    }
    case types.SET_PEOPLE_COUNT: {
      return handleSetPeopleCount(state, <PeopleCountPayload>payload);
    }
    case types.SET_PEOPLE_IS_LOADING: {
      return handleSetIsLoading(state, <IsLoadingPayload>payload);
    }
    case types.SET_ALL_MEMBERS: {
      return handleSetAllMembers(state, <DataPayload<MemberType[]>>payload);
    }
    case types.SET_PEOPLE_SKILLS: {
      return handleSetPeopleSkillsRoles(state, <DataPayload<LabeledValue[]>>payload, 'skills');
    }
    case types.SET_PEOPLE_ROLES: {
      return handleSetPeopleSkillsRoles(state, <DataPayload<LabeledValue[]>>payload, 'roles');
    }
    case types.SET_PEOPLE_FILTERS: {
      return handleSetPeopleFilters(state, <DataPayload<PeopleFilters>>payload);
    }
    case types.RESET_PEOPLE: {
      return defaultState;
    }
    default: {
      return state;
    }
  }
});
