import { StartupType } from 'packages/startup_repository';
import { CohortStartupReviewDocumentType } from 'types/cohorts/cohortDetails';
import { identity } from 'utils';

export const getDocumentNameFromType = (type: CohortStartupReviewDocumentType): string => {
  switch (type) {
    case 'acceptanceLetter': {
      return 'Acceptance Letter';
    }
    case 'adgmLicense': {
      return 'ADGM License';
    }
    case 'incentiveAgreement': {
      return 'Incentive Agreement';
    }
    default: {
      return '';
    }
  }
}

export const getDocumentType = (startup: StartupType): CohortStartupReviewDocumentType => {
  const {
    acceptanceLetter,
    acceptanceLetterApprovedStatus,
    aDGMApprovedStatus,
    incentiveAgreementApprovedStatus,
    currentADGMLicenseID,
    currentIncentiveAgreementID,
  } = startup;
  let documentType: CohortStartupReviewDocumentType = '';
  if (identity.isTruthyString(acceptanceLetter) && !identity.isTruthyNumber(acceptanceLetterApprovedStatus)) {
    documentType = 'acceptanceLetter';
  } else if (!identity.isTruthyNumber(aDGMApprovedStatus) && identity.isTruthyNumber(currentADGMLicenseID)) {
    documentType = 'adgmLicense';
  } else if (!identity.isTruthyNumber(incentiveAgreementApprovedStatus)
    && identity.isTruthyNumber(currentIncentiveAgreementID)) {
    documentType = 'incentiveAgreement';
  }

  return documentType;
}
