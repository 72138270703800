import httpClient, {
  ApiResponseType,
  DeleteResourceSuccessResponseType,
  FORM_DATA_CONFIG,
  GetCountResponse,
  HttpRequestConfig, ParamsPayload, queryBuilder,
} from 'packages/http_client'
import {
  COHORT_API,
  PROGRAM_API,
  convertCreateCohortRequestPayload,
  convertEditCohortRequestPayload,
  convertGetCohortResponse,
  convertGetCohortsListResponse,
  convertProgramTypeBody,
} from './utils';
import {
  CohortType,
  CreateCohortApiResponseType,
  CreateCohortPayload,
  CreateProgramApiResponseType,
  CreateProgramPayload,
  CreateProgramType,
  GetCohortApiResponseType,
  GetCohortsCountResponseType,
  GetCohortsListApiResponseType,
  GetProgramByIdApiResponseType,
  GetProgramsApiResponseType,
  ParamsType,
  ProgramType,
  ProgramTypeParams,
  UpdateCohortApiResponseType,
} from './types';
import { identity } from '../../utils'

const createCohortApi = (payload: CreateCohortPayload) => {
  const url = COHORT_API;
  const requestPayload = convertCreateCohortRequestPayload(payload);
  return httpClient.post<ApiResponseType<CreateCohortApiResponseType>>(url, requestPayload, FORM_DATA_CONFIG);
};

const createProgramTypeApi = (payload: CreateProgramPayload) => {
  const url = PROGRAM_API;
  return httpClient.post<ApiResponseType<CreateProgramApiResponseType>>(url, payload);
};

const getCohortApi = (id: number, isPreload: boolean) => {
  const url = `${COHORT_API}/${id}`;
  const config: HttpRequestConfig = {
    params: {
      $preload: isPreload ? '1' : 0,
    },
  };
  return httpClient.get<ApiResponseType<GetCohortApiResponseType>>(url, config);
};

const getCohortsListApi = (queryParams: ParamsType = {}) => {
  const url = COHORT_API;
  const config: HttpRequestConfig = { params: queryParams };
  return httpClient.get<ApiResponseType<GetCohortsListApiResponseType | GetCohortsCountResponseType>>(url, config);
}

const getProgramsListApi = () => {
  const url = PROGRAM_API;
  return httpClient.get<ApiResponseType<GetProgramsApiResponseType>>(url);
};

const getProgramTypeByIdApi = (id: number) => {
  const url = `${PROGRAM_API}/${id}`;
  return httpClient.get<ApiResponseType<GetProgramByIdApiResponseType>>(url);
};

const updateProgramTypeByIdApi = (id: number, payload?: CreateProgramPayload) => {
  const url = `${PROGRAM_API}/${id}`;
  return httpClient.put<ApiResponseType<DeleteResourceSuccessResponseType>>(url, payload);
};

const deleteProgramTypeByIdApi = (id: number) => {
  const url = `${PROGRAM_API}/${id}`;
  return httpClient.delete<ApiResponseType<DeleteResourceSuccessResponseType>>(url);
};

const updateCohortApi = (id: number, payload: CohortType) => {
  const url = `${COHORT_API}/${id}`;
  const requestPayload = convertEditCohortRequestPayload(payload);
  return httpClient.put<ApiResponseType<UpdateCohortApiResponseType>>(url, requestPayload, FORM_DATA_CONFIG);
};

const deleteProgramTypesApi = (params?: ProgramTypeParams) => {
  const url = PROGRAM_API;
  const config = { params };
  return httpClient.delete<ApiResponseType<DeleteResourceSuccessResponseType>>(url, config);
}

export const createCohort = async (payload: CreateCohortPayload): Promise<ApiResponseType<number[]>> => {
  const response = await createCohortApi(payload);
  const { data, error, httpStatus } = response;
  return { error, httpStatus, data: data?.id };
};

export const getCohortsList = async (params: ParamsPayload): Promise<ApiResponseType<CohortType[]>> => {
  const queryParams = queryBuilder().limit(params.limit).offset(params.offset);
  if (params.isPreload) {
    queryParams.preload();
  }
  if (identity.isTruthyString(params.searchString)) {
    queryParams.searchQuery(params.searchString as string);
  }
  const response = await getCohortsListApi(queryParams.toObject());
  const { data, error, httpStatus } = response;
  return { error, httpStatus, data: convertGetCohortsListResponse(data as GetCohortsListApiResponseType) };
}

export const getProgramCohortsList = async (programId: number): Promise<ApiResponseType<CohortType[]>> => {
  const queryParams = { program_id: programId };
  const response = await getCohortsListApi(queryParams);
  const { data, error, httpStatus } = response;
  return { error, httpStatus, data: convertGetCohortsListResponse(data as GetCohortsListApiResponseType) };
};

export const createProgramType = async (payload: CreateProgramType) => {
  const response = await createProgramTypeApi(convertProgramTypeBody(payload));
  const { data, error, httpStatus } = response;
  return { error, httpStatus, data: data?.id[0] };
};

export const getCohortById = async (id: number, isPreload: boolean): Promise<ApiResponseType<CohortType>> => {
  const response = await getCohortApi(id, isPreload);
  const { data: cohort, error, httpStatus } = response;
  return { error, httpStatus, data: convertGetCohortResponse(cohort?.result) };
};

export const getProgramsList = async (): Promise<ApiResponseType<ProgramType[]>> => {
  const response = await getProgramsListApi();
  const { data, error, httpStatus } = response;
  return { error, httpStatus, data: data?.result };
};

export const getProgramTypeById = async (id: number): Promise<ApiResponseType<ProgramType>> => {
  const response = await getProgramTypeByIdApi(id);
  const { data, error, httpStatus } = response;
  return { error, httpStatus, data: data?.result };
}

export const updateCohort = async (id: number, payload: CohortType) => {
  const response = await updateCohortApi(id, payload);
  return response;
};

export const getCohortsCount = async (params: ParamsPayload): Promise<ApiResponseType<GetCountResponse[]>> => {
  const queryParams: ParamsType = { $f: params.f };
  if (identity.isTruthyString(params.searchString)) {
    queryParams.$q = params.searchString as string;
  }
  const response = await getCohortsListApi(queryParams);
  const { data, error, httpStatus } = response;
  return { error, httpStatus, data: data?.result as GetCountResponse[] };
}

export const deleteProgramTypeById = async (id: number) => {
  const response = await deleteProgramTypeByIdApi(id);
  const { error, httpStatus } = response;
  return { error, httpStatus };
}

export const updateProgramTypeById = async (id: number, payload: CreateProgramType) => {
  const response = await updateProgramTypeByIdApi(id, convertProgramTypeBody(payload));
  const { error, httpStatus } = response;
  return { error, httpStatus };
}

export const deleteProgramTypes = async (params?: ProgramTypeParams) => {
  const response = await deleteProgramTypesApi(params);
  const { error, httpStatus } = response;
  return { error, httpStatus };
}
