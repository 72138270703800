import React, { useEffect } from 'react';
import { Spin } from 'antd';

import { identity, isPartner } from 'utils';
import { RenderIf } from 'components/components';
import { ServiceRequestType } from 'packages/service_request_repository';
import { ServiceRequestsByCategoryProps } from 'types/requests';
import StaticNotification from '../../../components/Notification/StaticNotification';
import RequestRow from '../RequestRow';
import { isAll } from '../../utils';

const RequestsByCategory = (props: ServiceRequestsByCategoryProps) => {
  const {
    currentTab, isLoading, filteredRequests, userType,
  } = props;

  const showNotification = isAll(currentTab) && props.highlightRequest;

  useEffect(() => {
    const timer = setTimeout(() => {
      props.actions.removeHighlight();
    }, 10000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <Spin spinning={isLoading}>
      <div className="requests-by-category">
        <RenderIf condition={showNotification && !isPartner(userType)}>
          <StaticNotification
            type="success"
            message={(
              <span>
                Request to
                <b>
                  {` ${identity.isFullArray(filteredRequests) && identity.isObjWithChildren(filteredRequests[0].serviceRequest as ServiceRequestType)
                    ? (filteredRequests[0].serviceRequest as ServiceRequestType).serviceProvider?.name
                    : 'Unknown service provider'} `}
                </b>
                is successfully sent!
              </span>
            )}
            primaryButton={{
              onClick: () => null,
              text: 'View all services',
            }}
          />
        </RenderIf>
        <div className="requests-container">
          {identity.isFullArray(filteredRequests)
            ? filteredRequests.map((request, index) => (
              <RequestRow
                highlight={showNotification && filteredRequests[0].id === request.id}
                key={`request-${request.id ? request.id : index}`}
                request={request}
              />
            ))
            : `No ${isAll(currentTab) ? currentTab.replace(/-/g, ' ') : ''} requests.`}
        </div>
      </div>
    </Spin>
  );
}

export default RequestsByCategory;
