import React from 'react';

import SVGWrap from './SVGWrap';

const AdvisoryIcon = () => (
  <SVGWrap size={24} fill="none">
    <path d="M10.999 2H22.0004V15.0403H12.7688" strokeLinecap="square" strokeLinejoin="round" strokeWidth="1" />
    <path d="M6.07487 7.38619C7.56219 7.38619 8.7679 6.18045 8.7679 4.6931C8.7679 3.20574 7.56219 2 6.07487 2C4.58755 2 3.38184 3.20574 3.38184 4.6931C3.38184 6.18045 4.58755 7.38619 6.07487 7.38619Z" strokeLinecap="round" strokeWidth="1" />
    <path d="M15.0774 9.15381C15.0774 8.60152 14.6297 8.15381 14.0774 8.15381H6.07611H3.00098C2.44869 8.15381 2.00098 8.60152 2.00098 9.15381L2.00098 14.9316C2.00098 15.4366 2.41039 15.846 2.91543 15.846V15.846C3.38711 15.846 3.7814 16.2048 3.82582 16.6743L4.24393 21.094C4.29251 21.6075 4.72368 21.9998 5.23948 21.9998H6.9403C7.44441 21.9998 7.8697 21.6246 7.93251 21.1244L9.05426 12.1906C9.11707 11.6904 9.54236 11.3152 10.0465 11.3152H14.0774C14.6297 11.3152 15.0774 10.8675 15.0774 10.3152V9.15381Z" strokeLinecap="round" strokeWidth="1" />
  </SVGWrap>
);

export default AdvisoryIcon;
