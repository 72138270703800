import { createSelector } from 'reselect';
import { EventType } from 'packages/events_repository';
import { EventsStateType } from '../../../types/reduxTypes/StateTypes';
import { StateType } from '../../../types';

const selectEventsState = (state: StateType) => state.eventsReducer;

export const selectEventsCurrentTab = createSelector(
  selectEventsState,
  (state: EventsStateType) => state.currentTab,
);

export const selectEventsCurrentSubTab = createSelector(
  selectEventsState,
  (state: EventsStateType) => state.currentSubTab,
);

export const selectEventsResponseCount = createSelector(
  selectEventsState,
  (state: EventsStateType) => state.responseCount,
);

export const selectEventsCount = createSelector(
  selectEventsState,
  (state: EventsStateType) => state.count,
);

export const selectEvents = createSelector(
  selectEventsState,
  (state: EventsStateType) => state.events,
);

export const selectEventFilters = createSelector(
  selectEventsState,
  (state: EventsStateType) => state.filters,
);

export const selectIsLoading = createSelector(
  selectEventsState,
  (state: EventsStateType) => state.isLoading,
);

export const selectEventCategories = createSelector(
  selectEventsState,
  (state: EventsStateType) => state.eventCategories,
);

export const selectEventDetails = createSelector(
  selectEventsState,
  (state: EventsStateType) => state.event,
);

export const selectEventName = createSelector(
  selectEventDetails,
  (state: EventType) => state.name,
);

export const selectEventId = createSelector(
  selectEventsState,
  (state: EventsStateType) => state.id,
);

export const selectActiveEventDetailsTab = createSelector(
  selectEventsState,
  (state: EventsStateType) => state.details.activeTab,
);

export const selectEventAttendees = createSelector(
  selectEventsState,
  (state: EventsStateType) => state.details.eventAttendees,
);

export const selectEventRSVP = createSelector(
  selectEventsState,
  (state: EventsStateType) => state.details.eventRSVP,
);

export const selectEventFiltersActive = createSelector(
  selectEventsState,
  (state: EventsStateType) => state.filtersActive,
);
