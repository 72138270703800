/* eslint-disable @typescript-eslint/no-explicit-any */
import { RcFile } from 'antd/lib/upload';
import { LabeledValue } from 'antd/lib/select';

import { requiredValidation, emailValidation } from 'utils/constants/validationRules';
import { inputTypes } from '../../components/FormBuilder/utils/constants';
import { getFormFieldBuilder } from '../../components/FormBuilder/utils/FormFieldBuilder';

export type AddPartnerMemberFieldsCreatorParams = {
  beforeHeadshotUpload: (_file: RcFile) => boolean,
  partnerOptions: LabeledValue[],
}

export const addPartnerMemberFields = ({
  beforeHeadshotUpload,
  partnerOptions,
}: AddPartnerMemberFieldsCreatorParams) => [
  {
    fields: [
      getFormFieldBuilder('firstName', 'First name')
        .required()
        .withRules([requiredValidation])
        .build(),
      getFormFieldBuilder('lastName', 'Last name')
        .withRules([requiredValidation])
        .required()
        .build(),
    ],
  },
  {
    fields: [
      getFormFieldBuilder('email', 'Email', inputTypes.email)
        .withRules([requiredValidation, emailValidation])
        .required()
        .build(),
      getFormFieldBuilder('role', 'Role/Title')
        .withRules([requiredValidation])
        .required()
        .build(),
    ],
  },
  {
    fields: [
      getFormFieldBuilder('partnerID', 'Partner', inputTypes.select)
        .withRules([requiredValidation])
        .withOptions(partnerOptions)
        .required()
        .build(),
      getFormFieldBuilder('phoneNumber', 'Phone number', inputTypes.number)
        .build(),
    ],
  },
  {
    fields: [
      getFormFieldBuilder('photo', 'Headshot', inputTypes.image)
        .withBeforeUpload(beforeHeadshotUpload)
        .build(),
    ],
  },
];
