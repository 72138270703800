import { PayloadAction } from '@reduxjs/toolkit';

import { UnitType } from 'packages/service_repository';
import { PricingOption } from 'types/services';
import { DataPayload } from 'types/reduxTypes/ActionTypes';
import { types } from './actions';
import { defaultValues } from '../../../AddNewServiceDrawer';

type EditServiceState = {
  isActive?: boolean,
  offerDoc: string | Blob,
  priceUnit: UnitType,
  pricingOptions: PricingOption[],
  pricingOptionsError: boolean[],
}

const initialState: EditServiceState = {
  isActive: false,
  offerDoc: '',
  priceUnit: {
    id: 0,
    type: undefined,
    name: undefined,
  },
  pricingOptions: defaultValues.pricingOptions,
  pricingOptionsError: [false],
}

const handleSetPricingOptionsError = (state: EditServiceState, payload: DataPayload<boolean[]>) => ({
  ...state, pricingOptionsError: payload.data,
});

const handleSetPricingOptions = (state: EditServiceState, payload: DataPayload<PricingOption[]>) => ({
  ...state, pricingOptions: payload.data,
});

const handleSetPriceUnit = (state: EditServiceState, payload: DataPayload<UnitType>) => ({
  ...state, priceUnit: payload.data,
});

const handleSetOfferDoc = (state: EditServiceState, payload: DataPayload<string | Blob>) => ({
  ...state, offerDoc: payload.data,
});

const handleSetServiceActive = (state: EditServiceState, payload: DataPayload<boolean>) => ({
  ...state, isActive: payload.data,
});

const reducer = (
  // eslint-disable-next-line default-param-last
  state: EditServiceState = initialState,
  action: PayloadAction<DataPayload<string | Blob | UnitType | PricingOption[] | boolean[] | boolean>>,
) => {
  switch (action.type) {
    case types.SET_PRICING_OPTIONS_ERROR: {
      return handleSetPricingOptionsError(state, <DataPayload<boolean[]>>action.payload);
    }
    case types.SET_PRICING_OPTIONS: {
      return handleSetPricingOptions(state, <DataPayload<PricingOption[]>>action.payload);
    }
    case types.SET_PRICE_UNIT: {
      return handleSetPriceUnit(state, <DataPayload<UnitType>>action.payload);
    }
    case types.SET_OFFER_DOC: {
      return handleSetOfferDoc(state, <DataPayload<string | Blob>>action.payload);
    }
    case types.SET_SERVICE_ACTIVE: {
      return handleSetServiceActive(state, <DataPayload<boolean>> action.payload);
    }
    default: {
      return state;
    }
  }
}

export { initialState, reducer };
