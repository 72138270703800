import REQUEST_STATUS from 'utils/constants/requestStatusTypes';
import { API_ROOT } from '../../http_client';
import { PartnerTypeKeys } from '../types';

export const PARTNER_API = `${API_ROOT}/partner`;
export const PARTNER_REQUEST_API = `${API_ROOT}/partnerrequest`;
export const PARTNER_TEAM_MEMBERS_API = `${API_ROOT}/partnermember`;
export const PARTNER_EMAIL_API = 'api/partneremail/';

export const PARTNER_TYPE = {
  corporate: 'Corporate',
  government: 'Government',
  vc: 'VC',
}

export const NUMBER_TO_PARTNER_TYPE: {[key: number]: string} = {
  0: '-',
  1: PARTNER_TYPE.corporate,
  2: PARTNER_TYPE.government,
  3: PARTNER_TYPE.vc,
};

export const PARTNER_TYPE_TO_NUMBER: {[key: string]: PartnerTypeKeys} = {
  [NUMBER_TO_PARTNER_TYPE[0]]: 0,
  [NUMBER_TO_PARTNER_TYPE[1]]: 1,
  [NUMBER_TO_PARTNER_TYPE[2]]: 2,
  [NUMBER_TO_PARTNER_TYPE[3]]: 3,
}

export const PARTNER_REQUEST_STATUS_TYPES = {
  draft: 'draft',
  completed: 'completed',
  inProgress: 'in progress',
  rejected: 'rejected',
  pendingHUB71Approval: 'pending HUB71 approval',
};

export const PARTNER_REQUEST_STATUS = {
  1: 'draft',
  4: REQUEST_STATUS.pendingHUB71,
  5: REQUEST_STATUS.inProgress,
  6: REQUEST_STATUS.rejected,
  7: REQUEST_STATUS.delivered,
}
