import React, { useState } from 'react';
import { Select } from 'antd';

import { EventRSVPBeType, EventRSVPResponseKeys, EVENT_RSVP_RESPONSE } from 'packages/events_repository';
import { CheckmarkOutlineIcon, ChevronDownIcon, CloseIcon } from 'assets/icons';
import { EventRSVPButtonProps } from 'types/componentTypes';
import { isUpcoming } from '../../../../Events/utils/helpers';

const EventRSVPButton = (props: EventRSVPButtonProps) => {
  const { eventResponse, eventStatus, actions } = props;

  const rsvpOptions = [
    { value: 1, label: 'Attending' },
    { value: 2, label: EVENT_RSVP_RESPONSE['2'] },
    { value: 3, label: 'Not attending' },
  ];
  const [values, setValues] = useState<EventRSVPResponseKeys>();

  const onChange = (selectedValues: EventRSVPResponseKeys) => {
    setValues(selectedValues);

    const formData: EventRSVPBeType = {
      eventId: props.event.id,
      response: selectedValues,
      id: props.id,
    }
    actions.updateEventRSVP({ data: formData })
  }
  switch (eventResponse) {
    case 'interested': {
      return (
        <div className="event-response__interested">
          <CheckmarkOutlineIcon />
          <span>Interested</span>
        </div>
      );
    }
    case 'going': {
      return (
        <div className="event-response__going">
          <CheckmarkOutlineIcon />
          <span>Attending</span>
        </div>
      )
    }
    case 'not going': {
      return (
        <div className="event-response__not-going">
          <CloseIcon />
          <span>Not attending</span>
        </div>
      )
    }
    default: {
      if (isUpcoming(eventStatus)) {
        return (
          <Select
            suffixIcon={<ChevronDownIcon />}
            popupClassName="event-buttons__rsvp-dropdown"
            options={rsvpOptions}
            placeholder="RSVP"
            value={values}
            onChange={onChange}
          />
        )
      }
      return null;
    }
  }
}

export default EventRSVPButton;
