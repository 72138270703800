import React, { useEffect, useMemo } from 'react';
import { Spin } from 'antd';
import { useHistory } from 'react-router';

import { ProgramDetailsProps } from 'types/programDetails';
import { Modal, Overview, ExpandableTable } from 'components/components';
import { identity, getRowKey } from 'utils';
import { composeCohortsDetailsUrl } from 'routes/routeComposers';
import { cohortsColumns } from '../../../utils/constants/columns';
import ProgramOverview from './components/ProgramOverview';

import './ProgramDetails.scss';

const ProgramDetails = (props: ProgramDetailsProps) => {
  const {
    isModalOpen,
    isLoading,
    programCohorts,
    selectedCohort,
    toggleModal,
    actions: { getProgramCohorts },
  } = props;
  const history = useHistory();

  const id = useMemo(() => selectedCohort?.program?.id, [selectedCohort])

  useEffect(() => {
    if (identity.isTruthyNumber(id) && identity.isEmptyArray(programCohorts)) {
      getProgramCohorts({ id })
    }
  }, [id]);

  return (
    <Modal
      open={isModalOpen}
      onCancel={toggleModal}
      title={selectedCohort?.type || 'Cohort Program'}
      footer={null}
    >
      <Spin spinning={isLoading}>
        <ProgramOverview program={selectedCohort?.program} cohortCount={programCohorts.length} />
        <div className="program-details__about-overview">
          <Overview title={`About ${selectedCohort?.program?.name || 'Program'}`}>
            <p className="program-details__about-description">
              {
                selectedCohort?.program?.description || 'This program has no description about it'
              }
            </p>
          </Overview>
        </div>
        <div className="program-details__expandable_table">
          <ExpandableTable
            onRow={(record) => ({
              onClick: () => {
                history.push(composeCohortsDetailsUrl(record.id));
                toggleModal();
              },
            })}
            columns={cohortsColumns}
            dataSource={programCohorts}
            collapseHeader="Cohorts in this program"
            className="program-details__expandable_table"
            rowKey={getRowKey(programCohorts, 'cohort', 'id')}
          />
        </div>
      </Spin>
    </Modal>
  );
}

ProgramDetails.defaultProps = {
  programCohorts: [],
};

export default ProgramDetails;
