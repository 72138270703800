const navigateToRoute = (route: string) => {
  window.location.replace(route);
};

const openUrlInNewTab = (url: string) => {
  window.open(url, '_blank');
};

const refreshPage = () => {
  window.location.reload();
};

export {
  navigateToRoute,
  openUrlInNewTab,
  refreshPage,
}
