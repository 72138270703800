import { identity, getFullPhotoUrl } from 'utils';
import { REQUEST_STATUS_TO_NUMBER_MAP } from 'packages/service_request_repository';
import {
  PartnerType,
  PartnerTypePayload,
  CreatePartnerGetInTouchRequestPayload,
  CreatePartnerGetInTouchRequestType,
  PartnerRequest,
  PartnerRequestBe,
  PartnerMemberType,
  ResponsePartnerMemberType,
  ResponsePartnerType,
  CreatePartnerRequestType,
  CreatePartnerRequestPayload,
  UpdatePartnerRequest,
  CreateGetInTouchPartnerPayload,
  CreateGetInTouchPartnerRequestPayload,
  PartnerBeType,
} from '../types';
import { NUMBER_TO_PARTNER_TYPE, PARTNER_REQUEST_STATUS, PARTNER_TYPE_TO_NUMBER } from './constants';

export const convertPartnerRequestBody = (payload?: CreatePartnerRequestType):
    CreatePartnerRequestPayload => ({
  _application_date: payload?.application_date!,
  _created_by_id: payload?.created_by_id!,
  _startup_id: payload?.startup_id!,
  _status: REQUEST_STATUS_TO_NUMBER_MAP[payload?.status!],
  _partner_id: payload?.partner_id!,
  _message: payload?.message!,
  _subject: payload?.subject!,
  _pitch_deck: payload?.pitch_deck!,
  _video_url: payload?.video_url!,
  _acknowledged: payload?.acknowledged!,
});

export const convertPartnerRequest = (data?: PartnerRequestBe): PartnerRequest | undefined => {
  if (identity.isObjWithChildren(data)) {
    return ({
      id: data?.id!,
      partner: data?.partner!,
      status: PARTNER_REQUEST_STATUS[data?.status!],
      subject: data?.subject!,
      message: data?.message!,
      createdBy: data?.createdBy!,
      applicationDate: data?.applicationDate!,
      pitchDeck: data?.pitchDeck!,
      videoURL: data?.videoURL!,
      startup: data?.startup!,
      acknowledged: data?.acknowledged!,
    });
  }
}

export const convertPartnerTypeResponse = (response?: ResponsePartnerType)
  : PartnerType => ({
  id: response?.id!,
  name: response?.name!,
  description: response?.description!,
  contactFirstName: response?.contactFirstName!,
  contactLastName: response?.contactLastName!,
  contactEmail: response?.contactEmail!,
  contactPhone: response?.contactPhone!,
  contactRole: response?.contactRole!,
  active: response?.active!,
  location: response?.location!,
  logo: getFullPhotoUrl(response?.logo! as string),
  moU: response?.moU!,
  moUSigned: response?.moUSigned!,
  type: NUMBER_TO_PARTNER_TYPE[response?.type!],
  website: response?.website!,
});

export const convertPartnerTypeBody = (payload: PartnerType)
  : PartnerTypePayload => ({
  _active: payload.active,
  _contact_first_name: payload.contactFirstName,
  _contact_last_name: payload.contactLastName,
  _contact_email: payload.contactEmail,
  _contact_phone: payload.contactPhone,
  _contact_role: payload.contactRole,
  _description: payload.description,
  _location: payload.location,
  _logo: payload.logo,
  _mo_u: payload.moU,
  _mo_u_signed: payload.moUSigned,
  _name: payload.name,
  _type: PARTNER_TYPE_TO_NUMBER[payload.type],
  _website: payload.website,
});

export const convertPartnerRequestTypeBody = (payload: PartnerRequest)
  : UpdatePartnerRequest => ({
  _message: payload.message,
  _subject: payload.subject,
  _video_url: payload.videoURL,
  _pitch_deck: payload.pitchDeck,
  _acknowledged: payload.acknowledged,
});

export const convertPartners = (data?: ResponsePartnerType[]): PartnerType[] => {
  const partners: PartnerType[] = [];
  if (identity.isFullArray(data)) {
    for (const i in data!) {
      if (identity.isObjWithChildren(data[i])) {
        partners.push(convertPartnerTypeResponse(data[i])!);
      }
    }
  }
  return partners;
}

export const convertGetPartnerTeamMemberResponse = (response?: ResponsePartnerMemberType)
  : PartnerMemberType => ({
  id: response?.id!,
  firstName: response?.firstName!,
  lastName: response?.lastName!,
  email: response?.email!,
  active: response?.active!,
  jobTitle: response?.jobTitle!,
  photo: response?.photo!,
  phone: response?.phone!,
  mobile: response?.mobile!,
})

export const convertGetPartnerTeamMemberListResponse = (payload?: ResponsePartnerMemberType[]): PartnerMemberType[] => {
  if (identity.isFullArray(payload)) {
    return payload!.map((item) => convertGetPartnerTeamMemberResponse(item));
  }
  return [];
}

export const convertPartnerGetInTouchRequestBody = (payload: CreatePartnerGetInTouchRequestType):
  CreatePartnerGetInTouchRequestPayload => ({
  message: payload.message,
  attachment: payload.attachment,
  startup_id: payload.startupId,
});

export const convertCreateGetInTouchPartnerRequestPayload = (payload: CreateGetInTouchPartnerPayload):
  CreateGetInTouchPartnerRequestPayload => ({
  _message: payload.message!,
  _partner_id: payload.partnerID!,
  _pitch_deck: payload.pitchDeck!,
  _startup_id: payload.startupID!,
  _status: payload.status!,
  _video_url: payload.videoUrl!,
  _subject: payload.subject!,
});

export const convertCreatePartnerPayload = (payload: PartnerBeType): PartnerTypePayload => ({
  _active: payload?.active,
  _contact_email: payload?.contactEmail,
  _contact_first_name: payload?.contactFirstName,
  _contact_last_name: payload?.contactLastName,
  _contact_phone: payload?.contactPhone,
  _contact_role: payload?.contactRole,
  _description: payload?.description,
  _location: payload?.location,
  _logo: payload?.logo,
  _mo_u: payload?.moU,
  _mo_u_signed: payload?.moUSigned,
  _name: payload?.name,
  _type: payload?.type!,
  _website: payload?.website,
});
