import { connect } from 'react-redux';
import { Action, bindActionCreators, Dispatch } from 'redux';

import {
  selectIsDrawerFilterTableOpen,
} from '../../../../../redux/selectors/workspace';
import { setIsDrawerFilterTableOpen } from '../../../../../redux/actions/workspace';
import { StateType } from '../../../../../types';

const mapStateToProps = (state: StateType) => ({
  isFilterTableOpen: selectIsDrawerFilterTableOpen(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  actions: bindActionCreators({
    setIsDrawerFilterTableOpen,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps);
