import React, { useCallback } from 'react';
import { Form } from 'antd';

import DrawerButton from 'components/components/Drawer/components/DrawerButton';
import { FormBuilder } from 'components/components';
import { CreateProgramType } from 'packages/cohort_repository';
import { identity } from 'utils';
import { AddProgramTypeDrawerProps } from 'types/reduxTypes/ActionTypes/SettingsOverviewTypes';
import useSettingsForm from 'components/Settings/hooks/useSettingsForm';

import { addProgramTypeFields } from './utils/constants';

const AddProgramTypeDrawer = (props: AddProgramTypeDrawerProps) => {
  const { programTypeDetails, actions } = props;

  const { isEdit, form } = useSettingsForm({ defaultValues: programTypeDetails });

  const onSubmit = useCallback((data: CreateProgramType) => {
    if (!identity.isObjWithChildren(programTypeDetails)) {
      actions.createProgramType({ data });
      return;
    }
    actions.updateProgramTypeById({ data: { ...data, id: programTypeDetails?.id } });
  }, [programTypeDetails]);

  const btnText = isEdit ? 'Save changes' : 'Add program type';

  return (
    <div className="add-program-type">
      <Form form={form} onFinish={onSubmit} className="form-standalone" scrollToFirstError>
        <FormBuilder
          formFragment
          fields={addProgramTypeFields}
          oneColumn
        />
        <DrawerButton>{btnText}</DrawerButton>
      </Form>
    </div>
  )
}

export default AddProgramTypeDrawer;
