import React from 'react';

import SVGWrap from './SVGWrap';

const SearchIcon = () => (
  <SVGWrap>
    <path xmlns="http://www.w3.org/2000/svg" d="M14 13.3723L10.6479 10.0202C11.4534 9.05322 11.8551 7.81289 11.7693 6.55728C11.6836 5.30166 11.1171 4.12742 10.1877 3.27884C9.25827 2.43026 8.03746 1.97267 6.77925 2.00126C5.52103 2.02986 4.32227 2.54243 3.43235 3.43235C2.54243 4.32227 2.02986 5.52103 2.00126 6.77925C1.97267 8.03746 2.43026 9.25827 3.27884 10.1877C4.12742 11.1171 5.30166 11.6836 6.55728 11.7693C7.81289 11.8551 9.05322 11.4534 10.0202 10.6479L13.3723 14L14 13.3723ZM2.90343 6.8982C2.90343 6.10811 3.13772 5.33576 3.57667 4.67883C4.01562 4.02189 4.63952 3.50987 5.36947 3.20752C6.09942 2.90516 6.90263 2.82605 7.67754 2.98019C8.45245 3.13433 9.16424 3.5148 9.72292 4.07347C10.2816 4.63215 10.6621 5.34395 10.8162 6.11886C10.9703 6.89377 10.8912 7.69698 10.5889 8.42693C10.2865 9.15688 9.7745 9.78077 9.11757 10.2197C8.46063 10.6587 7.68829 10.893 6.8982 10.893C5.83908 10.8918 4.82368 10.4705 4.07477 9.72163C3.32586 8.97272 2.90461 7.95731 2.90343 6.8982Z" />
  </SVGWrap>
);

export default SearchIcon;
