import { DataPayload } from 'types/reduxTypes/ActionTypes';
import { ProfileType } from 'types/people';

export const types = {
  SET_LIMIT: 'SET_LIMIT',
  SET_CURRENT_PAGE: 'SET_CURRENT_PAGE',
  SET_OPEN_FILTERS: 'SET_OPEN_FILTERS',
  SET_PREVIOUS_TAB: 'SET_PREVIOUS_TAB',
  SET_FILTERS_CHANGED: 'SET_FILTERS_CHANGED',
};

export const setOpenFilters = (payload: DataPayload<boolean>) => ({
  type: types.SET_OPEN_FILTERS,
  payload,
});

export const setCurrentPage = (payload: DataPayload<number>) => ({
  type: types.SET_CURRENT_PAGE,
  payload,
});

export const setLimit = (payload: DataPayload<number>) => ({
  type: types.SET_LIMIT,
  payload,
});

export const setPreviousTab = (payload: DataPayload<ProfileType>) => ({
  type: types.SET_PREVIOUS_TAB,
  payload,
});

export const setFiltersChanged = (payload: DataPayload<boolean>) => ({
  type: types.SET_FILTERS_CHANGED,
  payload,
});
