import { DRAWERS } from 'utils';
import NoData from '../../components/NoData';
import ProgramTypeTable from '../components/ProgramTypeTable';
import { Education, StartupStage, Titles } from '../components/DataEntryPoints';
import MainCategoriesOverview from '../components/ServiceCategories/MainCategories';
import SubCategoriesOverview from '../components/ServiceCategories/Subcategories';
import TemplatesTable from '../components/TemplatesTable';

export const TABS = {
  templates: 'Templates',
  serviceCategories: 'Service categories',
  dataEntryPoints: 'Data entry points',
  programType: 'Program type',
}

export const tabsValues = Object.values(TABS);

export const TEMPLATE_TABS = {
  acceptanceLetter: 'Acceptance letter',
  incentiveAgreement: 'Incentive agreement',
  workOrder: 'Work order',
}

export const SERVICE_CATEGORIES_TABS = {
  mainCategories: 'Main categories',
  subCategories: 'Sub-categories',
}

export const DATA_ENTRY_POINTS_TABS = {
  education: 'Education',
  title: 'Title',
  startupStage: 'Startup stage',
  // funding: 'Funding',
}

export const TABS_SUBTABS_LOOKUP = {
  [TABS.templates]: Object.values(TEMPLATE_TABS),
  [TABS.serviceCategories]: Object.values(SERVICE_CATEGORIES_TABS),
  [TABS.dataEntryPoints]: Object.values(DATA_ENTRY_POINTS_TABS),
  [TABS.programType]: [],
}

/** Replace NoData with the proper component
 * that displays NoData component if there is no data
 */
export const TABS_COMPONENT_LOOKUP = {
  [TEMPLATE_TABS.acceptanceLetter]: {
    component: TemplatesTable,
    drawer: DRAWERS.addNewTemplate,
  },
  [TEMPLATE_TABS.incentiveAgreement]: {
    component: TemplatesTable,
    drawer: DRAWERS.addNewTemplate,
  },
  [TEMPLATE_TABS.workOrder]: {
    component: TemplatesTable,
    drawer: DRAWERS.addNewTemplate,
  },
  [SERVICE_CATEGORIES_TABS.mainCategories]: {
    component: MainCategoriesOverview,
    drawer: DRAWERS.addCategory,
  },
  [SERVICE_CATEGORIES_TABS.subCategories]: {
    component: SubCategoriesOverview,
    drawer: DRAWERS.addSubcategory,
  },
  [DATA_ENTRY_POINTS_TABS.education]: {
    component: Education,
    drawer: DRAWERS.addDataEntry,
  },
  [DATA_ENTRY_POINTS_TABS.title]: {
    component: Titles,
    drawer: DRAWERS.addDataEntry,
  },
  // [DATA_ENTRY_POINTS_TABS.funding]: {
  //   component: NoData,
  //   drawer: '',
  // },
  [DATA_ENTRY_POINTS_TABS.startupStage]: {
    component: StartupStage,
    drawer: DRAWERS.addDataEntry,
  },
  [TABS.programType]: {
    component: ProgramTypeTable,
    drawer: DRAWERS.addProgramType,
  },
  [TABS.templates]: {
    component: NoData,
    drawer: '',
  },
  [TABS.dataEntryPoints]: {
    component: NoData,
    drawer: '',
  },
  [TABS.serviceCategories]: {
    component: NoData,
    drawer: '',
  },
}
