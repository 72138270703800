import { TABS, TEMPLATE_TABS } from 'components/Settings/utils/constants';
import { SettingsOverviewStateType } from 'types/reduxTypes/StateTypes';
import { EmailTemplateType } from 'types/settingsOverview';

export const defaultTemplateState: EmailTemplateType = {
  id: 0,
  title: '',
  url: '',
  createdAt: '',
}

export const settingsOverviewDefaultState: SettingsOverviewStateType = {
  isLoading: false,
  emailTemplates: [],
  incentiveTemplates: [],
  workOrderTemplates: [],
  fileContent: '',
  activeTab: TABS.templates,
  activeSubTab: TEMPLATE_TABS.acceptanceLetter,
  deleteIds: [],
  programTypes: [],
  startupStages: [],
  education: [],
  titles: [],
  details: {},
};
