import { useReducer } from 'react';

import { DataPayload } from 'types/reduxTypes/ActionTypes';
import { StartupFiltersType } from 'types/startups';
import {
  setIndustries as setIndustriesAC,
  resetFilters as resetFiltersAC,
  setCohorts as setCohortsAC,
  setStage as setStageAC,
  setCapitalRaised as setCapitalRaisedAC,
  setIncentiveAmount as setIncentiveAmountAC,
  setFilters as setFiltersAC,
} from './actions';
import { reducer, initialState } from './reducer';

const useStartupsFiltersHook = () => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const setIndustries = (payload: DataPayload<number[]>) => {
    dispatch(setIndustriesAC(payload));
  }

  const setFilters = (payload: StartupFiltersType) => {
    dispatch(setFiltersAC(payload));
  }

  const setCohorts = (payload: DataPayload<number[]>) => {
    dispatch(setCohortsAC(payload));
  }

  const resetFilters = () => {
    dispatch(resetFiltersAC());
  }

  const setStage = (payload: DataPayload<number>) => {
    dispatch(setStageAC(payload));
  }

  const setCapitalRaised = (payload: DataPayload<string>) => {
    dispatch(setCapitalRaisedAC(payload));
  }

  const setIncentiveAmount = (payload: DataPayload<string>) => {
    dispatch(setIncentiveAmountAC(payload));
  }

  return {
    state,
    initialState,
    setIndustries,
    resetFilters,
    setCohorts,
    setStage,
    setCapitalRaised,
    setIncentiveAmount,
    setFilters,
  };
}

export default useStartupsFiltersHook;
