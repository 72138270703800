import React from 'react';

import { CardInfoRowContentProps } from '../../../../../types/componentTypes/CardProps';

const CardInfoRowContent = (props: CardInfoRowContentProps) => (
  <div className={props.isRounded! ? 'rounded' : ''}>
    <p className="description-title">{props.title.replace(/([A-Z])/g, ' $1').trim()}</p>
    <div className="category-section">
      <p className={props.isRounded! ? 'tag-sm--blue' : 'description'}>{props.description || 'Not Specified'}</p>
    </div>
  </div>
);

CardInfoRowContent.defaultProps = {
  isRounded: false,
}

export default CardInfoRowContent;
