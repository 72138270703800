import { PayloadAction } from '@reduxjs/toolkit';

import { RequestFiltersHookPayload, RequestFiltersState } from 'types/requests';
import { DataPayload } from 'types/reduxTypes/ActionTypes';
import { types } from './actions';
import { removeAddItemToArray } from '../../../../utils'

const initialState: RequestFiltersState = {
  from: 0,
  to: 0,
  category: [],
  serviceProviderId: [],
  partnerId: [],
  startupId: [],
  type: 'all',
}

const handleSetType = (state: RequestFiltersState, payload: DataPayload<'all' | 'partner' | 'service'>) => ({
  ...state, type: payload.data,
});

const handleSetAmount = (state: RequestFiltersState, payload: DataPayload<{ field: 'to' | 'from', value: number }>) => ({
  ...state, [payload.data.field]: payload.data.value,
});

const handleSetServiceProviderId = (state: RequestFiltersState, payload: DataPayload<number[]>) => {
  const serviceProviderId = payload.data;
  return { ...state, serviceProviderId };
}

const handleSetCategory = (state: RequestFiltersState, payload: DataPayload<number[]>) => {
  const category = removeAddItemToArray(state.category, payload.data[0]) as number[];
  return { ...state, category };
}

const handleSetPartners = (state: RequestFiltersState, payload: DataPayload<number[]>) => {
  const partnerId = payload.data;
  return { ...state, partnerId };
}

const handleSetAllFilters = (state: RequestFiltersState, payload: DataPayload<RequestFiltersState>) => payload.data;

const handleSetStartups = (state: RequestFiltersState, payload: DataPayload<number[]>) => {
  const startupId = payload.data;
  return { ...state, startupId };
}

// eslint-disable-next-line default-param-last
const reducer = (state: RequestFiltersState = initialState, action: PayloadAction<RequestFiltersHookPayload>) => {
  switch (action.type) {
    case types.SET_TYPE: {
      return handleSetType(state, <DataPayload<'all' | 'partner' | 'service'>>action.payload);
    }
    case types.SET_AMOUNT: {
      return handleSetAmount(state, <DataPayload<{ field: 'to' | 'from', value: number }>>action.payload);
    }
    case types.SET_SERVICE_PROVIDERS: {
      return handleSetServiceProviderId(state, <DataPayload<number[]>>action.payload);
    }
    case types.SET_CATEGORY: {
      return handleSetCategory(state, <DataPayload<number[]>>action.payload);
    }
    case types.SET_PARTNERS: {
      return handleSetPartners(state, <DataPayload<number[]>>action.payload);
    }
    case types.SET_STARTUPS: {
      return handleSetStartups(state, <DataPayload<number[]>>action.payload);
    }
    case types.SET_ALL_FILTERS: {
      return handleSetAllFilters(state, <DataPayload<RequestFiltersState>>action.payload);
    }
    case types.RESET_FILTERS: {
      return initialState;
    }
    default: {
      return state;
    }
  }
}

export { reducer, initialState };
