import React from 'react';
import { Button } from 'antd';
import { useHistory } from 'react-router-dom';

import { ButtonWithActionProps } from 'types/componentTypes';
import { identity } from 'utils';
import IconButton from '../IconButton';

const ButtonsWithActions = (props: ButtonWithActionProps) => {
  const { buttonTitle, buttons, isEditButton } = props;
  const history = useHistory();

  if (identity.isFullArray(buttons)) {
    return (
      <div className="buttons-container">
        {buttons?.map((button) => (
          <IconButton
            key={button.key}
            icon={button.icon}
            onClick={button.onClick}
          />
        ))}
      </div>
    )
  }

  return (
    <Button
      className="secondary-button md"
      onClick={() => history.push(props.link!)}
      disabled={props.disableEditButton!}
    >
      {isEditButton ? `Edit ${buttonTitle}` : `${buttonTitle}`}
    </Button>
  );
};

ButtonsWithActions.defaultProps = {
  buttons: [],
  link: '',
  isEditButton: false,
  disableEditButton: false,
}

export default ButtonsWithActions;
