import { connect } from 'react-redux';
import { bindActionCreators, Dispatch, Action } from 'redux';

import { StateType } from 'types';
import {
  selectIsDrawerFilterTableOpen,
  selectNationalities,
  selectStartups,
} from 'redux/selectors/workspace';
import {
  selectPeopleFilters,
  selectPeopleRoles,
  selectPeopleSkills,
} from 'redux/selectors/people';
import { selectUserType } from 'redux/selectors/auth';
import { getNationalities, getStartups } from 'redux/actions/workspace';
import { getPeopleRoles, getPeopleSkills, setPeopleFilters } from 'redux/actions/people';

const mapStateToProps = (state: StateType) => ({
  userType: selectUserType(state),
  isFilterTableOpen: selectIsDrawerFilterTableOpen(state),
  startups: selectStartups(state),
  nationalities: selectNationalities(state),
  roles: selectPeopleRoles(state),
  skills: selectPeopleSkills(state),
  activeFilters: selectPeopleFilters(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  actions: bindActionCreators({
    getStartups,
    getNationalities,
    getPeopleRoles,
    getPeopleSkills,
    setPeopleFilters,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps);
