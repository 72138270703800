import React from 'react';
import classnames from 'classnames';

import { OverviewDataColumnProps } from '../../../../../types/programDetails/overviewDataColumn';

import './OverviewDataColumn.scss';

const OverviewDataColumn = (props: OverviewDataColumnProps) => {
  const { label, text, invert } = props;

  return (
    <div className={classnames('overview-data-column', { 'overview-data-column__invert': invert })}>
      <p className="overview-data-label">{label}</p>
      <p className="overview-data-text">{text}</p>
    </div>
  )
};

OverviewDataColumn.defaultProps = { invert: false };

export default OverviewDataColumn;
