import { connect } from 'react-redux';
import { Action, bindActionCreators, Dispatch } from 'redux';

import {
  getNotifications, setNextPage, getNewNotificationsCount, resetNotifications,
} from 'redux/actions/notification';
import { selectUserId } from 'redux/selectors/auth';
import { selectActivities, selectSortBy } from '../../redux/selectors/activities';
import { selectAllFetched, selectIsLoading, selectNotificationsCount } from '../../redux/selectors/notifications';
import { StateType } from '../../types';

const mapStateToProps = (state: StateType) => ({
  activities: selectActivities(state),
  notificationsCount: selectNotificationsCount(state),
  isLoading: selectIsLoading(state),
  sortBy: selectSortBy(state),
  userId: selectUserId(state),
  allFetched: selectAllFetched(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  actions: bindActionCreators({
    setNextPage,
    getNotifications,
    getNewNotificationsCount,
    resetNotifications,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps);
