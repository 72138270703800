import { PayloadAction } from '@reduxjs/toolkit';

import { DataPayload } from 'types/reduxTypes/ActionTypes';
import { ServicesFiltersType, ServicesOverviewHookType } from 'types/services';
import config from 'assets/config';
import { types } from './actions';

const initialState: ServicesOverviewHookType = {
  filters: {},
  serviceProviderFilter: undefined,
  activeSpFilters: undefined,
  serviceProvidersFiltersChanged: true,
  serviceProviderName: '',
  spLimit: config.TABLE_DEFAULT_LIMIT,
  spCurrentPage: 1,
}

const handleSetFilters = (state: ServicesOverviewHookType, payload: ServicesFiltersType) => ({
  ...state, filters: payload,
});

const handleSetServiceProviderFilter = (
  state: ServicesOverviewHookType,
  payload: DataPayload<string | number | undefined>,
) => ({
  ...state, serviceProviderFilter: payload.data,
});

const handleSetActiveSpFilters = (state: ServicesOverviewHookType, payload: DataPayload<number[] | undefined>) => ({
  ...state, activeSpFilters: payload.data,
});

const handleSetServiceProvidersFiltersChanged = (state: ServicesOverviewHookType, payload: DataPayload<boolean>) => ({
  ...state, serviceProvidersFiltersChanged: payload.data,
});

const handleSetServiceProviderName = (state: ServicesOverviewHookType, payload: DataPayload<string>) => ({
  ...state, serviceProviderName: payload.data,
});

const handleSetSpLimit = (state: ServicesOverviewHookType, payload: DataPayload<number>) => ({
  ...state, spLimit: payload.data,
});

const handleSetSpCurrentPage = (state: ServicesOverviewHookType, payload: DataPayload<number>) => ({
  ...state, spCurrentPage: payload.data,
});

const reducer = (
  // eslint-disable-next-line default-param-last
  state: ServicesOverviewHookType = initialState,
  action: PayloadAction<ServicesFiltersType | DataPayload<boolean | number | undefined | number[] | string>>,
): ServicesOverviewHookType => {
  switch (action.type) {
    case types.SET_FILTERS: {
      return handleSetFilters(state, <ServicesFiltersType>action.payload);
    }
    case types.SET_SERVICE_PROVIDER_FILTERS: {
      return handleSetServiceProviderFilter(state, <DataPayload<string | number | undefined>>action.payload);
    }
    case types.SET_ACTIVE_SP_FILTERS: {
      return handleSetActiveSpFilters(state, <DataPayload<number[] | undefined>>action.payload);
    }
    case types.SET_SERVICE_PROVIDER_FILTERS_CHANGED: {
      return handleSetServiceProvidersFiltersChanged(state, <DataPayload<boolean>>action.payload);
    }
    case types.SET_SERVICE_PROVIDER_NAME: {
      return handleSetServiceProviderName(state, <DataPayload<string>>action.payload);
    }
    case types.SET_SP_LIMIT: {
      return handleSetSpLimit(state, <DataPayload<number>>action.payload);
    }
    case types.SET_SP_CURRENT_PAGE: {
      return handleSetSpCurrentPage(state, <DataPayload<number>>action.payload);
    }
    default: {
      return state;
    }
  }
}

export { initialState, reducer };
