import { ServiceFiltersType } from 'types/services';
import { DataPayload } from 'types/reduxTypes/ActionTypes';

export const types = {
  SET_FILTERS: 'SET_FILTERS',
  SET_SERVICE_PROVIDER_FILTERS: 'SET_SERVICE_PROVIDER_FILTERS',
  SET_ACTIVE_SP_FILTERS: 'SET_ACTIVE_SP_FILTERS',
  SET_SERVICE_PROVIDER_NAME: 'SET_SERVICE_PROVIDER_NAME',
  SET_SERVICE_PROVIDER_FILTERS_CHANGED: 'SET_SERVICE_PROVIDER_FILTERS_CHANGED',
  SET_SP_LIMIT: 'SET_SP_LIMIT',
  SET_SP_CURRENT_PAGE: 'SET_SP_CURRENT_PAGE',
}

export const setFilters = (payload: ServiceFiltersType) => ({
  type: types.SET_FILTERS,
  payload,
});

export const setServiceProviderFilter = (payload: DataPayload<string | number | undefined>) => ({
  type: types.SET_SERVICE_PROVIDER_FILTERS,
  payload,
});

export const setActiveSpFilters = (payload: DataPayload<number[] | undefined>) => ({
  type: types.SET_ACTIVE_SP_FILTERS,
  payload,
});

export const setServiceProvidersFiltersChanged = (payload: DataPayload<boolean>) => ({
  type: types.SET_SERVICE_PROVIDER_FILTERS_CHANGED,
  payload,
});

export const setServiceProviderName = (payload: DataPayload<string>) => ({
  type: types.SET_SERVICE_PROVIDER_NAME,
  payload,
});

export const setSpLimit = (payload: DataPayload<number>) => ({
  type: types.SET_SP_LIMIT,
  payload,
});

export const setSpCurrentPage = (payload: DataPayload<number>) => ({
  type: types.SET_SP_CURRENT_PAGE,
  payload,
});
