import { connect } from 'react-redux';
import { Action, bindActionCreators, Dispatch } from 'redux';

import { StateType } from 'types';
import { getWallet, getWalletTransactions } from 'redux/actions/wallet';
import {
  selectIsLoading,
  selectWallet,
  selectWalletTransactions,
  selectWalletCount,
} from 'redux/selectors/wallet';
import { selectUserStartupId, selectUserType } from 'redux/selectors/auth';
import { selectIsRowTableLayout } from 'redux/selectors/workspace';
import { setTableLayout } from 'redux/actions/workspace';

const mapStateToProps = (state: StateType) => ({
  isLoading: selectIsLoading(state),
  startupId: selectUserStartupId(state),
  userType: selectUserType(state),
  wallet: selectWallet(state),
  walletTransactions: selectWalletTransactions(state),
  isRowTableLayout: selectIsRowTableLayout(state),
  count: selectWalletCount(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  actions: bindActionCreators({
    getWallet,
    getWalletTransactions,
    setTableLayout,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps);
