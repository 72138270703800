import { connect } from 'react-redux';
import { bindActionCreators, Dispatch, Action } from 'redux';

import { StateType } from 'types';
import { selectStartupStages } from 'redux/selectors/settingsOverview';
import { getStartupStages, deleteStartupStages, setDataEntryPointDetail } from 'redux/actions/settingsOverview';
import { setDrawerBody, setIsDrawerOpen } from 'redux/actions/workspace';

const mapStateToProps = (state: StateType) => ({
  stages: selectStartupStages(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  actions: bindActionCreators({
    getStartupStages,
    deleteStartupStages,
    setDrawerBody,
    setIsDrawerOpen,
    setDataEntryPointDetail,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps);
