import httpClient, {
  ApiResponseType, HttpRequestConfig,
} from 'packages/http_client';
import {
  GetNotificationsApiResponse,
  NotificationBody,
  UpdateNotificationApiResponseType,
  GetNotificationsParams,
  NotificationType,
  NotificationsParams,
} from './types';
import {
  convertNotificationsList,
  convertToNotificationBody,
  NOTIFICATION_API,
} from './utils';

const getNotificationsApi = (params?: GetNotificationsParams) => {
  const url = NOTIFICATION_API;
  const config: HttpRequestConfig = { params }
  return httpClient.get<ApiResponseType<GetNotificationsApiResponse>>(url, config);
};

const updateNotificationByIdApi = (id: number, payload: NotificationBody) => {
  const url = `${NOTIFICATION_API}/${id}`;
  return httpClient.put<ApiResponseType<UpdateNotificationApiResponseType>>(url, payload);
};

const updateNotificationsApi = (payload: Partial<NotificationBody>, params?: NotificationsParams) => {
  const url = `${NOTIFICATION_API}`;
  const config = { params };
  return httpClient.put<ApiResponseType<UpdateNotificationApiResponseType>>(url, payload, config);
};

export const getNotifications = async (params: GetNotificationsParams) => {
  const response = await getNotificationsApi(params);
  const { error, httpStatus, data } = response;
  return { error, httpStatus, data: convertNotificationsList(data?.result) };
};

export const updateNotificationById = async (id: number, payload: NotificationType) => {
  const response = await updateNotificationByIdApi(id, convertToNotificationBody(payload));
  const { error, httpStatus } = response;
  return { error, httpStatus };
};

export const updateNotifications = async (payload: Partial<NotificationType>, params?: NotificationsParams) => {
  const response = await updateNotificationsApi(convertToNotificationBody(payload), params);
  const { error, httpStatus } = response;
  return { error, httpStatus };
};
