import React from 'react';

import SVGWrap from './SVGWrap';

const AuditAndAccountingIcon = () => (
  <SVGWrap size={24} fill="none">
    <path d="M9.09846 13.5319H20.7046C20.8201 13.5319 20.9135 13.6253 20.9135 13.7408V19.5441C20.9135 19.6596 20.8201 19.7531 20.7046 19.7531H9.09846C8.98298 19.7531 8.88955 19.6596 8.88955 19.5441V13.7408C8.88955 13.6253 8.98298 13.5319 9.09846 13.5319Z" strokeWidth="1" />
    <path d="M7.35753 16.4336H3.80025L5.18428 15.0495L4.8889 14.7535L3 16.6425L4.8889 18.532L5.18428 18.2361L3.80025 16.852H7.35753V16.4336Z" strokeWidth="1" />
    <path d="M16.6425 7.5661H20.2003L18.8157 8.95019L19.1117 9.24558L21 7.35718L19.1117 5.46821L18.8157 5.7636L20.2003 7.14827H16.6425V7.5661Z" strokeWidth="1" />
    <path d="M3.20891 4.50049H14.8151C14.9306 4.50049 15.024 4.59392 15.024 4.70941V10.5127C15.024 10.6282 14.9306 10.7216 14.8151 10.7216H3.20891C3.09343 10.7216 3 10.6282 3 10.5127V4.70941C3 4.59392 3.09343 4.50049 3.20891 4.50049Z" strokeWidth="1" />
  </SVGWrap>
);

export default AuditAndAccountingIcon;
