import { connect } from 'react-redux';
import { Action, bindActionCreators, Dispatch } from 'redux';

import { StateType } from '../../../../../../types'
import { selectServiceDetails } from '../../../../../../redux/selectors/serviceProviders';
import {
  createRequestedService,
  setRequestedServiceStatus,
} from '../../../../../../redux/actions/serviceProviders';

const mapStateToProps = (state: StateType) => ({
  selectedService: selectServiceDetails(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  actions: bindActionCreators({
    createRequestedService,
    setRequestedServiceStatus,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps);
