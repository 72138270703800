import React from 'react';

import SVGWrap from './SVGWrap';

const ChevronSortIcon = () => (
  <SVGWrap>
    <path d="M8.6001 14L5.1001 10.5L5.8051 9.795L8.6001 12.585L11.3951 9.795L12.1001 10.5L8.6001 14Z" />
    <path d="M8.6001 2L12.1001 5.5L11.3951 6.205L8.6001 3.415L5.8051 6.205L5.1001 5.5L8.6001 2Z" />
  </SVGWrap>
);

export default ChevronSortIcon;
