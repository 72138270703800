import { connect } from 'react-redux';
import { Action, bindActionCreators, Dispatch } from 'redux';

import { StateType } from '../../types';
import { getStartupsByOwnerId } from '../../redux/actions/startups';
import { selectCohortData, selectStartups, selectDocumentReviewRequests } from '../../redux/selectors/cohort';
import { getCohortById, getDocumentReviewRequests } from '../../redux/actions/cohort';
import { selectUserType } from '../../redux/selectors/auth';

const mapStateToProps = (state: StateType) => ({
  startups: selectStartups(state),
  selectedCohort: selectCohortData(state),
  startupsToReview: selectDocumentReviewRequests(state),
  userType: selectUserType(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  actions: bindActionCreators({
    getStartupsByOwnerId,
    getCohortById,
    getDocumentReviewRequests,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps);
