import { connect } from 'react-redux';
import { Action, bindActionCreators, Dispatch } from 'redux';

import { StateType } from 'types';
import {
  selectDestroyDrawerOnClose,
  selectIsLoading,
  selectIsOpen,
  selectIsDrawerFilterTableOpen,
  selectDrawerBody,
} from 'redux/selectors/workspace';
import { setIsDrawerOpen, setDestroyDrawerOnClose } from 'redux/actions/workspace';
import { setShowInvoiceFields } from 'redux/actions/request';
import { selectShowInvoiceFields } from 'redux/selectors/request';

const mapStateToProps = (state: StateType) => ({
  isLoading: selectIsLoading(state),
  isFilterTableOpen: selectIsDrawerFilterTableOpen(state),
  isOpen: selectIsOpen(state),
  destroyDrawerOnClose: selectDestroyDrawerOnClose(state),
  drawerBody: selectDrawerBody(state),
  showInvoiceFields: selectShowInvoiceFields(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  actions: bindActionCreators({
    setIsDrawerOpen,
    setDestroyDrawerOnClose,
    setShowInvoiceFields,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps);
