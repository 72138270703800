import { IsLoadingPayload } from 'types/reduxTypes/ActionTypes';
import {
  GetAddPeopleOptionsPayload,
  SetCheckedPeoplePayload,
  SetFiltersPayload,
} from 'types/reduxTypes/ActionTypes/ActivitiesTypes';

export const types = {
  GET_ACTIVITIES: 'GET_ACTIVITIES',
  SET_ACTIVITIES_IS_LOADING: 'SET_ACTIVITIES_IS_LOADING',
  SET_ACTIVITIES_FILTERS: 'SET_ACTIVITIES_FILTERS',
  GET_ADD_PEOPLE_OPTIONS: 'GET_ADD_PEOPLE_OPTIONS',
  SET_CHECKED_PEOPLE: 'SET_CHECKED_PEOPLE',
  RESET_ACTIVITIES_PAGINATION: 'RESET_ACTIVITIES_PAGINATION',
  RESET_ACTIVITIES: 'RESET_ACTIVITIES',
};

export const resetActivities = (payload: object = {}) => ({
  type: types.RESET_ACTIVITIES,
  payload,
});

export const getActivities = (payload: object = {}) => ({
  type: types.GET_ACTIVITIES,
  payload,
});

export const setIsLoading = (payload: IsLoadingPayload) => ({
  type: types.SET_ACTIVITIES_IS_LOADING,
  payload,
});

export const setFilters = (payload: SetFiltersPayload) => ({
  type: types.SET_ACTIVITIES_FILTERS,
  payload,
});

export const getAddPeopleOptions = (payload: GetAddPeopleOptionsPayload) => ({
  type: types.GET_ADD_PEOPLE_OPTIONS,
  payload,
});

export const setCheckedPeople = (payload: SetCheckedPeoplePayload) => ({
  type: types.SET_CHECKED_PEOPLE,
  payload,
});

export const resetActivitiesPagination = (payload: object = {}) => ({
  type: types.RESET_ACTIVITIES_PAGINATION,
  payload,
});
