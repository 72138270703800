import { connect } from 'react-redux';
import { bindActionCreators, Dispatch, Action } from 'redux';

import { selectServicesBySp, selectUnsentRequests } from 'redux/selectors/request';
import { StateType } from '../../../../../../types';
import { getServicesBySPId } from '../../../../../../redux/actions/request';

const mapStateToProps = (state: StateType) => ({
  services: selectServicesBySp(state),
  unsentRequests: selectUnsentRequests(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  actions: bindActionCreators({ getServicesBySPId }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps);
