import React from 'react';

import { DataColumnProps } from 'types/wallet';

const DataColumn = (props: DataColumnProps) => {
  const { heading, value } = props;

  return (
    <div className="data__col">
      <p className="data__col__heading">{heading}</p>
      <p className="data__col__value">{value}</p>
    </div>
  );
}

export default DataColumn;
