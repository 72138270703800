import { connect } from 'react-redux';
import { bindActionCreators, Dispatch, Action } from 'redux';

import {
  selectActiveEventDetailsTab,
  selectEventAttendees,
  selectEventDetails,
  selectEventId,
  selectEventRSVP,
  selectIsLoading,
} from 'redux/selectors/events';
import { StateType } from 'types';
import {
  getEventById, deleteEventById, setActiveTab, getEventAttendeesByEventId, setEventId, getEventRSVP,
} from 'redux/actions/events';
import { selectUserType } from 'redux/selectors/auth';
import { setDrawerBody, setIsDrawerOpen } from 'redux/actions/workspace';

const mapStateToProps = (state: StateType) => ({
  event: selectEventDetails(state),
  id: selectEventId(state),
  userType: selectUserType(state),
  activeTab: selectActiveEventDetailsTab(state),
  eventAttendees: selectEventAttendees(state),
  isLoading: selectIsLoading(state),
  eventRVSP: selectEventRSVP(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  actions: bindActionCreators({
    getEventById,
    deleteEventById,
    setActiveTab,
    getEventAttendeesByEventId,
    setEventId,
    getEventRSVP,
    setDrawerBody,
    setIsDrawerOpen,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps);
