import { identity } from 'utils';
import { NotificationBody, NotificationType, ResponseNotificationType } from '../types/notification';
import { NOTIFICATION_TYPE_TO_NUMBER, NUMBER_TO_NOTIFICATION_TYPE } from './constants';

export const convertToNotificationBody = (notification: Partial<NotificationType>)
: NotificationBody => ({
  _date_time: notification.dateTime,
  _header: notification.header,
  _item_pk: notification.itemPK,
  _item_type: NOTIFICATION_TYPE_TO_NUMBER[notification?.itemType!],
  _seen: Number(notification.seen),
  _startup: notification.startup,
  _startup_id: notification.startupId,
  _text: notification.text,
  _user_id: notification.userId,
});

export const convertNotificationResponse = (response: ResponseNotificationType)
: NotificationType => ({
  dateTime: response.dateTime,
  header: response.header,
  id: response.id,
  itemPK: response.itemPK,
  itemType: NUMBER_TO_NOTIFICATION_TYPE[response.itemType],
  seen: response.seen,
  startup: response.startup,
  startupId: response.startupID,
  text: response.text,
  user: response.user,
  userId: response.userID,
});

export const convertNotificationsList = (data?: ResponseNotificationType[]) => {
  if (identity.isNotArray(data)) {
    return [];
  }

  return data?.map((item) => convertNotificationResponse(item));
}
