import React from 'react'
import { TimePicker } from 'antd';

import Row from 'components/components/FormBuilder/components/Row';
import requiredValidation, { isEndingBeforeStartTimeRule, isStartAfterEndingTimeRule } from 'utils/constants/validationRules';
import Input from 'components/components/Input';

const TimeFromTo = () => (
  <Row>
    <Input name="startingTime" layout="vertical" label="Starting at" rules={[requiredValidation, isStartAfterEndingTimeRule]}>
      <TimePicker format="HH:mm" showSecond={false} minuteStep={15} />
    </Input>
    <Input name="endingTime" layout="vertical" label="Ending at" rules={[isEndingBeforeStartTimeRule]}>
      <TimePicker format="HH:mm" showSecond={false} minuteStep={15} />
    </Input>
  </Row>
)

export default TimeFromTo
