import { connect } from 'react-redux';
import { Dispatch, Action, bindActionCreators } from 'redux';

import { selectIsAuthenticated, selectIsLoading } from '../../redux/selectors/auth';
import { StateType } from '../../types';
import { login } from '../../redux/actions/auth';

const mapStateToProps = (state: StateType) => ({
  isLoading: selectIsLoading(state),
  isAuthenticated: selectIsAuthenticated(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  actions: bindActionCreators({
    login,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps);
