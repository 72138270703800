import { AddEmployeeFieldsCreatorParams, AddMemberFieldsCreatorParams } from 'types/people';
import { genderOptions } from 'utils/constants/inputFieldValues';
import { requiredValidation, emailValidation, positiveNumbersOnly } from 'utils/constants/validationRules';
import { inputTypes } from '../../components/FormBuilder/utils/constants';
import { createFormField } from '../../components/FormBuilder/utils/FormField';
import { getFormFieldBuilder } from '../../components/FormBuilder/utils/FormFieldBuilder';

export const MEMBER_TYPES = {
  startupMember: 'Startup member',
  startupFounder: 'Startup founder',
  employee: 'Employee',
}

export const memberTypeOptions = [
  {
    label: MEMBER_TYPES.startupMember,
    value: MEMBER_TYPES.startupMember,
  },
  {
    label: MEMBER_TYPES.startupFounder,
    value: MEMBER_TYPES.startupFounder,
  },
  {
    value: MEMBER_TYPES.employee,
    label: 'HUB71 employee',
  },
];

export const permissionsOptions = [
  {
    label: 'Dashboard',
    value: 'Dashboard',
  },
  {
    label: 'People',
    value: 'People',
  },
  {
    label: 'Startups',
    value: 'Startups',
  },
  {
    label: 'Services',
    value: 'Services',
  },
  {
    label: 'Events',
    value: 'Events',
  },
];

export const defaultValues = {
  [MEMBER_TYPES.employee]: {
    memberType: MEMBER_TYPES.employee,
    firstName: '',
    lastName: '',
    email: '',
    designation: '',
    title: '',
    employeeCode: '',
    permissions: [],
    profileImage: undefined,
  },
  [MEMBER_TYPES.startupMember]: {
    memberType: MEMBER_TYPES.startupMember,
    firstName: '',
    lastName: '',
    email: '',
    gender: 'Male',
    profileImage: undefined,
    skills: [],
    hobbies: [],
    jobFunctions: [],
    startupId: [],
  },
  [MEMBER_TYPES.startupFounder]: {
    memberType: MEMBER_TYPES.startupFounder,
    firstName: '',
    lastName: '',
    email: '',
    gender: 'Male',
    profileImage: undefined,
    skills: [],
    hobbies: [],
    jobFunctions: [],
    startupId: [],
  },
}

export const addEmployeeFields = ({ beforeHeadshotUpload }: AddEmployeeFieldsCreatorParams) => [
  {
    fields: [
      getFormFieldBuilder('firstName', 'First name')
        .required()
        .withRules([requiredValidation])
        .build(),
      getFormFieldBuilder('lastName', 'Last name')
        .withRules([requiredValidation])
        .required()
        .build(),
    ],
  },
  {
    fields: [
      getFormFieldBuilder('email', 'Email', inputTypes.email)
        .withRules([requiredValidation, emailValidation])
        .required()
        .build(),
      getFormFieldBuilder('jobTitle', 'Role/Title')
        .withRules([requiredValidation])
        .required()
        .build(),
    ],
  },
  {
    fields: [
      getFormFieldBuilder('headshot', 'Headshot', inputTypes.image)
        .withBeforeUpload(beforeHeadshotUpload)
        .withRules([requiredValidation])
        .required()
        .build(),
    ],
  },
];

export const addMemberFields = ({
  involvementTypes,
  nationalityOptions,
  beforeHeadshotUpload,
  startupDrawerProps,
  isFounder,
}: AddMemberFieldsCreatorParams) => [
  {
    fields: [
      getFormFieldBuilder('firstName', 'First name')
        .withRules([requiredValidation])
        .required()
        .build(),
      getFormFieldBuilder('lastName', 'Last name')
        .required()
        .withRules([requiredValidation])
        .build(),
    ],
  },
  {
    fields: [
      getFormFieldBuilder('jobTitle', 'Role/Title')
        .withRules([requiredValidation])
        .required()
        .build(),
      getFormFieldBuilder('startupId', 'Startup', 'selectDrawer')
        .withRules([requiredValidation])
        .withProps(startupDrawerProps)
        .withPlaceholder('startup')
        .required()
        .build(),
    ],
  },
  {
    fields:[
      getFormFieldBuilder('nationalityId', 'Nationality', inputTypes.selectWithSearch)
        .withOptions(nationalityOptions)
        .build(),
      getFormFieldBuilder('phoneNumber', 'Mobile number', inputTypes.number)
        .min(0)
        .withRules([positiveNumbersOnly])
        .build(),
    ],
  },
  {
    fields: [
      getFormFieldBuilder('email', 'Email')
        .required()
        .withRules([requiredValidation, emailValidation])
        .build(),
      isFounder
        ? getFormFieldBuilder('whatsAppNumber', 'WhatsApp number', inputTypes.number)
          .withRules([requiredValidation])
          .min(0)
          .withRules([positiveNumbersOnly])
          .required()
          .build()
        : getFormFieldBuilder('whatsAppNumber', 'WhatsApp number', inputTypes.number)
          .min(0)
          .withRules([positiveNumbersOnly])
          .build(),
    ],
  },
  {
    fields: [
      getFormFieldBuilder('dateOfBirth', 'Date of birth', inputTypes.datetime)
        .required()
        .withRules([requiredValidation])
        .build(),
      getFormFieldBuilder('gender', 'Gender', inputTypes.select)
        .required()
        .withPlaceholder('Select gender')
        .withRules([requiredValidation])
        .withOptions(genderOptions)
        .build(),
    ],
  },
  {
    fields: [
      getFormFieldBuilder('headshot', 'Headshot', inputTypes.image)
        .required()
        .withRules([requiredValidation])
        .withBeforeUpload(beforeHeadshotUpload)
        .build(),
    ],
  },
  {
    fields: [
      getFormFieldBuilder('involvementId', 'Involvement', inputTypes.select)
        .required()
        .withRules([requiredValidation])
        .withOptions(involvementTypes)
        .build(),
    ],
  },
  {
    fields: [
      getFormFieldBuilder('bio', 'Biography', inputTypes.textArea)
        .withRows(3)
        .build(),
    ],
  },
  {
    fields: [
      createFormField('skills', 'Skills', inputTypes.addButton),
    ],
  },
  {
    fields: [
      createFormField('jobFunctions', 'Job functions', inputTypes.addButton),

    ],
  },
  {
    fields:  [
      createFormField('hobbies', 'Hobbies', inputTypes.addButton),
    ],
  },
];
