import { connect } from 'react-redux';
import { Action, bindActionCreators, Dispatch } from 'redux';

import { createProgramType, updateProgramTypeById } from 'redux/actions/settingsOverview';
import { selectProgramTypeDetails } from 'redux/selectors/settingsOverview';
import { StateType } from 'types';

const mapStateToProps = (state: StateType) => ({
  programTypeDetails: selectProgramTypeDetails(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  actions: bindActionCreators({
    createProgramType,
    updateProgramTypeById,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps);
