import React from 'react';

import SVGWrap from './SVGWrap';

const PeopleIcon = () => (
  <SVGWrap>
    <path d="M13 7H12V8H13C13.3977 8.00044 13.779 8.15861 14.0602 8.43982C14.3414 8.72103 14.4996 9.10231 14.5 9.5V11.5H15.5V9.5C15.4992 8.83719 15.2356 8.20176 14.7669 7.73308C14.2982 7.26441 13.6628 7.00077 13 7Z" />
    <path d="M12 2C12.2967 2 12.5867 2.08797 12.8334 2.2528C13.08 2.41762 13.2723 2.65189 13.3858 2.92597C13.4994 3.20006 13.5291 3.50166 13.4712 3.79264C13.4133 4.08361 13.2704 4.35088 13.0607 4.56066C12.8509 4.77044 12.5836 4.9133 12.2926 4.97118C12.0017 5.02906 11.7001 4.99935 11.426 4.88582C11.1519 4.77229 10.9176 4.58003 10.7528 4.33336C10.588 4.08668 10.5 3.79667 10.5 3.5C10.5 3.10218 10.658 2.72064 10.9393 2.43934C11.2206 2.15804 11.6022 2 12 2ZM12 1C11.5055 1 11.0222 1.14662 10.6111 1.42133C10.2 1.69603 9.87952 2.08648 9.6903 2.54329C9.50108 3.00011 9.45157 3.50277 9.54804 3.98773C9.6445 4.47268 9.8826 4.91814 10.2322 5.26777C10.5819 5.6174 11.0273 5.8555 11.5123 5.95196C11.9972 6.04843 12.4999 5.99892 12.9567 5.8097C13.4135 5.62048 13.804 5.30005 14.0787 4.88893C14.3534 4.4778 14.5 3.99445 14.5 3.5C14.5 2.83696 14.2366 2.20107 13.7678 1.73223C13.2989 1.26339 12.663 1 12 1Z" />
    <path d="M11.5 15H10.5V14C10.4996 13.6023 10.3414 13.221 10.0602 12.9398C9.77897 12.6586 9.39769 12.5004 9 12.5H7C6.60231 12.5004 6.22103 12.6586 5.93982 12.9398C5.65861 13.221 5.50044 13.6023 5.5 14V15H4.5V14C4.50078 13.3372 4.76442 12.7018 5.2331 12.2331C5.70177 11.7644 6.3372 11.5008 7 11.5H9C9.6628 11.5008 10.2982 11.7644 10.7669 12.2331C11.2356 12.7018 11.4992 13.3372 11.5 14V15Z" />
    <path d="M8 6.5C8.29667 6.5 8.58668 6.58797 8.83335 6.7528C9.08003 6.91762 9.27229 7.15189 9.38582 7.42597C9.49935 7.70006 9.52906 8.00166 9.47118 8.29264C9.4133 8.58361 9.27044 8.85088 9.06066 9.06066C8.85088 9.27044 8.58361 9.4133 8.29264 9.47118C8.00166 9.52906 7.70006 9.49935 7.42597 9.38582C7.15189 9.27229 6.91762 9.08003 6.7528 8.83335C6.58797 8.58668 6.5 8.29667 6.5 8C6.5 7.60218 6.65804 7.22064 6.93934 6.93934C7.22064 6.65804 7.60218 6.5 8 6.5ZM8 5.5C7.50555 5.5 7.0222 5.64662 6.61107 5.92133C6.19995 6.19603 5.87952 6.58648 5.6903 7.04329C5.50108 7.50011 5.45157 8.00277 5.54804 8.48773C5.6445 8.97268 5.8826 9.41814 6.23223 9.76777C6.58186 10.1174 7.02732 10.3555 7.51227 10.452C7.99723 10.5484 8.49989 10.4989 8.95671 10.3097C9.41352 10.1205 9.80397 9.80005 10.0787 9.38893C10.3534 8.9778 10.5 8.49445 10.5 8C10.5 7.33696 10.2366 6.70107 9.76777 6.23223C9.29893 5.76339 8.66304 5.5 8 5.5Z" />
    <path d="M4 7H3C2.3372 7.00078 1.70177 7.26442 1.2331 7.7331C0.764423 8.20177 0.500781 8.8372 0.5 9.5V11.5H1.5V9.5C1.50044 9.10231 1.65861 8.72103 1.93982 8.43982C2.22103 8.15861 2.60231 8.00044 3 8H4V7Z" />
    <path d="M4 2C4.29667 2 4.58668 2.08797 4.83336 2.2528C5.08003 2.41762 5.27229 2.65189 5.38582 2.92597C5.49935 3.20006 5.52906 3.50166 5.47118 3.79264C5.4133 4.08361 5.27044 4.35088 5.06066 4.56066C4.85088 4.77044 4.58361 4.9133 4.29264 4.97118C4.00166 5.02906 3.70006 4.99935 3.42597 4.88582C3.15189 4.77229 2.91762 4.58003 2.7528 4.33336C2.58797 4.08668 2.5 3.79667 2.5 3.5C2.5 3.10218 2.65804 2.72064 2.93934 2.43934C3.22064 2.15804 3.60218 2 4 2ZM4 1C3.50555 1 3.0222 1.14662 2.61107 1.42133C2.19995 1.69603 1.87952 2.08648 1.6903 2.54329C1.50108 3.00011 1.45157 3.50277 1.54804 3.98773C1.6445 4.47268 1.8826 4.91814 2.23223 5.26777C2.58186 5.6174 3.02732 5.8555 3.51227 5.95196C3.99723 6.04843 4.49989 5.99892 4.95671 5.8097C5.41352 5.62048 5.80397 5.30005 6.07867 4.88893C6.35338 4.4778 6.5 3.99445 6.5 3.5C6.5 2.83696 6.23661 2.20107 5.76777 1.73223C5.29893 1.26339 4.66304 1 4 1Z" />
  </SVGWrap>
);

export default PeopleIcon;
