/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react';
import { Button } from 'antd';

import { ServicesOverviewTabsProps } from 'types/services';
import FilterTabButton from './components/FilterButton';
import RenderIf from '../RenderIf';
import { ServicesDropdownFilters } from '../../ServicesAndServiceProviders';

import './FiltersWithIcons.scss';

const FiltersWithIcons = (props: ServicesOverviewTabsProps) => {
  const { categories, icons, displayFilters } = props;
  const [showDropdown, setShowDropdown] = useState<boolean>(false);

  return (
    <div className="filters-with-icons-container">
      <div className="filters-overview">
        <FilterTabButton
          setCurrentServicesTab={props.setCurrentServicesTab}
          currentTab={props.currentTab}
          setFilters={props.setFilters}
          datasource={categories}
          icons={icons}
          activeFilters={props.activeFilters}
          setFilter={props.setFilter}
        />
      </div>
      <RenderIf condition={displayFilters}>
        <div className="filters-overview__filter-button">
          <RenderIf condition={props.filterType !== 'startup'}>
            <RenderIf condition={!showDropdown}>
              <Button className="secondary-button" onClick={() => setShowDropdown(true)}>Filter</Button>
            </RenderIf>
            <RenderIf condition={showDropdown}>
              <ServicesDropdownFilters setShowDropdown={setShowDropdown} />
            </RenderIf>
          </RenderIf>
        </div>
      </RenderIf>
    </div>
  );
}
FiltersWithIcons.defaultProps = {
  displayFilters: false,
  filterType: 'startup',
  filtersActive: false,
}
export default FiltersWithIcons;
