import React from 'react';

import { StatusType } from '../../../../../../../types/requests';
import identity from '../../../../../../../utils/identity';
import { getStatusName } from '../../../../../utils/helpers';
import RenderIf from '../../../../../../components/RenderIf';

export const getSystemMessageFormat = (name: string, company: string, reason: string, markedAs: StatusType) => (
  <span>
    <b>{`${name} `}</b>
    from
    <b>{` ${company} `}</b>
    {`has marked this request as ${getStatusName(markedAs)} `}
    <RenderIf condition={identity.isTruthyString(reason)}>
      <span>
        {`with explanation: \n "${reason}"`}
      </span>
    </RenderIf>
  </span>
);

export default null;
