import { useEffect, useState } from 'react'

const useDebounceSearch = (delayInMs: number, onSearch: Function) => {
  const [search, setSearch] = useState('');

  useEffect(() => {
    const timeout = setTimeout(() => onSearch(search), delayInMs);
    return () => clearTimeout(timeout);
  }, [search]);

  return { search, setSearch };
}

export default useDebounceSearch;
