import React from 'react';

import SVGWrap from './SVGWrap';

const CohortsIcon = () => (
  <SVGWrap>
    <path d="M10 7H14C14.2651 6.9997 14.5193 6.89424 14.7068 6.70677C14.8942 6.5193 14.9997 6.26512 15 6V2C14.9997 1.73488 14.8942 1.4807 14.7068 1.29323C14.5193 1.10576 14.2651 1.0003 14 1H10C9.73488 1.0003 9.4807 1.10576 9.29323 1.29323C9.10576 1.4807 9.0003 1.73488 9 2H4.5C4.23488 2.0003 3.9807 2.10576 3.79323 2.29323C3.60576 2.4807 3.5003 2.73488 3.5 3V5H2C1.73488 5.0003 1.4807 5.10576 1.29323 5.29323C1.10576 5.4807 1.0003 5.73488 1 6V10C1.0003 10.2651 1.10576 10.5193 1.29323 10.7068C1.4807 10.8942 1.73488 10.9997 2 11H3V13C3.0003 13.2651 3.10576 13.5193 3.29323 13.7068C3.4807 13.8942 3.73488 13.9997 4 14H9C9.0003 14.2651 9.10576 14.5193 9.29323 14.7068C9.4807 14.8942 9.73488 14.9997 10 15H14C14.2651 14.9997 14.5193 14.8942 14.7068 14.7068C14.8942 14.5193 14.9997 14.2651 15 14V10C14.9997 9.73488 14.8942 9.4807 14.7068 9.29323C14.5193 9.10576 14.2651 9.0003 14 9H10C9.73488 9.0003 9.4807 9.10576 9.29323 9.29323C9.10576 9.4807 9.0003 9.73488 9 10V13H4V11H6C6.26512 10.9997 6.5193 10.8942 6.70677 10.7068C6.89424 10.5193 6.9997 10.2651 7 10V6C6.9997 5.73488 6.89424 5.4807 6.70677 5.29323C6.5193 5.10576 6.26512 5.0003 6 5H4.5V3H9V6C9.0003 6.26512 9.10576 6.5193 9.29323 6.70677C9.4807 6.89424 9.73488 6.9997 10 7ZM10 14V12H14V14H10ZM14 10V11H10V10H14ZM2 10V8H6V10H2ZM6.00025 7H2V6H6L6.00025 7ZM10 6V4H14V6H10ZM14 2V3H10V2H14Z" />
  </SVGWrap>
);

export default CohortsIcon;
