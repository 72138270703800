import React, { useEffect, useState } from 'react';
import classNames from 'classnames';

import { RenderIf, ButtonWithPrompt, Input } from 'components/components';
import { StatusType } from 'types/requests';
import { REQUEST_STATUS } from 'utils/constants/requestStatusTypes';
import { PartnerRequestDetailsStatusProps } from 'types/componentTypes/Drawer';
import { isPartner } from 'utils';
import {
  getStatusName,
  isInProgress,
} from '../../../../../../RequestsOverview/utils/helpers';
import { STATUS_COLOR_CLASS } from '../../../../../../RequestsOverview/components/RequestDetailsDrawer/utils/constants';
import {
  PARTNER_STATUS_OPTIONS_MAP,
  StatusOptionType,
  inProgressStatusOption,
  partnerRequestStatusOptions,
} from '../../utils/constants';

const PartnerRequestDetailsStatus = (props: PartnerRequestDetailsStatusProps) => {
  const {
    userType,
    status,
    actions,
    requestId,
  } = props;

  const [displayPrompt, setDisplayPrompt] = useState<boolean>(false);
  const [selectedStatus, setSelectedStatus] = useState(inProgressStatusOption);

  useEffect(() => {
    setDisplayPrompt(false);
  }, [status]);

  const onStatusChange = (value: StatusType) => {
    setDisplayPrompt(false);
    setSelectedStatus(PARTNER_STATUS_OPTIONS_MAP[value as StatusOptionType]);
    switch (value) {
      case REQUEST_STATUS.rejected:
        setDisplayPrompt(true);
        break;
      case REQUEST_STATUS.delivered:
        actions.changePartnerRequestStatus({ id: requestId, status: REQUEST_STATUS.delivered });
        break;
      default:
        break;
    }
  }

  const changeStatus = (reason: string) => {
    actions.changePartnerRequestStatus({
      id: requestId, status: selectedStatus.value as StatusType, reason,
    });
    setDisplayPrompt(false);
  }

  const onCancel = () => {
    setDisplayPrompt(false);
    setSelectedStatus(inProgressStatusOption);
  }

  const canBeDeliveredByUser = isInProgress(status);

  return (
    <div className="drawer-title__status--container">
      <RenderIf condition={isPartner(userType)}>
        <>
          {!canBeDeliveredByUser ? (
            <p className="drawer-title__status">
              Status:
              <span className={classNames('drawer-title__status-value', STATUS_COLOR_CLASS(userType)[status])}>{getStatusName(status, userType)}</span>
            </p>
          )
            : (
              <div className="drawer-title__status-select">
                Status
                <span className={classNames('drawer-title__status-select-value', STATUS_COLOR_CLASS(userType)[status])}>
                  <Input>
                    <Input.Select
                      options={partnerRequestStatusOptions}
                      className="drawer-title__selector"
                      onChange={onStatusChange}
                      value={selectedStatus}
                    />
                  </Input>
                </span>
              </div>
            )}
          <RenderIf condition={displayPrompt && canBeDeliveredByUser}>
            <ButtonWithPrompt
              defaultOpen
              onFinish={changeStatus}
              promptLabel={`Reason for marking as ${getStatusName(selectedStatus.value as StatusType, userType)}`}
              className="primary-button"
              promptActiveClassName="primary-button"
              onCancel={onCancel}
            >
              {`Mark as ${getStatusName(selectedStatus.value as StatusType, userType)}`}
            </ButtonWithPrompt>
          </RenderIf>
        </>
      </RenderIf>
      <RenderIf condition={!isPartner(userType)}>
        <p className="drawer-title__status">
          Status:
          <span className={classNames('drawer-title__status-value', STATUS_COLOR_CLASS(userType)[status])}>{getStatusName(status, userType)}</span>
        </p>
      </RenderIf>
    </div>
  );
}

export default PartnerRequestDetailsStatus;
