import React from 'react';

import { ChevronSortIcon } from 'assets/icons';
import { TableTitleWithSorterProps } from 'types/componentTypes';

import './TitleWithSorter.scss';

const TableTitleWithSorter = (props: TableTitleWithSorterProps) => {
  const { title } = props;

  return (
    <div className="table-title-with-sorter">
      {title}
      <span className="table-title-with-sorter--icon">
        <ChevronSortIcon />
      </span>
    </div>
  );
}

export default TableTitleWithSorter;
