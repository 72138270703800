import { useReducer } from 'react';

import { ProfileType } from 'types/people';
import {
  setCurrentPage as setCurrentPageAC,
  setLimit as setLimitAC,
  setOpenFilters as setOpenFiltersAC,
  setPreviousTab as setPreviousTabAC,
  setFiltersChanged as setFiltersChangedAC,
} from './actions';
import { initialState, reducer } from './reducer';

const useTableHook = () => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const setCurrentPage = (data: number) => {
    dispatch(setCurrentPageAC({ data }));
  }

  const setLimit = (data: number) => {
    dispatch(setLimitAC({ data }));
  }

  const setOpenFilters = (data: boolean) => {
    dispatch(setOpenFiltersAC({ data }));
  }

  const setPreviousTab = (data: ProfileType) => {
    dispatch(setPreviousTabAC({ data }));
  }

  const setFiltersChanged = (data: boolean) => {
    dispatch(setFiltersChangedAC({ data }));
  }

  return {
    state,
    setLimit,
    setOpenFilters,
    setCurrentPage,
    setPreviousTab,
    setFiltersChanged,
  };
}

export default useTableHook;
