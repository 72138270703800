import React, { useState } from 'react';
import { Button } from 'antd';
import { Viewer } from '@react-pdf-viewer/core';

import { identity } from 'utils';
import { AgreementFieldProps } from 'types/requests';
import { ViewIcon } from 'assets/icons';
import { Modal, RenderIf } from 'components/components';
import { downloadButton } from 'components/utils/constants/buttons';

import './AgreementField.scss';

const AgreementField = (props: AgreementFieldProps) => {
  const { altText, fileUrl, fileName } = props;
  const [open, setOpen] = useState(false);

  if (!identity.isTruthyString(fileUrl)) {
    return (
      <RenderIf condition={identity.isTruthyString(altText)}>
        <div className="agreement-field">
          <p>{altText}</p>
        </div>
      </RenderIf>
    );
  }

  return (
    <div className="agreement-field">
      <span>{identity.isTruthyString(fileName) ? fileName : 'Agreement.pdf'}</span>
      <Button type="ghost" onClick={() => setOpen(true)}><ViewIcon /></Button>
      <Modal
        open={open}
        onCancel={() => setOpen(false)}
        footer={null}
        title={fileName || 'Agreement'}
        buttons={downloadButton(fileUrl as string, 'pdf')}
      >
        <div className="al-pdf-preview__container">
          <Viewer fileUrl={fileUrl!} />
        </div>
      </Modal>
    </div>
  )
}

export default AgreementField;
