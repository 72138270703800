import { PayloadAction } from '@reduxjs/toolkit';

import { PeopleFilters } from 'types/people';
import { peopleFiltersDefault } from 'redux/utils';
import { DataPayload } from 'types/reduxTypes/ActionTypes';
import { types } from './actions';

const initialState: PeopleFilters = peopleFiltersDefault;

const handleSetNationalities = (state: PeopleFilters, payload: DataPayload<number[]>) => ({
  ...state, nationalities: payload.data,
});

const handleSetSkills = (state: PeopleFilters, payload: DataPayload<string[]>) => ({
  ...state, skills: payload.data,
});

const handleSetGender = (state: PeopleFilters, payload: DataPayload<number>) => ({
  ...state, gender: payload.data,
});

const handleSetStartups = (state: PeopleFilters, payload: DataPayload<number[]>) => ({
  ...state, startups: payload.data,
});

const handleSetRoles = (state: PeopleFilters, payload: DataPayload<string[]>) => ({
  ...state, roles: payload.data,
});

const reducer = (
  state: PeopleFilters,
  action: PayloadAction<object | PeopleFilters | DataPayload<number | number[] | string[]>>,
): PeopleFilters => {
  const { type, payload } = action;

  switch (type) {
    case types.SET_GENDER: {
      return handleSetGender(state, <DataPayload<number>>payload);
    }
    case types.SET_STARTUPS: {
      return handleSetStartups(state, <DataPayload<number[]>>payload);
    }
    case types.SET_ROLES: {
      return handleSetRoles(state, <DataPayload<string[]>>payload);
    }
    case types.SET_NATIONALITIES: {
      return handleSetNationalities(state, <DataPayload<number[]>>payload);
    }
    case types.SET_SKILLS: {
      return handleSetSkills(state, <DataPayload<string[]>>payload);
    }
    case types.SET_FILTERS: {
      return <PeopleFilters>payload;
    }
    case types.RESET_FILTERS: {
      return initialState;
    }
    default: {
      return state;
    }
  }
}

export { initialState, reducer };
