import React, { useState } from 'react'
import { Button, Form } from 'antd';
import TextArea from 'antd/lib/input/TextArea';

import { ButtonWithPromptProps } from 'types/componentTypes';
import { RenderIf, Input } from 'components/components';

import './ButtonWithPrompt.scss';

const ButtonWithPrompt = (props: ButtonWithPromptProps) => {
  const {
    className,
    promptPlaceholder,
    promptLabel,
    onFinish,
    onCancel,
    onPromptDisplay,
    children,
    promptActiveBtnText,
    promptActiveClassName,
    defaultOpen,
  } = props;

  const [form] = Form.useForm();
  const [promptActive, setPromptActive] = useState(defaultOpen || false);

  const handleSubmit = (data: { text: string}) => {
    if (promptActive) {
      onFinish(data.text);
      form.resetFields();
      setPromptActive(false);
    } else {
      setPromptActive(true);
      onPromptDisplay!();
    }
  }

  const handleCancel = () => {
    setPromptActive(false);
    onCancel!();
  }

  const buttonClassName = (promptActive ? promptActiveClassName : className) || 'secondary-button';

  return (
    <Form onFinish={handleSubmit} className="form-standalone button-with-prompt" form={form}>
      <RenderIf condition={promptActive}>
        <Input
          label={promptLabel}
          layout="vertical"
          name="text"
        >
          <TextArea rows={4} placeholder={promptPlaceholder} />
        </Input>
      </RenderIf>
      <div className="button-with-prompt__actions">
        <RenderIf condition={promptActive}>
          <Button
            className="secondary-button"
            onClick={handleCancel}
          >
            Cancel
          </Button>
        </RenderIf>
        <Button
          className={buttonClassName}
          htmlType="submit"
        >
          {promptActive ? promptActiveBtnText || children : children}
        </Button>
      </div>
    </Form>
  );
}

ButtonWithPrompt.defaultProps = {
  promptPlaceholder: '',
  onPromptDisplay: () => {},
  onCancel: () => {},
  promptActiveClassName: '',
  promptActiveBtnText: '',
  className: '',
  defaultOpen: false,
}

export default ButtonWithPrompt;
