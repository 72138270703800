import { connect } from 'react-redux';

import { StateType } from 'types';
import {
  selectPartner,
  selectServiceProvider,
  selectUser,
  selectUserStartup,
  selectUserType,
} from 'redux/selectors/auth';

const mapStateToProps = (state: StateType) => ({
  userType: selectUserType(state),
  partner: selectPartner(state),
  serviceProvider: selectServiceProvider(state),
  startup: selectUserStartup(state),
  user: selectUser(state),
});

export default connect(mapStateToProps);
