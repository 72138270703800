import { connect } from 'react-redux';

import { StateType } from '../../../../../../types';
import { selectWallet } from '../../../../../../redux/selectors/wallet';

const mapStateToProps = (state: StateType) => ({
  wallet: selectWallet(state),
});

export default connect(mapStateToProps, null);
