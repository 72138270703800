import React from 'react';
import { Button } from 'antd';

import { CloseIcon } from 'assets/icons';
import { DocumentBoxProps } from '../../../../types/services';
import './DocumentBox.scss';

const DocumentBox = (props: DocumentBoxProps) => {
  const { fileName, onDelete } = props;

  return (
    <div className="document-box">
      <p className="document-box__file-name">{fileName}</p>
      <Button
        className="document-box__delete-button"
        size="small"
        danger
        icon={<CloseIcon />}
        onClick={onDelete}
      />
    </div>
  )
}

export default DocumentBox;
