/* eslint-disable react/jsx-props-no-spreading  */
import React from 'react';
import classnames from 'classnames';

import { PreviewProps } from 'types/componentTypes';
import { PreviewContent, Buttons } from '../index';

import './Preview.scss';

const Preview = (props: PreviewProps) => (
  <div className={classnames('uploaded-file', { 'uploaded-file__documents': !props.isRoundableButton })}>
    <PreviewContent
      fileName={props.fileName}
      fileSizeMb={props?.isRoundableButton ? props.fileSizeMb : undefined}
      previewType={props.previewType}
      fileType={props.fileType}
    />
    <Buttons {...props} />
  </div>
)
Preview.defaultProps = {
  previewType: 'text',
}

export default Preview;
