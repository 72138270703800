import React from 'react';

import SVGWrap from './SVGWrap';

const RecruitmentIcon = () => (
  <SVGWrap size={24} fill="none">
    <path d="M20.7891 21H6.72656C6.60996 21 6.51562 20.9057 6.51562 20.7891V17.4844H5.55469V17.0625H7.89844V17.485H6.9375V20.5781H20.5781V6.9375H17.2734C17.1568 6.9375 17.0625 6.84316 17.0625 6.72656V3.42188H6.9375V6.51562H7.89844V6.9375H5.55469V6.51562H6.51562V3.21094C6.51562 3.09434 6.60996 3 6.72656 3H17.2734C17.3297 3 17.3836 3.02227 17.4229 3.06152L20.9385 6.57715C20.9777 6.61699 21 6.67031 21 6.72656V20.7891C21 20.9057 20.9057 21 20.7891 21ZM17.4844 6.51562H20.2799L17.4844 3.72012V6.51562ZM13.7578 17.4844H9.07031V17.0625H13.5469V6.9375H9.07031V6.51562H13.7578C13.8744 6.51562 13.9688 6.60996 13.9688 6.72656V8.85938H18.4453V9.28125H13.9688V11.2031H18.4453V11.625H13.9688V13.5469H18.4453V13.9693H13.9688V15.8906H18.4453V16.3131H13.9688V17.2734C13.9688 17.39 13.8744 17.4844 13.7578 17.4844ZM4.38281 17.4844H3.21094C3.09434 17.4844 3 17.39 3 17.2734V16.1016H3.42188V17.0625H4.38281V17.4844ZM11.332 14.9297H10.9102V14.6367C10.9102 13.5686 10.1865 12.6141 9.15059 12.3164C9.06738 12.2924 9.00703 12.2197 8.99883 12.1336C8.99062 12.0475 9.03574 11.9648 9.11309 11.9256C9.58887 11.6836 9.88477 11.2072 9.88477 10.6816C9.88477 9.90937 9.25664 9.28125 8.48437 9.28125C7.71211 9.28125 7.08398 9.90937 7.08398 10.6816C7.08398 11.2072 7.3793 11.6836 7.85566 11.9256C7.93301 11.9648 7.97812 12.0475 7.96992 12.1336C7.96172 12.2197 7.90137 12.2924 7.81816 12.3164C6.78223 12.6141 6.05859 13.5686 6.05859 14.6367V14.9297H5.63672V14.6367C5.63672 13.5246 6.30293 12.518 7.3002 12.0598C6.89883 11.7187 6.66211 11.2201 6.66211 10.6816C6.66211 9.67676 7.47949 8.85938 8.48437 8.85938C9.48926 8.85938 10.3066 9.67676 10.3066 10.6816C10.3066 11.2201 10.0699 11.7187 9.66855 12.0598C10.6658 12.518 11.332 13.5246 11.332 14.6367V14.9297ZM3.42188 14.9297H3V12.5859H3.42188V14.9297V14.9297ZM3.42188 11.4141H3V9.07031H3.42188V11.4141V11.4141ZM3.42188 7.89844H3V6.72656C3 6.60996 3.09434 6.51562 3.21094 6.51562H4.38281V6.9375H3.42188V7.89844Z" strokeWidth="0.585937" />
  </SVGWrap>
);

export default RecruitmentIcon;
