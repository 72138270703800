import httpClient, { ApiResponseType, DeleteResourceSuccessResponseType, HttpRequestConfig } from 'packages/http_client';
import {
  CreatePerformanceReportApiResponse,
  GetPerformanceParams,
  GetPerformanceReportByIdApiResponse,
  GetPerformanceReportByIdResponse,
  GetPerformanceReportsListApiResponse,
  GetPerformanceReportsListResponse,
  PerformanceBody,
  PerformanceTypePayload,

} from './types';
import {
  convertGetPerformanceReportsList,
  convertPerformanceBody,
  convertPerformanceResponse,
  PERFORMANCE_API,
} from './utils';

const getPerformanceReportsListApi = (params?: GetPerformanceParams) => {
  const url = PERFORMANCE_API;
  const config: HttpRequestConfig = { params };
  return httpClient.get<ApiResponseType<GetPerformanceReportsListApiResponse>>(url, config);
}

const getPerformanceReportByIdApi = (id: number) => {
  const url = `${PERFORMANCE_API}/${id}`;
  const config: HttpRequestConfig = {
    params: {
      $preload: 1,
    },
  };
  return httpClient.get<ApiResponseType<GetPerformanceReportByIdApiResponse>>(url, config);
}

const createPerformanceReportApi = (payload: PerformanceBody) => {
  const url = PERFORMANCE_API;
  return httpClient.post<ApiResponseType<CreatePerformanceReportApiResponse>>(url, payload);
}

const updatePerformanceReportByIdApi = (id: number, payload: PerformanceBody) => {
  const url = `${PERFORMANCE_API}/${id}`;
  return httpClient.put<ApiResponseType<CreatePerformanceReportApiResponse>>(url, payload);
}

const deletePerformanceReportByIdApi = (id: number) => {
  const url = `${PERFORMANCE_API}/${id}`;
  return httpClient.delete<ApiResponseType<DeleteResourceSuccessResponseType>>(url);
}

export const getPerformanceRepostsList = async (params?: GetPerformanceParams)
: Promise<GetPerformanceReportsListResponse> => {
  const response = await getPerformanceReportsListApi(params);
  const { error, httpStatus, data } = response;
  return { error, httpStatus, data: convertGetPerformanceReportsList(data?.result) };
}

export const getPerformanceReportById = async (id: number)
: Promise<GetPerformanceReportByIdResponse> => {
  const response = await getPerformanceReportByIdApi(id);
  const { error, httpStatus, data } = response;
  return { error, httpStatus, data: convertPerformanceResponse(data?.result) };
}

export const createPerformanceReport = async (payload: PerformanceTypePayload) => {
  const response = await createPerformanceReportApi(convertPerformanceBody(payload));
  const { error, httpStatus } = response;
  return { error, httpStatus };
}

export const updatePerformanceReportById = async (id: number, payload: PerformanceTypePayload) => {
  const response = await updatePerformanceReportByIdApi(id, convertPerformanceBody(payload));
  const { error, httpStatus } = response;
  return { error, httpStatus };
}

export const deletePerformanceReportById = async (id: number) => {
  const response = await deletePerformanceReportByIdApi(id);
  const { error, httpStatus } = response;
  return { error, httpStatus };
}
