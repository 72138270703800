import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { Button, Form, Spin } from 'antd';
import { useLocation, useParams } from 'react-router';
import { RcFile } from 'antd/lib/upload';

import { composePartnerDetailsUrl } from 'routes/routeComposers';
import { NOT_SPECIFIED } from 'components/CohortDetails/utils/constants';
import { PartnerType } from 'packages/partner_repository';
import Heading from 'components/components/Input/components/Heading';
import { EditPartnerProps } from 'types/partners/partnersOverview';
import { EditProfileImage, FormBuilder, Breadcrumb } from 'components/components';
import { scrollToFirstError } from 'components/components/FormBuilder/utils/helpers';
import { identity } from 'utils';
import routes from 'routes';
import { editPartnerFields } from './utils/constants';

const EditPartner = (props: EditPartnerProps) => {
  const [form] = Form.useForm();
  const [logo, setLogo] = useState<Blob>();
  const {
    details, isLoading, actions, userPartnerId,
  } = props;

  const params: { id?: string } = useParams();
  const { id } = params;
  const location = useLocation();
  const isEditCompanyProfile = identity.isTruthyNumber(userPartnerId)
    && location.pathname === routes.editCompanyProfileUrl;

  const partnerId = useMemo(() => (isEditCompanyProfile ? userPartnerId! : parseInt(id!, 10)), [id]);

  useEffect(() => {
    actions.getPartnerById({ id: partnerId });
  }, [id]);

  useEffect(() => {
    form.setFieldsValue(details);
  }, [details]);

  const beforeUpload = useCallback((file: RcFile) => {
    setLogo(file);
    return false;
  }, []);

  const onSubmit = (data: PartnerType) => {
    actions.updatePartner({ data: { ...data, id: partnerId, logo: logo! } });
  }

  return (
    <div className="content-container">
      <Spin spinning={isLoading}>
        <Breadcrumb navigateTo={isEditCompanyProfile ? routes.companyProfileUrl : composePartnerDetailsUrl(partnerId)} routeName="Partner overview" />
        <div className="edit-partner">
          <Heading title={details?.name! || NOT_SPECIFIED} />
          <Form
            className="form-standalone"
            form={form}
            onFinishFailed={scrollToFirstError}
            onFinish={onSubmit}
          >
            <EditProfileImage imageLink={details?.logo!} beforeLogoUpload={beforeUpload} />
            <FormBuilder
              layout="horizontal"
              oneColumn
              formFragment
              fields={editPartnerFields}
            />
            <Button className="primary-button" htmlType="submit">Save changes</Button>
          </Form>
        </div>
      </Spin>
    </div>
  )
}

export default EditPartner;
