import React from 'react';

import SVGWrap from './SVGWrap';

const PayrollIcon = () => (
  <SVGWrap size={24} fill="none">
    <path d="M7.71804 6.26807H16.2825C16.4364 6.26807 16.5611 6.39279 16.5611 6.54666V21.1453C16.5611 21.2992 16.4364 21.4239 16.2825 21.4239H7.71804C7.56419 21.4239 7.43945 21.2992 7.43945 21.1453V6.54666C7.43945 6.39279 7.56419 6.26807 7.71804 6.26807Z" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" />
    <path d="M13.4467 13.8459C13.4467 14.6448 12.799 15.2924 12.0002 15.2924C11.2013 15.2924 10.5537 14.6448 10.5537 13.8459C10.5537 13.047 11.2013 12.3994 12.0002 12.3994C12.799 12.3994 13.4467 13.047 13.4467 13.8459Z" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" />
    <path d="M4.06731 8.23316H2.1C2.04477 8.23316 2 8.18839 2 8.13316V2.10439C2 2.04917 2.04477 2.00439 2.1 2.00439H21.9C21.9552 2.00439 22 2.04917 22 2.10439V8.13316C22 8.18839 21.9552 8.23316 21.9 8.23316H19.9327" strokeLinecap="square" strokeWidth="1" />
  </SVGWrap>
);

export default PayrollIcon;
