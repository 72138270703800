import React, { useEffect, useCallback } from 'react';

import { MainCategoriesProps } from 'types/settingsOverview';
import { DRAWERS, identity } from 'utils';
import { ServiceCategoryType } from 'packages/service_repository';
import { MainCategoriesColumns } from '../../../../utils/constants/columns';
import SettingsTable from '../../SettingsTable';

const MainCategoriesOverview = (props: MainCategoriesProps) => {
  const {
    categories,
    onAddNew,
  } = props;
  const {
    getServiceCategories,
    deleteServiceCategoryById,
    setDrawerBody,
    setIsDrawerOpen,
    setServiceCategoryDetails,
    setIsMainCategory,
    setCategoryID,
  } = props.actions;

  useEffect(() => {
    if (identity.isEmptyArray(categories)) {
      getServiceCategories({});
    }
  }, []);

  const onDelete = useCallback((id: number) => {
    deleteServiceCategoryById({ id });
  }, []);

  const onUpdate = useCallback((data: ServiceCategoryType) => {
    setServiceCategoryDetails({ data })
    onAddNew!({ isEdit: true });
    setDrawerBody({ drawerBody: DRAWERS.editCategory });
    setIsDrawerOpen({ isDrawerOpen: true });
    setIsMainCategory({ data: true });
    setCategoryID({ data: 1 });
  }, []);

  return (
    <SettingsTable
      dataSource={categories}
      columns={MainCategoriesColumns(onDelete, onUpdate)}
    />
  );
}

export default MainCategoriesOverview;
