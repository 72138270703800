import React, { useEffect } from 'react';

import { TitlesProps } from 'types/settingsOverview';
import { DRAWERS, identity } from 'utils';
import { DataEntryPointType } from 'packages/settings_repository';
import SettingsTable from '../../SettingsTable';
import { dataEntryColumns } from '../../../../utils/constants/columns';

const Titles = (props: TitlesProps) => {
  const { titles, actions } = props;
  const {
    getTitles,
    deleteTitles,
    setDrawerBody,
    setIsDrawerOpen,
    setDataEntryPointDetail,
  } = actions;

  useEffect(() => {
    if (identity.isEmptyArray(titles)) {
      getTitles();
    }
  }, []);

  const onUpdate = (data: DataEntryPointType) => {
    setDataEntryPointDetail({ data });
    setDrawerBody({ drawerBody: DRAWERS.editDataEntry });
    setIsDrawerOpen({ isDrawerOpen: true });
  }

  return (
    <SettingsTable dataSource={titles} columns={dataEntryColumns(deleteTitles, onUpdate)} />
  );
}

export default Titles;
