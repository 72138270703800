/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import { Viewer } from '@react-pdf-viewer/core';
import { readRemoteFile } from 'react-papaparse';
import { Spin } from 'antd';

import { identity } from 'utils';
import { downloadButton } from 'components/utils/constants/buttons';
import { FilePreviewProps } from 'types/componentTypes';
import Modal from '../../../../../Modal';

import '@react-pdf-viewer/core/lib/styles/index.css';
import './FilePreviewModal.scss';

const FilePreviewModal = (props: FilePreviewProps) => {
  const { fileContent, fileType, title } = props;

  const [data, setData] = useState<any>([]);

  useEffect(() => {
    readRemoteFile(
      fileContent,
      {
        complete: (resul: any) => {
          setData(resul.data);
        },
        download: true,
      },
    )
  }, []);

  switch (fileType) {
    case 'pdf': {
      return (
        <Modal
          className="ac-letter-preview"
          open={props.isOpen}
          onCancel={() => props.setIsOpen(false)}
          title={title}
          footer={null}
          buttons={downloadButton(fileContent, 'pdf')}
        >
          <div className="al-pdf-preview__container">
            <Viewer fileUrl={fileContent} />
          </div>
        </Modal>
      )
    }
    case 'html': {
      return (
        <Modal
          title={title}
          footer={null}
          open={props.isOpen}
          onCancel={() => props.setIsOpen(false)}
          className="file-preview-modal"
          buttons={downloadButton(fileContent, 'html')}
        >
          <div className="file-preview-container">
            <iframe
              className="html-preview"
              title="html template"
              srcDoc={fileContent}
            />
          </div>
        </Modal>
      )
    }
    case 'csv': {
      return (
        <Modal
          title={title}
          footer={null}
          open={props.isOpen}
          onCancel={() => props.setIsOpen(false)}
          className="file-preview-modal"
          buttons={downloadButton(fileContent, 'csv')}
        >
          <Spin spinning={identity.isEmptyArray(data)}>
            <table>
              <tbody className="csv-preview">
                {
                  data.map((numList: any, i: any) => (
                    <tr key={i}>
                      {
                        numList.map((num: any, j: any) => identity.isTruthyString(num) && <td key={j}>{num}</td>)
                      }
                    </tr>
                  ))
                }
              </tbody>
            </table>
          </Spin>
        </Modal>
      )
    }
    default: {
      return null;
    }
  }
}
export default FilePreviewModal;
