import React from 'react';

import { identity } from 'utils';
import { AboutCohortProps } from 'types/cohorts/aboutCohort';
import { RenderIf, Modal } from 'components/components';

import './AboutCohort.scss';

const AboutCohort = (props: AboutCohortProps) => (
  <div className="about_cohort_modal">
    <Modal
      title={`About ${props.cohort?.name || 'Cohort'}`}
      footer={null}
      onCancel={props.toggleModal}
      open={props.isModalOpen}
    >
      <div className="about_cohort_modal-data">
        <div className="about_cohort_modal__logo_container">
          <RenderIf condition={identity.isTruthyString(props.cohort?.imageLink)}>
            <img
              className="cohort__image cover-image"
              src={props.cohort?.imageLink}
              alt={`${props.cohort?.name} Logo`}
            />
          </RenderIf>
        </div>
        <p className="text">{props.cohort?.about || 'This cohort has no description about it'}</p>
      </div>
    </Modal>
  </div>
);

export default AboutCohort;
