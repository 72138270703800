import { connect } from 'react-redux';
import { Action, bindActionCreators, Dispatch } from 'redux';

import {
  selectIsBlockedByNotice, selectUser, selectUserNotice, selectUserStartup,
} from 'redux/selectors/auth';
import { setIsNoticeModalOpen, setNoticeModalTemplate } from 'redux/actions/workspace';
import { StateType } from 'types';
import { selectNoticeModalTemplate } from 'redux/selectors/workspace';

const mapStateToProps = (state: StateType) => ({
  user: selectUser(state),
  startup: selectUserStartup(state),
  notice: selectUserNotice(state),
  isBlockedByNotice: selectIsBlockedByNotice(state),
  noticeModalTemplate: selectNoticeModalTemplate(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  actions: bindActionCreators({
    setIsNoticeModalOpen,
    setNoticeModalTemplate,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps);
