import { Action, bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';

import { setDeleteIds } from 'redux/actions/settingsOverview'
import { StateType } from 'types';
import { selectDeleteIds } from 'redux/selectors/settingsOverview';

const mapStateToProps = (state: StateType) => ({
  deleteIds: selectDeleteIds(state),
})
const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  actions: bindActionCreators({ setDeleteIds }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps);
