import httpClient, { ApiResponseType } from '../../http_client';
import { convertGetInvestmentStagesListResponse, INVESTMENT_STAGE_API } from '../utils';
import { GetInvestmentStagesApiResponseType, GetInvestmentStagesListResponse } from '../types';

const getInvestmentStagesApi = () => {
  const url = `${INVESTMENT_STAGE_API}`;
  return httpClient.get<ApiResponseType<GetInvestmentStagesApiResponseType>>(url);
}

export const getInvestmentStagesList = async (): Promise<GetInvestmentStagesListResponse> => {
  const response = await getInvestmentStagesApi();
  const { error, data, httpStatus } = response;
  return { error, httpStatus, data: convertGetInvestmentStagesListResponse(data) };
}

export default null;
