import React from 'react';

import { TabButtonProps } from 'types/requests';
import { identity, isStartup } from 'utils';
import { SubTab } from 'components/components';
import {
  getTabTitle,
  isNew,
  isUnsent,
} from '../../../../utils';

const TabButton = (props: TabButtonProps) => {
  const {
    currentTab,
    value,
    unsentCount,
    userType,
    count,
  } = props;
  const { setCurrentTab } = props.actions;

  const noUnsentRequests = isUnsent(value) && identity.isZero(unsentCount);

  if (isStartup(props.userType) && (isNew(value) || noUnsentRequests)) {
    return null;
  }

  return (
    <SubTab
      activeKey={currentTab}
      tab={isUnsent(value)
        ? `Unsent (${unsentCount})`
        : getTabTitle(count, value, userType)}
      tabKey={value}
      onClick={() => setCurrentTab({ currentTab: value })}
    />
  );
}

export default TabButton;
