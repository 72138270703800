import React from 'react'
import { Button, Form } from 'antd';

import { FormBuilder } from 'components/components';
import { AddNewDataEntryProps, AddNewDataEntryType } from 'types/reduxTypes/ActionTypes/SettingsOverviewTypes';
import { AddNewDataEntryDrawerFields, DATA_ENTRY_POINTS, defaultValue } from './utils/constants';

import './AddNewDataEntry.scss';

const AddNewDataEntry = (props: AddNewDataEntryProps) => {
  const { actions } = props;

  const [form] = Form.useForm();

  const handleSubmit = (data: AddNewDataEntryType) => {
    switch (data.type) {
      case DATA_ENTRY_POINTS.education: {
        actions.addEducation({ data });
        break;
      }
      case DATA_ENTRY_POINTS.startupStage: {
        actions.addStartupStage({ data });
        break;
      }
      case DATA_ENTRY_POINTS.title: {
        actions.addTitle({ data });
        break;
      }
      default: {
        break;
      }
    }

    form.resetFields();
  }

  const onCancel = () => {
    actions.setIsDrawerOpen({ isDrawerOpen: false });
    actions.setDestroyDrawerOnClose({ destroyDrawerOnClose: true });
  }

  return (
    <Form
      form={form}
      className="form-standalone add-new-data-entry"
      onFinish={handleSubmit}
      initialValues={defaultValue}
    >
      <FormBuilder
        formFragment
        oneColumn
        fields={AddNewDataEntryDrawerFields}
        defaultValues={defaultValue}
      />
      <div className="submit__wrap">
        <Button type="primary" className="secondary-button" onClick={onCancel}>
          Cancel
        </Button>
        <Button type="primary" htmlType="submit" className="primary-button">
          Add data entry point
        </Button>
      </div>
    </Form>
  )
}

export default AddNewDataEntry
