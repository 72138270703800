import React from 'react';
import classnames from 'classnames';

import { CardRowProps } from 'types/requests';

const CardRow = (props: CardRowProps) => {
  const { label, value, bold } = props;
  return (
    <div className="request-item-card__row">
      <p className={classnames('request-item-card__row__label', { 'request-item-card__row__label--bold': bold })}>{label}</p>
      <div className="dot" />
      <p className={classnames('request-item-card__row__item', { 'request-item-card__row__item--bold': bold })}>{value}</p>
    </div>
  );
}

CardRow.defaultProps = {
  bold: false,
}

export default CardRow;
