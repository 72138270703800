import PartnerGetInTouchDrawer from 'components/GetInTouchDrawerPartner';
import AddProgramTypeDrawer from 'components/Settings/components/ProgramTypeTable/components/AddProgramTypeDrawer';
import AddNewCategoryDrawer from 'components/Settings/components/ServiceCategories/MainCategories/components/AddNewCategoryDrawer';
import AddNewSubCategoryDrawer from 'components/Settings/components/ServiceCategories/Subcategories/components/AddNewSubCategoryDrawer';
import EditCategoryDrawer from 'components/Settings/components/ServiceCategories/EditCategoryDrawer';
import { AddNewTemplateDrawer } from 'components/Settings/components/TemplatesTable/components';
import { AddNewDataEntry } from 'components/Settings/components/DataEntryPoints';
import GetInTouchDrawer from 'components/GetInTouchDrawer';
import PartnerRequestDetailsDrawer from 'components/RequestsOverview/components/PartnerRequestDetailsDrawer';
import { PerformanceReviewDrawer, PerformanceViewDetailsDrawer } from 'components/PerformanceDrawers';
import EditEventDrawer from 'components/EditEventDrawer';
import EditDataEntryPointDrawer from 'components/Settings/components/DataEntryPoints/EditDataEntryPointDrawer';
import EditTemplateDrawer from 'components/Settings/components/TemplatesTable/components/EditTemplateDrawer';
import EditEventInfo from 'components/EditEventDrawer/components/EditEventInfo';
import AddNewPartnerMemberDrawer from 'components/AddNewPartnerMember';
import AddPeopleDrawer from 'components/ActivitySidebar/components/Header/components/Filters/components/AddPeopleDrawer';
import AddNewCohort from 'components/AddNewCohort';
import AddServiceProviderDrawer from 'components/AddServiceProviderDrawer';
import AddServiceProviderOffer from 'components/AddServiceProviderOffer';
import AddStartupDrawer from 'components/AddStartupDrawer';
import AddNewMemberDrawer from 'components/AddNewMemberDrawer';
import AddMemberDrawer from 'components/AddTeamMemberDrawer';
import RequestDetailsDrawer from 'components/RequestsOverview/components/RequestDetailsDrawer';
import ServicesFiltersDrawer from 'components/Services/components/ServicesFiltersDrawer';
import EditServiceDrawer from 'components/Services/components/EditServiceDrawer';
import RequestServiceDrawer from 'components/RequestServiceDrawer';
import DrawerTitle from 'components/components/Drawer/components/DrawerTitle';
import AddNewServiceDrawer, { AddNewServiceTitle } from 'components/AddNewServiceDrawer';
import AddEventDrawer from 'components/AddEventDrawer';
import AddPartnerDrawer from 'components/AddPartnerDrawer';
import StartupDocumentRequestDrawer from 'components/CohortDetails/components/Requests/components/StartupDocumentRequestDrawer';
import { ADGMLicenseDetailsDrawer, IncentiveAgreementDetailsDrawer } from 'components/EditStartup/components';

export const DRAWERS = {
  addMember: 'Add member',
  addPartner: 'Add partner',
  addMemberStartup: 'Add member startup',
  addStartup: 'Add startup',
  addPeopleFilters: 'Add people filters',
  addServiceProvider: 'Add service provider',
  addService: 'Add service',
  addServiceProviderOffer: 'Add service provider offer',
  addCohort: 'Add cohort',
  addEvent: 'Add event',
  requestDetails: 'Request details',
  partnerRequestDetails: 'Partner Request details',
  requestsFilters: 'Request filters',
  servicesFilters: 'Services filters',
  editService: 'Edit service',
  requestService: 'Request service',
  addProgramType: 'Add program type',
  addCategory: 'Add new category',
  addSubcategory: 'Add new subcategory',
  editCategory: 'Edit category',
  addNewTemplate: 'Add New Template',
  editTemplate: 'Edit Template',
  addDataEntry: 'Add new Data entry',
  editDataEntry: 'Edit Data entry',
  getInTouchDrawer: 'Get in touch',
  getInTouch: 'Get in Touch',
  performanceViewDrawer: 'Performance view',
  performanceReviewDrawer: 'Performance review',
  editEvent: 'Edit event',
  eventInfo: 'Event',
  addPartnerMember: 'Add partner member',
  acceptanceLetter: 'Acceptance Letter',
  adgmLicense: 'ADGM License',
  adgmLicenseDetails: 'ADGM License Details',
  incentiveAgreementDetails: 'Incentive Agreement Details',
  incentiveAgreement: 'Incentive Agreement',
}

export const TITLE_COLOR_CLASSES = {
  blue: 'drawer--blue',
}

export default {
  [DRAWERS.editTemplate]: {
    body: EditTemplateDrawer,
    title: DrawerTitle,
    titleClassName: TITLE_COLOR_CLASSES.blue,
  },
  [DRAWERS.editDataEntry]: {
    body: EditDataEntryPointDrawer,
    title: DrawerTitle,
    titleClassName: TITLE_COLOR_CLASSES.blue,
  },
  [DRAWERS.performanceReviewDrawer]: {
    body: PerformanceReviewDrawer,
    title: DrawerTitle,
    titleClassName: TITLE_COLOR_CLASSES.blue,
  },
  [DRAWERS.performanceViewDrawer]: {
    body: PerformanceViewDetailsDrawer,
    title: DrawerTitle,
    titleClassName: TITLE_COLOR_CLASSES.blue,
  },
  [DRAWERS.addDataEntry]: {
    body: AddNewDataEntry,
    title: DrawerTitle,
    titleClassName: TITLE_COLOR_CLASSES.blue,
  },
  [DRAWERS.addMember]: {
    body: AddNewMemberDrawer,
    titleClassName: TITLE_COLOR_CLASSES.blue,
  },
  [DRAWERS.addPartner]: {
    body: AddPartnerDrawer,
    titleClassName: TITLE_COLOR_CLASSES.blue,
  },
  [DRAWERS.addMemberStartup]: {
    body: AddMemberDrawer,
    titleClassName: TITLE_COLOR_CLASSES.blue,
  },
  [DRAWERS.addStartup]: {
    body: AddStartupDrawer,
    titleClassName: TITLE_COLOR_CLASSES.blue,
  },
  [DRAWERS.addPeopleFilters]: {
    body: AddPeopleDrawer,
    titleClassName: TITLE_COLOR_CLASSES.blue,
  },
  [DRAWERS.addServiceProvider]: {
    body: AddServiceProviderDrawer,
    titleClassName: TITLE_COLOR_CLASSES.blue,
  },
  [DRAWERS.addService]: {
    body: AddNewServiceDrawer,
    title: AddNewServiceTitle,
    titleClassName: TITLE_COLOR_CLASSES.blue,
  },
  [DRAWERS.addNewTemplate]: {
    body: AddNewTemplateDrawer,
    titleClassName: TITLE_COLOR_CLASSES.blue,
  },
  [DRAWERS.addServiceProviderOffer]: {
    body: AddServiceProviderOffer,
    titleClassName: TITLE_COLOR_CLASSES.blue,
  },
  [DRAWERS.addCohort]: {
    body: AddNewCohort,
    titleClassName: TITLE_COLOR_CLASSES.blue,
  },
  [DRAWERS.requestService]: {
    body: RequestServiceDrawer,
    title: DrawerTitle,
    titleClassName: TITLE_COLOR_CLASSES.blue,
  },
  [DRAWERS.addEvent]: {
    body: AddEventDrawer,
    titleClassName: TITLE_COLOR_CLASSES.blue,
  },
  [DRAWERS.requestDetails]: {
    body: RequestDetailsDrawer,
    title: DrawerTitle,
    titleClassName: TITLE_COLOR_CLASSES.blue,
  },
  [DRAWERS.servicesFilters]: {
    body: ServicesFiltersDrawer,
    title: DrawerTitle,
    titleClassName: TITLE_COLOR_CLASSES.blue,
  },
  [DRAWERS.editService]: {
    body: EditServiceDrawer,
    title: DrawerTitle,
    titleClassName: TITLE_COLOR_CLASSES.blue,
  },
  [DRAWERS.addProgramType]: {
    body: AddProgramTypeDrawer,
    titleClassName: TITLE_COLOR_CLASSES.blue,
  },
  [DRAWERS.addCategory]: {
    body: AddNewCategoryDrawer,
    title: DrawerTitle,
    titleClassName: TITLE_COLOR_CLASSES.blue,
  },
  [DRAWERS.addSubcategory]: {
    body: AddNewSubCategoryDrawer,
    title: DrawerTitle,
    titleClassName: TITLE_COLOR_CLASSES.blue,
  },
  [DRAWERS.editCategory]: {
    body: EditCategoryDrawer,
    title: DrawerTitle,
    titleClassName: TITLE_COLOR_CLASSES.blue,
  },
  [DRAWERS.getInTouchDrawer]: {
    body: PartnerGetInTouchDrawer,
    title: DrawerTitle,
    titleClassName: TITLE_COLOR_CLASSES.blue,
  },
  [DRAWERS.getInTouch]: {
    body: GetInTouchDrawer,
    title: DrawerTitle,
    titleClassName: TITLE_COLOR_CLASSES.blue,
  },
  [DRAWERS.partnerRequestDetails]: {
    body: PartnerRequestDetailsDrawer,
    title: DrawerTitle,
    titleClassName: TITLE_COLOR_CLASSES.blue,
  },
  [DRAWERS.editEvent]: {
    body: EditEventDrawer,
    title: DrawerTitle,
    titleClassName: TITLE_COLOR_CLASSES.blue,
  },
  [DRAWERS.eventInfo]: {
    body: EditEventInfo,
    title: DrawerTitle,
    titleClassName: TITLE_COLOR_CLASSES.blue,
  },
  [DRAWERS.addPartnerMember]: {
    body: AddNewPartnerMemberDrawer,
    title: DrawerTitle,
    titleClassName: TITLE_COLOR_CLASSES.blue,
  },
  [DRAWERS.acceptanceLetter]: {
    body: StartupDocumentRequestDrawer,
    title: DrawerTitle,
    titleClassName: TITLE_COLOR_CLASSES.blue,
  },
  [DRAWERS.adgmLicense]: {
    body: StartupDocumentRequestDrawer,
    title: DrawerTitle,
    titleClassName: TITLE_COLOR_CLASSES.blue,
  },
  [DRAWERS.incentiveAgreement]: {
    body: StartupDocumentRequestDrawer,
    title: DrawerTitle,
    titleClassName: TITLE_COLOR_CLASSES.blue,
  },
  [DRAWERS.adgmLicenseDetails]: {
    body: ADGMLicenseDetailsDrawer,
    title: DrawerTitle,
    titleClassName: TITLE_COLOR_CLASSES.blue,
  },
  [DRAWERS.incentiveAgreementDetails]: {
    body: IncentiveAgreementDetailsDrawer,
    title: DrawerTitle,
    titleClassName: TITLE_COLOR_CLASSES.blue,
  },
}
