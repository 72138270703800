import React from 'react';
import { Typography } from 'antd';

import { DescriptionBoxProps } from '../../../../../types/componentTypes/CardProps';

const DescriptionBox = (props: DescriptionBoxProps) => {
  const { title, description } = props;
  return (
    <div className="description-container">
      <Typography className="description-title">{title}</Typography>
      <Typography className="description">{description}</Typography>
    </div>
  );
};

DescriptionBox.defaultProps = {
  title: '',
  Description: '',
};

export default DescriptionBox;
