import React, { useState } from 'react';
import { Button } from 'antd';
import { AiOutlineFileText } from 'react-icons/ai';

import { openUrlInNewTab, getImageLink, identity } from 'utils';
import FilePreviewModal from 'components/components/Input/components/DraggerUpload/components/FilePreviewModal';
import { CompanyDeckFileProps } from 'types/startups/startupDetails/serviceProviderOverview';

import './CompanyDeckFile.scss';

const CompanyDeckFile = (props: CompanyDeckFileProps) => {
  const { fileContent, fileName } = props;

  const [isOpen, setIsOpen] = useState(false);

  const handleDownload = () => {
    openUrlInNewTab(getImageLink(fileContent));
  };

  if (!identity.isTruthyString(fileContent)) {
    return (
      <div className="company-deck-file__info--container">
        <p className="company-deck-file__info--name">No file uploaded</p>
      </div>
    )
  }

  return (
    <div className="company-deck-file">
      <div className="company-deck-file__info">
        <AiOutlineFileText className="company-deck-file__info--icon" />
        <div className="company-deck-file__info--container">
          <p className="company-deck-file__info--name">{fileName}</p>
        </div>
      </div>
      <div className="company-deck-file__buttons">
        <Button onClick={() => setIsOpen(true)} className="primary-button md">View</Button>
        <Button className="secondary-button md" onClick={() => handleDownload()}>Download</Button>
      </div>
      <FilePreviewModal
        title={fileName!}
        fileType="pdf"
        fileContent={getImageLink(fileContent)}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
      />
    </div>
  )
}

CompanyDeckFile.defaultProps = {
  fileSizeMb: '1',
  fileName: 'Rate Card Document.pdf',
}

export default CompanyDeckFile;
