import { OfferPriceType, ServiceProviderOfferType } from '../../../types/serviceProviders/serviceProvidersOffer';

export const FIXED_PRICE_TYPE: OfferPriceType = 'fixed';
export const RANGE_PRICE_TYPE: OfferPriceType = 'range';
export const VARIABLE_PRICE_TYPE: OfferPriceType = 'variable';

export const amountMinMessage = 'Cannot be larger than Amount To value';
export const amountMaxMessage = 'Cannot be smaller than Amount From value'

export const priceOfferOptions = [
  { key: 'fixed-price', value: FIXED_PRICE_TYPE, title: 'Fixed Price' },
  { key: 'price-range', value: RANGE_PRICE_TYPE, title: 'Price Range' },
  { key: 'variable-price', value: VARIABLE_PRICE_TYPE, title: 'Variable Price' },
];

export const defaultAddOfferFormValues: ServiceProviderOfferType = {
  name: '',
  description: '',
  offerDocument: '',
  priceType: FIXED_PRICE_TYPE,
  amount: '',
  amountMin: '',
  amountMax: '',
};
