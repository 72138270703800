import React, { useCallback, useState, useEffect } from 'react'
import { useHistory } from 'react-router';

import {
  DRAWERS,
  getOffset,
  getRowKey,
  identity,
  isEitherAdmin,
  isStartup,
  ROLES,
} from 'utils';
import { ServiceProvidersProps } from 'types/services';
import { ServiceProvider, ServiceProviderType } from 'types/serviceProviders';
import { composeServiceProviderDetailsUrl } from 'routes/routeComposers';
import { GridTable, Table } from 'components/components';
import config from 'assets/config';
import { DataPayload } from 'types/reduxTypes/ActionTypes';
import {
  serviceProvidersGridColumns,
  serviceProvidersOverviewColumns,
} from '../../../utils/constants/columns';

const ServiceProviders = (props: ServiceProvidersProps) => {
  const {
    userType,
    dataSource,
    requests,
    setSpLimit,
    setSpCurrentPage,
    spCurrentPage,
    limit,
    serviceProvidersCount,
    filters,
    setServiceProviderName,
    setFiltersChanged,
    activeSpFilters,
    serviceCategories,
    setActiveSpFilters,
    setServiceProviderFilter,
    isRowTableLayout,
    setServiceProvidersFiltersChanged,
    serviceProvidersFiltersChanged,
  } = props;

  const {
    setFilters,
    setDrawerBody,
    setIsDrawerOpen,
    setActiveTab,
    getServices,
    getServiceProviders,
    getServiceProvidersCount,
  } = props.actions;

  const history = useHistory();

  const [searchString, setSearchString] = useState<string>('');

  const handleViewServicesButton = (serviceProvider: ServiceProviderType) => {
    setActiveTab({ activeTab: 'services' });
    getServices({ filters: { serviceProviderId: [serviceProvider.id] } });
    setServiceProviderName(serviceProvider.name);
    let serviceProviderId: number[] | undefined = [];
    const serviceProviderIdFilters = identity.isFullArray(filters.serviceProviderId)
      ? [...filters.serviceProviderId]
      : [];
    if (identity.isTruthyNumber(serviceProvider.id)) {
      const hasServiceProviderId = (serviceProviderIdFilters).includes(serviceProvider.id as number);
      if (hasServiceProviderId) {
        serviceProviderId = (serviceProviderIdFilters).filter((item) => item !== serviceProvider.id as number);
      } else {
        serviceProviderId = serviceProviderIdFilters;
        serviceProviderId!.push(serviceProvider.id as number);
      }
    } else {
      serviceProviderId = undefined;
    }

    setFilters({ ...filters, serviceProviderId });
    setFiltersChanged(true);
  }

  const openServiceProviderDetails = (record: ServiceProvider) => history.push(
    composeServiceProviderDetailsUrl(record.id),
  );

  const onAddButtonClick = () => {
    setDrawerBody({ drawerBody: DRAWERS.addServiceProvider })
    setIsDrawerOpen({ isDrawerOpen: true });
  }

  const setSpCategories = useCallback((payload: DataPayload<number | 'all'>) => {
    let categories: number[] | undefined = [];
    if (payload.data === 'all') {
      setServiceProviderFilter(undefined);
      setActiveSpFilters(undefined);
    } else {
      const hasCategoryId = (activeSpFilters || []).includes(payload.data as number);
      if (hasCategoryId) {
        categories = (activeSpFilters || []).filter((item) => item !== payload.data as number);
      } else {
        categories = activeSpFilters || [];
        categories!.push(payload.data as number);
      }
      const categoriesString = identity.isFullArray(categories) ? categories.toString() : undefined;
      categories = identity.isFullArray(categories) ? categories : undefined;
      setServiceProviderFilter(categoriesString);
      setActiveSpFilters(categories);
      setSpCurrentPage(1);
      setServiceProvidersFiltersChanged(true);
    }
  }, [activeSpFilters]);

  useEffect(() => {
    let limitDefault = limit;
    if (isRowTableLayout && isEitherAdmin(userType)) {
      limitDefault = config.TABLE_DEFAULT_LIMIT;
    }
    if (!isRowTableLayout || isStartup(userType)) {
      limitDefault = config.GRID_TABLE_DEFAULT_LIMIT;
    }
    setSpLimit(limitDefault);
    setSpCurrentPage(1);
  }, [isRowTableLayout]);

  useEffect(() => {
    const query = {
      isPreload: true,
      limit,
      offset: getOffset(limit, spCurrentPage),
      categories: activeSpFilters,
      searchString,
    };
    getServiceProviders({ query });
    getServiceProvidersCount({ query });
    setServiceProvidersFiltersChanged(false);
  }, [
    limit, spCurrentPage, userType, activeSpFilters, userType,
    searchString, serviceProvidersFiltersChanged, isRowTableLayout,
  ]);

  switch (userType) {
    case ROLES.startupFounder:
    case ROLES.startupTeamMember: {
      return (
        <GridTable
          dataSource={dataSource}
          columns={serviceProvidersGridColumns}
          rowKey={getRowKey(dataSource, 'service-providers', 'id')}
          component="startup-sp"
          onGridCardClick={() => null}
          requests={requests}
          firstButtonTitle="View profile"
          secondButtonTitle="View services"
          firstButtonAction={
            (record: ServiceProviderType) => history.push(composeServiceProviderDetailsUrl(record?.id!))
          }
          secondButtonAction={(record: ServiceProviderType) => handleViewServicesButton(record)}
          hasDeleteOption={false}
          showCustomPagination
          setLimit={setSpLimit}
          setCurrentPage={setSpCurrentPage}
          currentPage={spCurrentPage}
          limit={limit}
          total={serviceProvidersCount}
        />
      );
    }
    case ROLES.admin:
    case ROLES.superAdmin: {
      return (
        <Table
          onRow={(record) => ({ onClick: () => openServiceProviderDetails(record) })}
          onGridCardClick={openServiceProviderDetails}
          leftContent="add-button"
          addButtonText="service provider"
          placeholder="perks and services"
          dataSource={dataSource}
          columns={serviceProvidersOverviewColumns}
          sectorOptions={serviceCategories}
          showDisplayButtons
          tableLocation="service-provider"
          onAddButtonClick={onAddButtonClick}
          rowKey={getRowKey(dataSource, 'service-providers', 'id')}
          showCustomPagination
          limit={limit}
          showSearchBar
          currentPage={spCurrentPage}
          setCurrentPage={setSpCurrentPage}
          setLimit={setSpLimit}
          total={serviceProvidersCount}
          component="startup-sp"
          setFilter={setSpCategories}
          activeFilters={activeSpFilters}
          setSearchString={setSearchString}
          filtersActive={identity.isTruthyString(searchString) || identity.isFullArray(activeSpFilters)}
        />
      );
    }
    default: {
      return null;
    }
  }
}

export default ServiceProviders;
