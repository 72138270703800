import React, { useMemo, useState } from 'react';
import { Button } from 'antd';
import moment from 'moment';

import DataColumn from 'components/components/DataColumns/components/DataColumn';
import { ButtonWithPrompt, RenderIf } from 'components/components';
import { NOT_SPECIFIED } from 'components/CohortDetails/utils/constants';
import AgreementField from 'components/RequestsOverview/components/RequestDetailsDrawer/components/OverviewTab/components/AgreementField';
import { StartupType } from 'packages/startup_repository';
import { CohortStartupReviewDocumentType, DocumentRequestStatus, StartupDocumentRequestDrawerProps } from 'types/cohorts/cohortDetails';
import {
  DRAWERS,
  identity,
  isStartup,
  parseNumberToPrice,
} from 'utils';
import { formatSlashMDY } from 'components/utils/constants/formatters';
import { getDocumentType } from '../../utils/helpers';

const StartupDocumentRequestDrawer = (props: StartupDocumentRequestDrawerProps) => {
  const { startup, actions, userType } = props;
  const {
    id,
    acceptanceLetter,
    currentADGMLicense,
    currentIncentiveAgreement,
    name,
    imageLink,
    about,
    numberOfEmployees,
    industry,
    website,
  } = startup;

  const [displayApproveBtn, setDisplayApproveBtn] = useState(true);

  const documentType: CohortStartupReviewDocumentType = useMemo(() => getDocumentType(startup), [startup]);

  const updateStartupDocumentStatus = (status: DocumentRequestStatus, rejectedReason?: string) => {
    const updateStartup: StartupType = { ...startup };
    switch (documentType) {
      case 'acceptanceLetter': {
        updateStartup.acceptanceLetterApprovedStatus = status;
        break;
      }
      case 'adgmLicense': {
        updateStartup.aDGMApprovedStatus = status;
        break;
      }
      case 'incentiveAgreement': {
        updateStartup.incentiveAgreementApprovedStatus = status;
        break;
      }
      default: {
        return;
      }
    }

    actions.changeStartupDocumentStatus({
      startup: updateStartup,
      documentType,
      rejectedReason,
      id,
    });
  }

  const { fileUrl, fileName, altText } = useMemo(() => {
    switch (documentType) {
      case 'acceptanceLetter': {
        return {
          fileUrl: acceptanceLetter,
          fileName: 'Acceptance Letter.pdf',
          altText: 'Acceptance Letter',
        }
      }
      case 'adgmLicense': {
        return {
          fileUrl: currentADGMLicense?.aDGMLicenseUrl,
          fileName: 'ADGM License.pdf',
          altText: 'ADGM License',
        }
      }
      case 'incentiveAgreement': {
        return {
          fileUrl: currentIncentiveAgreement?.file,
          fileName: 'Incentive Agreement.pdf',
          altText: 'Incentive Agreement',
        }
      }
      default: {
        return { fileUrl: '', fileName: '', altText: '' };
      }
    }
  }, [documentType]);

  const onDeny = (reason: string) => {
    updateStartupDocumentStatus(2, reason);
  }

  const onApprove = () => {
    if (documentType === 'incentiveAgreement' && [
      identity.isFalsy(currentIncentiveAgreement?.startDate),
      identity.isFalsy(currentIncentiveAgreement?.endDate),
      identity.isFalsy(currentIncentiveAgreement?.walletAmount),
    ].some(Boolean)) {
      actions.getStartupById({ id, owner: 'startup', isUpdate: false });
      actions.setIsDrawerOpen({ isDrawerOpen: true });
      actions.setDrawerBody({ drawerBody: DRAWERS.incentiveAgreementDetails });
    } else {
      updateStartupDocumentStatus(1);
    }
  }

  return (
    <div className="request-details-drawer">
      <div className="overview-tab-content" key={`overview-tab-content-${id}`}>
        <div className="overview-tab-content--container">
          <DataColumn label="Startup Name">{name || NOT_SPECIFIED}</DataColumn>
          <RenderIf condition={documentType === 'acceptanceLetter'}>
            <DataColumn label="Logo">
              {identity.isTruthyString(imageLink as string)
                ? <img className="profile-overview__image" src={imageLink as string} alt="Startup Logo" />
                : 'Startup logo is not uploaded'}
            </DataColumn>
            <DataColumn label="Primary Industry">
              {industry || NOT_SPECIFIED}
            </DataColumn>
            <DataColumn label="Website">
              {website || NOT_SPECIFIED}
            </DataColumn>
            <DataColumn label="Number of Employees">
              {numberOfEmployees || NOT_SPECIFIED }
            </DataColumn>
            <DataColumn label="Logo">
              {identity.isTruthyString(imageLink as string) ? (
                <img className="profile-overview__image" src={imageLink as string} alt="Startup Logo" />
              ) : 'Startup logo is not uploaded'}
            </DataColumn>
            <DataColumn label="About">
              {about || NOT_SPECIFIED}
            </DataColumn>
          </RenderIf>
          <RenderIf condition={documentType === 'incentiveAgreement'}>
            <DataColumn label="Start Date">
              {identity.isTruthyString(currentIncentiveAgreement?.startDate)
                ? moment(currentIncentiveAgreement?.startDate).format(formatSlashMDY)
                : 'Not specified'}
            </DataColumn>
            <DataColumn label="End Date">
              {identity.isTruthyString(currentIncentiveAgreement?.endDate)
                ? moment(currentIncentiveAgreement?.endDate).format(formatSlashMDY)
                : 'Not specified'}
            </DataColumn>
            <DataColumn label="Wallet Amount">
              {identity.isPositiveNumber(currentIncentiveAgreement?.walletAmount)
                ? parseNumberToPrice(currentIncentiveAgreement?.walletAmount!, 'AED')
                : 'Not specified'}
            </DataColumn>
          </RenderIf>
          <RenderIf condition={identity.isTruthyString(documentType)}>
            <DataColumn label={altText}>
              <AgreementField
                fileUrl={fileUrl}
                fileName={fileName}
                altText={`No ${altText} found for this startup`}
              />
            </DataColumn>
          </RenderIf>
        </div>
        <div className="overview-tab__actions">
          <RenderIf condition={identity.isTruthyString(documentType)}>
            <ButtonWithPrompt
              promptActiveClassName="danger-button"
              className="ghost-button"
              promptLabel="Reason for denying"
              promptPlaceholder="Write a reason..."
              onPromptDisplay={() => setDisplayApproveBtn(false)}
              onFinish={onDeny}
              onCancel={() => setDisplayApproveBtn(true)}
              promptActiveBtnText="Deny request"
            >
              {isStartup(userType) ? 'Cancel' : 'Deny'}
            </ButtonWithPrompt>
            <RenderIf condition={displayApproveBtn}>
              <Button className="primary-button" onClick={onApprove}>Approve</Button>
            </RenderIf>
          </RenderIf>
        </div>
      </div>
    </div>
  );
};

export default StartupDocumentRequestDrawer;
