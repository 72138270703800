import React, { useEffect } from 'react';

import { EducationProps } from 'types/settingsOverview';
import { DRAWERS, identity } from 'utils';
import { DataEntryPointType } from 'packages/settings_repository';
import { dataEntryColumns } from '../../../../utils/constants/columns';
import SettingsTable from '../../SettingsTable';

const Education = (props: EducationProps) => {
  const { education, actions } = props;
  const {
    getEducation,
    deleteEducation,
    setDrawerBody,
    setIsDrawerOpen,
    setDataEntryPointDetail,
  } = actions;

  useEffect(() => {
    if (identity.isEmptyArray(education)) {
      getEducation();
    }
  }, []);

  const onUpdate = (data: DataEntryPointType) => {
    setDataEntryPointDetail({ data })
    setDrawerBody({ drawerBody: DRAWERS.editDataEntry });
    setIsDrawerOpen({ isDrawerOpen: true });
  }

  return (
    <SettingsTable dataSource={education} columns={dataEntryColumns(deleteEducation, onUpdate)} />
  );
}

export default Education;
