import { getFormFieldBuilder } from 'components/components/FormBuilder/utils/FormFieldBuilder';
import requiredValidation from 'utils/constants/validationRules';

export const defaultValue = {
  title: '',
  url: '',
};

export const pdfFileSubTabs = ['Incentive agreement', 'Work order']

export const EditTemplateDrawerFields = [
  {
    fields: [
      getFormFieldBuilder('title', 'Name of the template')
        .withRules([requiredValidation])
        .build(),
    ],
  },
];
