import { connect } from 'react-redux';
import { bindActionCreators, Dispatch, Action } from 'redux';

import { updateEventRSVP } from 'redux/actions/events';

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  actions: bindActionCreators({
    updateEventRSVP,
  }, dispatch),
});

export default connect(null, mapDispatchToProps);
