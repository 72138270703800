import React from 'react';

import { ServiceProviderChildFormProps } from '../../../../types/serviceProviders/editServiceProvider';
import FormBuilder from '../../../components/FormBuilder';
import { editContactPersonFields } from '../../utils/constants';

const ContactPersonForm = (props: ServiceProviderChildFormProps) => {
  const { userType } = props;

  return (
    <FormBuilder
      oneColumn
      formFragment
      layout="horizontal"
      fields={editContactPersonFields(userType)}
    />
  )
}

export default ContactPersonForm;
