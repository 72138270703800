import { connect } from 'react-redux';
import { bindActionCreators, Dispatch, Action } from 'redux';

import { StateType } from 'types';
import { selectWorkspaceServiceProviders } from 'redux/selectors/workspace';
import { selectServiceCategories, selectServiceFilters } from 'redux/selectors/services';
import { getWorkspaceServiceProviders } from 'redux/actions/workspace';
import { getServiceCategories, setFilters } from 'redux/actions/services';

const mapStateToProps = (state: StateType) => ({
  serviceProviders: selectWorkspaceServiceProviders(state),
  categories: selectServiceCategories(state),
  filters: selectServiceFilters(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  actions: bindActionCreators({
    getWorkspaceServiceProviders,
    getServiceCategories,
    setFilters,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps);
