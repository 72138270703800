import { useReducer } from 'react';

import { DataPayload } from 'types/reduxTypes/ActionTypes';
import { ServicesFiltersType } from 'types/services';
import {
  setOfferPrice as setOfferPriceAC,
  setServiceProviders as setServiceProvidersAC,
  setCategories as setCategoriesAC,
  resetFilters as resetFiltersAC,
  setFilters as setFiltersAC,
} from './actions';
import { reducer, initialState } from './reducer';

const useServicesFiltersHook = () => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const setFilters = (payload: ServicesFiltersType) => {
    dispatch(setFiltersAC(payload));
  }

  const resetFilters = () => {
    dispatch(resetFiltersAC());
  }

  const setServiceProviders = (payload: DataPayload<number[]>) => {
    dispatch(setServiceProvidersAC(payload));
  }

  const setCategories = (payload: DataPayload<number[]>) => {
    dispatch(setCategoriesAC(payload));
  }

  const setOfferPrice = (payload: DataPayload<0 | 1 | 2>) => {
    dispatch(setOfferPriceAC(payload));
  }

  return {
    state,
    initialState,
    setOfferPrice,
    setCategories,
    setServiceProviders,
    resetFilters,
    setFilters,
  };
}

export default useServicesFiltersHook;
