import React from 'react';
import { Button } from 'antd';

import { AddIcon } from '../../../../../../assets/icons';
import { AddNewButtonsProps } from '../../../../../../types/reduxTypes/ActionTypes/WorkspaceTypes';

const AddNewService = (props: AddNewButtonsProps) => {
  const { item, actions: { setDrawerBody, setIsDrawerOpen } } = props;

  const handleClick = () => {
    setDrawerBody({ drawerBody: item.drawer })
    setIsDrawerOpen({ isDrawerOpen: true });
  }

  return (
    <Button className="primary-button" icon={<AddIcon />} onClick={handleClick}>
      {`Add new ${item.label}`}
    </Button>
  );
};

export default AddNewService;
