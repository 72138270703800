import React, { useState } from 'react';
import { Select } from 'antd';

import { SelectTagsProps } from 'types/componentTypes';
import Input from '../../index';

import './SelectTags.scss';

const SelectTags = (props: SelectTagsProps) => {
  const { name, label } = props;

  const [values, setValues] = useState<string[]>([]);

  const onChange = (selectedValues: string[]) => {
    setValues(selectedValues);
  }

  return (
    <div className="input">
      <div className="select-tags-container">
        <Input name={name} label={label} layout="vertical">
          <Select
            value={values}
            mode="tags"
            onChange={onChange}
            getPopupContainer={(trigger) => trigger.parentNode}
          />
        </Input>
      </div>
    </div>
  )
}

SelectTags.defaultProps = {
  name: '',
  label: '',
}

export default SelectTags;
