import { UploadFile } from 'antd';
import { PayloadAction } from '@reduxjs/toolkit';

import { DataPayload } from '../../../../../../../types/reduxTypes/ActionTypes';
import { types } from './actions';

type DraggerUploadHookState = {
  fileType: string,
  fileSize: string,
  fileName: string,
  files: UploadFile[],
  fileContent: string,
}

const initialState: DraggerUploadHookState = {
  files: [],
  fileSize: '',
  fileName: '',
  fileContent: '',
  fileType: '',
}

const handleSetFileType = (state: DraggerUploadHookState, payload: DataPayload<string>) => ({
  ...state, fileType: payload.data,
});

const handleSetContent = (state: DraggerUploadHookState, payload: DataPayload<string>) => ({
  ...state, fileContent: payload.data,
});

const handleSetFileName = (state: DraggerUploadHookState, payload: DataPayload<string>) => ({
  ...state, fileName: payload.data,
});

const handleSetFileSize = (state: DraggerUploadHookState, payload: DataPayload<string>) => ({
  ...state, fileSize: payload.data,
});

const handleSetFiles = (state: DraggerUploadHookState, payload: DataPayload<UploadFile[]>) => ({
  ...state, files: payload.data,
});

const reducer = (state: DraggerUploadHookState, action: PayloadAction<DataPayload<string | UploadFile[]>>) => {
  switch (action.type) {
    case types.SET_FILE_TYPE: {
      return handleSetFileType(state, <DataPayload<string>>action.payload);
    }
    case types.SET_FILE_CONTENT: {
      return handleSetContent(state, <DataPayload<string>>action.payload);
    }
    case types.SET_FILES: {
      return handleSetFiles(state, <DataPayload<UploadFile[]>>action.payload);
    }
    case types.SET_FILE_SIZE: {
      return handleSetFileSize(state, <DataPayload<string>>action.payload);
    }
    case types.SET_FILE_NAME: {
      return handleSetFileName(state, <DataPayload<string>>action.payload);
    }
    default: {
      return state;
    }
  }
}

export { initialState, reducer };
