import React from 'react';
import { Button } from 'antd';
import classnames from 'classnames';

import { RequestRowButtonsProps } from 'types/requests';
import { DRAWERS } from '../../../../../../utils';
import { isUnsent } from '../../../../utils';

const RequestRowButtons = (props: RequestRowButtonsProps) => {
  const { status, id } = props;
  const {
    setDrawerBody,
    setIsDrawerOpen,
    setRequestId,
    deleteDraftRequestItem,
  } = props.actions;

  const onClick = () => {
    if (isUnsent(status)) {
      deleteDraftRequestItem({ id });
    } else {
      setDrawerBody({ drawerBody: DRAWERS.requestDetails });
      setIsDrawerOpen({ isDrawerOpen: true });
      setRequestId({ id });
    }
  }

  return (
    <div className="request-row__buttons-container">
      <Button
        className={classnames('md', {
          'secondary-button': !isUnsent(status),
          'secondary-delete-button': isUnsent(status),
        })}
        onClick={onClick}
      >
        {isUnsent(status) ? 'Discard' : 'View details'}
      </Button>
    </div>
  );
}

export default RequestRowButtons;
