import { AxiosRequestConfig } from 'axios';

import httpClient, {
  ApiResponseType,
  GetCountResponse,
  HttpRequestConfig,
  ParamsPayload,
} from 'packages/http_client';
import { identity } from 'utils';
import {
  SERVICE_PROVIDER_API,
  convertCreateServiceProviderRequestPayload,
  convertEditServiceProviderPayload,
  convertGetServiceProviderResponse,
  convertGetServiceProvidersListResponse,
  serviceProviderFormatQuery,
} from '../utils';
import {
  CreateServiceProviderApiResponseType,
  CreateServiceProviderPayload,
  CreateServiceProviderResponse,
  EditServiceProviderApiResponseType,
  EditServiceProviderPayload,
  GetServiceProviderApiResponseType,
  GetServiceProvidersCountResponseType,
  GetServiceProvidersListApiResponseType,
  GetServiceProvidersListResponse,
  ParamsType,
  ServiceProviderType,
} from '../types';

const createServiceProviderApi = (payload: CreateServiceProviderPayload) => {
  const url = SERVICE_PROVIDER_API;
  const requestPayload = convertCreateServiceProviderRequestPayload(payload);
  return httpClient.post<ApiResponseType<CreateServiceProviderApiResponseType>>(url, requestPayload);
};

const getServiceProviderApi = (id: number, isPreload: boolean) => {
  const url = `${SERVICE_PROVIDER_API}/${id}`;
  const config: HttpRequestConfig = {
    params: {
      $preload: isPreload ? 1 : 0,
    },
  };
  return httpClient.get<ApiResponseType<GetServiceProviderApiResponseType>>(url, config);
};

const getServiceProvidersListApi = (params: ParamsType) => {
  const url = SERVICE_PROVIDER_API;
  const config: HttpRequestConfig = { params };
  return httpClient.get<ApiResponseType<
    | GetServiceProvidersListApiResponseType
    | GetServiceProvidersCountResponseType
    >>(url, config);
}

const getStartupServiceProvidersListApi = (query: ParamsPayload) => {
  const url = SERVICE_PROVIDER_API;
  const config: AxiosRequestConfig = { params: {} };
  config.params = serviceProviderFormatQuery(query)
  return httpClient.get<ApiResponseType<GetServiceProvidersListApiResponseType>>(url, config);
}

export const createServiceProvider = async (payload: CreateServiceProviderPayload)
: Promise<CreateServiceProviderResponse> => {
  const response = await createServiceProviderApi(payload);
  const { data, error, httpStatus } = response;
  return { error, httpStatus, data: data?.id[0] };
}

export const getServiceProvider = async (serviceProviderId: number, isPreload?: boolean)
: Promise<ApiResponseType<ServiceProviderType>> => {
  const response = await getServiceProviderApi(serviceProviderId, isPreload!);
  const { data, error, httpStatus } = response;
  return { error, httpStatus, data: convertGetServiceProviderResponse(data?.result) };
}

export const getServiceProvidersList = async (params: ParamsPayload): Promise<GetServiceProvidersListResponse> => {
  const queryParams: ParamsType = {
    $preload: params.isPreload ? 1 : 0,
    $limit: params.limit,
    $offset: params.offset,
    categories: identity.isFullArray(params.categories) ? params.categories!.toString() : undefined,
    $f: params.f,
    $q: identity.isTruthyString(params.searchString) ? params.searchString as string : undefined,
  };
  const response = await getServiceProvidersListApi(queryParams);
  const { data, error, httpStatus } = response;
  return {
    error, httpStatus, data: convertGetServiceProvidersListResponse(data as GetServiceProvidersListApiResponseType),
  }
}

export const getStartupSpsList = async (query?: ParamsPayload):
  Promise<GetServiceProvidersListResponse> => {
  const response = await getStartupServiceProvidersListApi(query!);
  const { data, error, httpStatus } = response;
  return { error, httpStatus, data: convertGetServiceProvidersListResponse(data) };
}

export const getServiceProvidersCount = async (params: ParamsPayload): Promise<ApiResponseType<GetCountResponse[]>> => {
  const queryParams: ParamsType = {
    $f: 'id__count',
    categories: identity.isFullArray(params.categories) ? params.categories!.toString() : undefined,
    $q: identity.isTruthyString(params.searchString) ? params.searchString as string : undefined,
  };
  const response = await getServiceProvidersListApi(queryParams);
  const { data, error, httpStatus } = response;
  return { error, httpStatus, data: data?.result as GetCountResponse[] };
}

const editServiceProviderApi = (serviceProviderId: number, payload: EditServiceProviderPayload) => {
  const url = `${SERVICE_PROVIDER_API}/${serviceProviderId}`;
  const requestPayload = convertEditServiceProviderPayload(payload);
  return httpClient.put<ApiResponseType<EditServiceProviderApiResponseType>>(url, requestPayload);
};

export const editServiceProvider = async (serviceProviderId: number, payload: EditServiceProviderPayload):
Promise<ApiResponseType<EditServiceProviderApiResponseType>> => {
  const response = await editServiceProviderApi(serviceProviderId, payload);
  const { error, httpStatus } = response;
  return { error, httpStatus };
};
