import { CreateProgramType, ProgramType } from 'packages/cohort_repository';
import { DataPayload, IdPayload, IsLoadingPayload } from 'types/reduxTypes/ActionTypes';
import { SetActiveTabPayload } from 'types/reduxTypes/ActionTypes/RequestsTypes';
import {
  CreateEmailTemplatePayload,
  DeleteEmailTemplatePayload,
  SetEmailTemplatesPayload,
  AddTemplatePayload,
  DeleteMultiplesTemplatePayload,
  AddDataEntryPayload,
  EditTemplateDataType,
} from 'types/reduxTypes/ActionTypes/SettingsOverviewTypes';
import { StartupStatusType } from 'packages/startup_repository';
import { DataEntryPointType } from 'packages/settings_repository';
import { EmailTemplateType } from 'types/settingsOverview';

export const types = {
  GET_EMAIL_TEMPLATES: 'GET_EMAIL_TEMPLATES',
  GET_EMAIL_TEMPLATE_BY_ID: 'GET_EMAIL_TEMPLATE_BY_ID',
  ADD_EMAIL_TEMPLATE: 'ADD_EMAIL_TEMPLATE',
  SET_EMAIL_TEMPLATES: 'SET_EMAIL_TEMPLATES',
  DELETE_EMAIL_TEMPLATE: 'DELETE_EMAIL_TEMPLATE',
  CREATE_EMAIL_TEMPLATE: 'CREATE_EMAIL_TEMPLATE',
  SET_ACTIVE_TAB_SETTINGS: 'SET_ACTIVE_TAB_SETTINGS',
  SET_ACTIVE_SUB_TAB_SETTINGS: 'SET_ACTIVE_SUB_TAB_SETTINGS',
  SET_DELETE_IDS: 'SET_DELETE_IDS',
  GET_PROGRAM_TYPES: 'GET_PROGRAM_TYPES',
  SET_PROGRAM_TYPES: 'SET_PROGRAM_TYPES',
  SET_PROGRAM_TYPE_DETAILS: 'SET_PROGRAM_TYPE_DETAILS',
  DELETE_PROGRAM_TYPES: 'DELETE_PROGRAM_TYPES',
  DELETE_PROGRAM_TYPE_BY_ID: 'DELETE_PROGRAM_TYPE_BY_ID',
  SET_IS_LOADING: 'SET_IS_LOADING',
  CREATE_PROGRAM_TYPE: 'CREATE_PROGRAM_TYPE',
  UPDATE_PROGRAM_TYPE_BY_ID: 'UPDATE_PROGRAM_TYPE_BY_ID',
  GET_EDUCATION: 'GET_EDUCATION',
  SET_EDUCATION: 'SET_EDUCATION',
  GET_STARTUP_STAGES: 'GET_STARTUP_STAGES',
  SET_STARTUP_STAGES: 'SET_STARTUP_STAGES',
  DELETE_EDUCATION: 'DELETE_EDUCATION',
  DELETE_STARTUP_STAGES: 'DELETE_STARTUP_STAGES',
  GET_TITLES: 'GET_TITLES',
  SET_TITLES: 'SET_TITLES',
  DELETE_TITLES: 'DELETE_TITLES',
  RESET_SETTINGS_DETAILS: 'RESET_SETTINGS_DETAILS',
  GET_INCENTIVE_TEMPLATES: 'GET_INCENTIVE_TEMPLATES',
  SET_INCENTIVE_TEMPLATES: 'SET_INCENTIVE_TEMPLATES',
  DELETE_MULTIPLE_TEMPLATES: 'DELETE_MULTIPLE_TEMPLATES',
  GET_WORK_ORDER_TEMPLATES: 'GET_WORK_ORDER_TEMPLATES',
  SET_WORK_ORDER_TEMPLATES: 'SET_WORK_ORDER_TEMPLATES',
  ADD_EDUCATION: 'ADD_EDUCATION',
  ADD_TITLE: 'ADD_TITLE',
  ADD_STARTUP_STAGE: 'ADD_STARTUP_STAGE',
  SET_TEMPLATE_DETAIL: 'SET_TEMPLATE_DETAIL',
  SET_DATA_ENTRY_POINT_DETAIL: 'SET_DATA_ENTRY_POINT_DETAIL',
  UPDATE_EDUCATION: 'UPDATE_EDUCATION',
  UPDATE_TITLE: 'UPDATE_TITLE',
  UPDATE_STARTUP_STAGE: 'UPDATE_STARTUP_STAGE',
  UPDATE_TEMPLATE_BY_ID: 'UPDATE_TEMPLATE_BY_ID',
  RESET_SO: 'RESET_SO',
};

export const resetSO = (payload: object = {}) => ({
  type: types.RESET_SO,
  payload,
});

export const updateTemplateById = (payload: DataPayload<EditTemplateDataType>) => ({
  type: types.UPDATE_TEMPLATE_BY_ID,
  payload,
});

export const updateEducation = (payload: DataPayload<DataEntryPointType>) => ({
  type: types.UPDATE_EDUCATION,
  payload,
});

export const updateTitle = (payload: DataPayload<DataEntryPointType>) => ({
  type: types.UPDATE_TITLE,
  payload,
});

export const updateStartupStage = (payload: DataPayload<DataEntryPointType>) => ({
  type: types.UPDATE_STARTUP_STAGE,
  payload,
});

export const setDataEntryPointDetail = (payload: DataPayload<DataEntryPointType>) => ({
  type: types.SET_DATA_ENTRY_POINT_DETAIL,
  payload,
});

export const setTemplateDetail = (payload: DataPayload<EmailTemplateType>) => ({
  type: types.SET_TEMPLATE_DETAIL,
  payload,
});

export const addStartupStage = (payload: AddDataEntryPayload) => ({
  type: types.ADD_STARTUP_STAGE,
  payload,
});

export const addTitle = (payload: AddDataEntryPayload) => ({
  type: types.ADD_TITLE,
  payload,
});

export const addEducation = (payload: AddDataEntryPayload) => ({
  type: types.ADD_EDUCATION,
  payload,
});

export const getWorkOrderTemplates = (payload: object = {}) => ({
  type: types.GET_WORK_ORDER_TEMPLATES,
  payload,
});

export const setWorkOrderTemplates = (payload: SetEmailTemplatesPayload) => ({
  type: types.SET_WORK_ORDER_TEMPLATES,
  payload,
});

export const deleteMultipleTemplates = (payload: DeleteMultiplesTemplatePayload) => ({
  type: types.DELETE_MULTIPLE_TEMPLATES,
  payload,
});

export const getIncentiveTemplates = (payload: object = {}) => ({
  type: types.GET_INCENTIVE_TEMPLATES,
  payload,
});

export const setIncentiveTemplates = (payload: SetEmailTemplatesPayload) => ({
  type: types.SET_INCENTIVE_TEMPLATES,
  payload,
});

export const addEmailTemplate = (payload: AddTemplatePayload) => ({
  type: types.ADD_EMAIL_TEMPLATE,
  payload,
});

export const getEmailTemplates = (payload: object = {}) => ({
  type: types.GET_EMAIL_TEMPLATES,
  payload,
});

export const setEmailTemplates = (payload: SetEmailTemplatesPayload) => ({
  type: types.SET_EMAIL_TEMPLATES,
  payload,
});

export const createEmailTemplate = (payload: CreateEmailTemplatePayload) => ({
  type: types.CREATE_EMAIL_TEMPLATE,
  payload,
});

export const deleteTemplate = (payload: DeleteEmailTemplatePayload) => ({
  type: types.DELETE_EMAIL_TEMPLATE,
  payload,
});

export const setActiveTab = (payload: SetActiveTabPayload) => ({
  type: types.SET_ACTIVE_TAB_SETTINGS,
  payload,
});

export const setActiveSubTab = (payload: SetActiveTabPayload) => ({
  type: types.SET_ACTIVE_SUB_TAB_SETTINGS,
  payload,
});

export const setDeleteIds = (payload: DataPayload<number[]>) => ({
  type: types.SET_DELETE_IDS,
  payload,
});

export const getProgramTypes = (payload: object = {}) => ({
  type: types.GET_PROGRAM_TYPES,
  payload,
});

export const setProgramTypes = (payload: DataPayload<ProgramType[]>) => ({
  type: types.SET_PROGRAM_TYPES,
  payload,
});

export const setIsLoading = (payload: IsLoadingPayload) => ({
  type: types.SET_IS_LOADING,
  payload,
});

export const deleteProgramTypes = (payload: object = {}) => ({
  type: types.DELETE_PROGRAM_TYPES,
  payload,
});

export const deleteProgramTypeById = (payload: IdPayload) => ({
  type: types.DELETE_PROGRAM_TYPE_BY_ID,
  payload,
});

export const updateProgramTypeById = (payload: ProgramType) => ({
  type: types.UPDATE_PROGRAM_TYPE_BY_ID,
  payload,
});

export const createProgramType = (payload: DataPayload<CreateProgramType>) => ({
  type: types.CREATE_PROGRAM_TYPE,
  payload,
});

export const setProgramTypeDetails = (payload: DataPayload<ProgramType>) => ({
  type: types.SET_PROGRAM_TYPE_DETAILS,
  payload,
})

export const getStartupStages = (payload: object = {}) => ({
  type: types.GET_STARTUP_STAGES,
  payload,
});

export const setStartupStages = (payload: DataPayload<StartupStatusType[]>) => ({
  type: types.SET_STARTUP_STAGES,
  payload,
});

export const getEducation = (payload: object = {}) => ({
  type: types.GET_EDUCATION,
  payload,
});

export const setEducation = (payload: DataPayload<DataEntryPointType[]>) => ({
  type: types.SET_EDUCATION,
  payload,
});

export const deleteEducation = (payload?: DataPayload<number>) => ({
  type: types.DELETE_EDUCATION,
  payload,
});

export const deleteStartupStages = (payload: object = {}) => ({
  type: types.DELETE_STARTUP_STAGES,
  payload,
});

export const getTitles = (payload: object = {}) => ({
  type: types.GET_TITLES,
  payload,
});

export const setTitles = (payload: DataPayload<DataEntryPointType>) => ({
  type: types.SET_TITLES,
  payload,
});

export const deleteTitles = (payload?: DataPayload<number>) => ({
  type: types.DELETE_TITLES,
  payload,
});

export const resetSettingsDetails = (payload: object = {}) => ({
  type: types.RESET_SETTINGS_DETAILS,
  payload,
})

export const getTemplateById = (payload: IdPayload) => ({
  type: types.GET_EMAIL_TEMPLATE_BY_ID,
  payload,
});
