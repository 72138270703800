import React from 'react'
import { Form } from 'antd'
import { RcFile } from 'antd/lib/upload';

import DrawerButton from 'components/components/Drawer/components/DrawerButton';
import { FormBuilder } from 'components/components';
import { GetInTouchRequestPartnersProps } from 'types/partners';
import { SendNewGetInTouchPartnerRequestPayload } from 'types/reduxTypes/ActionTypes/PartnersTypes';
import { GetInTouchDrawerFields } from './utils/constants';

const PartnerGetInTouchDrawer = (props: GetInTouchRequestPartnersProps) => {
  const { actions, id } = props;

  const [form] = Form.useForm();

  const handleFileUpload = (file: RcFile) => {
    form.setFieldValue('attachment', file as Blob);
    return false;
  }

  const handleSubmit = (data: SendNewGetInTouchPartnerRequestPayload) => {
    const addDataValues = {
      ...data,
      startupId: id,
    };
    actions.sendGetInTouchPartnerRequest(addDataValues);
    actions.setDestroyDrawerOnClose({ destroyDrawerOnClose: true });
  }

  return (
    <Form
      form={form}
      onFinish={handleSubmit}
      className="new-category-container"
      scrollToFirstError
    >
      <FormBuilder
        oneColumn
        formFragment
        fields={GetInTouchDrawerFields({ handleFileUpload, fileType: '.pdf' })}
      />
      <DrawerButton>Get in touch</DrawerButton>
    </Form>

  )
}
export default PartnerGetInTouchDrawer;
