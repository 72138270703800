/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
  useCallback, useEffect, useState,
} from 'react';
import { Button } from 'antd';

import { Row } from 'components/components/FormBuilder';
import { AddToListProps } from 'types/componentTypes';
import { identity } from 'utils';
import { AddIcon, CheckmarkIcon, CloseIcon } from 'assets/icons';
import Input from '../Input';
import RenderIf from '../RenderIf';
import AddItem from './components/AddItem';

import './AddToList.scss';

const AddToList = (props: AddToListProps) => {
  const {
    name, label, form, values,
  } = props;

  const [list, setList] = useState<string[]>(values || []);
  const [input, setInput] = useState('');
  const [showInput, setShowInput] = useState(false);

  useEffect(() => {
    form.setFieldValue(name, list);
    setInput('');
  }, [list]);

  const addToList = () => {
    const valueExist = list.indexOf(input) >= 0;

    if (identity.isTruthyString(input) && !valueExist) {
      setList([...list, input]);
      setShowInput(false);
    }
  }

  const onDelete = useCallback((deleteItem: string) => {
    setList(list.filter((item) => item !== deleteItem))
  }, [list])

  const onEdit = useCallback((oldValue: string, newValue: string) => {
    const newList = [...list];
    newList[list.indexOf(oldValue)] = newValue;
    setList(newList);
  }, [list]);

  const discardAdding = () => {
    setShowInput(false);
    setInput('');
  }

  return (
    <Row oneColumn>
      <Input label={label} layout="vertical">
        <div className="add-to-list">
          {list.map((item) => (
            <AddItem
              key={item}
              itemName={item}
              onDelete={onDelete}
              onEdit={onEdit}
            />
          ))}
          <div className="add-to-list__input">
            <RenderIf condition={showInput}>
              <Input.InputField
                value={input}
                onChange={(e) => setInput(e.target.value)}
              />
              <Button
                icon={<CheckmarkIcon />}
                type="ghost"
                onClick={addToList}
              />
              <Button
                icon={<CloseIcon />}
                type="ghost"
                onClick={discardAdding}
              />
            </RenderIf>
            <RenderIf condition={!showInput}>
              <Button
                icon={<AddIcon />}
                type="ghost"
                onClick={() => setShowInput(true)}
              >
                ADD
              </Button>
            </RenderIf>
          </div>
        </div>
      </Input>
    </Row>
  )
}

export default AddToList;
