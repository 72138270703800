/* eslint-disable @typescript-eslint/no-explicit-any */
import { PartnerRequestStatusType } from 'packages/partner_repository';
import { ServiceRequestType } from 'packages/service_request_repository';
import { StatusType } from 'types/requests';
import { identity } from 'utils';

export const changeRequestStatusHelper = (
  id: number,
  requests: any,
  status: StatusType | PartnerRequestStatusType,
) => {
  const request = requests.find((req: ServiceRequestType) => req.id === id);

  if (identity.isObjWithChildren(request)) {
    const requestIndex = requests.indexOf(request!);
    request!.status = status;
    requests[requestIndex] = request!;
  }

  return requests;
}

export default null;
