import { connect } from 'react-redux';
import { bindActionCreators, Dispatch, Action } from 'redux';

import { StateType } from 'types';
import { selectUserType } from 'redux/selectors/auth';
import { changeRequestStatus, setShowInvoiceFields } from 'redux/actions/request';
import { selectRequestId, selectRequestStatus, selectShowInvoiceFields } from 'redux/selectors/request';

const mapStateToProps = (state: StateType) => ({
  userType: selectUserType(state),
  status: selectRequestStatus(state),
  requestId: selectRequestId(state),
  showInvoiceFields: selectShowInvoiceFields(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  actions: bindActionCreators({ changeRequestStatus, setShowInvoiceFields }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps);
