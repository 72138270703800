import React, { useEffect } from 'react';
import { useParams } from 'react-router';

import {
  getOffset,
  getRowKey,
  isEitherAdmin,
  isStartup,
  useTableHook,
  identity,
} from 'utils';
import { WalletOverviewProps } from 'types/wallet';
import { composeStartupDetailsUrl } from 'routes/routeComposers';
import {
  Breadcrumb,
  RenderIf,
  StartupWallet,
  Table,
} from '../components';
import { walletOverviewColumns } from '../utils/constants/columns';

import './WalletOverview.scss';

const WalletOverview = (props: WalletOverviewProps) => {
  const {
    count,
    isLoading,
    startupId,
    userType,
    wallet,
    walletTransactions,
    isRowTableLayout,
    actions: { getWallet, setTableLayout },
  } = props;

  const params: { id?: string } = useParams();
  const { id } = params;

  const {
    state,
    setLimit,
    setCurrentPage,
  } = useTableHook();
  const { limit, currentPage } = state;

  useEffect(() => {
    if (!isRowTableLayout) {
      setTableLayout({ isRowTableLayout: true });
    }
  }, [isRowTableLayout]);

  useEffect(() => {
    if (!isRowTableLayout) {
      setTableLayout({ isRowTableLayout: true });
    }
  }, [isRowTableLayout]);

  useEffect(() => {
    if (isRowTableLayout) {
      if (isStartup(userType)) {
        getWallet({ startup_id: startupId, limit, offset: getOffset(limit, currentPage) });
      } else if (isEitherAdmin(userType)) {
        getWallet({ startup_id: id, limit, offset: getOffset(limit, currentPage) });
      }
    }
  }, [startupId, limit, currentPage, isRowTableLayout]);

  return (
    <div className="content-container">
      <div className="wallet-container">
        <RenderIf condition={isEitherAdmin(userType) && identity.isTruthyString(id)}>
          <Breadcrumb navigateTo={composeStartupDetailsUrl(id!)} routeName="Startup Details" />
        </RenderIf>
        <StartupWallet wallet={wallet} startupId={id} />
        <div className="table">
          <Table
            isLoading={isLoading}
            dataSource={walletTransactions}
            columns={walletOverviewColumns}
            pagination={false}
            setLimit={setLimit}
            setCurrentPage={setCurrentPage}
            showCustomPagination
            limit={limit}
            total={count}
            currentPage={currentPage}
            rowKey={getRowKey(walletTransactions, 'transaction', 'id')}
          />
        </div>
      </div>
    </div>
  );
}

export default WalletOverview;
