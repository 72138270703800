import React, { useEffect } from 'react';

import { DRAWERS, identity } from 'utils';
import { StartupStageProps } from 'types/settingsOverview';
import { DataEntryPointType } from 'packages/settings_repository';
import SettingsTable from '../../SettingsTable';
import { dataEntryColumns } from '../../../../utils/constants/columns';

const StartupStage = (props: StartupStageProps) => {
  const { stages, actions } = props;
  const {
    getStartupStages,
    deleteStartupStages,
    setDrawerBody,
    setIsDrawerOpen,
    setDataEntryPointDetail,
  } = actions;

  useEffect(() => {
    if (identity.isEmptyArray(stages)) {
      getStartupStages();
    }
  }, []);

  const onUpdate = (data: DataEntryPointType) => {
    setDataEntryPointDetail({ data });
    setDrawerBody({ drawerBody: DRAWERS.editDataEntry });
    setIsDrawerOpen({ isDrawerOpen: true });
  }

  return (
    <SettingsTable dataSource={stages} columns={dataEntryColumns(deleteStartupStages, onUpdate)} />
  );
}

export default StartupStage;
