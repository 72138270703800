import React from 'react';

import SVGWrap from './SVGWrap';

const PartnersIcon = () => (
  <SVGWrap>
    <path d="M9 15.5H10V14.5C10.0001 14.3674 10.0528 14.2403 10.1465 14.1465C10.2403 14.0528 10.3674 14.0001 10.5 14H13.5C13.6326 14.0001 13.7597 14.0528 13.8535 14.1465C13.9472 14.2403 13.9999 14.3674 14 14.5V15.5H15V14.5C14.9996 14.1023 14.8414 13.721 14.5602 13.4398C14.279 13.1586 13.8977 13.0004 13.5 13H10.5C10.1023 13.0004 9.72103 13.1586 9.43982 13.4398C9.15861 13.721 9.00044 14.1023 9 14.5V15.5Z" />
    <path d="M12 12.5C11.6044 12.5 11.2178 12.3827 10.8889 12.1629C10.56 11.9432 10.3036 11.6308 10.1522 11.2654C10.0009 10.8999 9.96126 10.4978 10.0384 10.1098C10.1156 9.72186 10.3061 9.36549 10.5858 9.08579C10.8655 8.80608 11.2219 8.6156 11.6098 8.53843C11.9978 8.46126 12.3999 8.50087 12.7654 8.65224C13.1308 8.80362 13.4432 9.05996 13.6629 9.38886C13.8827 9.71776 14 10.1044 14 10.5C13.9995 11.0303 13.7886 11.5387 13.4136 11.9136C13.0387 12.2886 12.5303 12.4995 12 12.5ZM12 9.5C11.8022 9.5 11.6089 9.55865 11.4444 9.66853C11.28 9.77841 11.1518 9.93459 11.0761 10.1173C11.0004 10.3 10.9806 10.5011 11.0192 10.6951C11.0578 10.8891 11.153 11.0673 11.2929 11.2071C11.4327 11.347 11.6109 11.4422 11.8049 11.4808C11.9989 11.5194 12.2 11.4996 12.3827 11.4239C12.5654 11.3482 12.7216 11.22 12.8315 11.0556C12.9414 10.8911 13 10.6978 13 10.5C12.9996 10.2349 12.8942 9.98075 12.7067 9.79329C12.5193 9.60583 12.2651 9.50036 12 9.5Z" />
    <path d="M1 15.5H2V14.5C2.00012 14.3674 2.05284 14.2403 2.14658 14.1466C2.24032 14.0528 2.36743 14.0001 2.5 14H5.5C5.63257 14.0001 5.75968 14.0528 5.85342 14.1466C5.94716 14.2403 5.99988 14.3674 6 14.5V15.5H7V14.5C6.99956 14.1023 6.84139 13.721 6.56018 13.4398C6.27897 13.1586 5.89769 13.0004 5.5 13H2.5C2.10231 13.0004 1.72103 13.1586 1.43982 13.4398C1.15861 13.721 1.00044 14.1023 1 14.5V15.5Z" />
    <path d="M4 12.5C3.60444 12.5 3.21776 12.3827 2.88886 12.1629C2.55996 11.9432 2.30362 11.6308 2.15224 11.2654C2.00087 10.8999 1.96126 10.4978 2.03843 10.1098C2.1156 9.72186 2.30608 9.36549 2.58579 9.08579C2.86549 8.80608 3.22186 8.6156 3.60982 8.53843C3.99778 8.46126 4.39991 8.50087 4.76537 8.65224C5.13082 8.80362 5.44318 9.05996 5.66294 9.38886C5.8827 9.71776 6 10.1044 6 10.5C5.99944 11.0303 5.78855 11.5386 5.4136 11.9136C5.03865 12.2886 4.53026 12.4994 4 12.5ZM4 9.5C3.80222 9.5 3.60888 9.55865 3.44443 9.66853C3.27998 9.77841 3.15181 9.93459 3.07612 10.1173C3.00043 10.3 2.98063 10.5011 3.01921 10.6951C3.0578 10.8891 3.15304 11.0673 3.29289 11.2071C3.43275 11.347 3.61093 11.4422 3.80491 11.4808C3.99889 11.5194 4.19996 11.4996 4.38268 11.4239C4.56541 11.3482 4.72159 11.22 4.83147 11.0556C4.94135 10.8911 5 10.6978 5 10.5C4.9997 10.2349 4.89424 9.9807 4.70677 9.79323C4.5193 9.60576 4.26512 9.50031 4 9.5Z" />
    <path d="M9 8H10V7C10.0001 6.86743 10.0528 6.74032 10.1466 6.64658C10.2403 6.55284 10.3674 6.50012 10.5 6.5H13.5C13.6326 6.50012 13.7597 6.55284 13.8534 6.64658C13.9472 6.74032 13.9999 6.86743 14 7V8H15V7C14.9996 6.60231 14.8414 6.22103 14.5602 5.93982C14.279 5.65861 13.8977 5.50044 13.5 5.5H10.5C10.1023 5.50044 9.72103 5.65861 9.43982 5.93982C9.15861 6.22103 9.00044 6.60231 9 7V8Z" />
    <path d="M12 5C11.6044 5 11.2178 4.8827 10.8889 4.66294C10.56 4.44318 10.3036 4.13082 10.1522 3.76537C10.0009 3.39992 9.96126 2.99778 10.0384 2.60982C10.1156 2.22186 10.3061 1.86549 10.5858 1.58579C10.8655 1.30608 11.2219 1.1156 11.6098 1.03843C11.9978 0.96126 12.3999 1.00087 12.7654 1.15224C13.1308 1.30362 13.4432 1.55996 13.6629 1.88886C13.8827 2.21776 14 2.60444 14 3C13.9994 3.53026 13.7886 4.03865 13.4136 4.4136C13.0386 4.78855 12.5303 4.99944 12 5ZM12 2C11.8022 2 11.6089 2.05865 11.4444 2.16853C11.28 2.27841 11.1518 2.43459 11.0761 2.61732C11.0004 2.80004 10.9806 3.00111 11.0192 3.19509C11.0578 3.38907 11.153 3.56726 11.2929 3.70711C11.4327 3.84696 11.6109 3.9422 11.8049 3.98079C11.9989 4.01937 12.2 3.99957 12.3827 3.92388C12.5654 3.84819 12.7216 3.72002 12.8315 3.55557C12.9414 3.39112 13 3.19778 13 3C12.9997 2.73488 12.8942 2.4807 12.7068 2.29323C12.5193 2.10576 12.2651 2.00031 12 2Z" />
    <path d="M1 8H2V7C2.00017 6.86745 2.05291 6.74037 2.14664 6.64664C2.24037 6.55291 2.36744 6.50017 2.5 6.5H5.5C5.63256 6.50017 5.75963 6.55291 5.85336 6.64664C5.94709 6.74037 5.99983 6.86745 6 7V8H7V7C6.99956 6.60231 6.84139 6.22103 6.56018 5.93982C6.27897 5.65861 5.89769 5.50044 5.5 5.5H2.5C2.10231 5.50044 1.72103 5.65861 1.43982 5.93982C1.15861 6.22103 1.00044 6.60231 1 7V8Z" />
    <path d="M4 5C3.60444 5 3.21776 4.8827 2.88886 4.66294C2.55996 4.44318 2.30362 4.13082 2.15224 3.76537C2.00087 3.39992 1.96126 2.99778 2.03843 2.60982C2.1156 2.22186 2.30608 1.86549 2.58579 1.58579C2.86549 1.30608 3.22186 1.1156 3.60982 1.03843C3.99778 0.96126 4.39991 1.00087 4.76537 1.15224C5.13082 1.30362 5.44318 1.55996 5.66294 1.88886C5.8827 2.21776 6 2.60444 6 3C5.9994 3.53025 5.7885 4.03861 5.41356 4.41356C5.03861 4.7885 4.53025 4.99941 4 5ZM4 2C3.80222 2 3.60888 2.05865 3.44443 2.16853C3.27998 2.27841 3.15181 2.43459 3.07612 2.61732C3.00043 2.80004 2.98063 3.00111 3.01921 3.19509C3.0578 3.38907 3.15304 3.56726 3.29289 3.70711C3.43275 3.84696 3.61093 3.9422 3.80491 3.98079C3.99889 4.01937 4.19996 3.99957 4.38268 3.92388C4.56541 3.84819 4.72159 3.72002 4.83147 3.55557C4.94135 3.39112 5 3.19778 5 3C4.99974 2.73487 4.89429 2.48067 4.70681 2.29319C4.51934 2.10571 4.26514 2.00027 4 2Z" />
  </SVGWrap>
);

export default PartnersIcon;
