import React, {
  RefObject, useRef, useState,
} from 'react';
import { Button, InputRef } from 'antd';

import { CheckmarkIcon, EditIcon, TrashCanIcon } from 'assets/icons';
import { AddItemProps } from 'types/componentTypes';
import Input from '../../../Input';

import './AddItem.scss';

const AddItem = React.memo((props: AddItemProps) => {
  const { onDelete, onEdit, itemName } = props;
  const [isEdit, setIsEdit] = useState(false);
  const ref = useRef<InputRef>();

  const saveChanges = () => {
    onEdit(itemName, ref.current?.input?.value as string);
    setIsEdit(false);
  }

  const onEditClick = () => {
    setIsEdit(true);
    setTimeout(() => ref.current?.focus());
  }

  return (
    <div className="add-item">
      {isEdit ? (
        <>
          <Input.InputField
            ref={ref as RefObject<InputRef>}
            defaultValue={itemName}
          />
          <Button
            icon={<CheckmarkIcon />}
            type="ghost"
            onClick={saveChanges}
          />
        </>
      )
        : (
          <>
            <p>{itemName}</p>
            <Button
              icon={<EditIcon />}
              type="ghost"
              onClick={onEditClick}
            />
            <Button
              icon={<TrashCanIcon />}
              type="ghost"
              onClick={() => onDelete(itemName)}
              className="delete"
            />
          </>
        )}
    </div>
  );
});

export default AddItem
