import React from 'react';
import { Button } from 'antd';

import Overview from '../../../../../components/Overview';
import { RequestedServiceProps } from '../../../../../../types/serviceProviders/servicesOverview';
import SERVICE_STATUS from '../../../../../../utils/constants/serviceStatusTypes';
import { ServiceProviderServicesType } from '../../../../../../types/serviceProviders/ServiceProviderDetails';

import './RequestedServiceRow.scss';

const RequestedServiceRow = (props: RequestedServiceProps) => {
  const { services, actions } = props;

  return (
    <div>
      { services.map((service: ServiceProviderServicesType) => (
        (service.serviceStatus === 'added' ? (
          <div className="requested-service">
            <Overview title="Requested Service">
              <div className="request-service__container">
                <div className="request-service__details">
                  <p className="request-service__details__name">{service.name}</p>
                  <p className="request-service__details__description">{service.about}</p>
                </div>
                <Button className="secondary-button" onClick={() => actions.setRequestedServiceStatus({ id: service.id, status: SERVICE_STATUS.removed, service })}>Remove</Button>
              </div>
            </Overview>
            <Button className="primary-button">Send request</Button>
          </div>
        ) : (
          <Overview title="Requested Service">
            <div className="request-service__container">
              <div className="request-service__details">
                <p className="request-service__details__description">There are currently no requests</p>
              </div>
            </div>
          </Overview>
        ))
      )) }
    </div>
  );
}
export default RequestedServiceRow;
