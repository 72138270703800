import isFalsy from '../isFalsy';

const identity: IdentityFuncMap = {
  isFalsy,
  isFunction: (data) => typeof data === 'function',
  isZero: (data) => typeof data === 'number' && data === 0,
  isTruthyNumber: (data) => [
    typeof data === 'number',
    data !== 0,
    !isNaN(data),
  ].every(Boolean),
  isPositiveNumber: (data) => [
    typeof data === 'number',
    data > 0,
    !isNaN(data),
  ].every(Boolean),
  isTruthyString: (data) => typeof data === 'string' && data.length > 0,
  isEmptyString: (data) => typeof data === 'string' && data.length === 0,
  isNotNull: (data) => typeof data === 'object' && data !== null,
  isObjWithChildren(data) {
    if (this.isNotNull(data)) {
      return Object.keys(data).length > 0;
    }
    return false;
  },
  isNotArray: (data) => !Array.isArray(data),
  isArray: (data) => Array.isArray(data),
  isFullArray(data) {
    if (this.isNotArray(data)) {
      return false;
    }
    return Array.isArray(data) && data.length > 0;
  },
  isEmptyArray(data) {
    if (this.isNotArray(data)) {
      return true;
    }

    return Array.isArray(data) && data.length === 0;
  },
  isChild(data) {
    const _child = data;
    return {
      isNotNull: this.isNotNull,
      ofArray: (arr) => Array.isArray(arr) && arr.includes(_child),
      ofObject: (obj: object = {}) => {
        if (this.isNotNull(obj)) {
          return Object.hasOwnProperty.call(obj, _child);
        }
        return false;
      },
    };
  },
};

interface IdentityFuncMap {
  isFalsy: (data: any) => boolean;
  isFunction: (data: any) => boolean;
  isZero: (data: any) => boolean;
  isTruthyNumber: (data: any) => boolean;
  isPositiveNumber: (data: any) => boolean;
  isTruthyString: (data: any) => boolean;
  isEmptyString: (data: any) => boolean;
  isNotNull: (data: any) => boolean;
  isObjWithChildren: (data: any) => boolean;
  isNotArray: (data: any) => boolean;
  isFullArray: (data: any) => boolean;
  isEmptyArray: (data: any) => boolean;
  isChild: (data: any) => ChildIdFuncMap;
  isArray: (data: any) => boolean,
}

interface ChildIdFuncMap {
  ofArray: (array: any[]) => boolean;
  ofObject: (obj: object) => boolean;
  isNotNull: (data: any) => boolean;
}

export default identity;
