import React, { useState } from 'react';
import { Button } from 'antd';
import classnames from 'classnames';

import { SearchBarProps } from 'types/componentTypes';
import { identity } from 'utils';
import { SearchIcon } from 'assets/icons';
import {
  LeftContent,
  DisplayButtons,
  TypeButton,
  FilterButton,
  AllCategoriesButton,
} from './components';
import RenderIf from '../RenderIf';
import Input from '../Input';
import ExportDataButton from './components/ExportDataButton';
import { filtersDropdown } from './utils/constants';

import './SearchBar.scss';

const SearchBar = (props: SearchBarProps) => {
  const {
    leftContent,
    showSearchBar,
    showSearchField,
    showFilterButton,
    showTypeButton,
    showDisplayButtons,
    showAllCategories,
    filtersActive,
    searchString,
    showExportButton,
    exportDataModel,
    filterButtonType,
    filterDropdownElements,
  } = props;

  const [showDropdown, setShowDropdown] = useState<boolean>(false);

  const Body = filtersDropdown[filterDropdownElements!]?.body;

  if (!showSearchBar) {
    return null;
  }

  return (
    <div className={classnames('search-bar', {
      'search-bar-no-button': identity.isFalsy(leftContent),
      'search-bar-only-filter': [
        identity.isFalsy(leftContent),
        !showSearchField,
        !showTypeButton,
        !showDisplayButtons,
        !showAllCategories,
      ].every(Boolean),
    })}
    >
      <LeftContent
        leftContent={leftContent}
        addButtonText={props.addButtonText}
        onAddButtonClick={props.onAddButtonClick}
        infoText={props.infoText}
      />
      <div className={classnames('filter-container', { 'filter-container-no-input': identity.isFalsy(showSearchField) })}>
        <RenderIf condition={showSearchField}>
          <Input className="search-bar__search-field" layout="vertical">
            <Input.InputField
              prefix={<Button className="button-icon" icon={<SearchIcon />} type="text" />}
              placeholder="Search..."
              bordered={false}
              type="text"
              defaultValue={searchString}
              onChange={(e) => props.setSearchString!(e.target.value)}
            />
          </Input>
        </RenderIf>
        <TypeButton showTypeButton={showTypeButton} />
        <DisplayButtons showDisplayButtons={showDisplayButtons} />
        <AllCategoriesButton showAllCategories={showAllCategories} />
        <RenderIf condition={filterButtonType === 'default'}>
          <FilterButton
            showFilterButton={showFilterButton}
            onClickFilterButton={props.onClickFilterButton}
            filtersActive={filtersActive}
          />
        </RenderIf>
        <RenderIf condition={filterButtonType === 'dropdown'}>
          <div className={classnames('filters-dropdown__container', { 'filters-dropdown__container--shown': showDropdown })}>
            <RenderIf condition={!showDropdown}>
              <FilterButton
                onClickFilterButton={() => setShowDropdown(true)}
                filtersActive={filtersActive}
              />
            </RenderIf>
            <RenderIf condition={showDropdown && identity.isTruthyString(filterDropdownElements)}>
              <Body setShowDropdown={setShowDropdown} />
            </RenderIf>
          </div>
        </RenderIf>
        <ExportDataButton showExportButton={showExportButton} exportDataModel={exportDataModel} />
      </div>
    </div>
  );
}

SearchBar.defaultProps = {
  showDisplayButtons: false,
  leftContent: undefined,
  addButtonText: '',
  showSearchBar: false,
  title: '',
  showSearchField: false,
  showFilterButton: false,
  filtersActive: false,
  searchString: '',
  setSearchString: () => null,
  filterDropdownElements: '',
}

export default SearchBar;
