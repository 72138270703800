/* eslint-disable react/jsx-props-no-spreading */
// Spreading props because IconButton accepts the same props as antd button
import React from 'react';
import { Button, ButtonProps } from 'antd';

import '../../TitleWithActions.scss';

const IconButton = (
  props: Omit<ButtonProps, 'type' | 'shape' | 'className'>,
) => <Button type="primary" shape="circle" className="button" {...props} />;

export default IconButton;
