import React, { useState } from 'react';

import requiredValidation from 'utils/constants/validationRules';
import identity from 'utils/identity';
import {
  passwordConfirmationValidation,
  passwordLengthValidation,
  validatePasswordsConfirmation,
} from './utils/constants';
import PasswordField from './components/PasswordField';

import './ConfirmPassword.scss';

const ConfirmPassword = () => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const confirmPasswordsAreSame = async () => {
    if (identity.isTruthyString(confirmPassword)) {
      validatePasswordsConfirmation(password, confirmPassword);
    }
  };

  return (
    <div className="confirm-password-container">
      <PasswordField
        label="New Password"
        name="password"
        rules={[
          requiredValidation,
          passwordLengthValidation,
        ]}
        value={password}
        onChange={(e) => setPassword(e.target.value)}
      />
      <PasswordField
        label="Confirm Password"
        name="confirmPassword"
        rules={[
          requiredValidation,
          { ...passwordConfirmationValidation, validator: confirmPasswordsAreSame },
        ]}
        value={confirmPassword}
        onChange={(e) => setConfirmPassword(e.target.value)}
      />
    </div>
  );
};

export default ConfirmPassword;
