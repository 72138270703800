import { connect } from 'react-redux';
import { bindActionCreators, Dispatch, Action } from 'redux';

import { StateType } from '../../types';
import { selectRequestDetails } from '../../redux/selectors/request';
import { getRequestById } from '../../redux/actions/request';

const mapStateToProps = (state: StateType) => ({
  request: selectRequestDetails(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  actions: bindActionCreators({ getRequestById }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps);
