import { connect } from 'react-redux';
import { bindActionCreators, Dispatch, Action } from 'redux';

import { getRequestsByOwnerId } from 'redux/actions/request';
import { getServiceProviderById, getServicesByServiceProviderId } from 'redux/actions/serviceProviders';
import {
  selectServiceProviderLoading,
  selectServiceProviderAboutInfo,
  selectServiceProviderRequests,
  selectServiceProviderStartups,
  selectServicesByServiceProviderId,
} from 'redux/selectors/serviceProviders';
import { StateType } from 'types';
import { getProviderServicesWithStartup, getStartupById, getStartupsByOwnerId } from 'redux/actions/startups';
import { selectUserServiceProviderId, selectUserType } from 'redux/selectors/auth';

const mapStateToProps = (state: StateType) => ({
  requests: selectServiceProviderRequests(state),
  startups: selectServiceProviderStartups(state),
  aboutInfo: selectServiceProviderAboutInfo(state),
  isLoading: selectServiceProviderLoading(state),
  userType: selectUserType(state),
  services: selectServicesByServiceProviderId(state),
  userServiceProviderId: selectUserServiceProviderId(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  actions: bindActionCreators({
    getServiceProviderById,
    getRequestsByOwnerId,
    getStartupsByOwnerId,
    getStartupById,
    getProviderServicesWithStartup,
    getServicesByServiceProviderId,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps);
