import { Action, bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';

import { changePassword } from 'redux/actions/auth';
import { selectIsLoading } from 'redux/selectors/auth';
import { StateType } from 'types';
import { selectResetForm } from 'redux/selectors/workspace';

const mapStateToProps = (state: StateType) => ({
  isLoading: selectIsLoading(state),
  resetForm: selectResetForm(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  actions: bindActionCreators({
    changePassword,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps);
