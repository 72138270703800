import React, { useEffect, useState } from 'react';
import { Button, Form, Spin } from 'antd';
import { RcFile } from 'antd/lib/upload/interface';

import { identity, isEitherAdmin } from 'utils';
import { SPPartnerUserProps } from 'types/user';
import {
  Breadcrumb,
  EditProfileImage,
  FormBuilder,
  Input,
  TitleWithActions,
} from 'components/components';
import routes from 'routes';
import { scrollToFirstError } from 'components/components/FormBuilder/utils/helpers';
import { PartnerMemberType, SPMemberType } from 'packages/user_repository';
import MyProfileChangePassword from 'components/MyProfileChangePassword';
import { editAdminFields, editPartnerMemberFormFields } from '../../utils/formFields';

const EditPartnerSPMember = (props: SPPartnerUserProps) => {
  const { firstName, lastName, id } = props.user;
  const [photo, setPhoto] = useState('');

  useEffect(() => {
    if (!identity.isPositiveNumber(id)) {
      props.actions.getMyProfileData();
    }
  }, [id]);

  const [form] = Form.useForm();

  useEffect(() => {
    setPhoto(props.user.photo as string);
  }, [props.user]);

  useEffect(() => {
    form.setFieldsValue(props.user);
    form.setFieldValue('photo', props.user.photo);
  }, [props.user]);

  const handleSubmit = (data: PartnerMemberType | SPMemberType) => {
    props.actions.editMyProfileData({ data: { ...data, photo: form.getFieldValue('photo') } });
  }

  const handleBeforeUpload = (file: RcFile) => {
    form.setFieldValue('photo', file as Blob);
    return false;
  }

  return (
    <Spin spinning={props.isLoading}>
      <div className="edit-my-profile">
        <Breadcrumb navigateTo={routes.myProfileUrl} routeName="My profile" />
        <TitleWithActions title={`${firstName} ${lastName}`} />
        <Form
          onFinish={handleSubmit}
          onFinishFailed={scrollToFirstError}
          validateTrigger="submit"
          form={form}
        >
          <div className="edit-my-profile__fields">
            <EditProfileImage imageLink={photo} beforeLogoUpload={handleBeforeUpload} />
            <FormBuilder
              oneColumn
              layout="horizontal"
              fields={isEitherAdmin(props.userType) ? editAdminFields : editPartnerMemberFormFields}
              formFragment
            />
            <Button htmlType="submit" className="primary-button">save</Button>
          </div>
        </Form>
        <Input.Heading title="Change Password" />
        <MyProfileChangePassword />
      </div>
    </Spin>
  );
}

export default EditPartnerSPMember;
