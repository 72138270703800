import React from 'react';
import { Modal } from 'antd';

import { CloseIcon } from 'assets/icons';
import { DiscardModalProps } from 'types/componentTypes/StepsForm';

import './DiscardModal.scss';

const DiscardModal = (props: DiscardModalProps) => {
  const {
    isOpen,
    onCancel,
    onDiscard,
    text,
    modalTitle,
    okButtonText,
    cancelButtonText,
    onClose,
  } = props;

  return (
    <Modal
      open={isOpen}
      onOk={onDiscard}
      onCancel={() => onClose()}
      cancelButtonProps={{ onClick: () => onCancel() }}
      okText={okButtonText}
      cancelText={cancelButtonText}
      closeIcon={<CloseIcon />}
      title={modalTitle}
      className="discard-modal"
    >
      {text}
    </Modal>
  );
}

export default DiscardModal;
