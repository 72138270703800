import { createSelector } from 'reselect';

import { StateType } from 'types';
import { SettingsOverviewStateType } from 'types/reduxTypes/StateTypes';

const selectSettingsOverviewState = (state: StateType) => state.settingsOverviewReducer;

export const selectEmailTemplates = createSelector(
  selectSettingsOverviewState,
  (state: SettingsOverviewStateType) => state.emailTemplates,
);

export const selectIncentiveTemplates = createSelector(
  selectSettingsOverviewState,
  (state: SettingsOverviewStateType) => state.incentiveTemplates,
);

export const selectWorkOrderTemplates = createSelector(
  selectSettingsOverviewState,
  (state: SettingsOverviewStateType) => state.workOrderTemplates,
);

export const selectFileContent = createSelector(
  selectSettingsOverviewState,
  (state: SettingsOverviewStateType) => state.fileContent,
);

export const selectActiveTab = createSelector(
  selectSettingsOverviewState,
  (state: SettingsOverviewStateType) => state.activeTab,
);

export const selectActiveSubTab = createSelector(
  selectSettingsOverviewState,
  (state: SettingsOverviewStateType) => state.activeSubTab,
);

export const selectDeleteIds = createSelector(
  selectSettingsOverviewState,
  (state: SettingsOverviewStateType) => state.deleteIds,
);

export const selectProgramTypes = createSelector(
  selectSettingsOverviewState,
  (state: SettingsOverviewStateType) => state.programTypes,
);

export const selectIsLoading = createSelector(
  selectSettingsOverviewState,
  (state: SettingsOverviewStateType) => state.isLoading,
);

export const selectStartupStages = createSelector(
  selectSettingsOverviewState,
  (state: SettingsOverviewStateType) => state.startupStages,
);

export const selectEducation = createSelector(
  selectSettingsOverviewState,
  (state: SettingsOverviewStateType) => state.education,
);

export const selectTemplateDetail = createSelector(
  selectSettingsOverviewState,
  (state: SettingsOverviewStateType) => state.details.template,
);

export const selectDataEntryPointDetail = createSelector(
  selectSettingsOverviewState,
  (state: SettingsOverviewStateType) => state.details.dataEntryPoint,
);

export const selectTitles = createSelector(
  selectSettingsOverviewState,
  (state: SettingsOverviewStateType) => state.titles,
);

export const selectProgramTypeDetails = createSelector(
  selectSettingsOverviewState,
  (state: SettingsOverviewStateType) => state.details.programType,
);
