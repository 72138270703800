import { connect } from 'react-redux';
import { Action, bindActionCreators, Dispatch } from 'redux';

import { setStartupForReview } from 'redux/actions/startups';
import { setDrawerBody, setIsDrawerOpen } from 'redux/actions/workspace';

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  actions: bindActionCreators({
    setDrawerBody, setIsDrawerOpen, setStartupForReview,
  }, dispatch),
});

export default connect(null, mapDispatchToProps);
