import { connect } from 'react-redux';

import { StateType } from '../../../../types';
import { selectPersonDetails, selectStartupDetails } from '../../../../redux/selectors/request';

const mapStateToProps = (state: StateType) => ({
  personDetails: selectPersonDetails(state),
  startup: selectStartupDetails(state),
});

export default connect(mapStateToProps);
