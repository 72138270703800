import { StepFieldObject } from 'types/componentTypes/StepsForm';
import {
  RaisingInformationFields,
  InvestmentInformationFields,
  PitchDeckFields,
  ReviewComponent,
} from '../components';

import {
  basicInformationFields,
  foundersAndEmployeesFields,
  goalsAndChallengesFields,
} from './formFields';

export const steps: StepFieldObject[] = [
  {
    title: 'Basic information',
    stepFields: basicInformationFields,
  },
  {
    title: 'Founders and employees',
    stepFields: foundersAndEmployeesFields,
  },
  {
    title: 'Raising information',
    customFieldsComponent: RaisingInformationFields,
  },
  {
    title: 'Investment information',
    customFieldsComponent: InvestmentInformationFields,
  },
  {
    title: 'Goals and challenges',
    stepFields: goalsAndChallengesFields,
  },
  {
    title: 'Pitch deck',
    customFieldsComponent: PitchDeckFields,
  },
  {
    title: 'Review',
    customFieldsComponent: ReviewComponent,
  },
];

export const discardModalText = 'Any unsaved changes will be lost, so make sure to save your report as draft if you want to continue later';

export const defaultValues = {
  currentlyRaising: '0',
  bridgeRound: '0',
  interestedInInternational: '0',
  sharePitchDeckWithPartners: '0',
}
