import { connect } from 'react-redux';
import { bindActionCreators, Dispatch, Action } from 'redux';

import { createCohort, getPrograms } from '../../redux/actions/cohort';
import { getEmailTemplates } from '../../redux/actions/settingsOverview';
import { StateType } from '../../types';
import { selectEmailTemplates } from '../../redux/selectors/settingsOverview';
import { selectPrograms } from '../../redux/selectors/cohort';

const mapStateToProps = (state: StateType) => ({
  emailTemplates: selectEmailTemplates(state),
  programs: selectPrograms(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  actions: bindActionCreators({
    createCohort,
    getEmailTemplates,
    getPrograms,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps);
