import { UserRoleType } from 'packages/authentication_repository';
import { isIn } from 'packages/http_client';
import { REPORT_STATUS_ALTERNATIVE_NAMES_BY_ROLE, REPORT_STATUS_ORIGINAL_NAMES } from 'packages/performance_repository';
import { REPORT_STATUS_NUMBERS_BY_ROLE } from './constants';

export const getTabWithCountByUserType = (counts: { [key: string]: number}, tab: string, userType: UserRoleType) => {
  const count = counts[REPORT_STATUS_ORIGINAL_NAMES[tab] || tab];
  return `${REPORT_STATUS_ALTERNATIVE_NAMES_BY_ROLE[userType][tab] || tab} (${count})`;
};

export const getStatusInParamsByRole = (userType: UserRoleType) => (
  isIn(REPORT_STATUS_NUMBERS_BY_ROLE[userType])
);
