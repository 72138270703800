import React from 'react';
import { Button } from 'antd';

import { DRAWERS, isEitherAdmin } from 'utils';
import { RenderIf } from 'components/components';
import { EventButtonsProps } from 'types/componentTypes';
import { RSVPButton } from '..';

import './EventButtons.scss';

const EventButtons = (props: EventButtonsProps) => {
  const {
    userType, eventResponse, eventStatus, actions,
  } = props;

  const onViewDetails = () => {
    actions.setDrawerBody({ drawerBody: DRAWERS.editEvent });
    actions.setIsDrawerOpen({ isDrawerOpen: true });
    actions.setEventId(({ data: props.id }));
  }

  return (
    <div className="event-buttons__container">
      <RenderIf condition={!isEitherAdmin(userType)}>
        <RSVPButton eventStatus={eventStatus} eventResponse={eventResponse!} id={props.id} event={props.event} />
      </RenderIf>
      <Button className="secondary-button md" onClick={onViewDetails}>View details</Button>
    </div>
  );
}

export default EventButtons;
