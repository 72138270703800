import { connect } from 'react-redux';
import { Action, bindActionCreators, Dispatch } from 'redux';

import { selectInvestmentStageOptions, selectSectorOptions, selectStageOptions } from 'redux/selectors/startups';
import { setIsDrawerOpen } from '../../redux/actions/workspace';
import {
  createStartup, getStartupSectors, getStartupStages, getStartupInvestmentStages,
} from '../../redux/actions/startups';
import { getCohorts } from '../../redux/actions/cohort';
import { selectCohorts } from '../../redux/selectors/cohort';
import { StateType } from '../../types';

const mapStateToProps = (state: StateType) => ({
  cohorts: selectCohorts(state),
  sectorOptions: selectSectorOptions(state),
  stageOptions: selectStageOptions(state),
  investmentStageOptions: selectInvestmentStageOptions(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  actions: bindActionCreators({
    setIsDrawerOpen,
    createStartup,
    getCohorts,
    getStartupSectors,
    getStartupStages,
    getStartupInvestmentStages,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps);
