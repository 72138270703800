import { AxiosRequestConfig } from 'axios';

import httpClient, { ApiResponseType, FORM_DATA_CONFIG, queryBuilder } from '../../http_client';
import {
  ADGMLicenseType,
  CreateADGMLicenseApiResponseType,
  CreateADGMLicensePayload,
  ResponseADGMLicenseType,
  UpdateAdgmLicenseByStartupIdApiResponseType,
} from '../types';
import {
  convertCreateADMGLicenseRequestPayload,
  convertCreateStartupApiId,
  convertEditAdgmLicenseByStartupIdRequestPayload,
  convertGetAdGMLicenseResponse,
  STARTUP_ADGM_LICENSE,
} from '../utils';

const createStartupADGMLicenseApi = (payload: CreateADGMLicensePayload) => {
  const url = STARTUP_ADGM_LICENSE;
  const requestPayload = convertCreateADMGLicenseRequestPayload(payload);
  return httpClient.post<ApiResponseType<CreateADGMLicenseApiResponseType>>(url, requestPayload, FORM_DATA_CONFIG);
}

const getStartupADGMLicenseByStartupIdApi = (id: number, isPreload: boolean) => {
  const url = `${STARTUP_ADGM_LICENSE}`;
  const params = queryBuilder({ startup_id: id });
  if (isPreload) {
    params.preload();
  }
  const config: AxiosRequestConfig = { params: params.toObject() };
  return httpClient.get<ApiResponseType<ResponseADGMLicenseType>>(url, config);
}

const updateAdgmLicenseByStartupIdApi = (id: number, payload: ADGMLicenseType) => {
  const url = `${STARTUP_ADGM_LICENSE}/${id}`;
  const requestPayload = convertEditAdgmLicenseByStartupIdRequestPayload(payload);
  return httpClient.put<ApiResponseType<UpdateAdgmLicenseByStartupIdApiResponseType>>(url, requestPayload);
};

export const updateAdgmLicenseByStartupId = async (id: number, payload: ADGMLicenseType) => {
  const response = await updateAdgmLicenseByStartupIdApi(id, payload);
  const { error, httpStatus } = response;
  return { error, httpStatus };
};

export const getStartupADGMLicenseByStartupId = async (id: number, isPreload: boolean): Promise<
  ApiResponseType<ADGMLicenseType>> => {
  const response = await getStartupADGMLicenseByStartupIdApi(id, isPreload);
  const { data, error, httpStatus } = response;
  return { error, httpStatus, data: convertGetAdGMLicenseResponse(data?.result![0]) };
};

export const createStartupADGMLicense = async (payload: CreateADGMLicensePayload) => {
  const response = await createStartupADGMLicenseApi(payload);
  const { data, error, httpStatus } = response;
  return { error, httpStatus, data: convertCreateStartupApiId(data?.id) };
}
