import { UserRoleType } from 'types/auth';
import { ROLES } from '../constants';

const isStartupFounder = (userType: UserRoleType) => userType === ROLES.startupFounder;

const isStartupTeamMember = (userType: UserRoleType) => userType === ROLES.startupTeamMember;

const isStartup = (userType: UserRoleType) => isStartupFounder(userType) || isStartupTeamMember(userType);

const isAdmin = (userType: UserRoleType) => userType === ROLES.admin;

const isSuperAdmin = (userType: UserRoleType) => userType === ROLES.superAdmin;

const isEitherAdmin = (userType: UserRoleType) => isAdmin(userType) || isSuperAdmin(userType);

const isServiceProvider = (userType: UserRoleType) => userType === ROLES.serviceProvider;

const isPartner = (userType: UserRoleType) => userType === ROLES.partner;

export {
  isServiceProvider,
  isAdmin,
  isEitherAdmin,
  isSuperAdmin,
  isStartupFounder,
  isStartupTeamMember,
  isStartup,
  isPartner,
}
