import React, { useEffect, useMemo, useState } from 'react';
import { Drawer, Button, Input } from 'antd';

import { FilterTableDrawerProps } from 'types/componentTypes';
import { identity } from 'utils/';
import { SearchIcon } from 'assets/icons';
import { RowTable } from '../../../Table';
import RenderIf from '../../../RenderIf';

import './FilterTableDrawer.scss';

const FilterTableDrawer = (props: FilterTableDrawerProps) => {
  const {
    actions,
    rowKey,
    rowSelection,
    columns,
    dataSource,
    showHeader,
    showButtons,
    rowClassName,
    drawerTitle,
    placeholder,
    filterBy,
    type,
    onRow,
    openDrawer,
    onClose,
    onResetAll,
    isFilterTableOpen,
  } = props;

  const [filter, setFilter] = useState<string>('');

  useEffect(() => () => actions.setIsDrawerFilterTableOpen({ isDrawerFilterTableOpen: false }), []);

  const filteredDataSource = useMemo(
    () => (identity.isTruthyString(filter) ? dataSource?.filter((item) => (
      (item?.[filterBy as string] as string)?.toLowerCase()?.includes(filter.toLowerCase())))
      : dataSource),
    [filter, dataSource, filterBy],
  );

  const onDrawerClose = () => {
    actions.setIsDrawerFilterTableOpen({ isDrawerFilterTableOpen: false });
    if (identity.isFunction(onClose)) {
      onClose!();
    }
  }

  const resetAll = () => {
    if (identity.isFunction(onResetAll)) {
      onResetAll!();
    }
  }

  const drawerClass = 'filter-table-drawer';

  return (
    <Drawer
      className="drawer"
      open={openDrawer && isFilterTableOpen}
      placement="right"
      title={drawerTitle}
      onClose={onDrawerClose}
    >
      <div className={`${drawerClass} ${type === 'radio' ? `${drawerClass}--hidden-select` : `${drawerClass}--select`}`}>
        <div className="input">
          <Input
            prefix={<SearchIcon />}
            placeholder={`Search ${placeholder}`}
            bordered={false}
            type="text"
            onChange={(e) => setFilter(e.target.value)}
          />
        </div>
        <RowTable
          rowKey={rowKey}
          pagination={false}
          rowSelection={rowSelection}
          rowClassName={rowClassName}
          columns={columns}
          dataSource={filteredDataSource}
          showHeader={showHeader}
          onRow={onRow}
          className="table"
        />
        <RenderIf condition={showButtons!}>
          <div className="add-people-table__actions">
            <Button className="secondary-button" onClick={resetAll}>Clear all</Button>
            <Button onClick={onDrawerClose} className="primary-button">Done</Button>
          </div>
        </RenderIf>
      </div>
    </Drawer>
  )
};

FilterTableDrawer.defaultProps = {
  showButtons: true,
  type: 'radio',
  showHeader: true,
}

export default FilterTableDrawer;
