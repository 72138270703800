import { EventsStateType } from 'types/reduxTypes/StateTypes';
import { EventType } from 'types/events';
import { CountryType } from 'packages/country_repository';
import { EVENT_STATUS } from 'packages/events_repository';

export const defaultEventsState: EventsStateType = {
  filtersActive: false,
  events: [],
  currentTab: EVENT_STATUS['1'],
  filters: {},
  isLoading: false,
  currentSubTab: 'all',
  count: {
    upcoming: 0,
    past: 0,
  },
  responseCount: {
    going: 0,
    notGoing: 0,
    interested: 0,
    all: 0,
    notConfirmed: 0,
  },
  eventCategories: [],
  event: {
    id: -1,
    active: false,
    address: '',
    city: '',
    category: {
      id: -1,
      name: '',
    },
    country: {
      code: '',
      id: -1,
      name: '',
    },
    description: '',
    endingTime: '',
    frequency: 0,
    image: '',
    link: '',
    location: '',
    name: '',
    private: false,
    startingTime: '',
    status: '-',
    type: '-',
  },
  id: -1,
  details: {
    activeTab: 'Overview',
    eventAttendees: [],
    eventRSVP: {
      response: 'not confirmed',
    },
  },
};

const countryDefault: CountryType = { code: '', id: 0, name: '' }

export const defaultEvent: EventType = {
  id: 0,
  active: false,
  address: '',
  city: '',
  category: {
    id: 0,
    name: '',
  },
  country: countryDefault,
  description: '',
  endingTime: '',
  frequency: 0,
  image: '',
  link: '',
  location: '',
  name: '',
  private: false,
  startingTime: '',
  status: 'upcoming',
  type: '-',
}
