import React from 'react';

import SVGWrap from './SVGWrap';

const RequestIcon = () => (
  <SVGWrap>
    <path xmlns="http://www.w3.org/2000/svg" d="M11 11V14H3V2H8V1H3C2.73478 1 2.48043 1.10536 2.29289 1.29289C2.10536 1.48043 2 1.73478 2 2V14C2 14.2652 2.10536 14.5196 2.29289 14.7071C2.48043 14.8946 2.73478 15 3 15H11C11.2652 15 11.5196 14.8946 11.7071 14.7071C11.8946 14.5196 12 14.2652 12 14V11H11Z" />
    <path xmlns="http://www.w3.org/2000/svg" d="M14.77 2.88L13.12 1.23C12.9705 1.08342 12.7694 1.00131 12.56 1.00131C12.3506 1.00131 12.1495 1.08342 12 1.23L5 8.23V11H7.765L14.765 4C14.9116 3.85046 14.9937 3.6494 14.9937 3.44C14.9937 3.2306 14.9116 3.02954 14.765 2.88H14.77ZM7.35 10H6V8.65L10.72 3.925L12.075 5.28L7.35 10ZM12.78 4.575L11.425 3.22L12.56 2.085L13.915 3.44L12.78 4.575Z" />
  </SVGWrap>
);

export default RequestIcon;
