import React from 'react';

import SVGWrap from './SVGWrap';

const AddImageIcon = () => (
  <SVGWrap>
    <path xmlns="http://www.w3.org/2000/svg" d="M2 10V11H4.293L1 14.293L1.707 15L5 11.707V14H6V10H2Z" />
    <path xmlns="http://www.w3.org/2000/svg" d="M9.5 7C9.79667 7 10.0867 6.91203 10.3334 6.7472C10.58 6.58238 10.7723 6.34811 10.8858 6.07403C10.9994 5.79994 11.0291 5.49834 10.9712 5.20736C10.9133 4.91639 10.7704 4.64912 10.5607 4.43934C10.3509 4.22956 10.0836 4.0867 9.79264 4.02882C9.50166 3.97094 9.20006 4.00065 8.92597 4.11418C8.65189 4.22771 8.41762 4.41997 8.2528 4.66664C8.08797 4.91332 8 5.20333 8 5.5C8 5.89782 8.15804 6.27936 8.43934 6.56066C8.72064 6.84196 9.10218 7 9.5 7ZM9.5 5C9.59889 5 9.69556 5.02932 9.77779 5.08427C9.86001 5.13921 9.9241 5.2173 9.96194 5.30866C9.99978 5.40002 10.0097 5.50055 9.99039 5.59755C9.9711 5.69454 9.92348 5.78363 9.85355 5.85355C9.78363 5.92348 9.69454 5.9711 9.59755 5.99039C9.50056 6.00969 9.40002 5.99978 9.30866 5.96194C9.2173 5.9241 9.13921 5.86001 9.08426 5.77779C9.02932 5.69556 9 5.59889 9 5.5C9 5.36739 9.05268 5.24021 9.14645 5.14645C9.24021 5.05268 9.36739 5 9.5 5Z" />
    <path xmlns="http://www.w3.org/2000/svg" d="M13 2H3C2.73478 2 2.48043 2.10536 2.29289 2.29289C2.10536 2.48043 2 2.73478 2 3V8H3V3H13V10.585L11.205 8.79C11.0176 8.60375 10.7642 8.49921 10.5 8.49921C10.2358 8.49921 9.98236 8.60375 9.795 8.79L9 9.585L5.9154 6.5L5.20785 7.20775L8.295 10.295C8.48236 10.4813 8.73581 10.5858 9 10.5858C9.26419 10.5858 9.51764 10.4813 9.705 10.295L10.5 9.5L13 12V13H8V14H13C13.2652 14 13.5196 13.8946 13.7071 13.7071C13.8946 13.5196 14 13.2652 14 13V3C14 2.73478 13.8946 2.48043 13.7071 2.29289C13.5196 2.10536 13.2652 2 13 2Z" />
  </SVGWrap>
);

export default AddImageIcon;
