import { DataPayload, IdPayload, IsLoadingPayload } from 'types/reduxTypes/ActionTypes';
import {
  EventResponseCount,
  EventsCount,
  EventsFilters,
  EventsQuery,
  EventState,
} from 'types/events';
import {
  CreateEventPayload,
  EventCategory,
  EventRSVPBeType,
  EventRSVPResponseType,
  EventAttendeeType,
  EventRSVPType,
  EventType,
  UpdateEventPayload,
} from 'packages/events_repository';
import { SetActiveEventTabPayload } from 'types/reduxTypes/ActionTypes/EventsTypes';
import { ParamsPayload } from 'packages/http_client';

export const types = {
  GET_EVENTS: 'GET_EVENTS',
  SET_EVENTS: 'SET_EVENTS',
  SET_EVENT_TAB: 'SET_EVENT_TAB',
  ADD_EVENT: 'ADD_EVENT',
  SET_FILTERS: 'SET_FILTERS',
  SET_EVENTS_IS_LOADING: 'SET_EVENTS_IS_LOADING',
  SET_EVENT_SUB_TAB: 'SET_EVENT_SUB_TAB',
  SET_EVENTS_COUNT: 'SET_EVENTS_COUNT',
  GET_EVENTS_COUNT: 'GET_EVENTS_COUNT',
  SET_EVENTS_RESPONSE_COUNT: 'SET_EVENTS_RESPONSE_COUNT',
  GET_EVENTS_RESPONSE_COUNT: 'GET_EVENTS_RESPONSE_COUNT',
  GET_EVENT_CATEGORIES: 'GET_EVENT_CATEGORIES',
  SET_EVENT_CATEGORIES: 'SET_EVENT_CATEGORIES',
  SET_EVENT_RSVP: 'SET_EVENT_RSVP',
  GET_EVENT_BY_ID: 'GET_EVENT_BY_ID',
  SET_EVENT_BY_ID: 'SET_EVENT_BY_ID',
  DELETE_EVENT_BY_ID: 'DELETE_EVENT_BY_ID',
  SET_EVENT_ID: 'SET_EVENT_ID',
  SET_ACTIVE_EVENT_DETAILS_TAB: 'SET_ACTIVE_EVENT_DETAILS_TAB',
  GET_EVENT_ATTENDEES: 'GET_EVENT_ATTENDEES',
  SET_EVENT_ATTENDEES: 'SET_EVENT_ATTENDEES',
  UPDATE_EVENT_BY_ID: 'UPDATE_EVENT_BY_ID',
  GET_EVENT_RSVP: 'GET_EVENT_RSVP',
  UPDATE_EVENT_RSVP: 'UPDATE_EVENT_RSVP',
  RESET_EVENTS: 'RESET_EVENTS',
}

export const resetEvents = (payload: object = {}) => ({
  type: types.RESET_EVENTS,
  payload,
});

export const setEventSubTab = (payload: DataPayload<EventRSVPResponseType>) => ({
  type: types.SET_EVENT_SUB_TAB,
  payload,
});

export const setEventsCount = (payload: DataPayload<EventsCount>) => ({
  type: types.SET_EVENTS_COUNT,
  payload,
});

export const getEventsCount = (payload: DataPayload<EventRSVPResponseType>) => ({
  type: types.GET_EVENTS_COUNT,
  payload,
});

export const getEvents = (payload: EventsQuery = {}) => ({
  type: types.GET_EVENTS,
  payload,
});

export const setEvents = (payload: DataPayload<EventType[]>) => ({
  type: types.SET_EVENTS,
  payload,
});

export const setEventTab = (payload: DataPayload<EventState>) => ({
  type: types.SET_EVENT_TAB,
  payload,
});

export const addEvent = (payload: DataPayload<CreateEventPayload>) => ({
  type: types.ADD_EVENT,
  payload,
});

export const setFilters = (payload: DataPayload<EventsFilters>) => ({
  type: types.SET_FILTERS,
  payload,
});

export const setIsLoading = (payload: IsLoadingPayload) => ({
  type: types.SET_EVENTS_IS_LOADING,
  payload,
});

export const setEventsResponseCount = (payload: DataPayload<EventResponseCount>) => ({
  type: types.SET_EVENTS_RESPONSE_COUNT,
  payload,
});

export const getEventsResponseCount = (payload: object = {}) => ({
  type: types.GET_EVENTS_RESPONSE_COUNT,
  payload,
});

export const getEventCategories = (payload: object = {}) => ({
  type: types.GET_EVENT_CATEGORIES,
  payload,
});

export const setEventCategories = (payload: DataPayload<EventCategory[]>) => ({
  type: types.SET_EVENT_CATEGORIES,
  payload,
});

export const getEventById = (payload: IdPayload) => ({
  type: types.GET_EVENT_BY_ID,
  payload,
});

export const setEventDetails = (payload: DataPayload<EventType>) => ({
  type: types.SET_EVENT_BY_ID,
  payload,
});

export const deleteEventById = (payload: IdPayload) => ({
  type: types.DELETE_EVENT_BY_ID,
  payload,
});

export const setEventId = (payload: DataPayload<number>) => ({
  type: types.SET_EVENT_ID,
  payload,
})

export const setActiveTab = (payload: SetActiveEventTabPayload) => ({
  type: types.SET_ACTIVE_EVENT_DETAILS_TAB,
  payload,
});

export const getEventAttendeesByEventId = (payload: IdPayload) => ({
  type: types.GET_EVENT_ATTENDEES,
  payload,
});

export const setEventAttendeesByEventId = (payload: DataPayload<EventAttendeeType[]>) => ({
  type: types.SET_EVENT_ATTENDEES,
  payload,
});

export const updateEventById = (payload: DataPayload<UpdateEventPayload>) => ({
  type: types.UPDATE_EVENT_BY_ID,
  payload,
});

export const getEventRSVP = (payload: ParamsPayload = {}) => ({
  type: types.GET_EVENT_RSVP,
  payload,
});

export const setEventRSVP = (payload: DataPayload<EventRSVPType>) => ({
  type: types.SET_EVENT_RSVP,
  payload,
});

export const updateEventRSVP = (payload: DataPayload<EventRSVPBeType>) => ({
  type: types.UPDATE_EVENT_RSVP,
  payload,
});
