import React, { useEffect, useMemo } from 'react';
import { Form } from 'antd';
import { RcFile } from 'antd/lib/upload';
import { LabeledValue } from 'antd/lib/select';

import { PartnerType as Partner } from 'packages/partner_repository';
import DrawerButton from 'components/components/Drawer/components/DrawerButton';
import { scrollToFirstError } from 'components/components/FormBuilder/utils/helpers';
import { AddPartnerMemberDrawerProps } from 'types/partners/addPartner';
import { PartnerMemberType } from 'packages/people_repository/types/partner_member_type';
import { FormBuilder } from '../components';
import { addPartnerMemberFields, AddPartnerMemberFieldsCreatorParams } from './utils/constants';

const AddNewPartnerMemberDrawer = (props: AddPartnerMemberDrawerProps) => {
  const {
    partners, actions,
  } = props;

  const [form] = Form.useForm();

  useEffect(() => {
    actions.getPartners();
  }, []);

  const beforeHeadshotUpload = (file: RcFile) => {
    form.setFieldValue('photo', file as Blob);
    return false;
  }

  const onSubmit = (data: PartnerMemberType) => {
    actions.addNewPartnerMember({ data })
  };

  const partnerOptions: LabeledValue[] = useMemo(() => (
    partners?.map((item: Partner) => ({ label: `${item.name || 'Unknown partner'}`, value: item.id }))), [partners]);

  const partnerMemberFieldsCreateData: AddPartnerMemberFieldsCreatorParams = { beforeHeadshotUpload, partnerOptions };

  return (
    <Form
      className="form-builder add-new-member-form"
      onFinish={onSubmit}
      form={form}
      onFinishFailed={scrollToFirstError}
    >
      <FormBuilder
        formFragment
        fields={addPartnerMemberFields(partnerMemberFieldsCreateData)}
      />
      <DrawerButton>Add member</DrawerButton>
    </Form>
  )
};

export default AddNewPartnerMemberDrawer;
