import { connect } from 'react-redux';
import { bindActionCreators, Dispatch, Action } from 'redux';

import { selectUserType } from 'redux/selectors/auth';
import { StateType } from 'types';
import { getRequestsCount } from 'redux/actions/request';
import { selectCurrentTab, selectRequestOverviewFilters } from 'redux/selectors/request';

const mapStateToProps = (state: StateType) => ({
  userType: selectUserType(state),
  currentTab: selectCurrentTab(state),
  filters: selectRequestOverviewFilters(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  actions: bindActionCreators({ getRequestsCount }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps);
