/* eslint-disable react/jsx-props-no-spreading */
/*
  NOTE: linter is disabled for this file because we need to pass all props to Antd Dropdown. So instead of writing each
  prop line by line, we spread the props inside of Antd Dropdown component. It will not be possible for this component
  to receive any not allowed props, since the component itself accepts the same props as Antd Dropdown without children.
*/
import React from 'react';
import { DropdownProps, Dropdown as AntdDropdown } from 'antd';
import classNames from 'classnames';

import { ChevronDownIcon } from '../../../../../assets/icons';

import './Dropdown.scss';

const Dropdown = (props: Omit<DropdownProps, 'icon' | 'placement' | 'trigger'>) => (
  <AntdDropdown.Button
    {...props}
    className={classNames('dropdown-button', props.className, { 'dropdown-button-disabled': props.disabled })}
    icon={<ChevronDownIcon />}
    placement="bottom"
    trigger={['click']}
  >
    {props.children}
  </AntdDropdown.Button>
);

export default Dropdown;
