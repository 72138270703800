import React from 'react';

import getLabelValueObject from 'utils/helpers/getLabelValueObject';
import { ServicesType } from 'types/services';
import MemberAttributes from 'components/MemberDetails/components/MemberAttributes';
import { getServicePriceText } from 'components/components/Card/components/CardInfoRow/utils/helpers';
import { LabeledValue } from 'types';
import { getServiceCategories } from '.';

export const getServiceRequestOverviewColumns = (data: ServicesType, showAllColumns: boolean) => {
  const columnsArray = []
  if (!showAllColumns) {
    columnsArray.push(getLabelValueObject('Service type', `${data.type || 'Incentives'}`));
    columnsArray.push(getLabelValueObject('Service Provider', `${data.serviceProvider.name || 'Service Provider name not specified'}`));
  } else {
    columnsArray.push(getLabelValueObject('Service type', `${data.type || 'Incentives'}`));
    columnsArray.push(getLabelValueObject('Service Provider', `${data.serviceProvider.name || 'Service Provider name not specified'}`));
    columnsArray.push(getLabelValueObject('Description', `${data.about || 'No description specified'}`));
    columnsArray.push(getLabelValueObject('Price', getServicePriceText(data)));
    columnsArray.push({ label: 'Category', value: <MemberAttributes attributes={getServiceCategories(data.category.split(','))} /> });
  }
  return columnsArray;
};

export const planTypeOptions: LabeledValue[] = [
  { key: '', value: '', label: 'Enter or choose from dropdown' },
  { key: 'bronze', value: 'Bronze', label: 'Bronze' },
  { key: 'silver', value: 'Silver', label: 'Silver' },
  { key: 'enhancedSilver', value: 'Enhanced Silver', label: 'Enhanced Silver' },
  { key: 'gold', value: 'Enhanced Gold', label: 'Enhanced Gold' },
  { key: 'platinum', value: 'Platinum', label: 'Platinum' },
];

export const yesNoOptions = [
  { key: 'yes', value: 'yes', title: 'Yes' },
  { key: 'no', value: 'no', title: 'No' },
];

export const relationTypeOptions = [
  { key: 'spouse', value: 'spouse', title: 'Spouse' },
  { key: 'child', value: 'child', title: 'Child' },
];

export const genderOptions = [
  { key: 'male', value: 'male', title: 'Male' },
  { key: 'female', value: 'female', title: 'Female' },
];

export const placeOfBirthOptions = [
  { key: 'uae', value: 'uae', title: 'UAE' },
  { key: 'other', value: 'other', title: 'Other' },
];

export const officeTypeOptions = [
  { key: '', value: '', label: 'Select...' },
  { key: 'dedicated-office', value: 'dedicated-office', label: 'Dedicated Office' },
  { key: 'dedicated-desk', value: 'dedicated-desk', label: 'Dedicated Desk' },
  { key: 'all-access', value: 'all-access-pass', label: 'All Access Pass' },
  { key: 'private-office', value: 'private-office', label: 'Private Office' },
];
