import { connect } from 'react-redux';

import { selectUserType } from 'redux/selectors/auth';
import { selectPartnerRequestDetails } from 'redux/selectors/partners';
import { selectPerformanceReportDetails } from 'redux/selectors/performance';
import { selectEventName } from 'redux/selectors/events';
import { selectRequestItems } from '../../../../../redux/selectors/request';
import { StateType } from '../../../../../types';
import { selectDrawerBody } from '../../../../../redux/selectors/workspace';
import { selectServiceName } from '../../../../../redux/selectors/services';

const mapStateToProps = (state: StateType) => ({
  items: selectRequestItems(state),
  serviceName: selectServiceName(state),
  drawerBody: selectDrawerBody(state),
  userType: selectUserType(state),
  partnerRequest: selectPartnerRequestDetails(state),
  performanceReport: selectPerformanceReportDetails(state),
  eventName: selectEventName(state),
});

export default connect(mapStateToProps);
