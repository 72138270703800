import React from 'react';

import SVGWrap from './SVGWrap';

const ArrowLeftIcon = () => (
  <SVGWrap>
    <path xmlns="http://www.w3.org/2000/svg" d="M7 13L7.705 12.295L3.915 8.5H14V7.5H3.915L7.705 3.705L7 3L2 8L7 13Z" />
  </SVGWrap>
);

export default ArrowLeftIcon;
