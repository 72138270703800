import React from 'react';
import { AiFillCheckCircle } from 'react-icons/ai';

import '../../AcceptanceLetter.scss';

const AcceptedLetter = () => (
  <div className="acceptance-letter__accepted-container">
    <AiFillCheckCircle />
    <span className="acceptance-letter__accepted-text">Acceptance Letter has been accepted</span>
  </div>
);

export default AcceptedLetter;
