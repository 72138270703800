import React from 'react'
import { Form } from 'antd';

import { EditDataEntryPointDrawerProps } from 'types/settingsOverview'
import DrawerButton from 'components/components/Drawer/components/DrawerButton/View';
import { FormBuilder } from 'components/components';
import { DataEntryPointType } from 'packages/settings_repository';
import { DATA_ENTRY_POINTS } from '../AddNewDataEntry/utils/constants';
import { EditDataEntryDrawerFields, defaultValue } from './utils/constants';

const EditDataEntryPointDrawer = (props: EditDataEntryPointDrawerProps) => {
  const { activeSubTab, dataEntryPoint, actions } = props;

  const [form] = Form.useForm();

  const handleSubmit = (data: DataEntryPointType) => {
    switch (activeSubTab) {
      case DATA_ENTRY_POINTS.education: {
        actions.updateEducation({ data });
        break;
      }
      case DATA_ENTRY_POINTS.startupStage: {
        actions.updateStartupStage({ data });
        break;
      }
      case DATA_ENTRY_POINTS.title: {
        actions.updateTitle({ data });
        break;
      }
      default: {
        break;
      }
    }
  }

  return (
    <Form
      form={form}
      onFinish={handleSubmit}
      initialValues={dataEntryPoint}
    >
      <FormBuilder
        oneColumn
        formFragment
        defaultValues={defaultValue}
        fields={EditDataEntryDrawerFields}
      />
      <DrawerButton>Save changes</DrawerButton>
    </Form>
  )
}

export default EditDataEntryPointDrawer
