import { CreateResourceSuccessResponseType, GetParamsType, ResponseType } from 'packages/http_client';
import { TEMPLATE_NAME_QUERY_PARAM, TEMPLATE_TYPE_QUERY_PARAM } from '../utils/constants';

// eslint-disable-next-line no-shadow
export enum TemplateTypeEnum {
  'Acceptance Letter' = 1,
  'Incentive Agreement' = 2,
  'Other' = 99,
}

export type ResponseTemplateType = {
  id: number,
  name: string,
  type: number,
  createdAt: string,
  file: string,
  fileContent: string,
};

export type TemplateType = {
  id: number,
  title: string,
  url: string,
  createdAt: string,
  fileContent?: string,
}

export type AddTemplateType = {
  name: string,
  type: number,
  file: string | Blob,
};

export type GetTemplateApiRequestParams = {
  [TEMPLATE_TYPE_QUERY_PARAM]?: number,
  [TEMPLATE_NAME_QUERY_PARAM]?: string,
};

export type CreateTemplateApiResponseType = CreateResourceSuccessResponseType;

export type GetTemplatesListApiResponseType = {
  result: ResponseTemplateType[],
};

export type GetTemplateByIdApiResponseType = {
  result: ResponseTemplateType,
};

export type GetTemplateByIdResponse = ResponseType<TemplateType>;

export interface GetTemplatesListResponse extends ResponseType {
  data?: TemplateType[],
}

export type CreateTemplateApiRequestPayload = {
  _name?: string,
  _type?: number,
  _file?: string | Blob,
}

export type UpdateTemplateApiRequestPayload = {
  _name?: string,
  _file_content?: string,
  _file?: string | Blob,
}

export interface TemplateParams extends GetParamsType {
  id?: number[],
}
