/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';

import { TypeButtonProps } from '../../../../../types/componentTypes/SearchBarProps';
import Input from '../../../Input';
import { items } from '../../utils/constants';

const TypeButton = (props: TypeButtonProps) => {
  const { showTypeButton } = props;
  const [checkedValue, setCheckedValue] = useState<Array<CheckboxValueType>>([]);

  if (!showTypeButton) {
    return null;
  }

  const onChange = (checkedValues: Array<CheckboxValueType>) => {
    setCheckedValue(checkedValues)
  };

  const typeMenu = (
    <div className="search-bar-dropdown">
      <Input.CheckboxGroup
        onChange={onChange}
        options={items}
        value={checkedValue}
      />
    </div>
  );

  return (
    <Input className="type-button-container" layout="vertical">
      <Input.Dropdown
        className="type-button"
        overlay={typeMenu}
      >
        Type
      </Input.Dropdown>
    </Input>
  )
}

TypeButton.defaultProps = {
  showTypeButton: false,
}

export default TypeButton;
