import React from 'react'
import moment from 'moment';

import { RenderIf } from 'components/components';
import { formatSlashMDYHD } from 'components/utils/constants/formatters';
import { SettingsDateProps } from 'types/reduxTypes/ActionTypes/SettingsOverviewTypes';
import { identity } from 'utils';

const SettingsTableDate = (props: SettingsDateProps) => {
  const { date } = props;

  return (
    <RenderIf condition={!identity.isEmptyString(date)}>
      <div className="settings__table-created-date">
        <span className="settings__table-created-date__value">
          {moment(date).format(formatSlashMDYHD)}
        </span>
      </div>
    </RenderIf>
  )
}

SettingsTableDate.defaultProps = {
  date: '',
}

export default SettingsTableDate
