import React, { useEffect, useState } from 'react'

import { templatesTableColumns } from 'components/utils/constants/columns'
import { TemplatesTableProps } from 'types/reduxTypes/ActionTypes/SettingsOverviewTypes';
import { DRAWERS, identity } from 'utils';
import { TABS_COMPONENT_LOOKUP, TEMPLATE_TABS } from 'components/Settings/utils';
import { EmailTemplateType } from 'types/settingsOverview';
import SettingsTable from '../SettingsTable';

const TemplatesTable = (props: TemplatesTableProps) => {
  const {
    emailTemplates,
    incentiveTemplates,
    workOrderTemplates,
    activeSubTab,
    activeTab,
  } = props;
  const {
    getEmailTemplates,
    getIncentiveTemplates,
    setDeleteIds,
    setDrawerBody,
    setIsDrawerOpen,
    getWorkOrderTemplates,
    deleteTemplate,
    getTemplateById,
  } = props.actions;

  const [dataSource, setDataSource] = useState([])

  useEffect(() => {
    if (identity.isEmptyArray(emailTemplates)) {
      getEmailTemplates();
    }
    if (identity.isEmptyArray(incentiveTemplates)) {
      getIncentiveTemplates();
    }
    if (identity.isEmptyArray(workOrderTemplates)) {
      getWorkOrderTemplates();
    }
  }, []);

  useEffect(() => {
    switch (activeSubTab) {
      case TEMPLATE_TABS.acceptanceLetter: {
        setDataSource(emailTemplates);
        break;
      }
      case TEMPLATE_TABS.incentiveAgreement: {
        setDataSource(incentiveTemplates);
        break;
      }
      case TEMPLATE_TABS.workOrder: {
        setDataSource(workOrderTemplates);
        break;
      }
      default: {
        setDataSource([]);
        break;
      }
    }
    setDeleteIds({ data: [] });
  }, [emailTemplates, activeSubTab, incentiveTemplates, workOrderTemplates])

  const onAddNew = () => {
    const drawerBody = TABS_COMPONENT_LOOKUP[activeSubTab || activeTab].drawer;
    setDrawerBody({ drawerBody });
    setIsDrawerOpen({ isDrawerOpen: true });
  }

  const onUpdate = (data: EmailTemplateType) => {
    getTemplateById({ id: data.id });
    setDrawerBody({ drawerBody: DRAWERS.editTemplate });
    setIsDrawerOpen({ isDrawerOpen: true });
  }

  return (
    <SettingsTable
      onAddNew={onAddNew}
      dataSource={dataSource}
      columns={templatesTableColumns(deleteTemplate, onUpdate)}
    />
  )
}

export default TemplatesTable;
