import React from 'react';

import SVGWrap from './SVGWrap';

const AddIcon = () => (
  <SVGWrap>
    <path xmlns="http://www.w3.org/2000/svg" d="M8.75 7.25V2H7.25V7.25H2V8.75H7.25V14H8.75V8.75H14V7.25H8.75Z" />
  </SVGWrap>
);

export default AddIcon;
