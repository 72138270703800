import { connect } from 'react-redux';

import { StateType } from '../../../../types';
import { selectUserType } from '../../../../redux/selectors/auth';

const mapStateToProps = (state: StateType) => ({
  userType: selectUserType(state),
});

export default connect(mapStateToProps);
