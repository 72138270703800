import React from 'react';

import SVGWrap from './SVGWrap';

const OfficeSpaceIcon = () => (
  <SVGWrap size={24} fill="none">
    <path d="M13.9688 4.75781V3.21094C13.9688 3.09434 13.8744 3 13.7578 3H3.21094C3.09434 3 3 3.09434 3 3.21094V20.7891C3 20.9057 3.09434 21 3.21094 21H20.7891C20.9057 21 21 20.9057 21 20.7891V7.3125C21 7.1959 20.9057 7.10156 20.7891 7.10156H17.4844M13.9688 4.75781H17.2734C17.39 4.75781 17.4844 4.85215 17.4844 4.96875V7.10156M13.9688 4.75781V4.96875M17.4844 7.10156H17.1292M13.9688 7.10156H17.1292M13.9688 7.10156C13.9688 6.29687 13.9688 4.74375 13.9688 4.96875M13.9688 7.10156V7.5M17.1292 7.10156V4.96875H13.9688M13.9688 7.5V20.5781H20.5781V7.5H13.9688ZM9.86719 20.5781H13.5469V3.21094H3.35575V20.5781H7.10156V16.1016C7.10156 15.985 7.1959 15.8906 7.3125 15.8906H9.65625C9.77285 15.8906 9.86719 15.985 9.86719 16.1016V20.5781ZM7.52344 20.5781H9.44531V16.3125H7.52344V20.5781ZM18.6562 17.2734H18.2344V16.1016H18.6568L18.6562 17.2734ZM16.3125 17.2734H15.8906V16.1016H16.3131L16.3125 17.2734ZM12.2109 17.2734H11.7891V16.1016H12.2109V17.2734ZM5.17969 17.2734H4.75781V16.1016H5.17969V17.2734ZM18.6562 13.7578H18.2344V12.5859H18.6568L18.6562 13.7578ZM16.3125 13.7578H15.8906V12.5859H16.3131L16.3125 13.7578ZM12.2109 13.7578H11.7891V12.5859H12.2109V13.7578ZM9.86719 13.7578H9.44531V12.5859H9.86719V13.7578ZM7.52344 13.7578H7.10156V12.5859H7.52344V13.7578ZM5.17969 13.7578H4.75781V12.5859H5.17969V13.7578ZM18.6562 10.2422H18.2344V9.07031H18.6568L18.6562 10.2422ZM16.3125 10.2422H15.8906V9.07031H16.3131L16.3125 10.2422ZM12.2109 10.2422H11.7891V9.07031H12.2109V10.2422ZM9.86719 10.2422H9.44531V9.07031H9.86719V10.2422ZM7.52344 10.2422H7.10156V9.07031H7.52344V10.2422ZM5.17969 10.2422H4.75781V9.07031H5.17969V10.2422ZM12.2109 6.72656H11.7891V5.55469H12.2109V6.72656ZM9.86719 6.72656H9.44531V5.55469H9.86719V6.72656ZM7.52344 6.72656H7.10156V5.55469H7.52344V6.72656ZM5.17969 6.72656H4.75781V5.55469H5.17969V6.72656Z" strokeWidth="0.585937" />
  </SVGWrap>
);

export default OfficeSpaceIcon;
