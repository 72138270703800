import React from 'react';
import { identity, DRAWERS } from 'utils';
import { getStartupFile } from 'components/EditStartup/utils';
import { AboutProps } from 'types/componentTypes/AboutProps';
import { RenderIf, Input } from 'components/components';
import { ViewUploadButton } from 'components/EditStartup/components';

import './About.scss';

const About = (props: AboutProps) => {
  const {
    title,
    description,
    isStartup,
    startupDetails,
  } = props;

  const handleOpenDrawer = (drawerBody: string) => {
    props.actions.setIsDrawerOpen({ isDrawerOpen: true });
    props.actions.setDrawerBody({ drawerBody });
  }

  return (
    <div className="about">
      <div className="about__container">
        <RenderIf condition={identity.isTruthyString(title)}>
          <div className="about__header">
            <h3 className="about__title">{title}</h3>
          </div>
        </RenderIf>
        <div className="about__card">
          { description || 'No description or details specified' }
          { props.children }
        </div>
        <RenderIf condition={isStartup}>
          <div className="about__details">
            <div className="details_row">
              <h4>Website:</h4>
              <p>{startupDetails?.website}</p>
            </div>
            <RenderIf condition={identity.isTruthyString(startupDetails?.acceptanceLetter)}>
              <div className="details_row">
                <h4>Acceptance Letter:</h4>
                <div>
                  <Input.DraggerUpload
                    defaultFileList={identity.isTruthyString(startupDetails?.acceptanceLetter)
                      ? [...getStartupFile(startupDetails?.acceptanceLetter!)] : []}
                    previewType="image"
                    // beforeUpload={beforeAcceptanceLetterUpload}
                    accept=".pdf"
                    isRoundableButton
                    name={identity.isTruthyString(startupDetails?.acceptanceLetter) ? 'acceptanceLetter' : ''}
                  />
                </div>
              </div>
            </RenderIf>
            <RenderIf condition={!identity.isZero(startupDetails?.aDGMApprovedStatus)}>
              <div className="details_row">
                <h4>ADGM License:</h4>
                <div>
                  <ViewUploadButton
                    userType="Startup team member"
                    name="ADGM License"
                    onClick={() => handleOpenDrawer(DRAWERS.adgmLicenseDetails)}
                    condition={startupDetails?.aDGMApprovedStatus === 1
                      && identity.isTruthyString(startupDetails?.currentADGMLicense?.aDGMLicenseUrl)}
                    status={startupDetails?.aDGMApprovedStatus}
                  />
                </div>
              </div>
            </RenderIf>
            <RenderIf condition={!identity.isZero(startupDetails?.currentIncentiveAgreement?.licenseAgreementStatus)}>
              <div className="details_row">
                <h4>Incentive Agreement:</h4>
                <div>
                  <ViewUploadButton
                    userType="Startup team member"
                    name="Incentive Agreement"
                    onClick={() => handleOpenDrawer(DRAWERS.incentiveAgreementDetails)}
                    condition={startupDetails?.currentIncentiveAgreement?.licenseAgreementStatus
                      && identity.isTruthyString(startupDetails?.currentIncentiveAgreement?.file)}
                    status={startupDetails?.currentIncentiveAgreement?.licenseAgreementStatus}
                  />
                </div>
              </div>
            </RenderIf>
          </div>
        </RenderIf>
      </div>
    </div>
  );
};

export default About;
