import { connect } from 'react-redux';
import { bindActionCreators, Dispatch, Action } from 'redux';

import { getStartupById, getStartupTeamMembers, getStartupServiceProviders } from 'redux/actions/startups';
import { StateType } from 'types';
import {
  selectStartupName,
  selectAboutStartup,
  selectStatCards,
  selectStartupTeamMembers,
  selectStartupDetails,
  selectStartupDetailsRequests,
  selectIsLoading,
  selectPartnersStatCards,
  selectStartupServiceProviders,
} from 'redux/selectors/startups';
import { getRequestsByOwnerId } from 'redux/actions/request';
import { selectUserStartupId, selectUserType } from 'redux/selectors/auth';

const mapStateToProps = (state: StateType) => ({
  startupName: selectStartupName(state),
  aboutStartup: selectAboutStartup(state),
  statCards: selectStatCards(state),
  partnersStatCards: selectPartnersStatCards(state),
  teamMembers: selectStartupTeamMembers(state),
  info: selectStartupDetails(state),
  requests: selectStartupDetailsRequests(state),
  userType: selectUserType(state),
  isLoading: selectIsLoading(state),
  userStartupId: selectUserStartupId(state),
  startupServiceProviders: selectStartupServiceProviders(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  actions: bindActionCreators({
    getStartupById,
    getStartupTeamMembers,
    getRequestsByOwnerId,
    getStartupServiceProviders,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps);
