import { API_HOST } from 'packages/http_client/utils';
import identity from 'utils/identity';
import { EditTemplateDataType } from 'types/reduxTypes/ActionTypes/SettingsOverviewTypes';
import {
  AddTemplateType,
  CreateTemplateApiRequestPayload,
  GetTemplatesListApiResponseType,
  ResponseTemplateType,
  TemplateType,
  UpdateTemplateApiRequestPayload,
} from '../types';

export const convertGetTemplateResponse = (template: ResponseTemplateType): TemplateType => {
  const {
    id, file, name, createdAt, fileContent,
  } = template;
  const templateURL = API_HOST + file;
  const data: TemplateType = {
    id,
    title: name,
    url: templateURL,
    createdAt,
    fileContent,
  };
  return data;
}

export const convertGetTemplatesListResponse = (
  response: GetTemplatesListApiResponseType | undefined,
): TemplateType[] => {
  if (!identity.isFullArray(response?.result)) {
    return [];
  }

  const data: TemplateType[] = [];
  for (let i = 0; i < response?.result.length!; i += 1) {
    const template: TemplateType = convertGetTemplateResponse(response?.result[i]!);
    data.push(template);
  }

  return data;
}

export const convertTemplateRequestPayload = (payload: AddTemplateType): CreateTemplateApiRequestPayload => ({
  _name: payload.name,
  _file: payload.file,
  _type: payload.type,
});

export const convertUpdateTemplateRequestPayload = (
  payload: EditTemplateDataType,
): UpdateTemplateApiRequestPayload => ({
  _name: payload.title,
  _file_content: payload.fileContent,
  _file: payload.url,
});
