import React from 'react';

export const capitalRaisedOptions = [
  {
    title: 'Less than AED 100,000',
    value: '{"from":"100000","to":"100000","value":"less"}',
    key: 'Less than AED 100,000',
  },
  {
    title: 'AED 100,000 - AED 200,000',
    value: '{"from":"100000","to":"200000","value":"range"}',
    key: 'AED 100,000 - AED 200,000',
  },
  {
    title: 'AED 200,000 - AED 500,000',
    value: '{"from":"200000","to":"500000","value":"range"}',
    key: 'AED 200,000 - AED 500,000',
  },
  {
    title: 'AED 500,000 - AED 1,000,000',
    value: '{"from":"500000","to":"1000000","value":"range"}',
    key: 'AED 500,000 - AED 1,000,000',
  },
  {
    title: 'More than AED 1,000,000',
    value: '{"from":"1000000","to":"1000000","value":"greater"}',
    key: 'More than AED 1,000,000',
  },
];

export const startupStageOptions = (stages: { label: string | React.ReactNode, value: string | number }[]) => stages
  .map(({ label, value }) => ({ key: label as string, title: label as string, value: value as string }));
