import React from 'react';

import PerformanceDetails from 'components/PerformanceDrawers/components/PerformanceDetails';
import { StepCustomFieldComponentProps } from 'types/componentTypes/StepsForm';
import { PerformanceTypePayload } from 'packages/performance_repository';
import { identity } from 'utils';

const ReviewComponent = (props: StepCustomFieldComponentProps) => {
  const { form } = props;
  const formState = form?.getFieldsValue(true);
  const performanceReport: PerformanceTypePayload = {
    ...formState,
    pitchDeck: identity.isTruthyString(formState?.pitchDeck?.name)
      ? URL.createObjectURL((formState?.pitchDeck as Blob)) : formState.pitchDeck,
  }

  return (
    <div className="review-component">
      <PerformanceDetails performanceReport={performanceReport} />
    </div>
  )
}

export default ReviewComponent
