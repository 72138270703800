import { EventDetailsTabs } from 'types/events/eventsProps';

export type TabsCamelCaseType = 'overview' | 'attendees';

export const TABS: {[key in TabsCamelCaseType]: EventDetailsTabs} = {
  overview: 'Overview',
  attendees: 'Attendees',
};

export default null;
