import { connect } from 'react-redux';
import { bindActionCreators, Dispatch, Action } from 'redux';

import { StateType } from 'types';
import {
  selectEventFilters,
  selectEvents,
  selectEventsCurrentSubTab,
  selectEventsCurrentTab,
  selectIsLoading,
  selectEventsCount,
} from 'redux/selectors/events';
import { getEvents, getEventsCount } from 'redux/actions/events';

const mapStateToProps = (state: StateType) => ({
  events: selectEvents(state),
  currentTab: selectEventsCurrentTab(state),
  filters: selectEventFilters(state),
  isLoading: selectIsLoading(state),
  currentSubTab: selectEventsCurrentSubTab(state),
  count: selectEventsCount(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  actions: bindActionCreators({ getEvents, getEventsCount }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps);
