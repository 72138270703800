import React from 'react';

import SVGWrap from './SVGWrap';

const ServicesIcon = () => (
  <SVGWrap>
    <path xmlns="http://www.w3.org/2000/svg" d="M14.5 12.5H13.5V11.5H14V9.5H12V10H11V9C11 8.86739 11.0527 8.74021 11.1464 8.64645C11.2402 8.55268 11.3674 8.5 11.5 8.5H14.5C14.6326 8.5 14.7598 8.55268 14.8535 8.64645C14.9473 8.74021 15 8.86739 15 9V12C15 12.1326 14.9473 12.2598 14.8535 12.3536C14.7598 12.4473 14.6326 12.5 14.5 12.5Z" />
    <path xmlns="http://www.w3.org/2000/svg" d="M12 15H9C8.86739 15 8.74021 14.9473 8.64644 14.8536C8.55267 14.7598 8.5 14.6326 8.5 14.5V11.5C8.5 11.3674 8.55267 11.2402 8.64644 11.1464C8.74021 11.0527 8.86739 11 9 11H12C12.1326 11 12.2598 11.0527 12.3535 11.1464C12.4473 11.2402 12.5 11.3674 12.5 11.5V14.5C12.5 14.6326 12.4473 14.7598 12.3535 14.8536C12.2598 14.9473 12.1326 15 12 15ZM9.5 14H11.5V12H9.5V14Z" />
    <path xmlns="http://www.w3.org/2000/svg" d="M7.5 9.92895C7.13865 9.83482 6.81093 9.64126 6.55407 9.37023C6.2972 9.09921 6.12149 8.76158 6.04687 8.39571C5.97225 8.02983 6.00173 7.65036 6.13195 7.30039C6.26217 6.95043 6.48792 6.64399 6.78356 6.41588C7.0792 6.18778 7.43287 6.04714 7.80442 6.00995C8.17597 5.97276 8.55051 6.0405 8.88549 6.20548C9.22048 6.37046 9.50249 6.62607 9.6995 6.94328C9.8965 7.26049 10.0006 7.62659 10 8H11C11.0006 7.4279 10.8376 6.86756 10.5302 6.38506C10.2228 5.90256 9.78385 5.51801 9.2651 5.27675C8.74636 5.0355 8.16945 4.9476 7.6024 5.02342C7.03535 5.09924 6.5018 5.33562 6.06467 5.70469C5.62753 6.07375 5.30504 6.56012 5.13522 7.10643C4.9654 7.65274 4.95532 8.23622 5.10619 8.78806C5.25705 9.33991 5.56256 9.83712 5.9867 10.2211C6.41083 10.605 6.9359 10.8596 7.5 10.955V9.92895Z" />
    <path xmlns="http://www.w3.org/2000/svg" d="M14.445 6.775L13.29 7.79L12.58 7.08L13.785 6.02L12.605 3.98L10.885 4.56C10.4821 4.22464 10.0257 3.95928 9.53499 3.775L9.17999 2H6.81999L6.46499 3.775C5.9703 3.95414 5.51146 4.21995 5.10999 4.56L3.39499 3.98L2.21499 6.02L3.57499 7.215C3.48248 7.73257 3.48248 8.26243 3.57499 8.78L2.21499 9.98L3.39499 12.02L5.11499 11.44C5.51789 11.7754 5.97424 12.0407 6.46499 12.225L6.81999 14H7.49999V15H6.81999C6.58878 14.9997 6.36479 14.9194 6.18612 14.7726C6.00745 14.6259 5.88514 14.4218 5.83999 14.195L5.58499 12.935C5.35862 12.8246 5.13981 12.6993 4.92999 12.56L3.71499 12.97C3.61167 13.0036 3.50364 13.0205 3.39499 13.02C3.21935 13.0212 3.04658 12.9755 2.89451 12.8876C2.74245 12.7997 2.61661 12.6728 2.52999 12.52L1.34999 10.48C1.23328 10.2795 1.18992 10.0447 1.22732 9.81572C1.26471 9.58677 1.38055 9.37794 1.55499 9.225L2.51499 8.385C2.50499 8.255 2.49999 8.13 2.49999 8C2.49999 7.87 2.50999 7.745 2.51999 7.62L1.55499 6.775C1.38055 6.62206 1.26471 6.41324 1.22732 6.18428C1.18992 5.95533 1.23328 5.72049 1.34999 5.52L2.52999 3.48C2.61661 3.3272 2.74245 3.20029 2.89451 3.1124C3.04658 3.0245 3.21935 2.97879 3.39499 2.98C3.50364 2.9795 3.61167 2.99638 3.71499 3.03L4.92499 3.44C5.13652 3.30065 5.357 3.17538 5.58499 3.065L5.83999 1.805C5.88514 1.57824 6.00745 1.37411 6.18612 1.22735C6.36479 1.08059 6.58878 1.00025 6.81999 1H9.17999C9.41121 1.00025 9.6352 1.08059 9.81386 1.22735C9.99253 1.37411 10.1148 1.57824 10.16 1.805L10.415 3.065C10.6414 3.17541 10.8602 3.30068 11.07 3.44L12.285 3.03C12.3883 2.99638 12.4963 2.9795 12.605 2.98C12.7806 2.97879 12.9534 3.0245 13.1055 3.1124C13.2575 3.20029 13.3834 3.3272 13.47 3.48L14.65 5.52C14.7667 5.72049 14.8101 5.95533 14.7727 6.18428C14.7353 6.41324 14.6194 6.62206 14.445 6.775Z" />
  </SVGWrap>
);

export default ServicesIcon;
