import { connect } from 'react-redux';
import { bindActionCreators, Dispatch, Action } from 'redux';

import { StateType } from '../../types';
import { getServiceProviderById, patchServiceProviderDataById } from '../../redux/actions/serviceProviders';
import { selectServiceProviderDetails, selectServiceProviderLoading } from '../../redux/selectors/serviceProviders';
import { selectUserServiceProviderId, selectUserType } from '../../redux/selectors/auth';

const mapStateToProps = (state: StateType) => ({
  serviceProvider: selectServiceProviderDetails(state),
  isLoading: selectServiceProviderLoading(state),
  userType: selectUserType(state),
  userServiceProviderId: selectUserServiceProviderId(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  actions: bindActionCreators({
    getServiceProviderById,
    patchServiceProviderDataById,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps);
