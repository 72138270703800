import { Rule } from 'antd/lib/form';
import config from 'assets/config';

export const validatePasswordsConfirmation = (password: string, confirmPassword: string) => {
  if (password !== confirmPassword) {
    throw new Error();
  }
};

export const passwordConfirmationValidation: Rule = {
  message: 'The passwords entered do not match',
};

export const passwordLengthValidation: Rule = {
  message: 'The password must be at least 8 characters in length',
  min: config.MIN_PASSWORD_LENGTH,
};
