import { API_ROOT } from '../../http_client';
import {
  EventFrequency,
  EventRSVPResponseType,
  EventStatus,
  EventTypeType,
} from '../types';
import { EventResponse } from '../../../types/events';

export const EVENT_FREQUENCY: {[key: number]: EventFrequency} = {
  0: '-',
  1: 'one time',
  2: 'daily',
  3: 'weekly',
  4: 'monthly',
  5: 'annual',
}

export const EVENT_STATUS: {[key: number]: EventStatus} = {
  0: '-',
  1: 'upcoming',
  2: 'past',
}

export const EVENT_STATUS_TO_NUMBER = {
  [EVENT_STATUS['0']]: 0,
  [EVENT_STATUS['1']]: 1,
  [EVENT_STATUS['2']]: 2,
}

export const EVENT_TYPE: {[key: number]: EventTypeType} = {
  0: '-',
  1: 'virtual',
  2: 'physical',
  3: 'hybrid',
}

export const EVENT_TYPE_TO_NUMBER = {
  [EVENT_TYPE['0']]: 0,
  [EVENT_TYPE['1']]: 1,
  [EVENT_TYPE['2']]: 2,
  [EVENT_TYPE['3']]: 3,
}

export const ATTENDEE_GROUP = {
  0: '-',
  1: 'founders',
  2: 'startup members',
  3: 'partners',
}

export const EVENT_RSVP_RESPONSE: {[key: number]: EventRSVPResponseType} = {
  0: 'not confirmed',
  1: 'going',
  2: 'interested',
  3: 'not going',
}

export const EVENT_RSVP_KEYS: {[key: number]: EventResponse} = {
  0: 'notConfirmed',
  1: 'going',
  2: 'interested',
  3: 'notGoing',
}

export const EVENT_RSVP_RESPONSE_TO_NUMBER = {
  [EVENT_RSVP_RESPONSE['0']]: 0,
  [EVENT_RSVP_RESPONSE['1']]: 1,
  [EVENT_RSVP_RESPONSE['2']]: 2,
  [EVENT_RSVP_RESPONSE['3']]: 3,
}

export const EVENTS_API = `${API_ROOT}/event`;
export const EVENTS_RSVP_API = `${EVENTS_API}rsvp`;
export const EVENTS_ATTENDEE_API = `${EVENTS_API}attendee`;
export const EVENTS_CATEGORY = `${EVENTS_API}category`;
