import React from 'react';
import { Pagination } from 'antd';

import { CustomPaginationProps } from 'types/componentTypes/TableProps';
import { gridPaginationOptions, paginationOptions } from './constants';

import './CustomPagination.scss';

const CustomPagination = (props: CustomPaginationProps) => {
  const {
    limit, total, currentPage, setCurrentPage, setLimit, gridLayout,
  } = props;

  const onChange = (page: number) => {
    setCurrentPage(page);
  };

  const onSizeChange = (current: number, size: number) => {
    setLimit(size);
    setCurrentPage(current);
  };

  return (
    <div className="custom-pagination">
      <p className="custom-pagination__heading">Results per page</p>
      <Pagination
        showSizeChanger
        pageSizeOptions={gridLayout ? gridPaginationOptions : paginationOptions}
        locale={{ items_per_page: '' }}
        current={currentPage}
        total={total}
        pageSize={limit}
        onChange={onChange}
        onShowSizeChange={onSizeChange}
      />
    </div>
  )
}

CustomPagination.defaultProps = {
  limit: 1,
  total: 1,
  gridLayout: false,
}

export default CustomPagination;
