import React from 'react'

import { NOT_SPECIFIED } from 'components/CohortDetails/utils/constants';
import { RenderIf } from 'components/components';
import DataColumn from 'components/components/DataColumns/components/DataColumn/View';
import { PerformanceDetailsProps } from 'types/performance'
import { identity, parseNumberToPrice } from 'utils';
import AgreementField from 'components/RequestsOverview/components/RequestDetailsDrawer/components/OverviewTab/components/AgreementField';

import './PerformanceDetails.scss';

const PerformanceDetails = (props: PerformanceDetailsProps) => {
  const { performanceReport, showReportName } = props;

  return (
    <div className="performance-details">
      <RenderIf condition={showReportName}>
        <p className="performance-details__report">
          {`${
            performanceReport?.quarters
              ? `Q${performanceReport?.quarters}  ` : NOT_SPECIFIED
          }
          ${
    identity.isTruthyNumber(performanceReport?.year)
      ? `${performanceReport?.year} ` : NOT_SPECIFIED
    }
          Report`}
        </p>
      </RenderIf>
      <DataColumn label="Year">
        {
          identity.isTruthyNumber(performanceReport?.year)
            ? performanceReport?.year : NOT_SPECIFIED
        }
      </DataColumn>
      <DataColumn label="Quarter">
        {
          identity.isEmptyString(performanceReport?.quarters)
            ? NOT_SPECIFIED : `Q${performanceReport?.quarters}`
        }
      </DataColumn>
      <DataColumn label="Number of Startup Founders">
        {
          identity.isTruthyNumber(performanceReport?.numberOfFounder)
            ? performanceReport?.numberOfFounder : 0
        }
      </DataColumn>
      <DataColumn label="Number of Startup female Founders">
        {
          identity.isTruthyNumber(performanceReport?.numberOfFemaleFounders)
            ? performanceReport?.numberOfFemaleFounders : 0
        }
      </DataColumn>
      <DataColumn label="Number of full-time employees">
        {
          identity.isTruthyNumber(performanceReport?.numberOfFullTimeEmployees)
            ? performanceReport?.numberOfFullTimeEmployees : 0
        }
      </DataColumn>
      <DataColumn label="Number of female full-time employees">
        {
          identity.isTruthyNumber(performanceReport?.numberOfFemaleFullTimeEmployees)
            ? performanceReport?.numberOfFemaleFullTimeEmployees : 0
        }
      </DataColumn>
      <DataColumn label="Number of full-time employees to be based in Abu Dhabi">
        {
          identity.isTruthyNumber(performanceReport?.numOfAbuDhabiVisaHolders)
            ? performanceReport?.numOfAbuDhabiVisaHolders : 0
        }
      </DataColumn>
      <DataColumn label="Currently raising">
        {performanceReport?.currentlyRaising || 0}
      </DataColumn>
      <DataColumn label="Fundraising stage">
        {
          identity.isEmptyString(performanceReport?.investmentStage?.name)
            ? NOT_SPECIFIED : performanceReport?.investmentStage?.name
        }
      </DataColumn>
      <DataColumn label="This is a bridge round">
        {performanceReport?.bridgeRound ? 'Yes' : 'No'}
      </DataColumn>
      <DataColumn label="Last Quarter Revenue (USD)">
        {parseNumberToPrice(performanceReport?.lastQuarterRevenue || 0, '$')}
      </DataColumn>
      <DataColumn label="Current Year Revenue (USD)">
        {performanceReport?.currentYearRevenue || 0}
      </DataColumn>
      <DataColumn label="Currently Raising (USD)">
        {parseNumberToPrice(performanceReport?.currentlyRaisingAmount || 0, '$')}
      </DataColumn>
      <DataColumn label="Existing investors">
        {
          identity.isEmptyString(performanceReport?.leadInvestorInCurrentRound)
            ? NOT_SPECIFIED : performanceReport?.leadInvestorInCurrentRound
        }
      </DataColumn>
      <DataColumn label="Last Valuation (USD)">
        {parseNumberToPrice(performanceReport?.lastValuation || 0, '$')}
      </DataColumn>
      <DataColumn label="Key Milestones">
        {
          identity.isEmptyString(performanceReport?.keyMilestones)
            ? NOT_SPECIFIED : performanceReport?.keyMilestones
        }
      </DataColumn>
      <DataColumn label="Are you interested in exploring international markets?">
        {performanceReport?.interestedInInternational ? 'Yes' : 'No'}
      </DataColumn>
      <DataColumn label="Please name them with the value proposition for each">
        {
          identity.isEmptyString(performanceReport?.internationalMarkets)
            ? NOT_SPECIFIED : performanceReport?.internationalMarkets
        }
      </DataColumn>
      <DataColumn label="What are your current top three challenges?">
        {
          identity.isEmptyString(performanceReport?.topChallenges)
            ? NOT_SPECIFIED : performanceReport?.topChallenges
        }
      </DataColumn>
      <DataColumn label="Lead investor name (current round)">
        {
          identity.isEmptyString(performanceReport?.leadInvestorInCurrentRound)
            ? NOT_SPECIFIED : performanceReport?.leadInvestorInCurrentRound
        }
      </DataColumn>
      <DataColumn label="Lead investor commitment">
        {
          identity.isTruthyString(performanceReport?.leadInvestorInCommitment)
            ? performanceReport?.leadInvestorInCommitment : NOT_SPECIFIED
        }
      </DataColumn>
      <DataColumn label="Current Pitch Deck (please upload a new one if applicable)">
        <AgreementField fileUrl={performanceReport?.pitchDeck} fileName="PitchDeck.pdf" altText="No Pitch Deck found" />
      </DataColumn>
      <DataColumn label="Do you mind sharing your latest pitch deck with Hub71 VC Partners?">
        {performanceReport?.sharePitchDeckWithPartners ? 'I didn\'t mind' : 'No'}
      </DataColumn>
      <DataColumn label="Would you like to tell us something else?">
        {identity.isEmptyString(performanceReport?.comments) ? NOT_SPECIFIED : performanceReport?.comments}
      </DataColumn>
    </div>
  )
}

PerformanceDetails.defaultProps = {
  showReportName: false,
};

export default PerformanceDetails
