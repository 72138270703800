import { Moment } from 'moment';
import { LabeledValue } from 'antd/lib/select';
import { RcFile } from 'antd/lib/upload';

import { formatDateEveryNth, formatDateWeekdayOfMonth } from 'utils/helpers/dateTimeHelpers';
import { requiredValidation } from '../../../utils/constants/validationRules';
import getLabelValueObject from '../../../utils/helpers/getLabelValueObject';
import { inputTypes } from '../../components/FormBuilder/utils/constants';
import { getFormFieldBuilder } from '../../components/FormBuilder/utils/FormFieldBuilder';

export const thirtyDays = Array.from({ length: 30 }, (_, i) => getLabelValueObject(`${i < 9 ? '0' : ''}${i + 1}`, i + 1));
export const thirtyOneDays = [...thirtyDays, getLabelValueObject('31', 31)];
export const february = thirtyDays.filter((i) => Number(i.value) < 29);

export const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const monthsOptions = months.map((item) => getLabelValueObject(item, item));

export const MONTHS_DAYS_MAP = {
  [months[0]]: thirtyOneDays,
  [months[1]]: february,
  [months[2]]: thirtyOneDays,
  [months[3]]: thirtyDays,
  [months[4]]: thirtyOneDays,
  [months[5]]: thirtyDays,
  [months[6]]: thirtyOneDays,
  [months[7]]: thirtyOneDays,
  [months[8]]: thirtyDays,
  [months[9]]: thirtyOneDays,
  [months[10]]: thirtyDays,
  [months[11]]: thirtyOneDays,
}

export const FREQUENCY = {
  daily: 2,
  weekly: 3,
  monthly: 4,
  annual: 5,
  once: 1,
}

export const frequencyOptions = [
  getLabelValueObject('only once', 1),
  getLabelValueObject('daily', 2),
  getLabelValueObject('weekly', 3),
  getLabelValueObject('monthly', 4),
  getLabelValueObject('annual', 5),
];

export const getMonthlyOptions = (date: Moment) => [
  {
    label: formatDateEveryNth(date),
    value: date.date(),
  },
  {
    label: formatDateWeekdayOfMonth(date, true),
    value: 'Custom',
  },
]

const eventTypeTypeOptions: LabeledValue[] = [
  { value: 1, label: 'Virtual' },
  { value: 2, label: 'Physical' },
  { value: 3, label: 'Hybrid' },
];

const attendeeGroupTypeOptions: LabeledValue[] = [
  { value: 1, label: 'Founders' },
  { value: 2, label: 'Startup members' },
  { value: 3, label: 'Partners' },
];

export type EventTopFieldsParams = {
  eventCategories: LabeledValue[],
}

export type EventMiddleFieldsParams = {
  beforeLogoUpload: (_file: RcFile) => boolean,
}

export const addEventTopFields = ({ eventCategories }: EventTopFieldsParams) => [
  {
    fields: [
      getFormFieldBuilder('name', 'Event Name')
        .withPlaceholder('Event name')
        .withRules([requiredValidation])
        .required()
        .build(),
    ],
  },
  {
    fields: [
      getFormFieldBuilder('type', 'Type', inputTypes.select)
        .withOptions(eventTypeTypeOptions)
        .withPlaceholder('Select event type')
        .required()
        .withRules([requiredValidation])
        .build(),
      getFormFieldBuilder('category', 'Category', inputTypes.select)
        .withOptions(eventCategories)
        .withPlaceholder('Select event category')
        .required()
        .withRules([requiredValidation])
        .build(),
    ],
  },
];

export const addEventMiddleFields = ({ beforeLogoUpload }: EventMiddleFieldsParams) => [
  {
    fields: [
      getFormFieldBuilder('attendee_group', 'Invite group', inputTypes.select)
        .withPlaceholder('Select group')
        .withOptions(attendeeGroupTypeOptions)
        .build(),
    ],
  },
  {
    fields: [
      getFormFieldBuilder('description', 'Description', inputTypes.textArea)
        .withPlaceholder('About event')
        .withRows(3)
        .build(),
    ],
  },
  {
    fields: [
      getFormFieldBuilder('image', 'Image', inputTypes.image)
        .withBeforeUpload(beforeLogoUpload)
        .build(),
    ],
  },
];
