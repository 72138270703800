import React, { useEffect } from 'react';
import {
  Button,
  Form,
  Spin,
  UploadFile,
} from 'antd';
import { useParams } from 'react-router';
import moment from 'moment';

import { DatePickerIcon } from 'assets/icons';
import {
  Input,
  Breadcrumb,
  TitleWithActions,
  scrollToFirstError,
} from 'components/components';
import { EditCohortProps } from 'types/cohorts/editCohort';
import { CohortType } from 'types/cohorts/cohortOverview';
import { composeCohortsDetailsUrl } from 'routes/routeComposers';
import { getImageLink, identity, positiveNumbersOnly } from 'utils';
import { useEditCohortHook } from './utils';
import { formatDateDashYMDTimeHMS, formatSlashMDY } from '../utils/constants/formatters';

import './EditCohort.scss';

const EditCohort = (props: EditCohortProps) => {
  const { isLoading, selectedCohort, actions: { getCohortById, patchCohortById } } = props;

  const params: { id?: string } = useParams();
  const { id } = params;

  const getStartupCsvFile = (csvFileLink: string) => {
    const url = getImageLink(csvFileLink);
    const fileName = identity.isTruthyString(url)
      ? url.split('/').at(-1)!
      : 'n/a';
    const defaultFileList: UploadFile[] = [{
      uid: '-1',
      name: fileName,
      status: 'done',
      url,
      thumbUrl: url,
    }];

    return defaultFileList;
  };

  const {
    state, updated, setAll, setField,
  } = useEditCohortHook();

  useEffect(() => {
    getCohortById({ id: Number.parseInt(id!, 10), isUpdate: false });
  }, [id]);

  useEffect(() => {
    setAll({ cohort: selectedCohort });
  }, [selectedCohort]);

  const handleSubmit = () => {
    if (updated) {
      const payloadData: CohortType = {
        name: state.name,
        from: moment(state.from).format(formatDateDashYMDTimeHMS),
        to: moment(state.to).format(formatDateDashYMDTimeHMS),
        amount: state.amount,
        id: state.id,
        type: state.type,
        startups: state.startups,
        acceptanceLetterTemplate: state.acceptanceLetterTemplate,
        startupCsvFile: state.startupCsvFile,
        selectionFrom: moment(state.selectionFrom).format(formatDateDashYMDTimeHMS),
        selectionTo: moment(state.selectionTo).format(formatDateDashYMDTimeHMS),
      };

      patchCohortById({ id, data: payloadData });
    }
  };

  return (
    <div className="content-container">
      <Spin spinning={isLoading}>
        <div className="edit-cohort__container">
          <Breadcrumb navigateTo={composeCohortsDetailsUrl(Number.parseInt(id!, 10))} routeName="Cohort Details" />
          <TitleWithActions title={selectedCohort.name} />
          <Form validateTrigger="onSubmit" onFinish={handleSubmit} onFinishFailed={scrollToFirstError}>
            <div className="edit-cohort__fields">
              <Input label="Name" required>
                <Input.InputField
                  type="text"
                  value={state.name}
                  required
                  onChange={(e) => setField({ field: 'name', value: e.target.value })}
                />
              </Input>
              <Input label="Program">
                <Input.Dropdown disabled>
                  {state.type}
                </Input.Dropdown>
              </Input>
              <div className="edit-cohort__section">
                <Input.Heading title="Cohort Selection Period" />
                <Input label="From">
                  <Input.DatePicker
                    placeholder=""
                    format={formatSlashMDY}
                    suffixIcon={<DatePickerIcon />}
                    value={moment(state.selectionFrom)}
                    onChange={(e) => setField({ field: 'selectionFrom', value: moment(e).utc().format(formatDateDashYMDTimeHMS) })}
                  />
                </Input>
                <Input label="To">
                  <Input.DatePicker
                    placeholder=""
                    format={formatSlashMDY}
                    suffixIcon={<DatePickerIcon />}
                    value={moment(state.selectionTo)}
                    onChange={(e) => setField({ field: 'selectionTo', value: moment(e).utc().format(formatDateDashYMDTimeHMS) })}
                  />
                </Input>
              </div>
              <div className="edit-cohort__section">
                <Input.Heading title="Cohort Period" />
                <Input label="From">
                  <Input.DatePicker
                    placeholder=""
                    format={formatSlashMDY}
                    suffixIcon={<DatePickerIcon />}
                    value={moment(state.from)}
                    onChange={(e) => setField({ field: 'from', value: moment(e).utc().format(formatDateDashYMDTimeHMS) })}
                  />
                </Input>
                <Input label="To">
                  <Input.DatePicker
                    placeholder=""
                    format={formatSlashMDY}
                    suffixIcon={<DatePickerIcon />}
                    value={moment(state.to)}
                    onChange={(e) => setField({ field: 'to', value: moment(e).utc().format(formatDateDashYMDTimeHMS) })}
                  />
                </Input>
              </div>
              <div className="edit-cohort__section">
                <Input.Heading title="Amount" />
                <Input label="Amount" rules={[positiveNumbersOnly]}>
                  <Input.InputNumber
                    min={0}
                    addonBefore="AED"
                    value={parseInt(state.amount, 10)}
                    onChange={(amount) => setField({ field: 'amount', value: amount ? amount!.toString() : '' })}
                  />
                </Input>
              </div>
              <div className="edit-cohort__section">
                <Input.Heading title="Startups List" />
                {identity.isTruthyString(state.startupCsvFile) ? (
                  <Input.DraggerUpload
                    previewType="image"
                    defaultFileList={[...getStartupCsvFile(state.startupCsvFile!)]}
                    isRoundableButton
                  />
                ) : (
                  <p className="edit-cohort__section__no-startups">No startup list added to this cohort</p>
                )}
              </div>
            </div>
            <Button type="primary" htmlType="submit" className="primary-button" disabled={!updated}>
              UPDATE COHORT
            </Button>
          </Form>
        </div>
      </Spin>
    </div>
  );
}

export default EditCohort;
