import { createSelector } from 'reselect';

import { StateType } from 'types';
import { DashboardStateType } from 'types/reduxTypes/StateTypes';

const selectDashboardState = (state: StateType) => state.dashboardReducer;

export const selectDashboardRequests = createSelector(
  selectDashboardState,
  (state: DashboardStateType) => state.requests,
);

export const selectDashboardEvents = createSelector(
  selectDashboardState,
  (state: DashboardStateType) => state.events,
);

export const selectIsLoading = createSelector(
  selectDashboardState,
  (state: DashboardStateType) => state.isLoading,
);

export const selectDashboardCounts = createSelector(
  selectDashboardState,
  (state: DashboardStateType) => state.count,
);
