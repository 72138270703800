import React, { useEffect } from 'react'
import { Redirect, Route } from 'react-router-dom';

import { isStartupDataMissing } from 'utils/helpers/checkStartupStatus';
import routes from 'routes';
import { StartupType } from 'types/auth';
import { StartupRouteProps } from 'types/routes/PrivateRouteProps';
import { NOTICE_LEVEL_TO_TEMPLATE_MAP } from 'utils/constants/noticeModal';
import STARTUP_STATUS from 'utils/constants/startupStatusType';
import { identity } from 'utils';

const StartupRoute = (props: StartupRouteProps) => {
  const {
    user,
    component,
    exact,
    path,
    startup,
    isBlockedByNotice,
    noticeModalTemplate,
    actions,
    notice,
  } = props;

  useEffect(() => {
    if (!isBlockedByNotice || path === routes.createPerformanceReportUrl) {
      return;
    }

    if (!identity.isObjWithChildren(noticeModalTemplate)) {
      const noticeDetailsTemplate = {
        ...NOTICE_LEVEL_TO_TEMPLATE_MAP[notice?.level!],
        text: notice?.text,
      };
      actions.setNoticeModalTemplate({ data:  noticeDetailsTemplate });
    }

    actions.setIsNoticeModalOpen({ isOpen: true });
  }, [notice, path])

  if (isStartupDataMissing(startup!)) {
    return <Redirect to={routes.addMissingDetailsUrl} />
  }

  if ((user as StartupType).status === STARTUP_STATUS.rejected) {
    return <Redirect to={routes.startupRejectedUrl} />
  }

  return <Route path={path} exact={exact} component={component} />
}

export default StartupRoute;
