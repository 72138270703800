import { connect } from 'react-redux';
import { Action, bindActionCreators, Dispatch } from 'redux';

import { addEvent, getEventCategories } from 'redux/actions/events';
import { selectEventCategories } from 'redux/selectors/events';
import { StateType } from 'types';
import { getUsers } from 'redux/actions/users';
import { selectUsers } from 'redux/selectors/users';

const mapStateToProps = (state: StateType) => ({
  eventCategories: selectEventCategories(state),
  users: selectUsers(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  actions: bindActionCreators({
    addEvent,
    getEventCategories,
    getUsers,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps);
