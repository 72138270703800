import { connect } from 'react-redux';
import { Action, bindActionCreators, Dispatch } from 'redux';

import { selectUserType } from 'redux/selectors/auth';
import { StateType } from 'types';
import { setIsDrawerOpen, setDrawerBody } from '../../../../../../redux/actions/workspace';

const mapStateToProps = (state: StateType) => ({
  userType: selectUserType(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  actions: bindActionCreators({
    setIsDrawerOpen,
    setDrawerBody,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps);
