import { SettingsActionsType } from 'types/settingsOverview';
import {
  DATA_ENTRY_POINTS_TABS, TABS, SERVICE_CATEGORIES_TABS, TEMPLATE_TABS,
} from './constants';

export const getActionTabLookup = (actions: SettingsActionsType) => ({
  // add all delete checked items actions here
  [TABS.programType]: actions.deleteProgramTypes,
  [DATA_ENTRY_POINTS_TABS.education]: actions.deleteEducation,
  [DATA_ENTRY_POINTS_TABS.startupStage]: actions.deleteStartupStages,
  [DATA_ENTRY_POINTS_TABS.title]: actions.deleteTitles,
  [SERVICE_CATEGORIES_TABS.mainCategories]: actions.deleteServiceCategory,
  [SERVICE_CATEGORIES_TABS.subCategories]: actions.deleteServiceSubCategory,
  [TEMPLATE_TABS.acceptanceLetter]: actions.deleteMultipleTemplates,
  [TEMPLATE_TABS.incentiveAgreement]: actions.deleteMultipleTemplates,
  [TEMPLATE_TABS.workOrder]: actions.deleteMultipleTemplates,
});

export default null;
