import React, { useEffect, useState } from 'react';
import { Form } from 'antd';
import { RcFile } from 'antd/lib/upload';
import moment from 'moment';

import DrawerButton from 'components/components/Drawer/components/DrawerButton';
import { scrollToFirstError } from 'components/components/FormBuilder/utils/helpers';
import { identity, arrayToString } from 'utils';
import { CreateTeamMemberType } from 'packages/people_repository';
import { AddEmployeeDrawerProps } from 'types/people';
import { defaultValues, memberTypeOptions, MEMBER_TYPES } from './utils/constants';
import { Input } from '../components';
import { AddEmployeeForm, AddStartupMemberForm } from './components';

import './AddNewMemberDrawer.scss';

const AddNewMemberDrawer = (props: AddEmployeeDrawerProps) => {
  const {
    startups, involvementTypes, nationalities, actions,
  } = props;

  const [memberType, setMemberType] = useState<string>(MEMBER_TYPES.startupMember);
  const [form] = Form.useForm();

  const isEmployee = memberType === MEMBER_TYPES.employee;

  const beforeHeadshotUpload = (file: RcFile) => {
    form.setFieldValue('headshot', file as Blob);
    return false;
  }

  useEffect(() => {
    if (identity.isEmptyArray(startups)) {
      actions.getStartups();
    }
    if (identity.isEmptyArray(involvementTypes)) {
      actions.getInvolvementTypes();
    }
    if (identity.isEmptyArray(nationalities)) {
      actions.getNationalities();
    }
  }, [])

  const onSubmit = (data: CreateTeamMemberType) => {
    const isFounder = memberType === MEMBER_TYPES.startupFounder;
    let formData = {}
    if (isEmployee) {
      actions.addHub71Employee(data);
    } else {
      formData = {
        ...data,
        dateOfBirth: moment(data?.dateOfBirth).format('YYYY-MM-DD HH:mm:ss'),
        founder: isFounder ? 1 : 0,
        skills: arrayToString(data?.skills!),
        hobbies: arrayToString(data?.hobbies!),
        jobFunctions: arrayToString(data?.jobFunctions!),
      }
      actions.addMember(formData);
    }
  };

  return (
    <Form
      className="form-builder add-new-member-form"
      initialValues={defaultValues[memberType]}
      onFinish={onSubmit}
      form={form}
      onFinishFailed={scrollToFirstError}
    >
      <div className="form-builder__row">
        <Input
          name="memberType"
          label="Member type"
          layout="vertical"
        >
          <Input.Select
            options={memberTypeOptions}
            value={memberType}
            onChange={(value) => setMemberType(value)}
          />
        </Input>
      </div>
      {isEmployee
        ? <AddEmployeeForm beforeHeadshotUpload={beforeHeadshotUpload} />
        : (
          <AddStartupMemberForm
            isFounder={memberType === MEMBER_TYPES.startupFounder}
            form={form}
            startups={startups}
            involvementTypes={involvementTypes}
            nationalities={nationalities}
            beforeHeadshotUpload={beforeHeadshotUpload}
          />
        )}
      <DrawerButton>Add member</DrawerButton>
    </Form>
  )
};

export default AddNewMemberDrawer;
