import React from 'react';
import { Button } from 'antd';
import { FileExcelOutlined } from '@ant-design/icons';

import { ExportDataButtonProps } from 'types/componentTypes';
import { exportTableDataAsExcel } from 'packages/settings_repository';
import { isProduction, isServiceProvider, isStartup } from 'utils';

const ExportDataButton = (props: ExportDataButtonProps) => {
  const { exportDataModel, showExportButton, user } = props;

  if (!showExportButton || !exportDataModel || isProduction) {
    return null;
  }

  const onHandleClick = () => {
    let query = '';
    if (user) {
      const { serviceProviderId, startupId, userType } = user;
      if (isStartup(userType)) {
        query = `startup_id=${startupId}`;
      }

      if (isServiceProvider(userType)) {
        query = `serviceProviderId=${serviceProviderId}`;
      }
    }
    exportTableDataAsExcel(exportDataModel, query);
  };

  return (
    <div className="table-export-button__container">
      <Button
        className="export-button secondary-button"
        onClick={onHandleClick}
      >
        <FileExcelOutlined color="white" />
        Download
      </Button>
    </div>
  )
};

export default ExportDataButton;
