import React from 'react';

import SVGWrap from './SVGWrap';

const ChevronRightIcon = () => (
  <SVGWrap>
    <path xmlns="http://www.w3.org/2000/svg" d="M11 8L6.00005 13L5.30005 12.3L9.60005 8L5.30005 3.7L6.00005 3L11 8Z" />
  </SVGWrap>
);

export default ChevronRightIcon;
