import { connect } from 'react-redux';
import { Action, bindActionCreators, Dispatch } from 'redux';

import { setIsDrawerOpen, setDestroyDrawerOnClose } from 'redux/actions/workspace';
import { addEducation, addStartupStage, addTitle } from 'redux/actions/settingsOverview';

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  actions: bindActionCreators({
    addEducation,
    setIsDrawerOpen,
    setDestroyDrawerOnClose,
    addStartupStage,
    addTitle,
  }, dispatch),
});

export default connect(null, mapDispatchToProps);
