import { connect } from 'react-redux';
import { Action, bindActionCreators, Dispatch } from 'redux';

import { addEmailTemplate } from 'redux/actions/settingsOverview';
import { setIsDrawerOpen, setDestroyDrawerOnClose } from 'redux/actions/workspace';

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  actions: bindActionCreators({
    addEmailTemplate,
    setIsDrawerOpen,
    setDestroyDrawerOnClose,
  }, dispatch),
});

export default connect(null, mapDispatchToProps);
