import { connect } from 'react-redux';
import { bindActionCreators, Dispatch, Action } from 'redux';

import { StateType } from 'types';
import { selectUserType } from 'redux/selectors/auth';
import { selectServiceAndSpActiveTab, selectServicesCount } from 'redux/selectors/services';
import { selectServiceProvidersCount } from 'redux/selectors/serviceProviders';
import { setActiveTab } from 'redux/actions/services';

const mapStateToProps = (state: StateType) => ({
  userType: selectUserType(state),
  activeTab: selectServiceAndSpActiveTab(state),
  serviceProvidersCount: selectServiceProvidersCount(state),
  servicesCount: selectServicesCount(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  actions: bindActionCreators({
    setActiveTab,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps);
