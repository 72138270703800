import React, { useEffect, useMemo, useState } from 'react'
import { Form } from 'antd'
import { LabeledValue } from 'antd/lib/select';

import DrawerButton from 'components/components/Drawer/components/DrawerButton/View';
import Row from 'components/components/FormBuilder/components/Row';
import DataColumn from 'components/components/DataColumns/components/DataColumn';
import { identity } from 'utils';
import { ServiceCategoryType } from 'types/services';
import { UpdateServiceCategoriesPayload } from 'types/reduxTypes/ActionTypes/ServiceTypes';
import { FormBuilder, Input } from 'components/components';
import { EditServiceCategoryDrawerProps } from 'types/settingsOverview';
import useSettingsForm from 'components/Settings/hooks/useSettingsForm';
import { AddNewSubCategoryFormFileds, ServiceSubCategoryParams, categoryOptions } from '../Subcategories/components/AddNewSubCategoryDrawer/utils/contstants';
import { AddNewCategoryFormFields } from '../MainCategories/components/AddNewCategoryDrawer/utils/constants';

import './EditSerivceCategory.scss';

const EditServiceCategoryDrawer = (props: EditServiceCategoryDrawerProps) => {
  const {
    actions,
    categories,
    serviceCategoryDetails,
    serviceSubCategoryDetails,
    hasMainCategory,
    categoryID,
  } = props;

  const [categoryId, setCategoryId] = useState(categoryID);
  const [previousCategoryId, setPreviousCategoryId] = useState(categoryId);
  const { form } = useSettingsForm({
    defaultValues: hasMainCategory
      ? serviceCategoryDetails : serviceSubCategoryDetails,
  });

  useEffect(() => {
    if (identity.isEmptyArray(categories)) {
      actions.getServiceCategories({});
    }
    setCategoryId(categoryID!)
  }, [categoryID]);

  const categoryTypeChanged = categoryId !== previousCategoryId;
  const isMainCategory = categoryId === 1;
  const isDefaultCategoryMain = isMainCategory && categoryId === categoryID;
  // Main category id is 1, and sub-category id is 2
  const handleSubmit = (data: UpdateServiceCategoriesPayload) => {
    if ((!categoryTypeChanged && isMainCategory) || (isDefaultCategoryMain && categoryTypeChanged)) {
      actions.updateServiceCategoryById({ ...data, id: serviceCategoryDetails?.id! })
    } else if ((!categoryTypeChanged && !isMainCategory)) {
      actions.updateServiceSubCategoryById({ ...data, id: serviceSubCategoryDetails?.id! })
    } else if (categoryTypeChanged && !isMainCategory) {
      actions.deleteServiceCategoryById({ id: serviceCategoryDetails?.id! });
      actions.addServiceSubCategory({ data })
    } else if ((categoryTypeChanged && isMainCategory)) {
      actions.deleteServiceSubCategoryById({ id: serviceSubCategoryDetails?.id! });
      actions.addServiceCategory({ data })
    }
  }

  const handleCategoryChange = (data: number) => {
    setCategoryId(data);
    setPreviousCategoryId(categoryId);
  }

  const serviceProvidersOptions: LabeledValue[] = useMemo(() => (
    categories!.map((item: ServiceCategoryType) => ({ label: `${item.name || 'Unknown category'}`, value: item.id }))), [categories]);

  const serviceFiltersData: ServiceSubCategoryParams = {
    categories: serviceProvidersOptions,
  };

  return (
    <Form
      form={form}
      onFinish={handleSubmit}
      className="new-category-container"
    >
      <Row oneColumn>
        <DataColumn label="Category type" className="input">
          <Input.Select
            options={categoryOptions}
            value={categoryId}
            onChange={(data: number) => handleCategoryChange(data)}
          />
        </DataColumn>
      </Row>
      { (!isMainCategory) ? (
        <FormBuilder
          oneColumn
          formFragment
          fields={AddNewSubCategoryFormFileds(serviceFiltersData)}
        />
      ) : (
        <FormBuilder
          oneColumn
          formFragment
          fields={AddNewCategoryFormFields}
        />
      )}
      <DrawerButton>Save changes</DrawerButton>
    </Form>
  )
}
export default EditServiceCategoryDrawer;
