import { connect } from 'react-redux';
import { bindActionCreators, Dispatch, Action } from 'redux';

import { StateType } from '../../../../../../types';
import { selectUserType } from '../../../../../../redux/selectors/auth';
import { setDrawerBody, setIsDrawerOpen } from '../../../../../../redux/actions/workspace';
import { deleteDraftRequestItem, setRequestId } from '../../../../../../redux/actions/request';

const mapStateToProps = (state: StateType) => ({
  userType: selectUserType(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  actions: bindActionCreators({
    setIsDrawerOpen,
    setRequestId,
    setDrawerBody,
    deleteDraftRequestItem,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps);
