import { connect } from 'react-redux';

import { StateType } from 'types';
import { selectDashboardEvents } from 'redux/selectors/dashboard';

const mapStateToProps = (state: StateType) => ({
  events: selectDashboardEvents(state),
});

export default connect(mapStateToProps);
