import { useReducer } from 'react';

import { reducer, initialState } from './reducer';

import {
  setField as setFieldAC, SetFieldType, setLogo as setLogoAC, setCsvFile as setCsvFileAC,
} from './actions';
import { DataPayload } from '../../../../types/reduxTypes/ActionTypes';

const useAddNewCohortHook = () => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const setField = (payload: SetFieldType) => {
    dispatch(setFieldAC(payload));
  }

  const setLogo = (payload: DataPayload<string | Blob>) => {
    dispatch(setLogoAC(payload));
  }

  const setCsvFile = (payload: DataPayload<string | Blob>) => {
    dispatch(setCsvFileAC(payload));
  }

  return {
    state,
    setField,
    setLogo,
    setCsvFile,
  }
}

export default useAddNewCohortHook;
