import React, { useState, useEffect } from 'react';
import { Form } from 'antd';

import { UnitType } from 'packages/service_repository';
import DrawerButton from 'components/components/Drawer/components/DrawerButton';
import { scrollToFirstError } from 'components/components/FormBuilder/utils/helpers';
import EventEmitter from 'event_emitter';
import { identity } from 'utils';
import { AddServiceDrawerProps, AddServiceType } from 'types/services/addService';
import { HandlePricingOptionChangeType, PricingOption } from 'types/services';
import {
  ServiceDetailForm,
  ServiceCategoryForm,
  AddPricingOptions,
  AdditionalOptions,
  RangeOptions,
} from './components';
import { defaultValues, resetFiltersEvent, setErrorForAmounts } from './utils';
import { RenderIf } from '../components';

import './AddNewServiceDrawer.scss';

const AddNewServiceDrawer = (props: AddServiceDrawerProps) => {
  const { categories, units, actions: { addService, getServiceCategories, getServiceUnitsList } } = props;

  const [additionalOptionName, setAdditionalOptionName] = useState(defaultValues.additionalOptionName)
  const [additionalOptionPrice, setAdditionalOptionPrice] = useState(defaultValues.additionalOptionPrice)
  const [form] = Form.useForm();
  const [pricingOptions, setPricingOptions] = useState<PricingOption[]>(defaultValues.pricingOptions);
  const [pricingOptionsError, setPricingOptionsError] = useState<boolean[]>([false]);
  const [offerDocLink, setOfferDocLink] = useState<string | Blob>('');
  const [priceUnit, setPriceUnit] = useState<UnitType>();
  const offerPriceStatus = Form.useWatch('offerPrice', form);

  useEffect(() => {
    if (identity.isEmptyArray(categories)) {
      getServiceCategories();
    }

    if (identity.isEmptyArray(units)) {
      getServiceUnitsList();
    }
  }, []);

  const handleSubmit = (data: AddServiceType) => {
    form.setFieldValue('additionalOptionName', additionalOptionName);
    form.setFieldValue('additionalOptionPrice', additionalOptionPrice);
    form.setFieldValue('pricingOptions', pricingOptions);
    form.setFieldValue('offerDocLink', offerDocLink);

    const unit = priceUnit;
    if (offerPriceStatus === 'range') {
      const formValues = {
        ...data,
        additionalOptionPrice,
        additionalOptionName,
        pricingOptions,
        offerDocLink,
        unit,
      };
      form.resetFields();
      setAdditionalOptionName(defaultValues.additionalOptionName);
      setAdditionalOptionPrice(defaultValues.additionalOptionPrice);
      addService({ data: formValues });
    } else {
      const formValues = {
        ...data,
        additionalOptionPrice,
        additionalOptionName,
        priceFrom: pricingOptions[0].amount,
        pricingOptions,
        offerDocLink,
        unit,
      };
      const { submit, newErrors } = setErrorForAmounts(pricingOptions);
      setPricingOptionsError(newErrors);

      if (!submit) {
        form.resetFields();
        EventEmitter.emit(resetFiltersEvent);
        setAdditionalOptionName(defaultValues.additionalOptionName);
        setAdditionalOptionPrice(defaultValues.additionalOptionPrice);
        addService({ data: formValues });
      }
    }
  }

  const handleSetPriceUnit = (unit: UnitType) => {
    setPriceUnit(unit);
  };

  const handleOfferDoc = (file: string | Blob) => {
    setOfferDocLink(file);
    return false;
  };

  const onDeletePricingOption = (id: number) => {
    const newArray = pricingOptions.filter((recipient: PricingOption) => recipient.id !== id);
    setPricingOptions(newArray);
  };

  const onAddPricingOption = (pricingOption: PricingOption) => {
    const id = pricingOptions.length + 1;
    setPricingOptions([...pricingOptions, { ...pricingOption, id }]);
    setPricingOptionsError([...pricingOptionsError, false]);
  };

  const onChangeHandle = (values: HandlePricingOptionChangeType) => {
    const { field, value, id } = values;
    const newPricingOptions = [...pricingOptions];
    newPricingOptions[id] = { ...newPricingOptions[id], [field]: value };
    setPricingOptions(newPricingOptions);
  }

  return (
    <Form
      form={form}
      onFinish={handleSubmit}
      initialValues={defaultValues}
      validateTrigger="onSubmit"
      className="new-service-container"
      onFinishFailed={scrollToFirstError}
    >
      <ServiceDetailForm />
      <RenderIf condition={offerPriceStatus === 'range'}>
        <RangeOptions setPriceUnit={handleSetPriceUnit} units={units} />
      </RenderIf>
      <RenderIf condition={offerPriceStatus === 'fixed'}>
        <AddPricingOptions
          errors={pricingOptionsError}
          pricingOptions={pricingOptions}
          onDeletePricingOption={onDeletePricingOption}
          onAddPricingOption={onAddPricingOption}
          onChangeHandle={onChangeHandle}
          setPriceUnit={handleSetPriceUnit}
          units={units}
        />
      </RenderIf>
      <ServiceCategoryForm form={form} setOfferDocLink={handleOfferDoc} />
      <RenderIf condition={false}>
        {/* Disabling additional options as those will be handled as special case later on */}
        <AdditionalOptions
          onChangeAdditionalOptionName={setAdditionalOptionName}
          onChangeAdditionalOptionPrice={setAdditionalOptionPrice}
          additionalOptionName={additionalOptionName}
          additionalOptionPrice={additionalOptionPrice}
        />
      </RenderIf>
      <DrawerButton>Add service</DrawerButton>
    </Form>

  )
}

export default AddNewServiceDrawer
