import { STAGE_API } from 'packages/startup_repository';
import { identity } from 'utils';
import {
  convertDataEntryPoint,
  convertDataEntryPoints,
  convertDataEntryRequestPayload,
  EDUCATION_API,
  EXPORT_API,
  TITLE_API,
} from './utils';
import httpClient, { ApiResponseType, API_HOST, GetParamsType } from '../http_client';
import {
  AddDataEntryPointType,
  CreateDataEntryApiResponseType,
  DataEntryPointType,
  ExportTableDataModel,
  GetEducationApiResponse,
  GetEducationByIdApiResponse,
  UpdateDataEntryApiResponseType,
} from './types';

const getEducationApi = () => {
  const url = EDUCATION_API;
  return httpClient.get<ApiResponseType<GetEducationApiResponse>>(url);
}

const getEducationByIdApi = (id: number) => {
  const url = `${EDUCATION_API}/${id}`;
  return httpClient.get<ApiResponseType<GetEducationByIdApiResponse>>(url);
}

const deleteEducationApi = (params: GetParamsType) => {
  const url = EDUCATION_API;
  const config = { params };
  return httpClient.delete(url, config);
}

const getTitlesApi = () => {
  const url = TITLE_API;
  return httpClient.get<ApiResponseType<GetEducationApiResponse>>(url);
}

const getTitleByIdApi = (id: number) => {
  const url = `${TITLE_API}/${id}`;
  return httpClient.get<ApiResponseType<GetEducationByIdApiResponse>>(url);
}

const deleteTitlesApi = (params: GetParamsType) => {
  const url = TITLE_API;
  const config = { params };
  return httpClient.delete(url, config);
}

export const getEducation = async () => {
  const response = await getEducationApi();
  const { error, httpStatus, data } = response;
  return { error, httpStatus, data: convertDataEntryPoints(data?.result) };
}

export const getEducationById = async (id: number) => {
  const response = await getEducationByIdApi(id);
  const { error, httpStatus, data } = response;
  return { error, httpStatus, data: convertDataEntryPoint(data?.result) };
}

export const deleteEducation = async (params: GetParamsType) => {
  const response = await deleteEducationApi(params);
  const { error, httpStatus, data } = response;
  return { error, httpStatus, data };
}

export const getTitles = async () => {
  const response = await getTitlesApi();
  const { error, httpStatus, data } = response;
  return { error, httpStatus, data: convertDataEntryPoints(data?.result) };
}

export const getTitleById = async (id: number) => {
  const response = await getTitleByIdApi(id);
  const { error, httpStatus, data } = response;
  return { error, httpStatus, data: convertDataEntryPoint(data?.result) };
}

export const deleteTitles = async (params: GetParamsType) => {
  const response = await deleteTitlesApi(params);
  const { error, httpStatus, data } = response;
  return { error, httpStatus, data };
}

const createEducationApi = (payload: AddDataEntryPointType) => {
  const url = EDUCATION_API;
  const requestPayload = convertDataEntryRequestPayload(payload);
  return httpClient.post<ApiResponseType<CreateDataEntryApiResponseType>>(url, requestPayload);
};

export const createEducation = async (payload: AddDataEntryPointType) => {
  const response = await createEducationApi(payload);
  const { data, error, httpStatus } = response;
  return { error, httpStatus, data: data?.id[0] };
}

const createTitleApi = (payload: AddDataEntryPointType) => {
  const url = TITLE_API;
  const requestPayload = convertDataEntryRequestPayload(payload);
  return httpClient.post<ApiResponseType<CreateDataEntryApiResponseType>>(url, requestPayload);
};

export const createTitle = async (payload: AddDataEntryPointType) => {
  const response = await createTitleApi(payload);
  const { data, error, httpStatus } = response;
  return { error, httpStatus, data: data?.id[0] };
}

const createStartupStageApi = (payload: AddDataEntryPointType) => {
  const url = STAGE_API;
  const requestPayload = convertDataEntryRequestPayload(payload);
  return httpClient.post<ApiResponseType<CreateDataEntryApiResponseType>>(url, requestPayload);
};

export const createStartupStage = async (payload: AddDataEntryPointType) => {
  const response = await createStartupStageApi(payload);
  const { data, error, httpStatus } = response;
  return { error, httpStatus, data: data?.id[0] };
}

const updateEducationByIdApi = (id: number, payload: DataEntryPointType) => {
  const url = `${EDUCATION_API}/${id}`;
  const requestPayload = convertDataEntryRequestPayload(payload);
  return httpClient.put<ApiResponseType<UpdateDataEntryApiResponseType>>(url, requestPayload);
};

export const updateEducationById = async (id: number, payload: DataEntryPointType) => {
  const response = await updateEducationByIdApi(id, payload);
  const { error, httpStatus } = response;
  return { error, httpStatus };
}

const updateTitleByIdApi = (id: number, payload: DataEntryPointType) => {
  const url = `${TITLE_API}/${id}`;
  const requestPayload = convertDataEntryRequestPayload(payload);
  return httpClient.put<ApiResponseType<UpdateDataEntryApiResponseType>>(url, requestPayload);
};

export const updateTitleById = async (id: number, payload: DataEntryPointType) => {
  const response = await updateTitleByIdApi(id, payload);
  const { error, httpStatus } = response;
  return { error, httpStatus };
}

const updateStartupStageByIdApi = (id: number, payload: DataEntryPointType) => {
  const url = `${STAGE_API}/${id}`;
  const requestPayload = convertDataEntryRequestPayload(payload);
  return httpClient.put<ApiResponseType<UpdateDataEntryApiResponseType>>(url, requestPayload);
};

export const updateStartupStageById = async (id: number, payload: DataEntryPointType) => {
  const response = await updateStartupStageByIdApi(id, payload);
  const { error, httpStatus } = response;
  return { error, httpStatus };
}

export const exportTableDataAsExcel = (table: ExportTableDataModel, query?: string) => {
  let url = `${API_HOST}/${EXPORT_API}?m=${table as string}`;
  if (identity.isTruthyString(query)) {
    url += `&${query}`;
  }
  window.open(url, '_blank');
};
