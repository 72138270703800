import { connect } from 'react-redux';
import { Action, bindActionCreators, Dispatch } from 'redux';

import { StateType } from '../../../../types';
import { selectServiceDetails, selectServicesByServiceProviderId } from '../../../../redux/selectors/serviceProviders';
import
{
  getServiceById,
  getServiceProviders,
  getServicesByServiceProviderId,
} from '../../../../redux/actions/serviceProviders';
import { setIsDrawerOpen } from '../../../../redux/actions/workspace';

const mapStateToProps = (state: StateType) => ({
  services: selectServicesByServiceProviderId(state),
  service: selectServiceDetails(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  actions: bindActionCreators({
    getServiceProviders,
    setIsDrawerOpen,
    getServicesByServiceProviderId,
    getServiceById,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps);
