import React from 'react';

import SVGWrap from './SVGWrap';

const EventIcon = () => (
  <SVGWrap>
    <path xmlns="http://www.w3.org/2000/svg" d="M14 3C14 2.73478 13.8946 2.48043 13.7071 2.29289C13.5196 2.10536 13.2652 2 13 2H11V1H10V2H6V1H5V2H3C2.73478 2 2.48043 2.10536 2.29289 2.29289C2.10536 2.48043 2 2.73478 2 3V13C2 13.2652 2.10536 13.5196 2.29289 13.7071C2.48043 13.8946 2.73478 14 3 14H5V13H3V3H5V4H6V3H10V4H11V3H13V6H14V3Z" />
    <path xmlns="http://www.w3.org/2000/svg" d="M10.5 7.5L11.7745 9.969L14.5 10.3645L12.5 12.2865L13 15L10.5 13.719L8 15L8.5 12.2865L6.5 10.3645L9.3 9.969L10.5 7.5Z" />
  </SVGWrap>
);

export default EventIcon;
