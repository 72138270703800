import React from 'react';

import { SEARCH_RESULT_TYPE } from 'packages/search_repository/utils/constants';
import history from 'history_instance';
import { SearchType } from 'packages/search_repository/types';
import SearchResponseItem from '../components/SearchResponseItem';
import { SEARCH_RESULT_TYPE_COMPOSE_FUNCTION } from './constants';

export const handleSearchResponseItemClick = (item: SearchType) => {
  const composePath = SEARCH_RESULT_TYPE_COMPOSE_FUNCTION[item.type];
  const path = composePath(item.id);
  history.push(path);
}

export const formatSearchResults = (searchResults: SearchType[]) => searchResults.map((item) => ({
  label: (
    <SearchResponseItem
      key={`${item.name}-${item.id}`}
      image={item.image}
      name={item.name}
      type={SEARCH_RESULT_TYPE[item.type] || item.type}
      onClick={() => handleSearchResponseItemClick(item)}
    />
  ),
  value: item.name,
  key:`${item.name}-${item.id}`,
}));

export default null;
