import { connect } from 'react-redux';
import { bindActionCreators, Dispatch, Action } from 'redux';

import { StateType } from 'types';
import { selectCurrentTab, selectRequestsCount } from 'redux/selectors/request';
import { selectUserType } from 'redux/selectors/auth';
import { setCurrentTab } from 'redux/actions/request';

const mapStateToProps = (state: StateType) => ({
  userType: selectUserType(state),
  currentTab: selectCurrentTab(state),
  count: selectRequestsCount(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  actions: bindActionCreators({ setCurrentTab }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps);
