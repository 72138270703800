/* eslint-disable prefer-promise-reject-errors */
import { Rule } from 'antd/lib/form';
import { Moment } from 'moment';

import { removeTimeFromDate } from 'utils/helpers/dateTimeHelpers';
import { identity, stringToArray } from 'utils';

const isValueGreaterThanZero = async (rule: Rule, value: string | number) => {
  const intValue = typeof value === 'string' ? parseInt(value, 10) : value;
  if (intValue <= 0) {
    throw new Error();
  }

  return rule;
};

type ValidationObject = {
  getFieldValue: Function,
}

export const requiredValidation: Rule = {
  required: true,
  message: 'Required field',
}

export const positiveNumbersOnly: Rule = {
  message: 'Field cannot be a negative number',
  pattern: /^[0-9]+$/,
}

export const emailValidation: Rule = {
  type: 'email',
  message: 'Invalid email',
}

export const greaterThanZeroOnly: Rule = {
  message: 'Value must be greater than 0',
  validator: isValueGreaterThanZero,
}

export const validateFacebookLink: Rule = {
  pattern: /^(http[s]?:\/\/)?(www\.)?facebook\.com\/[\w\W]+$/,
  message: 'Invalid facebook link',
}
export const validateInstagramLink: Rule = {
  pattern: /^(http[s]?:\/\/)?(www\.)?instagram\.com\/[\w\W]+$/,
  message: 'Invalid instagram link',
}

export const validateLinkedInLink: Rule = {
  pattern: /^(http[s]?:\/\/)?(www\.)?linkedin\.com\/[\w\W]+$/,
  message: 'Invalid linkedIn link',
}

export const validateTwitterLink: Rule = {
  pattern: /^(http[s]?:\/\/)?(www\.)?twitter\.com\/[\w\W]+$/,
  message: 'Invalid twitter link',
}

export const passwordMatchValidator = ({ getFieldValue }: ValidationObject) => ({
  validator(rule: Rule, value: string) {
    if (!value || getFieldValue('password') === value) {
      return Promise.resolve();
    }
    return Promise.reject(new Error('The passwords entered do not match'));
  },
});

export const isEndingBeforeStartingDateRule = ({ getFieldValue } : ValidationObject) => ({
  validator(rule: Rule, endingDate: Moment) {
    const startingDate: Moment = getFieldValue('startingDate');

    if (startingDate?.isAfter(endingDate)) {
      return Promise.reject('Invalid end');
    }

    return Promise.resolve();
  },
});

export const validateCharLength = () => ({
  validator(rule: Rule, value: string[]) {
    const hasItemsLongerThan60Chars = value.find((item) => item.length > 60);
    if (hasItemsLongerThan60Chars) {
      return Promise.reject(`${hasItemsLongerThan60Chars} is longer than 60 characters. Separate long tags on words using commas or enter key.`);
    }
    return Promise.resolve();
  },
});

export const validateCharLengthStringInput = () => ({
  validator(rule: Rule, value: string) {
    const values = stringToArray(value);
    const hasItemsLongerThan60Chars = values.find((item) => item.length > 60);
    if (hasItemsLongerThan60Chars) {
      return Promise.reject(`${hasItemsLongerThan60Chars} is longer than 60 characters. Separate long tags on words using commas.`);
    }
    return Promise.resolve();
  },
})

export const isStartAfterEndingTimeRule = ({ getFieldValue } : ValidationObject) => ({
  validator(rule: Rule, startingTime: Moment) {
    const endingDate: Moment = removeTimeFromDate(getFieldValue('endingDate'));
    const startingDate: Moment = removeTimeFromDate(getFieldValue('startingDate'));

    if (!identity.isFalsy(startingDate) && startingDate?.isBefore(endingDate)) {
      return Promise.resolve();
    }

    if (startingDate?.isAfter(endingDate)) {
      return Promise.reject('Invalid end');
    }

    const endingTime: Moment = getFieldValue('endingTime');

    if (startingTime?.isAfter(endingTime)) {
      return Promise.reject('Invalid start');
    }

    return Promise.resolve();
  },
});

export const isEndingBeforeStartTimeRule = ({ getFieldValue } : ValidationObject) => ({
  validator(rule: Rule, endingTime: Moment) {
    const startingDate: Moment = removeTimeFromDate(getFieldValue('startingDate'));
    const endingDate: Moment = removeTimeFromDate(getFieldValue('endingDate'));

    if (!identity.isFalsy(startingDate) && startingDate?.isBefore(endingDate)) {
      return Promise.resolve();
    }

    if (startingDate?.isAfter(endingDate)) {
      return Promise.reject('Invalid end');
    }

    const startingTime: Moment = getFieldValue('startingTime');

    if (endingTime?.isBefore(startingTime)) {
      return Promise.reject('Invalid end');
    }

    return Promise.resolve();
  },
});

export default requiredValidation;
