import React from 'react';
import { Button } from 'antd';
import classnames from 'classnames';

import { identity } from 'utils';
import { FilterButtonProps } from 'types/componentTypes/FilterButtonsProps';

import './FilterButtons.scss';

const FilterButtons = (props: FilterButtonProps) => {
  const { dataSource, setFilter, activeFilters } = props;

  return (
    <div className="filter-buttons-container">
      <Button
        className={classnames('secondary-button md', { 'filter-is-active': identity.isEmptyArray(activeFilters) })}
        autoFocus
        key="all"
        onClick={() => setFilter!({ data: 'all' })}
      >
        all
      </Button>
      { dataSource?.map(({ type, id, value }) => (
        <Button
          className={classnames('secondary-button md', { 'filter-is-active': (activeFilters || []).includes(id) })}
          key={`${type}-${id}`}
          onClick={() => setFilter!({ data: id })}
        >
          {value}
        </Button>
      ))}
    </div>
  )
}

FilterButtons.defaultProps = {
  activeFilter: 'all',
  setFilter: () => null,
}
export default FilterButtons;
