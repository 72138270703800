import { PeopleFilters } from 'types/people'
import { DataPayload } from 'types/reduxTypes/ActionTypes'

export const types = {
  SET_FILTERS: 'SET_FILTERS',
  RESET_FILTERS: 'RESET_FILTERS',
  SET_NATIONALITIES: 'SET_NATIONALITIES',
  SET_STARTUPS: 'SET_STARTUPS',
  SET_SKILLS: 'SET_SKILLS',
  SET_ROLES: 'SET_ROLES',
  SET_GENDER: 'SET_GENDER',
}

export const setFilters = (payload: PeopleFilters) => ({
  type: types.SET_FILTERS,
  payload,
});

export const resetFilters = (payload: object = {}) => ({
  type: types.RESET_FILTERS,
  payload,
});

export const setNationalities = (payload: DataPayload<number[]>) => ({
  type: types.SET_NATIONALITIES,
  payload,
});

export const setSkills = (payload: DataPayload<string[]>) => ({
  type: types.SET_SKILLS,
  payload,
});

export const setRoles = (payload: DataPayload<string[]>) => ({
  type: types.SET_ROLES,
  payload,
});

export const setStartups = (payload: DataPayload<number[]>) => ({
  type: types.SET_STARTUPS,
  payload,
});

export const setGender = (payload: DataPayload<number>) => ({
  type: types.SET_GENDER,
  payload,
});
