import { connect } from 'react-redux';
import { bindActionCreators, Dispatch, Action } from 'redux';

import { setIsDrawerOpen } from 'redux/actions/workspace';
import { getInTouch } from 'redux/actions/partners';
import { StateType } from 'types';
import { selectPartnerData } from 'redux/selectors/partners';
import { selectUserStartupId } from 'redux/selectors/auth';

const mapStateToProps = (state: StateType) => ({
  partnerData: selectPartnerData(state),
  startupId: selectUserStartupId(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  actions: bindActionCreators({
    setIsDrawerOpen,
    getInTouch,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps);
