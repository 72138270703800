import React from 'react';
import moment from 'moment';
import { Col } from 'antd';

import { WalletTransactionDetailsProps } from 'types/wallet';
import { identity, parseNumberToPrice } from 'utils';
import { Overview } from 'components/components';
import { formatSlashMDY } from '../../../../../../../utils/constants/formatters';

import './LeftContent.scss';

const LeftContent = (props: WalletTransactionDetailsProps) => {
  const { transaction, wallet } = props;
  const {
    amount, date, type, // details,
  } = transaction;
  // const { serviceProvider, serviceRequest } = details;
  const slashFormatDate = moment(date).format(formatSlashMDY);

  return (
    <div className="overviews overviews__left-content">
      <div className="overviews__transparent-container wallet-overview-summary">
        <h3 className="overview__title">Overview</h3>
        <Overview>
          <Col span={12}>
            <Overview.DataColumn label="Transaction" text={parseNumberToPrice(amount, 'AED')} />
          </Col>
          <Col span={12}>
            <Overview.DataColumn
              label="Wallet Balance"
              text={identity.isTruthyNumber(wallet?.balance) ? `${wallet?.balance} AED` : '0 AED'}
            />
          </Col>
        </Overview>
      </div>
      <div className="overviews__transparent-container">
        <h3 className="overview__title">Other Details</h3>
        <Overview>
          <div className="overview__card-data">
            <Overview.DataColumn label="Date" text={slashFormatDate} />
            <Overview.DataColumn label="Transaction Type" text={type || 'Not Specified'} />
          </div>
          {/* <RenderIf condition={isIncentive(serviceProvider.type)}>
            <div className="overview__card-data">
              <Overview.DataColumn label="Category" text={serviceRequest.service?.category || 'Not Specified'} />
            </div>
          </RenderIf> */}
        </Overview>
      </div>
    </div>
  );
}

export default LeftContent;
