import { connect } from 'react-redux';

import { selectContactPerson, selectServiceProviderInfo } from 'redux/selectors/serviceProviders';
import { StateType } from 'types';
import { selectUserServiceProviderId, selectUserType } from 'redux/selectors/auth';

const mapStateToProps = (state: StateType) => ({
  serviceProviderInfo: selectServiceProviderInfo(state),
  contactPerson: selectContactPerson(state),
  userType: selectUserType(state),
  userServiceProviderId: selectUserServiceProviderId(state),
});

export default connect(mapStateToProps);
