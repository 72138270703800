import React from 'react';
import { RadioChangeEvent } from 'antd';
import { RcFile } from 'antd/lib/upload';
import classnames from 'classnames';
import moment from 'moment';

import { requiredValidation, identity } from 'utils';
import { ADGMLicenseProps } from 'types/addDetails';
import { Input, RenderIf } from 'components/components';
import { formatDateDashYMDTimeHMS, formatSlashMDY } from '../../../utils/constants/formatters';
import { yesNoOptions } from '../../../RequestServiceDrawer/utils/constants';

import './ADGMLicense.scss';

const ADGMLicense = (props: ADGMLicenseProps) => {
  const {
    viewType,
    adgmLicense,
    setHasLicense,
    setADGMField,
    setADGMAll,
    hasLicense,
    title,
    fileName,
    isAddMissingDetails,
  } = props;

  const {
    aDGMLicenseNo,
    legalADGMName,
    legalSignatoryName,
    legalSignatoryEmail,
    legalSignatoryTitle,
    address,
    acceptanceDate,
    issueDate,
    expiryDate,
  } = adgmLicense;

  const handleOnChange = (e: RadioChangeEvent) => {
    if (e.target.value === 'yes') {
      setHasLicense({ data : true });
    } else {
      setHasLicense({ data: false });
      setADGMAll({
        data: {
          id: 0,
          startupID: 0,
          aDGMLicenseUrl: '',
          aDGMLicenseNo: '',
          legalADGMName: '',
          legalSignatoryName: '',
          legalSignatoryEmail: '',
          legalSignatoryTitle: '',
          address: '',
          issueDate: '',
          expiryDate: '',
          acceptanceDate: '',
        },
      });
    }
  }

  const beforeUpload = (license: RcFile) => {
    setADGMField({ field: 'aDGMLicenseUrl', data: license as Blob });
    return false;
  }

  return (
    <div className="adgm-license">
      <Input.Heading title={title} />
      <Input label="Does the startup have ADGM license?" layout="vertical">
        <Input.Radio
          onChange={handleOnChange}
          value={hasLicense ? 'yes' : 'no'}
          values={yesNoOptions}
          defaultValue="no"
          direction="horizontal"
        />
      </Input>
      <RenderIf condition={hasLicense}>
        <>
          <div className={classnames('adgm-license__fields', { 'adgm-license__fields__add-missing-details': isAddMissingDetails })}>
            <Input label="License No." layout="vertical" required rules={[requiredValidation]}>
              <Input.InputField
                name="license_no"
                value={aDGMLicenseNo}
                onChange={(e) => setADGMField({ field: 'aDGMLicenseNo', data: e.target.value })}
              />
            </Input>
            <Input label="ADGM Legal Name" layout="vertical" required={isAddMissingDetails} rules={isAddMissingDetails ? [requiredValidation] : []}>
              <Input.InputField
                name="legalADGMName"
                value={legalADGMName}
                onChange={(e) => setADGMField({ field: 'legalADGMName', data: e.target.value })}
              />
            </Input>
            <Input label="ADGM Legal Signatory Name" layout="vertical" required={isAddMissingDetails} rules={isAddMissingDetails ? [requiredValidation] : []}>
              <Input.InputField
                name="legalSignatoryName"
                value={legalSignatoryName}
                onChange={(e) => setADGMField({ field: 'legalSignatoryName', data: e.target.value })}
              />
            </Input>
            <Input label="ADGM Legal Signatory Title" layout="vertical" required={isAddMissingDetails} rules={isAddMissingDetails ? [requiredValidation] : []}>
              <Input.InputField
                name="legalSignatoryTitle"
                value={legalSignatoryTitle}
                onChange={(e) => setADGMField({ field: 'legalSignatoryTitle', data: e.target.value })}
              />
            </Input>
            <Input label="ADGM Legal Signatory Email" layout="vertical" required={isAddMissingDetails} rules={isAddMissingDetails ? [requiredValidation] : []}>
              <Input.InputField
                name="legalSignatoryEmail"
                value={legalSignatoryEmail}
                onChange={(e) => setADGMField({ field: 'legalSignatoryEmail', data: e.target.value })}
              />
            </Input>
            <Input label="Registered WeWork Address" layout="vertical" required={isAddMissingDetails} rules={isAddMissingDetails ? [requiredValidation] : []}>
              <Input.InputField
                name="address"
                value={address}
                onChange={(e) => setADGMField({ field: 'address', data: e.target.value })}
              />
            </Input>
            <Input label="ADGM License Issue Date" layout="vertical" required={isAddMissingDetails} rules={isAddMissingDetails ? [requiredValidation] : []}>
              <Input.DatePicker
                name="issueDate"
                format={formatSlashMDY}
                value={identity.isTruthyString(issueDate) ? moment(issueDate) : null}
                onChange={(e) => setADGMField({ field: 'issueDate', data: moment(e).format(formatDateDashYMDTimeHMS) })}
              />
            </Input>
            <Input label="ADGM License Expiry Date" layout="vertical" required={isAddMissingDetails} rules={isAddMissingDetails ? [requiredValidation] : []}>
              <Input.DatePicker
                name="expiryDate"
                format={formatSlashMDY}
                value={identity.isTruthyString(expiryDate) ? moment(expiryDate) : null}
                onChange={(e) => setADGMField({ field: 'expiryDate', data: moment(e).format(formatDateDashYMDTimeHMS) })}
              />
            </Input>
            <Input label="HUB71 Acceptance Letter Date" layout="vertical" required={isAddMissingDetails} rules={isAddMissingDetails ? [requiredValidation] : []}>
              <Input.DatePicker
                name="acceptanceDate"
                format={formatSlashMDY}
                value={identity.isTruthyString(acceptanceDate) ? moment(acceptanceDate) : null}
                onChange={(e) => setADGMField({ field: 'acceptanceDate', data: moment(e).format(formatDateDashYMDTimeHMS) })}
              />
            </Input>
          </div>
          <Input name="documents" required={isAddMissingDetails} label={`${viewType === 'startup' ? '' : 'Upload'} ADGM License`} layout="vertical">
            <Input.DraggerUpload
              defaultFileList={identity.isEmptyString(fileName) ? []
                : [{
                  uid: '1',
                  name: fileName!,
                }]}
              allowChange
              accept=".pdf"
              beforeUpload={beforeUpload}
              isRoundableButton
            />
          </Input>
        </>
      </RenderIf>
    </div>
  );
}

ADGMLicense.defaultProps = {
  viewType: 'missing_details',
  title:'ADGM License',
  isAddMissingDetails: false,
}

export default ADGMLicense;
