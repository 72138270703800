import { createSelector } from 'reselect';

import { StateType } from '../../../types';

const selectActivitiesState = (state: StateType) => state.activitiesReducer;

export const selectActivities = createSelector(
  selectActivitiesState,
  (state) => state.activities,
);

export const selectIsLoading = createSelector(
  selectActivitiesState,
  (state) => state.isLoading,
);

export const selectFilters = createSelector(
  selectActivitiesState,
  (state) => state.filters,
);

export const selectSortBy = createSelector(
  selectActivitiesState,
  (state) => state.filters.sortBy,
);

export const selectAddPeopleOptions = createSelector(
  selectActivitiesState,
  (state) => state.addPeopleOptions,
);

export const selectCheckedPeople = createSelector(
  selectActivitiesState,
  (state) => state.checkedPeople,
);
