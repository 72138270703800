import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom';

import { Form } from 'antd';
import { IncentiveRequestDetailsProps } from 'types/requests';
import { TitleWithActions, FormBuilder } from '../../../components';
import { requestDetailsFieldsTop, requestDetailsFieldsBottom } from './utils/constants';
import RequestedBy from './components/RequestedBy';
import buttons from './utils/buttons';

import './IncentiveRequestDetails.scss';

const IncentiveRequestDetails = (props: IncentiveRequestDetailsProps) => {
  const params: { id?: string } = useParams();
  const { id } = params;
  const [form] = Form.useForm();

  const { actions, requestDetails } = props;

  const { startup, person } = requestDetails;

  useEffect(() => {
    actions.getRequestById({ id: Number(id) });
  }, []);

  useEffect(() => {
    form.setFieldsValue(requestDetails);
  }, [form, requestDetails])

  return (
    <div className="incentive-request-details content-container">
      <TitleWithActions title={`Request #${requestDetails.requestNumber}`} buttons={buttons()} />
      <Form form={form} className="incentive-request-details__form form-standalone">
        <FormBuilder
          formFragment
          oneColumn
          layout="horizontal"
          fields={requestDetailsFieldsTop}
        />
        <RequestedBy personDetails={person} startup={startup} />
        <FormBuilder
          formFragment
          oneColumn
          layout="horizontal"
          fields={requestDetailsFieldsBottom}
        />
      </Form>
    </div>
  )
}

export default IncentiveRequestDetails;
