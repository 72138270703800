import React from 'react';

import { HeadingProps } from 'types/componentTypes';

import './Heading.scss';

const Heading = (props: HeadingProps) => (
  <h1 className="heading">{props.title}</h1>
);

export default Heading;
