import { connect } from 'react-redux';

import { StateType } from '../../../../types';
import { selectRequestProposal } from '../../../../redux/selectors/request';
import { selectUserType } from '../../../../redux/selectors/auth';

const mapStateToProps = (state: StateType) => ({
  proposal: selectRequestProposal(state),
  userType: selectUserType(state),
});

export default connect(mapStateToProps);
