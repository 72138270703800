export const getFileType = (value: string) => {
  switch (value) {
    case 'html':
    case 'pdf':
    case 'csv':
      return value;
    default:
      return undefined;
  }
}

export default null;
