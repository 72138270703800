import React from 'react';
import classnames from 'classnames';

import { identity } from 'utils';
import { CardCoverProps } from 'types/componentTypes';
import { ImageIcon } from 'assets/icons';

const CardCover = (props: CardCoverProps) => {
  const { component, data, isPeople } = props;

  if (identity.isTruthyString(data?.imageLink)) {
    return (
      <img
        alt="example"
        src={component === 'service-provider' ? data.serviceProvider.imageLink : data.imageLink}
        className={classnames('card-logo', { 'card-logo-cover' : isPeople })}
      />
    )
  }

  return (
    <div className="empty-container">
      <div className="empty-image">
        <ImageIcon />
      </div>
    </div>
  );
}

CardCover.defaultProps = {
  component: '',
}

export default CardCover;
