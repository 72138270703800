import { ServiceQuery, ServiceCategoryType, ServiceSubCategoryType } from 'packages/service_repository';
import {
  SetServicesPayload,
  DeleteServicePayload,
  SetServiceByIdPayload,
  UpdateServiceByIdPayload,
  ServiceCountPayload,
  SetCurrentServicesTabPayload,
  SetCurrentSubCategoryTabPayload,
  SetServiceTimeUnitsPayload,
} from 'types/reduxTypes/ActionTypes/ServicesType';
import { DataPayload, IdPayload, IsLoadingPayload } from 'types/reduxTypes/ActionTypes';
import { ServiceAndSpActiveTabPayload, ServicesFiltersType } from 'types/services'
import { AddServiceCategoryPayload, AddServicePayload, AddServiceSubCategoryPayload } from 'types/reduxTypes/ActionTypes/ServiceTypes';

export const types = {
  GET_SERVICES: 'GET_SERVICES',
  SET_SERVICES: 'SET_SERVICES',
  SET_SERVICES_IS_LOADING: 'SET_SERVICES_IS_LOADING',
  DELETE_SERVICE: 'DELETE_SERVICE',
  GET_SERVICE_BY_ID: 'GET_SERVICE_BY_ID',
  SET_SERVICE_BY_ID: 'SET_SERVICE_BY_ID',
  UPDATE_SERVICE_BY_ID: 'UPDATE_SERVICE_BY_ID',
  SET_SELECTED_SERVICE_PROVIDER: 'SET_SELECTED_SERVICE_PROVIDER',
  ADD_SERVICE: 'ADD_SERVICE',
  GET_SERVICE_FILTERS: 'GET_SERVICE_FILTERS',
  GET_SERVICE_CATEGORIES: 'GET_SERVICE_CATEGORIES',
  SET_SERVICE_CATEGORIES: 'SET_SERVICE_CATEGORIES',
  GET_SERVICE_SUB_CATEGORIES: 'GET_SERVICE_SUB_CATEGORIES',
  SET_SERVICE_SUB_CATEGORIES: 'SET_SERVICE_SUB_CATEGORIES',
  GET_INSURANCE_TYPES: 'GET_INSURANCE_TYPES',
  SET_SERVICE_SP_ACTIVE_TAB: 'SET_SERVICE_SP_ACTIVE_TAB',
  GET_SERVICE_COUNT: 'GET_SERVICE_COUNT',
  SET_SERVICE_COUNT: 'SET_SERVICE_COUNT',
  SET_CURRENT_SERVICES_TAB: 'SET_CURRENT_SERVICES_TAB',
  GET_ALL_SERVICE_SUB_CATEGORIES: 'GET_ALL_SERVICE_SUB_CATEGORIES',
  SET_ALL_SERVICE_SUB_CATEGORIES: 'SET_ALL_SERVICE_SUB_CATEGORIES',
  SET_CURRENT_SUB_CATEGORY_TAB: 'SET_CURRENT_SUB_CATEGORY_TAB',
  ADD_SERVICE_CATEGORY: 'ADD_SERVICE_CATEGORY',
  ADD_SERVICE_SUBCATEGORY: 'ADD_SERVICE_SUBCATEGORY',
  DELETE_SERVICE_CATEGORY: 'DELETE_SERVICE_CATEGORY',
  DELETE_SERVICE_CATEGORY_BY_ID: 'DELETE_SERVICE_CATEGORY_BY_ID',
  DELETE_SERVICE_SUB_CATEGORY: 'DELETE_SERVICE_SUB_CATEGORY',
  DELETE_SERVICE_SUB_CATEGORY_BY_ID: 'DELETE_SERVICE_SUB_CATEGORY_BY_ID',
  UPDATE_SERVICE_CATEGORY_BY_ID: 'UPDATE_SERVICE_CATEGORY_BY_ID',
  UPDATE_SERVICE_SUB_CATEGORY_BY_ID: 'UPDATE_SERVICE_SUB_CATEGORY_BY_ID',
  SET_SERVICE_CATEGORY_DETAILS: 'SET_SERVICE_CATEGORY_DETAILS',
  SET_SERVICE_SUB_CATEGORY_DETAILS: 'SET_SERVICE_SUB_CATEGORY_DETAILS',
  SET_IS_MAIN_CATEGORY: 'SET_IS_MAIN_CATEGORY',
  SET_CATEGORY_ID: 'SET_CATEGORY_ID',
  GET_SERVICE_UNITS_LIST: 'GET_SERVICE_UNITS_LIST',
  SET_SERVICE_UNITS_LIST: 'SET_SERVICE_UNITS_LIST',
  SET_SERVICES_FILTERS: 'SET_SERVICES_FILTERS',
  RESET_SERVICES: 'RESET_SERVICES',
};

export const resetServices = (payload: object = {}) => ({
  type: types.RESET_SERVICES,
  payload,
});

export const getServices = (payload: ServiceQuery) => ({
  type: types.GET_SERVICES,
  payload,
});

export const setServices = (payload: SetServicesPayload) => ({
  type: types.SET_SERVICES,
  payload,
});

export const setIsLoading = (payload: IsLoadingPayload) => ({
  type: types.SET_SERVICES_IS_LOADING,
  payload,
});

export const deleteService = (payload: DeleteServicePayload) => ({
  type: types.DELETE_SERVICE,
  payload,
});

export const getServiceById = (payload: IdPayload) => ({
  type: types.GET_SERVICE_BY_ID,
  payload,
});

export const setServiceById = (payload: SetServiceByIdPayload) => ({
  type: types.SET_SERVICE_BY_ID,
  payload,
});

export const updateServiceById = (payload: UpdateServiceByIdPayload) => ({
  type: types.UPDATE_SERVICE_BY_ID,
  payload,
});

export const setSelectedServiceProvider = (payload: IdPayload) => ({
  type: types.SET_SELECTED_SERVICE_PROVIDER,
  payload,
});

export const addService = (payload: AddServicePayload) => ({
  type: types.ADD_SERVICE,
  payload,
});

export const getServiceFilters = (payload: object = {}) => ({
  type: types.GET_SERVICE_FILTERS,
  payload,
});

export const getServiceCategories = (payload: object = {}) => ({
  type: types.GET_SERVICE_CATEGORIES,
  payload,
});

export const setServiceCategories = (payload: DataPayload<ServiceCategoryType[]>) => ({
  type: types.SET_SERVICE_CATEGORIES,
  payload,
});

export const getServiceSubCategories = (payload: IdPayload) => ({
  type: types.GET_SERVICE_SUB_CATEGORIES,
  payload,
});

export const setServiceSubCategories = (payload: DataPayload<ServiceSubCategoryType[]>) => ({
  type: types.SET_SERVICE_SUB_CATEGORIES,
  payload,
});

export const getInsuranceTypes = (payload: object = {}) => ({
  type: types.GET_INSURANCE_TYPES,
  payload,
});

export const setActiveTab = (payload: ServiceAndSpActiveTabPayload) => ({
  type: types.SET_SERVICE_SP_ACTIVE_TAB,
  payload,
});

export const getServicesCount = (payload: ServiceQuery) => ({
  type: types.GET_SERVICE_COUNT,
  payload,
});

export const setServicesCount = (payload: ServiceCountPayload) => ({
  type: types.SET_SERVICE_COUNT,
  payload,
});

export const setCurrentServicesTab = (payload: SetCurrentServicesTabPayload) => ({
  type: types.SET_CURRENT_SERVICES_TAB,
  payload,
});

export const getAllServiceSubCategories = (payload: object = {}) => ({
  type: types.GET_ALL_SERVICE_SUB_CATEGORIES,
  payload,
});

export const setAllServiceSubCategories = (payload: DataPayload<ServiceSubCategoryType[]>) => ({
  type: types.SET_ALL_SERVICE_SUB_CATEGORIES,
  payload,
});

export const setCurrentSubCategoryTab = (payload: SetCurrentSubCategoryTabPayload) => ({
  type: types.SET_CURRENT_SUB_CATEGORY_TAB,
  payload,
});

export const addServiceCategory = (payload: DataPayload<AddServiceCategoryPayload>) => ({
  type: types.ADD_SERVICE_CATEGORY,
  payload,
});

export const addServiceSubCategory = (payload: DataPayload<AddServiceSubCategoryPayload>) => ({
  type: types.ADD_SERVICE_SUBCATEGORY,
  payload,
});

export const deleteServiceCategory = (payload: object = {}) => ({
  type: types.DELETE_SERVICE_CATEGORY,
  payload,
});

export const deleteServiceSubCategory = (payload: object = {}) => ({
  type: types.DELETE_SERVICE_SUB_CATEGORY,
  payload,
});

export const deleteServiceCategoryById = (payload: IdPayload) => ({
  type: types.DELETE_SERVICE_CATEGORY_BY_ID,
  payload,
});

export const setServiceCategoryDetails = (payload: DataPayload<ServiceCategoryType>) => ({
  type: types.SET_SERVICE_CATEGORY_DETAILS,
  payload,
})

export const setServiceSubCategoryDetails = (payload: DataPayload<ServiceSubCategoryType>) => ({
  type: types.SET_SERVICE_SUB_CATEGORY_DETAILS,
  payload,
})

export const updateServiceCategoryById = (payload: ServiceCategoryType) => ({
  type: types.UPDATE_SERVICE_CATEGORY_BY_ID,
  payload,
});

export const deleteServiceSubCategoryById = (payload: IdPayload) => ({
  type: types.DELETE_SERVICE_SUB_CATEGORY_BY_ID,
  payload,
});

export const updateServiceSubCategoryById = (payload: ServiceSubCategoryType) => ({
  type: types.UPDATE_SERVICE_SUB_CATEGORY_BY_ID,
  payload,
});

export const setIsMainCategory = (payload: DataPayload<boolean>) => ({
  type: types.SET_IS_MAIN_CATEGORY,
  payload,
});

export const setCategoryID = (payload: DataPayload<number>) => ({
  type: types.SET_CATEGORY_ID,
  payload,
});

export const setServiceUnitsList = (payload: SetServiceTimeUnitsPayload) => ({
  type: types.SET_SERVICE_UNITS_LIST,
  payload,
});

export const getServiceUnitsList = (payload: object = {}) => ({
  type: types.GET_SERVICE_UNITS_LIST,
  payload,
});

export const setFilters = (payload: ServicesFiltersType) => ({
  type: types.SET_SERVICES_FILTERS,
  payload,
});
