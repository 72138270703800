import React, { useState } from 'react';
import { Button } from 'antd';
import moment from 'moment';

import { getCurrencyAmountString, identity } from 'utils';
import { DetailsOverviewProps } from 'types/cohorts/cohortDetails';
import { Overview } from '../../../components';
import { formatSlashMDY } from '../../../utils/constants/formatters';
import { ProgramDetails, AboutCohort } from '..';
import { NOT_SPECIFIED } from '../../utils/constants';

import './Overviews.scss';

const Overviews = (props: DetailsOverviewProps) => {
  const [isAboutModalOpen, setIsAboutModalOpen] = useState(false);
  const [isProgramModalOpen, setIsProgramModalOpen] = useState(false);
  const { selectedCohort } = props;

  const toggleAboutModal = () => {
    setIsAboutModalOpen(!isAboutModalOpen);
  };

  const toggleProgramModal = () => {
    setIsProgramModalOpen(!isProgramModalOpen);
  };

  return (
    <div className="overviews">
      <div className="overviews__transparent-container">
        <h3 className="overview__title">Cohorts Overview</h3>
        <Overview>
          <Overview.DataColumn label="From date" text={moment(selectedCohort.from).format(formatSlashMDY) || NOT_SPECIFIED} />
          <Overview.DataColumn label="To date" text={moment(selectedCohort.to).format(formatSlashMDY) || NOT_SPECIFIED} />
          <Overview.DataColumn label="Amount" text={getCurrencyAmountString(selectedCohort.amount) || NOT_SPECIFIED} />
          <Button className="secondary-button md" onClick={() => toggleAboutModal()}>ABOUT COHORT</Button>
        </Overview>
        <AboutCohort isModalOpen={isAboutModalOpen} toggleModal={toggleAboutModal} cohort={props.selectedCohort} />
      </div>
      {identity.isObjWithChildren(selectedCohort.program) ? (
        <div className="overviews__transparent-container">
          <h3 className="overview__title">Program Overview</h3>
          <Overview>
            <Overview.DataColumn label="Program" text={selectedCohort.type || NOT_SPECIFIED} />
            <Overview.DataColumn label="Amount" text={getCurrencyAmountString(selectedCohort?.program?.amount) || NOT_SPECIFIED} />
            <Button className="secondary-button md" onClick={() => toggleProgramModal()}>VIEW DETAILS</Button>
          </Overview>
          <ProgramDetails isModalOpen={isProgramModalOpen} toggleModal={toggleProgramModal} />
        </div>
      ) : null}
    </div>
  )
}

export default Overviews;
