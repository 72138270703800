import React from 'react';

import { RenderIfProps } from 'types/componentTypes/RenderIfProps';

// use this to wrap component when conditional rendering with null return for unfulfilled condition.
const RenderIf = (props: RenderIfProps) => {
  if (props.condition) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return (<>{props.children}</>);
  }

  return null;
}

RenderIf.defaultProps = {
  condition: false,
}

export default RenderIf;
