import { connect } from 'react-redux';
import { Action, bindActionCreators, Dispatch } from 'redux';

import { StateType } from 'types';
import {
  selectIsLoading,
  selectPartners,
  selectPartnersFiltersActive,
} from 'redux/selectors/partners';
import { getPartners, setPartnerData } from 'redux/actions/partners';
import { setIsDrawerOpen, setDrawerBody } from 'redux/actions/workspace';
import { selectStartupStatus, selectUserType } from 'redux/selectors/auth';

const mapStateToProps = (state: StateType) => ({
  partners: selectPartners(state),
  userType: selectUserType(state),
  isLoading: selectIsLoading(state),
  startupStatus: selectStartupStatus(state),
  filtersActive: selectPartnersFiltersActive(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  actions: bindActionCreators({
    getPartners,
    setIsDrawerOpen,
    setDrawerBody,
    setPartnerData,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps);
