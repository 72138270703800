import { useReducer } from 'react';

import { ServiceFiltersType } from 'types/services';
import {
  setFilters as setFiltersAC,
  setServiceProviderFilter as setServiceProviderFilterAC,
  setActiveSpFilters as setActiveSpFiltersAC,
  setServiceProvidersFiltersChanged as setServiceProvidersFiltersChangedAC,
  setServiceProviderName as setServiceProviderNameAC,
  setSpLimit as setSpLimitAC,
  setSpCurrentPage as setSpCurrentPageAC,
} from './actions';
import { reducer, initialState } from './reducer';

const useServicesOverviewHook = () => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const setFilters = (payload: ServiceFiltersType) => {
    dispatch(setFiltersAC(payload))
  }
  const setServiceProviderFilter = (data: string | number | undefined) => {
    dispatch(setServiceProviderFilterAC({ data }))
  }
  const setActiveSpFilters = (data: number[] | undefined) => {
    dispatch(setActiveSpFiltersAC({ data }))
  }
  const setServiceProvidersFiltersChanged = (data: boolean) => {
    dispatch(setServiceProvidersFiltersChangedAC({ data }))
  }
  const setServiceProviderName = (data: string) => {
    dispatch(setServiceProviderNameAC({ data }))
  }

  const setSpLimit = (data: number) => {
    dispatch(setSpLimitAC({ data }));
  }

  const setSpCurrentPage = (data: number) => {
    dispatch(setSpCurrentPageAC({ data }));
  }

  return {
    state,
    setFilters,
    setServiceProviderFilter,
    setActiveSpFilters,
    setServiceProvidersFiltersChanged,
    setServiceProviderName,
    setSpLimit,
    setSpCurrentPage,
  }
}

export default useServicesOverviewHook;
