import { connect } from 'react-redux';
import { bindActionCreators, Dispatch, Action } from 'redux';

import { sendGetInTouchPartnerRequest } from 'redux/actions/partners';
import { setDestroyDrawerOnClose } from 'redux/actions/workspace';
import { selectSelectedStartupId } from 'redux/selectors/startups';
import { StateType } from 'types';

const mapStateToProps = (state: StateType) => ({
  id: selectSelectedStartupId(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  actions: bindActionCreators({
    sendGetInTouchPartnerRequest,
    setDestroyDrawerOnClose,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps);
