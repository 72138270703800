import React from 'react';

import { DetailsDrawerRowProps } from 'types/startups/editStartup';

import './DetailsDrawerRow.scss';

const DetailsDrawerRow = (props: DetailsDrawerRowProps) => {
  const { label, item } = props;

  return (
    <div className="details-drawer-row">
      <span className="details-drawer-row__label">{`${label}:`}</span>
      <span className="details-drawer-row__item">{item}</span>
    </div>
  );
}

export default DetailsDrawerRow;
