/* eslint-disable @typescript-eslint/no-explicit-any */
import { identity } from '..';

export const sortHelper = (itemA: any, itemB: any) => {
  if (!(identity.isFalsy(itemA) || identity.isFalsy(itemB))) {
    return itemA.localeCompare(itemB);
  }
};

export default sortHelper;
