import { identity } from 'utils';
import { RequestServiceHookState } from '../reducer';

export const setDisabledButton = (state: RequestServiceHookState): boolean => {
  switch (state.service.categoryId) {
    case 1: {
      return !identity.isTruthyString(state.request.officeType)
        || !identity.isPositiveNumber(state.request.quantity);
    }
    case 2: {
      return !identity.isObjWithChildren(state.request.teamMember)
        || identity.isEmptyString(state.request.accommodationType);
    }
    case 3: {
      return !state.request.isApplyingForDependant
        ? [
          !identity.isObjWithChildren(state.request.teamMember),
          identity.isEmptyString(state.request.plan),
        ].some(Boolean)
        : [
          identity.isFalsy(state.request.dependantDateOfBirth),
          identity.isFalsy(state.request.dependantFullName),
          identity.isFalsy(state.request.dependantGender),
          identity.isFalsy(state.request.isDependantUAE),
          identity.isFalsy(state.request.relationToTM),
        ].some(Boolean);
    }
    default: {
      return !identity.isPositiveNumber(state.request.quantity);
    }
  }
}

export default null;
