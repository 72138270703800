import React from 'react';
import { Button } from 'antd';
import classnames from 'classnames';

import RenderIf from 'components/components/RenderIf';
import { identity } from 'utils';
import { CardButtonsProps } from 'types/componentTypes/CardProps';
import { disableDeleteServiceButton } from 'utils/constants/serviceRequestHelpers';

const CardButtons = (props: CardButtonsProps) => {
  const {
    firstButtonTitle,
    secondButtonTitle,
    firstButtonAction,
    secondButtonAction,
    isFirstButtonDisabled,
    isSecondButtonDisabled,
    className,
    data,
  } = props;
  const firstButtonDisabled = disableDeleteServiceButton(data)
  const isSecondaryButton = identity.isTruthyString(firstButtonTitle) && !identity.isTruthyString(secondButtonTitle);
  const isPrimaryButton = !identity.isTruthyString(firstButtonTitle) && identity.isTruthyString(secondButtonTitle);
  const displayOneButton = isSecondaryButton || isPrimaryButton;

  return (
    <div className={classnames(
      'buttons-section',
      { 'single-button-section': displayOneButton },
    )}
    >
      <RenderIf condition={identity.isTruthyString(firstButtonTitle)}>
        <Button
          type="primary"
          className={isFirstButtonDisabled ? 'secondary-delete-button md' : 'secondary-button md'}
          onClick={(e) => { e.stopPropagation(); firstButtonAction!(data) }}
          disabled={isFirstButtonDisabled ? firstButtonDisabled : false}
        >
          {firstButtonTitle}
        </Button>
      </RenderIf>
      <RenderIf condition={identity.isTruthyString(secondButtonTitle)}>
        <Button
          type="primary"
          className={className}
          onClick={(e) => { e.stopPropagation(); secondButtonAction!(data) }}
          disabled={isSecondButtonDisabled}
        >
          {secondButtonTitle}
        </Button>
      </RenderIf>
    </div>
  );
}

export default CardButtons;
