import React from 'react';

import SVGWrap from './SVGWrap';

const PerformanceIcon = () => (
  <SVGWrap>
    <path d="M13 8C13 7.19552 12.8046 6.40308 12.4304 5.6909L11.6824 6.43895C11.8925 6.93253 12.0006 7.46355 12 8H13Z" />
    <path d="M11.7071 5L11 4.29295L8.64265 6.6503C8.44245 6.55257 8.22279 6.50119 8 6.5C7.70333 6.5 7.41332 6.58798 7.16665 6.7528C6.91997 6.91762 6.72771 7.15189 6.61418 7.42598C6.50065 7.70007 6.47095 8.00167 6.52882 8.29264C6.5867 8.58361 6.72956 8.85088 6.93934 9.06066C7.14912 9.27044 7.4164 9.4133 7.70737 9.47118C7.99834 9.52906 8.29994 9.49935 8.57403 9.38582C8.84812 9.27229 9.08239 9.08003 9.24721 8.83336C9.41203 8.58668 9.5 8.29668 9.5 8C9.49881 7.77722 9.44744 7.55756 9.3497 7.35735L11.7071 5ZM8 8.5C7.90111 8.5 7.80444 8.47068 7.72222 8.41574C7.63999 8.3608 7.57591 8.28271 7.53806 8.19134C7.50022 8.09998 7.49032 7.99945 7.50961 7.90246C7.5289 7.80547 7.57652 7.71638 7.64645 7.64645C7.71638 7.57652 7.80547 7.5289 7.90246 7.50961C7.99945 7.49032 8.09998 7.50022 8.19134 7.53806C8.28271 7.57591 8.3608 7.63999 8.41574 7.72222C8.47068 7.80444 8.5 7.90111 8.5 8C8.49983 8.13256 8.4471 8.25964 8.35337 8.35337C8.25963 8.4471 8.13256 8.49983 8 8.5Z" />
    <path d="M8 4C8.53649 3.99947 9.06753 4.10752 9.56115 4.31765L10.3092 3.5696C9.54767 3.1709 8.6961 2.97554 7.83692 3.00244C6.97774 3.02935 6.14006 3.2776 5.40497 3.72317C4.66987 4.16875 4.06225 4.79655 3.64092 5.54581C3.21959 6.29507 2.99883 7.14041 3 8H4C4.00122 6.93951 4.42304 5.9228 5.17292 5.17292C5.9228 4.42304 6.93951 4.00122 8 4Z" />
    <path d="M8 15C6.61553 15 5.26216 14.5895 4.11101 13.8203C2.95987 13.0511 2.06266 11.9579 1.53285 10.6788C1.00303 9.3997 0.86441 7.99224 1.13451 6.63437C1.4046 5.2765 2.07129 4.02922 3.05026 3.05026C4.02922 2.07129 5.2765 1.4046 6.63437 1.13451C7.99224 0.86441 9.3997 1.00303 10.6788 1.53285C11.9579 2.06266 13.0511 2.95987 13.8203 4.11101C14.5895 5.26216 15 6.61553 15 8C14.9979 9.85588 14.2597 11.6351 12.9474 12.9474C11.6351 14.2597 9.85588 14.9979 8 15ZM8 2C6.81332 2 5.65328 2.3519 4.66658 3.01119C3.67989 3.67047 2.91085 4.60755 2.45673 5.7039C2.0026 6.80026 1.88378 8.00666 2.11529 9.17054C2.3468 10.3344 2.91825 11.4035 3.75736 12.2426C4.59648 13.0818 5.66558 13.6532 6.82946 13.8847C7.99335 14.1162 9.19975 13.9974 10.2961 13.5433C11.3925 13.0892 12.3295 12.3201 12.9888 11.3334C13.6481 10.3467 14 9.18669 14 8C13.9982 6.40926 13.3655 4.88419 12.2406 3.75936C11.1158 2.63454 9.59075 2.00182 8 2Z" />
  </SVGWrap>
);

export default PerformanceIcon;
