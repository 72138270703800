import { connect } from 'react-redux';

import { selectNotifications, selectIsLoading } from '../../../../redux/selectors/notifications';
import { StateType } from '../../../../types';

const mapStateToProps = (state: StateType) => ({
  notifications: selectNotifications(state),
  isLoading: selectIsLoading(state),
});

export default connect(mapStateToProps);
