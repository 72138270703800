/* eslint-disable @typescript-eslint/no-explicit-any */
import { Form } from 'antd'
import { useEffect, useMemo } from 'react';
import { identity } from 'utils';

type UseSettingsFormDefaultValuesType = {
    defaultValues?: unknown,
}

const useSettingsForm = (props: UseSettingsFormDefaultValuesType) => {
  const [form] = Form.useForm();
  const { defaultValues } = props;
  const isEdit = useMemo(() => identity.isObjWithChildren(defaultValues), [defaultValues]);

  useEffect(() => {
    if (isEdit) {
      form.setFieldsValue(defaultValues);
      return;
    }
    form.resetFields();
  }, [defaultValues]);

  return {
    isEdit,
    form,
  }
}

export default useSettingsForm;
