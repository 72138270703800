import React from 'react';

import { identity } from 'utils';
import { OverviewProps } from 'types/programDetails/overview';
import OverviewDataColumn from './components/OverviewDataColumn';
import RenderIf from '../RenderIf';

import './Overview.scss';

const Overview = (props: OverviewProps) => {
  const { title, headerContent, footerContent } = props;

  return (
    <div className="overview">
      <div className="overview__container">
        <RenderIf condition={identity.isTruthyString(title) || !identity.isFalsy(headerContent)}>
          <div className="overview__header">
            <RenderIf condition={identity.isTruthyString(title)}>
              <h3 className="overview__title">{title}</h3>
            </RenderIf>
            {headerContent}
          </div>
        </RenderIf>
        <div className="overview__card">
          {props.children}
        </div>
      </div>
      {footerContent}
    </div>
  );
};

Overview.DataColumn = OverviewDataColumn;

Overview.defaultProps = {
  title: '',
  headerContent: null,
  footerContent: null,
}

export default Overview;
