import { connect } from 'react-redux';
import { Action, bindActionCreators, Dispatch } from 'redux';

import { StateType } from 'types';
import { selectServiceSubCategories } from 'redux/selectors/services';
import {
  getAllServiceSubCategories,
  deleteServiceSubCategoryById,
  updateServiceSubCategoryById,
  setServiceSubCategoryDetails,
  setIsMainCategory,
  setCategoryID,
} from 'redux/actions/services';
import { setDrawerBody, setIsDrawerOpen } from 'redux/actions/workspace';

const mapStateToProps = (state: StateType) => ({
  subCategories: selectServiceSubCategories(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  actions: bindActionCreators({
    getAllServiceSubCategories,
    deleteServiceSubCategoryById,
    updateServiceSubCategoryById,
    setDrawerBody,
    setIsDrawerOpen,
    setServiceSubCategoryDetails,
    setIsMainCategory,
    setCategoryID,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps);
