import { connect } from 'react-redux';
import { bindActionCreators, Dispatch, Action } from 'redux';

import { StateType } from 'types';
import { setCurrentSubCategoryTab } from 'redux/actions/services';
import { selectCurrentSubCategoryTab } from 'redux/selectors/services';

const mapStateToProps = (state: StateType) => ({
  servicesSubCategoryFilterTab: selectCurrentSubCategoryTab(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  actions: bindActionCreators({ setCurrentSubCategoryTab }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps);
