import React from 'react';

import SVGWrap from './SVGWrap';

const CloudServicesIcon = () => (
  <SVGWrap size={24} fill="none">
    <path d="M8.5 6H10M4 9H5.5M7 9H10M2.5 12H4M5.5 12H10M10 15H4M2.5 15H1M2.5 18H4M5.5 18H10M11.5 4C18 4 18 9.5 18 9.5C18 9.5 22.5 10 22.5 14.5C22.5 19 17.5 19 17.5 19H11.5" strokeWidth="1" />
  </SVGWrap>
);

export default CloudServicesIcon;
