import React, { useEffect } from 'react';
import { useParams } from 'react-router';

import { identity } from 'utils';
import { RequestDetailsProps } from 'types/requests/requestDetails';
import { RequestInfo, Proposal, RequestedBy } from './components';

import './RequestDetails.scss';

const RequestDetails = (props: RequestDetailsProps) => {
  const { isModal, actions } = props;
  const params: { id?: string } = useParams();

  useEffect(() => {
    if (!isModal && identity.isTruthyString(params.id)) {
      actions.getRequestById({ id: parseInt(params.id!, 10) });
    }
  }, [params]);

  return (
    <div className="request-details">
      <RequestInfo isModal={isModal} />
      <Proposal />
      <RequestedBy />
    </div>
  );
}

export default RequestDetails;
