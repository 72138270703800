import {
  SetCohortsPayload,
  SetCohortPayload,
  PatchCohortPayload,
  CreateCohortPayload,
  CohortQuery,
} from 'types/reduxTypes/ActionTypes/CohortTypes';
import { DataPayload, IdPayload, IsLoadingPayload } from 'types/reduxTypes/ActionTypes';
import { SetStartupListPayload, SetStartupsPayload } from 'types/reduxTypes/ActionTypes/StartupsTypes';

export const types = {
  GET_COHORTS: 'GET_COHORTS',
  SET_COHORTS: 'SET_COHORTS',
  GET_COHORT_BY_ID: 'GET_COHORT_BY_ID',
  SET_COHORT_BY_ID: 'SET_COHORT_BY_ID',
  PATCH_COHORT_BY_ID: 'PATCH_COHORT_BY_ID',
  CREATE_COHORT: 'CREATE_COHORT',
  SET_COHORT_STARTUPS: 'SET_COHORT_STARTUPS',
  GET_DOCUMENT_REVIEW_REQUESTS: 'GET_DOCUMENT_REVIEW_REQUESTS',
  SET_DOCUMENT_REVIEW_REQUESTS: 'SET_DOCUMENT_REVIEW_REQUESTS',
  GET_PROGRAMS: 'GET_PROGRAMS',
  SET_PROGRAMS: 'SET_PROGRAMS',
  SET_COHORTS_IS_LOADING: 'SET_COHORTS_IS_LOADING',
  GET_PROGRAM_COHORTS: 'GET_PROGRAM_COHORTS',
  SET_PROGRAM_COHORTS: 'SET_PROGRAM_COHORTS',
  GET_COHORTS_COUNT: 'GET_COHORTS_COUNT',
  SET_COHORTS_COUNT: 'SET_COHORTS_COUNT',
  RESET_COHORT: 'RESET_COHORT',
};

export const resetCohort = (payload: object = {}) => ({
  type: types.RESET_COHORT,
  payload,
});

export const getCohorts = (payload: CohortQuery = { query: {} }) => ({
  type: types.GET_COHORTS,
  payload,
});

export const setCohorts = (payload: SetCohortsPayload) => ({
  type: types.SET_COHORTS,
  payload,
});

export const setCohortStartups = (payload: SetStartupsPayload) => ({
  type: types.SET_COHORT_STARTUPS,
  payload,
});

export const getCohortById = (payload: IdPayload) => ({
  type: types.GET_COHORT_BY_ID,
  payload,
});

export const setCohortById = (payload: SetCohortPayload) => ({
  type: types.SET_COHORT_BY_ID,
  payload,
});

export const patchCohortById = (payload: PatchCohortPayload) => ({
  type: types.PATCH_COHORT_BY_ID,
  payload,
});

export const createCohort = (payload: CreateCohortPayload) => ({
  type: types.CREATE_COHORT,
  payload,
});

export const getDocumentReviewRequests = (payload: IdPayload) => ({
  type: types.GET_DOCUMENT_REVIEW_REQUESTS,
  payload,
});

export const setReviewDocumentRequests = (payload: SetStartupListPayload) => ({
  type: types.SET_DOCUMENT_REVIEW_REQUESTS,
  payload,
});

export const getPrograms = (payload: object = {}) => ({
  type: types.GET_PROGRAMS,
  payload,
});

export const setPrograms = (payload: object = {}) => ({
  type: types.SET_PROGRAMS,
  payload,
});

export const setIsLoading = (payload: IsLoadingPayload) => ({
  type: types.SET_COHORTS_IS_LOADING,
  payload,
});

export const getProgramCohorts = (payload: IdPayload) => ({
  type: types.GET_PROGRAM_COHORTS,
  payload,
});

export const setProgramCohorts = (payload: SetCohortsPayload) => ({
  type: types.SET_PROGRAM_COHORTS,
  payload,
});

export const setCohortsCount = (payload: DataPayload<number>) => ({
  type: types.SET_COHORTS_COUNT,
  payload,
});

export const getCohortsCount = (payload: object = {}) => ({
  type: types.GET_COHORTS_COUNT,
  payload,
});
