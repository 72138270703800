import React from 'react';
import { Breadcrumb } from 'antd';
import { CgChevronLeft } from 'react-icons/cg';

import { BreadcrumbProps } from '../../../types/componentTypes/BreadcrumbProps';

import './Breadcrumb.scss';

const BreadCrumb = (props: BreadcrumbProps) => {
  const { routeName, navigateTo } = props;

  return (
    <Breadcrumb className="breadcrumb-container">
      <Breadcrumb.Item className="title" href={navigateTo}>
        <span className="title">
          <CgChevronLeft className="back-button" />
          {`Back to ${routeName}`}
        </span>
      </Breadcrumb.Item>
    </Breadcrumb>
  );
};

export default BreadCrumb;
