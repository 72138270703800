import { getFormFieldBuilder } from 'components/components/FormBuilder/utils/FormFieldBuilder';
import { inputTypes } from 'components/components/FormBuilder/utils/constants';
import { AddTemplateType } from 'packages/template_repository';
import { AddNewTemplateFieldsCreatorParams } from 'types/reduxTypes/ActionTypes/SettingsOverviewTypes';
import requiredValidation from 'utils/constants/validationRules';

export const defaultValue: AddTemplateType = {
  name: '',
  type: 1,
  file: '',
};

export const pdfFileTabs = [2, 99]

export const TemplateTypes = {
  AcceptanceLetter: 'Acceptance Letter',
  IncentiveAgreement: 'Incentive Agreement',
  WorkOrder: 'Work order',
}

export const TemplateOptions = [
  {
    value: 1,
    label: TemplateTypes.AcceptanceLetter,
  },
  {
    value: 2,
    label: TemplateTypes.IncentiveAgreement,
  },
  {
    value: 99,
    label: TemplateTypes.WorkOrder,
  },
];

export const AddNewTemplateDrawerFields = ({
  handleFileUpload,
  fileType,
}: AddNewTemplateFieldsCreatorParams) => [
  {
    fields: [
      getFormFieldBuilder('type', 'Choose template to add', inputTypes.select)
        .withOptions(TemplateOptions)
        .withRules([requiredValidation])
        .build(),
    ],
  },
  {
    fields: [
      getFormFieldBuilder('name', 'Name of the template')
        .withRules([requiredValidation])
        .build(),
    ],
  },
  {
    fields: [
      getFormFieldBuilder('file', 'Upload template', inputTypes.dragger)
        .withBeforeUpload(handleFileUpload)
        .withRules([requiredValidation])
        .withAccept(fileType)
        .build(),
    ],
  },
];
