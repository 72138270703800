import { connect } from 'react-redux';

import { StateType } from 'types';
import { selectIsRowTableLayout } from 'redux/selectors/workspace';

const mapStateToProps = (state: StateType) => ({
  isRowTableLayout: selectIsRowTableLayout(state),
});

export default connect(mapStateToProps);
