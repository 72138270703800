import React, { useEffect, useMemo } from 'react'

import { identity } from 'utils';
import { IdPayload } from 'types/reduxTypes/ActionTypes';
import { SettingsTableProps } from 'types/settingsOverview';
import { RowTable } from 'components/components/Table/components';
import { NoData } from '../../../components';

import './SettingsTable.scss';

const SettingsTable = (props: SettingsTableProps) => {
  const { deleteIds, onAddNew, actions } = props;

  useEffect(() => () => {
    actions.setDeleteIds({ data: [] });
  }, []);

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    actions.setDeleteIds({ data: newSelectedRowKeys as number[] })
  };

  const rowSelection = useMemo(() => ({
    deleteIds,
    onChange: onSelectChange,
  }), [deleteIds]);

  return (
    <div className="settings-table">
      {identity.isEmptyArray(props.dataSource)
        ? <NoData onAddNew={onAddNew} />
        : (
          <RowTable
            dataSource={props.dataSource}
            rowSelection={rowSelection}
            columns={props.columns}
            rowKey={(record: IdPayload) => record.id}
            className="table"
          />
        )}
    </div>
  )
}

export default SettingsTable;
