import React from 'react';

import SVGWrap from './SVGWrap';

const CheckmarkIcon = () => (
  <SVGWrap>
    <path d="M6.5 12L2 7.49997L2.707 6.79297L6.5 10.5855L13.293 3.79297L14 4.49997L6.5 12Z" />
  </SVGWrap>
);

export default CheckmarkIcon;
