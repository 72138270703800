import React from 'react'
import { Col, Row } from 'antd';

import { WalletTransactionDetailsProps } from 'types/wallet';
import { LeftContent, RightContent } from './components';

import './WalletModalContent.scss';

const WalletModalContent = (props: WalletTransactionDetailsProps) => (
  <div className="wallet-transaction-overview">
    <Row gutter={12}>
      <Col span={10}>
        <LeftContent transaction={props.transaction} wallet={props.wallet} />
      </Col>
      <Col span={14}>
        <RightContent transaction={props.transaction} />
      </Col>
    </Row>
  </div>
);

export default WalletModalContent;
