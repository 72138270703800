import React from 'react'
import { CardRow } from '../RequestItemCard/components';
import './PaymentStatusCard.scss'

interface PaymentStatusCardProps{
  paymentAmount: number
  paymentDate: string
  paymentReference: string
  paymentProcessed: string
}

const PaymentStatusCard = (props: PaymentStatusCardProps) => (
  <div className="request-item-card" style={{ marginTop: '30px' }}>
    <h1 className="request-item-card__title">Payment Information</h1>
    <CardRow label="Payment Reference" value={props.paymentReference} />
    <CardRow label="Payment Date" value={props.paymentDate} />
    <CardRow label="Payment Amount" value={`AED ${props.paymentAmount}`} />
    <CardRow label="Payment Status" value={props.paymentProcessed} />
  </div>
)
export default PaymentStatusCard;
