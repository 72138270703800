import httpClient, {
  ApiResponseType,
  HttpRequestConfig,
  queryBuilder,
  NumberResponseType,
  ParamsPayload,
  ResponseType,
  FORM_DATA_CONFIG,
} from '../http_client';
import {
  GetPartnerByIdApiResponse,
  GetPartnerTeamMembersListApiResponseType,
  GetPartnerByIdResponse,
  GetPartnerRequestsCountResponse,
  PartnerRequest,
  PartnerType,
  PartnerTypePayload,
  UpdatePartnerApiResponse,
  GetPartnersResponse,
  CreatePartnerGetInTouchRequestResponse,
  CreatePartnerGetInTouchRequestPayload,
  CreatePartnerGetInTouchRequestType,
  GetPartnerRequestResponse,
  UpdatePartnerRequestPayload,
  UpdatePartnerRequestApiResponseType,
  UpdatePartnerRequestType,
  UpdatePartnerRequestResponse,
  CreateGetInTouchPartnerPayload,
  CreateGetInTouchPartnerRequestApiResponseType,
  CreateGetInTouchPartnerRequestResponse,
  PartnerBeType,
  CreatePartnerApiResponseType,
} from './types';
import {
  PARTNER_REQUEST_API,
  PARTNER_API,
  PARTNER_EMAIL_API,
  PARTNER_TEAM_MEMBERS_API,
  convertPartnerTypeBody,
  convertPartnerTypeResponse,
  convertGetPartnerTeamMemberListResponse,
  convertPartners,
  convertPartnerGetInTouchRequestBody,
  convertPartnerRequest,
  convertPartnerRequestBody,
  convertCreateGetInTouchPartnerRequestPayload,
  convertCreatePartnerPayload,
} from './utils';

// API
const getInTouchPartnerRequestApi = (payload: CreateGetInTouchPartnerPayload) => {
  const url = PARTNER_REQUEST_API;
  const requestPayload = convertCreateGetInTouchPartnerRequestPayload(payload);
  return httpClient.post<ApiResponseType<CreateGetInTouchPartnerRequestApiResponseType>>(url, requestPayload);
}

const getPartnerRequestByIdApi = (id: number) => {
  const url = `${PARTNER_REQUEST_API}/${id}`;
  const params = queryBuilder()
    .preload()
    .toObject();
  const config = { params };
  return httpClient.get<ApiResponseType<GetPartnerRequestResponse>>(url, config);
}

const getPartnerRequestsCountApi = (query?: ParamsPayload) => {
  const url = PARTNER_REQUEST_API;
  const params = queryBuilder({ partner_id: query?.partnerId })
    .select([{ field: 'id', aggregation: 'count' }])
    .toObject();
  const config = { params };
  return httpClient.get<ApiResponseType<GetPartnerRequestsCountResponse>>(url, config);
}

const getPartnerByIdApi = (id: number) => {
  const url = `${PARTNER_API}/${id}`;
  return httpClient.get<ApiResponseType<GetPartnerByIdApiResponse>>(url);
}

const updatePartnerByIdApi = (id: number, payload: PartnerTypePayload) => {
  const url = `${PARTNER_API}/${id}`;
  return httpClient.put<ApiResponseType<UpdatePartnerApiResponse>>(url, payload);
};

const getPartnersApi = (query?: ParamsPayload) => {
  const url = PARTNER_API;
  const params = queryBuilder({ type: query?.type })
    .limit(query?.limit)
    .offset(query?.offset);
  if (query?.isPreload) {
    params.preload();
  }
  const config = { params: params.toObject() };
  return httpClient.get<ApiResponseType<GetPartnersResponse>>(url, config);
}

const postPartnerEmail = (payload: CreatePartnerGetInTouchRequestPayload) => {
  const url = PARTNER_EMAIL_API;
  return httpClient.post<ApiResponseType<CreatePartnerGetInTouchRequestResponse>>(url, payload);
}

const updatePartnerRequestApi = (id: number, payload: UpdatePartnerRequestPayload) => {
  const url = `${PARTNER_REQUEST_API}/${id}`;
  return httpClient.put<ApiResponseType<UpdatePartnerRequestApiResponseType>>(url, payload);
}

const createPartnerApi = (payload: PartnerBeType) => {
  const url = PARTNER_API;
  const requestPayload = convertCreatePartnerPayload(payload);
  return httpClient.post<ApiResponseType<CreatePartnerApiResponseType>>(url, requestPayload, FORM_DATA_CONFIG);
}

// SERVICES

export const getPartnerRequestById = async (id: number): Promise<ResponseType<PartnerRequest>> => {
  const response = await getPartnerRequestByIdApi(id);
  const { error, data, httpStatus } = response;
  return { error, httpStatus, data: convertPartnerRequest(data?.result) };
}

export const getPartnerRequestsCount = async (query?: ParamsPayload): Promise<NumberResponseType> => {
  const response = await getPartnerRequestsCountApi(query);
  const { error, data, httpStatus } = response;
  return { error, httpStatus, data: data?.result![0].id__count };
}

const getPartnerTeamMembersApi = (id: number) => {
  const url = `${PARTNER_TEAM_MEMBERS_API}`;
  const params = queryBuilder({ partner_id: id }).toObject();
  const config: HttpRequestConfig = { params };
  return httpClient.get<ApiResponseType<GetPartnerTeamMembersListApiResponseType>>(url, config);
}

export const getPartnerById = async (id: number): Promise<GetPartnerByIdResponse> => {
  const response = await getPartnerByIdApi(id);
  const { error, httpStatus, data } = response;
  return { error, httpStatus, data: convertPartnerTypeResponse(data?.result) };
}

export const getPartnerMembersById = async (id: number) => {
  const response = await getPartnerTeamMembersApi(id);
  const { error, httpStatus, data } = response;
  return { error, httpStatus, data: convertGetPartnerTeamMemberListResponse(data?.result) };
}

export const updatePartnerById = async (id: number, payload: PartnerType) => {
  const response = await updatePartnerByIdApi(id, convertPartnerTypeBody(payload));
  const { error, httpStatus } = response;
  return { error, httpStatus };
}

export const getPartners = async (query?: ParamsPayload): Promise<ResponseType<PartnerType[]>> => {
  const response = await getPartnersApi(query);
  const { error, httpStatus, data } = response;
  return { error, httpStatus, data: convertPartners(data?.result) };
}

export const createPartnerGetInTouchRequest = async (payload: CreatePartnerGetInTouchRequestType) => {
  const response = await postPartnerEmail(convertPartnerGetInTouchRequestBody(payload));
  const { error, httpStatus, data } = response;
  return { error, httpStatus, data: data?.id };
}

export const updatePartnerRequest = async (id: number, payload?: UpdatePartnerRequestType):
Promise<UpdatePartnerRequestResponse> => {
  const response = await updatePartnerRequestApi(id, convertPartnerRequestBody(payload));
  const { error, httpStatus } = response;
  return { error, httpStatus };
}

export const getInTouchPartnerRequest = async (payload: CreateGetInTouchPartnerPayload): Promise<
  CreateGetInTouchPartnerRequestResponse> => {
  const response = await getInTouchPartnerRequestApi(payload);
  const { error, httpStatus, data } = response;
  return { error, httpStatus, data: data?.id };
}

export const createPartner = async (payload: PartnerBeType) => {
  const response = await createPartnerApi(payload);
  const { data, error, httpStatus } = response;
  return { error, httpStatus, data: data?.id[0] };
}
