import { connect } from 'react-redux';
import { Action, bindActionCreators, Dispatch } from 'redux';

import { selectIsNoticeModalOpen, selectNoticeModalTemplate } from 'redux/selectors/workspace';
import { setIsNoticeModalOpen } from 'redux/actions/workspace';
import { StateType } from 'types';

const mapStateToProps = (state: StateType) => ({
  isOpen: selectIsNoticeModalOpen(state),
  details: selectNoticeModalTemplate(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  actions: bindActionCreators({
    setIsNoticeModalOpen,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps);
