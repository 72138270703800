import React, { useMemo } from 'react'
import moment from 'moment';

import { WalletTransactionDetailsProps } from 'types/wallet';
import { identity, parseNumberToPrice } from 'utils';
import { Overview, CompanyDeckFile } from 'components/components';
import { isReimbursement } from '../../utils';
import { DataColumn, Invoice } from './components';
import { formatSlashMDY } from '../../../../../../../utils/constants/formatters';
import { getFileName } from '../../../../../../../AddMissingDetails/utils';

import './RightContent.scss';

const RightContent = (props: WalletTransactionDetailsProps) => {
  const {
    transaction : {
      amount, date, type, details,
    },
  } = props;
  const { serviceProvider, serviceRequest } = details;
  const slashFormatInvoiceDate = useMemo(() => moment(date).format(formatSlashMDY), [date]);
  const priceAmount = useMemo(() => parseNumberToPrice(amount, 'AED'), [amount]);

  // TODO: Discuss with product regarding Reimbursements - there is a separate model on BE call ReimbursementRequest
  // For now, not important as only incentive requests being focused on
  if (isReimbursement(type)) {
    return (
      <div className="right__content-container">
        <div className="sub-container">
          <div className="content-container">
            <DataColumn heading="Receipt/Invoice Date" value={slashFormatInvoiceDate} />
            <DataColumn heading="Receipt/Invoice Amount" value={priceAmount} />
          </div>
          <div className="content-container__description">
            <DataColumn heading="Description" value="No Description specified for this transaction" />
          </div>
        </div>
        <div className="sub-container">
          <p className="sub-container__header">Receipt / Invoice</p>
          <Invoice fileLink="" />
        </div>
      </div>
    );
  }

  /**
   * Refactoring the code because this component was designed with 1 service per transaction.
   * There can be multiple services requested through a single request. Will update component once
   * discussed with product and design team. For now, only displaying service provider information
  */
  return (
    <div className="right__content-container">
      <div className="overviews">
        <div className="overviews__transparent-container">
          <h3 className="overview__title">Service Provider</h3>
          <Overview>
            <div className="overview__card-data">
              <Overview.DataColumn label="Name" text={serviceProvider.name || 'Unknown'} />
              <Overview.DataColumn label="Type" text={serviceProvider.type || 'Incentive'} />
            </div>
            <div className="overview__card-data">
              <div className="overview-data-column">
                <p className="overview-data-label">Invoice</p>
                {identity.isTruthyString(serviceRequest.invoice) ? (
                  <CompanyDeckFile
                    fileContent={serviceRequest.invoice!}
                    fileName={getFileName(serviceRequest.invoice!)}
                  />
                ) : (
                  <p className="overview-data-text">No invoice currently added.</p>
                )}
              </div>
            </div>
          </Overview>
        </div>
      </div>
    </div>
  );
}

export default RightContent;
