import React, { useEffect, useState } from 'react';

import DataColumn from 'components/components/DataColumns/components/DataColumn';
import { Input } from 'components/components';
import { OfficeColumnsProps } from 'types/services/requestService';
import { identity, positiveNumbersOnly } from 'utils';
import { officeTypeOptions } from 'components/RequestServiceDrawer/utils/constants';

const OfficeColumns = (props: OfficeColumnsProps) => {
  const {
    setOfficeType,
    setQuantity,
    setTeamMember,
    member,
    teamMembersOptions,
    state,
    getMemberById,
  } = props;

  const { teamMember, officeType, quantity } = state.request;

  const [teamMemberId, setTeamMemberId] = useState<number>(0);

  useEffect(() => {
    setQuantity({ data: 1 });
    setOfficeType({ data: '' });
    setTeamMember({ data: undefined })
  }, []);

  useEffect(() => {
    setTeamMember({ data: member })
  }, [member]);

  useEffect(() => {
    if (identity.isTruthyNumber(teamMemberId)) {
      getMemberById({ id: teamMemberId });
    } else {
      setTeamMember({ data: undefined });
    }
  }, [teamMemberId]);

  return (
    <div className="overview-columns">
      <DataColumn label="Choose team member" className="input">
        <Input.Select
          options={teamMembersOptions}
          value={identity.isTruthyNumber(teamMember?.id) ? teamMember?.id : 0}
          onChange={(data) => setTeamMemberId(data)}
        />
      </DataColumn>
      <Input label="Office type" className="overview-column" required layout="vertical">
        <Input.Select
          options={officeTypeOptions}
          value={identity.isTruthyString(officeType) ? officeType : ''}
          onChange={(data) => setOfficeType({ data })}
        />
      </Input>
      <Input rules={[positiveNumbersOnly]} label="Quantity" className="overview-column" required layout="vertical">
        <Input.InputNumber
          min={1}
          value={quantity}
          placeholder="Enter quantity"
          onChange={(e) => setQuantity({ data: e! })}
        />
      </Input>
    </div>
  );
}

export default OfficeColumns;
