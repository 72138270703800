import { PayloadAction } from '@reduxjs/toolkit';
import { LabeledValue } from 'antd/lib/select';

import { DataPayload } from 'types/reduxTypes/ActionTypes';
import { ADGMLicenseType } from 'packages/startup_repository';
import { defaultADGMLicense } from 'redux/utils';
import { types } from './actions';
import { SetADGMFieldType } from '../../../AddMissingDetails/utils/addMissingDetailsHook/actions';

type AddStartupHookType = {
  hasLicense: boolean,
  adgmLicense: ADGMLicenseType,
  filteredSectorOptions: LabeledValue[]
}

const initialState: AddStartupHookType = {
  adgmLicense: defaultADGMLicense,
  hasLicense: false,
  filteredSectorOptions: [],
}

const handleSetAdgmField = (state: AddStartupHookType, payload: SetADGMFieldType) => ({
  ...state, adgmLicense: { ...state.adgmLicense, [payload.field]: payload.data },
});

const handleSetAdgmAll = (state: AddStartupHookType, payload: DataPayload<ADGMLicenseType>) => ({
  ...state, adgmLicense: { ...state.adgmLicense, ...payload.data },
});

const handleSetHasLicense = (state: AddStartupHookType, payload: DataPayload<boolean>) => (
  { ...state, hasLicense: payload.data }
);

const handleSetFilteredSectorOptions = (state: AddStartupHookType, payload: DataPayload<LabeledValue[]>) => (
  { ...state, filteredSectorOptions: payload.data }
)

const reducer = (
  state: AddStartupHookType,
  action: PayloadAction<DataPayload<boolean | LabeledValue[] | ADGMLicenseType> | SetADGMFieldType>,
): AddStartupHookType => {
  switch (action.type) {
    case types.SET_HAS_LICENSE: {
      return handleSetHasLicense(state, <DataPayload<boolean>>action.payload);
    }
    case types.SET_FILTERED_SECTOR_OPTIONS: {
      return handleSetFilteredSectorOptions(state, <DataPayload<LabeledValue[]>>action.payload);
    }
    case types.SET_ADGM_FIELD: {
      return handleSetAdgmField(state, <SetADGMFieldType>action.payload);
    }
    case types.SET_ADGM_ALL: {
      return handleSetAdgmAll(state, <DataPayload<ADGMLicenseType>>action.payload);
    }
    default: {
      return state;
    }
  }
}

export { initialState, reducer };
