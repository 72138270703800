import React from 'react';

import { Navbar } from 'components/LayoutWithSidebar/components';
import AddMissingDetails from 'components/AddMissingDetails';

const AddMissingDetailsWithNavbar = () => (
  <div>
    <Navbar />
    <AddMissingDetails />
  </div>
);

export default AddMissingDetailsWithNavbar;
