import { StartupType as Startup } from 'packages/startup_repository';
import { FieldType } from '../../../../types/reduxTypes/ActionTypes/StartupsTypes';

export const types = {
  SET_FIELD: 'SET_FIELD',
  SET_ALL: 'SET_ALL',
}

export type SetAllType = {
  startup: Startup,
};

export type SetFieldType = {
  field: FieldType,
  value: string | Blob,
}

export const setAll = (payload: SetAllType) => ({
  type: types.SET_ALL,
  payload,
});

export const setField = (payload: SetFieldType) => ({
  type: types.SET_FIELD,
  payload,
});
