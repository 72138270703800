import React from 'react';

import SVGWrap from './SVGWrap';

const HousingIconV2 = () => (
  <SVGWrap size={24} fill="none">
    <path d="M19.8815 10.7941H19.4561V20.6985H13.9819V13.8317C13.9819 13.7143 13.887 13.6193 13.7696 13.6193H10.2299C10.1125 13.6193 10.0175 13.7143 10.0175 13.8317V20.6985H4.54272V10.9694L4.11796 11.5V20.6985H3.15044V21.1233H20.849V20.6985H19.8815V10.7941ZM19.8815 10.7941L19.882 7.93231H19.4567V10.3693L12.1496 3.06239C12.0664 2.9792 11.9325 2.9792 11.8493 3.06239L3 11.9114L3.30029 12.2117L11.9997 3.5131L20.6985 12.2117L21 11.912L19.8815 10.7941ZM10.4422 20.6985H13.5572V14.0441H10.4422V20.6985Z" strokeWidth="0.589944" />
  </SVGWrap>
);

export default HousingIconV2;
