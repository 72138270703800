import produce from 'immer';

import {
  IncentiveAgreementType,
  InvestmentStageType,
  SectorType,
  StageType,
} from 'packages/startup_repository';
import { checkStartupFiltersActive, getLabelValueObject, identity } from 'utils';
import { DataPayload, IsLoadingPayload } from 'types/reduxTypes/ActionTypes';
import { StartupsStateType } from 'types/reduxTypes/StateTypes';
import {
  StartupActionType,
  SetStartupsPayload,
  SetStartupByIdPayload,
  SetStartupServiceProviderPayload,
  SetStartupCountPayload,
  SetStartupForReviewPayload,
} from 'types/reduxTypes/ActionTypes/StartupsTypes';
import { StartupFiltersType } from 'types/startups/startupFilters';
import { ChangeRequestStatusPayload, SetRequestsPayload } from 'types/reduxTypes/ActionTypes/RequestsTypes';
import { SetTeamMembersPayload } from 'types/reduxTypes/ActionTypes/TeamMemberTypes';
import { StatusType } from 'types/requests';
import { ADGMLicenseType } from 'packages/startup_repository/types/adgm_license';
import { ServiceProviderType } from 'types/serviceProviders';
import { GetAmountSumsResponse } from 'packages/wallet_repository';
import { changeRequestStatusHelper, startupsDefaultState } from '../../utils';
import { types } from '../../actions/startups';

const defaultState: StartupsStateType = startupsDefaultState;

const handleSetSumsForSP = (state: StartupsStateType, payload: DataPayload<GetAmountSumsResponse[]>) => {
  state.details.serviceProviders = state.details.serviceProviders.map((sp) => {
    const serviceProvider = payload.data.find(({ serviceProviderId }) => serviceProviderId === sp.id);

    if (identity.isObjWithChildren(serviceProvider)) {
      sp.amountConsumed = serviceProvider!.amountConsumed;
      sp.amountBlocked = serviceProvider!.amountBlocked;
    }

    return sp;
  });

  return state;
}

const handleSetStartupServiceProviders = (state: StartupsStateType, payload: DataPayload<ServiceProviderType[]>) => {
  state.details.serviceProviders = payload.data;
  state.details.serviceProvidersCount = payload.data.length;
  return state;
}

const handleSetStartups = (state: StartupsStateType, payload: SetStartupsPayload) => {
  state.startups = payload.startups;
  state.filtersActive = checkStartupFiltersActive(state.filters);
  return state;
}

const handleSetStartupById = (state: StartupsStateType, payload: SetStartupByIdPayload) => {
  state.details.data = payload.startup;
  return state;
}

const handleSetStartupRequests = (state: StartupsStateType, payload: SetRequestsPayload) => {
  state.details.requests = payload.requests;
  return state;
}

const handleSetRequestStatusChange = (state: StartupsStateType, payload: ChangeRequestStatusPayload) => {
  const { id, status } = payload;
  const { details: { requests } } = state;
  state.details.requests = changeRequestStatusHelper(id, requests, status as StatusType);
  return state;
}

const handleSetStartupTeamMembers = (state: StartupsStateType, payload: SetTeamMembersPayload) => {
  state.details.teamMembers = payload.members;
  return state;
}

const handleSetServiceProvider = (state: StartupsStateType, payload: SetStartupServiceProviderPayload) => {
  state.details.serviceProvider = payload.serviceProvider;
  return state;
}

const handleSetStageOptions = (state: StartupsStateType, payload: DataPayload<StageType[]>) => {
  state.stageOptions = payload.data?.map((stage) => getLabelValueObject(stage.name, stage.id));
  return state;
}

const handleSetInvestmentStageOptions = (state: StartupsStateType, payload: DataPayload<InvestmentStageType[]>) => {
  state.investmentStageOptions = payload.data?.map((stage) => getLabelValueObject(stage.name, stage.id));
  return state;
}

const handleSetLoading = (state: StartupsStateType, payload: IsLoadingPayload) => {
  state.isLoading = payload.isLoading;
  return state;
}

const handleSetStartupCount = (state: StartupsStateType, payload: SetStartupCountPayload) => {
  state.startupCount = payload.startupCount;
  return state;
}

const handleSetSectorOptions = (state: StartupsStateType, payload: DataPayload<SectorType[]>) => {
  state.sectorOptions = payload.data?.map((sector) => getLabelValueObject(sector.name, sector.id));
  return state;
}

const handleSetStartupTeamMembersCount = (state: StartupsStateType, payload: DataPayload<number>) => {
  state.details.teamMembersCount = payload.data;
  return state;
}

const handleSetADGMLicenseByStartupId = (state: StartupsStateType, payload: DataPayload<ADGMLicenseType>) => {
  state.adgmLicenseByStartupId = payload.data;
  return state;
}

const handleSetStartupId = (state: StartupsStateType, payload: DataPayload<number>) => {
  state.startupId = payload.data;
  return state;
}

const handleSetStartupForReview = (state: StartupsStateType, payload: SetStartupForReviewPayload) => {
  state.selectedStartupForReview = payload.startup;
  return state;
}

const handleSetIncentiveAgreementById = (state: StartupsStateType, payload: DataPayload<IncentiveAgreementType>) => {
  state.incentiveAgreement = payload.data;
  return state;
}

const handleSetStartupFilters = (state: StartupsStateType, payload: StartupFiltersType) => {
  state.filters = payload;
  return state;
}

// eslint-disable-next-line default-param-last
export default produce((state: StartupsStateType = defaultState, action: StartupActionType) => {
  const { type, payload } = action;
  switch (type) {
    case types.SET_ADGM_LICENSE_BY_STARTUP_ID: {
      return handleSetADGMLicenseByStartupId(state, <DataPayload<ADGMLicenseType>>payload);
    }
    case types.SET_STARTUPS: {
      return handleSetStartups(state, <SetStartupsPayload>payload);
    }
    case types.SET_SECTOR_OPTIONS: {
      return handleSetSectorOptions(state, <DataPayload<SectorType[]>>payload);
    }
    case types.SET_STARTUPS_COUNT: {
      return handleSetStartupCount(state, <SetStartupCountPayload>payload);
    }
    case types.SET_STARTUP_BY_ID: {
      return handleSetStartupById(state, <SetStartupByIdPayload>payload);
    }
    case types.SET_STARTUP_REQUESTS: {
      return handleSetStartupRequests(state, <SetRequestsPayload>payload);
    }
    case types.SET_REQUEST_STATUS_CHANGE: {
      return handleSetRequestStatusChange(state, <ChangeRequestStatusPayload>payload);
    }
    case types.SET_STARTUP_TEAM_MEMBERS: {
      return handleSetStartupTeamMembers(state, <SetTeamMembersPayload>payload);
    }
    case types.SET_STARTUP_SERVICE_PROVIDER_BY_ID: {
      return handleSetServiceProvider(state, <SetStartupServiceProviderPayload>payload);
    }
    case types.SET_STARTUPS_IS_LOADING: {
      return handleSetLoading(state, <IsLoadingPayload>payload);
    }
    case types.SET_STAGES_OPTIONS: {
      return handleSetStageOptions(state, <DataPayload<StageType[]>>payload);
    }
    case types.SET_INVESTMENT_STAGE_OPTIONS: {
      return handleSetInvestmentStageOptions(state, <DataPayload<InvestmentStageType[]>>payload);
    }
    case types.SET_STARTUP_TEAM_MEMBERS_COUNT: {
      return handleSetStartupTeamMembersCount(state, <DataPayload<number>>payload);
    }
    case types.SET_STARTUP_ID: {
      return handleSetStartupId(state, <DataPayload<number>>payload);
    }
    case types.SET_STARTUP_FOR_REVIEW: {
      return handleSetStartupForReview(state, <SetStartupForReviewPayload>payload);
    }
    case types.SET_INCENTIVE_AGREEMENT_BY_ID: {
      return handleSetIncentiveAgreementById(state, <DataPayload<IncentiveAgreementType>>payload);
    }
    case types.SET_STARTUP_FILTERS: {
      return handleSetStartupFilters(state, <StartupFiltersType>payload);
    }
    case types.SET_STARTUP_SERVICE_PROVIDERS: {
      return handleSetStartupServiceProviders(state, <DataPayload<ServiceProviderType[]>>payload);
    }
    case types.SET_SUMS_FOR_SERVICE_PROVIDERS: {
      return handleSetSumsForSP(state, <DataPayload<GetAmountSumsResponse[]>>payload);
    }
    case types.RESET_STARTUPS: {
      return defaultState;
    }
    default: {
      return state;
    }
  }
});
