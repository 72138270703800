export * from './selectOptions';
export * from './environmentVariables';
export * from './drawersMap';
export { default as ROLES } from './roles';
export * from './requestTypes';
export * from './serviceStatusTypes';
export * from './startupStatusType';
export * from './validationRules';
export * from './serviceRequestHelpers';

export const offerPriceTypeValues = [
  {
    value: 0,
    title: 'All',
    key: 'all',
  },
  {
    value: 1,
    title: 'Fixed',
    key: 'fixed',
  },
  {
    value: 2,
    title: 'Range',
    key: 'range',
  },
];
