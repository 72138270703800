import { connect } from 'react-redux';
import { bindActionCreators, Dispatch, Action } from 'redux';

import { StateType } from 'types';
import {
  selectEventsCurrentSubTab,
  selectEventsCurrentTab,
  selectEventsResponseCount,
  selectEventFilters,
  selectEventFiltersActive,
} from 'redux/selectors/events';
import { getEventsResponseCount, setEventSubTab, setEventTab } from 'redux/actions/events';
import { selectUserType } from 'redux/selectors/auth';

const mapStateToProps = (state: StateType) => ({
  currentTab: selectEventsCurrentTab(state),
  currentSubTab: selectEventsCurrentSubTab(state),
  userType: selectUserType(state),
  responseCount: selectEventsResponseCount(state),
  filters: selectEventFilters(state),
  filtersActive: selectEventFiltersActive(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  actions: bindActionCreators({ setEventTab, setEventSubTab, getEventsResponseCount }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps);
