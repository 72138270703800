import React from 'react';

import { HandleProps } from 'types/componentTypes';

import './Handle.scss';

const Handle = (props: HandleProps) => (
  <div className="custom-slider-range">
    <div className="custom-slider-handle" style={{ left: `${props.value}%` }}>
      {`${props.value}%`}
    </div>
  </div>
);

export default Handle;
