import { connect } from 'react-redux';
import { Action, bindActionCreators, Dispatch } from 'redux';

import { selectPartnerRequestDetails } from 'redux/selectors/partners';
import { getPartnerRequestById } from 'redux/actions/partners';
import { selectUserType } from 'redux/selectors/auth';
import {
  changePartnerRequestStatus,
  getCommentsByRequestId,
  getRequestItemsByRequestId,
  sendNewRequestComment,
  setActiveTab,
  acknowledgePartnerRequestById,
} from 'redux/actions/request';
import {
  selectCommentsLoading, selectPartnerRequestId, selectRequestComments, selectRequestDetailsActiveTab,
} from 'redux/selectors/request';
import { StateType } from '../../../../types';

const mapStateToProps = (state: StateType) => ({
  request: selectPartnerRequestDetails(state),
  userType: selectUserType(state),
  id: selectPartnerRequestId(state),
  activeTab: selectRequestDetailsActiveTab(state),
  comments: selectRequestComments(state),
  commentsLoading: selectCommentsLoading(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  actions: bindActionCreators({
    getRequestItemsByRequestId,
    getPartnerRequestById,
    changePartnerRequestStatus,
    sendNewRequestComment,
    getCommentsByRequestId,
    acknowledgePartnerRequestById,
    setActiveTab,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps);
