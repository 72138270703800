import { Button } from 'antd';
import React from 'react';

import { DataContainerProps } from 'types/submitOverview';
import { identity } from 'utils';
import Overview from '../Overview';
import RenderIf from '../RenderIf';

import './DataContainer.scss';

const DataContainer = (props: DataContainerProps) => {
  const {
    title, noDataText, buttonText, data, headerContent, hideButton,
  } = props;

  return (
    <Overview title={title} headerContent={headerContent}>
      {identity.isFalsy(data)
        ? (
          <div className="submit-overview-data-container__body">
            <p className="submit-overview-data-container__no-data-text">
              {`You currently have no ${noDataText}. ${hideButton ? '' : `Click on the button below to create a new ${buttonText}.`}`}
            </p>
            <RenderIf condition={!hideButton}>
              <Button className="primary-button">{`Create new ${buttonText}`}</Button>
            </RenderIf>
          </div>
        )
        : data}
    </Overview>
  );
}

export default DataContainer;
