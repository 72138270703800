import React, { ReactNode } from 'react';

type IconWrapperProps = {
  children: ReactNode,
}

const IconWrapper = (props: IconWrapperProps) => (
  <div className="sidebar-icon">
    {props.children}
  </div>
)

export default IconWrapper;
