import { GetWalletQueryParams, GetWalletTransactionsQueryParams } from 'packages/wallet_repository';
import { SetWalletPayload, SetWalletTransactionsPayload } from 'types/reduxTypes/ActionTypes/WalletTypes';
import { DataPayload, IsLoadingPayload } from 'types/reduxTypes/ActionTypes';

export const types = {
  GET_WALLET: 'GET_WALLET',
  SET_WALLET:'SET_WALLET',
  GET_WALLET_TRANSACTIONS: 'GET_WALLET_TRANSACTIONS',
  SET_WALLET_TRANSACTIONS: 'SET_WALLET_TRANSACTIONS',
  SET_WALLET_IS_LOADING: 'SET_WALLET_IS_LOADING',
  RESET_WALLET: 'RESET_WALLET',
  GET_WALLET_COUNT: 'GET_WALLET_COUNT',
  SET_WALLET_COUNT: 'SET_WALLET_COUNT',
};

export const resetWallet = (payload: object = {}) => ({
  type: types.RESET_WALLET,
  payload,
});

export const getWallet = (payload: GetWalletQueryParams) => ({
  type: types.GET_WALLET,
  payload,
});

export const setWallet = (payload: SetWalletPayload) => ({
  type: types.SET_WALLET,
  payload,
});

export const getWalletTransactions = (payload: GetWalletTransactionsQueryParams) => ({
  type: types.GET_WALLET_TRANSACTIONS,
  payload,
});

export const setWalletTransactions = (payload: SetWalletTransactionsPayload) => ({
  type: types.SET_WALLET_TRANSACTIONS,
  payload,
});

export const setIsLoading = (payload: IsLoadingPayload) => ({
  type: types.SET_WALLET_IS_LOADING,
  payload,
});

export const getWalletCount = (payload: GetWalletTransactionsQueryParams) => ({
  type: types.GET_WALLET_COUNT,
  payload,
});

export const setWalletCount = (payload: DataPayload<number>) => ({
  type: types.SET_WALLET_COUNT,
  payload,
});
