import React, { useCallback, useEffect } from 'react'

import { getProgramTypeColumns } from 'components/utils/constants/columns';
import { ProgramTypeTableProps } from 'types/settingsOverview';
import { identity } from 'utils';

import { ProgramType } from 'packages/cohort_repository';
import SettingsTable from '../SettingsTable';

const ProgramTypeTable = (props: ProgramTypeTableProps) => {
  const { programTypes, onAddNew, actions } = props;

  useEffect(() => {
    if (identity.isEmptyArray(programTypes)) {
      actions.getProgramTypes();
    }
  }, []);

  const onDelete = useCallback((id: number) => {
    actions.deleteProgramTypeById({ id });
  }, []);

  const onUpdate = useCallback((data: ProgramType) => {
    actions.setProgramTypeDetails({ data });
    onAddNew!({ isEdit: true });
  }, []);

  return (
    <SettingsTable
      onAddNew={onAddNew}
      dataSource={programTypes}
      columns={getProgramTypeColumns(onDelete, onUpdate)}
    />
  )
}

export default ProgramTypeTable;
