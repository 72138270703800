import { Action, bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';

import {
  selectActiveSubTab,
  selectActiveTab,
  selectDeleteIds,
  selectIsLoading,
} from 'redux/selectors/settingsOverview';
import {
  setActiveTab,
  setActiveSubTab,
  deleteProgramTypes,
  deleteTitles,
  deleteEducation,
  deleteStartupStages,
  resetSettingsDetails,
  deleteMultipleTemplates,
} from 'redux/actions/settingsOverview';
import { setDrawerBody, setIsDrawerOpen } from 'redux/actions/workspace';
import { StateType } from 'types';
import { deleteServiceCategory, deleteServiceSubCategory } from 'redux/actions/services';

const mapStateToProps = (state: StateType) => ({
  activeTab: selectActiveTab(state),
  activeSubTab: selectActiveSubTab(state),
  deleteIds: selectDeleteIds(state),
  isLoading: selectIsLoading(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  actions: bindActionCreators({
    setActiveSubTab,
    setActiveTab,
    setDrawerBody,
    setIsDrawerOpen,
    deleteProgramTypes,
    deleteTitles,
    deleteEducation,
    deleteStartupStages,
    deleteServiceCategory,
    deleteServiceSubCategory,
    resetSettingsDetails,
    deleteMultipleTemplates,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps);
