import React from 'react';

import SVGWrap from './SVGWrap';

const ImageIcon = () => (
  <SVGWrap size={64}>
    <path d="M38 28C39.1867 28 40.3467 27.6481 41.3334 26.9888C42.3201 26.3295 43.0892 25.3925 43.5433 24.2961C43.9974 23.1997 44.1162 21.9933 43.8847 20.8295C43.6532 19.6656 43.0818 18.5965 42.2426 17.7574C41.4035 16.9182 40.3344 16.3468 39.1705 16.1153C38.0067 15.8838 36.8003 16.0026 35.7039 16.4567C34.6075 16.9108 33.6705 17.6799 33.0112 18.6666C32.3519 19.6533 32 20.8133 32 22C32 23.5913 32.6321 25.1174 33.7574 26.2426C34.8826 27.3679 36.4087 28 38 28ZM38 20C38.3956 20 38.7822 20.1173 39.1111 20.3371C39.44 20.5568 39.6964 20.8692 39.8478 21.2346C39.9991 21.6001 40.0387 22.0022 39.9616 22.3902C39.8844 22.7781 39.6939 23.1345 39.4142 23.4142C39.1345 23.6939 38.7781 23.8844 38.3902 23.9616C38.0022 24.0387 37.6001 23.9991 37.2346 23.8478C36.8692 23.6964 36.5568 23.44 36.3371 23.1111C36.1173 22.7822 36 22.3956 36 22C36 21.4696 36.2107 20.9609 36.5858 20.5858C36.9609 20.2107 37.4696 20 38 20Z" />
    <path d="M52 8H12C10.9391 8 9.92172 8.42143 9.17157 9.17157C8.42143 9.92172 8 10.9391 8 12V52C8 53.0609 8.42143 54.0783 9.17157 54.8284C9.92172 55.5786 10.9391 56 12 56H52C53.0609 56 54.0783 55.5786 54.8284 54.8284C55.5786 54.0783 56 53.0609 56 52V12C56 10.9391 55.5786 9.92172 54.8284 9.17157C54.0783 8.42143 53.0609 8 52 8ZM52 52H12V40L22 30L33.18 41.18C33.9295 41.925 34.9433 42.3432 36 42.3432C37.0567 42.3432 38.0705 41.925 38.82 41.18L42 38L52 48V52ZM52 42.34L44.82 35.16C44.0705 34.415 43.0567 33.9968 42 33.9968C40.9433 33.9968 39.9295 34.415 39.18 35.16L36 38.34L24.82 27.16C24.0705 26.415 23.0567 25.9968 22 25.9968C20.9433 25.9968 19.9295 26.415 19.18 27.16L12 34.34V12H52V42.34Z" />
  </SVGWrap>
);

export default ImageIcon;
