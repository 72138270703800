/* eslint-disable react/no-array-index-key */
import React from 'react'

import { DataColumnsProps } from '../../../types/componentTypes/DataColumns';
import DataColumn from './components/DataColumn';

import './DataColumns.scss';

const DataColumns = (props: DataColumnsProps) => (
  <div className="overview-columns">
    {props.columns.map((item, index) => (
      <DataColumn key={`overview-column-${index}`} label={item.label}>
        {item.value}
      </DataColumn>
    ))}
  </div>
)

export default DataColumns;
