import routes from 'routes';
import { NoticeModalConfig } from 'types/componentTypes';

export const reportPeriodStartedTemplate: NoticeModalConfig = {
  title: 'Report period started',
  closable: true,
  okText: 'Go to performance',
  path: routes.performanceUrl,
};

export const reportPeriodEndsTemplate: NoticeModalConfig = {
  title: 'Report period ends today!',
  closable: false,
  okText: 'Add new report',
  path: routes.createPerformanceReportUrl,
};

export const NOTICE_LEVEL = {
  info: 'info',
  blocked: 'blocked',
}

export const NOTICE_LEVEL_TO_TEMPLATE_MAP = {
  [NOTICE_LEVEL.info]: reportPeriodStartedTemplate,
  [NOTICE_LEVEL.blocked]: reportPeriodEndsTemplate,
}
