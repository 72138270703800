import { createSelector } from 'reselect';
import { StateType } from 'types';
import { PerformanceStateType } from 'types/reduxTypes/StateTypes';

const selectPerformanceState = (state: StateType) => state.performanceReducer;

export const selectPerformanceReports = createSelector(
  selectPerformanceState,
  (state: PerformanceStateType) => state.performanceReports,
);

export const selectPerformanceReportDetails = createSelector(
  selectPerformanceState,
  (state: PerformanceStateType) => state.details.data,
);

export const selectPerformanceReportId = createSelector(
  selectPerformanceState,
  (state: PerformanceStateType) => state.performanceReportId,
);

export const selectIsLoading = createSelector(
  selectPerformanceState,
  (state: PerformanceStateType) => state.isLoading,
);

export const selectLimit = createSelector(
  selectPerformanceState,
  (state: PerformanceStateType) => state.limit,
);

export const selectOffset = createSelector(
  selectPerformanceState,
  (state: PerformanceStateType) => state.offset,
);

export const selectActiveTab = createSelector(
  selectPerformanceState,
  (state: PerformanceStateType) => state.activeTab,
);

export const selectCounts = createSelector(
  selectPerformanceState,
  (state: PerformanceStateType) => state.counts,
);
