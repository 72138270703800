import React, { useMemo } from 'react';

import { identity, isEitherAdmin, isStartup } from 'utils';
import { DataPayload } from 'types/reduxTypes/ActionTypes';
import {
  ServicesAndServiceProvidersSubTabsProps,
  ServiceSubCategoryType,
} from 'types/services';
import { categoriesIconsVol2 } from '../../utils';
import { FiltersWithIcons } from '../../../components';
import ServiceSubCategoriesTabs from '../ServiceSubCategoriesTabs';
import FilterTag from '../FilterTag';

const SubTabs = (props: ServicesAndServiceProvidersSubTabsProps) => {
  const {
    activeTab,
    serviceProviderName,
    setOpenFilters,
    filters,
    setServiceProviderName,
    subCategories,
    openFilters,
    setFiltersChanged,
    categories,
    servicesFilterTab,
    userType,
    setServiceProviderFilter,
    setSpCurrentPage,
    setServiceProvidersFiltersChanged,
    setActiveSpFilters,
    activeSpFilters,
    filtersActive,
  } = props;

  const {
    setActiveTab,
    getServices,
    setCurrentServicesTab,
    setFilters,
  } = props.actions;

  const handleRemoveFilter = () => {
    setFilters({ ...filters, serviceProviderId: undefined });
    setActiveTab({ activeTab: 'services' });
    getServices({ filters: { id: undefined } });
    setServiceProviderName('');
  }

  const subcategoriesOptions = useMemo(() => {
    const options: { name: string, id: number, active: boolean }[] = [{ id: 0, name: 'All', active: true }];
    subCategories?.map(({ id, name, active }: Required<ServiceSubCategoryType>) => options.push({ id, name, active }));
    return options;
  }, [subCategories]);

  const setCategories = (payload: DataPayload<number | 'all'>) => {
    let categoryId: number[] | undefined;
    if (payload.data !== 'all') {
      const hasCategoryId = (filters.categoryId as number[] || []).includes(payload.data as number);
      if (hasCategoryId) {
        categoryId = (filters.categoryId as number[] || []).filter((item) => item !== payload.data as number);
      } else {
        /// as we are sending array in api query for that reason passed it as an array
        categoryId = [payload.data];
      }
    }
    categoryId = identity.isFullArray(categoryId) ? categoryId : undefined;
    setFilters({ ...filters, categoryId });
    setFiltersChanged(true);
  }

  const setSpCategories = (payload: DataPayload<number | 'all'>) => {
    let categoryId: number[] | undefined;
    if (payload.data !== 'all') {
      const hasCategoryId = (activeSpFilters as number[] || []).includes(payload.data as number);
      if (hasCategoryId) {
        categoryId = (activeSpFilters as number[] || []).filter((item) => item !== payload.data as number);
      } else {
        categoryId = activeSpFilters as number[] || [];
        categoryId!.push(payload.data as number);
      }
    }
    const cId = identity.isFullArray(categoryId) ? categoryId![0] : undefined;
    setServiceProviderFilter(cId);
    setActiveSpFilters(categoryId);
    setServiceProvidersFiltersChanged(true);
    setSpCurrentPage(1);
  }

  if (!isStartup(userType) && !isEitherAdmin(userType)) {
    return null;
  }

  switch (activeTab) {
    case 'services': {
      if (identity.isTruthyString(serviceProviderName) && isStartup(userType)) {
        return (
          <>
            <FilterTag
              prefix="Provider"
              item={serviceProviderName}
              handleRemoveFilter={handleRemoveFilter}
            />
            <ServiceSubCategoriesTabs
              tabs={subcategoriesOptions}
              onFilterButtonClick={() => setOpenFilters(true)}
              filters={filters}
              open={openFilters}
              setFilters={setCategories}
              onClose={() => setOpenFilters(false)}
            />
          </>
        );
      }
      return (
        <FiltersWithIcons
          filtersActive={filtersActive}
          categories={categories}
          icons={categoriesIconsVol2}
          onFilterButtonClick={() => setOpenFilters(true)}
          displayFilters
          setCurrentServicesTab={setCurrentServicesTab}
          currentTab={servicesFilterTab}
          filters={filters}
          open={openFilters}
          setFilters={setFilters}
          setFilter={setCategories}
          onClose={() => setOpenFilters(false)}
          activeFilters={filters.categoryId}
          setFiltersChanged={setFiltersChanged}
          filterType="services"
        />
      );
    }
    case 'service-providers': {
      if (isStartup(userType)) {
        return (
          <FiltersWithIcons
            categories={categories}
            icons={categoriesIconsVol2}
            onFilterButtonClick={() => setOpenFilters(true)}
            displayFilters={false}
            setCurrentServicesTab={setCurrentServicesTab}
            currentTab={servicesFilterTab}
            filters={filters}
            open={openFilters}
            setFilter={setSpCategories}
            onClose={() => setOpenFilters(false)}
            activeFilters={activeSpFilters}
            setFiltersChanged={setServiceProvidersFiltersChanged}
            filterType="services"
          />
        )
      }
      return null;
    }
    default: {
      return null;
    }
  }
}

export default SubTabs;
