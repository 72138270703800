import { createSelector } from 'reselect';
import { StateType } from 'types';
import { NotificationsStateType } from 'types/reduxTypes/StateTypes';

const selectNotificationsState = (state: StateType) => state.notificationsReducer;

export const selectIsOpen = createSelector(
  selectNotificationsState,
  (state: NotificationsStateType) => state.isOpen,
);

export const selectIsLoading = createSelector(
  selectNotificationsState,
  (state: NotificationsStateType) => state.isLoading,
);

export const selectNotifications = createSelector(
  selectNotificationsState,
  (state: NotificationsStateType) => state.notifications,
);

export const selectLimit = createSelector(
  selectNotificationsState,
  (state: NotificationsStateType) => state.limit,
);

export const selectOffset = createSelector(
  selectNotificationsState,
  (state: NotificationsStateType) => state.offset,
);

export const selectNotificationsCount = createSelector(
  selectNotificationsState,
  (state: NotificationsStateType) => state.notifications.length,
);

export const selectOrderBy = createSelector(
  selectNotificationsState,
  (state: NotificationsStateType) => state.orderBy,
);

export const selectNewCount = createSelector(
  selectNotificationsState,
  (state: NotificationsStateType) => state.newCount,
);

export const selectAllFetched = createSelector(
  selectNotificationsState,
  (state: NotificationsStateType) => state.allFetched,
);
