import produce from 'immer';

import {
  ActivitiesActionType,
  FilterKey,
  SetCheckedPeoplePayload,
  SetFiltersPayload,
  SetIsLoadingPayload,
} from 'types/reduxTypes/ActionTypes/ActivitiesTypes';
import { ActivitiesStateType } from 'types/reduxTypes/StateTypes';
import activitiesDefaultState from 'utils/defaultStates/activities';
import { types } from '../../actions/activities';

const defaultState = activitiesDefaultState;

const handleSetIsLoading = (state: ActivitiesStateType, payload: SetIsLoadingPayload) => {
  state.isLoading = payload.isLoading;
  return state;
}

const handleSetFilters = (state: ActivitiesStateType, payload: SetFiltersPayload) => {
  Object.keys(payload).forEach((key) => {
    state.filters[key as FilterKey] = payload[key as FilterKey];
  });

  return state;
}

const handleResetPagination = (state: ActivitiesStateType) => {
  state.page = defaultState.page;
  state.size = defaultState.size;
  state.activities = defaultState.activities;
  return state;
}

const handleSetCheckedPeople = (state: ActivitiesStateType, payload: SetCheckedPeoplePayload) => {
  state.checkedPeople = payload.checkedPeople;
  return state;
}

// eslint-disable-next-line default-param-last
export default produce((state = defaultState, action: ActivitiesActionType) => {
  switch (action.type) {
    case types.SET_ACTIVITIES_IS_LOADING: {
      return handleSetIsLoading(state, <SetIsLoadingPayload> action.payload);
    }
    case types.SET_ACTIVITIES_FILTERS: {
      return handleSetFilters(state, <SetFiltersPayload> action.payload);
    }
    case types.RESET_ACTIVITIES_PAGINATION: {
      return handleResetPagination(state);
    }
    case types.SET_CHECKED_PEOPLE: {
      return handleSetCheckedPeople(state, <SetCheckedPeoplePayload> action.payload);
    }
    case types.RESET_ACTIVITIES: {
      return defaultState;
    }
    default: {
      return state;
    }
  }
});
