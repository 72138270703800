import React, { useEffect, useMemo, useState } from 'react';
import {
  Button,
  Col,
  Row,
  Tabs,
} from 'antd';

import REQUEST_STATUS from 'utils/constants/requestStatusTypes';
import { PARTNER_TYPE, PartnerRequestStatusType } from 'packages/partner_repository';
import DataColumn from 'components/components/DataColumns/components/DataColumn';
import { PartnerRequestDetailsDrawerProps, RequestDetailsTabs } from 'types/requests';
import {
  identity,
  isEitherAdmin,
  isPartner,
  isStartup,
} from 'utils';
import { NOT_SPECIFIED } from 'components/CohortDetails/utils/constants';
import { ButtonWithPrompt, RenderIf } from 'components/components';
import { isPendingHUB71 } from 'components/RequestsOverview/utils/helpers';
import { AgreementField } from '../RequestDetailsDrawer/components/OverviewTab/components';
import { TABS } from '../RequestDetailsDrawer';
import { DiscussionBoard } from '../RequestDetailsDrawer/components';
import { disableCommentsStatuses, showActionStatuses } from './utils/constants';

import '../RequestDetailsDrawer/RequestDetailsDrawer.scss';

const PartnerRequestDetailsDrawer = (props: PartnerRequestDetailsDrawerProps) => {
  const {
    actions,
    request,
    userType,
    id,
  } = props;

  const {
    partner, message, startup, pitchDeck, status, acknowledged,
  } = request! || {};
  const [displayApproveBtn, setDisplayApproveBtn] = useState(true);
  const [displayAcknowledgeBtn, setDisplayAcknowledgeBtn] = useState(true);

  const hiddenAcknowledgeBtn = !acknowledged && displayAcknowledgeBtn;

  useEffect(() => {
    if (identity.isEmptyArray(request)) {
      actions.getPartnerRequestById({ id });
    }
    actions.getCommentsByRequestId({ id: id!, request: 'partner-request' });
    actions.setActiveTab({ tab: TABS.overview });
  }, [id]);

  const onDeny = (reason: string) => {
    actions.changePartnerRequestStatus({
      id, reason, status: REQUEST_STATUS.rejected,
    });
    setDisplayApproveBtn(true);
    setDisplayAcknowledgeBtn(true);
  }

  const onAcknowledged = () => {
    const formData = {
      ...request,
      acknowledged: 1,
    }
    actions.acknowledgePartnerRequestById({ id, partnerRequest: formData })
  }

  const onApprove = () => {
    let newStatus: PartnerRequestStatusType;
    if (isPendingHUB71(status)) {
      newStatus = REQUEST_STATUS.inProgress;
    }
    actions.changePartnerRequestStatus({ id, status: newStatus! })
  }

  const canHUB71Approve = useMemo(() => isEitherAdmin(userType)
    && isPendingHUB71(status), [identity.isTruthyString(status)]);

  const onTabChange = (tab: string) => {
    actions.setActiveTab({ tab: tab as RequestDetailsTabs });
  }

  const onComment = (text: string) => {
    actions.sendNewRequestComment({ text });
  }

  return (
    <div className="request-details-drawer">
      <Tabs onChange={onTabChange} activeKey={props.activeTab} color="green">
        <Tabs.TabPane
          tab={TABS.overview}
          key={TABS.overview}
        >
          <div className="overview-tab-content" key={`overview-tab-content-${34}`}>
            <div className="overview-tab-content--container">
              <DataColumn label="Request Details">
                <RenderIf condition={identity.isTruthyString(startup?.name)}>
                  <Row>
                    <Col span={3}>
                      <h4>From</h4>
                    </Col>
                    <Col span={21} className="overview-tab__request-sender-recipient-text">
                      <p>{startup?.name}</p>
                    </Col>
                  </Row>
                </RenderIf>
                <RenderIf condition={identity.isTruthyString(partner?.name)}>
                  <Row>
                    <Col span={3}>
                      <h4>To</h4>
                    </Col>
                    <Col span={21} className="overview-tab__request-sender-recipient-text">
                      <p>{partner?.name}</p>
                    </Col>
                  </Row>
                </RenderIf>
              </DataColumn>
              <RenderIf condition={identity.isTruthyString(partner?.name)}>
                <DataColumn label={`Why do you want to connect with ${partner?.name!}?`}>
                  {identity.isTruthyString(message) ? message : NOT_SPECIFIED}
                </DataColumn>
              </RenderIf>
              <RenderIf condition={identity.isTruthyString(pitchDeck) && identity.isTruthyNumber(partner?.type!)}>
                <DataColumn label={`${partner?.type === PARTNER_TYPE?.vc ? 'Pitch' : 'Corporate'} Deck`}>
                  <AgreementField fileUrl={pitchDeck} fileName={`${partner?.type === PARTNER_TYPE.vc ? 'Pitch' : 'Corporate'}Deck.pdf`} altText={`No ${partner?.type === PARTNER_TYPE.vc ? 'Pitch' : 'Corporate'} Deck associated with this request`} />
                </DataColumn>
              </RenderIf>
              <RenderIf condition={!identity.isTruthyString(pitchDeck) && identity.isTruthyNumber(partner?.type!)}>
                <DataColumn label={`${partner?.type === PARTNER_TYPE.vc ? 'Pitch' : 'Corporate'} Deck`}>
                  { NOT_SPECIFIED }
                </DataColumn>
              </RenderIf>
            </div>
            <div className="overview-tab__actions">
              <RenderIf condition={canHUB71Approve}>
                <>
                  <ButtonWithPrompt
                    promptActiveClassName="danger-button"
                    className="ghost-button"
                    promptLabel="Reason for denying"
                    promptPlaceholder="Write a reason..."
                    onPromptDisplay={() => setDisplayApproveBtn(false)}
                    onFinish={onDeny}
                    onCancel={() => setDisplayApproveBtn(true)}
                    promptActiveBtnText="Deny request"
                  >
                    {isStartup(userType) ? 'Cancel' : 'Deny'}
                  </ButtonWithPrompt>
                  <RenderIf condition={displayApproveBtn}>
                    <Button className="primary-button" onClick={onApprove}>Approve</Button>
                  </RenderIf>
                </>
              </RenderIf>
              <RenderIf condition={isPartner(userType) && showActionStatuses.includes(status)}>
                <>
                  <ButtonWithPrompt
                    promptActiveClassName="danger-button"
                    className="ghost-button"
                    promptLabel="Reason for not qualified"
                    promptPlaceholder="Write a reason..."
                    onPromptDisplay={() => setDisplayAcknowledgeBtn(false)}
                    onFinish={onDeny}
                    onCancel={() => setDisplayAcknowledgeBtn(true)}
                    promptActiveBtnText="Not Qualified"
                  >
                    not qualified
                  </ButtonWithPrompt>
                  <RenderIf condition={hiddenAcknowledgeBtn}>
                    <Button onClick={onAcknowledged} className="primary-button">Acknowledge</Button>
                  </RenderIf>
                </>
              </RenderIf>
            </div>
          </div>
        </Tabs.TabPane>
        <Tabs.TabPane
          tab={TABS.discussionBoard}
          key={TABS.discussionBoard}
        >
          <DiscussionBoard
            userType={userType}
            acknowledge={acknowledged}
            isLoading={props.commentsLoading}
            comments={props.comments}
            onComment={onComment}
            disableComment={disableCommentsStatuses.includes(request?.status)}
          />
        </Tabs.TabPane>
      </Tabs>
    </div>
  );
}

export default PartnerRequestDetailsDrawer;
