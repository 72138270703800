import { connect } from 'react-redux';
import { Action, bindActionCreators, Dispatch } from 'redux';

import { selectProgramTypes } from 'redux/selectors/settingsOverview';
import { getProgramTypes, setProgramTypeDetails, deleteProgramTypeById } from 'redux/actions/settingsOverview';

import { StateType } from 'types';

const mapStateToProps = (state: StateType) => ({
  programTypes: selectProgramTypes(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  actions: bindActionCreators({
    getProgramTypes,
    setProgramTypeDetails,
    deleteProgramTypeById,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps);
