import { Avatar } from 'antd';
import moment from 'moment';
import React from 'react'
import { BsFillPersonFill } from 'react-icons/bs';

import { RenderIf } from 'components/components';
import { CommentProps } from 'types/requests';
import { identity } from 'utils';

import './Comment.scss';

const Comment = (props: CommentProps) => {
  const {
    name, imageUrl, companyName, date, text,
  } = props;

  return (
    <div className="request-comment">
      <Avatar className="request-comment__avatar" size="large" src={imageUrl} icon={<BsFillPersonFill />} />
      <div className="request-comment__container">
        <div className="request-comment__header">
          <div className="request-comment__user-info">
            <p className="request-comment__name">{name}</p>
            <RenderIf condition={identity.isTruthyString(companyName)}>
              <p className="request-comment__startup-name">{companyName}</p>
            </RenderIf>
          </div>
          <p className="request-comment__date">{moment(date).fromNow(true)}</p>
        </div>
        <div className="request-comment__text">{text}</div>
      </div>
    </div>
  )
}

export default Comment;
