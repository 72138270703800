import React from 'react';
import moment from 'moment';

import { RequestInfoProps } from 'types/requestDetailsModal';
import { composeRequestMoreDetailsUrl } from 'routes/routeComposers';
import { TitleWithActions, ProfileOverview } from 'components/components';
import { isSuperAdmin, getServicePrice } from 'utils';
import { formatSlashMDY } from '../../../utils/constants/formatters';

import './RequestInfo.scss';

const RequestInfo = (props: RequestInfoProps) => {
  const {
    service, requestNumber, date, status, id, requestType,
  } = props.requestInfo;
  const { imageLink } = service.serviceProvider;
  const {
    name, about, offerPrice, priceFrom, priceTo, priceType,
  } = service;

  return (
    <div className="request-info">
      <TitleWithActions
        title="Request overview"
        buttonTitle={props.isModal ? '' : 'More details'}
        link={props.isModal ? '' : composeRequestMoreDetailsUrl(id)}
        showButton={!isSuperAdmin(props.userType)}
      />
      <ProfileOverview
        heading={`#R${requestNumber}`}
        imageUrl={imageLink}
        rightContent={[
          { label: 'offer', value: name },
          { label: 'price', value: getServicePrice(offerPrice, priceFrom, priceTo, priceType) },
          { label: 'requested on', value: moment(date).format(formatSlashMDY) },
          { label: 'status', value: status },
          { label: 'description', value: about },
        ]}
        subheading={`Request | ${requestType}`}
      />
    </div>
  );
}

export default RequestInfo;
