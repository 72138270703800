import React, { useEffect, useState } from 'react';
import { Button } from 'antd';
import { RcFile } from 'antd/lib/upload';
import moment from 'moment';
import { formatDateDashYMDTimeHMS } from 'components/utils/constants/formatters';
import { UploadInvoiceFieldsProps } from 'types/requests';
import { Input, RenderIf } from 'components/components';
import { postServiceRequestDocuments } from 'packages/service_request_repository/api';
import './UploadInvoiceFields.scss';

const UploadInvoiceFields = (props: UploadInvoiceFieldsProps) => {
  const { serviceRequest, actions: { setShowInvoiceFields, updateServiceRequest } } = props;
  const [invoiceFile, setInvoiceFile] = useState<string | Blob>('');
  const [insurancePolicy, setInsurancePolicy] = useState<string | Blob>('');
  const [isInsurancePolicy, setIsInsurancePolicy] = useState<boolean>(false);
  const [invoiceNumber, setInvoiceNumber] = useState<string>('');
  const [invoiceDate, setInvoiceDate] = useState<any>();
  const [newInvoicePrice, setNewInvoicePrice] = useState<number>(serviceRequest.totalPrice);
  useEffect(() => {
    if (serviceRequest.itemList[0] === 'Health Insurance') {
      setIsInsurancePolicy(true)
    }
  }, [serviceRequest.itemList[0]])

  const beforeInvoiceUpload = (file: RcFile) => {
    setInvoiceFile(file as Blob);
    return false;
  }

  const beforeInsurancePolicyUpload = async (file: RcFile) => {
    setInsurancePolicy(file as Blob);
    setIsInsurancePolicy(false)
    return false;
  }

  const handleSubmit = async () => {
    if (serviceRequest.itemList[0] === 'Health Insurance') {
      const res = await postServiceRequestDocuments({
        _document: insurancePolicy,
        _name: 'Insurance Policy',
        _service_request_id: serviceRequest.id,
      })
      if (res.httpStatus === 200 && res.data?.status === 'ok') {
        updateServiceRequest({
          id: serviceRequest.id,
          invoice: invoiceFile,
          invoiceNumber,
          totalPrice: newInvoicePrice,
          invoiceDate: moment(invoiceDate).format(formatDateDashYMDTimeHMS),
        });
      }
    } else {
      updateServiceRequest({
        id: serviceRequest.id,
        invoice: invoiceFile,
        invoiceNumber,
        totalPrice: newInvoicePrice,
        invoiceDate: moment(invoiceDate).format(formatDateDashYMDTimeHMS),
      });
    }
  }

  return (
    <div className="upload-invoice-fields">
      <Input label="Invoice number" required>
        <Input.InputField
          placeholder="Enter Invoice Number"
          name="invoiceNumber"
          value={invoiceNumber}
          onChange={({ target: { value } }) => setInvoiceNumber(value)}
        />
      </Input>
      <Input label="Invoice Date" required>
        <Input.DatePicker
          placeholder="Enter Invoice Date"
          name="invoiceDate"
          value={invoiceDate}
          onChange={(value) => setInvoiceDate(value)}
        />
      </Input>
      <Input label="Total price" required>
        <Input.InputField
          placeholder="Total Price Excluding VAT"
          name="totalPrice"
          value={newInvoicePrice}
          onChange={(e: any) => setNewInvoicePrice(e.target.value)}
        />
      </Input>
      {newInvoicePrice > serviceRequest.totalPrice && (
        <div style={{ textAlign: 'center' }}>
          <p style={{ color: 'red' }}>{`Price cannot be more than ${serviceRequest.totalPrice}`}</p>
        </div>
      )}
      <Input label="Invoice pdf" required>
        <Input.DraggerUpload
          beforeUpload={beforeInvoiceUpload}
          previewType="image"
          name="invoice"
          accept=".pdf"
          isRoundableButton
        />
      </Input>
      <RenderIf condition={serviceRequest.itemList[0] === 'Health Insurance'}>
        <Input label="Insurance Policy" required>
          <Input.DraggerUpload
            beforeUpload={beforeInsurancePolicyUpload}
            previewType="image"
            name="insurancepolicy"
            accept=".pdf"
            isRoundableButton
          />
        </Input>
      </RenderIf>
      <div className="upload-invoice-fields__buttons">
        <Button className="secondary-button md" onClick={() => setShowInvoiceFields({ data: false })}>Cancel</Button>
        <Button
          className="primary-button md"
          onClick={handleSubmit}
          disabled={
            invoiceNumber === ''
            || invoiceFile === ''
            || !newInvoicePrice
            || newInvoicePrice > serviceRequest.totalPrice
            || !invoiceDate
            || isInsurancePolicy
          }
        >
          Deliver
        </Button>
      </div>
    </div>
  );
}

export default UploadInvoiceFields;
