import { connect } from 'react-redux';
import { bindActionCreators, Dispatch, Action } from 'redux';

import { StateType } from 'types';
import { selectUserType } from 'redux/selectors/auth';
import { setDrawerBody, setIsDrawerOpen } from 'redux/actions/workspace';
import { getServices, setActiveTab, setFilters } from 'redux/actions/services'
import { selectRequests } from 'redux/selectors/request';
import { selectServiceProviders, selectServiceProvidersCount } from 'redux/selectors/serviceProviders';
import { selectServiceCategories, selectServicesFilters } from 'redux/selectors/services';
import { getServiceProviders, getServiceProvidersCount } from 'redux/actions/serviceProviders';
import { selectIsRowTableLayout } from 'redux/selectors/workspace';

const mapStateToProps = (state: StateType) => ({
  userType: selectUserType(state),
  requests: selectRequests(state),
  dataSource: selectServiceProviders(state),
  serviceProvidersCount: selectServiceProvidersCount(state),
  serviceCategories: selectServiceCategories(state),
  isRowTableLayout: selectIsRowTableLayout(state),
  filters: selectServicesFilters(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  actions: bindActionCreators({
    setDrawerBody,
    setIsDrawerOpen,
    setActiveTab,
    getServices,
    getServiceProviders,
    getServiceProvidersCount,
    setFilters,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps);
