import { SortBy } from '../../../types/activities';

export const OLD_TO_NEW : SortBy = 'Old to new';
export const NEW_TO_OLD : SortBy = 'New to old';

export const sortByOptions = {
  OLD_TO_NEW,
  NEW_TO_OLD,
};

export const activityTypes = {
  EDITED: 'Edited',
  ADDED: 'Added',
};

export const descriptionTextValues = {
  [activityTypes.EDITED]: 'edited by modifying',
  [activityTypes.ADDED]: 'added field',
}

export const typeFilterOptions = [
  {
    label: 'Field edited',
    value: activityTypes.EDITED,
  },
  {
    label: 'Field added',
    value: activityTypes.ADDED,
  },
];
