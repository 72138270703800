import { DEFAULT_API_HOST } from 'packages/http_client'

const config = {}

// Configuration file example.
//
// Development: Add to the public directory as config.js.
//
// Production: Add to the build directory after the build is
// complete as config.js.

// Your app's URL
config.DS_APP_URL = 'http://localhost:3000/cohorts/addNewCohort'

// If config.DS_REDIRECT_AUTHENTICATION is true, then add a
// redirect URI to the integration key of DS_APP_URL since
// the OAuth flow will restart the application
//
// If config.DS_REDIRECT_AUTHENTICATION is false, then add a
// redirect URI to the integration key of
// DS_APP_URL/oauthResponse.html

config.DS_CLIENT_ID = '6b5ecf14-5b69-4d13-aff2-9a543a76651e'
config.IMPLICIT_SCOPES = 'signature'

// DocuSign Identity server
config.DS_IDP = 'https://account-d.docusign.com'

// or the demo and prod platforms that your
// users' accounts need
config.DS_API_CORS_PROXIES = {
  'https://na2.docusign.net': 'https://demo.docusign.net',
  'https://na3.docusign.net': 'https://demo.docusign.net',
  'https://demo.docusign.net': 'https://demo.docusign.net',
}

// redirect authentication?
// true: the app redirects to the IdP
// false: the app opens a new tab for authentication, then closes it
config.DS_REDIRECT_AUTHENTICATION = false
config.DS_DEBUG = true

config.MIN_PASSWORD_LENGTH = 8

config.DEFAULT_APPLICATION_HOST_URL = DEFAULT_API_HOST
/// Table Default Limit
config.TABLE_DEFAULT_LIMIT = 10

config.GRID_TABLE_DEFAULT_LIMIT = 12

export default config
