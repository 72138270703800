import { createSelector } from 'reselect';

import { StateType } from 'types';
import { ServiceProviderStateType } from 'types/reduxTypes/StateTypes';
import { ServiceProviderType } from 'types/serviceProviders';

const selectServiceProvidersState = (state: StateType) => state.serviceProvidersReducer;

export const selectServiceProviders = createSelector(
  selectServiceProvidersState,
  (state: ServiceProviderStateType) => state.serviceProviders,
);

export const selectServiceProviderDetails = createSelector(
  selectServiceProvidersState,
  (state: ServiceProviderStateType) => state.details.data,
);

export const selectServiceProviderInfo = createSelector(
  selectServiceProviderDetails,
  (state: ServiceProviderType) => {
    const {
      name, imageLink, category, websiteLink, id,
    } = state;
    return {
      name, imageLink, websiteLink, category, id,
    };
  },
);

export const selectContactPerson = createSelector(
  selectServiceProviderDetails,
  (state: ServiceProviderType) => {
    const {
      firstName, lastName, phoneNumber: phone, email, role,
    } = state;
    return {
      firstName, lastName, phone, email, role,
    };
  },
);

export const selectServiceProviderRequests = createSelector(
  selectServiceProvidersState,
  (state: ServiceProviderStateType) => state.details.requests.slice(0, 3),
);

export const selectServiceProviderStartups = createSelector(
  selectServiceProvidersState,
  (state: ServiceProviderStateType) => state.details.startups,
);

export const selectServicesByServiceProviderId = createSelector(
  selectServiceProvidersState,
  (state: ServiceProviderStateType) => state.details.services,
);

export const selectServiceProviderAboutInfo = createSelector(
  selectServiceProviderDetails,
  (state: ServiceProviderType) => {
    const { name, about, companyDeck } = state;
    return { name, about, companyDeck };
  },
);

export const selectServiceProviderCurrentStartup = createSelector(
  selectServiceProvidersState,
  (state: ServiceProviderStateType) => state.details.startup.details,
);

export const selectServiceProviderCurrentStartupRequests = createSelector(
  selectServiceProvidersState,
  (state: ServiceProviderStateType) => state.details.startup.requests,
);

export const selectServiceProviderLoading = createSelector(
  selectServiceProvidersState,
  (state: ServiceProviderStateType) => state.isLoading,
);

export const selectServiceDetails = createSelector(
  selectServiceProvidersState,
  (state: ServiceProviderStateType) => state.details.service,
);

export const selectServiceProvidersCount = createSelector(
  selectServiceProvidersState,
  (state: ServiceProviderStateType) => state.serviceProvidersCount,
);
