import { convertGetStartupResponse } from 'packages/startup_repository';
import { identity } from 'utils';
import {
  PerformanceBody, PerformanceType, PerformanceTypePayload, ResponsePerformanceType,
} from '../types'
import { NUMBER_TO_REPORT_STATUS, REPORT_STATUS_TO_NUMBER } from './constants';

export const convertPerformanceBody = (payload: PerformanceTypePayload)
: PerformanceBody => ({
  _startup_id: payload.startupId,
  _number_of_founder: payload.numberOfFounder,
  _number_of_female_founders: payload.numberOfFemaleFounders,
  _number_of_full_time_employees: payload.numberOfFullTimeEmployees,
  _number_of_female_full_time_employees: payload.numberOfFemaleFullTimeEmployees,
  _num_of_abu_dhabi_visa_holders: payload.numOfAbuDhabiVisaHolders,
  _percentage_of_female_employees: payload.percentageOfFemaleEmployees,
  _current_hiring_status: Number(payload.currentHiringStatus) || undefined,
  _investment_stage_id: payload.investmentStageId,
  _bridge_round: Number(payload.bridgeRound) || undefined,
  _last_quarter_revenue: payload.lastQuarterRevenue,
  _current_year_revenue: payload.currentYearRevenue,
  _total_funding_raised: payload.totalFundingRaised,
  _currently_raising_amount: payload.currentlyRaisingAmount,
  _investors: payload.investors?.toString(),
  _last_valuation: payload.lastValuation,
  _key_milestones: payload.keyMilestones,
  _interested_in_international: Number(payload.interestedInInternational) || undefined,
  _international_markets: payload.internationalMarkets,
  _top_challenges: payload.topChallenges,
  _lead_investor_in_current_round: payload.leadInvestorInCurrentRound,
  _lead_investor_in_commitment: payload.leadInvestorInCommitment,
  _pitch_deck: payload.pitchDeck,
  _share_pitch_deck_with_partners: Number(payload.sharePitchDeckWithPartners) || undefined,
  _comments: payload.comments,
  _quarters: payload.quarters,
  _year: payload.year,
  _created_by_id: payload.createdById,
  _status: REPORT_STATUS_TO_NUMBER[payload.status!],
  _rejection_reason: payload.rejectionReason,
});

export const convertUpdatePerformanceBody = (payload: PerformanceTypePayload)
: PerformanceBody => ({
  _status: REPORT_STATUS_TO_NUMBER[payload.status!],
  _rejection_reason: payload.rejectionReason,
});

export const convertPerformanceResponse = (payload?: ResponsePerformanceType): PerformanceType => ({
  ...payload,
  id: payload?.id!,
  startupId: payload?.startupID!,
  startup: convertGetStartupResponse(payload?.startup)!,
  numberOfFounder: payload?.numberOfFounder!,
  numberOfFemaleFounders: payload?.numberOfFemaleFounders!,
  numberOfFullTimeEmployees: payload?.numberOfFullTimeEmployees!,
  numberOfFemaleFullTimeEmployees: payload?.numberOfFemaleFullTimeEmployees!,
  numOfAbuDhabiVisaHolders: payload?.numOfAbuDhabiVisaHolders!,
  percentageOfFemaleEmployees: payload?.percentageOfFemaleEmployees!,
  currentHiringStatus: String(Number(payload?.currentHiringStatus)!),
  investmentStageId: payload?.investmentStageID!,
  investmentStage: payload?.investmentStage!,
  bridgeRound: String(Number(payload?.bridgeRound)!),
  lastQuarterRevenue: payload?.lastQuarterRevenue!,
  currentYearRevenue: payload?.currentYearRevenue!,
  totalFundingRaised: payload?.totalFundingRaised!,
  currentlyRaisingAmount: payload?.currentlyRaisingAmount!,
  investors: identity.isTruthyString(payload?.investors!) ? (payload?.investors as string).split(',') : payload?.investors!,
  lastValuation: payload?.lastValuation!,
  keyMilestones: payload?.keyMilestones!,
  interestedInInternational: String(Number(payload?.interestedInInternational)!),
  internationalMarkets: payload?.internationalMarkets!,
  topChallenges: payload?.topChallenges!,
  leadInvestorInCurrentRound: payload?.leadInvestorInCurrentRound!,
  leadInvestorInCommitment: payload?.leadInvestorInCommitment!,
  pitchDeck: payload?.pitchDeck!,
  sharePitchDeckWithPartners: String(Number(payload?.sharePitchDeckWithPartners)!),
  comments: payload?.comments!,
  quarters: payload?.quarters!,
  year: payload?.year!,
  createdById: payload?.createdByID!,
  createdBy: payload?.createdBy!,
  createdAt: payload?.createdAt!,
  status: NUMBER_TO_REPORT_STATUS[payload?.status!],
});

export const convertGetPerformanceReportsList = (result?: ResponsePerformanceType[]) => {
  if (identity.isNotArray(result)) {
    return [];
  }

  return result?.map((item) => convertPerformanceResponse(item));
}
