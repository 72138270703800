import React, { useEffect } from 'react'

import { RenderIf } from 'components/components';
import Header from './components/Header';
import NotificationsList from './components/NotificationsList';
import { ActivitySidebarProps } from '../../types/activities';
import identity from '../../utils/identity';
import ShowMoreButton from './components/ShowMoreButton';

import './ActivitySidebar.scss';

const ActivitySidebar = (props: ActivitySidebarProps) => {
  const {
    notificationsCount, isLoading, actions, userId, allFetched,
  } = props;

  useEffect(() => () => actions.resetNotifications(), []);

  useEffect(() => {
    if (identity.isTruthyNumber(userId)) {
      actions.getNewNotificationsCount();
      actions.getNotifications();
    }
  }, [userId]);

  const showMore = () => {
    actions.setNextPage();
    actions.getNotifications();
  };

  return (
    <div className="activity-sidebar">
      <Header />
      <NotificationsList />
      <RenderIf condition={identity.isTruthyNumber(notificationsCount) && !allFetched}>
        <ShowMoreButton isLoading={isLoading} onShowMore={showMore} />
      </RenderIf>
      <RenderIf condition={!identity.isTruthyNumber(notificationsCount) && !isLoading}>
        <div className="activity-sidebar__no-data">
          <p>There are no notifications to be displayed.</p>
        </div>
      </RenderIf>
    </div>
  );
}

export default ActivitySidebar;
