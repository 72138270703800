import { PayloadAction } from '@reduxjs/toolkit';

import { DataPayload } from 'types/reduxTypes/ActionTypes';
import config from 'assets/config';
import { ProfileType } from 'types/people';
import { types } from './actions';

export type TableHookType = {
  limit: number,
  currentPage: number,
  openFilters: boolean
  previousTab: ProfileType,
  filtersChanged: boolean,
}

const initialState: TableHookType = {
  limit: config.TABLE_DEFAULT_LIMIT,
  currentPage: 1,
  openFilters: false,
  previousTab: 'all',
  filtersChanged: true,
}

const handleSetLimit = (state: TableHookType, payload: DataPayload<number>) => ({ ...state, limit: payload.data });

const handleSetCurrentPage = (state: TableHookType, payload: DataPayload<number>) => ({
  ...state, currentPage: payload.data,
});

const handleSetOpenFilters = (state: TableHookType, payload: DataPayload<boolean>) => ({
  ...state, openFilters: payload.data,
});

const handleSetPreviousTab = (state: TableHookType, payload: DataPayload<ProfileType>) => ({
  ...state, previousTab: payload.data, currentPage: 1,
});

const handleSetFiltersChanged = (state: TableHookType, payload: DataPayload<boolean>) => ({
  ...state, filtersChanged: payload.data,
});

const reducer = (state: TableHookType, action: PayloadAction<DataPayload<boolean | number | ProfileType>>) => {
  switch (action.type) {
    case types.SET_LIMIT: {
      return handleSetLimit(state, <DataPayload<number>>action.payload);
    }
    case types.SET_CURRENT_PAGE: {
      return handleSetCurrentPage(state, <DataPayload<number>>action.payload);
    }
    case types.SET_OPEN_FILTERS: {
      return handleSetOpenFilters(state, <DataPayload<boolean>>action.payload);
    }
    case types.SET_PREVIOUS_TAB: {
      return handleSetPreviousTab(state, <DataPayload<ProfileType>>action.payload);
    }
    case types.SET_FILTERS_CHANGED: {
      return handleSetFiltersChanged(state, <DataPayload<boolean>>action.payload);
    }
    default: {
      return state;
    }
  }
}

export { reducer, initialState };
