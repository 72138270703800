import { identity } from 'utils';
import { defaultService } from 'redux/utils';
import {
  CreateServiceApiRequestPayload,
  CreateServiceCategoryApiRequestPayload,
  CreateServiceCategoryType,
  CreateServicePayload,
  CreateServiceSubCategoryApiRequestPayload,
  CreateServiceSubCategoryType,
  PriceType,
  ResponseServiceType,
  ResponseUnitType,
  ServiceCategoryType,
  ServicePriceApiRequestPayload,
  ServicePriceType,
  ServicePriceTypeEnum,
  ServiceQuery,
  ServiceSubCategoryType,
  ServiceType,
  Unit,
  UnitType,
  UpdateServiceApiRequestPayload,
  UpdateServicePayload,
} from '../../types';
import { convertGetServiceProviderResponse } from './service_provider_converter';
import { queryBuilder } from '../../../http_client';
import { NUMBER_TO_UNIT_MAP, UNIT_TO_NUMBER_MAP } from '../constants';

export const getPriceTypeFromUnitName = (name?: string, unit?: number): PriceType => {
  const nameLowercase = name?.toLowerCase();
  const unitType: Unit = NUMBER_TO_UNIT_MAP[unit!];
  if (unitType !== 'Time' || !identity.isTruthyString(nameLowercase)) {
    return '';
  }

  switch (nameLowercase) {
    case 'month': {
      return 'per-month';
    }
    case 'week': {
      return 'per-week';
    }
    case 'hour': {
      return 'per-hour';
    }
    case 'year': {
      return 'per-year';
    }
    default: {
      return 'per-day';
    }
  }
};

export const getPriceTypeFromUnit = (unit?: ResponseUnitType): PriceType => {
  if (!identity.isObjWithChildren(unit)) {
    return '';
  }

  const { type, name } = unit!;
  return getPriceTypeFromUnitName(name, type);
};

const formatTimeUnitName = (unitName?: string): string | undefined => {
  if (identity.isTruthyString(unitName)) {
    if (unitName?.indexOf('ly') === -1 && unitName?.toLowerCase().indexOf('per') === -1) {
      return `Per ${unitName}`;
    }
  }

  return unitName;
};

export const convertServiceUnit = (response?: ResponseUnitType): UnitType | undefined => {
  if (identity.isObjWithChildren(response)) {
    const type = NUMBER_TO_UNIT_MAP[response?.type!];
    return {
      id: response?.id!,
      name: type === 'Time' ? formatTimeUnitName(response?.name) : response?.name,
      type,
    };
  }
};

export const convertServiceUnits = (response?: ResponseUnitType[]): UnitType[] => {
  const units: UnitType[] = [];

  if (identity.isFullArray(response)) {
    response?.forEach((unit) => {
      const convertedUnit: UnitType | undefined = convertServiceUnit(unit);
      if (identity.isObjWithChildren(convertedUnit)) {
        units.push(convertedUnit!);
      }
    });
  }

  return units;
};

export const getOfferPrice = (priceType?: 0 | 1 | 2) => {
  switch (priceType) {
    case 1:
      return 'fixed';
    case 2:
      return 'range';
    default:
      return '-';
  }
}

const convertServicePriceText = (price: string, offerType: 'fixed' | 'range' | '-'): string => {
  if (!identity.isTruthyString(price)) {
    return '';
  }

  /** E.g. price = 500 OR price = 100 - 500 */
  if (price.indexOf('-') === -1) {
    return `AED ${price}`;
  }

  if (offerType === 'range') {
    return price.split('-').map((value) => `AED ${value.trim()}`).join(' to ');
  }

  const singlePrice = price.split('-').shift()?.trim();
  return identity.isTruthyString(singlePrice) ? `AED ${singlePrice}` : '';
};

export const convertServiceCategoryToString = (category?: ServiceCategoryType): string => {
  if (identity.isObjWithChildren(category) && identity.isTruthyString(category?.name)) {
    return category?.name!;
  }

  return '';
};

export const convertServiceCategoriesToString = (categories?: ServiceCategoryType[]): string => {
  if (!identity.isFullArray(categories)) {
    return '';
  }

  const categoryNames = categories?.map((category) => convertServiceCategoryToString(category));
  return categoryNames!.join(', ');
};

export const convertCreateServicePayload = (payload: CreateServicePayload): CreateServiceApiRequestPayload => ({
  _category_id: payload.category,
  _description: payload.about,
  _document: payload.offerDocLink,
  _name: payload.name,
  _price_type: ServicePriceTypeEnum[payload.offerPrice],
  _service_provider_id: payload.serviceProvider?.id || payload.serviceProviderId,
  _service_provider_type_id: 1, // Fixing to 1 until more types implemented
  _sub_categories: payload.subCategories?.join(','),
  _unit_type: UNIT_TO_NUMBER_MAP[payload.unit?.type!] || 0,
  _active: 1,
});

export const convertUpdateServicePayload = (payload: UpdateServicePayload): UpdateServiceApiRequestPayload => ({
  _description: payload.about,
  _document: payload.offerDoc,
  _name: payload.name,
  _service_provider_type_id: 1, // Fixing to 1 until more types implemented
  _price_type: ServicePriceTypeEnum[payload?.offerPrice!],
  _unit_type: UNIT_TO_NUMBER_MAP[payload.unit?.type!] || 0,
  _active: payload.active ? 1 : 0,
});

export const convertGetServiceResponse = (response?: ResponseServiceType): ServiceType | undefined => {
  const offerPrice = getOfferPrice(response?.priceType);
  if (identity.isObjWithChildren(response)) {
    return {
      id: response?.id!,
      serviceProvider: convertGetServiceProviderResponse(response?.serviceProvider),
      name: response?.name!,
      about: response?.description!,
      category: convertServiceCategoryToString(response?.category),
      priceText: convertServicePriceText(response?.price!, offerPrice), // price to be displayed to users sent by BE as aggregated data
      priceFrom: '0', // Will be set in the service price api response
      priceTo: '0', // Will be set in the service price api response
      priceType: getPriceTypeFromUnitName(response?.unit, response?.unitType),
      offerPriceId: response?.priceType!,
      type: 'Incentive',
      offerPrice,
      offerDocLink: response?.document!,
      startup: {
        id: 0,
        name: '',
      },
      serviceStatus: 'unsent',
      categoryId: identity.isTruthyNumber(response!.category!.id) ? response!.category!.id : response!.categoryID!,
      priceUnits: response?.unit,
      subCategories: response?.subCategories,
      serviceProviderId: response?.serviceProviderID,
      active: response?.active,
    };
  }
  return defaultService;
};

export const convertGetServicesListResponse = (response?: ResponseServiceType[]): ServiceType[] => {
  if (!identity.isFullArray(response)) {
    return [];
  }

  return response!.map((serviceType) => convertGetServiceResponse(serviceType)!);
};

export const convertServicePriceRequestPayload = (payload: ServicePriceType): ServicePriceApiRequestPayload => ({
  _from_price: payload.fromPrice,
  _service_id: payload.serviceID!,
  _to_price: payload.toPrice,
  _unit_id: payload.unitID || 0,
});

export const formatQuery = (query: ServiceQuery) => {
  const { isPreload, limit, offset } = query;
  const {
    categoryId, serviceProviderId, priceTypeId, fieldSelection, serviceSubCategory,
  } = query.filters || {};

  const params = queryBuilder({
    price_type: priceTypeId,
    sub_categories: identity.isPositiveNumber(serviceSubCategory) ? serviceSubCategory : undefined,
  });
  if (isPreload) {
    params.preload();
  }
  params
    .limit(limit)
    .offset(offset)
    .select(fieldSelection)
    .in({ category_id: categoryId, service_provider_id: serviceProviderId })
  return params.toObject();
}

export const convertCreateServiceCategoryPayload = (
  payload: ServiceCategoryType,
): CreateServiceCategoryApiRequestPayload => ({
  _name: payload?.name!,
  _active: payload?.active!,
});

export const convertCreateServiceSubCategoryPayload = (
  payload: ServiceSubCategoryType,
): CreateServiceSubCategoryApiRequestPayload => ({
  _name: payload?.name!,
  _active: payload?.active!,
  _service_category_id: payload?.serviceCategoryID,
});

export const convertServiceCategoryBody = (payload: CreateServiceCategoryType):
  CreateServiceCategoryApiRequestPayload => ({
  _name: payload.name,
});

export const convertServiceSubCategoryBody = (payload: CreateServiceSubCategoryType):
  CreateServiceSubCategoryApiRequestPayload => ({
  _name: payload.name,
  _service_category_id: payload?.serviceCategoryID!,
});
