import { connect } from 'react-redux';
import { Action, bindActionCreators, Dispatch } from 'redux';

import { getServiceById, deleteService } from 'redux/actions/services';
import { getUnsentRequests } from 'redux/actions/request';
import { selectIsLoading, selectServiceDetails } from 'redux/selectors/services';
import { selectUnsentRequests } from 'redux/selectors/request';
import { setIsDrawerOpen, setDrawerBody } from 'redux/actions/workspace';
import { StateType } from 'types';
import { selectUserType } from 'redux/selectors/auth';

const mapStateToProps = (state: StateType) => ({
  service: selectServiceDetails(state),
  unsentRequests: selectUnsentRequests(state),
  isLoading: selectIsLoading(state),
  userType: selectUserType(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  actions: bindActionCreators({
    getServiceById,
    getUnsentRequests,
    setDrawerBody,
    setIsDrawerOpen,
    deleteService,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps);
