import React from 'react';
import { AiOutlinePlus } from 'react-icons/ai';
import { Button } from 'antd';

import { LeftContentProps } from '../../../../../types/componentTypes/SearchBarProps';
import identity from '../../../../../utils/identity';

const LeftContent = (props: LeftContentProps) => {
  const { leftContent, onAddButtonClick } = props;

  const onClick = () => {
    if (!identity.isFalsy(onAddButtonClick)) {
      onAddButtonClick!();
    }
  }

  switch (leftContent) {
    case 'add-button': {
      return (
        <Button
          type="link"
          icon={<AiOutlinePlus />}
          className="add-new-btn"
          onClick={onClick}
        >
          {`add new ${props.addButtonText}`}
        </Button>
      );
    }
    case 'info-text': {
      return <span className="info-text">{props.infoText}</span>
    }
    default: {
      return null;
    }
  }
}

LeftContent.defaultProps = {
  addButtonText: '',
  infoText: '',
  leftContent: undefined,
}

export default LeftContent;
