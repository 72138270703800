import React from 'react';
import { BsBoxArrowInDown } from 'react-icons/bs';

import { ButtonType } from '../../../../../types/componentTypes/TitleProps';

// NOTE: pass data to buttons function when button actions are defined.
const buttons = (): ButtonType[] => ([
  {
    key: 'download-button-incentive-request-details',
    icon: <BsBoxArrowInDown />,
    onClick: () => null,
  },
]);

export default buttons;
