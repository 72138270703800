import { API_ROOT } from 'packages/http_client/utils';

export const STARTUP_API = `${API_ROOT}/startup`;

export const STAGE_API = `${API_ROOT}/stage`;

export const INVESTMENT_STAGE_API = `${API_ROOT}/investmentstage`;

export const SECTOR_API = `${API_ROOT}/sector`;

export const STARTUP_ADGM_LICENSE = `${API_ROOT}/startupadgmlicense`;
export const STARTUP_INCENTIVE_AGREEMENT = `${API_ROOT}/startupincentiveagreement`;
