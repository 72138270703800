import React from 'react';

import SVGWrap from './SVGWrap';

const SidebarCollapseIcon = () => (
  <SVGWrap>
    <path d="M6.16667 2.5V13.5M10.4444 9.83333L8.61111 8L10.4444 6.16667M3.72222 2.5H12.2778C12.9528 2.5 13.5 3.04721 13.5 3.72222V12.2778C13.5 12.9528 12.9528 13.5 12.2778 13.5H3.72222C3.04721 13.5 2.5 12.9528 2.5 12.2778V3.72222C2.5 3.04721 3.04721 2.5 3.72222 2.5Z" fill="transparent" strokeWidth="0.916667" strokeLinecap="round" strokeLinejoin="round" />
  </SVGWrap>
);

export default SidebarCollapseIcon;
