import { useReducer } from 'react';

import { DataPayload } from 'types/reduxTypes/ActionTypes';
import { PeopleFilters } from 'types/people';
import { initialState, reducer } from './reducer';
import {
  setFilters as setFiltersAC,
  setGender as setGenderAC,
  setNationalities as setNationalitiesAC,
  setRoles as setRolesAC,
  setSkills as setSkillsAC,
  setStartups as setStartupsAC,
  resetFilters as resetFiltersAC,
} from './actions';

const usePeopleFiltersHook = () => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const setFilters = (payload: PeopleFilters) => {
    dispatch(setFiltersAC(payload));
  }

  const setGender = (payload: DataPayload<number>) => {
    dispatch(setGenderAC(payload));
  }

  const setNationalities = (payload: DataPayload<number[]>) => {
    dispatch(setNationalitiesAC(payload));
  }

  const setRoles = (payload: DataPayload<string[]>) => {
    dispatch(setRolesAC(payload));
  }

  const setSkills = (payload: DataPayload<string[]>) => {
    dispatch(setSkillsAC(payload));
  }

  const setStartups = (payload: DataPayload<number[]>) => {
    dispatch(setStartupsAC(payload));
  }

  const resetFilters = () => {
    dispatch(resetFiltersAC());
  }

  return {
    setFilters,
    setGender,
    setNationalities,
    setRoles,
    setSkills,
    setStartups,
    resetFilters,
    state,
    initialState,
  };
}

export default usePeopleFiltersHook;
