import React from 'react';
import { Button } from 'antd';

import { DashboardCardProps } from 'types/componentTypes/Dashboard';

const DashboardCard = (props: DashboardCardProps) => (
  <div className={`dashboard-card ${props.color}-card`}>
    <div className="dashboard-card__icon">
      {props.icon}
    </div>
    <p className="dashboard-card__title">{props.title}</p>
    <span className="dashboard-card__count">{props.count}</span>
    <Button className="secondary-button md" onClick={props.onClick}>{props.buttonText}</Button>
  </div>
);

export default DashboardCard;
