import { connect } from 'react-redux';
import { bindActionCreators, Dispatch, Action } from 'redux';

import { getPerformanceReportById, updatePerformanceReportById } from 'redux/actions/performance';
import { setIsDrawerOpen } from 'redux/actions/workspace';
import { selectPerformanceReportDetails, selectPerformanceReportId } from 'redux/selectors/performance';
import { selectIsLoading } from 'redux/selectors/workspace';
import { StateType } from 'types';

const mapStateToProps = (state: StateType) => ({
  id: selectPerformanceReportId(state),
  performanceReport: selectPerformanceReportDetails(state),
  isLoading: selectIsLoading(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  actions: bindActionCreators({
    getPerformanceReportById,
    setIsDrawerOpen,
    updatePerformanceReportById,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps);
