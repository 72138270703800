import { createSelector } from 'reselect';

import { StateType } from 'types';
import { PeopleStateType } from 'types/reduxTypes/StateTypes';

const selectPeopleState = (state: StateType) => state.peopleReducer;

export const selectFounders = createSelector(
  selectPeopleState,
  (state: PeopleStateType) => state.founders,
);

export const selectInvolvementTypes = createSelector(
  selectPeopleState,
  (state: PeopleStateType) => state.involvementTypes,
);

export const selectTeamMembers = createSelector(
  selectPeopleState,
  (state: PeopleStateType) => state.teamMembers,
);

export const selectActiveTab = createSelector(
  selectPeopleState,
  (state: PeopleStateType) => state.activeTab,
);

export const selectPeopleFilters = createSelector(
  selectPeopleState,
  (state: PeopleStateType) => state.filters,
);

export const selectPeopleCount = createSelector(
  selectPeopleState,
  (state: PeopleStateType) => state.count,
);

export const selectIsLoading = createSelector(
  selectPeopleState,
  (state: PeopleStateType) => state.isLoading,
);

export const selectAllMembers = createSelector(
  selectPeopleState,
  (state: PeopleStateType) => state.allMembers,
);

export const selectPeopleRoles = createSelector(
  selectPeopleState,
  (state: PeopleStateType) => state.roles,
);

export const selectPeopleSkills = createSelector(
  selectPeopleState,
  (state: PeopleStateType) => state.skills,
);

export const selectPeopleFiltersActive = createSelector(
  selectPeopleState,
  (state: PeopleStateType) => state.filtersActive,
);
