import React from 'react';

import SVGWrap from './SVGWrap';

const SidebarExpandIcon = () => (
  <SVGWrap>
    <path d="M6.16667 2.5V13.5M9.22222 6.16667L11.0556 8L9.22222 9.83333M3.72222 2.5H12.2778C12.9528 2.5 13.5 3.04721 13.5 3.72222V12.2778C13.5 12.9528 12.9528 13.5 12.2778 13.5H3.72222C3.04721 13.5 2.5 12.9528 2.5 12.2778V3.72222C2.5 3.04721 3.04721 2.5 3.72222 2.5Z" fill="transparent" strokeWidth="1.22222" strokeLinecap="round" strokeLinejoin="round" />
  </SVGWrap>
);

export default SidebarExpandIcon;
