import React from 'react';
import classnames from 'classnames';

import StatCard from './components/StatCard';
import { StatCardsProps } from '../../../types/componentTypes';
import { cardTypesMap } from './utils/constants';

import './StatCardsContainer.scss';

const StatCards = (props: StatCardsProps) => (
  <div className={classnames('stat-cards-container', { 'is-one-card': props.cards.length === 1 })}>
    {props.cards.map((statCard) => (
      <StatCard
        title={statCard.title}
        count={statCard.count}
        path={statCard.path}
        icon={cardTypesMap[statCard.title]?.icon}
        key={`stat-card-${statCard.title}-${statCard.count}`}
      />
    ))}
  </div>
)

export default StatCards;
