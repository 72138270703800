/* eslint-disable @typescript-eslint/no-explicit-any, import/no-extraneous-dependencies */
import { GetRowKey } from 'rc-table/lib/interface';

import identity from '../identity';

/**
 * @function getRowKeys
 * function used for checking and returning row key. This is needed because antd table renders the row
 * key even on empty arrays, making the app crash.
 */
export const getRowKey = (items: any[], initialKey: string, recordKey: string): string | GetRowKey<any> | undefined => {
  if (identity.isFullArray(items)) {
    return (record) => `${initialKey}-${record[recordKey]}`;
  }
  return undefined;
}

export default getRowKey;
