import React, { useEffect } from 'react';
import { Spin } from 'antd';

import { getOffset, useTableHook, identity } from 'utils';
import { EventsProps } from 'types/events/eventsProps';
import { EventType } from 'types/events';
import { Event } from 'components/components';
import { CustomPagination } from 'components/components/Table/components';
import { EventTabs, FiltersDrawer } from './components';
import { isUpcoming } from './utils/helpers';

import './Events.scss';

const Events = (props: EventsProps) => {
  const {
    events,
    currentTab,
    filters,
    currentSubTab,
    count,
  } = props;
  const { getEvents, getEventsCount } = props.actions;

  const {
    state, setLimit, setCurrentPage, setOpenFilters,
  } = useTableHook();

  const { openFilters, limit, currentPage } = state;

  useEffect(() => {
    getEventsCount();
  }, [filters]);

  useEffect(() => {
    setCurrentPage(1);
  }, [currentTab, currentSubTab]);

  useEffect(() => {
    getEvents({ limit, offset: getOffset(limit, currentPage) });
  }, [currentTab, filters, currentSubTab, limit, currentPage]);

  return (
    <div className="events content-container">
      <EventTabs setOpenFilters={setOpenFilters} />
      <Spin spinning={props.isLoading}>
        <div className="events__container">
          {identity.isFullArray(events)
            ? events.map((event: EventType) => <Event rightContent="buttons" showLocation key={event.id} event={event} id={event?.eventRsvpId!} />)
            : <p className="no-events">No events.</p>}
        </div>
      </Spin>
      <FiltersDrawer open={openFilters} onClose={() => setOpenFilters(false)} />
      <CustomPagination
        setLimit={setLimit}
        setCurrentPage={setCurrentPage}
        currentPage={currentPage}
        limit={limit}
        total={isUpcoming(currentTab) ? count.upcoming : count.past}
      />
    </div>
  );
}

export default Events;
