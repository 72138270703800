import React, { useEffect } from 'react';
import {
  Form, Drawer, Button, DatePicker,
} from 'antd';
import classNames from 'classnames';

import { FiltersDrawerProps } from 'types/events/eventsProps';
import { EventsFilters } from 'types/events';
import EventEmitter from 'event_emitter';
import { FormBuilder, Input } from 'components/components';
import AddButtonDrawer from 'components/components/AddButtonDrawer';
import { identity } from 'utils';
import { eventFilterFields, resetFiltersEvent } from './utils/constants';
import { categoriesColumns } from '../../../utils/constants/columns';

import './FiltersDrawer.scss';

const FiltersDrawer = (props: FiltersDrawerProps) => {
  const {
    open, onClose, isFilterTableOpen, actions, eventCategories,
  } = props;

  useEffect(() => {
    if (identity.isEmptyArray(eventCategories)) {
      actions.getEventCategories();
    }
  }, []);

  const [form] = Form.useForm();

  const resetAll = () => {
    form.resetFields();
    EventEmitter.emit(resetFiltersEvent);
  }

  const onSubmit = (data: EventsFilters) => {
    actions.setFilters({ data });
    onClose();
  }

  /** Temporary using antd drawer directly until
   * the drawer component gets refactored */
  return (
    <Drawer
      title="Filters"
      className={classNames('drawer', 'event-filters', { 'drawer--hidden': isFilterTableOpen })}
      open={open && !isFilterTableOpen}
      onClose={() => onClose()}
    >
      <Form
        form={form}
        className="form-standalone"
        onFinish={onSubmit}
        validateTrigger="onSubmit"
      >
        <AddButtonDrawer
          form={form}
          drawerTitle="Categories"
          columns={categoriesColumns}
          dataSource={eventCategories.map(({ id: value, name: label }) => ({ value, label }))}
          options={eventCategories.map(({ id: value, name: label }) => ({ value, label }))}
          filterBy="label"
          placeholder="categories"
          name="categories"
          rowKeyName="value"
          label="Categories"
          eventName={resetFiltersEvent}
        />
        <Input label="Starting at" layout="vertical" name="startingTime">
          <DatePicker />
        </Input>
        <Input label="Ending at" layout="vertical" name="endingTime">
          <DatePicker />
        </Input>
        <FormBuilder
          formFragment
          fields={eventFilterFields}
          oneColumn
        />
        <div className="event-filters__buttons">
          <Button className="secondary-button" onClick={resetAll}>Reset all</Button>
          <Button className="primary-button" htmlType="submit">Filter</Button>
        </div>
      </Form>
    </Drawer>
  )
}

FiltersDrawer.defaultProps = {
  isFilterTableOpen: false,
}

export default FiltersDrawer;
