export default function isFalsy(data: any): boolean {
  const falsyConditions = [
    data === null,
    data === undefined,
    data === false,
    typeof data === 'number' && (data === 0 || isNaN(data)), // eslint-disable-line no-restricted-globals
    typeof data === 'string' && data.length === 0,
    Array.isArray(data) && data.length === 0,
    typeof data === 'object'
    && data !== null
    && Object.keys(data).length === 0
    && !Date.parse(data),
  ];

  return falsyConditions.some(Boolean);
}
