import { connect } from 'react-redux';
import { bindActionCreators, Dispatch, Action } from 'redux';

import { addServiceProviderOffer } from '../../redux/actions/serviceProviders'

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  actions: bindActionCreators({
    addServiceProviderOffer,
  }, dispatch),
});

export default connect(null, mapDispatchToProps);
