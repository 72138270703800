import React, { useState } from 'react';
import {
  Button,
  Form,
  Spin,
  Input as InputAntd,
} from 'antd';

import { WarningIcon } from 'assets/icons';
import { RenderIf, Input } from 'components/components';
import { onlySpacesRegex } from 'utils/constants/regexPatterns';
import { isPartner, identity } from 'utils';
import { DiscussionBoardProps } from 'types/requests';
import Comment from './components/Comment';
import { getSystemMessageFormat } from './utils/helpers';

import './DiscussionBoard.scss';

const { TextArea } = InputAntd;

const DiscussionBoard = (props: DiscussionBoardProps) => {
  const {
    comments, isLoading, onComment, userType, acknowledge,
    disableComment,
  } = props;
  const [form] = Form.useForm();

  const [comment, setComment] = useState('');

  const isCommentEmpty = !identity.isTruthyString(comment) || onlySpacesRegex.test(comment)

  const onSubmit = (data: { newComment: string }) => {
    onComment(data.newComment);
    form.resetFields();
  }

  return (
    <Spin spinning={isLoading && identity.isEmptyArray(comments)}>
      <div className="discussion-board">
        <div className="discussion-board__comments">
          {identity.isFullArray(comments)
            ? comments?.map((item) => (
              <Comment
                key={item.id}
                name={item.systemMessage ? 'System message' : item.person?.name}
                imageUrl={item.person?.imageLink}
                companyName={item.systemMessage ? '' : item.person?.companyName}
                date={item.date}
                text={
                  item.systemMessage
                    ? getSystemMessageFormat(item.person.name, item.person.companyName, item.text, item.newStatus!)
                    : item.text
                }
              />
            ))
            : <p className="discussion-board__no-comments">Currently, there are no comments.</p>}
        </div>
        <Form
          className="form-standalone"
          onFinish={onSubmit}
          form={form}
        >
          <RenderIf condition={!isPartner(userType)}>
            <>
              <Input
                label="Add comment"
                layout="vertical"
                name="newComment"
              >
                <TextArea
                  rows={4}
                  placeholder="Add a new comment..."
                  disabled={isLoading || disableComment}
                  onChange={(e) => setComment(e.target.value)}
                />
              </Input>
              <Button className="primary-button" htmlType="submit" disabled={isLoading || disableComment || isCommentEmpty}>Send </Button>
            </>
          </RenderIf>
          <RenderIf condition={isPartner(userType)}>
            <>
              <Input
                label="Add comment"
                layout="vertical"
                name="newComment"
              >
                <TextArea rows={4} placeholder="Add a new comment..." disabled={isLoading || !acknowledge || disableComment} onChange={(e) => setComment(e.target.value)} />
              </Input>
              <Button className="primary-button" htmlType="submit" disabled={isLoading || !acknowledge || disableComment || isCommentEmpty}>Send </Button>
              <RenderIf condition={!acknowledge}>
                <div className="warning-container">
                  <WarningIcon />
                  <p className="warning-container__tag">You must acknowledge request before replying.</p>
                </div>
              </RenderIf>
            </>
          </RenderIf>
        </Form>
      </div>
    </Spin>
  );
}

DiscussionBoard.defaultProps = {
  comments: [],
  isLoading: false,
  acknowledge: false,
  disableComment: false,
}

export default DiscussionBoard;
