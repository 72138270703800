/* eslint-disable react/no-array-index-key */
import React from 'react'

import { TeamMemberDetailsProps } from 'types/services';

import './TeamMemberDetails.scss';

const TeamMemberDetails = (props: TeamMemberDetailsProps) => (
  <div className="details-overview">
    <p className="details-overview__header">
      {props.required && <span className="sign" />}
      {props.header}
    </p>
    <p className={`details-overview__subheader ${props.isError && 'alert_text'}`}>{ props.subheader }</p>
  </div>
)

export default TeamMemberDetails;
