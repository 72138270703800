import { People } from '../../../../../../../../../types/activities';

export const filterTableData = (tableData: People[], peopleFilter: string) => {
  const filter = peopleFilter.toLowerCase();
  return tableData.filter((option) => (`${option?.firstName} ${option?.lastName}`.toLocaleLowerCase().includes(filter) || filter === ''));
}

export const removeDuplicates = (arr: string[]) => arr.filter((
  item: string,
  index: number,
) => arr.indexOf(item) === index);
