import requiredValidation from '../../../../../../../../utils/constants/validationRules';
import { inputTypes } from '../../../../../../../components/FormBuilder/utils/constants';
import { getFormFieldBuilder } from '../../../../../../../components/FormBuilder/utils/FormFieldBuilder';

export const editServiceDetailsFields = [
  {
    fields: [
      getFormFieldBuilder('about', 'Description', inputTypes.textArea)
        .withRows(4)
        .withRules([requiredValidation])
        .build(),
    ],
  },
  {
    fields: [
      getFormFieldBuilder('category', 'Category')
        .withRules([requiredValidation])
        .build(),
    ],
  },
  {
    fields: [
      getFormFieldBuilder('website', 'Website')
        .withRules([requiredValidation])
        .build(),
    ],
  },
  {
    fields: [
      getFormFieldBuilder('price', 'Price')
        .withRules([requiredValidation])
        .build(),
    ],
  },
];

export default null;
