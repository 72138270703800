import React from 'react';
import { Typography } from 'antd';

import { DescriptionBoxProps } from '../../../../../types/componentTypes/CardProps';

const { Paragraph } = Typography;

const DescriptionBox = (props: DescriptionBoxProps) => {
  const { title, description } = props;

  return (
    <div className="description-container">
      <Typography className="description-title">{ title }</Typography>
      <div>
        <Paragraph
          className="description"
          ellipsis={{
            rows: 3,
          }}
        >
          { description }
        </Paragraph>
      </div>
    </div>
  );
};

DescriptionBox.defaultProps = {
  title: '',
  Description: '',
};

export default DescriptionBox;
