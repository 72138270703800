import React from 'react';

import SVGWrap from './SVGWrap';

const UploadIcon = () => (
  <SVGWrap>
    <path d="M5.50005 9L6.20505 9.705L7.50005 8.415V14.5H8.50005V8.415L9.79505 9.705L10.5001 9L8.00005 6.5L5.50005 9Z" />
    <path d="M11.7501 11H11.5001V10H11.7501C12.3468 10.0239 12.9286 9.80971 13.3674 9.40463C13.8062 8.99955 14.0662 8.43674 14.0901 7.84C14.1139 7.24326 13.8998 6.66148 13.4947 6.22265C13.0896 5.78381 12.5268 5.52387 11.9301 5.5H11.5001L11.4501 5.09C11.3391 4.24822 10.926 3.47547 10.2876 2.91569C9.64921 2.35591 8.8291 2.04727 7.98005 2.04727C7.131 2.04727 6.31089 2.35591 5.6725 2.91569C5.03412 3.47547 4.62098 4.24822 4.51005 5.09L4.50005 5.5H4.07005C3.47331 5.52387 2.9105 5.78381 2.50542 6.22265C2.10034 6.66148 1.88618 7.24326 1.91005 7.84C1.93392 8.43674 2.19387 8.99955 2.6327 9.40463C3.07154 9.80971 3.65331 10.0239 4.25005 10H4.50005V11H4.25005C3.44825 10.9949 2.67663 10.6936 2.08354 10.154C1.49045 9.61446 1.11776 8.87468 1.03712 8.07694C0.956473 7.27919 1.17357 6.47979 1.64668 5.83243C2.11978 5.18506 2.81549 4.73544 3.60005 4.57C3.81591 3.56326 4.37047 2.66099 5.17121 2.01373C5.97195 1.36648 6.97043 1.0134 8.00005 1.0134C9.02967 1.0134 10.0282 1.36648 10.8289 2.01373C11.6296 2.66099 12.1842 3.56326 12.4001 4.57C13.1846 4.73544 13.8803 5.18506 14.3534 5.83243C14.8265 6.47979 15.0436 7.27919 14.963 8.07694C14.8823 8.87468 14.5096 9.61446 13.9166 10.154C13.3235 10.6936 12.5518 10.9949 11.7501 11Z" />
  </SVGWrap>
);

export default UploadIcon;
