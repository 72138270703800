import { connect } from 'react-redux';
import { Action, bindActionCreators, Dispatch } from 'redux';

import { getNotifications, resetNotifications, setOrderBy } from 'redux/actions/notification';
import { selectOrderBy } from 'redux/selectors/notifications';
import {
  resetActivitiesPagination, setFilters, getActivities,
} from '../../../../../../redux/actions/activities';
import { selectFilters } from '../../../../../../redux/selectors/activities';
import { StateType } from '../../../../../../types';

const mapStateToProps = (state: StateType) => ({
  orderBy: selectOrderBy(state),
  filters: selectFilters(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  actions: bindActionCreators({
    getNotifications,
    setFilters,
    getActivities,
    resetActivitiesPagination,
    resetNotifications,
    setOrderBy,
  }, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps);
