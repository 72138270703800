import React from 'react'
import { Avatar } from 'antd';
import moment from 'moment';
import { BsFillPersonFill } from 'react-icons/bs';

import { NotificationProps } from '../../../../types/activities';

import './Notification.scss';

const Notification = (props: NotificationProps) => {
  const {
    dateTime, header, text, avatarUrl, username,
  } = props;

  return (
    <div className="notification">
      <Avatar className="avatar" size="large" src={avatarUrl} icon={<BsFillPersonFill />} />
      <div className="notification__info">
        <div className="notification__info-header">
          <p className="notification__description">
            {`${username} ${header}`}
          </p>
          <p className="notification__time-ago">{moment(dateTime).fromNow(true)}</p>
        </div>
        <div className="notification__info-card">
          <p>{text}</p>
        </div>
      </div>
    </div>
  )
};

Notification.defaultProps = {
  username: '',
  avatarUrl: '',
}

export default Notification;
