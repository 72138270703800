import { DataPayload, IsLoadingPayload } from 'types/reduxTypes/ActionTypes';
import { EventType } from 'packages/events_repository/types';
import { ResponseRequestType } from '../../../packages/service_request_repository';

export const types = {
  SET_DASHBOARD_EVENTS: 'SET_DASHBOARD_EVENTS',
  SET_DASHBOARD_REQUESTS: 'SET_DASHBOARD_REQUESTS',
  SET_DASHBOARD_IS_LOADING: 'SET_DASHBOARD_IS_LOADING',
  SET_DASHBOARD_EVENTS_COUNT: 'SET_DASHBOARD_EVENTS_COUNT',
  SET_DASHBOARD_REQUESTS_COUNT: 'SET_DASHBOARD_REQUESTS_COUNT',
  SET_DASHBOARD_STARTUPS_COUNT: 'SET_DASHBOARD_STARTUPS_COUNT',
  SET_DASHBOARD_TEAM_MEMBERS_COUNT: 'SET_DASHBOARD_TEAM_MEMBERS_COUNT',
  GET_DASHBOARD_COUNT: 'GET_DASHBOARD_COUNT',
  GET_DASHBOARD_DATA: 'GET_DASHBOARD_DATA',
  RESET_DASHBOARD: 'RESET_DASHBOARD',
}

export const resetDashboard = (payload: object = {}) => ({
  type: types.RESET_DASHBOARD,
  payload,
});

export const setDashboardEvents = (payload: DataPayload<EventType[]>) => ({
  type: types.SET_DASHBOARD_EVENTS,
  payload,
});

export const setDashboardRequests = (payload: DataPayload<ResponseRequestType[]>) => ({
  type: types.SET_DASHBOARD_REQUESTS,
  payload,
});

export const setIsLoading = (payload: IsLoadingPayload) => ({
  type: types.SET_DASHBOARD_IS_LOADING,
  payload,
});

export const getDashboardCount = (payload: object = {}) => ({
  type: types.GET_DASHBOARD_COUNT,
  payload,
});

export const setDashboardTeamMembersCount = (payload: DataPayload<number>) => ({
  type: types.SET_DASHBOARD_TEAM_MEMBERS_COUNT,
  payload,
});

export const setDashboardEventsCount = (payload: DataPayload<number>) => ({
  type: types.SET_DASHBOARD_EVENTS_COUNT,
  payload,
});

export const setDashboardStartupsCount = (payload: DataPayload<number>) => ({
  type: types.SET_DASHBOARD_STARTUPS_COUNT,
  payload,
});

export const setDashboardRequestsCount = (payload: DataPayload<number>) => ({
  type: types.SET_DASHBOARD_REQUESTS_COUNT,
  payload,
});

export const getDashboardData = (payload: object = {}) => ({
  type: types.GET_DASHBOARD_DATA,
  payload,
});
