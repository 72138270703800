import React from 'react';
import { Skeleton } from 'antd';
import { ActivitiesListProps } from '../../../../types/activities';
import identity from '../../../../utils/identity';

import Notification from '../Notification';

const NotificationsList = (props: ActivitiesListProps) => {
  const { isLoading, notifications } = props;

  return (
    <Skeleton
      loading={isLoading && !identity.isFullArray(notifications)}
      avatar
      active
      paragraph={{ rows: 6 }}
    >
      {notifications.map((n) => (
        <Notification
          dateTime={n.dateTime}
          header={n.header}
          text={n.text}
          key={n.id}
        />
      ))}
    </Skeleton>
  )
}

export default NotificationsList;
