import { connect } from 'react-redux';
import { Action, bindActionCreators, Dispatch } from 'redux';

import { changeStartupDocumentStatus, getStartupById } from 'redux/actions/startups';
import { selectStartupForReview } from 'redux/selectors/startups';
import { StateType } from 'types';
import { selectUserType } from 'redux/selectors/auth';
import { setIsDrawerOpen, setDrawerBody } from 'redux/actions/workspace';

const mapStateToProps = (state: StateType) => ({
  startup: selectStartupForReview(state),
  userType: selectUserType(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  actions: bindActionCreators({
    changeStartupDocumentStatus,
    setDrawerBody,
    setIsDrawerOpen,
    getStartupById,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps);
