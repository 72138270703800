import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router';
import { Button } from 'antd';

import { SearchIcon } from 'assets/icons';
import { Input } from 'components/components';
import { AddPeopleDrawerProps, People } from 'types/activities';
import { identity, getRowKey } from 'utils';
import Table from 'components/components/Table/components/RowTable';
import { addPeopleColumns } from '../../../../../../../utils/constants/columns';
import { filterTableData } from './utils/helpers';

import './AddPeopleDrawer.scss';

const AddPeopleDrawer = (props: AddPeopleDrawerProps) => {
  const {
    addPeopleOptions, checkedPeople, actions,
  } = props;
  const params: { id?: string } = useParams();
  const { id } = params;

  const [peopleFilter, setPeopleFilter] = useState<string>('');

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[]) => {
      actions.setCheckedPeople({ checkedPeople: selectedRowKeys as string[] });
    },
    getCheckboxProps: (record: People) => ({
      name: `${record.lastName}`,
    }),
    selectedRowKeys: checkedPeople,
    preserveSelectedRowKeys: true,
  };

  useEffect(() => {
    if (!identity.isFullArray(addPeopleOptions) && identity.isTruthyString(id)) {
      actions.getAddPeopleOptions({ cohortId: id! });
    }
  }, [addPeopleOptions, id]);

  const dataSource = useMemo(() => filterTableData(addPeopleOptions, peopleFilter), [addPeopleOptions, peopleFilter]);

  const onDone = () => {
    setPeopleFilter('');
    actions.setIsDrawerOpen({ isDrawerOpen: false });
  }

  return (
    <div className="add-people-table">
      <div className="input">
        <Input.InputField
          type="text"
          prefix={<SearchIcon />}
          placeholder="Search people..."
          value={peopleFilter}
          onChange={(e) => setPeopleFilter(e.target.value)}
        />
      </div>
      <Table
        dataSource={dataSource}
        showSorterTooltip={false}
        rowSelection={rowSelection}
        rowKey={getRowKey(dataSource, 'add-people-drawer', 'name')}
        columns={addPeopleColumns}
      />
      <div className="add-people-table__actions">
        <Button
          className="secondary-button"
          onClick={() => actions.setCheckedPeople({ checkedPeople: [] })}
        >
          Clear all
        </Button>
        <Button
          className="primary-button"
          onClick={onDone}
        >
          Done
        </Button>
      </div>
    </div>
  );
}

export default AddPeopleDrawer;
