/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react'
import { Avatar } from 'antd';

import { RenderIf } from 'components/components';
import { SearchType } from 'packages/search_repository/types';
import { identity } from 'utils';

import './SearchResponseItem.scss';

const SearchResponseItem = (props: Omit<SearchType, 'id'> & { onClick: Function}) => {
  const {
    image,
    name,
    type,
    onClick,
  } = props;

  return (
    <div className="search-response-item" onClick={() => onClick()}>
      <span className="search-response-item__name">
        <RenderIf condition={identity.isTruthyString(image)}>
          <Avatar src={image} />
        </RenderIf>
        {name}
      </span>
      <span className="search-response-item__type">{type}</span>
    </div>
  )
}

export default SearchResponseItem;
