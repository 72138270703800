import { connect } from 'react-redux';

import { selectUserType } from 'redux/selectors/auth';
import { StateType } from 'types';
// import { selectCurrentServicesFilterTab } from 'redux/selectors/services';

const mapStateToProps = (state: StateType) => ({
  userType: selectUserType(state),
});

export default connect(mapStateToProps, null);
