/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
  useEffect, useCallback, useState, useMemo,
} from 'react'
import {
  DatePicker, Form, RadioChangeEvent, TimePicker,
} from 'antd'
import moment, { Moment } from 'moment';
import { RcFile } from 'antd/lib/upload';
import { LabeledValue } from 'antd/lib/select';

import { addPeopleColumns } from 'components/utils/constants/columns';
import { UserType } from 'packages/user_repository/types/users';
import AddButtonDrawer from 'components/components/AddButtonDrawer';
import { FREQUENCY, frequencyOptions } from 'components/AddEventDrawer/utils/constants';
import RadioTags from 'components/components/Input/components/RadioTags';
import { resetFiltersEvent } from 'components/Events/components/FiltersDrawer/utils/constants';
import { Row } from 'components/components/FormBuilder/components';
import DrawerButton from 'components/components/Drawer/components/DrawerButton';
import {
  EventAttendeeType, EventCategory, EVENT_TYPE_TO_NUMBER, UpdateEventPayload,
} from 'packages/events_repository';
import { EditEventDetailsOverviewProps } from 'types/events/eventsProps';
import { identity, isEitherAdmin } from 'utils';
import {
  EditProfileImage, FormBuilder, RenderIf, Input,
} from 'components/components';
import { scrollToFirstError } from 'components/components/FormBuilder/utils/helpers';
import { editEventFields, EventFieldsParams } from '../EventOverview/utils/constants';

import '../../EditEventDrawer.scss';

const EditEventInfo = (props: EditEventDetailsOverviewProps) => {
  const {
    actions, event, id, eventCategories, users, eventAttendees,
  } = props;
  const [form] = Form.useForm();
  const [logo, setLogo] = useState<Blob>();
  const initialFrequencyId = useMemo(() => event?.frequency, [event?.frequency])
  const [frequency, setFrequency] = useState<number>(initialFrequencyId!);

  const onFrequencyChange = (e: RadioChangeEvent) => {
    setFrequency(e.target.value);
    form.setFieldValue('startingDate', undefined);
    form.setFieldValue('endingDate', undefined);
    form.setFieldValue('startingTime', undefined);
    form.setFieldValue('endingTime', undefined);
  }

  useEffect(() => {
    actions.getEventAttendeesByEventId({ id })
  }, [id])

  useEffect(() => {
    if (identity.isEmptyArray(eventCategories)) {
      actions.getEventCategories();
    }
  }, [eventCategories])

  useEffect(() => {
    actions.getEventById({ id: isEitherAdmin(props.userType) ? event?.id! : id! });
  }, [])

  const eventUsers: number[] = [];
  eventAttendees!.map((item: EventAttendeeType) => eventUsers.push(item.user.id));

  const fullForm = {
    ...event,
    category: event?.category.id,
    user_id: eventUsers,
    frequency: event?.frequency,
    type: EVENT_TYPE_TO_NUMBER[event?.type!],
  }

  useEffect(() => {
    form.setFieldsValue(fullForm);
  }, [fullForm]);

  const beforeUpload = useCallback((file: RcFile) => {
    setLogo(file);
    return false;
  }, []);

  const frequencyId = form.getFieldValue('frequencyId');
  const onSubmit = (data: UpdateEventPayload) => {
    const formData: UpdateEventPayload = {
      ...data,
      image: logo!,
      id: isEitherAdmin(props.userType) ? event?.id! : id!,
      endingTime: moment(data?.endingTime).utc().format('YYYY-MM-DD HH:mm:ss'),
      startingTime: moment(data?.startingTime).utc().format('YYYY-MM-DD HH:mm:ss'),
      frequency: frequencyId,
    }
    actions.updateEventById({ data: formData });
  }

  const onStartingDateChange = (date: Moment | null) => {
    form.setFieldValue('startingTime', moment(date).format('YYYY-MM-DD HH:mm:ss'));
  }

  const onEndingDateChange = (date: Moment | null) => {
    form.setFieldValue('endingTime', moment(date).format('YYYY-MM-DD HH:mm:ss'));
  }

  const evetCategoriesOptions: LabeledValue[] | undefined = useMemo(() => (
    eventCategories?.map((item: EventCategory) => ({ label: `${item.name || 'Unknown category'}`, value: item.id }))), [eventCategories]);

  useEffect(() => {
    if (identity.isEmptyArray(users)) {
      const query = {
        isPreload: true,
        userGroup: [1, 2, 3, 4],
      };
      actions?.getUsers!({ query })
    }
  }, [users]);

  const eventsTopFieldsData: EventFieldsParams = {
    eventCategories: evetCategoriesOptions!,
  };

  return (
    <div className="edit-event-drawer">
      <Form
        className="form-standalone"
        form={form}
        onFinishFailed={scrollToFirstError}
        onFinish={onSubmit}
      >
        <FormBuilder
          formFragment
          fields={editEventFields(eventsTopFieldsData)}
        />
        <Row oneColumn>
          <Input name="frequencyId" label="Frequency" layout="vertical">
            <RadioTags
              options={frequencyOptions}
              value={frequency}
              onChange={onFrequencyChange}
              defaultValue={frequency}
            />
          </Input>
        </Row>
        <RenderIf condition={
          frequency === FREQUENCY.monthly
          || frequency === FREQUENCY.weekly
          || frequency === FREQUENCY.annual
          || frequency === FREQUENCY.once
        }
        >
          <div className="date-pickers">
            <Form.Item
              getValueFromEvent={(onChange) => moment(onChange).format('YYYY-MM-DD')}
              getValueProps={(i) => ({ value: moment(i) })}
              label="Starting at"
              name="startingTime"
              className="form-item-vertical"
              colon={false}
            >
              <DatePicker
                showTime
                onChange={onStartingDateChange}
                showSecond={false}
                minuteStep={15}
                format="YYYY-MM-DD HH:mm"
                defaultValue={moment(event?.startingTime)}
              />
            </Form.Item>
            <Form.Item
              getValueFromEvent={(onChange) => moment(onChange).format('YYYY-MM-DD')}
              getValueProps={(i) => ({ value: moment(i) })}
              label="Ending at"
              name="endingTime"
              className="form-item-vertical"
              colon={false}
            >
              <DatePicker
                showTime
                onChange={onEndingDateChange}
                showSecond={false}
                minuteStep={15}
                format="YYYY-MM-DD HH:mm"
                defaultValue={moment(event?.endingTime)}
              />
            </Form.Item>
          </div>
        </RenderIf>
        <RenderIf condition={
          (frequency || event?.frequency) === FREQUENCY.daily
        }
        >
          <div className="date-pickers">
            <Form.Item
              getValueFromEvent={(onChange) => moment(onChange).utc().format('HH:mm')}
              getValueProps={(i) => ({ value: moment(i) })}
              label="Starting at"
              name="startingTime"
              className="form-item-vertical"
              colon={false}
            >
              <TimePicker
                onChange={onStartingDateChange}
                showSecond={false}
                minuteStep={15}
                format="HH:mm"
                defaultValue={moment(event?.startingTime)}
              />
            </Form.Item>
            <Form.Item
              getValueFromEvent={(onChange) => moment(onChange).format('HH:mm')}
              getValueProps={(i) => ({ value: moment(i) })}
              label="Ending at"
              name="endingTime"
              className="form-item-vertical"
              colon={false}
            >
              <TimePicker
                onChange={onEndingDateChange}
                showSecond={false}
                minuteStep={15}
                format="HH:mm"
                defaultValue={moment(event?.endingTime)}
              />
            </Form.Item>
          </div>
        </RenderIf>
        <Row oneColumn>
          <AddButtonDrawer
            form={form}
            name="user_id"
            drawerTitle="Invite people"
            columns={addPeopleColumns}
            buttonText="people"
            label="Invite people"
            dataSource={users!}
            options={users?.map(({ firstName, lastName, id: userId }: UserType) => ({ label: `${firstName} ${lastName}`, value: userId }))!}
            rowKeyName="id"
            placeholder="people"
            eventName={resetFiltersEvent}
            filterBy="firstName"
          />
        </Row>
        <EditProfileImage imageLink={event?.image!} beforeLogoUpload={beforeUpload} />
        <DrawerButton>Save changes</DrawerButton>
      </Form>
    </div>
  )
}

export default EditEventInfo;
