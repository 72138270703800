import React from 'react';
import { Button } from 'antd';
import classnames from 'classnames';
import { AiFillAppstore, AiOutlineAppstore } from 'react-icons/ai';
import { InferProps } from 'prop-types';

import { DisplayButtonsProps } from '../../../../../types/componentTypes/SearchBarProps';
import { ListIcon } from '../../../../../assets/icons';

const DisplayButtons = (props: InferProps<DisplayButtonsProps>) => {
  const { showDisplayButtons, isRowTableLayout, actions: { setTableLayout } } = props;

  if (!showDisplayButtons) {
    return null;
  }

  return (
    <div className="display-container">
      <Button
        onClick={() => setTableLayout({ isRowTableLayout: true })}
        className={classnames('display-button', { 'display-button-selected': isRowTableLayout })}
        icon={<ListIcon />}
      />
      <Button
        onClick={() => setTableLayout({ isRowTableLayout: false })}
        className={classnames('display-button', { 'display-button-selected': !isRowTableLayout })}
        icon={isRowTableLayout ? <AiOutlineAppstore /> : <AiFillAppstore />}
      />
    </div>
  );
}

DisplayButtons.defaultProps = {
  showDisplayButtons: false,
}

export default DisplayButtons;
