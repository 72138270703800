import React from 'react';

import { REQUEST_STATUS } from 'utils/constants/requestStatusTypes';
import getLabelValueObject from 'utils/helpers/getLabelValueObject';
import { getStatusName } from '../../../../../RequestsOverview/utils/helpers';

export const drawerTitleClass = 'drawer-title';

export const STATUS_COLOR_CLASS = {
  [REQUEST_STATUS.pendingQuotation]: `${drawerTitleClass}--pending`,
  [REQUEST_STATUS.new]: `${drawerTitleClass}--new`,
  [REQUEST_STATUS.quoted]: `${drawerTitleClass}--to-be-signed`,
  [REQUEST_STATUS.delivered]: `${drawerTitleClass}--delivered`,
  [REQUEST_STATUS.rejected]: `${drawerTitleClass}--rejected`,
  [REQUEST_STATUS.inProgress]: `${drawerTitleClass}--in-progress`,
  [REQUEST_STATUS.pendingHUB71]: `${drawerTitleClass}--info-required`,
};

export const inProgressStatusOption = getLabelValueObject(
  <p className={STATUS_COLOR_CLASS[REQUEST_STATUS.inProgress]}>
    {getStatusName(REQUEST_STATUS.inProgress)}
  </p>,
  REQUEST_STATUS.inProgress,
);

export const statusOptions = [
  getLabelValueObject(
    <p className={STATUS_COLOR_CLASS[REQUEST_STATUS.delivered]}>
      {getStatusName(REQUEST_STATUS.delivered)}
    </p>,
    REQUEST_STATUS.delivered,
  ),
];

export const partnerRequestStatusOptions = [
  getLabelValueObject(
    <p className={STATUS_COLOR_CLASS[REQUEST_STATUS.rejected]}>
      {getStatusName(REQUEST_STATUS.rejected)}
    </p>,
    REQUEST_STATUS.rejected,
  ),
  getLabelValueObject(
    <p className={STATUS_COLOR_CLASS[REQUEST_STATUS.delivered]}>
      {getStatusName(REQUEST_STATUS.delivered)}
    </p>,
    REQUEST_STATUS.delivered,
  ),
];

export type StatusOptionType = 'in-progress' | 'pending-HUB71' | 'delivered';

export const STATUS_OPTIONS_MAP = {
  [REQUEST_STATUS.delivered]: statusOptions[0],
};

export const PARTNER_STATUS_OPTIONS_MAP = {
  [REQUEST_STATUS.rejected]: partnerRequestStatusOptions[0],
  [REQUEST_STATUS.delivered]: partnerRequestStatusOptions[1],
};
