import { StartupType } from 'packages/startup_repository';
import { identity } from 'utils';

export const isStartupRequiredFieldsEmpty = (startup: StartupType) => {
  const {
    imageLink, name, website, primarySector, stageName,
  } = startup;

  return identity.isEmptyString(imageLink)
  || identity.isEmptyString(name)
  || identity.isEmptyString(website)
  || identity.isEmptyString(primarySector?.name)
  || identity.isEmptyString(stageName);
};

export const isStartupDataMissing = (startup: StartupType) => {
  const { status } = startup;

  return status.code === 'acceptance_letter_sent'
    || (status.code === 'acceptance_letter_signed' && isStartupRequiredFieldsEmpty(startup));
};

export const isCommunity = (status: string) => status === 'community';

export const isExpired = (status: string) => (['graduated', 'dropped_out', 'terminated'].includes(status))
