import { LabeledValue } from 'antd/lib/select';

import { DataPayload } from 'types/reduxTypes/ActionTypes';
import { ADGMLicenseType } from 'packages/startup_repository';
import { SetADGMFieldType } from '../../../AddMissingDetails/utils/addMissingDetailsHook/actions';

export const types = {
  SET_HAS_LICENSE: 'SET_HAS_LICENSE',
  SET_FILTERED_SECTOR_OPTIONS: 'SET_FILTERED_SECTOR_OPTIONS',
  SET_ADGM_ALL: 'SET_ADGM_ALL',
  SET_ADGM_FIELD: 'SET_ADGM_FIELD',
}

export const setHasLicense = (payload: DataPayload<boolean>) => ({
  type: types.SET_HAS_LICENSE,
  payload,
});

export const setFilteredSectorOptions = (payload: DataPayload<LabeledValue[]>) => ({
  type: types.SET_FILTERED_SECTOR_OPTIONS,
  payload,
});

export const setADGMAll = (payload: DataPayload<ADGMLicenseType>) => ({
  type: types.SET_ADGM_ALL,
  payload,
});

export const setADGMField = (payload: SetADGMFieldType) => ({
  type: types.SET_ADGM_FIELD,
  payload,
});
