import React from 'react';
import { Avatar } from 'antd';

import { RequestedByProps } from '../../../../types/requestDetailsModal';
import Overview from '../../../components/Overview';

import './RequestedBy.scss';

const RequestedBy = (props: RequestedByProps) => {
  const {
    name, email, phone, role, imageLink,
  } = props.personDetails;

  const { name: company, imageLink: companyImg } = props.startup;

  return (
    <Overview title="Requested by">
      <div className="requested-by__container">
        <div className="requested-by__info-container">
          <Avatar src={imageLink} alt={name} className="requested-by__person-image" />
          <div className="requested-by__info">
            <h3 className="requested-by__name">{name}</h3>
            <div className="requested-by__company-container">
              <div>
                <Avatar size="small" src={companyImg} alt={company} className="company-image" />
                <span className="requested-by__company-name">{company}</span>
              </div>
              <div className="dot" />
              <span className="requested-by__role">{role}</span>
            </div>
          </div>
        </div>
        <div className="requested-by__contact-container">
          <span className="requested-by__email">{email}</span>
          <span className="requested-by__phone">{phone}</span>
        </div>
      </div>
    </Overview>
  );
}

export default RequestedBy;
