import { connect } from 'react-redux';
import { bindActionCreators, Dispatch, Action } from 'redux';

import { selectServiceCategories, selectServiceSubCategories } from 'redux/selectors/services';
import { getServiceSubCategories } from 'redux/actions/services';
import { StateType } from 'types';

const mapStateToProps = (state: StateType) => ({
  categories: selectServiceCategories(state),
  subCategories: selectServiceSubCategories(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  actions: bindActionCreators({
    getServiceSubCategories,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps);
