import { defaultServiceProvider, partnerDef, startupDef } from 'redux/utils';
import { AuthStateType } from 'types/auth';
import { PartnerMemberType, SPMemberType, UserType } from 'packages/user_repository';
import { MemberType } from 'types/memberDetails';

export const myProfileDefault: PartnerMemberType | SPMemberType | MemberType | UserType | { id: number } = {
  ETAWithSign: undefined,
  about: '',
  areasOfExpertise: '',
  company: '',
  dateOfBirth: '',
  email: '',
  emiratesId: undefined,
  firstName: '',
  founder: 0,
  gender: 'Male',
  hobbies: [],
  id: 0,
  imageLink: '',
  jobFunctions: [],
  jobTitle: '',
  lastName: '',
  linkToConnect: '',
  location: {
    country: '',
    city: '',
  },
  mobile: '',
  nationality: '',
  nationalityId: 0,
  numberOfKids: 0,
  passport: '',
  phone: '',
  phoneNumber: '',
  photo: '',
  role: '',
  skills: [],
  specialTags: '',
  startupId: 0,
  status: '',
  statusId: 0,
  userId: 0,
  visa: '',
  visaProof: '',
}

export const authDefaultState: AuthStateType = {
  user: {
    userType: '',
    userId: '',
    userName: '',
    email: '',
    startupId: 0,
    serviceProviderId: 0,
  },
  isAuthenticated: false,
  isAuthenticationChecked: false,
  isLoading: false,
  startupStatus: '',
  startup: startupDef,
  partner: partnerDef,
  serviceProvider: defaultServiceProvider,
  myProfile: { id: 0 },
}

export default authDefaultState;
