import React from 'react';

import SVGWrap from './SVGWrap';

const InsuranceIconV2 = () => (
  <SVGWrap size={20} fill="none">
    <path d="M12.1387 14.1016V12.5506C14.742 12.4398 16.8262 10.2871 16.8262 7.65625V1.21094C16.8262 1.09434 16.7319 1 16.6153 1H14.8575C14.7409 1 14.6465 1.09434 14.6465 1.21094C14.6465 1.32754 14.7409 1.42188 14.8575 1.42188H16.4043V7.65625C16.4043 10.1248 14.3963 12.1328 11.9278 12.1328C9.45921 12.1328 7.45119 10.1248 7.45119 7.65625V1.42188H8.99807C9.11467 1.42188 9.20901 1.32754 9.20901 1.21094C9.20901 1.09434 9.11467 1 8.99807 1H7.24025C7.12365 1 7.02932 1.09434 7.02932 1.21094V7.65625C7.02932 10.2865 9.1135 12.4393 11.7168 12.5506V14.1016C11.7168 16.5701 9.70882 18.5781 7.24025 18.5781C4.77169 18.5781 2.76368 16.5701 2.76368 14.1016V11.367C3.42637 11.265 3.93556 10.6908 3.93556 10C3.93556 9.2377 3.31505 8.61719 2.55274 8.61719C1.79043 8.61719 1.16992 9.2377 1.16992 10C1.16992 10.6908 1.6791 11.265 2.3418 11.367V14.1016C2.3418 16.8021 4.53907 19 7.24025 19C9.94085 19 12.1387 16.8021 12.1387 14.1016ZM3.51368 10C3.51368 10.5297 3.08243 10.9609 2.55274 10.9609C2.02305 10.9609 1.5918 10.5297 1.5918 10C1.5918 9.47031 2.02305 9.03906 2.55274 9.03906C3.08243 9.03906 3.51368 9.47031 3.51368 10Z" strokeWidth="0.585938" />
  </SVGWrap>
);

export default InsuranceIconV2;
