/* eslint-disable react/jsx-props-no-spreading, @typescript-eslint/no-explicit-any */
// NOTE: this rule is disabled because the table can accept any record type
/* NOTE: this rule is disabled because RowTable accepts props that are the same as AntdTable Props, so there is no
 * possibility of spreading props that are not accepted by the antd table. */
import React from 'react';
import { Spin, Table, TableProps } from 'antd'

import '../../Table.scss';

const RowTable = (props: TableProps<any> & { isLoading?: boolean }) => {
  const { isLoading, ...tableProps } = props;

  return (
    <Spin spinning={isLoading}>
      <Table {...tableProps} pagination={false} />
    </Spin>
  );
}

RowTable.defaultProps = {
  isLoading: false,
};

export default RowTable;
