import React, { useEffect, useMemo, useState } from 'react';
import { Col, Row } from 'antd';
import moment from 'moment';
import { formatSlashMDY } from 'components/utils/constants/formatters';

import DataColumn from 'components/components/DataColumns/components/DataColumn';
import { StatusType } from 'packages/service_repository';
import { ServiceRequestType } from 'packages/service_request_repository';
import {
  identity,
  isEitherAdmin,
  isPartner,
  isServiceProvider,
  isStartup,
  parseNumberToPrice,
} from 'utils';
import { OverviewTabProps } from 'types/requests';
import REQUEST_STATUS from 'utils/constants/requestStatusTypes';
import { ButtonWithPrompt, RenderIf } from 'components/components'
import StaticNotification from 'components/components/Notification/StaticNotification';
import {
  getRequestSenderName,
  INSUFFICIENT_FUND_TEXT_BY_ROLE,
  validateHub71AdminApproval,
  validateStartupApproval,
} from '../../utils';
import {
  isDelivered,
  isInProgress,
  isNew,
  isPendingHUB71,
  isQuoted,
  isRejected,
} from '../../../../utils';
import {
  ReasonForDenying,
  UploadInvoiceFields,
  RequestItemCard,
  AgreementField,
} from './components';
import PaymentStatusCard from './components/PaymentStatusCard';
import './OverviewTab.scss';

const getPaymentProcessed = (paymentProcessed?: number) => {
  if (paymentProcessed === 0) {
    return 'Not Initiated';
  } if (paymentProcessed === 1) {
    return 'Initiated';
  } if (paymentProcessed === 2) {
    return 'In Process';
  } if (paymentProcessed === 3) {
    return 'Completed';
  }

  return 'None';
};

const OverviewTab = (props: OverviewTabProps) => {
  const {
    requestItems,
    userType,
    actions,
    details,
    wallet,
    showInvoiceFields,
  } = props;

  const { status, id, totalPrice } = requestItems[0]?.serviceRequest as ServiceRequestType || {};

  const { startup, serviceProvider } = details;

  console.log(requestItems)
  const [displayApproveBtn, setDisplayApproveBtn] = useState(true);
  const [showInsufficientFunds, setShowInsufficientFunds] = useState(false);

  const onDeny = (reason: string) => {
    actions.changeRequestStatus({
      id, component: 'requests', reason, status: REQUEST_STATUS.rejected,
    });
    setDisplayApproveBtn(true);
  }

  const onApprove = () => {
    let newStatus: StatusType | undefined;
    let disableUpdate: boolean = false;
    switch (status) {
      case REQUEST_STATUS.new: {
        newStatus = REQUEST_STATUS.quoted;
        break;
      }
      case REQUEST_STATUS.quoted: {
        if (!validateStartupApproval(totalPrice, wallet)) {
          setShowInsufficientFunds(true);
          disableUpdate = true;
        } else {
          newStatus = REQUEST_STATUS.pendingHUB71;
        }
        break;
      }
      case REQUEST_STATUS.pendingHUB71: {
        if (!validateHub71AdminApproval(wallet) && !showInsufficientFunds) {
          setShowInsufficientFunds(true);
          disableUpdate = true;
        } else {
          newStatus = REQUEST_STATUS.inProgress;
        }
        break;
      }
      default: {
        break;
      }
    }

    if (!disableUpdate && identity.isTruthyString(newStatus)) {
      actions.changeRequestStatus({ id: id!, component: 'requests', status: newStatus! });
      setShowInsufficientFunds(false);
    }
  }

  useEffect(() => {
    setShowInsufficientFunds(false);
  }, [details]);

  useEffect(() => {
    if (!isInProgress(status)) {
      actions.setShowInvoiceFields({ data: false });
    }
  }, [status]);

  const isEditable = useMemo(() => isServiceProvider(userType) && isNew(status), [status, userType]);
  const canServiceProviderApprove = useMemo(() => isServiceProvider(userType) && isNew(status), [status, userType]);
  const canStartupApprove = useMemo(() => isStartup(userType) && isQuoted(status), [status, userType]);
  const canHUB71Approve = useMemo(() => isEitherAdmin(userType) && isPendingHUB71(status), [status, userType]);
  const senderName = useMemo(() => getRequestSenderName(startup), [startup]);

  return (
    <div className="overview-tab-content" key={`overview-tab-content-${id}`}>
      <RenderIf condition={!isPartner(userType)}>
        <div className="overview-tab-content--container">
          <DataColumn label="Request Details">
            <Row>
              <Col span={3}>
                <h4>From</h4>
              </Col>
              <Col span={21} className="overview-tab__request-sender-recipient-text">
                <p>{senderName}</p>
              </Col>
            </Row>
            <RenderIf condition={identity.isTruthyString(serviceProvider?.name)}>
              <Row>
                <Col span={3}>
                  <h4>To</h4>
                </Col>
                <Col span={21} className="overview-tab__request-sender-recipient-text">
                  <p>{serviceProvider?.name}</p>
                </Col>
              </Row>
            </RenderIf>
          </DataColumn>
          {requestItems.map((item) => <RequestItemCard key={item.id} item={item} editable={isEditable} />) }
          <div className="overview-tab__total">
            <div className="overview-tab__total-text">Total</div>
            <div className="overview-tab__total-amount">{parseNumberToPrice(totalPrice || 0, 'AED')}</div>
          </div>
          <RenderIf condition={!isNew(status) && !isRejected(status)}>
            <>
              <AgreementField
                altText="No Work Order associated with this request"
                fileName="Work Order.pdf"
                fileUrl={details.workOrder}
              />
              <AgreementField
                fileName="Invoice.pdf"
                fileUrl={details.invoice}
              />
            </>
          </RenderIf>
          <RenderIf condition={isQuoted(status) && isServiceProvider(userType)}>
            <StaticNotification
              type="warning"
              message="Pending startup"
              description="Pending startup approval."
            />
          </RenderIf>
          <RenderIf condition={showInsufficientFunds}>
            <StaticNotification
              type="error"
              message="Insufficient funds"
              description={INSUFFICIENT_FUND_TEXT_BY_ROLE[userType]}
            />
          </RenderIf>
        </div>
      </RenderIf>
      <RenderIf condition={showInvoiceFields && isInProgress(status)}>
        <UploadInvoiceFields serviceRequest={requestItems[0]?.serviceRequest as ServiceRequestType} />
      </RenderIf>
      <RenderIf condition={[canStartupApprove, canServiceProviderApprove, canHUB71Approve].some(Boolean)}>
        <ReasonForDenying
          onDeny={onDeny}
          setDisplayApproveBtn={setDisplayApproveBtn}
          userType={userType}
          displayApproveBtn={displayApproveBtn}
          onApprove={onApprove}
          showInsufficientFunds={showInsufficientFunds}
        />
      </RenderIf>
      <RenderIf condition={isEitherAdmin(userType)
        && ![isRejected(status), isDelivered(status), isPendingHUB71(status)].some(Boolean)}
      >
        <div className="overview-tab__actions">
          <ButtonWithPrompt
            promptActiveClassName="danger-button"
            className="ghost-button"
            promptLabel="Reason for denying"
            promptPlaceholder="Write a reason..."
            onPromptDisplay={() => setDisplayApproveBtn(false)}
            onFinish={onDeny}
            onCancel={() => setDisplayApproveBtn(true)}
            promptActiveBtnText="Deny request"
          >
            {isStartup(userType) ? 'Cancel' : 'Deny'}
          </ButtonWithPrompt>
        </div>
      </RenderIf>
      <RenderIf condition={details.status === 'delivered'}>
        <PaymentStatusCard
          paymentAmount={details?.paymentAmount ? details?.paymentAmount : 0}
          paymentDate={details?.paymentDate ? moment(details?.paymentDate).format(formatSlashMDY) : 'NA'}
          paymentReference={details?.paymentReference ? details?.paymentReference : '#'}
          paymentProcessed={getPaymentProcessed(details?.paymentProcessed)}
        />
      </RenderIf>
    </div>
  )
}

export default OverviewTab;
