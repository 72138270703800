import React, { useMemo } from 'react';
import moment from 'moment';

import { identity } from 'utils';
import { ChevronRightIcon } from 'assets/icons';
import { EventProps } from 'types/componentTypes';
import { NOT_SPECIFIED } from 'components/CohortDetails/utils/constants';
import { EVENT_TYPE } from 'packages/events_repository';
import { EventButtons } from './components';
import { RenderIf } from '..';

import './Event.scss';

const Event = (props: EventProps) => {
  const {
    startingTime,
    name,
    endingTime,
    category,
    type,
    location,
    eventResponse,
    status,
  } = props.event;

  const EVENT_TYPE_COLORS = {
    [EVENT_TYPE['1']]: 'green',
    [EVENT_TYPE['2']]: 'purple',
    [EVENT_TYPE['3']]: 'blue',
  }

  const rightContent = useMemo(() => {
    switch (props.rightContent) {
      case 'chevron': {
        return <ChevronRightIcon />;
      }
      case 'buttons': {
        return <EventButtons eventStatus={status} eventResponse={eventResponse || 'not confirmed'} event={props.event} id={identity.isTruthyNumber(props.id) ? props?.id : props?.event.id} />;
      }
      default: {
        return null;
      }
    }
  }, [eventResponse]);

  return (
    <div className={`event-card event-card-${EVENT_TYPE_COLORS[type]}`}>
      <div className="event-card-left">
        <div className="event-card__date">
          <span className="event-card__date-day">{moment(startingTime).format('DD')}</span>
          <span className="event-card__date-month">{moment(startingTime).format('MMM')}</span>
        </div>
        <div className="event-card__details">
          <p className="event-card__title">{name}</p>
          <RenderIf condition={props.showLocation}>
            <span className="event-card__category">{location}</span>
          </RenderIf>
          <div className="event-card__info">
            <span className="event-card__category">{category.name || NOT_SPECIFIED}</span>
            <span className="event-card__time">
              {`${moment(startingTime).format('HH:mm')} - ${moment(endingTime).format('HH:mm')}`}
            </span>
          </div>
        </div>
      </div>
      {rightContent}
    </div>
  );
}

Event.defaultProps = {
  showLocation: false,
  rightContent: undefined,
}

export default Event;
