import { useReducer } from 'react';
import { UploadFile } from 'antd';

import { reducer, initialState } from './reducer';
import {
  setFileName as setFileNameAC,
  setFileSize as setFileSizeAC,
  setFileContent as setFileContentAC,
  setFileType as setFileTypeAC,
  setFiles as setFilesAC,
} from './actions';
import { DataPayload } from '../../../../../../../types/reduxTypes/ActionTypes';

const useDraggerUploadHook = () => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const setFileName = (payload: DataPayload<string>) => {
    dispatch(setFileNameAC(payload));
  }
  const setFileSize = (payload: DataPayload<string>) => {
    dispatch(setFileSizeAC(payload));
  }
  const setFileContent = (payload: DataPayload<string>) => {
    dispatch(setFileContentAC(payload));
  }
  const setFileType = (payload: DataPayload<string>) => {
    dispatch(setFileTypeAC(payload));
  }
  const setFiles = (payload: DataPayload<UploadFile[]>) => {
    dispatch(setFilesAC(payload));
  }

  return {
    state,
    setFileName,
    setFileContent,
    setFiles,
    setFileType,
    setFileSize,
  };
}

export default useDraggerUploadHook;
