import React, { useEffect, useState } from 'react';
import classNames from 'classnames';

import { RenderIf, ButtonWithPrompt, Input } from 'components/components';
import { StatusType } from 'types/requests';
import REQUEST_STATUS from 'utils/constants/requestStatusTypes';
import { RequestDetailsStatusProps } from 'types/componentTypes/Drawer';
import { isServiceProvider } from 'utils';
import { getStatusName, isInProgress } from 'components/RequestsOverview/utils/helpers';
import { STATUS_COLOR_CLASS } from 'components/RequestsOverview/components/RequestDetailsDrawer/utils/constants';
import {
  inProgressStatusOption,
  STATUS_OPTIONS_MAP,
  statusOptions,
  StatusOptionType,
} from '../../utils/constants';

const RequestDetailsStatus = (props: RequestDetailsStatusProps) => {
  const {
    userType,
    status,
    actions,
    requestId,
    showInvoiceFields,
  } = props;

  const [displayPrompt, setDisplayPrompt] = useState<boolean>(false);
  const [selectedStatus, setSelectedStatus] = useState(inProgressStatusOption);

  useEffect(() => {
    setDisplayPrompt(false);
  }, [status]);

  useEffect(() => {
    if (!showInvoiceFields && status !== REQUEST_STATUS.delivered) {
      setSelectedStatus(inProgressStatusOption);
    }
    if (status === REQUEST_STATUS.delivered) {
      setSelectedStatus(STATUS_OPTIONS_MAP[REQUEST_STATUS.delivered]);
    }
  }, [showInvoiceFields, status]);

  const onStatusChange = (value: StatusType) => {
    setDisplayPrompt(false);
    setSelectedStatus(STATUS_OPTIONS_MAP[value as StatusOptionType]);
    actions.setShowInvoiceFields({ data: value === REQUEST_STATUS.delivered });
  }

  const changeStatus = (reason: string) => {
    actions.changeRequestStatus({
      id: requestId, status: selectedStatus.value as StatusType, component: 'requests', reason,
    });
    setDisplayPrompt(false);
  }

  const onCancel = () => {
    setDisplayPrompt(false);
    setSelectedStatus(inProgressStatusOption);
  }

  const canBeDeliveredByUser = isInProgress(status) && isServiceProvider(userType);

  return (
    <div className="drawer-title__status--container">
      {!canBeDeliveredByUser ? (
        <p className="drawer-title__status">
          Status:
          <span className={classNames('drawer-title__status-value', STATUS_COLOR_CLASS(userType)[status])}>{getStatusName(status, userType)}</span>
        </p>
      )
        : (
          <div className="drawer-title__status-select">
            Status
            <span className={classNames('drawer-title__status-select-value', STATUS_COLOR_CLASS(userType)[status])}>
              <Input>
                <Input.Select
                  options={statusOptions}
                  className="drawer-title__selector"
                  onChange={onStatusChange}
                  value={selectedStatus}
                />
              </Input>
            </span>
          </div>
        )}
      <RenderIf condition={displayPrompt && canBeDeliveredByUser}>
        <ButtonWithPrompt
          defaultOpen
          onFinish={changeStatus}
          promptLabel={`Reason for marking as ${getStatusName(selectedStatus.value as StatusType, userType)}`}
          className="primary-button"
          promptActiveClassName="primary-button"
          onCancel={onCancel}
        >
          {`Mark as ${getStatusName(selectedStatus.value as StatusType, userType)}`}
        </ButtonWithPrompt>
      </RenderIf>
      {/* NOTE: removed for now: until client defines the status between in progress and delivered in order to be able to implement
      the ability for startup to confirm delivered status.
       <RenderIf condition={isDelivered(status)}>
        <StaticNotification
          type="info"
          message="Marked as Delivered"
          description="Waiting for startup to confirm status and review."
        />
      </RenderIf> */}
    </div>
  );
}

export default RequestDetailsStatus;
