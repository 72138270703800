import React, { useEffect, useState } from 'react'
import { Button, Tabs, Spin } from 'antd';

import { UserType } from 'packages/user_repository/types/users';
import { RowTable } from 'components/components/Table/components';
import { EventAttendeeColumns } from 'components/utils/constants/columns';
import { EditDrawerProps, EventDetailsTabs } from 'types/events/eventsProps';
import { DRAWERS, isEitherAdmin } from 'utils';
import { DeleteConfirmModal, RenderIf } from 'components/components';
import { RSVPButton } from 'components/components/Event/components';
import EventDetailsOverview from './components/EventOverview';
import { TABS } from './utils/constants';

import './EditEventDrawer.scss';

const EditEventDrawer = (props: EditDrawerProps) => {
  const {
    actions, id, eventAttendees, isLoading, event,
  } = props;
  const [open, setOpen] = useState<boolean>(false);

  useEffect(
    () => {
      actions.setActiveTab({ tab: TABS.overview });
      actions.getEventAttendeesByEventId({ id })
    },
    [id],
  );

  useEffect(() => {
    actions.getEventById({ id });
  }, [])

  const onTabChange = (tab: string) => {
    actions.setActiveTab({ tab: tab as EventDetailsTabs });
  }

  const eventUsers: UserType[] = [];
  eventAttendees.map((item) => eventUsers.push(item.user))

  const handleDelete = () => {
    actions.deleteEventById({ id })
  }

  const isPastEvent = event?.status === 'past';

  const onEditButton = () => {
    actions.setDrawerBody({ drawerBody: DRAWERS.eventInfo });
    actions.setIsDrawerOpen({ isDrawerOpen: true });
    actions.setEventId(({ data: props.id }));
  }

  return (
    <div className="edit-event-drawer">
      <Spin spinning={isLoading}>
        <RenderIf condition={isEitherAdmin(props.userType)}>
          <div>
            <Tabs onChange={onTabChange} activeKey={props.activeTab} color="green">
              <Tabs.TabPane
                tab={TABS.overview}
                key={TABS.overview}
              >
                <EventDetailsOverview />
              </Tabs.TabPane>
              <Tabs.TabPane
                tab={TABS.attendees}
                key={TABS.attendees}
              >
                <RowTable
                  dataSource={eventUsers}
                  columns={EventAttendeeColumns}
                  className="table"
                />
              </Tabs.TabPane>
            </Tabs>
            <div className="submit__wrap">
              <Button type="primary" className="secondary-delete-button" onClick={() => setOpen(true)}>
                Delete event
              </Button>
              <RenderIf condition={!isPastEvent}>
                <Button type="primary" htmlType="submit" className="primary-button" onClick={onEditButton}>
                  Edit event
                </Button>
              </RenderIf>
            </div>
          </div>
        </RenderIf>
        <RenderIf condition={!isEitherAdmin(props.userType)}>
          <>
            <EventDetailsOverview />
            <div className="event-buttons__container">
              <RSVPButton
                eventStatus={event?.status!}
                eventResponse={event?.eventResponse!}
                id={event?.eventRsvpId!}
                event={event!}
              />
            </div>
          </>
        </RenderIf>
        <DeleteConfirmModal
          isOpen={open}
          onCancel={() => setOpen(false)}
          itemName={event?.name!}
          onConfirm={handleDelete}
        />
      </Spin>
    </div>
  )
}
export default EditEventDrawer;
