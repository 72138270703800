import React, { useCallback, useEffect, useState } from 'react'
import { RadioChangeEvent } from 'antd';

import { StepCustomFieldComponentProps } from 'types/componentTypes/StepsForm';
import { FormBuilder, Input, RenderIf } from 'components/components';
import { Row } from 'components/components/FormBuilder/components';
import { investmentInformationFields, yesNoOptions } from '../../utils/formFields';
import AddToList from '../../../../../components/AddToList';
import { yesNoValueToBoolean } from '../../utils/helpers';

const InvestmentInformationFields = (props: StepCustomFieldComponentProps) => {
  const { form } = props;
  const interestedName = 'interestedInInternational';
  const [interested, setInterested] = useState(Boolean(Number((form?.getFieldValue(interestedName)))));

  const onRadioChange = useCallback((e: RadioChangeEvent) => {
    setInterested(Boolean(Number(e.target.value)));
  }, []);

  useEffect(() => () => {
    if (!yesNoValueToBoolean(form?.getFieldValue(interestedName))) {
      form?.setFieldValue('internationalMarkets', undefined);
    }
  }, []);

  return (
    <>
      <AddToList
        form={form!}
        name="investors"
        label="Existing investors"
        values={form?.getFieldValue('investors')}
      />
      <FormBuilder
        fields={investmentInformationFields}
        formFragment
        oneColumn
      />
      <Row oneColumn>
        <Input
          layout="vertical"
          label="Are you interested in exploring international markets? "
          name={interestedName}
        >
          <Input.Radio
            values={yesNoOptions}
            defaultValue={form?.getFieldValue(interestedName) || '0'}
            onChange={onRadioChange}
          />
        </Input>
      </Row>
      <RenderIf condition={interested}>
        <Row oneColumn>
          <Input
            layout="vertical"
            label="Please name them with the value proposition for each "
            name="internationalMarkets"
          >
            <Input.InputField.TextArea rows={4} />
          </Input>
        </Row>
      </RenderIf>
    </>
  )
};

export default InvestmentInformationFields;
