import { NotificationType } from 'packages/notification_repository';
import { DataPayload, IsLoadingPayload } from 'types/reduxTypes/ActionTypes';

export const types = {
  TOGGLE_NOTIFICATIONS: 'TOGGLE_NOTIFICATIONS',
  GET_NOTIFICATIONS: 'GET_NOTIFICATIONS',
  SET_NOTIFICATIONS: 'SET_NOTIFICATIONS',
  RESET_NOTIFICATIONS: 'RESET_NOTIFICATIONS',
  SET_IS_LOADING_NOTIFICATIONS: 'SET_IS_LOADING_NOTIFICATIONS',
  SET_OFFSET_NOTIFICATIONS: 'SET_OFFSET_NOTIFICATIONS',
  SET_ORDER_BY_NOTIFICATIONS: 'SET_ORDER_BY_NOTIFICATIONS',
  SET_NOTIFICATIONS_SEEN: 'SET_NOTIFICATIONS_SEEN',
  GET_NEW_NOTIFICATIONS_COUNT: 'GET_NEW_NOTIFICATIONS_COUNT',
  SET_NEW_NOTIFICATIONS_COUNT: 'SET_NEW_NOTIFICATIONS_COUNT',
  SET_LIMIT_NOTIFICATIONS: 'SET_LIMIT_NOTIFICATIONS',
  SET_ALL_FETCHED: 'SET_ALL_FETCHED',
  RESET_NOTIFICATION: 'RESET_NOTIFICATION',
}

export const resetNotification = (payload: object = {}) => ({
  type: types.RESET_NOTIFICATION,
  payload,
});

export const toggleNotifications = (payload: object = {}) => ({
  type: types.TOGGLE_NOTIFICATIONS,
  payload,
});

export const getNotifications = (payload: object = {}) => ({
  type: types.GET_NOTIFICATIONS,
  payload,
});

export const setIsLoading = (payload: IsLoadingPayload) => ({
  type: types.SET_IS_LOADING_NOTIFICATIONS,
  payload,
});

export const setNotifications = (payload: DataPayload<NotificationType[]>) => ({
  type: types.SET_NOTIFICATIONS,
  payload,
});

export const setNextPage = (payload: object = {}) => ({
  type: types.SET_OFFSET_NOTIFICATIONS,
  payload,
});

export const resetNotifications = (payload: object = {}) => ({
  type: types.RESET_NOTIFICATIONS,
  payload,
});

export const setOrderBy = (payload: DataPayload<string>) => ({
  type: types.SET_ORDER_BY_NOTIFICATIONS,
  payload,
});

export const getNewNotificationsCount = (payload: object = {}) => ({
  type: types.GET_NEW_NOTIFICATIONS_COUNT,
  payload,
});

export const setNotificationsSeen = (payload: object = {}) => ({
  type: types.SET_NOTIFICATIONS_SEEN,
  payload,
});

export const setNewNotificationsCount = (payload: DataPayload<number>) => ({
  type: types.SET_NEW_NOTIFICATIONS_COUNT,
  payload,
});

export const setLimit = (payload: DataPayload<number>) => ({
  type: types.SET_LIMIT_NOTIFICATIONS,
  payload,
});

export const setAllFetched = (payload: DataPayload<boolean>) => ({
  type: types.SET_ALL_FETCHED,
  payload,
});
