import { PriceType } from 'packages/service_repository';

const getServicePrice = (offerPrice: 'range' | 'fixed' | '-', priceFrom: string, priceTo: string, priceType: PriceType = '') => {
  if (offerPrice === 'range') {
    return `${priceFrom} - ${priceTo} ${priceType.replace('-', ' ')}`;
  }

  return `${priceFrom} ${priceType.replace('-', ' ')}`;
}

export default getServicePrice;
