/* eslint-disable @typescript-eslint/no-explicit-any */
import { PartnerBeType, PartnerType } from 'packages/partner_repository';
import { SetPartnerTeamMembersPayload } from 'types/partners/addPartner';
import {
  SetCurrentPartnerTabPayload,
  SendNewGetInTouchPartnerRequestPayload,
  GetInTouchPayload,
  SetPartnerRequestPayload,
} from 'types/reduxTypes/ActionTypes/PartnersTypes';
import { ChangePartnerRequestStatusPayload } from 'types/reduxTypes/ActionTypes/RequestsTypes';
import { PartnerMemberType } from 'packages/people_repository/types/partner_member_type';
import { DataPayload, IdPayload, IsLoadingPayload } from '../../../types/reduxTypes/ActionTypes';
import { ParamsPayload } from '../../../packages/http_client';

export const types = {
  GET_ALL_PARTNERS: 'GET_ALL_PARTNERS',
  GET_PARTNER_BY_ID: 'GET_PARTNER_BY_ID',
  SET_ALL_PARTNERS: 'SET_ALL_PARTNERS',
  SET_CURRENT_PARTNER_TAB: 'SET_CURRENT_PARTNER_TAB',
  ADD_PARTNER: 'ADD_PARTNER',
  SET_PARTNER_DETAILS: 'SET_PARTNER_DETAILS',
  UPDATE_PARTNER_BY_ID: 'UPDATE_PARTNER_BY_ID',
  SET_IS_PARTNER_LOADING: 'SET_IS_PARTNER_LOADING',
  GET_PARTNER_TEAM_MEMBERS: 'GET_PARTNER_TEAM_MEMBERS',
  SET_PARTNER_TEAM_MEMBERS: 'SET_PARTNER_TEAM_MEMBERS',
  SEND_PARTNER_GET_IN_TOUCH_REQUEST: 'SEND_PARTNER_GET_IN_TOUCH_REQUEST',
  GET_IN_TOUCH: 'GET_IN_TOUCH',
  SET_PARTNER_DATA: 'SET_PARTNER_DATA',
  GET_PARTNER_REQUEST_BY_ID: 'GET_PARTNER_REQUEST_BY_ID',
  SET_PARTNER_REQUEST_BY_ID: 'SET_PARTNER_REQUEST_BY_ID',
  SET_PARTNER_REQUEST_STATUS_CHANGE: 'SET_PARTNER_REQUEST_STATUS_CHANGE',
  ADD_PARTNER_MEMBER: 'ADD_PARTNER_MEMBER',
  RESET_PARTNER: 'RESET_PARTNER',
}

export const resetPartner = (payload: object = {}) => ({
  type: types.RESET_PARTNER,
  payload,
});

export const getPartners = (payload: ParamsPayload = {}) => ({
  type: types.GET_ALL_PARTNERS,
  payload,
});

export const setPartnerData = (payload: DataPayload<PartnerType>) => ({
  type: types.SET_PARTNER_DATA,
  payload,
});

export const getInTouch = (payload: GetInTouchPayload) => ({
  type: types.GET_IN_TOUCH,
  payload,
});

export const setPartners = (payload: DataPayload<PartnerType[]>) => ({
  type: types.SET_ALL_PARTNERS,
  payload,
});

export const setCurrentPartnerTab = (payload: SetCurrentPartnerTabPayload) => ({
  type: types.SET_CURRENT_PARTNER_TAB,
  payload,
});

export const addNewPartner = (payload: PartnerBeType) => ({
  type: types.ADD_PARTNER,
  payload,
});

export const getPartnerById = (payload: IdPayload) => ({
  type: types.GET_PARTNER_BY_ID,
  payload,
});

export const setPartnerDetails = (payload: DataPayload<PartnerType>) => ({
  type: types.SET_PARTNER_DETAILS,
  payload,
})

export const updatePartner = (payload: DataPayload<PartnerType>) => ({
  type: types.UPDATE_PARTNER_BY_ID,
  payload,
});

export const setIsLoading = (payload: IsLoadingPayload) => ({
  type: types.SET_IS_PARTNER_LOADING,
  payload,
});

export const getPartnerTeamMembers = (payload: IdPayload) => ({
  type: types.GET_PARTNER_TEAM_MEMBERS,
  payload,
});

export const setPartnerTeamMembers = (payload: SetPartnerTeamMembersPayload) => ({
  type: types.SET_PARTNER_TEAM_MEMBERS,
  payload,
});

export const sendGetInTouchPartnerRequest = (payload: SendNewGetInTouchPartnerRequestPayload) => ({
  type: types.SEND_PARTNER_GET_IN_TOUCH_REQUEST,
  payload,
});

export const getPartnerRequestById = (payload: IdPayload) => ({
  type: types.GET_PARTNER_REQUEST_BY_ID,
  payload,
});

export const setPartnerRequestById = (payload: SetPartnerRequestPayload) => ({
  type: types.SET_PARTNER_REQUEST_BY_ID,
  payload,
});

export const setPartnerRequestStatusChange = (payload: ChangePartnerRequestStatusPayload) => ({
  type: types.SET_PARTNER_REQUEST_STATUS_CHANGE,
  payload,
});

export const addNewPartnerMember = (payload: PartnerMemberType) => ({
  type: types.ADD_PARTNER_MEMBER,
  payload,
});
