import React from 'react';

import SVGWrap from './SVGWrap';

const DesignIconV2 = () => (
  <SVGWrap size={24} fill="none">
    <path d="M17.8537 8.19172L7.52322 18.5221L7.10135 18.8924L5.10451 19.7485L4.25198 18.8954L5.10744 16.8985L5.47774 16.4767L15.8082 6.14626M17.8537 8.19172L15.8082 6.14626M17.8537 8.19172L20.467 5.5785C20.6128 5.43202 20.6128 5.19414 20.467 5.04824L18.9517 3.53304C18.8808 3.46215 18.7871 3.42289 18.6869 3.42289C18.5867 3.42289 18.4924 3.46215 18.4221 3.53304L15.8082 6.14626M3.21079 21C3.15571 21 3.1018 20.9783 3.06196 20.9385C3.00103 20.8775 2.98345 20.785 3.01685 20.7065L4.77463 16.605C4.78518 16.5804 4.80041 16.5575 4.81975 16.5388L18.1232 3.23481C18.2732 3.08423 18.4736 3.00161 18.6863 3.00161C18.899 3.00161 19.1 3.08423 19.2506 3.23481L20.7658 4.75001C21.0757 5.06055 21.0757 5.56561 20.7658 5.87674L7.46111 19.1807C7.44236 19.1995 7.42009 19.2147 7.3949 19.2252L3.2934 20.983C3.26704 20.9947 3.23891 21 3.21079 21ZM4.07327 19.3125L3.61273 20.3871L4.68674 19.9266L4.07327 19.3125ZM17.859 21C17.8051 21 17.7506 20.9795 17.7096 20.9385L12.4362 15.6652L12.7345 15.3663L13.4639 16.0964L14.7793 14.7804L15.0782 15.0792L13.7628 16.3946L14.9293 17.5612L15.6588 16.8312L15.9577 17.13L15.2276 17.8595L16.3942 19.026L17.7096 17.71L18.0084 18.0089L16.6924 19.3243L17.859 20.4908L20.4904 17.8595L15.3659 12.7356L15.6647 12.4373L20.938 17.7106C21.0207 17.7932 21.0207 17.9268 20.938 18.0094L18.0084 20.9391C17.9674 20.9795 17.9129 21 17.859 21ZM8.33531 11.5631L3.06196 6.28981C2.97935 6.2072 2.97935 6.07419 3.06196 5.99158L5.9916 3.06196C6.07422 2.97935 6.20722 2.97935 6.28984 3.06196L11.5632 8.33527L11.265 8.6335L6.14043 3.50961L3.50902 6.14099L4.67561 7.30756L5.9916 5.99216L6.28984 6.2904L4.97384 7.6058L6.14043 8.77237L6.87049 8.04289L7.16873 8.34113L6.43866 9.0706L7.60525 10.2372L8.92124 8.92178L9.21948 9.22001L7.90348 10.5354L8.63296 11.2655L8.33531 11.5631Z" strokeWidth="0.585926" />
  </SVGWrap>
);

export default DesignIconV2;
