import React, { useState } from 'react';
import { Button } from 'antd';

import { EditIcon, TrashCanIcon } from 'assets/icons';
import { TableActionProps } from 'types/reduxTypes/ActionTypes/SettingsOverviewTypes';
import { DeleteConfirmModal } from 'components/components';

import './TableActions.scss';

const TableActions = (props: TableActionProps) => {
  const {
    onDelete, onUpdate, name, disabled,
  } = props;

  const [open, setOpen] = useState<boolean>(false);

  const onConfirm = () => {
    onDelete!();
    setOpen(false);
  }

  return (
    <div className="table-action-buttons">
      <Button className="ghost-button" disabled={disabled} icon={<EditIcon />} onClick={onUpdate}>Edit</Button>
      <div className="divider" />
      <Button className="ghost-button delete" disabled={disabled} onClick={() => setOpen(true)} icon={<TrashCanIcon />}>Delete</Button>
      <DeleteConfirmModal
        isOpen={open}
        onCancel={() => setOpen(false)}
        itemName={name}
        onConfirm={onConfirm}
      />
    </div>
  )
}

export default TableActions
