import { DataPayload } from 'types/reduxTypes/ActionTypes';
import { UnitType } from 'packages/service_repository';
import { PricingOption } from 'types/services';

export const types = {
  SET_OFFER_DOC: 'SET_OFFER_DOC',
  SET_PRICE_UNIT: 'SET_PRICE_UNIT',
  SET_PRICING_OPTIONS: 'SET_PRICING_OPTIONS',
  SET_PRICING_OPTIONS_ERROR: 'SET_PRICING_OPTIONS_ERROR',
  SET_SERVICE_ACTIVE: 'SET_SERVICE_ACTIVE',
}

export const setOfferDoc = (payload: DataPayload<string | Blob>) => ({
  type: types.SET_OFFER_DOC,
  payload,
});

export const setPriceUnit = (payload: DataPayload<UnitType>) => ({
  type: types.SET_PRICE_UNIT,
  payload,
});

export const setPricingOptions = (payload: DataPayload<PricingOption[]>) => ({
  type: types.SET_PRICING_OPTIONS,
  payload,
});

export const setPricingOptionsError = (payload: DataPayload<boolean[]>) => ({
  type: types.SET_PRICING_OPTIONS_ERROR,
  payload,
});

export const setServiceActive = (payload: DataPayload<boolean>) => ({
  type: types.SET_SERVICE_ACTIVE,
  payload,
});
