import React from 'react';
import { Viewer } from '@react-pdf-viewer/core';
import { Button } from 'antd';

import { AcceptanceLetterPreviewProps } from 'types/addDetails';
import { downloadButton } from '../../../../../utils/constants/buttons';
import AcceptedLetter from '../AcceptedLetter';
import { Modal } from '../../../../../components';

import '@react-pdf-viewer/core/lib/styles/index.css';
import './AcceptanceLetterPreview.scss';

const AcceptanceLetterPreview = (props: AcceptanceLetterPreviewProps) => {
  const {
    isOpen, setIsOpen, showFooter, letterAcceptance, fileUrl,
  } = props;

  return (
    <Modal
      className="ac-letter-preview"
      open={isOpen}
      onCancel={() => setIsOpen(false)}
      title="Acceptance Letter"
      buttons={downloadButton(fileUrl || '', 'pdf')}
      showButton
      footer={showFooter ? (
        <div className="footer__container">
          {letterAcceptance
            ? <AcceptedLetter />
            : (
              <Button className="primary-button">
                I accept this acceptance letter
              </Button>
            )}
        </div>
      ) : null}
    >
      <div className="al-pdf-preview__container">
        <Viewer fileUrl={fileUrl!} />
      </div>
    </Modal>
  );
}

AcceptanceLetterPreview.defaultProps = {
  showFooter: true,
}

export default AcceptanceLetterPreview;
