import { DataPayload } from 'types/reduxTypes/ActionTypes';
import { ServicesFiltersType } from 'types/services';

export const types = {
  RESET_FILTERS: 'RESET_FILTERS',
  SET_SERVICE_PROVIDERS: 'SET_SERVICE_PROVIDERS',
  SET_CATEGORIES: 'SET_CATEGORIES',
  SET_OFFER_PRICE: 'SET_OFFER_PRICE',
  SET_FILTERS: 'SET_FILTERS',
}

export const resetFilters = (payload: object = {}) => ({
  type: types.RESET_FILTERS,
  payload,
});

export const setServiceProviders = (payload: DataPayload<number[]>) => ({
  type: types.SET_SERVICE_PROVIDERS,
  payload,
});

export const setCategories = (payload: DataPayload<number[]>) => ({
  type: types.SET_CATEGORIES,
  payload,
});

export const setOfferPrice = (payload: DataPayload<0 | 1 | 2>) => ({
  type: types.SET_OFFER_PRICE,
  payload,
});

export const setFilters = (payload: ServicesFiltersType) => ({
  type: types.SET_FILTERS,
  payload,
});
