import { AxiosError } from 'axios';
import { httpsLinkRegex } from 'utils/constants/regexPatterns';

import identity from 'utils/identity';
import { ApiErrorResponseType, ErrorResponseType } from '../types';

const beautifyErrorMessage = (errorMessage?: string) => {
  if (identity.isFalsy(errorMessage)) {
    return '';
  }

  const message = errorMessage!.trim();
  if (identity.isEmptyString(message)) {
    return '';
  }

  // message will always have length of at least 1
  const firstLetter = message.charAt(0).toUpperCase();
  return firstLetter + (message.length > 1 ? message.slice(1) : '');
};

export const convertAxiosError = (axiosError: AxiosError) => {
  const errorResponse: ErrorResponseType = {
    message: 'There was a network error. Please try again',
    code: axiosError.code!,
    status: axiosError.status!,
  };

  return errorResponse;
};

export const convertApiError = (apiError: ApiErrorResponseType) => {
  const errorResponse: ErrorResponseType = {
    message: beautifyErrorMessage(apiError.err_msg),
    code: apiError.session!,
    status: apiError.status!,
  };
  return errorResponse;
};

export const formatUrl = (url?: string) => {
  if (!identity.isTruthyString(url) || httpsLinkRegex.test(url!)) {
    return url;
  }

  return `https://${url}`;
}
