import React from 'react';

import SVGWrap from './SVGWrap';

const ListIcon = () => (
  <SVGWrap>
    <path d="M14 3H5V4H14V3Z" />
    <path d="M14 12H5V13H14V12Z" />
    <path d="M14 7.5H5V8.5H14V7.5Z" />
    <path d="M3 7.5H2V8.5H3V7.5Z" />
    <path d="M3 3H2V4H3V3Z" />
    <path d="M3 12H2V13H3V12Z" />
  </SVGWrap>
);

export default ListIcon;
