import produce from 'immer';

import { CohortStateType } from 'types/reduxTypes/StateTypes';
import {
  CohortActionType,
  SetCohortPayload,
  SetCohortsPayload,
  SetProgramsPayload,
} from 'types/reduxTypes/ActionTypes/CohortTypes';
import { SetStartupListPayload, SetStartupsPayload } from 'types/reduxTypes/ActionTypes/StartupsTypes';
import { DataPayload, IsLoadingPayload } from 'types/reduxTypes/ActionTypes';
import { types } from '../../actions/cohort';
import { cohortDefaultState } from '../../utils';

const defaultState: CohortStateType = cohortDefaultState;

const handleSetCohortsCount = (state: CohortStateType, payload: DataPayload<number>) => {
  state.cohortsCount = payload.data;
  return state;
}

const handleSetCohorts = (state: CohortStateType, payload: SetCohortsPayload) => {
  state.cohorts = payload.cohorts;
  return state;
}

const handleSetCohortById = (state: CohortStateType, payload: SetCohortPayload) => {
  state.selectedCohort.data = payload.cohort;
  return state;
}

const handleSetCohortStartups = (state: CohortStateType, payload: SetStartupsPayload) => {
  state.selectedCohort.startups = payload.startups;
  return state;
}

const handleSetDocumentReviewRequests = (state: CohortStateType, payload: SetStartupListPayload) => {
  state.selectedCohort.data.reviewStartups = payload.startups;
  return state;
}

const handleSetPrograms = (state: CohortStateType, payload: SetProgramsPayload) => {
  state.programs = payload.programs;
  return state;
}

const handleSetLoading = (state: CohortStateType, payload: IsLoadingPayload) => {
  state.isLoading = payload.isLoading;
  return state;
}

const handleSetProgramCohorts = (state: CohortStateType, payload: SetCohortsPayload) => {
  state.programCohorts = payload.cohorts;
  return state;
}

// NOTE: produce recipe accepts state as first parameter, and we need to set its default value. Since eslint requires that default assignments are last, we need to disable this rule only in this case.
// eslint-disable-next-line default-param-last
export default produce((state: CohortStateType = defaultState, action: CohortActionType) => {
  const { type, payload } = action;
  switch (type) {
    case types.SET_COHORTS: {
      return handleSetCohorts(state, <SetCohortsPayload>payload);
    }
    case types.SET_COHORT_BY_ID: {
      return handleSetCohortById(state, <SetCohortPayload>payload);
    }
    case types.SET_COHORT_STARTUPS: {
      return handleSetCohortStartups(state, <SetStartupsPayload>payload);
    }
    case types.SET_DOCUMENT_REVIEW_REQUESTS: {
      return handleSetDocumentReviewRequests(state, <SetStartupListPayload> payload);
    }
    case types.SET_PROGRAMS: {
      return handleSetPrograms(state, <SetProgramsPayload>payload);
    }
    case types.SET_COHORTS_IS_LOADING: {
      return handleSetLoading(state, <IsLoadingPayload>payload);
    }
    case types.SET_PROGRAM_COHORTS: {
      return handleSetProgramCohorts(state, <SetCohortsPayload>payload);
    }
    case types.SET_COHORTS_COUNT: {
      return handleSetCohortsCount(state, <DataPayload<number>>payload);
    }
    case types.RESET_COHORT: {
      return defaultState;
    }
    default: {
      return state;
    }
  }
});
