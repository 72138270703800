import { WalletStateType } from 'types/reduxTypes/StateTypes';

export const defaultWalletState: WalletStateType = {
  wallet: {
    id: 0,
    startupID: 0,
    balance: '0',
    totalWalletAmount: '0',
    consumedAmount: '0',
    blockedAmount: '0',
  },
  walletTransactions: [],
  isLoading: false,
  count: 0,
}

export default null;
