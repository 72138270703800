/* eslint-disable react/jsx-props-no-spreading, @typescript-eslint/no-explicit-any */
/*
  NOTE: linter is disabled for this file because we need to pass all props to Antd Upload. So instead of writing each
  prop line by line, we spread the props inside of Antd Upload component. It will not be possible for this component
  to receive any not allowed props, since the component itself accepts the same props as Antd Upload without children.
*/
import React, { useState } from 'react';
import { Button, Upload } from 'antd';
import ImgCrop from 'antd-img-crop';

import { identity } from 'utils';
import { UploadImageProps } from 'types/componentTypes';
import { AddImageIcon } from 'assets/icons';
import extractImageProps from './utils';

import '../../Input.scss';
import './UploadImage.scss';

const UploadImage = (props: UploadImageProps) => {
  const [fileList, setFileList] = useState([]);

  const onChange = (res: any) => {
    setFileList(res.fileList);
  };

  const uploadProps = extractImageProps(props);

  return (
    <ImgCrop
      shape={props.shape}
      aspect={16 / 9}
      minZoom={0.4}
      maxZoom={props.maxZoom}
      zoom={props.zoom}
      rotate={props.rotate}
      cropperProps={{ aspect: 16 / 9, restrictPosition: false }}
      modalTitle="Crop image"
      modalClassName="crop-image"
    >
      <Upload
        {...uploadProps}
        name="avatar"
        listType="picture-card"
        onChange={onChange}
        accept=".jpg,.png,.jpeg"
      >
        {identity.isEmptyArray(fileList) && <Button className="upload-image"><AddImageIcon /></Button>}
      </Upload>
    </ImgCrop>
  );
}

UploadImage.defaultProps = {
  shape: 'rect',
  aspect: 1,
  zoom: true,
  rotate: false,
  minZoom: 1,
  maxZoom: 3,
}

export default UploadImage;
