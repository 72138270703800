/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useCallback } from 'react'
import classnames from 'classnames';
import { Button } from 'antd';

import { RenderIf } from 'components/components';
import { identity } from 'utils';
import IconWrapper from 'components/LayoutWithSidebar/components/Sidebar/IconWrapper';
import { IconPlaceholder, ListIcon, ChevronRightIcon } from 'assets/icons';
import { FilterIconType, FiltersTabButtonProps, ServiceCategoryType } from 'types/services';

const FilterTabButton = (props: FiltersTabButtonProps) => {
  const { setFilter, datasource, icons } = props;

  const scrollRight = () => {
    let scrollPosition = document.getElementById('buttonRow')?.scrollLeft;
    if (scrollPosition || scrollPosition === 0) {
      scrollPosition += 80;
      document
        .getElementById('buttonRow')
        ?.scroll({ left: scrollPosition, behavior: 'smooth' });
    }
  };

  const renderIcon = useCallback((item: ServiceCategoryType) => {
    const filterIcon = identity.isTruthyString(item.name)
      ? icons.find((icon: FilterIconType) => icon.names.includes(item.name!.toLowerCase()))
      : { icon: null };
    const hasIcon = identity.isObjWithChildren(filterIcon) && !identity.isFalsy(filterIcon.icon);
    return hasIcon ? filterIcon.icon : <IconPlaceholder />;
  }, [icons]);

  return (
    <div className="filters-tab-overview-container">
      <div className="filters-tab-overview" id="buttonRow">
        <div className="filters-tab-overview__filters-overview-tabs">
          <div className="filters-overview-tabs-container">
            <div
              className={classnames('filters-overview-tabs-container__tab', { 'active-tab':  identity.isEmptyArray(props.activeFilters) })}
              key="all"
              onClick={() => setFilter!({ data: 'all' })}
            >
              <IconWrapper>
                {identity.isObjWithChildren(props?.icons[0]!) ? props?.icons[0].icon! : <ListIcon />}
              </IconWrapper>
              <p className="tab-title">all</p>
            </div>
            {datasource?.map((item: ServiceCategoryType) => (
              <div
                key={item.id}
                className={classnames('filters-overview-tabs-container__tab', { 'active-tab': (props.activeFilters || []).includes(item.id) })}
                onClick={() => setFilter!({ data: item.id })}
              >
                <IconWrapper>
                  {renderIcon(item)}
                </IconWrapper>
                <RenderIf condition={identity.isTruthyString(item.name)}>
                  <p className="tab-title">{item.name}</p>
                </RenderIf>
              </div>
            ))}
          </div>
        </div>
      </div>
      <RenderIf condition={datasource?.length! >= 7}>
        <div className="button-container">
          <div className="arrow-button-container">
            <Button className="arrow-button" onClick={() => scrollRight()}>
              <IconWrapper><ChevronRightIcon /></IconWrapper>
            </Button>
          </div>
        </div>
      </RenderIf>
    </div>
  );
}

FilterTabButton.defaultProps = {
  currentTab: 'All',
  setFilter: () => null,
}

export default FilterTabButton;
