import React from 'react';

import { TextProps } from '../../../types/componentTypes/Text';

import './Text.scss';

/**
 * @component Text
 * Renders Text component
 * Size refers to the font size of title. Default value is (s).
 *
 * (xxs): 14px
 *
 * (xs): 16px
 *
 * (s): 18px
 *
 * (m): 22px
 *
 * (l): 31px
 * @param props
 * @constructor
 */
const Text = (props: TextProps) => {
  const { text, size, weight } = props;

  return (
    <p className={`text text-${size} text-${weight!}`}>{text}</p>
  );
}

Text.defaultProps = {
  size: 's',
  weight: 700,
}

export default Text;
