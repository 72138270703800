import React from 'react'

import DataContainer from '../components/DataContainer';
import StatCards from '../components/StatCards';
import InfoTrack from '../InfoTrack';
import WelcomeHeader from './components/WelcomeHeader';
import StartupWallet from '../components/StartupWallet';
import { mockSubmitOverviewStats } from '../utils/mocks/mockSubmitOverviewStats';

import './SubmitOverview.scss';

const SubmitOverview = () => (
  <div className="submit-overview content-container">
    <InfoTrack title="Congratulations!" description="Your account has been created successfully" />
    <WelcomeHeader />
    <StartupWallet
      wallet={{
        blockedAmount: 'AED 0.00',
        balance: 'AED 500,000.00',
        consumedAmount: 'AED 0.00',
        totalWalletAmount: 'AED 0.00',
      }}
      showTransactionButton
    />
    <DataContainer
      title="Requests"
      noDataText="requests"
      buttonText="request"
    />
    <DataContainer
      title="Team Members"
      noDataText="members in your team"
      buttonText="member"
    />
    <StatCards cards={mockSubmitOverviewStats} />
  </div>
)

export default SubmitOverview;
