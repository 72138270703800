import {
  all,
  call,
  select,
  put,
  takeLatest,
} from 'redux-saga/effects';
import moment from 'moment';
import { PayloadAction } from '@reduxjs/toolkit';

import config from 'assets/config';
import { identity, isEitherAdmin } from 'utils';
import { DataPayload, IdPayload } from 'types/reduxTypes/ActionTypes';
import { selectIsOpen } from 'redux/selectors/workspace';
import { setDestroyDrawerOnClose, setIsDrawerLoading, setIsDrawerOpen } from 'redux/actions/workspace';
import showNotification from 'services/utils/showNotification';
import {
  EVENT_RSVP_RESPONSE_TO_NUMBER,
  EVENT_STATUS_TO_NUMBER,
  getEvents,
  getEventsCount,
  getEventsForUser,
  getEventsRSVPCount,
  createEvent,
  CreateEventAttendeePayload,
  CreateEventPayload,
  getEventsCategories,
  createEventAttendeeList,
  createEventRSVP,
  CreateEventRSVPRequestPayload,
  getEventById,
  deleteEventById,
  getEventAttendessList,
  // EventType,
  updateEventById,
  getEventRSVP,
  EventRSVPBeType,
  updateEventRSVPById,
  getRSVPEventById,
  UpdateEventPayload,
} from 'packages/events_repository';
import { ParamsPayload } from 'packages/http_client';
import {
  EventsFilters,
  EventsQuery,
  EventState,
  EventSubTab,
} from 'types/events';
import { UserType } from 'types/auth';
import { formatDashYMD } from 'components/utils/constants/formatters';
import {
  setEvents,
  setEventsCount,
  setEventsResponseCount,
  setIsLoading,
  types,
  setEventCategories,
  getEvents as getEventsAction,
  setEventDetails,
  setEventAttendeesByEventId,
  setEventRSVP,
} from '../../actions/events';
import { selectEventFilters, selectEventsCurrentSubTab, selectEventsCurrentTab } from '../../selectors/events';
import { selectUser, selectUserId } from '../../selectors/auth';

function * handleGetEvents(action: PayloadAction<EventsQuery>) {
  yield put(setIsLoading({ isLoading: true }));
  const { limit, offset } = action.payload;
  const currentTab: EventState = yield select(selectEventsCurrentTab);
  const filters: EventsFilters = yield select(selectEventFilters);
  const user: UserType = yield select(selectUser);
  const params: ParamsPayload = {
    eventStatus: EVENT_STATUS_TO_NUMBER[currentTab],
    limit,
    offset,
    ...filters,
    startingTime: filters.startingTime ? moment(filters.startingTime).format(formatDashYMD) : undefined,
    endingTime: filters.endingTime ? moment(filters.endingTime).format(formatDashYMD) : undefined,
  };
  let apiCall = getEventsForUser;

  if (isEitherAdmin(user.userType)) {
    apiCall = getEvents;
  } else {
    const currentSubTab: EventSubTab = yield select(selectEventsCurrentSubTab);
    params.response = currentSubTab === 'all' ? undefined : EVENT_RSVP_RESPONSE_TO_NUMBER[currentSubTab];
    params.userId = user.userId;
  }

  const { data, error, httpStatus } = yield call(apiCall, params);

  if (identity.isObjWithChildren(error)) {
    showNotification(`Something went wrong: ${error.message}`, true, httpStatus);
  } else {
    yield put(setEvents({ data }));
  }
  yield put(setIsLoading({ isLoading: false }));
}

function * handleGetEventsCount() {
  const currentTab: EventState = yield select(selectEventsCurrentTab);
  const filters: EventsFilters = yield select(selectEventFilters);
  const user: UserType = yield select(selectUser);
  const params: ParamsPayload = {
    eventStatus: EVENT_STATUS_TO_NUMBER[currentTab],
    ...filters,
    startingTime: filters.startingTime ? moment(filters.startingTime).format(formatDashYMD) : undefined,
    endingTime: filters.endingTime ? moment(filters.endingTime).format(formatDashYMD) : undefined,
  };
  let apiCall = getEventsRSVPCount;

  if (isEitherAdmin(user.userType)) {
    apiCall = getEventsCount;
    params.fieldSelection = ['status'];
  } else {
    const currentSubTab: EventSubTab = yield select(selectEventsCurrentSubTab);
    params.response = currentSubTab === 'all' ? undefined : EVENT_RSVP_RESPONSE_TO_NUMBER[currentSubTab];
    params.fieldSelection = ['events.status'];
    params.groupBy = 'events.status';
    params.userId = user.userId;
  }

  const { data, error, httpStatus } = yield call(apiCall, 'status', params);

  if (identity.isObjWithChildren(error)) {
    showNotification(`Something went wrong: ${error.message}`, true, httpStatus);
  } else {
    yield put(setEventsCount({ data }));
  }
}

function * handleGetEventResponseCount() {
  const currentTab: EventState = yield select(selectEventsCurrentTab);
  const userId: number = yield select(selectUserId);
  const filters: EventsFilters = yield select(selectEventFilters);
  const params: ParamsPayload = {
    eventStatus: EVENT_STATUS_TO_NUMBER[currentTab],
    fieldSelection: ['response'],
    groupBy: 'response',
    userId,
    ...filters,
    startingTime: filters.startingTime ? moment(filters.startingTime).format(formatDashYMD) : undefined,
    endingTime: filters.endingTime ? moment(filters.endingTime).format(formatDashYMD) : undefined,
  };
  const { data, error, httpStatus } = yield call(getEventsRSVPCount, 'response', params);

  if (identity.isObjWithChildren(error)) {
    showNotification(`Something went wrong: ${error.message}`, true, httpStatus);
  } else {
    yield put(setEventsResponseCount({ data }));
  }
}

function * handleAddEvent(action: PayloadAction<DataPayload<CreateEventPayload>>) {
  const isDrawerOpen: boolean = yield select(selectIsOpen);

  if (isDrawerOpen) {
    yield put(setIsDrawerLoading({ isLoading: true }));
  }

  const { error, status, data: eventId } = yield call(createEvent, action.payload.data);

  const { attendee_group, user_id } = action.payload.data;
  const createEventAttendeeListPayload: CreateEventAttendeePayload = {
    attendee_group,
    event_id: eventId,
    user_id,
  }

  const createEventRSVPPayload: CreateEventRSVPRequestPayload = {
    eventId,
    userId: user_id,
    response: 0,
  }

  let attendeeListError;
  let eventRSVPError;
  if (error) {
    showNotification(action.payload.data.name, error, status);
  } else {
    ({ attendeeListError } = yield call(createEventAttendeeList, createEventAttendeeListPayload));
    if (attendeeListError) {
      showNotification(attendeeListError.message, true)
    } else {
      showNotification('Event successfully added.');
      ({ eventRSVPError } = yield call(createEventRSVP, createEventRSVPPayload));
      if (eventRSVPError) {
        showNotification(attendeeListError.message, true)
      }
    }
    const params: ParamsPayload = {
      limit: config.TABLE_DEFAULT_LIMIT,
      offset: 0,
      isPreload: true,
    };
    yield put(getEventsAction(params));
    yield put(setIsDrawerLoading({ isLoading: false }));
    yield put(setDestroyDrawerOnClose({ destroyDrawerOnClose: true }));
    yield put(setIsDrawerOpen({ isDrawerOpen: false }));
  }
}

function * handleGetEventCategories() {
  const { data, error, status } = yield call(getEventsCategories);
  if (error) {
    showNotification('error', error, status);
  } else {
    yield put(setEventCategories({ data }));
  }
}

function * handleGetEventById(action: PayloadAction<IdPayload>) {
  yield put(setIsLoading({ isLoading: true }));
  const user: UserType = yield select(selectUser);
  const { id } = action.payload;

  let apiCall = getRSVPEventById;
  if (isEitherAdmin(user.userType)) {
    apiCall = getEventById;
  }
  const { error, httpStatus, data } = yield call(apiCall, id);
  if (identity.isObjWithChildren(error)) {
    showNotification(error?.message, true, httpStatus);
  } else {
    yield put(setEventDetails({ data }));
  }
  yield put(setIsLoading({ isLoading: false }));
}

function * handleDeleteEventById(action: PayloadAction<IdPayload>) {
  const { id } = action.payload;
  const { error, httpStatus } = yield call(deleteEventById, id);
  yield put(setIsLoading({ isLoading: true }));
  if (identity.isObjWithChildren(error)) {
    showNotification(error?.message, true, httpStatus);
  } else {
    showNotification('Event deleted', false);
    const params: ParamsPayload = {
      limit: config.TABLE_DEFAULT_LIMIT,
      offset: 0,
      isPreload: true,
      currentSubTab: 'all',
    };
    yield put(getEventsAction(params));
    yield put(setIsDrawerLoading({ isLoading: false }));
    yield put(setDestroyDrawerOnClose({ destroyDrawerOnClose: true }));
    yield put(setIsDrawerOpen({ isDrawerOpen: false }));
  }
  yield put(setIsLoading({ isLoading: false }));
}

function * handleGetEventAttendeesById(action: PayloadAction<IdPayload>) {
  const { id } = action.payload;
  const { error, httpStatus, data } = yield call(getEventAttendessList, id);
  yield put(setIsLoading({ isLoading: true }));
  if (identity.isObjWithChildren(error)) {
    showNotification(error?.message, true, httpStatus);
  } else {
    yield put(setEventAttendeesByEventId({ data }));
  }
  yield put(setIsLoading({ isLoading: false }));
}

function * handleUpdateEventById(action: PayloadAction<DataPayload<UpdateEventPayload>>) {
  yield put(setIsLoading({ isLoading: true }));

  const { data } = action.payload;
  const { error, httpStatus } = yield call(updateEventById, data.id, data);

  const { attendee_group, user_id } = action.payload.data;

  const createEventAttendeeListPayload: CreateEventAttendeePayload = {
    attendee_group,
    event_id: data.id,
    user_id,
  }

  const createEventRSVPPayload: CreateEventRSVPRequestPayload = {
    eventId: data.id,
    userId: user_id,
    response: 0,
  }

  let attendeeListError;
  let eventRSVPError;

  if (identity.isObjWithChildren(error)) {
    showNotification(error?.message, true, httpStatus);
  } else {
    const params: ParamsPayload = {
      limit: config.TABLE_DEFAULT_LIMIT,
      offset: 0,
      isPreload: true,
    };
    yield put(getEventsAction(params));
    yield put(setIsDrawerLoading({ isLoading: false }));
    yield put(setDestroyDrawerOnClose({ destroyDrawerOnClose: true }));
    yield put(setIsDrawerOpen({ isDrawerOpen: false }));
    if (identity.isFullArray(user_id)) {
      showNotification(`${data.name} successfully updated`);
      ({ attendeeListError } = yield call(createEventAttendeeList, createEventAttendeeListPayload));
      if (attendeeListError) {
        showNotification(attendeeListError.message, true)
      } else {
        ({ eventRSVPError } = yield call(createEventRSVP, createEventRSVPPayload));
        if (eventRSVPError) {
          showNotification(attendeeListError.message, true)
        }
      }
    }
  }
  yield put(setIsLoading({ isLoading: false }));
}

function * handleEventRSVP(action: PayloadAction<ParamsPayload>) {
  const userId: number = yield select(selectUserId);
  yield put(setIsLoading({ isLoading: true }));

  const { limit, offset, eventId } = action.payload;
  const query = {
    isPreload: true, limit, offset, userId, eventId,
  };
  const { error, httpStatus, data } = yield call(getEventRSVP, query);
  if (identity.isObjWithChildren(error)) {
    showNotification(error?.message, true, httpStatus);
  } else {
    yield put(setEventRSVP({ data: data[0] }));
  }

  yield put(setIsLoading({ isLoading: false }));
}

function * handleUpdateEventRSVPById(action: PayloadAction<DataPayload<EventRSVPBeType>>) {
  yield put(setIsLoading({ isLoading: true }));
  const userId: number = yield select(selectUserId);

  const { data } = action.payload;

  const updateEventRSVPByIdpayload: EventRSVPBeType = {
    ...data,
    userId,
  }
  const { error, httpStatus } = yield call(updateEventRSVPById, data.id!, updateEventRSVPByIdpayload);

  if (identity.isObjWithChildren(error)) {
    showNotification(error?.message, true, httpStatus);
  } else {
    showNotification('RSVP status successfully changed');
    const params: ParamsPayload = {
      limit: config.TABLE_DEFAULT_LIMIT,
      offset: 0,
      isPreload: true,
      currentSubTab: 'all',
    };
    yield put(getEventsAction(params));
    yield put(setIsDrawerLoading({ isLoading: false }));
    yield put(setDestroyDrawerOnClose({ destroyDrawerOnClose: true }));
    yield put(setIsDrawerOpen({ isDrawerOpen: false }));
  }
}

export default function * eventsSagas() {
  yield all([
    takeLatest(types.GET_EVENTS, handleGetEvents),
    takeLatest(types.ADD_EVENT, handleAddEvent),
    takeLatest(types.GET_EVENTS_COUNT, handleGetEventsCount),
    takeLatest(types.GET_EVENTS_RESPONSE_COUNT, handleGetEventResponseCount),
    takeLatest(types.GET_EVENT_CATEGORIES, handleGetEventCategories),
    takeLatest(types.GET_EVENT_BY_ID, handleGetEventById),
    takeLatest(types.DELETE_EVENT_BY_ID, handleDeleteEventById),
    takeLatest(types.GET_EVENT_ATTENDEES, handleGetEventAttendeesById),
    takeLatest(types.UPDATE_EVENT_BY_ID, handleUpdateEventById),
    takeLatest(types.GET_EVENT_RSVP, handleEventRSVP),
    takeLatest(types.UPDATE_EVENT_RSVP, handleUpdateEventRSVPById),
  ]);
}
