import { connect } from 'react-redux';
import { Action, bindActionCreators, Dispatch } from 'redux';

import { StateType } from 'types';
import {
  selectFounders,
  selectActiveTab,
  selectTeamMembers,
  selectPeopleCount,
  selectIsLoading,
  selectAllMembers,
  selectPeopleFilters,
  selectPeopleFiltersActive,
} from 'redux/selectors/people';
import {
  getFounders,
  getTeamMembers,
  setActiveTab,
  getPeopleCount,
  getAllMembers,
  setPeopleFilters,
} from 'redux/actions/people';
import { setIsDrawerOpen, setDrawerBody, setTableLayout } from 'redux/actions/workspace';
import { selectIsRowTableLayout } from '../../redux/selectors/workspace';

const mapStateToProps = (state: StateType) => ({
  founders: selectFounders(state),
  teamMembers: selectTeamMembers(state),
  activeTab: selectActiveTab(state),
  peopleCount: selectPeopleCount(state),
  isLoading: selectIsLoading(state),
  all: selectAllMembers(state),
  filters: selectPeopleFilters(state),
  isRowTableLayout: selectIsRowTableLayout(state),
  filtersActive: selectPeopleFiltersActive(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  actions: bindActionCreators({
    getFounders,
    getTeamMembers,
    setIsDrawerOpen,
    setActiveTab,
    setDrawerBody,
    getPeopleCount,
    getAllMembers,
    setPeopleFilters,
    setTableLayout,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps);
