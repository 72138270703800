import React from 'react';

import SVGWrap from './SVGWrap';

const AdvertisementIcon = () => (
  <SVGWrap size={24} fill="none">
    <path d="M21.8668 19.9533L8.22008 15.1442C8.18008 15.1301 8.15332 15.0923 8.15332 15.0499V7.50116C8.15332 7.45766 8.18143 7.41916 8.22286 7.40591L21.8695 3.04172C21.9341 3.02109 22 3.06923 22 3.13697V19.8589C22 19.9279 21.9318 19.9762 21.8668 19.9533Z" strokeLinecap="round" strokeWidth="1" />
    <path d="M2 11.2745C2 11.7796 2.09949 12.2798 2.29278 12.7464C2.48607 13.2131 2.76939 13.6371 3.12655 13.9942C3.84786 14.7156 4.82618 15.1208 5.84627 15.1208H8.15404V7.42822H5.84627C5.34117 7.42822 4.84102 7.52771 4.37437 7.721C3.90772 7.9143 3.48371 8.19761 3.12655 8.55477C2.40523 9.27609 2 10.2544 2 11.2745V11.2745Z" strokeLinecap="round" strokeWidth="1" />
    <path d="M5.8457 15.1206V19.6437" stroke="black" strokeLinecap="square" strokeLinejoin="round" strokeWidth="1" />
  </SVGWrap>
);

export default AdvertisementIcon;
