import { parseNumberToPrice } from '../../../utils';

export const getServiceCategories = (category: string[]) => {
  const serviceAttributes: { label: string; value: string; }[] = [];
  category.map(((value) => serviceAttributes.push({
    label: value,
    value,
  })));
  return serviceAttributes;
}

export const totalRequestAmountForInsurance = (plan: string) => {
  let newAmount;
  switch (plan) {
    case ('Bronze'): {
      newAmount = 10 * 1000;
      return parseNumberToPrice(newAmount, 'AED');
    }
    case ('Silver'): {
      newAmount = 10 * 2000;
      return parseNumberToPrice(newAmount, 'AED');
    }
    case ('Enhanced Silver'): {
      newAmount = 10 * 3000;
      return parseNumberToPrice(newAmount, 'AED');
    }
    case ('Enhanced Gold'): {
      newAmount = 10 * 4000;
      return parseNumberToPrice(newAmount, 'AED');
    }
    case ('Platinum'): {
      newAmount = 10 * 5000;
      return parseNumberToPrice(newAmount, 'AED');
    }
    default: {
      return parseNumberToPrice(0, 'AED');
    }
  }
}
