import { serviceProviderTypeOptions } from 'components/AddServiceProviderDrawer/utils/constants';
import requiredValidation from 'utils/constants/validationRules';
import { UserRoleType } from 'types/auth';
import { isEitherAdmin } from 'utils';
import { inputTypes, getFormFieldBuilder } from '../../../components';
import { OfferPriceValues } from '../../../AddNewServiceDrawer';

export const editServiceDetailFields = (userType: UserRoleType) => {
  const fields = [
    {
      fields: [
        getFormFieldBuilder('type', 'Service type', inputTypes.select)
          .withRules([requiredValidation])
          .withOptions(serviceProviderTypeOptions)
          .required()
          .build(),
      ],
    },
    {
      fields: [
        getFormFieldBuilder('name', 'Service name')
          .withRules([requiredValidation])
          .withPlaceholder('eg. UX Design')
          .required()
          .build(),
      ],
    },
    {
      fields: [
        getFormFieldBuilder('about', 'Description', inputTypes.textArea)
          .withRows(5)
          .build(),
      ],
    },
  ];

  if (isEitherAdmin(userType)) {
    fields.push({
      fields: [
        getFormFieldBuilder('offerPrice', 'Offer Price', inputTypes.radio)
          .withRadioValues(OfferPriceValues)
          .withRules([requiredValidation])
          .build(),
      ],
    });
  }

  return fields;
};

export const defaultValues = {
  type: [],
  offerPrice: '',
  range: {
    from: 'AED 2,000.54',
    to: 'AED 3,000.54',
  },
};
