import { AxiosRequestHeaders } from 'axios';

import { HttpRequestConfig } from 'packages/http_client/types';
import { CONTENT_TYPE_HEADER, MULTI_PART_FORM_HEADER_NAME } from '../constants';

export const updateContentRequestHeader = (request: HttpRequestConfig): AxiosRequestHeaders | undefined => {
  const { headers, method } = request;
  if (method === 'POST' || method === 'post' || method === 'PUT' || method === 'put') {
    headers![CONTENT_TYPE_HEADER] = MULTI_PART_FORM_HEADER_NAME;
  }

  return headers;
};

export default null;
