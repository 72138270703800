import React from 'react';
import { Select as AntdSelect, SelectProps } from 'antd';
import { DefaultOptionType } from 'rc-select/lib/Select';

import { ChevronDownIcon } from 'assets/icons';
import { sortHelper } from 'utils';

const Select = (props: SelectProps) => {
  const {
    onChange,
    options,
    value,
    onSearch,
    placeholder,
    showSearch,
    onClick,
    open,
    defaultActiveFirstOption,
    defaultValue,
    defaultOpen,
    className,
    dropdownRender,
    maxTagCount,
    mode,
    tagRender,
  } = props;

  const toLowerCase = (option: DefaultOptionType) => ((option?.label ?? '') as string)?.toLowerCase()

  return (
    <AntdSelect
      tagRender={tagRender}
      className={className}
      mode={mode}
      onChange={onChange}
      onClick={onClick}
      options={options}
      value={value}
      maxTagCount={maxTagCount}
      getPopupContainer={(trigger) => trigger.parentNode}
      onSearch={onSearch}
      showSearch={showSearch}
      filterOption={showSearch ? (input, option) => (toLowerCase(option!))?.includes(input?.toLowerCase()) : undefined}
      placeholder={placeholder}
      open={open}
      defaultActiveFirstOption={defaultActiveFirstOption}
      defaultValue={defaultValue}
      defaultOpen={defaultOpen}
      suffixIcon={<ChevronDownIcon />}
      dropdownRender={dropdownRender}
      filterSort={showSearch ? (optionA, optionB) => sortHelper(toLowerCase(optionA), toLowerCase(optionB)) : undefined}
    />
  )
}

export default Select;
