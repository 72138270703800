import { connect } from 'react-redux';
import { bindActionCreators, Dispatch, Action } from 'redux';

import { StateType } from 'types';
import { selectAdgmLicenseByStartupId, selectStartupId } from 'redux/selectors/startups';
import { getADGMLicenseByStartupId } from 'redux/actions/startups';
import { selectUserStartupId } from 'redux/selectors/auth';

const mapStateToProps = (state: StateType) => ({
  adgm: selectAdgmLicenseByStartupId(state),
  userStartupId: selectUserStartupId(state),
  id: selectStartupId(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  actions: bindActionCreators({ getADGMLicenseByStartupId }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps);
