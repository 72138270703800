import React, { useEffect, useMemo } from 'react';
import { useParams } from 'react-router';

import { TitleWithActions, ExpandableTable, Breadcrumb } from 'components/components';
import { isEitherAdmin, getRowKey } from 'utils';
import { cohortsUrl } from 'routes/urls';
import { CohortDetailsProps } from 'types/cohorts/cohortDetails';
import { composeEditCohortUrl } from 'routes/routeComposers';
import { Overviews, Requests as AcceptanceLetterADGM } from './components';
import { startupsInCohortColumns } from '../utils/constants/columns';

import './CohortDetails.scss';

const CohortDetails = (props: CohortDetailsProps) => {
  const {
    selectedCohort, startups, userType, startupsToReview,
  } = props;
  const { getStartupsByOwnerId, getCohortById } = props.actions;

  const params: { id?: string } = useParams();
  const { id } = params;
  const cohortId = useMemo(() => parseInt(id!, 10), [id]);

  useEffect(() => {
    getCohortById({ id: cohortId, isUpdate: true });
    getStartupsByOwnerId({ id: cohortId, owner: 'cohort' });
  }, [cohortId]);

  return (
    <div className="cohort-details content-container">
      <Breadcrumb navigateTo={cohortsUrl} routeName="Cohorts Overview" />
      <div className="cohort-details__container">
        <TitleWithActions
          title={selectedCohort.name}
          link={composeEditCohortUrl(cohortId)}
          buttonTitle="cohort"
          isEditButton
          showButton={isEitherAdmin(userType)}
        />
        <Overviews />
        <ExpandableTable
          columns={startupsInCohortColumns}
          dataSource={startups}
          collapseHeader="Startups in this cohort"
          rowKey={getRowKey(startups, 'cohort-details-startup', 'id')}
        />
        <AcceptanceLetterADGM startups={startupsToReview} />
      </div>
    </div>
  )
}

export default CohortDetails;
