/* eslint-disable @typescript-eslint/no-explicit-any */
import { ConditionObject } from 'packages/http_client/types';

export const equals = (field: string, value: any): ConditionObject => ({ value, field });
export const isInside = (field: string, value: any): ConditionObject => ({ value, field, operation: 'in' })
export const greaterThan = (field: string, value: any): ConditionObject => ({ value, field, operation: 'greaterThan' });
export const greaterThanOrEqual = (field: string, value: any): ConditionObject => ({ value, field, operation: 'greaterThanOrEqual' });
export const lessThan = (field: string, value: any): ConditionObject => ({ value, field, operation: 'lessThan' });
export const lessThanOrEqual = (field: string, value: any): ConditionObject => ({ value, field, operation: 'lessThanOrEqual' });
export const contains = (field: string, value: string): ConditionObject => ({ value, field, operation: 'contains' });
export const startsWith = (field: string, value: string): ConditionObject => ({ value, field, operation: 'startsWith' });
export const endsWith = (field: string, value: string): ConditionObject => ({ value, field, operation: 'endsWith' });

export default {
  equals,
  in: isInside,
  greaterThan,
  greaterThanOrEqual,
  lessThan,
  lessThanOrEqual,
  contains,
  startsWith,
  endsWith,
}
