import { useReducer } from 'react';

import { DataPayload } from 'types/reduxTypes/ActionTypes';
import { EditMemberType } from 'types/people/editMemberProfile';
import { SetFieldType } from 'types/reduxTypes/ActionTypes/PeopleTypes';
import { reducer, initialState } from './reducer';
import {
  setField as setFieldAC,
  setAll as setAllAC,
  setEmiratesId as setEmiratesIdAC,
  setShowError as setShowErrorAC,
  setVisa as setVisaAC,
  setPassport as setPassportAC,
} from './actions';

const useEditMemberHook = () => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const setField = (payload: SetFieldType) => {
    dispatch(setFieldAC(payload));
  }

  const setShowError = (payload: SetFieldType) => {
    dispatch(setShowErrorAC(payload));
  }

  const setAll = (payload: DataPayload<EditMemberType>) => {
    dispatch(setAllAC(payload));
  }

  const setEmiratesId = (payload: DataPayload<string | Blob>) => {
    dispatch(setEmiratesIdAC(payload));
  }

  const setVisa = (payload: DataPayload<string | Blob>) => {
    dispatch(setVisaAC(payload));
  }

  const setPassport = (payload: DataPayload<string | Blob>) => {
    dispatch(setPassportAC(payload));
  }

  return {
    state,
    setAll,
    setField,
    setShowError,
    setEmiratesId,
    setVisa,
    setPassport,
  }
}

export default useEditMemberHook;
