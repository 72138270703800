import { notification } from 'antd';

export default (description: string, error: boolean = false, status: number = 200) => {
  if (error) {
    if (status !== 401) {
      notification.error({
        message: `Error ${status}`,
        description,
      });
    }
  } else {
    notification.success({
      message: 'Success',
      description,
    });
  }
};
