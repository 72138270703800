import React, { useState, useMemo } from 'react';
import { Button, Form } from 'antd';
import { useParams } from 'react-router';

import { scrollToFirstError } from 'components/components/FormBuilder/utils/helpers';
import {
  AddServiceProviderOfferProps,
  OfferPriceType,
  ServiceProviderOfferType,
} from '../../types/serviceProviders/serviceProvidersOffer';
import Input from '../components/Input';
import OfferPriceInput from './OfferPriceInput';
import requiredValidation from '../../utils/constants/validationRules';
import { defaultAddOfferFormValues, FIXED_PRICE_TYPE, priceOfferOptions } from './utils/constants';
import Row from '../components/FormBuilder/components/Row';

const AddServiceProviderOffer = (props: AddServiceProviderOfferProps) => {
  const { actions: { addServiceProviderOffer } } = props;
  const [form] = Form.useForm();
  const [priceType, setPriceType] = useState<OfferPriceType>(FIXED_PRICE_TYPE);

  const params: { id?: string } = useParams();
  const { id } = params;
  const serviceProviderId = useMemo(() => parseInt(id!, 10), [id]);

  const resetForm = () => {
    form.resetFields();
    setPriceType(FIXED_PRICE_TYPE);
  };

  const onSubmit = (formData: ServiceProviderOfferType) => {
    addServiceProviderOffer({ id: serviceProviderId, offer: formData });
    resetForm();
  };

  return (
    <Form
      className="form-builder add-new-offer-form"
      onFinish={onSubmit}
      initialValues={defaultAddOfferFormValues}
      validateTrigger="onSubmit"
      form={form}
      onFinishFailed={scrollToFirstError}
    >
      <Row>
        <Input
          name="name"
          label="Offer Name"
          layout="vertical"
          rules={[requiredValidation]}
        >
          <Input.InputField name="name" type="text" />
        </Input>
      </Row>
      <Row oneColumn>
        <Input
          name="priceType"
          label="Offer Price"
          rules={[requiredValidation]}
          layout="vertical"
        >
          <Input.Radio
            values={priceOfferOptions}
            onChange={(e) => setPriceType(e.target.value)}
            value={priceType}
          />
        </Input>
      </Row>
      <OfferPriceInput priceType={priceType} />
      <Row oneColumn>
        <Input
          name="description"
          label="Description"
          layout="vertical"
        >
          <Input.InputField.TextArea name="description" autoSize={{ minRows: 3, maxRows: 5 }} />
        </Input>
      </Row>
      <Row>
        <Input name="offerDocument" label="Offer Document" layout="vertical">
          <Input.DraggerUpload
            previewType="image"
            accept="document"
            isRoundableButton
          />
        </Input>
      </Row>
      <Button htmlType="submit" className="primary-button">ADD OFFER</Button>
    </Form>
  );
};

export default AddServiceProviderOffer;
