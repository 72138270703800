import { Action, bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';

import { getResetPassword } from 'redux/actions/auth';
import { selectIsAuthenticated, selectIsLoading } from 'redux/selectors/auth';
import { StateType } from 'types';

const mapStateToProps = (state: StateType) => ({
  isLoading: selectIsLoading(state),
  isAuthenticated: selectIsAuthenticated(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  actions: bindActionCreators({
    getResetPassword,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps);
