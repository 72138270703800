import { connect } from 'react-redux';
import { Action, bindActionCreators, Dispatch } from 'redux';

import { selectServiceProviders } from 'redux/selectors/serviceProviders';
import { getServiceProviders } from 'redux/actions/serviceProviders';
import { StateType } from '../../../../types';
import { selectUserType } from '../../../../redux/selectors/auth';

const mapStateToProps = (state: StateType) => ({
  userType: selectUserType(state),
  serviceProviders: selectServiceProviders(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  actions: bindActionCreators({
    getServiceProviders,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps);
