import { WorkspaceStateType } from 'types/reduxTypes/StateTypes';

export const defaultWorkspaceState: WorkspaceStateType = {
  isRowTableLayout: false,
  partners: [],
  serviceProviders: [],
  drawer: {
    isDrawerLoading: false,
    isDrawerOpen: false,
    destroyDrawerOnClose: false,
    isDrawerFilterTableOpen: false,
    drawerBody: '',
  },
  searchResults: [],
  resetForm: false,
  nationalities: [],
  startups: [],
  noticeModal: {
    isOpen: false,
  },
  servicesCount: 0,
  serviceProvidersCount: 0,
}

export default null;
