import React from 'react';
import { Modal as AntModal } from 'antd';
import classnames from 'classnames';

import { CloseIcon } from 'assets/icons';
import { identity } from 'utils';
import { ModalProps } from 'types/componentTypes';
import TitleWithActions from '../TitleWithActions';

import './Modal.scss'

const Modal = (props: ModalProps) => (
  <AntModal
    width={props.width}
    open={props.open}
    onOk={props.onOk}
    onCancel={props.onCancel}
    footer={props.footer}
    title={(
      <TitleWithActions
        title={props.title}
        buttons={props.buttons}
        buttonTitle={props.buttonTitle}
        link={props.link}
        showButton={props.showButton}
      />
    )}
    closeIcon={<CloseIcon />}
    className={classnames(props.className, { 'modal__no-footer': identity.isFalsy(props.footer) })}
  >
    {props.children}
  </AntModal>
);

Modal.defaultProps = {
  title: '',
  showButton: false,
};

export default Modal;
