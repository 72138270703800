import produce from 'immer';

import { PerformanceType } from 'packages/performance_repository';
import { types } from 'redux/actions/performance';
import { defaultPerformanceState } from 'redux/utils';
import { DataPayload, IdPayload, IsLoadingPayload } from 'types/reduxTypes/ActionTypes';
import { PerformanceActionType, SetCountByStatusPayload } from 'types/reduxTypes/ActionTypes/PerformanceTypes';
import { PerformanceStateType } from 'types/reduxTypes/StateTypes';

const handleSetPerformanceReports = (state: PerformanceStateType, payload: DataPayload<PerformanceType[]>) => {
  state.performanceReports = payload.data;

  return state;
}

const handleSetLimit = (state: PerformanceStateType, payload: DataPayload<number>) => {
  state.limit = payload.data;
  return state;
}

const handleOffset = (state: PerformanceStateType, payload: DataPayload<number>) => {
  state.offset = payload.data;
  return state;
}

const handleSetIsLoading = (state: PerformanceStateType, payload: IsLoadingPayload) => {
  state.isLoading = payload.isLoading;
  return state;
}

const handleSetActiveTab = (state: PerformanceStateType, payload: DataPayload<string>) => {
  state.activeTab = payload.data;
  return state;
}

const handleSetCountByStatus = (state: PerformanceStateType, payload: SetCountByStatusPayload) => {
  const { count, status } = payload;
  state.counts[status] = count;
  return state;
}

const handleRemovePerformanceReportById = (state: PerformanceStateType, payload: IdPayload) => {
  const { id } = payload;
  state.performanceReports = state.performanceReports.filter((item) => item.id !== id);
  return state;
}

const handleSetPerformanceId = (state: PerformanceStateType, payload: IdPayload) => {
  state.performanceReportId = payload.id;
  return state;
}

const handleSetPerformanceById = (state: PerformanceStateType, payload: DataPayload<PerformanceType>) => {
  state.details.data = payload.data;
  state.performanceReportId = payload.data.id;
  return state;
}

const handleResetCounts = (state: PerformanceStateType) => {
  state.counts = defaultPerformanceState.counts;
  return state;
}

// eslint-disable-next-line default-param-last
export default produce((state = defaultPerformanceState, action: PerformanceActionType) => {
  const { payload } = action;
  switch (action.type) {
    case types.SET_PERFORMANCE_REPORT_BY_ID: {
      return handleSetPerformanceById(state, <DataPayload<PerformanceType>>payload);
    }
    case types.SET_PERFORMANCE_REPORT_ID: {
      return handleSetPerformanceId(state, <IdPayload>payload);
    }
    case types.SET_PERFORMANCE_REPORTS: {
      return handleSetPerformanceReports(state, <DataPayload<PerformanceType[]>>payload);
    }
    case types.SET_IS_LOADING_PERFORMANCE: {
      return handleSetIsLoading(state, <IsLoadingPayload>payload);
    }
    case types.SET_LIMIT_PERFORMANCE: {
      return handleSetLimit(state, <DataPayload<number>>payload);
    }
    case types.SET_OFFSET_PERFORMANCE: {
      return handleOffset(state, <DataPayload<number>>payload);
    }
    case types.SET_ACTIVE_TAB_PERFORMANCE: {
      return handleSetActiveTab(state, <DataPayload<string>>payload);
    }
    case types.SET_COUNT_BY_STATUS_PERFORMANCE: {
      return handleSetCountByStatus(state, <SetCountByStatusPayload>payload);
    }
    case types.REMOVE_PERFORMANCE_REPORT_BY_ID: {
      return handleRemovePerformanceReportById(state, <IdPayload>payload);
    }
    case types.RESET_COUNTS_PERFORMANCE: {
      return handleResetCounts(state);
    }
    case types.RESET_ALL_PERFORMANCE: {
      return defaultPerformanceState;
    }
    default: {
      return state;
    }
  }
})
