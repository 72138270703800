import React from 'react'
import { Button } from 'antd';

import { DrawerCancelButtonProps } from 'types/componentTypes/Drawer';

const DrawerCancelButton = (props: DrawerCancelButtonProps) => {
  const { actions } = props;
  const onClick = () => actions.setIsDrawerOpen({ isDrawerOpen: false });

  return (
    <Button className="secondary-button" onClick={onClick}>Cancel</Button>
  );
}

export default DrawerCancelButton;
