import React from 'react';
import { Button } from 'antd';
import classNames from 'classnames';

import { identity, DRAWERS, isCommunity } from 'utils';
import { RenderIf, Text } from 'components/components';
import { getServicePriceText } from 'components/components/Card/components/CardInfoRow/utils/helpers';
import { ServiceCardProps } from 'types/requests';

import './ServiceCard.scss';

const ServiceCard = (props: ServiceCardProps) => {
  const {
    service,
    requestedService,
    disabledRequest,
    actions,
    startupStatus,
  } = props;
  const { name, serviceProvider } = service;

  const onRequestService = () => {
    actions.setDrawerBody({ drawerBody: DRAWERS.requestService });
    actions.setIsDrawerOpen({ isDrawerOpen: true })
    actions.getServiceById({ id: service.id })
  }

  return (
    <div className="add-more-services__service-card">
      <div className="add-more-services__service-card--container">
        <Text text={name} size="m" />
        <RenderIf condition={identity.isTruthyString(serviceProvider.name)}>
          <Text text={`by ${serviceProvider.name}`} size="xxs" />
        </RenderIf>
      </div>
      <div className="add-more-services__service-card--container">
        <Text text="Price" size="xxs" />
        <Text text={getServicePriceText(service)} size="xs" />
      </div>
      <div className="add-more-services__service-card--buttons">
        <Button className="secondary-button md">View profile</Button>
        <Button
          className={classNames('primary-button md', { 'requested-service-button' : requestedService })}
          onClick={onRequestService}
          disabled={disabledRequest || requestedService || !isCommunity(startupStatus)}
        >
          {requestedService ? 'Service requested' : 'Request service'}
        </Button>
      </div>
    </div>
  );
}

export default ServiceCard;
