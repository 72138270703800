import React, { useEffect, useMemo } from 'react'
import { Form } from 'antd'
import { LabeledValue } from 'antd/lib/select';

import DrawerButton from 'components/components/Drawer/components/DrawerButton';
import { ServiceCategoryType } from 'types/services';
import { AddServiceSubCategoryPayload } from 'types/reduxTypes/ActionTypes/ServiceTypes';
import { FormBuilder } from 'components/components';
import { AddNewSubCategoryDrawerProps } from 'types/settingsOverview';
import { AddNewSubCategoryFormFileds, ServiceSubCategoryParams } from './utils/contstants';

import '../../../MainCategories/components/AddNewCategoryDrawer/AddNewCategoryDrawer.scss'

const AddNewSubCategoryDrawer = (props: AddNewSubCategoryDrawerProps) => {
  const { categories, actions } = props;

  const [form] = Form.useForm();

  useEffect(() => {
    actions.getServiceCategories({});
  }, []);

  const handleSubmit = (data: AddServiceSubCategoryPayload) => {
    const addDataValues = {
      ...data,
    };
    actions.addServiceSubCategory({ data: addDataValues });
  }

  const serviceProvidersOptions: LabeledValue[] = useMemo(() => (
    categories!.map((item: ServiceCategoryType) => ({ label: `${item.name || 'Unknown category'}`, value: item.id }))), [categories]);

  const serviceFiltersData: ServiceSubCategoryParams = {
    categories: serviceProvidersOptions,
  };

  return (
    <Form
      form={form}
      onFinish={handleSubmit}
      className="new-category-container"
      scrollToFirstError
    >
      <FormBuilder
        oneColumn
        formFragment
        fields={AddNewSubCategoryFormFileds(serviceFiltersData)}
      />
      <DrawerButton>Add category</DrawerButton>
    </Form>

  )
}
export default AddNewSubCategoryDrawer;
