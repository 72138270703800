import { identity } from 'utils';
import { AddDataEntryPointType, CreateDataEntryApiRequestPayload, DataEntryPointType } from '../types';

export const convertDataEntryPoint = (data?: DataEntryPointType) => ({
  id: data?.id,
  name: data?.name,
  createdAt: data?.createdAt,
});

export const convertDataEntryPoints = (data?: DataEntryPointType[]) => {
  if (identity.isEmptyArray(data)) {
    return [];
  }
  return data!.map((item) => convertDataEntryPoint(item));
}

export const convertDataEntryRequestPayload = (payload: AddDataEntryPointType): CreateDataEntryApiRequestPayload => ({
  _name: payload.name,
});
