import React, { useState } from 'react';

import { getServicePrice } from 'utils';
import { RequestContainerProps } from 'types/startups/startupDetails/serviceProviderOverview';
import RequestDetailsModal from '../../../../RequestDetailsModal';
import { getStatusName } from '../../../../RequestsOverview/utils/helpers';

import './RequestContainer.scss';

const RequestContainer = (props: RequestContainerProps) => {
  const {
    requestNumber,
    description,
    serviceRequest: { status },
    id,
  } = props.request;
  const {
    category, priceFrom, priceTo, offerPrice, priceType,
  } = props.request.service;
  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <>
      <button type="button" className="service-request__container" onClick={() => setIsOpen(true)}>
        <div className="service-request__container--header">
          <p className="service-request__container--about">{description}</p>
          <p className="service-request__container--category-id">
            <span>{requestNumber}</span>
            <div className="dot" />
            <span>{category}</span>
          </p>
          <p className="service-request__container--amount">{getServicePrice(offerPrice, priceFrom, priceTo, priceType)}</p>
        </div>
        <div className="service-request__container--status">{getStatusName(status)}</div>
      </button>
      <RequestDetailsModal id={id!} isOpen={isOpen} setIsOpen={setIsOpen} />
    </>
  );
}

export default RequestContainer;
