import { getFormFieldBuilder } from 'components/components/FormBuilder/utils/FormFieldBuilder';
import { inputTypes } from 'components/components/FormBuilder/utils/constants';
import requiredValidation from 'utils/constants/validationRules';

export const DATA_ENTRY_POINTS = {
  education: 'Education',
  title: 'Title',
  startupStage: 'Startup stage',
}

export const defaultValue = {
  name: '',
  type: DATA_ENTRY_POINTS.education,
};

export const DataEntryOptions = [
  {
    value: DATA_ENTRY_POINTS.education,
    label: DATA_ENTRY_POINTS.education,
  },
  {
    value: DATA_ENTRY_POINTS.startupStage,
    label: DATA_ENTRY_POINTS.startupStage,
  },
  {
    value: DATA_ENTRY_POINTS.title,
    label: DATA_ENTRY_POINTS.title,
  },
];

export const AddNewDataEntryDrawerFields = [
  {
    fields: [
      getFormFieldBuilder('type', 'Choose parent Data entry point', inputTypes.select)
        .withOptions(DataEntryOptions)
        .withRules([requiredValidation])
        .build(),
    ],
  },
  {
    fields: [
      getFormFieldBuilder('name', 'Name of Data entry item')
        .withRules([requiredValidation])
        .build(),
    ],
  },
];
