import { UserAuthType } from 'packages/authentication_repository';
import identity from 'utils/identity';

const userStorageKey = 'hub71_user';

export const saveUserToLocal = (user: UserAuthType) => {
  localStorage.setItem(userStorageKey, JSON.stringify(user));
};

export const getUserFromLocal = (): UserAuthType | null => {
  const user = localStorage.getItem(userStorageKey);
  if (identity.isTruthyString(user)) {
    return JSON.parse(user!);
  }

  return null;
};

export const removeUserFromLocal = () => {
  localStorage.removeItem(userStorageKey);
};

export const clearNoticeFromLocal = () => {
  const user = getUserFromLocal();

  if (!identity.isObjWithChildren(user)) {
    return;
  }

  delete user?.notice;
  saveUserToLocal(user!);
}
