import { ServiceProviderServicesType } from 'types/serviceProviders/ServiceProviderDetails';
import { ServiceStatusType } from 'types/serviceProviders/servicesOverview';
import { identity } from 'utils';

export const setRequestedServiceStatusHelper = (
  id: number,
  services: ServiceProviderServicesType[],
  status: ServiceStatusType,
) => {
  const request = services.find((req) => req.id === id);

  if (identity.isObjWithChildren(request)) {
    const requestIndex = services.indexOf(request!);
    request!.serviceStatus = status;
    services[requestIndex] = request!;
  }

  return services;
}

export default null;
