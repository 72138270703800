import { getImageLink, identity } from 'utils';

export const getFileList = (link?: string) => {
  const idSplit = identity.isTruthyString(link) ? (link as string).split('/') : '';
  const url = getImageLink(link as string);
  const name = idSplit[idSplit.length - 1];
  return (
    identity.isTruthyString(name)
      ? [{
        name,
        size: 0,
        url,
        uid: '1',
      }]
      : []
  );
}

export default null;
