import { NotificationsStateType } from 'types/reduxTypes/StateTypes';

export const notificationsDefaultState: NotificationsStateType = {
  isOpen: false,
  limit: 3,
  offset: 0,
  newCount: 0,
  orderBy: '',
  isLoading: false,
  notifications: [],
  allFetched: false,
}

export default null;
