import { connect } from 'react-redux';
import { Action, bindActionCreators, Dispatch } from 'redux';

import { getEventCategories, setFilters } from 'redux/actions/events';
import { selectActiveTab } from '../../../../redux/selectors/people';
import { selectIsDrawerFilterTableOpen } from '../../../../redux/selectors/workspace'
import { StateType } from '../../../../types'
import { selectEventCategories } from '../../../../redux/selectors/events';

const mapStateToProps = (state: StateType) => ({
  isFilterTableOpen: selectIsDrawerFilterTableOpen(state),
  activeTab: selectActiveTab(state),
  eventCategories: selectEventCategories(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  actions: bindActionCreators({
    setFilters,
    getEventCategories,
  }, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps);
