import React from 'react';
import classnames from 'classnames';
import { Button } from 'antd';

import { SubTabProps } from 'types/componentTypes';

const SubTab = (props: SubTabProps) => {
  const {
    activeKey,
    tabKey,
    tab,
    onClick,
  } = props;

  return (
    <Button
      className={classnames('sub-tab', { 'active-sub-tab': activeKey === tabKey })}
      onClick={onClick}
    >
      <p className="tab-text">{tab}</p>
    </Button>
  );
}

export default SubTab;
