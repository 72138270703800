import { createSelector } from 'reselect';

import { StateType } from 'types';
import { StartupsStateType } from 'types/reduxTypes/StateTypes';
import { StartupType } from 'types/startups';
import { RequestType } from 'types/requests';
import routes from 'routes';
import { isProduction } from 'utils';

const selectStartupsState = (state: StateType) => state.startupsReducer;

export const selectIncentiveAgreement = createSelector(
  selectStartupsState,
  (state: StartupsStateType) => state.incentiveAgreement,
)

export const selectStartups = createSelector(
  selectStartupsState,
  (state: StartupsStateType) => state.startups,
);

export const selectStartupCount = createSelector(
  selectStartupsState,
  (state: StartupsStateType) => state.startupCount,
);

export const selectStartupDetails = createSelector(
  selectStartupsState,
  (state: StartupsStateType) => state.details.data,
);

export const selectCohortDetails = createSelector(
  selectStartupDetails,
  (state: StartupType) => state.cohort,
);

export const selectStartupRequests = createSelector(
  selectStartupsState,
  (state: StartupsStateType) => state.details.requests,
)

export const selectStartupDetailsRequests = createSelector(
  selectStartupRequests,
  (state: RequestType[]) => state.slice(0, 3),
);

export const selectStartupId = createSelector(
  selectStartupDetails,
  (state: StartupType) => state.id,
);

export const selectAboutStartup = createSelector(
  selectStartupDetails,
  (state: StartupType) => state.about,
);

export const selectStartupName = createSelector(
  selectStartupDetails,
  (state: StartupType) => state.name,
);

export const selectStatCards = createSelector(
  selectStartupsState,
  (state: StartupsStateType) => {
    const stats = [
      { title: 'service providers', count: state.details.serviceProvidersCount || 0, path: routes.servicesAndSpsOverviewUrl },
    ];

    if (!isProduction) {
      stats.push({ title: 'upcoming events', count: state.details.data.upcomingEventsCount || 0, path: routes.eventsUrl });
    }

    return stats;
  },
);

export const selectPartnersStatCards = createSelector(
  selectStartupsState,
  (state: StartupsStateType) => {
    const stats = [
      { title: 'founders', count: state.details.teamMembers.filter((member) => member.founder)?.length, path: '' },
    ];

    if (!isProduction) {
      stats.push({ title: 'upcoming events', count: state.details.data?.upcomingEventsCount || 0, path: routes.eventsUrl });
    }

    return stats;
  },
);

export const selectIncentiveAgreementStatus = createSelector(
  selectStartupDetails,
  (state: StartupType) => state.incentiveAgreementApprovedStatus,
);

export const selectStartupTeamMembers = createSelector(
  selectStartupsState,
  (state: StartupsStateType) => state.details.teamMembers,
);

export const selectStartupServiceProviders = createSelector(
  selectStartupsState,
  (state: StartupsStateType) => state.details.serviceProviders,
);

export const selectStartupLocations = createSelector(
  selectStartupsState,
  (state: StartupsStateType) => state.locations,
);

export const selectSectorOptions = createSelector(
  selectStartupsState,
  (state: StartupsStateType) => state.sectorOptions,
);

export const selectStageOptions = createSelector(
  selectStartupsState,
  (state: StartupsStateType) => state.stageOptions,
);

export const selectInvestmentStageOptions = createSelector(
  selectStartupsState,
  (state: StartupsStateType) => state.investmentStageOptions,
);

export const selectIsLoading = createSelector(
  selectStartupsState,
  (state: StartupsStateType) => state.isLoading,
);

export const selectStartupTeamMembersCount = createSelector(
  selectStartupsState,
  (state: StartupsStateType) => state.details.teamMembersCount,
);

export const selectAdgmLicenseByStartupId = createSelector(
  selectStartupsState,
  (state: StartupsStateType) => state.adgmLicenseByStartupId,
);

export const selectSelectedStartupId = createSelector(
  selectStartupsState,
  (state: StartupsStateType) => state.startupId!,
);

export const selectStartupForReview = createSelector(
  selectStartupsState,
  (state: StartupsStateType) => state.selectedStartupForReview,
);

export const selectStartupFilters = createSelector(
  selectStartupsState,
  (state: StartupsStateType) => state.filters,
);

export const selectStartupFiltersActive = createSelector(
  selectStartupsState,
  (state: StartupsStateType) => state.filtersActive,
);
