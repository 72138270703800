import { connect } from 'react-redux';
import { Action, bindActionCreators, Dispatch } from 'redux';

import { setIsDrawerOpen, setDrawerBody } from '../../../../../../redux/actions/workspace';

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  actions: bindActionCreators({
    setIsDrawerOpen,
    setDrawerBody,
  }, dispatch),
});

export default connect(null, mapDispatchToProps);
