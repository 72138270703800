import React from 'react'
import classnames from 'classnames';

import { DataColumnProps } from 'types/componentTypes/DataColumns';

import './DataColumn.scss';

const DataColumn = (props: DataColumnProps) => {
  const { label, children, required } = props;
  return (
    <div className={classnames('overview-column', props.className)}>
      <div>
        {required && <span className="sign" />}
        <label className="overview-column__label">{label}</label>
      </div>
      <div className="overview-column__value">{children}</div>
    </div>
  )
}

DataColumn.defaultProps = {
  className: '',
};

export default DataColumn;
