import React from 'react'
import { Button } from 'antd';
import { useHistory } from 'react-router-dom';

import routes from 'routes';
import { StartupSelectTitleProps } from 'types/people';
import { AddIcon, ChevronLeftIcon } from 'assets/icons';

const StartupSelectTitle = (props: StartupSelectTitleProps) => {
  const { actions } = props;
  const history = useHistory();

  return (
    <div className="add-member__filter-table-title">
      <Button
        icon={<ChevronLeftIcon />}
        className="dark-blue-link"
        type="link"
        onClick={() => actions.setIsDrawerFilterTableOpen({ isDrawerFilterTableOpen: false })}
      >
        Back
      </Button>
      <div className="add-member__filter-table-title-body">
        <h3 className="add-member__filter-table-title-text">Startups</h3>
        <Button
          className="dark-blue-link add-member__add-startup"
          type="link"
          icon={<AddIcon />}
          onClick={() => history.push(routes.startupsUrl)}
        >
          Add startup
        </Button>
      </div>
    </div>
  )
}

export default StartupSelectTitle;
