import { AggregationObject, ConditionObject, SelectFieldsType } from 'packages/http_client/types';
import { identity } from 'utils';
import { AGGREGATIONS, OPERATION_TYPE } from '../constants';

const or = (orArray: ConditionObject[]) => {
  const parsedValues: string[] = [];
  orArray.forEach((item) => {
    let value;
    if (identity.isFalsy(item.operation)) {
      value = `${item.field}=${item.value}`;
    } else {
      value = `${item.field}__${OPERATION_TYPE[item.operation!]}=${item.value}`;
    }
    parsedValues.push(value);
  })
  return parsedValues.toString().replace(',', '|');
};

const select = (fields: SelectFieldsType | undefined) => {
  if (identity.isFullArray(fields)) {
    const selectArray: string[] = [];

    fields!.forEach((item) => {
      if (identity.isObjWithChildren(item)) {
        const { field, aggregation } = (item as AggregationObject);
        selectArray.push(`${field}__${(AGGREGATIONS[aggregation])}`)
      } else {
        selectArray.push(item as string);
      }
    });

    return selectArray.toString();
  }
};

export const paramToString = (param: number | number[] | string | string[]) => param.toString();
export const orderByDescending = (param: string | string[]) => `-${paramToString(param)}`;
export const isIn = paramToString;
export const orderBy = paramToString;
export const groupBy = paramToString;

export const join = (fields: {tableName: string, onId: string, leftJoin?: boolean}[]) => {
  let joinString: string = '';
  for (const key in fields) {
    const { tableName, onId, leftJoin } = fields[key];
    if (identity.isTruthyString(joinString)) {
      joinString += `&${tableName}${leftJoin ? '__left' : ''}__${onId}`;
    } else {
      joinString = `${tableName}__${onId}`;
    }
  }
  return joinString;
};

export const formatParams = {
  or,
  select,
  orderBy,
  orderByDescending,
  groupBy,
  in: isIn,
  join,
}
