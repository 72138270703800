import React, { useMemo, useState } from 'react';
import { Button } from 'antd';

import { ServiceOfferedDocViewProps } from '../../../../types/services';
import identity from '../../../../utils/identity';
import FilePreviewModal from '../../../components/Input/components/DraggerUpload/components/FilePreviewModal';
import { ViewIcon } from '../../../../assets/icons';

import './ServiceOfferedDocView.scss';

const ServiceOfferedDocView = (props: ServiceOfferedDocViewProps) => {
  const { altText, fileContent } = props;

  const [isOpen, setIsOpen] = useState(false);

  const fileName = useMemo(() => (
    identity.isTruthyString(fileContent)
      ? fileContent.split('/').at(-1)!.replace('.pdf', '')
      : 'n/a'
  ), [fileContent]);
  const fileNameWithType = useMemo(() => (
    identity.isTruthyString(fileContent)
      ? fileContent.split('/').at(-1)
      : 'n/a'
  ), [fileContent]);

  const openHandler = () => {
    if (!identity.isEmptyString(fileContent)) {
      setIsOpen(true);
    }
  }

  if (!identity.isTruthyString(fileContent)) {
    return <p className="service-offered-file__no-document">{altText}</p>
  }

  return (
    <div className="service-offered-file">
      <div className="service-offered-file__info">
        <p className="service-offered-file__info--name">{fileNameWithType}</p>
      </div>
      <div className="service-offered-file__button">
        <Button className="view-btn" onClick={openHandler} type="text" icon={<ViewIcon />} />
        <FilePreviewModal
          title={fileName}
          fileType="pdf"
          fileContent={fileContent}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
        />
      </div>
    </div>
  )
}

ServiceOfferedDocView.defaultProps = {
  fileSizeMb: '1',
}

export default ServiceOfferedDocView;
