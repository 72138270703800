/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { identity } from 'utils';

import { CardArrayRowContentProps } from '../../../../../types/componentTypes/CardProps';

const CardArrayRowContent = (props: CardArrayRowContentProps) => {
  const { data } = props;
  return (
    <>
      <p className="description-title">
        { props?.title!.replace(/([A-Z])/g, ' $1').trim() }
      </p>
      <div className={props.isRounded! ? 'rounded' : ''}>
        <div className="category-section">
          { identity.isObjWithChildren(data) ? (
            <>
              { props.data.map((cat: any) => (
                <p className="tag-sm--blue" key={`${cat.id}-${cat.name}`}>{cat.name}</p>
              )) }
            </>
          ) : (
            <p className="tag-sm--blue">Not Specified</p>
          )}
        </div>
      </div>
    </>
  );
}
CardArrayRowContent.defaultProps = {
  isRounded: false,
}

export default CardArrayRowContent;
