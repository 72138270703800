import React, { useMemo } from 'react';
import moment from 'moment';

import { About, ProfileOverview, TitleWithActions } from 'components/components';
import { parseStartupCapacity } from 'packages/http_client';
import {
  arrayToLabelValue,
  identity,
  isEitherAdmin,
  isStartup,
} from 'utils';
import routes from 'routes';
import { DetailsProps } from '../../../../types/memberDetails';
import { formatSlashMDY } from '../../../utils/constants/formatters';
import { NOT_SPECIFIED } from '../../../CohortDetails/utils/constants';
import MemberAttributes from '../MemberAttributes';

const Details = (props: DetailsProps) => {
  const { userId, link, userType } = props;
  const {
    firstName,
    lastName,
    imageLink,
    about,
    company,
    email,
    role,
    phoneNumber,
    gender,
    location,
    dateOfBirth,
    founder,
    skills,
    jobFunctions,
    userId: id,
    facebook,
    instagram,
    twitter,
    linkedIn,
  } = props.user;

  const rightContent = [
    { label: 'Company', value: `${company} ${parseStartupCapacity(founder)}` },
    { label: 'Email', value: email },
    { label: 'Role', value: role },
    { label: 'Mobile', value: phoneNumber },
    { label: 'Gender', value: gender },
    { label: 'Date of Birth', value: identity.isTruthyString(dateOfBirth) ? moment(dateOfBirth).format(formatSlashMDY) : NOT_SPECIFIED },
  ];

  const socialMediaLinks = {
    facebook,
    twitter,
    instagram,
    linkedIn,
  }

  const jobFunctionsMapped = useMemo(() => arrayToLabelValue(jobFunctions), [jobFunctions]);
  const skillsMapped = useMemo(() => arrayToLabelValue(skills), [skills]);

  return (
    <div className="profile-details">
      <TitleWithActions
        title={link === routes.editMyProfileUrl ? 'My profile' : 'Profile overview'}
        link={link}
        buttonTitle="profile"
        isEditButton
        showButton={isEitherAdmin(userType) || (isStartup(userType) && userId === id)}
      />
      <ProfileOverview
        heading={`${firstName} ${lastName}`}
        imageUrl={imageLink as string}
        rightContent={rightContent}
        subheading={`${location.city}, ${location.country}`}
        subheadingIsLocation
        coverImage
        socialMediaLinks={socialMediaLinks}
      />
      <About title={`About ${firstName}`} description={about}>
        <MemberAttributes attributeTitle="skills" attributes={skillsMapped} />
        <MemberAttributes attributeTitle="main job functions" attributes={jobFunctionsMapped} />
      </About>
    </div>
  );
}

export default Details;
