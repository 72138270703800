import { LabeledValue } from 'types';
import { getPrimitiveLabeledValueObject } from 'utils/helpers/getLabelValueObject';

export const scrollToTopOfTheForm = () => {
  const stepForm = document.querySelector('.steps-form__fields');
  stepForm?.scroll(0, 0);
}

export const scrollToCurrentStep = () => {
  const stepItem = document.querySelector('.step-item--selected');
  stepItem?.scrollIntoView({ behavior: 'smooth', block: 'end' });
}

export const getYearsOptions = (startingYear: number, endingYear:number) => {
  const years: LabeledValue[] = [];

  for (let year = endingYear; year >= startingYear; year--) {
    const option = getPrimitiveLabeledValueObject(year.toString(), year);
    years.push(option);
  }

  return years;
}

export const formatBlob = (file: Blob) => ({
  uid: Math.floor(Math.random() * 1000).toString(),
  name: 'PitchDeck.pdf',
  status: 'done',
  size: file.size,
})

export const getBlobFromUrl = async (url: string) => await fetch(url).then((res) => res.blob());

export const booleanToYesNoValue = (value: boolean) => Number(value).toString();
export const yesNoValueToBoolean = (value: '1' | '0') => Boolean(Number(value));
