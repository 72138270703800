/* eslint-disable @typescript-eslint/no-explicit-any */
import { TableRowSelection } from 'antd/lib/table/interface';
import React, { useEffect, useState } from 'react'

import { identity, requiredValidation } from 'utils';
import { LayoutType, SelectDrawerProps } from 'types/componentTypes';
import FilterTableDrawer from '../Drawer/components/FilterTableDrawer';
import Input from '../Input';

const SelectDrawer = (props: SelectDrawerProps) => {
  const {
    actions,
    options,
    columns,
    dataSource,
    drawerTitle,
    rowKeyName,
    form,
    name,
    filterBy,
    placeholder,
    label,
    isFilterTableOpen,
    layout,
    required,
  } = props;

  const [value, setValue] = useState<string | number>();
  const [openDrawer, setOpenDrawer] = useState(false);

  useEffect(() => {
    if (!isFilterTableOpen) {
      setOpenDrawer(false);
    }
  }, [isFilterTableOpen, form, name]);

  const rowSelectionRadio: TableRowSelection<any> = {
    type: 'radio',
    selectedRowKeys: [value as React.Key],
    preserveSelectedRowKeys: true && identity.isFullArray(value),
  };

  const onClick = () => {
    actions.setIsDrawerFilterTableOpen({ isDrawerFilterTableOpen: true });
    setOpenDrawer(true);
  }

  return (
    <>
      <Input
        name={name}
        label={label}
        layout={layout as LayoutType}
        required={required}
        rules={required ? [requiredValidation] : []}
      >
        <Input.Select
          onClick={onClick}
          open={false}
          value={value}
          options={options}
          placeholder={`Select ${placeholder}`}
        />
      </Input>
      <FilterTableDrawer
        drawerTitle={drawerTitle}
        columns={columns}
        dataSource={dataSource}
        rowKey={(record) => record?.[rowKeyName]}
        showHeader={false}
        showButtons={false}
        onRow={(record) => ({
          onClick: () => {
            setValue(record.id);
            form.setFieldValue(name, record?.[rowKeyName]);
          },
        })}
        rowSelection={rowSelectionRadio}
        filterBy={filterBy}
        placeholder={placeholder}
        type="radio"
        openDrawer={openDrawer}
        onClose={() => setOpenDrawer(false)}
      />
    </>
  );
}

SelectDrawer.defaultProps = {
  layout: 'vertical',
}

export default SelectDrawer;
