import React from 'react';

import { NUMBER_TO_PARTNER_TYPE } from 'packages/partner_repository';
import { PartnersOverviewTabsProps } from 'types/partners/partnersOverview';
import PartnersTabButton from './TabButton';

import './PartnersOverviewTabs.scss';

const PartnersOverviewTabs = (props: PartnersOverviewTabsProps) => {
  const { partners, tableState } = props;

  // temporary solution until this we get more information about categories
  const tabs = ['all', NUMBER_TO_PARTNER_TYPE[1], NUMBER_TO_PARTNER_TYPE[2], NUMBER_TO_PARTNER_TYPE[3]];

  return (
    <div className="partners-overview-tabs">
      {tabs.map((tab) => (
        <PartnersTabButton tableState={tableState} key={tab} value={tab} partners={partners} />
      ))}
    </div>
  );
}

export default PartnersOverviewTabs;
