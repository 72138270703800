import React from 'react';

import SVGWrap from './SVGWrap';

const IconPlaceholder = () => (
  <SVGWrap size={18}>
    <path d="M0.75 0C0.335786 0 0 0.335786 0 0.75V5.25C0 5.66421 0.335786 6 0.75 6C1.16421 6 1.5 5.66421 1.5 5.25V1.5H5.25C5.66421 1.5 6 1.16421 6 0.75C6 0.335786 5.66421 0 5.25 0H0.75Z" />
    <path d="M0.75 18C0.335786 18 0 17.6642 0 17.25V12.75C0 12.3358 0.335786 12 0.75 12C1.16421 12 1.5 12.3358 1.5 12.75V16.5H5.25C5.66421 16.5 6 16.8358 6 17.25C6 17.6642 5.66421 18 5.25 18H0.75Z" />
    <path d="M17.25 18C17.6642 18 18 17.6642 18 17.25V12.75C18 12.3358 17.6642 12 17.25 12C16.8358 12 16.5 12.3358 16.5 12.75V16.5H12.75C12.3358 16.5 12 16.8358 12 17.25C12 17.6642 12.3358 18 12.75 18H17.25Z" />
    <path d="M17.25 0C17.6642 0 18 0.335786 18 0.75V5.25C18 5.66421 17.6642 6 17.25 6C16.8358 6 16.5 5.66421 16.5 5.25V1.5H12.75C12.3358 1.5 12 1.16421 12 0.75C12 0.335786 12.3358 0 12.75 0H17.25Z" />
  </SVGWrap>
);

export default IconPlaceholder;
