import React, { useMemo, useState } from 'react'
import { Button, Form } from 'antd'
import { RcFile } from 'antd/lib/upload';

import { yesNoOptions } from 'components/RequestServiceDrawer/utils/constants';
import { Row } from 'components/components/FormBuilder/components';
import { FormBuilder, Input, RenderIf } from 'components/components'
import { GetInTouchDrawerProps, GetInTouchPartnerFieldsCreatorParams } from 'types/partners/getInTouch';
import { scrollToFirstError } from 'components/components/FormBuilder/utils/helpers';
import { GetInTouchType } from 'types/partners';
import { PARTNER_TYPE } from 'packages/partner_repository';
import { requiredValidation } from 'utils';
import { getInTouchDrawerBottomFields, getInTouchDrawerTopFields } from './utils/constants'

import './GetInTouchDrawer.scss';

const GetInTouchDrawer = (props: GetInTouchDrawerProps) => {
  const { partnerData, startupId, actions } = props;

  const [form] = Form.useForm();
  const [showPitchDeck, setShowPitchDeck] = useState('no');

  const handleSubmit = (data: GetInTouchType) => {
    const formData = {
      ...data,
      startupID: startupId,
      partnerID: partnerData?.id,
      status: 4,
    }
    actions.getInTouch(formData)
  }

  const onCancel = () => {
    actions.setIsDrawerOpen({ isDrawerOpen: false })
  }

  const beforePitchDeckUpload = (file: RcFile) => {
    form.setFieldValue('pitchDeck', file as Blob);
    return false;
  }

  const getInTouchFieldsCreateData: GetInTouchPartnerFieldsCreatorParams = {
    partnerName: partnerData.name,
  };

  const pitchDeckRules = useMemo(() => (partnerData.type === PARTNER_TYPE.vc
    ? [requiredValidation] : undefined), [partnerData.type]);

  return (
    <Form
      form={form}
      className="form-standalone get-in-touch"
      onFinish={handleSubmit}
      onFinishFailed={scrollToFirstError}
    >
      <FormBuilder
        formFragment
        oneColumn
        fields={getInTouchDrawerTopFields(getInTouchFieldsCreateData)}
      />
      <Row oneColumn>
        <Input label={`Upload ${partnerData.type === PARTNER_TYPE.vc ? 'Pitch' : 'Corporate'} Deck?`} layout="vertical">
          <Input.Radio
            values={yesNoOptions}
            value={showPitchDeck}
            onChange={(e) => setShowPitchDeck(e.target.value)}
          />
        </Input>
        <RenderIf condition={showPitchDeck === 'yes'}>
          <Input
            name="pitchDeck"
            layout="vertical"
            rules={pitchDeckRules}
          >
            <Input.DraggerUpload beforeUpload={beforePitchDeckUpload} accept=".pdf" />
          </Input>
        </RenderIf>
      </Row>

      <FormBuilder
        formFragment
        oneColumn
        fields={getInTouchDrawerBottomFields}
      />

      <div className="submit__wrap">
        <Button type="primary" className="secondary-button" onClick={onCancel}>
          Cancel
        </Button>
        <Button type="primary" htmlType="submit" className="primary-button">
          Submit request
        </Button>
      </div>
    </Form>
  )
}

export default GetInTouchDrawer
