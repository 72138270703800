import { useReducer } from 'react';

import { DataPayload } from 'types/reduxTypes/ActionTypes';
import { UnitType } from 'packages/service_repository';
import { PricingOption } from 'types/services';
import { reducer, initialState } from './reducer';
import {
  setOfferDoc as setOfferDocAC,
  setPriceUnit as setPriceUnitAC,
  setPricingOptions as setPricingOptionsAC,
  setPricingOptionsError as setPricingOptionsErrorAC,
  setServiceActive as setServiceActiveAC,
} from './actions';

const useEditServiceHook = () => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const setOfferDoc = (payload: DataPayload<string | Blob>) => {
    dispatch(setOfferDocAC(payload));
  }

  const setPriceUnit = (data: UnitType) => {
    dispatch(setPriceUnitAC({ data }));
  }

  const setPricingOptions = (payload: DataPayload<PricingOption[]>) => {
    dispatch(setPricingOptionsAC(payload));
  }

  const setPricingOptionsError = (payload: DataPayload<boolean[]>) => {
    dispatch(setPricingOptionsErrorAC(payload));
  }

  const setServiceActive = (payload: DataPayload<boolean>) => {
    dispatch(setServiceActiveAC(payload));
  }

  return {
    state,
    setOfferDoc,
    setPriceUnit,
    setPricingOptions,
    setPricingOptionsError,
    setServiceActive,
  };
};

export default useEditServiceHook;
