import { connect } from 'react-redux';
import { Action, bindActionCreators, Dispatch } from 'redux';

import { getAddPeopleOptions, setCheckedPeople } from '../../../../../../../../redux/actions/activities';
import {
  selectAddPeopleOptions, selectCheckedPeople,
} from '../../../../../../../../redux/selectors/activities';
import { setIsDrawerOpen } from '../../../../../../../../redux/actions/workspace';
import { StateType } from '../../../../../../../../types';

const mapStateToProps = (state: StateType) => ({
  addPeopleOptions: selectAddPeopleOptions(state),
  checkedPeople: selectCheckedPeople(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  actions: bindActionCreators({
    getAddPeopleOptions,
    setIsDrawerOpen,
    setCheckedPeople,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps);
