import { UploadFile } from 'antd';

import { getImageLink, identity } from 'utils';

export const getServiceFiles = (fileLink: string) => {
  if (!identity.isTruthyString(fileLink)) {
    return undefined;
  }

  const url = getImageLink(fileLink);
  const defaultFileList: UploadFile[] = [{
    uid: '-1',
    name: 'ServicePriceList.pdf',
    status: 'done',
    url,
    thumbUrl: url,
  }];

  return defaultFileList;
};

export default null;
