import { ServiceType } from 'packages/service_repository';
import { identity } from 'utils';

export const addPriceUnitsToServicePrice = (priceText: string, priceUnits?: string): string => {
  if (identity.isTruthyString(priceText) && identity.isTruthyString(priceUnits)) {
    let unit = priceUnits?.replace('-', ' ')!;
    unit = unit.toLowerCase();

    if (unit.indexOf('ly') === unit.length - 2) {
      if (unit !== 'daily') {
        unit = unit.slice(0, unit.length - 2);
      } else {
        unit = 'day';
      }
    }

    if (unit.indexOf('per') === -1) {
      unit = `per ${unit}`
    }

    return `${priceText} ${unit}`;
  }

  return priceText;
}

export const getServicePriceText = (data: ServiceType): string => {
  const {
    priceText, offerPrice, priceFrom, priceTo, priceUnits,
  } = data;
  let servicePriceText: string = '';

  if (identity.isTruthyString(priceText)) {
    servicePriceText = priceText!;
  } else if (offerPrice === 'range' && identity.isTruthyString(priceFrom) && identity.isTruthyString(priceTo)) {
    servicePriceText = `AED ${priceFrom} to AED ${priceTo}`;
  } else if (identity.isTruthyString(priceFrom)) {
    servicePriceText = `AED ${priceFrom}`;
  }

  if (identity.isTruthyString(servicePriceText)) {
    return addPriceUnitsToServicePrice(servicePriceText, priceUnits);
  }

  return 'Not Specified';
};
