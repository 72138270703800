import React from 'react';
import { Popover } from 'antd';

import { HelpIcon } from '../../../../../../assets/icons';

const HelpPopover: React.FC = () => {
  const content = (
    <div>
      <p>Content</p>
      <p>Content</p>
    </div>
  );
  return (
    <Popover
      className="help-wrapper"
      placement="bottomRight"
      content={content}
      trigger="click"
    >
      <>
        <HelpIcon />
        <p className="help--title">Help</p>
      </>
    </Popover>
  );
};

export default HelpPopover;
