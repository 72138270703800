import {
  AddServiceProviderOfferPayload,
  AddServiceProviderPayload,
  GetServiceProviderPayload,
  PatchServiceProviderPayload,
  SetServiceProviderPayload,
  CreateRequestedServicePayload,
  SetRequestedServicePayload,
} from 'types/reduxTypes/ActionTypes/ServiceProviderTypes';
import { ChangeRequestStatusPayload } from 'types/reduxTypes/ActionTypes/RequestsTypes';
import { SetStartupByIdPayload } from 'types/reduxTypes/ActionTypes/StartupsTypes';
import {
  IsLoadingPayload,
  IdPayload,
  DataPayload,
  GetAllQueryPayload,
} from 'types/reduxTypes/ActionTypes';
import { ParamsPayload } from 'packages/http_client';
import { ServiceProviderType } from 'types/serviceProviders';

export const types = {
  GET_SERVICE_PROVIDERS: 'GET_SERVICE_PROVIDERS',
  SET_SERVICE_PROVIDERS: 'SET_SERVICE_PROVIDERS',
  GET_SERVICE_PROVIDER_BY_ID: 'GET_SERVICE_PROVIDER_BY_ID',
  SET_SERVICE_PROVIDER_BY_ID: 'SET_SERVICE_PROVIDER_BY_ID',
  SET_STARTUP_DETAILS_ON_SPD: 'SET_STARTUP_DETAILS_ON_SPD',
  ADD_SERVICE_PROVIDER_OFFER:'ADD_SERVICE_PROVIDER_OFFER',
  ADD_SERVICE_PROVIDER: 'ADD_SERVICE_PROVIDER',
  PATCH_SERVICE_PROVIDER_DATA_BY_ID: 'PATCH_SERVICE_PROVIDER_DATA_BY_ID',
  SET_SERVICE_PROVIDER_LOADING: 'SET_SERVICE_PROVIDER_LOADING',
  SET_SERVICE_PROVIDER_REQUEST_STATUS: 'SET_SERVICE_PROVIDER_REQUEST_STATUS',
  GET_SERVICES_BY_SERVICE_PROVIDER_ID: 'GET_SERVICES_BY_SERVICE_PROVIDER_ID',
  GET_SP_SERVICE_BY_ID: 'GET_SP_SERVICE_BY_ID',
  ADD_REQUESTED_SERVICE: 'ADD_REQUESTED_SERVICE',
  SET_REQUESTED_SERVICE: 'SET_REQUESTED_SERVICE',
  GET_SERVICE_PROVIDERS_COUNT: 'GET_SERVICE_PROVIDERS_COUNT',
  SET_SERVICE_PROVIDERS_COUNT: 'SET_SERVICE_PROVIDERS_COUNT',
  RESET_SP: 'RESET_SP',
};

export const resetSP = (payload: object = {}) => ({
  type: types.RESET_SP,
  payload,
});

export const getServiceProviders = (payload: GetAllQueryPayload<ParamsPayload> = { query: {} }) => ({
  type: types.GET_SERVICE_PROVIDERS,
  payload,
});

export const setServiceProviders = (payload: DataPayload<ServiceProviderType[]>) => ({
  type: types.SET_SERVICE_PROVIDERS,
  payload,
});

export const getServiceProviderById = (payload: GetServiceProviderPayload) => ({
  type: types.GET_SERVICE_PROVIDER_BY_ID,
  payload,
});

export const setServiceProviderById = (payload: SetServiceProviderPayload) => ({
  type: types.SET_SERVICE_PROVIDER_BY_ID,
  payload,
});

export const setStartupDetails = (payload: SetStartupByIdPayload) => ({
  type: types.SET_STARTUP_DETAILS_ON_SPD,
  payload,
});

export const addServiceProviderOffer = (payload: AddServiceProviderOfferPayload) => ({
  type: types.ADD_SERVICE_PROVIDER_OFFER,
  payload,
});

export const addServiceProvider = (payload: AddServiceProviderPayload) => ({
  type: types.ADD_SERVICE_PROVIDER,
  payload,
});

export const patchServiceProviderDataById = (payload: PatchServiceProviderPayload) => ({
  type: types.PATCH_SERVICE_PROVIDER_DATA_BY_ID,
  payload,
});

export const setServiceProviderLoading = (payload: IsLoadingPayload) => ({
  type: types.SET_SERVICE_PROVIDER_LOADING,
  payload,
});

export const setServiceProviderRequestStatus = (payload: ChangeRequestStatusPayload) => ({
  type: types.SET_SERVICE_PROVIDER_REQUEST_STATUS,
  payload,
});

export const getServicesByServiceProviderId = (payload: IdPayload) => ({
  type: types.GET_SERVICES_BY_SERVICE_PROVIDER_ID,
  payload,
});

export const getServiceById = (payload: IdPayload) => ({
  type: types.GET_SP_SERVICE_BY_ID,
  payload,
});

export const createRequestedService = (payload: CreateRequestedServicePayload) => ({
  type: types.ADD_REQUESTED_SERVICE,
  payload,
});

export const setRequestedServiceStatus = (payload: SetRequestedServicePayload) => ({
  type: types.SET_REQUESTED_SERVICE,
  payload,
});

export const getServiceProvidersCount = (payload: GetAllQueryPayload<ParamsPayload>) => ({
  type: types.GET_SERVICE_PROVIDERS_COUNT,
  payload,
});

export const setServiceProvidersCount = (payload: DataPayload<number>) => ({
  type: types.SET_SERVICE_PROVIDERS_COUNT,
  payload,
});
