import React from 'react';

import SVGWrap from './SVGWrap';

const ChevronUpIcon = () => (
  <SVGWrap>
    <path xmlns="http://www.w3.org/2000/svg" d="M8 5L13 10L12.3 10.7L8 6.4L3.7 10.7L3 10L8 5Z" />
  </SVGWrap>
);

export default ChevronUpIcon;
