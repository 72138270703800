import {
  all, call, put, takeLatest,
} from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';

import { identity } from 'utils';
import { types, setUsers } from 'redux/actions/users';
import showNotification from 'services/utils/showNotification';
import { GetAllQueryPayload } from 'types/reduxTypes/ActionTypes';
import { UserQuery } from 'packages/user_repository/types/users';
import { getUsers } from 'packages/user_repository/api';

function * handleGetUsers(action: PayloadAction<GetAllQueryPayload<UserQuery>>) {
  const { data: users, error, httpStatus } = yield call(getUsers, action.payload.query);

  if (identity.isObjWithChildren(error)) {
    showNotification(`Error fetching service providers: ${error.message}`, true, httpStatus);
  } else {
    yield put(setUsers({ users }));
  }
}

export default function * serviceProvidersSagas() {
  yield all([
    takeLatest(types.GET_USERS, handleGetUsers),
  ]);
}
