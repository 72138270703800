import { connect } from 'react-redux';
import { bindActionCreators, Dispatch, Action } from 'redux';

import { StateType } from 'types';
import { selectUserType } from 'redux/selectors/auth';
import { selectEventsCurrentTab } from 'redux/selectors/events';
import { setDrawerBody, setIsDrawerOpen } from 'redux/actions/workspace';
import { setEventId } from 'redux/actions/events';

const mapStateToProps = (state: StateType) => ({
  userType: selectUserType(state),
  currentTab: selectEventsCurrentTab(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  actions: bindActionCreators({
    setDrawerBody,
    setIsDrawerOpen,
    setEventId,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps);
