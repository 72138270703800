import React, {
  useEffect,
  useMemo,
  useCallback,
  useState,
} from 'react';
import { Button, Tabs, Spin } from 'antd';

import { identity } from 'utils';
import { OnAddNewSettings, SettingsProps } from 'types/settingsOverview';
import { RenderIf, DeleteConfirmModal } from 'components/components';
import { AddIcon, TrashCanIcon } from 'assets/icons';
import {
  tabsValues,
  TABS_COMPONENT_LOOKUP,
  TABS_SUBTABS_LOOKUP,
  getActionTabLookup,
} from './utils';

import './Settings.scss';

const Settings = (props: SettingsProps) => {
  const {
    isLoading, activeTab, activeSubTab, deleteIds, actions,
  } = props;

  const [open, setOpen] = useState<boolean>(false);

  const ACTIONS_TAB_LOOKUP = useMemo(() => getActionTabLookup(actions), []);

  useEffect(() => {
    const tab = TABS_SUBTABS_LOOKUP[activeTab][0] || '';
    actions.setActiveSubTab({ tab });
  }, [activeTab]);

  const handleOnTabChange = (tab: string) => {
    actions.setActiveTab({ tab });
  }

  const handleOnSubTabChange = (tab: string) => {
    actions.setActiveSubTab({ tab });
  }

  const onAddNew = useCallback((settings?: OnAddNewSettings) => {
    const drawerBody = TABS_COMPONENT_LOOKUP[activeSubTab || activeTab].drawer;

    const { isEdit } = settings || {};

    if (!isEdit) {
      actions.resetSettingsDetails();
    }

    actions.setDrawerBody({ drawerBody });
    actions.setIsDrawerOpen({ isDrawerOpen: true });
  }, [activeTab, activeSubTab]);

  const onDelete = () => {
    const handleDelete = ACTIONS_TAB_LOOKUP[activeSubTab || activeTab];
    handleDelete();
    setOpen(false);
  }

  const TabComponent = TABS_COMPONENT_LOOKUP[activeSubTab || activeTab].component;

  return (
    <div className="content-container">
      <Spin spinning={isLoading}>
        <div className="settings">
          <Tabs tabBarGutter={0} activeKey={activeTab} onChange={handleOnTabChange} className="tab-buttons-lg custom-tabs">
            {tabsValues.map((tab) => <Tabs.TabPane tab={tab} key={tab} />)}
          </Tabs>
          <div className="settings__subtabs">
            <RenderIf condition={TABS_SUBTABS_LOOKUP[activeTab].length > 0}>
              <Tabs tabBarGutter={0} activeKey={activeSubTab} onChange={handleOnSubTabChange}>
                {TABS_SUBTABS_LOOKUP[activeTab].map((tab) => <Tabs.TabPane tab={tab} key={tab} />)}
              </Tabs>
            </RenderIf>
            <div className="settings__subtabs-operations">
              {identity.isFullArray(deleteIds)
                ? <Button icon={<TrashCanIcon />} onClick={() => setOpen(true)} className="secondary-delete-button">Delete selected</Button>
                : <Button icon={<AddIcon />} className="secondary-button" onClick={() => onAddNew()}>Add new</Button>}
            </div>
          </div>
          <TabComponent onAddNew={onAddNew} />
          <DeleteConfirmModal
            isOpen={open}
            onCancel={() => setOpen(false)}
            itemName={activeSubTab || activeTab}
            isDeleteAll
            onConfirm={onDelete}
          />
        </div>
      </Spin>
    </div>
  )
}

export default Settings;
