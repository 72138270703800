import { arrayToString, identity } from 'utils';
import {
  CreateEventAttendeePayload,
  CreateEventAttendeeRequestPayload,
  CreateEventPayload,
  CreateEventRequestPayload,
  CreateEventRSVPPayload,
  CreateEventRSVPRequestPayload,
  EventAttendeeType,
  EventBeType,
  EventCategory,
  EventRSVPBeType,
  EventRSVPType, EventStatus,
  EventType,
  UpdateEventPayload,
  UpdateEventRequestPayload,
} from '../types';
import {
  EVENT_RSVP_KEYS,
  EVENT_RSVP_RESPONSE,
  EVENT_STATUS,
  EVENT_TYPE,
  // EVENT_TYPE_TO_NUMBER,
} from './constants';
import { EventResponseCount, EventsCount } from '../../../types/events';
import { ParamsPayload, queryBuilder } from '../../http_client';

export const convertEvent = (data?: EventBeType): EventType | undefined => {
  if (identity.isObjWithChildren(data)) {
    return ({
      id: data!.id,
      address: data!.address,
      city: data!.city,
      active: data!.active,
      category: data!.category,
      country: data!.country,
      description: data!.description,
      endingTime: data!.endingTime,
      frequency: data!.frequency,
      image: data!.image,
      link: data!.link,
      location: data!.location,
      name: data!.name,
      private: data!.private,
      startingTime: data!.startingTime,
      status: EVENT_STATUS[data!.status],
      type: EVENT_TYPE[data!.type],
    });
  }
}

export const convertEvents = (data?: EventBeType[]): EventType[] => {
  const events: EventType[] = [];
  if (identity.isFullArray(data)) {
    for (const i in data!) {
      if (identity.isObjWithChildren(data[i])) {
        events.push(convertEvent(data[i])!);
      }
    }
  }
  return events;
}

export const convertEventRSVP = (data?: EventRSVPBeType): EventRSVPType | undefined => {
  if (identity.isObjWithChildren(data)) {
    return ({
      id: data!.id,
      event: convertEvent(data?.event)!,
      user: data!.user,
      response: EVENT_RSVP_RESPONSE[data!.response!],
    });
  }
}

export const convertEventRSVPs = (data?: EventRSVPBeType[]): EventRSVPType[] => {
  const eventRSVPs: EventRSVPType[] = [];
  if (identity.isFullArray(data)) {
    for (const i in data!) {
      eventRSVPs.push(convertEventRSVP(data[i])!);
    }
  }
  return eventRSVPs;
}

export const convertEventRSVPToEvent = (data?: EventRSVPBeType): EventType => ({
  ...convertEvent(data?.event)!,
  eventResponse: !identity.isFalsy(data?.response) ? EVENT_RSVP_RESPONSE[data?.response!] : 'not confirmed',
  eventRsvpId: data?.id!,
});

export const convertEventsRSVPToEvents = (data?: EventRSVPBeType[]): EventType[] => {
  const events: EventType[] = [];
  if (identity.isFullArray(data)) {
    for (const i in data!) {
      events.push(convertEventRSVPToEvent(data[i])!);
    }
  }
  return events;
}

export const convertEventsCountResponse = (type: 'all' | 'response' | 'status', data?: { id__count: number, response?: number, events__status?: number, status?: number }[])
  : number | EventsCount | EventResponseCount => {
  switch (type) {
    case 'all': {
      return identity.isFullArray(data) ? data![0].id__count : 0;
    }
    case 'response': {
      const response: EventResponseCount = {
        going: 0, notGoing: 0, interested: 0, all: 0, notConfirmed: 0,
      };
      for (const i in data) {
        const key: keyof EventResponseCount = EVENT_RSVP_KEYS[data[Number(i)].response!];
        response[key] = data[Number(i)].id__count;
        response.all += data[Number(i)].id__count;
      }
      return response;
    }
    case 'status': {
      const response: EventsCount = { upcoming: 0, past: 0 };
      for (const i in data) {
        let key: EventStatus;
        if (identity.isTruthyNumber(data[Number(i)].events__status)) {
          key = EVENT_STATUS[data[Number(i)].events__status!];
        } else {
          key = EVENT_STATUS[data[Number(i)].status!];
        }
        response[key as keyof EventsCount] = data[Number(i)].id__count;
      }
      return response;
    }
    default: {
      return 0;
    }
  }
}
export const convertEventCategory = (data?: EventCategory): EventCategory | undefined => {
  if (identity.isObjWithChildren(data)) {
    return ({
      id: data?.id!,
      name: data?.name!,
    });
  }
}

export const convertEventCategories = (data?: EventCategory[] | undefined): EventCategory[] => {
  const eventCategories: EventCategory[] = [];
  if (identity.isFullArray(data)) {
    for (const i in data!) {
      eventCategories.push(convertEventCategory(data[i])!);
    }
  }
  return eventCategories;
};

export const convertCreateEventRequestPayload = (payload: CreateEventPayload): CreateEventRequestPayload => ({
  _name: payload.name,
  _active: payload.active,
  _address: payload.address,
  _category_id: payload.category,
  _city: payload.city,
  _country: payload.country,
  _description: payload.description,
  _ending_time: payload.endingTime,
  _frequency: payload.frequency,
  _image: payload.image,
  _link: payload.link,
  _location: payload.location,
  _private: payload.private,
  _starting_time: payload.startingTime,
  _status: payload.status,
  _type: payload.type,
});

export const convertCreateEventAttendeeRequestPayload = (payload: CreateEventAttendeePayload)
  : CreateEventAttendeeRequestPayload => {
  const convertedPayload: CreateEventAttendeeRequestPayload = {
    _attendee_group: payload.attendee_group,
    _event_id: payload.event_id,
  }
  for (const i in payload.user_id) {
    convertedPayload[`_user_id__${i}`] = payload.user_id[Number(i)];
  }
  return convertedPayload;
};

export const convertCreateEventRSVPPayload = (payload: CreateEventRSVPRequestPayload)
  : CreateEventRSVPPayload => {
  const convertedPayload: CreateEventRSVPPayload = {
    _event_id: payload?.eventId,
    _response: payload?.response!,
  }
  for (const i in payload.userId) {
    convertedPayload[`_user_id__${i}`] = payload.userId[Number(i)];
  }
  return convertedPayload;
};

export const eventRSVPQuery = (query?: ParamsPayload, idCount: boolean = false) => {
  const qParams = queryBuilder({ user_id: query?.userId, response: query?.response })
    .limit(query?.limit)
    .offset(query?.offset)
    .preload();
  if (idCount) {
    if (identity.isFullArray(query?.fieldSelection)) {
      qParams.select([{ field: 'id', aggregation: 'count' }, ...query!.fieldSelection!]);
    } else {
      qParams.select([{ field: 'id', aggregation: 'count' }]);
    }
  }
  if (identity.isFullArray(query?.groupBy) || identity.isTruthyString(query?.groupBy)) {
    qParams.groupBy(query?.groupBy);
  }
  if (identity.isFullArray(query?.eventResponse)) {
    qParams.in({ response: query?.eventResponse as number[] });
  }
  const fields = [];
  const queryTable = [];
  const tableName = 'events';
  if (identity.isPositiveNumber(query?.eventStatus)
    || identity.isFullArray(query?.frequency)
    || identity.isFullArray(query?.categories)
    || identity.isFullArray(query?.type)
    || identity.isTruthyString(query?.startingTime)
    || identity.isTruthyString(query?.endingTime)) {
    fields.push({ tableName, onId: 'event_id' });
  }
  if (identity.isPositiveNumber(query?.eventStatus)) {
    queryTable.push({ tableName, field: 'status', value: JSON.stringify(query?.eventStatus) });
  }
  if (identity.isFullArray(query?.frequency)) {
    queryTable.push({ tableName, field: 'frequency__in', value: arrayToString(query?.frequency as number[]) });
  }
  if (identity.isFullArray(query?.categories)) {
    queryTable.push({ tableName, field: 'category_id__in', value: arrayToString(query?.categories) });
  }
  if (identity.isFullArray(query?.type)) {
    queryTable.push({ tableName, field: 'type__in', value: arrayToString(query?.type as number[]) });
  }
  if (identity.isTruthyString(query?.startingTime)) {
    queryTable.push({ tableName, field: 'starting_time__contains', value: query?.startingTime as string });
  }
  if (identity.isTruthyString(query?.endingTime)) {
    queryTable.push({ tableName, field: 'ending_time__contains', value: query?.endingTime as string });
  }
  qParams.join(fields).queryRelatedTable(queryTable);
  return qParams.toObject();
}
export const convertEventAttendee = (data?: EventAttendeeType): EventAttendeeType | undefined => {
  if (identity.isObjWithChildren(data)) {
    return ({
      id: data?.id!,
      event: data?.event!,
      user: data?.user!,
      attendeeGroup: data?.attendeeGroup!,
    });
  }
}

export const convertEventAttendeeList = (data?: EventAttendeeType[] | undefined): EventAttendeeType[] => {
  const eventAttendeesList: EventAttendeeType[] = [];
  if (identity.isFullArray(data)) {
    for (const i in data!) {
      eventAttendeesList.push(convertEventAttendee(data[i])!);
    }
  }
  return eventAttendeesList;
};

export const convertEditEventRequestPayload = (payload: UpdateEventPayload): UpdateEventRequestPayload => ({
  _name: payload?.name!,
  _active: payload?.active!,
  _address: payload?.address!,
  _category_id: payload?.category,
  _city: payload?.city!,
  _country: payload?.country!,
  _description: payload?.description!,
  _ending_time: payload?.endingTime!,
  _starting_time: payload?.startingTime!,
  _frequency: payload?.frequency!,
  _image: payload?.image!,
  _link: payload?.link!,
  _location: payload?.location!,
  _private: payload?.private!,
  _status: payload?.status!,
  _type: payload?.type,
});

export const convertEventRSVPResponse = (data?: EventRSVPBeType): EventRSVPType => ({
  id: data?.id!,
  eventId: data?.eventId,
  user: data?.user,
  response: EVENT_RSVP_RESPONSE[data?.response!],
})

export const convertEditEventRSVPPayload = (payload: EventRSVPBeType): CreateEventRSVPPayload => ({
  _crated_at: payload?.createdAt!,
  _event_id: payload?.eventId!,
  _user_id: payload?.userId!,
  _response: payload?.response!,
});
