import React from 'react';

const SVGWrap = (props: {
  children: React.ReactElement | React.ReactElement[], fill?: string, size?: number | undefined, stroke?: number,
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.size}
    height={props.size}
    viewBox={`0 0 ${props.size} ${props.size}`}
    fill={props.fill}
    stroke="currentColor"
    strokeWidth={props.stroke}
  >
    {props.children}
  </svg>
)

SVGWrap.defaultProps = {
  size: 16,
  fill: 'currentColor',
  stroke: 0,
}

export default SVGWrap;
