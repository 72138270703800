import { PartnerType } from 'packages/partner_repository';
import { PartnerType as OldPartnerType } from 'types/partners'
import { PartnersStateType } from 'types/reduxTypes/StateTypes';

export const partnerDef: PartnerType = {
  active: false,
  contactEmail: '',
  contactFirstName: '',
  contactLastName: '',
  contactPhone: '',
  contactRole: '',
  description: '',
  id: 0,
  location: '',
  logo: '',
  moU: '',
  moUSigned: '',
  name: '',
  type: '',
  website: '',
}

export const defaultPartner: OldPartnerType = {
  id: -1,
  name: '',
  type: '',
  website: '',
  status: [],
  mubadala: '',
  imageLink: '',
  about: '',
  contactPersonFirstName: '',
  contactPersonLastName: '',
  email: '',
  phoneNumber: '',
  role: '',
  category: [],
  location: '',
  recipients: [],
}

export const defaultPartnersState: PartnersStateType = {
  isLoading: false,
  partners: [],
  filtersActive: false,
  currentTab: 'all',
  details: {},
  partnerData: partnerDef,
};
