import React, { useEffect, useState } from 'react';
import { Button } from 'antd';
import moment from 'moment';

import { CohortDetailsProps } from 'types/startups/startupDetails';
import { Overview } from 'components/components';
import { identity } from 'utils';
import { DetailsRow, CohortDetailsModal } from './components';
import { formatSlashMDY } from '../../../utils/constants/formatters';
import { NOT_SPECIFIED } from '../../../CohortDetails/utils/constants';

import './CohortDetails.scss';

const CohortDetails = (props: CohortDetailsProps) => {
  const { details, startups } = props;
  const {
    name,
    from,
    to,
    amount,
  } = details;
  const [showCohortDetails, setShowCohortDetails] = useState<boolean>(false);

  useEffect(() => {
    props.actions.getStartupsInCohort({ id: details.id });
  }, [details]);

  return (
    <Overview
      title="Cohort Details"
      headerContent={(
        <Button
          className="secondary-button"
          onClick={() => setShowCohortDetails(true)}
        >
          View Details
        </Button>
      )}
    >
      <div className="startup-details__cohort-details">
        <DetailsRow
          leftInfoContent={name}
          leftInfoTag="Name"
          rightInfoContent={`AED ${amount}`}
          rightInfoTag="Amount"
          invert={false}
        />
        <DetailsRow
          leftInfoContent={identity.isTruthyString(from) ? moment(from).format(formatSlashMDY) : NOT_SPECIFIED}
          leftInfoTag="From"
          rightInfoContent={identity.isTruthyString(to) ? moment(to).format(formatSlashMDY) : NOT_SPECIFIED}
          rightInfoTag="To"
          invert={false}
        />
      </div>
      <CohortDetailsModal
        cohort={details}
        setShowCohortDetails={setShowCohortDetails}
        showCohortDetails={showCohortDetails}
        startups={startups}
      />
    </Overview>
  );
}

export default CohortDetails;
