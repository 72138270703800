import React from 'react'
import { Button } from 'antd';

import { DrawerButtonsProps } from 'types/componentTypes/Drawer';
import DrawerCancelButton from '../DrawerCancelButton';

import './DrawerButtons.scss';

const DrawerButton = (props: DrawerButtonsProps) => {
  const { children } = props;

  return (
    <div className="drawer-buttons">
      <DrawerCancelButton />
      <Button htmlType="submit" className="primary-button">{children}</Button>
    </div>
  )
}

export default DrawerButton;
