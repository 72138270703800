import React, { useState } from 'react';
import { Col, Row, Switch } from 'antd';

import { AdditionalOptionsProps } from 'types/services/addService';
import { Input, RenderIf } from '../../../components';
import { OptionValues } from '../../utils';

import './AdditionalOptions.scss';

const AdditionalOptions = (props: AdditionalOptionsProps) => {
  const {
    additionalOptionName,
    additionalOptionPrice,
    onChangeAdditionalOptionName,
    onChangeAdditionalOptionPrice,
  } = props;

  const [isAdditionalOption, setIsAdditionalOption] = useState(false);

  const onSwitch = (value: boolean) => {
    setIsAdditionalOption(value)
  }

  return (
    <div className="additional-options">
      <p>Additional options</p>
      <div className="additional-options-container">
        <div className="switch-wrap">
          <Switch
            size="small"
            onChange={(e) => onSwitch(e)}
          />
          <p className="switch-wrap-label">Furnishing</p>
        </div>
        <RenderIf condition={isAdditionalOption}>
          <Row className="additional-options-fields">
            <Col span={12}>
              <Input required layout="vertical">
                <Input.InputField
                  type="text"
                  name="price"
                  placeholder="Price for furnishing"
                  className="input_fields"
                  value={additionalOptionPrice}
                  onChange={(e) => onChangeAdditionalOptionPrice(e.target.value)}
                />
              </Input>
            </Col>
            <Col span={12}>
              <Input required layout="vertical">
                <Input.Select
                  options={OptionValues}
                  value={additionalOptionName}
                  onChange={(e) => onChangeAdditionalOptionName(e)}
                >
                  {additionalOptionName}
                </Input.Select>
              </Input>
            </Col>
          </Row>
        </RenderIf>
      </div>
    </div>
  );
}
export default AdditionalOptions;
