import { connect } from 'react-redux';
import { bindActionCreators, Dispatch, Action } from 'redux';

import { getRequestById } from '../../redux/actions/request';

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  actions: bindActionCreators({ getRequestById }, dispatch),
});

export default connect(null, mapDispatchToProps);
