import React, { useCallback, useEffect, useState } from 'react'
import { RadioChangeEvent } from 'antd';

import { StepCustomFieldComponentProps } from 'types/componentTypes/StepsForm';
import { FormBuilder, Input, RenderIf } from 'components/components';
import { identity, positiveNumbersOnly } from 'utils';
import { Row } from 'components/components/FormBuilder/components';
import { raisingInformationFields, yesNoOptions } from '../../utils/formFields';
import { booleanToYesNoValue, yesNoValueToBoolean } from '../../utils/helpers';

const RaisingInformationFields = (props: StepCustomFieldComponentProps) => {
  const { form, investmentStageOptions } = props;
  const currentlyRaisingName = 'currentlyRaising';
  const investmentStageName = 'investmentStageId';
  const bridgeRoundName = 'bridgeRound';
  const currentlyRaisingAmountName = 'currentlyRaisingAmount';

  const [currentlyRaising, setcurrentlyRaising] = useState(
    yesNoValueToBoolean(form?.getFieldValue(currentlyRaisingName))
    || identity.isTruthyNumber(form?.getFieldValue(investmentStageName))
    || form?.getFieldValue(bridgeRoundName)
    || '0',
  );

  const onRadioChange = useCallback((e: RadioChangeEvent) => {
    setcurrentlyRaising(yesNoValueToBoolean(e.target.value));
  }, []);

  useEffect(() => () => {
    if (form?.getFieldValue(currentlyRaisingName) === '0') {
      form?.setFieldValue(investmentStageName, undefined);
      form?.setFieldValue(bridgeRoundName, undefined);
      form?.setFieldValue(currentlyRaisingAmountName, undefined);
    }
  }, []);

  return (
    <>
      <Row oneColumn>
        <Input
          layout="vertical"
          label="Currently raising"
          name={currentlyRaisingName}
        >
          <Input.Radio
            values={yesNoOptions}
            defaultValue={Number(currentlyRaising).toString()}
            onChange={onRadioChange}
          />
        </Input>
      </Row>
      <RenderIf condition={yesNoValueToBoolean(currentlyRaising)}>
        <>
          <Row oneColumn>
            <Input
              layout="vertical"
              label="Currently raising(USD)"
              name={currentlyRaisingAmountName}
              rules={[positiveNumbersOnly]}
            >
              <Input.InputNumber />
            </Input>
          </Row>
          <Row oneColumn>
            <Input
              layout="vertical"
              label="Fundraising Stage"
              name="investmentStageId"
            >
              <Input.Select options={investmentStageOptions} />
            </Input>
          </Row>
          <Row oneColumn>
            <Input
              layout="vertical"
              label="This is a bridge round"
              name="bridgeRound"
            >
              <Input.Radio values={yesNoOptions} defaultValue={booleanToYesNoValue(false)} />
            </Input>
          </Row>
        </>
      </RenderIf>
      <FormBuilder
        fields={raisingInformationFields}
        formFragment
        oneColumn
      />
    </>
  )
};

export default RaisingInformationFields;
