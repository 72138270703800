import { connect } from 'react-redux';
import { bindActionCreators, Dispatch, Action } from 'redux';

import { getStartupsInCohort } from 'redux/actions/startups';
import { StateType } from '../../../../types';
import { selectCohortDetails, selectStartups } from '../../../../redux/selectors/startups';

const mapStateToProps = (state: StateType) => ({
  details: selectCohortDetails(state),
  startups: selectStartups(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  actions: bindActionCreators({
    getStartupsInCohort,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps);
