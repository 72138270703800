import {
  all, call, put, takeLatest, select,
} from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';

import { setDestroyDrawerOnClose, setIsDrawerLoading, setIsDrawerOpen } from 'redux/actions/workspace';
import config from 'assets/config';
import {
  createStartup,
  createStartupADGMLicense,
  CreateStartupPayload,
  CreateStartupResponse,
  getInvestmentStagesList,
  getSectorsList,
  getStagesList,
  getStartupsCountApi,
  getStartupsList,
  getStartupById,
  getStartupsInCohort,
  StartupType,
  updateStartup,
  getStartupADGMLicenseByStartupId,
  updateAdgmLicenseByStartupId,
  StartupQuery,
  getStartupsCountWorkingWithIdCount,
  getStartupIncentiveAgreementById,
  createStartupIncentiveAgreement,
  updateStartupIncentiveAgreement,
  IncentiveAgreementType,
} from 'packages/startup_repository';
import {
  createStartupTeamMember,
  CreateTeamMemberPayload,
  getStartupTeamMemberCount,
  getStartupTeamMembers,
  GetTeamMembersParams,
} from 'packages/people_repository';
import {
  identity,
  isEitherAdmin,
  isPartner,
  isStartup,
  ROLES,
} from 'utils';
import { CreateADGMLicensePayload } from 'packages/startup_repository/types/adgm_license';
import {
  selectCohortDetails,
  selectIncentiveAgreement,
  selectStartupDetails,
  selectStartupForReview,
} from 'redux/selectors/startups';
import {
  GetStartupsByOwnerIdPayload,
  UpdateStartupPayload,
  GetStartupByIdPayload,
  UpdateAdgmLicenseByStartupIdPayload,
  UpdateStartupDocumentStatusPayload,
} from 'types/reduxTypes/ActionTypes/StartupsTypes';
import { GetTeamMembersPayload } from 'types/reduxTypes/ActionTypes/TeamMemberTypes';
import showNotification from 'services/utils/showNotification';
import { DataPayload, GetAllQueryPayload, IdPayload } from 'types/reduxTypes/ActionTypes';
import sleep from 'components/utils/mocks/sleep';
import { PeopleFilters } from 'types/people';
import { UserRoleType } from 'types/auth';
import { ParamsPayload } from 'packages/http_client';
import { getStartupSpsList } from 'packages/service_repository';
import { getAmountSumsBySpAndStartupApi, GetAmountSumsResponse } from 'packages/wallet_repository';
import { ServiceProviderType } from 'types/serviceProviders';
import {
  CreateIncentiveAgreementPayload,
  UpdateIncentiveAgreementPayload,
} from 'types/startups';
import { CohortType } from 'types/cohorts';
import { getDocumentNameFromType } from 'components/CohortDetails/components/Requests/utils/helpers'
import { setCohortStartups, setReviewDocumentRequests } from '../../actions/cohort';
import { setStartupDetails } from '../../actions/serviceProviders';
import {
  setStartupById,
  setStartups,
  types,
  createStartupADGMLicense as createStartupADGMLicenseAC,
  setStartupTeamMembers,
  setSectorOptions,
  setStageOptions,
  setInvestmentStageOptions,
  setIsLoading,
  setStartupsCount,
  setTeamMembersCount,
  getStartupById as reduxGetStartupById,
  getStartups,
  setADGMLicenseByStartupId,
  setIncentiveAgreementById,
  setStartupServiceProviders,
  setSumsForServiceProviders,
  updateStartupById,
  getStartupsByOwnerId,
  setStartupForReview,
} from '../../actions/startups';
import {
  selectPartnerId,
  selectUserServiceProviderId,
  selectUserStartup,
  selectUserStartupId,
  selectUserType,
} from '../../selectors/auth';
import { selectIsRowTableLayout } from '../../selectors/workspace';
import { selectDocumentReviewRequests } from '../../selectors/cohort';
import { defaultIncentiveAgreement } from '../../utils';

function * handleGetStartups(action: PayloadAction<StartupQuery>) {
  yield put(setIsLoading({ isLoading: true }));

  const userType: UserRoleType = yield select(selectUserType);
  let query = action.payload;
  switch (userType) {
    case ROLES.partner: {
      const partnerId: number = yield select(selectPartnerId);
      query = { ...query, partnerId, statusID: 7 };
      break;
    }
    case ROLES.serviceProvider: {
      const serviceProviderId: number = yield select(selectUserServiceProviderId);
      query = { ...query, serviceProviderId };
      break;
    }
    default: {
      break;
    }
  }

  const { data: startups, error, httpStatus } = yield call(getStartupsList, query);
  if (identity.isObjWithChildren(error)) {
    showNotification(`Unable to fetch startups: ${error.message}`, true, httpStatus);
  } else {
    yield put(setStartups({ startups }));
  }
  yield put(setIsLoading({ isLoading: false }));
}

function * handleGetSectors() {
  const { data, error, httpStatus } = yield call(getSectorsList);
  if (identity.isObjWithChildren(error)) {
    showNotification(error.message, true, httpStatus);
  } else {
    yield put(setSectorOptions({ data }));
  }
}

function * handleGetStartupById(action: PayloadAction<GetStartupByIdPayload>) {
  yield put(setIsLoading({ isLoading: true }));
  const { id, owner, isUpdate } = action.payload;
  const selectedStartup: StartupType = yield select(selectStartupDetails);
  const { id: selectedStartupId } = selectedStartup;

  if (!(id === selectedStartupId && identity.isFalsy(isUpdate) && owner === 'service-provider')) {
    const {
      data: startup,
      error,
      httpStatus,
    } = yield call(getStartupById, id, true);

    if (identity.isObjWithChildren(error)) {
      showNotification(`Unable to fetch startup: ${error?.message}`, true, httpStatus);
    } else {
      switch (owner) {
        case 'startup': {
          yield put(setStartupById({ startup }));
          break;
        }
        case 'service-provider': {
          yield put(setStartupDetails({ startup }));
          break;
        }
        default:
          break;
      }
    }
  }
  yield put(setIsLoading({ isLoading: false }));
}

function * handleCreateStartupADGMLicense(action: PayloadAction<CreateADGMLicensePayload>) {
  yield put(setIsDrawerLoading({ isLoading: true }));

  const { startupId } = action.payload;
  const { error, httpStatus } = yield call(createStartupADGMLicense, action.payload);

  if (identity.isObjWithChildren(error)) {
    showNotification(`Something went wrong: ${error.message}`, true, httpStatus);
  } else {
    showNotification('Startup ADGM License uploaded successfully');
    yield put(reduxGetStartupById({ id: startupId, owner: 'startup', isUpdate: false }));
    yield put(setDestroyDrawerOnClose({ destroyDrawerOnClose: true }));
    yield put(setIsDrawerOpen({ isDrawerOpen: false }));
  }

  yield put(setIsDrawerLoading({ isLoading: false }));
}

function * handleCreateNewStartup(action: PayloadAction<DataPayload<CreateStartupPayload>>) {
  yield put(setIsDrawerLoading({ isLoading: true }));

  const { data } = action.payload;

  const { error: startupError, data: startupId }: CreateStartupResponse = yield call(createStartup, data);

  if (identity.isObjWithChildren(startupError) || identity.isFalsy(startupId)) {
    showNotification(startupError?.message || 'Error creating a startup.', true);
    yield put(setIsDrawerLoading({ isLoading: false }));
  } else {
    const {
      firstName,
      lastName,
      email,
      jobTitle,
      adgmLicense,
      hasLicense,
    } = data;

    const createADGMLicensePayload: CreateADGMLicensePayload = {
      ...adgmLicense,
      startupId: startupId as number,
    };

    const createTeamMemberPayload: CreateTeamMemberPayload = {
      startupId: startupId as number, founder: 1, firstName, lastName, email, jobTitle,
    }

    const { error } = yield call(createStartupTeamMember, createTeamMemberPayload);

    if (hasLicense) {
      yield put(createStartupADGMLicenseAC(createADGMLicensePayload));
    }

    if (error) {
      showNotification(error.message, true);
    }

    showNotification('Startup Created successfully');

    yield put(setDestroyDrawerOnClose({ destroyDrawerOnClose: true }));
    yield put(setIsDrawerOpen({ isDrawerOpen: false }));
    yield put(setIsDrawerLoading({ isLoading: false }));
    const userType: UserRoleType = yield select(selectUserType);
    const isRowTableLayout: boolean = yield select(selectIsRowTableLayout);

    const startupQuery: StartupQuery = {
      limit: (isRowTableLayout && !isPartner(userType)) ? config.TABLE_DEFAULT_LIMIT : config.GRID_TABLE_DEFAULT_LIMIT,
      offset: 0,
      isPreload: true,
      filters: {},
    };

    yield put(getStartups(startupQuery));
  }
}

function * handleGetStartupTeamMembers(action: PayloadAction<GetTeamMembersPayload>) {
  const {
    id: startupId, offset, limit, filters,
  } = action.payload;

  yield put(setIsLoading({ isLoading: true }));

  const { data: members, error, httpStatus } = yield call(getStartupTeamMembers, {
    startupId, offset, limit, filters,
  });

  if (identity.isObjWithChildren(error)) {
    showNotification(`Unable to fetch startup team members: ${error.message}`, true, httpStatus);
  } else {
    yield put(setStartupTeamMembers({ members }));
  }

  yield put(setIsLoading({ isLoading: false }));
}

function * handleGetTeamMembersCount(action: PayloadAction<PeopleFilters>) {
  const startupId: number = yield select(selectUserStartupId);
  const params: GetTeamMembersParams = { startupId, filters: action.payload };
  const { data, error, httpStatus } = yield call(getStartupTeamMemberCount, params);

  if (identity.isObjWithChildren(error)) {
    showNotification(`Something went wrong: ${error.message}`, true, httpStatus);
  } else {
    yield put(setTeamMembersCount({ data: data[0].id__count }));
  }
}

const reloadOnSubmit = async () => {
  await sleep(2000)
  window.location.reload();
}

function * handleUpdateStartupById(action: PayloadAction<UpdateStartupPayload>) {
  const { id, startup, reload } = action.payload;
  yield put(setIsLoading({ isLoading: true }));

  const { error, httpStatus } = yield call(updateStartup, id, startup);

  if (identity.isObjWithChildren(error)) {
    showNotification(`Error updating startup: ${error.message}`, true, httpStatus);
  } else {
    showNotification(`Startup ${id} updated successfully`);
    yield all([
      put(reduxGetStartupById({ id, owner: 'startup', isUpdate: false })),
      put(setStartups({ startups: [] })),
    ])
  }

  yield put(setIsLoading({ isLoading: false }));

  if (reload && httpStatus === 200) {
    yield call(reloadOnSubmit);
  }
}

function * handleGetStartupsByOwnerId(action: PayloadAction<GetStartupsByOwnerIdPayload>) {
  const { id, owner } = action.payload;

  if (owner === 'cohort') {
    const query: StartupQuery = {
      filters: { cohorts: [id] },
      isPreload: true,
    };
    const { data: startups, error, httpStatus } = yield call(getStartupsList, query);
    if (identity.isObjWithChildren(error)) {
      showNotification(`Error fetching cohort startups: ${error.message}`, true, httpStatus);
    } else {
      yield put(setCohortStartups({ startups }));
      yield put(setReviewDocumentRequests({ startups }));
    }
  }
}

function * handleGetStages() {
  const { data, error, httpStatus } = yield call(getStagesList);

  if (identity.isObjWithChildren(error)) {
    showNotification(error.message, true, httpStatus);
  } else {
    yield put(setStageOptions({ data }));
  }
}

function * handleGetInvestmentStages() {
  const { data, error, httpStatus } = yield call(getInvestmentStagesList);
  if (identity.isObjWithChildren(error)) {
    showNotification(error.message, true, httpStatus);
  } else {
    yield put(setInvestmentStageOptions({ data }));
  }
}

function * handleGetStartupsCount(action: PayloadAction<StartupQuery>) {
  let params: StartupQuery = action.payload;

  const userType: UserRoleType = yield select(selectUserType);
  let apiCall = getStartupsCountWorkingWithIdCount;
  switch (userType) {
    case ROLES.partner: {
      const partnerId: number = yield select(selectPartnerId);
      params = { ...params, partnerId, fieldSelection: ['startup_id'] };
      break;
    }
    case ROLES.serviceProvider: {
      const serviceProviderId: number = yield select(selectUserServiceProviderId);
      params = { ...params, serviceProviderId, fieldSelection: ['startup_id'] };
      break;
    }
    default: {
      apiCall = getStartupsCountApi;
      break;
    }
  }
  const { data: startupCount, error, httpStatus } = yield call(apiCall, params);
  if (identity.isObjWithChildren(error)) {
    showNotification(`Unable to fetch startups count: ${error.message}`, true, httpStatus);
  } else {
    yield put(setStartupsCount({ startupCount }));
  }
}

function * handleGetStartupsInCohort(action: PayloadAction<IdPayload>) {
  const { id } = action.payload;
  const { data: startups, error, httpStatus } = yield call(getStartupsInCohort, id);

  if (identity.isObjWithChildren(error)) {
    showNotification(error.message, true, httpStatus);
  } else {
    yield put(setStartups({ startups }));
  }
}

function * handleGetADGMLicenseByStartupId(action: PayloadAction<GetStartupByIdPayload>) {
  const { id } = action.payload;

  const { data: adgmLicense, error, httpStatus } = yield call(getStartupADGMLicenseByStartupId, id, true);

  if (identity.isObjWithChildren(error)) {
    showNotification(`Unable to fetch adgmLicense: ${error.message}`, true, httpStatus);
  } else {
    yield put(setADGMLicenseByStartupId({ data: adgmLicense }));
  }
}

function * handleUpdateADGMLicenseByStartupId(action: PayloadAction<UpdateAdgmLicenseByStartupIdPayload>) {
  yield put(setIsDrawerLoading({ isLoading: true }));

  const { id, adgmLicense } = action.payload;
  const { error, httpStatus } = yield call(updateAdgmLicenseByStartupId, id, adgmLicense)
  if (identity.isObjWithChildren(error)) {
    showNotification(`Unable to update adgmLicense: ${error.message}`, true, httpStatus);
  } else {
    showNotification('Startup ADGM License uploaded successfully');
    yield put(setADGMLicenseByStartupId({ data: adgmLicense }));
    yield put(reduxGetStartupById({ id, owner: 'startup', isUpdate: false }));
    yield put(setDestroyDrawerOnClose({ destroyDrawerOnClose: true }));
    yield put(setIsDrawerOpen({ isDrawerOpen: false }));
  }

  yield put(setIsDrawerLoading({ isLoading: false }));
}

function * handleChangeStartupDocumentStatus(action: PayloadAction<UpdateStartupDocumentStatusPayload>) {
  const { id, startup, documentType } = action.payload;
  yield put(setIsDrawerLoading({ isLoading: true }));

  const { error, httpStatus } = yield call(updateStartup, id, startup);

  if (identity.isObjWithChildren(error)) {
    showNotification(`Error processing document request: ${error.message}`, true, httpStatus);
  } else {
    showNotification(`${startup.name}'s ${getDocumentNameFromType(documentType)} processed successfully.`);
    if (httpStatus === 200) {
      const startupsInReview: StartupType[] = yield select(selectDocumentReviewRequests);
      const filteredStartupList = startupsInReview.filter((startupInReview) => startupInReview.id !== id);
      // Note: No need to update startup list based on document status update
      yield put(setReviewDocumentRequests({ startups: filteredStartupList }));
      yield put(setDestroyDrawerOnClose({ destroyDrawerOnClose: true }));
      yield put(setIsDrawerOpen({ isDrawerOpen: false }));
    }
  }

  yield put(setIsDrawerLoading({ isLoading: false }));
}

function * handleGetIncentiveAgreementById() {
  let startup: StartupType;
  const userType: UserRoleType = yield select(selectUserType);

  if (isStartup(userType)) {
    startup = yield select(selectUserStartup);
  } else {
    startup = yield select(selectStartupDetails);
  }

  const id: number = startup.currentIncentiveAgreementID;

  if (identity.isTruthyNumber(id)) {
    const { error, httpStatus, data } = yield call(getStartupIncentiveAgreementById, id);

    if (identity.isObjWithChildren(error)) {
      showNotification(`Something went wrong: ${error.message}`, true, httpStatus);
    } else {
      yield put(setIncentiveAgreementById({ data }));
    }
  } else {
    yield put(setIncentiveAgreementById({ data: defaultIncentiveAgreement }));
  }
}

function * handleGetStartupServiceProviders(action: PayloadAction<GetAllQueryPayload<ParamsPayload>>) {
  yield put(setIsLoading({ isLoading: true }));

  const { data, error, httpStatus } = yield call(getStartupSpsList, action.payload.query);

  if (identity.isObjWithChildren(error)) {
    showNotification(`Error fetching service providers: ${error.message}`, true, httpStatus);
  } else {
    yield all([
      put(setStartupServiceProviders({ data })),
      call(handleGetSumsForServiceProviders, data, action.payload.query.startupId as number),
    ]);
  }

  yield put(setIsLoading({ isLoading: false }));
}

function * handleGetSumsForServiceProviders(data: ServiceProviderType[], startupId: number) {
  const spSums: GetAmountSumsResponse[] = [];

  for (const i in data) {
    const { data: sums, error } = yield call(
      getAmountSumsBySpAndStartupApi,
      { serviceProviderId: data[i].id, startupId },
    );

    if (identity.isObjWithChildren(error) || !identity.isObjWithChildren(sums)) {
      spSums.push({ serviceProviderId: data[i].id, amountConsumed: 'AED 0.00', amountBlocked: 'AED 0.00' });
    } else {
      spSums.push({ ...sums, serviceProviderId: data[i].id });
    }
  }

  yield put(setSumsForServiceProviders({ data: spSums }));
}

function * handleCreateIncentiveAgreement(action: PayloadAction<CreateIncentiveAgreementPayload>) {
  const { data } = action.payload;
  yield put(setIsLoading({ isLoading: true }));

  const { error, httpStatus } = yield call(createStartupIncentiveAgreement, data);

  if (identity.isObjWithChildren(error)) {
    showNotification(`Something went wrong: ${error.message}`, true, httpStatus);
  } else {
    showNotification('Incentive agreement sent for approval.');
    yield all([
      put(reduxGetStartupById({ id: data.startupID, owner: 'startup', isUpdate: false })),
      call(handleGetIncentiveAgreementById),
      put(setIsDrawerOpen({ isDrawerOpen: false })),
    ]);
  }

  yield put(setIsLoading({ isLoading: false }));
}

function * handleUpdateIncentiveAgreement(action: PayloadAction<UpdateIncentiveAgreementPayload>) {
  const { data, id } = action.payload;
  yield put(setIsLoading({ isLoading: true }));

  const { error, httpStatus } = yield call(updateStartupIncentiveAgreement, data, id);

  if (identity.isObjWithChildren(error)) {
    showNotification(`Something went wrong: ${error.message}`, true, httpStatus);
  } else {
    const userType: UserRoleType = yield select(selectUserType);
    if (isStartup(userType)) {
      showNotification('Incentive agreement sent for approval.');
      yield all([
        put(setIsDrawerOpen({ isDrawerOpen: false })),
        put(updateStartupById({ id: data.startupID, startup: { incentiveAgreementApprovedStatus: 0 }, reload: true })),
      ]);
    } else {
      const cohort: CohortType = yield select(selectCohortDetails);
      yield put(getStartupsByOwnerId({ id: cohort.id, owner: 'cohort' }));
    }
    yield call(handleGetIncentiveAgreementById);
    if (isEitherAdmin(userType)) {
      const currentIncentiveAgreement: IncentiveAgreementType = yield select(selectIncentiveAgreement);
      const startup: StartupType = yield select(selectStartupForReview);
      yield put(setStartupForReview({ startup: { ...startup, currentIncentiveAgreement } }));
    }
  }
  yield put(setIsLoading({ isLoading: false }));
}

export default function * startupsSagas() {
  yield all([
    takeLatest(types.UPDATE_ADGM_LICENSE_BY_STARTUP_ID, handleUpdateADGMLicenseByStartupId),
    takeLatest(types.CREATE_STARTUP_ADGM_LICENSE, handleCreateStartupADGMLicense),
    takeLatest(types.GET_ADGM_LICENSE_BY_STARTUP_ID, handleGetADGMLicenseByStartupId),
    takeLatest(types.GET_STARTUPS, handleGetStartups),
    takeLatest(types.GET_STARTUPS_COUNT, handleGetStartupsCount),
    takeLatest(types.GET_STARTUP_BY_ID, handleGetStartupById),
    takeLatest(types.CREATE_STARTUP, handleCreateNewStartup),
    takeLatest(types.GET_STARTUP_TEAM_MEMBERS, handleGetStartupTeamMembers),
    takeLatest(types.UPDATE_STARTUP_BY_ID, handleUpdateStartupById),
    takeLatest(types.GET_STARTUPS_BY_OWNER_ID, handleGetStartupsByOwnerId),
    takeLatest(types.GET_SECTORS, handleGetSectors),
    takeLatest(types.GET_STAGES, handleGetStages),
    takeLatest(types.GET_INVESTMENT_STAGES, handleGetInvestmentStages),
    takeLatest(types.GET_STARTUPS_IN_COHORT, handleGetStartupsInCohort),
    takeLatest(types.GET_STARTUP_TEAM_MEMBERS_COUNT, handleGetTeamMembersCount),
    takeLatest(types.CHANGE_STARTUP_DOCUMENT_STATUS, handleChangeStartupDocumentStatus),
    takeLatest(types.GET_INCENTIVE_AGREEMENT_BY_ID, handleGetIncentiveAgreementById),
    takeLatest(types.GET_STARTUP_SERVICE_PROVIDERS, handleGetStartupServiceProviders),
    takeLatest(types.CREATE_INCENTIVE_AGREEMENT, handleCreateIncentiveAgreement),
    takeLatest(types.UPDATE_INCENTIVE_AGREEMENT, handleUpdateIncentiveAgreement),
  ]);
}
