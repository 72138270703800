import React, { useEffect } from 'react';

import { Input, RenderIf } from 'components/components';
import DataColumn from 'components/components/DataColumns/components/DataColumn';
import { DefaultColumnsProps } from 'types/services/requestService';
import { ServicesType } from 'types/services';
import { identity, positiveNumbersOnly } from 'utils';
import ServiceOfferedDocView from '../../../ServiceOfferedDocView';

const getUnitQuantityText = (data: ServicesType): string => {
  const { priceType } = data;
  const quantityText = 'Number of';
  switch (priceType) {
    case 'per-day':
      return `${quantityText} days`;
    case 'per-hour':
      return `${quantityText} hours`;
    case 'per-week':
      return `${quantityText} weeks`;
    case 'per-month':
      return `${quantityText} months`;
    case 'per-year':
      return `${quantityText} years`;
    default:
      return 'Quantity';
  }
}

const DefaultColumns = (props: DefaultColumnsProps) => {
  const {
    setQuantity,
    quantity,
    details,
    // setPriceType,
    // priceType,
  } = props;
  /* NOTE: commented for now, until we implement price per hour, day and month on backend
    const pricingType = useMemo(() => getPriceType(priceType), [priceType]);
    const pricingTypes = useMemo(() => setDisabilityOfPriceType([priceType]), [priceType]); */
  useEffect(() => {
    setQuantity({ data: 1 });
  }, []);

  return (
    <div className="overview-columns">
      {/* <DataColumn label="Pricing options" className="input"> */}
      {/*  <Input.Radio */}
      {/*    onChange={({ target: { value: data } }) => setPriceType({ data })} */}
      {/*    values={pricingTypes} */}
      {/*    value={priceType} */}
      {/*    direction="horizontal" */}
      {/*  /> */}
      {/* </DataColumn> */}
      <Input rules={[positiveNumbersOnly]} label={getUnitQuantityText(details)} className="overview-column" required layout="vertical">
        <Input.InputNumber
          min={1}
          value={quantity}
          onChange={(e) => setQuantity({ data: e! })}
          placeholder="Enter quantity"
          className="quantity-select"
        />
      </Input>
      <DataColumn label="Rate Card Document">
        <ServiceOfferedDocView
          altText="No Rate Card Document available for this service provider"
          fileContent={details.serviceProvider?.companyDeck as string}
        />
      </DataColumn>
      <RenderIf condition={identity.isTruthyString(details.offerDocLink)}>
        <DataColumn label="Offer Document">
          <ServiceOfferedDocView
            altText="No Offer Document available for this service"
            fileContent={details.offerDocLink as string}
          />
        </DataColumn>
      </RenderIf>
    </div>
  );
}

export default DefaultColumns;
