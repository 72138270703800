import React, { useEffect, useState } from 'react';
import { Col, Row } from 'antd';

import { greaterThanZeroOnly, requiredValidation } from 'utils';
import { Input, RenderIf } from 'components/components';
import { AddPricingOptionFormProps } from 'types/services/addService';
import { PricingOptionFieldType } from 'types/services';
import { CloseIcon } from 'assets/icons';
import { enterYourPriceMessage } from '../../../../utils';

import './PricingOptionForm.scss';

const PricingOptionForm = (props: AddPricingOptionFormProps) => {
  const {
    id,
    pricingOption,
    onDeletePricingOption,
    onChangeHandle,
    hideDeleteOption,
    error,
    setPriceUnit,
    units,
  } = props;

  const [formValue, setFormValue] = useState(pricingOption);

  useEffect(() => {
    setFormValue(pricingOption);
  }, [pricingOption]);

  const onChange = (field: PricingOptionFieldType, value: string) => {
    setFormValue({ ...formValue, [field]: value });
    onChangeHandle({ id, field, value });
  }

  const onDeleteHandle = () => {
    onDeletePricingOption(pricingOption.id);
  }

  return (
    <Row>
      <Col span={24}>
        <Row className="pricing-option-form" gutter={8}>
          <Col span={13}>
            <Input
              required
              layout="vertical"
              name="amount"
              label="Amount"
              rules={[greaterThanZeroOnly]}
            >
              <Input.InputField
                required
                type="number"
                className="input_fields"
                value={formValue.amount}
                onChange={(e) => onChange('amount', e.target.value)}
              />
            </Input>
            <RenderIf condition={error && formValue.amount.length < 1}>
              <p className="field-error">{enterYourPriceMessage}</p>
            </RenderIf>
          </Col>
          <Col span={9}>
            <Input
              layout="vertical"
              name="unit"
              label="Unit"
              required
              rules={[requiredValidation]}
            >
              <Input.Select
                options={units?.map((unit) => ({
                  value: unit.id, label: unit.name, type: unit.type, id: unit.id,
                }))}
                value={formValue.option}
                onChange={(e, option) => {
                  onChange('option', e);
                  setPriceUnit(option);
                }}
              >
                {formValue.option}
              </Input.Select>
            </Input>
          </Col>
          <RenderIf condition={!hideDeleteOption}>
            <Col onClick={onDeleteHandle} span={2}>
              <div className="delete-icon">
                <CloseIcon />
              </div>
            </Col>
          </RenderIf>
        </Row>
      </Col>
    </Row>
  )
};

export default PricingOptionForm;
