import axios, { AxiosRequestConfig } from 'axios';

import {
  API_HOST,
  DEFAULT_HEADERS,
} from './utils';
import { authInterceptor, errorInterceptor, responseInterceptor } from './utils/interceptors';

const DEFAULT_API_CONFIG: AxiosRequestConfig = {
  baseURL: API_HOST,
  timeout: 20000,
  headers: DEFAULT_HEADERS,
  withCredentials: true,
};

const httpClient = axios.create(DEFAULT_API_CONFIG);

httpClient.interceptors.request.use(authInterceptor);
httpClient.interceptors.response.use(responseInterceptor, errorInterceptor);

export default httpClient;
