import React, { useMemo, useEffect, useState } from 'react';
import { useParams } from 'react-router';

import { servicesOverviewColumns } from '../../../utils/constants/columns';
import { ServicesProps } from '../../../../types/serviceProviders/serviceProvidersOverview';
import { getRowKey } from '../../../../utils/helpers/getRowKey';
import Table from '../../../components/Table';
import ServiceDrawer from './components/ServiceDrawer';
import { ServiceProviderServicesType } from '../../../../types/serviceProviders/ServiceProviderDetails';
import RequestedServiceRow from './components/RequestedServiceRow';

import './ServicesOverview.scss';

const ServicesOverView = (props: ServicesProps) => {
  const { services, service } = props;
  const { getServicesByServiceProviderId, getServiceById } = props.actions;

  const [openDrawer, setOpenDrawer] = useState(false);
  const [serviceId, setServiceId] = useState<number>(0);
  const params: { id?: string } = useParams();
  const { id } = params;

  const serviceProviderId = useMemo(() => parseInt(id!, 10), [id]);

  useEffect(() => {
    getServicesByServiceProviderId({ id: serviceProviderId });
    getServiceById({ id: serviceId });
  }, [serviceProviderId, serviceId]);

  const openServiceDrawer = (selectedServiceId: number) => {
    setOpenDrawer(true);
    setServiceId(selectedServiceId);
  }

  return (
    <div className="services-overview content-container">
      <RequestedServiceRow services={services} />
      <Table
        onRow={(record) => ({ onClick: () => openServiceDrawer(record.id) })}
        showSearchBar
        leftContent="add-button"
        addButtonText="offer"
        placeholder="perks and services"
        dataSource={services}
        showDisplayButtons
        tableLocation="service-provider"
        onAddButtonClick={() => null}
        rowKey={getRowKey(services, 'service', 'id')}
        showTypeButton={false}
        columns={servicesOverviewColumns}
        onGridCardClick={(record: ServiceProviderServicesType) => openServiceDrawer(record.id)}
        component="services"
      />
      <ServiceDrawer
        onClose={() => setOpenDrawer(false)}
        open={openDrawer}
        serviceId={service.id}
        service={service}
      />
    </div>
  );
}

export default ServicesOverView;
