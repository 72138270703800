import { connect } from 'react-redux';
import { bindActionCreators, Dispatch, Action } from 'redux';

import { StateType } from 'types';
import { changePassword } from 'redux/actions/auth';
import { selectResetForm } from 'redux/selectors/workspace';

const mapStateToProps = (state: StateType) => ({
  resetForm: selectResetForm(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  actions: bindActionCreators({ changePassword }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps);
