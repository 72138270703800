import produce from 'immer';

import {
  ServiceRequestItemType,
  ResponseRequestType as AllRequestType,
  ServiceRequestDocumentType,
} from 'packages/service_request_repository';
import {
  RequestActionType,
  SetRequestPayload,
  ChangeRequestStatusPayload,
  SetCurrentTabPayload,
  SetActiveTabPayload,
  SetCommentsPayload,
  AddNewRequestCommentPayload,
} from 'types/reduxTypes/ActionTypes/RequestsTypes';
import { RequestStateType } from 'types/reduxTypes/StateTypes';
import { DataPayload, IdPayload, IsLoadingPayload } from 'types/reduxTypes/ActionTypes';
import { ServicesType } from 'types/services';
import { RequestFiltersState, RequestsCountObject } from 'types/requests';
import { checkRequestsFiltersActive, identity } from 'utils'
import { defaultRequestStateType, changeRequestStatusHelper } from '../../utils';
import { types } from '../../actions/request';

const defaultState: RequestStateType = defaultRequestStateType;

const handleSetRequestDetails = (state: RequestStateType, payload: SetRequestPayload) => {
  state.details.data = payload.request;
  return state;
}

const handleSetServicesBySpId = (state: RequestStateType, payload: DataPayload<ServicesType[]>) => {
  state.servicesBySP = payload.data;
  return state;
}

const handleSetRequests = (state: RequestStateType, payload: DataPayload<AllRequestType[]>) => {
  state.requests = payload.data;
  state.filtersActive = checkRequestsFiltersActive(state.filters);
  return state;
}

const handleSetRequestId = (state: RequestStateType, payload: IdPayload) => {
  state.serviceRequestId = payload.id;
  state.partnerRequestId = 0;
  return state;
}

const handleSetShowInvoiceFields = (state: RequestStateType, payload: DataPayload<boolean>) => {
  state.showInvoiceFields = payload.data;
  return state;
}

const handleSetRequestStatus = (state: RequestStateType, payload: ChangeRequestStatusPayload) => {
  const { id, status } = payload;
  const { requests } = state;
  state.requests = changeRequestStatusHelper(id, requests, status);
  state.items[0].serviceRequest!.status = status;
  return state;
}

const handleSetIsLoading = (state: RequestStateType, payload: IsLoadingPayload) => {
  state.isLoading = payload.isLoading;
  return state;
}

const handleAddNewComment = (state: RequestStateType, payload: AddNewRequestCommentPayload) => {
  if (identity.isNotArray(state.details.comments)) {
    state.details.comments = [];
  }

  state.details.comments?.push(payload.comment);
  return state;
}

const handleRemoveHighlight = (state: RequestStateType) => {
  state.highlightRequest = false;
  state.highlightUnsentRequest = false;
  state.addedRequestsCount = 0;
  return state;
}

const handleSetHighlightRequest = (state: RequestStateType) => {
  state.highlightRequest = true;
  return state;
}

const handleSetCurrentTab = (state: RequestStateType, payload: SetCurrentTabPayload) => {
  state.currentTab = payload.currentTab;
  return state;
}

const handleSetActiveTab = (state: RequestStateType, payload: SetActiveTabPayload) => {
  state.details.activeTab = payload.tab;
  return state;
}

const handleSetUnsentRequests = (state: RequestStateType, payload: DataPayload<ServiceRequestItemType[]>) => {
  state.unsentRequests = payload.data;
  state.highlightUnsentRequest = true;
  return state;
}

const handleSetRequestItems = (state: RequestStateType, payload: DataPayload<ServiceRequestItemType[]>) => {
  state.items = payload.data;
  return state;
}

const handleSetComments = (state: RequestStateType, payload: SetCommentsPayload) => {
  state.details.comments = payload.comments;
  return state;
}

const handleSetCommentsLoading = (state: RequestStateType, payload: IsLoadingPayload) => {
  state.details.commentsLoading = payload.isLoading;
  return state;
}

const handleSetPartnerRequestId = (state: RequestStateType, payload: IdPayload) => {
  state.partnerRequestId = payload.id;
  state.serviceRequestId = 0;
  return state;
}

const handleSetFilters = (state: RequestStateType, payload: DataPayload<RequestFiltersState>) => {
  state.filters = payload.data;
  return state;
}

const handleSetRequestsCount = (state: RequestStateType, payload: DataPayload<RequestsCountObject>) => {
  state.count = payload.data;
  return state;
}

const handleSetRequestDocuments = (state: RequestStateType, payload: DataPayload<ServiceRequestDocumentType[]>) => {
  state.details.documents = payload.data;
  return state;
}

// eslint-disable-next-line default-param-last
export default produce((state: RequestStateType = defaultState, action: RequestActionType) => {
  const { type, payload } = action;
  switch (type) {
    case types.SET_PARTNER_REQUEST_ID: {
      return handleSetPartnerRequestId(state, <IdPayload>payload);
    }
    case types.SET_REQUEST_BY_ID: {
      return handleSetRequestDetails(state, <SetRequestPayload>payload);
    }
    case types.SET_REQUESTS: {
      return handleSetRequests(state, <DataPayload<AllRequestType[]>>payload);
    }
    case types.SET_REQUEST_STATUS: {
      return handleSetRequestStatus(state, <ChangeRequestStatusPayload>payload);
    }
    case types.SET_REQUESTS_IS_LOADING: {
      return handleSetIsLoading(state, <IsLoadingPayload>payload);
    }
    case types.ADD_NEW_REQUEST_COMMENT: {
      return handleAddNewComment(state, <AddNewRequestCommentPayload>payload);
    }
    case types.SET_REQUEST_ID: {
      return handleSetRequestId(state, <IdPayload>payload);
    }
    case types.SET_ACTIVE_TAB: {
      return handleSetActiveTab(state, <SetActiveTabPayload>payload);
    }
    case types.SET_CURRENT_TAB: {
      return handleSetCurrentTab(state, <SetCurrentTabPayload>payload);
    }
    case types.REMOVE_HIGHLIGHT: {
      return handleRemoveHighlight(state);
    }
    case types.SET_HIGHLIGHT_REQUEST: {
      return handleSetHighlightRequest(state);
    }
    case types.SET_UNSENT_REQUESTS: {
      return handleSetUnsentRequests(state, <DataPayload<ServiceRequestItemType[]>>payload);
    }
    case types.SET_REQUEST_ITEMS: {
      return handleSetRequestItems(state, <DataPayload<ServiceRequestItemType[]>>payload);
    }
    case types.SET_COMMENTS: {
      return handleSetComments(state, <SetCommentsPayload>payload);
    }
    case types.SET_COMMENTS_LOADING: {
      return handleSetCommentsLoading(state, <IsLoadingPayload>payload);
    }
    case types.SET_REQUESTS_COUNT: {
      return handleSetRequestsCount(state, <DataPayload<RequestsCountObject>>payload);
    }
    case types.SET_SERVICES_BY_SP_ID: {
      return handleSetServicesBySpId(state, <DataPayload<ServicesType[]>>payload);
    }
    case types.SET_SERVICE_REQUEST_DOCUMENTS: {
      return handleSetRequestDocuments(state, <DataPayload<ServiceRequestDocumentType[]>>payload);
    }
    case types.SET_SHOW_INVOICE_FIELDS: {
      return handleSetShowInvoiceFields(state, <DataPayload<boolean>>payload);
    }
    case types.SET_REQUEST_OVERVIEW_FILTERS: {
      return handleSetFilters(state, <DataPayload<RequestFiltersState>>payload);
    }
    case types.RESET_REQUESTS: {
      return defaultState;
    }
    default: {
      return state;
    }
  }
});
