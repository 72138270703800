import React, { useEffect } from 'react';
import { Button, Form } from 'antd';

import { Input } from 'components/components';
import { scrollToFirstError } from 'components/components/FormBuilder/utils/helpers';
import { passwordMatchValidator, requiredValidation } from 'utils';
import { ChangePasswordPayload } from 'types/auth/changePassword';
import { MyProfileChangePasswordProps } from '../../types/myProfileChangePassword';
import { passwordLengthValidation } from '../components/ConfirmPassword/utils/constants';

import './MyProfileChangePassword.scss';

const MyProfileChangePassword = (props: MyProfileChangePasswordProps) => {
  const { changePassword } = props.actions;
  const { resetForm } = props;

  const [form] = Form.useForm();

  const onFinish = (formData: ChangePasswordPayload) => {
    changePassword(formData);
  };

  useEffect(() => {
    if (resetForm) {
      form.resetFields();
    }
  }, [resetForm]);

  return (
    <Form onFinish={onFinish} form={form} validateTrigger="onSubmit" onFinishFailed={scrollToFirstError}>
      <div className="change-password__fields">
        <Input rules={[requiredValidation]} name="oldPassword" label="Old password">
          <Input.InputField type="password" />
        </Input>
        <Input rules={[requiredValidation, passwordLengthValidation]} name="password" label="New password">
          <Input.InputField type="password" />
        </Input>
        <Input
          rules={[requiredValidation, passwordMatchValidator]}
          dependencies={['password']}
          name="confirmPassword"
          label="Confirm new password"
        >
          <Input.InputField type="password" />
        </Input>
        <Button htmlType="submit" className="primary-button">Change password</Button>
      </div>
    </Form>

  );
}

export default MyProfileChangePassword;
