import { StartupType as Startup, StartupStatusType, IncentiveAgreementType } from 'packages/startup_repository';
import { ADGMLicenseType } from 'packages/startup_repository/types/adgm_license';
import { StartupsStateType } from 'types/reduxTypes/StateTypes';
import { StartupType } from 'types/startups';
import { defaultSelectedCohort } from '.';
import { serviceProviderDefault } from './ServiceProvidersDefaults';

const defaultStatus: StartupStatusType = {
  id: -1,
  code: '',
  name: '',
  createdAt: '',
}

export const defaultStartup: StartupType = {
  balance: '',
  consumed: '',
  raised: '',
  about: '',
  id: -1,
  imageLink: '',
  industry: '',
  country: '',
  city: '',
  name: '',
  stage: '',
  type: '',
  employees: 0,
  cohort: defaultSelectedCohort,
  serviceProvidersCount: 0,
  upcomingEventsCount: 0,
  founderEmail: '',
  founders: [],
  status: defaultStatus,
  website: '',
  numberOfEmployees: -1,
}

export const defaultADGMLicense: ADGMLicenseType = {
  acceptanceDate: '',
  address: '',
  expiryDate: '',
  issueDate: '',
  legalADGMName: '',
  legalSignatoryEmail: '',
  legalSignatoryName: '',
  legalSignatoryTitle: '',
  id: -1,
  aDGMLicenseNo: '',
  startupID: 0,
  aDGMLicenseUrl: '',
  approvedRejectedStatus: false,
}

export const startupDef: Startup = {
  id: -1,
  name: '',
  about: '',
  primarySectorID: -1,
  primarySector: {
    id: -1,
    name: '',
  },
  secondarySectorID: -1,
  secondarySector: {
    id: -1,
    name: '',
  },
  stage: -1,
  website: '',
  numberOfEmployees: 0,
  imageLink: '',
  location: '',
  industry: '',
  stageName: '',
  walletID: -1,
  wallet: null,
  status: defaultStatus,
  cohort: defaultSelectedCohort,
  founderEmail: '',
  aDGMApprovedStatus: 0,
  incentiveAgreementApprovedStatus: 0,
  currentADGMLicense: {
    aDGMLicenseUrl: '',
    aDGMLicenseNo: '',
    id: -1,
  },
  currentADGMLicenseID: 0,
  currentIncentiveAgreement: null,
  currentIncentiveAgreementID: 0,
  acceptanceLetter: '',
  firstName: '',
  lastName: '',
  acceptanceLetterApprovedStatus: 0,
}

export const defaultIncentiveAgreement: IncentiveAgreementType = {
  file: '',
  id: 0,
  startupID: 0,
  startDate: '',
  endDate: '',
  walletAmount: 0,
}

export const startupsDefaultState: StartupsStateType = {
  filtersActive: false,
  filters: {
    industries: [],
    locations: [],
    cohorts: [],
    stage: 0,
    capitalRaised: '',
    incentiveAmount: '',
    fieldSelection: [],
    searchString: '',
  },
  incentiveAgreement: defaultIncentiveAgreement,
  startups: [],
  locations: [],
  adgmLicenseByStartupId: defaultADGMLicense,
  sectorOptions: [],
  stageOptions: [],
  investmentStageOptions: [],
  isLoading: false,
  details: {
    data: defaultStartup,
    requests: [],
    teamMembers: [],
    teamMembersCount: 0,
    serviceProviders: [],
    serviceProvidersCount: 0,
    serviceProvider: serviceProviderDefault,
    requestsList: [],
  },
  startupCount: 0,
  selectedStartupForReview: startupDef,
};
