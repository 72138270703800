import React from 'react';
import { Button } from 'antd';

import { ButtonWithPrompt, RenderIf } from 'components/components';
import { isStartup } from 'utils';
import { ReasonForDenyingProps } from 'types/requests';

const ReasonForDenying = (props: ReasonForDenyingProps) => {
  const {
    onDeny,
    setDisplayApproveBtn,
    userType,
    onApprove,
    displayApproveBtn,
    showInsufficientFunds,
  } = props;

  return (
    <div className="overview-tab__actions">
      <ButtonWithPrompt
        promptActiveClassName="danger-button"
        className="ghost-button"
        promptLabel="Reason for denying"
        promptPlaceholder="Write a reason..."
        onPromptDisplay={() => setDisplayApproveBtn(false)}
        onFinish={onDeny}
        onCancel={() => setDisplayApproveBtn(true)}
        promptActiveBtnText="Deny request"
      >
        {isStartup(userType) ? 'Cancel' : 'Deny'}
      </ButtonWithPrompt>
      <RenderIf condition={displayApproveBtn}>
        <Button className="primary-button" onClick={onApprove} disabled={showInsufficientFunds && isStartup(userType)}>Approve</Button>
      </RenderIf>
    </div>
  );
}

export default ReasonForDenying;
