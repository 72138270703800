import React from 'react';

import { Navbar } from 'components/LayoutWithSidebar/components';

const StartupRejected = () => (
  <div>
    <Navbar />
    <p style={{ padding: 50, textAlign: 'center' }}>
      Your startup is rejected
    </p>
  </div>
);

export default StartupRejected;
