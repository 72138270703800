import { createSelector } from 'reselect';

import { StateType } from 'types';
import { WorkspaceStateType } from 'types/reduxTypes/StateTypes';

const selectWorkspaceState = (state: StateType) => state.workspaceReducer;

export const selectIsRowTableLayout = createSelector(
  selectWorkspaceState,
  (state: WorkspaceStateType) => state.isRowTableLayout,
);

export const selectSearchResults = createSelector(
  selectWorkspaceState,
  (state: WorkspaceStateType) => state.searchResults,
);

export const selectIsLoading = createSelector(
  selectWorkspaceState,
  (state: WorkspaceStateType) => state.drawer.isDrawerLoading,
);

export const selectIsOpen = createSelector(
  selectWorkspaceState,
  (state: WorkspaceStateType) => state.drawer.isDrawerOpen,
);

export const selectDestroyDrawerOnClose = createSelector(
  selectWorkspaceState,
  (state: WorkspaceStateType) => state.drawer.destroyDrawerOnClose,
);

export const selectIsDrawerFilterTableOpen = createSelector(
  selectWorkspaceState,
  (state: WorkspaceStateType) => state.drawer.isDrawerFilterTableOpen,
);

export const selectDrawerBody = createSelector(
  selectWorkspaceState,
  (state: WorkspaceStateType) => state.drawer.drawerBody,
);

export const selectResetForm = createSelector(
  selectWorkspaceState,
  (state: WorkspaceStateType) => state.resetForm,
);

export const selectNationalities = createSelector(
  selectWorkspaceState,
  (state: WorkspaceStateType) => state.nationalities,
);

export const selectStartups = createSelector(
  selectWorkspaceState,
  (state: WorkspaceStateType) => state.startups,
);

export const selectIsNoticeModalOpen = createSelector(
  selectWorkspaceState,
  (state: WorkspaceStateType) => state.noticeModal.isOpen,
);

export const selectNoticeModalTemplate = createSelector(
  selectWorkspaceState,
  (state: WorkspaceStateType) => state.noticeModal.template,
);

export const selectWorkspacePartners = createSelector(
  selectWorkspaceState,
  (state: WorkspaceStateType) => state.partners,
);

export const selectWorkspaceServiceProviders = createSelector(
  selectWorkspaceState,
  (state: WorkspaceStateType) => state.serviceProviders,
);

export const selectWorkspaceServicesCount = createSelector(
  selectWorkspaceState,
  (state: WorkspaceStateType) => state.servicesCount,
);

export const selectWorkspaceSpCount = createSelector(
  selectWorkspaceState,
  (state: WorkspaceStateType) => state.serviceProvidersCount,
);
