import { positiveNumbersOnly, requiredValidation } from 'utils';
import { getFormFieldBuilder, inputTypes } from 'components/components/FormBuilder/utils';

export const editPartnerMemberFormFields = [
  {
    fields: [
      getFormFieldBuilder('firstName', 'First name')
        .withRules([requiredValidation])
        .build(),
    ],
  },
  {
    fields: [
      getFormFieldBuilder('lastName', 'Last name')
        .withRules([requiredValidation])
        .build(),
    ],
  },
  {
    fields: [
      getFormFieldBuilder('phone', 'Phone', inputTypes.number)
        .withRules([positiveNumbersOnly])
        .build(),
    ],
  },
  {
    fields: [
      getFormFieldBuilder('mobile', 'Mobile', inputTypes.number)
        .withRules([positiveNumbersOnly])
        .build(),
    ],
  },
];

export const editAdminFields = [
  {
    fields: [
      getFormFieldBuilder('firstName', 'First name')
        .withRules([requiredValidation])
        .build(),
    ],
  },
  {
    fields: [
      getFormFieldBuilder('lastName', 'Last name')
        .withRules([requiredValidation])
        .build(),
    ],
  },
];
