import React from 'react';
import moment from 'moment';

import { identity } from 'utils';
import { RequestSecondInfoProps } from 'types/requests';
import { PRICE_QUANTITY } from '../../../RequestDetailsDrawer';
import { formatSlashMDY } from '../../../../../utils/constants/formatters';
import { isUnsent } from '../../../../utils';

const RequestSecondInfo = (props: RequestSecondInfoProps) => {
  const {
    status, date, quantity, priceType, requestNumber,
  } = props;

  if (isUnsent(status)) {
    return (
      <span className="request-row__request-info__unsent-request--quantity">
        {identity.isTruthyString(priceType) ? `${quantity} ${PRICE_QUANTITY[priceType]}` : `Quantity: ${quantity}`}
      </span>
    );
  }

  return (
    <div className="request-row__request-info--row">
      <span className="request-row__request-info--row__details">{`#R${requestNumber}`}</span>
      <div className="dot" />
      <span className="request-row__request-info--row__details">{moment(date).format(formatSlashMDY)}</span>
    </div>
  );
}

export default RequestSecondInfo;
