import { GetInTouchPartnerFieldsCreatorParams } from 'types/partners/getInTouch';
import requiredValidation from '../../../../utils/constants/validationRules';
import { getFormFieldBuilder, inputTypes } from '../../../components/FormBuilder/utils';

export const getInTouchDrawerTopFields = ({
  partnerName,
}: GetInTouchPartnerFieldsCreatorParams) => [
  {
    fields: [
      getFormFieldBuilder('subject', 'Subject')
        .withPlaceholder('Title of your request')
        .withRules([requiredValidation])
        .required()
        .build(),
    ],
  },
  {
    fields: [
      getFormFieldBuilder('message', `Why do you want to connect with ${partnerName}?`, inputTypes.textArea)
        .withPlaceholder('Please write a short description why do you want to connect with this partner. How is this partner relevant to your business?')
        .withRules([requiredValidation])
        .withRows(4)
        .required()
        .build(),
    ],
  },
];

export const getInTouchDrawerBottomFields = [
  {
    fields: [
      getFormFieldBuilder('additionalComments', 'Additional comments', inputTypes.textArea)
        .withPlaceholder('If you have any additional comments, please write them in this field')
        .withRows(4)
        .build(),
    ],
  },
];
