import { connect } from 'react-redux';
import { Action, bindActionCreators, Dispatch } from 'redux';

import { logout } from 'redux/actions/auth';
import { selectUser, selectUserType } from 'redux/selectors/auth';
import { StateType } from 'types';

const mapStateToProps = (state: StateType) => ({
  loggedInUser: selectUser(state),
  userType: selectUserType(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  actions: bindActionCreators({
    logout,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps);
