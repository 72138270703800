import React from 'react';

import { NotificationType } from '../../../../types/componentTypes/Notification';
import {
  CheckmarkOutlineIcon,
  InformationIcon,
  WarningIcon,
  WarningTriangleIcon,
} from '../../../../assets/icons';

const NotificationIcon = (props: { type: NotificationType }) => {
  switch (props.type) {
    case 'success': {
      return <CheckmarkOutlineIcon />;
    }
    case 'warning': {
      return <WarningTriangleIcon />;
    }
    case 'error': {
      return <WarningIcon />;
    }
    case 'info': {
      return <InformationIcon />;
    }
    default: {
      return null;
    }
  }
}

export default NotificationIcon;
