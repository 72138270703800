import { Button } from 'antd';
import React from 'react'

import { SortByProps } from '../../../../../../../../types/activities';
import { sortByOptions } from '../../../../../../utils/constants';

import './SortByPopover.scss';

const SortByPopover = (props : SortByProps) => {
  const { onSortByClick } = props;

  return (
    <div className="sort-by-popover">
      <Button type="link" onClick={() => onSortByClick(sortByOptions.OLD_TO_NEW)}>OLD TO NEW</Button>
      <Button type="link" onClick={() => onSortByClick(sortByOptions.NEW_TO_OLD)}>NEW TO OLD</Button>
    </div>
  )
}

export default SortByPopover;
