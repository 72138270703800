import { connect } from 'react-redux';

import { StateType } from 'types';
import { selectInvestmentStageOptions } from 'redux/selectors/startups';

const mapStateToProps = (state: StateType) => ({
  investmentStageOptions: selectInvestmentStageOptions(state),
});

export default connect(mapStateToProps);
