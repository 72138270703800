import React, { useMemo } from 'react';
import { ResponsivePie } from '@nivo/pie';
import classnames from 'classnames';

import { WalletGraphProps } from 'types/startups';
import { WalletType } from 'types/wallet';
import { parseNumberToPrice } from 'utils';
import {
  fill,
  defs,
  getStartAngle,
  margin,
} from '../../utils';

import '../../StartupWallet.scss';

const WalletGraph = (props: WalletGraphProps) => {
  const { data, wallet } = props;

  const startAngle = useMemo(() => getStartAngle(wallet), [wallet]);

  return (
    <div className={classnames('wallet-graph', { 'wallet-consumed': wallet.consumedAmount >= wallet.totalWalletAmount })}>
      <ResponsivePie
        legends={[]}
        data={data}
        defs={defs}
        fill={fill}
        borderWidth={1}
        startAngle={-startAngle}
        endAngle={360 - startAngle}
        innerRadius={0.6}
        sortByValue={false}
        margin={margin}
        arcLabel={(e) => `${parseNumberToPrice(parseFloat(wallet[e.id as keyof WalletType] as string))}`}
        arcLinkLabel="label"
        arcLinkLabelsThickness={2}
        enableArcLinkLabels
        isInteractive={false}
        arcLinkLabelsDiagonalLength={24}
        arcLinkLabelsStraightLength={16}
        arcLinkLabelsTextOffset={6}
        arcLabelsSkipAngle={10}
        arcLinkLabelsSkipAngle={5}
      />
      <div className="wallet-graph__centered-metric">
        <span className="wallet-graph__centered-metric--value">
          {parseNumberToPrice(parseFloat(wallet.totalWalletAmount))}
        </span>
        <span className="wallet-graph__centered-metric--label">Total</span>
      </div>
    </div>
  );
}

export default WalletGraph;
