import { connect } from 'react-redux';
import { Action, bindActionCreators, Dispatch } from 'redux';

import { setRequestedServiceStatus } from '../../../../../../redux/actions/serviceProviders';

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  actions: bindActionCreators({ setRequestedServiceStatus }, dispatch),
});

export default connect(null, mapDispatchToProps);
