import React from 'react'
import { Button } from 'antd';
import { useHistory } from 'react-router';

import { RenderIf } from 'components/components';
import { identity } from 'utils';
import { StatCardProps } from '../../../../../types/componentTypes/StatCards';

import './StatCard.scss';
import { cardTypesMap } from '../../utils/constants';

const StatCard = (props: StatCardProps) => {
  const {
    title, count, icon,
  } = props;
  const history = useHistory();

  return (
    <div className={`stat-card stat-card--${cardTypesMap[title]?.color}`}>
      <div className="stat-card__icon-container">
        {icon}
      </div>
      <h3 className="stat-card__title">{title}</h3>
      <h1 className="stat-card__count">{count}</h1>
      <p className="stat-card__description">{`Number of ${title}`}</p>
      <RenderIf condition={identity.isTruthyString(cardTypesMap[title]?.path)}>
        <Button className="stat-card__link secondary-button" onClick={() => history.push(cardTypesMap[title]?.path)}>{`View ${title}`}</Button>
      </RenderIf>
    </div>
  )
}

export default StatCard;
