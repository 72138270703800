import React from 'react';
import { AiOutlinePlus } from 'react-icons/ai';
import { Button } from 'antd';

import { Input } from '../../../components';
import { ServiceProviderOffersProps } from '../../../../types/serviceProviders/editServiceProvider';
import { DRAWERS } from '../../../../utils';

import './ServiceProviderOffers.scss';

const ServiceProviderOffers = (props: ServiceProviderOffersProps) => {
  const { actions: { setIsDrawerOpen, setDrawerBody } } = props;

  const handleClick = () => {
    setDrawerBody({ drawerBody: DRAWERS.addServiceProviderOffer })
    setIsDrawerOpen({ isDrawerOpen: true });
  }

  return (
    <div className="form-builder__row service-provider-offers">
      <Input.Heading title="Offers" />
      <Button
        type="link"
        className="service-provider-offers__add-offer-btn"
        icon={<AiOutlinePlus />}
        onClick={handleClick}
      >
        ADD OFFER
      </Button>
    </div>
  );
};

export default ServiceProviderOffers;
