import { getFormFieldBuilder } from 'components/components/FormBuilder/utils/FormFieldBuilder';
import requiredValidation from 'utils/constants/validationRules';

export const AddNewCategoryFormFields = [
  {
    fields: [
      getFormFieldBuilder('name', 'Name of the category')
        .withPlaceholder('eg. Name')
        .withRules([requiredValidation])
        .required()
        .build(),
    ],
  },
];

export default null;
