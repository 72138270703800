import moment from 'moment';

import { inputTypes } from 'components/components/FormBuilder/utils/constants';
import { getFormFieldBuilder } from 'components/components/FormBuilder/utils/FormFieldBuilder';
import { positiveNumbersOnly, requiredValidation } from 'utils';
import { getPrimitiveLabeledValueObject } from 'utils/helpers/getLabelValueObject';
import { getYearsOptions } from './helpers';

export const HUB71_FOUNDED_YEAR = 2017;
export const yearOptions = getYearsOptions(HUB71_FOUNDED_YEAR, moment().year());
export const quarterOptions = [
  getPrimitiveLabeledValueObject('Q1', 1),
  getPrimitiveLabeledValueObject('Q2', 2),
  getPrimitiveLabeledValueObject('Q3', 3),
  getPrimitiveLabeledValueObject('Q4', 4),
];

export const yesNoOptions = [
  { title: 'Yes', value: '1', key: 'yes' },
  { title: 'No', value: '0', key: 'no' },
];

export const sharePitchDeckOptions = [
  { title: 'I don\'t mind', value: '1', key: 'yes' },
  { title: 'I do mind', value: '0', key: 'no' },
];

export const basicInformationFields = [
  {
    fields: [
      getFormFieldBuilder('year', 'Year', inputTypes.select)
        .withOptions(yearOptions)
        .withPlaceholder('Year')
        .withRules([requiredValidation])
        .build(),
    ],
  },
  {
    fields: [
      getFormFieldBuilder('quarters', 'Quarter', inputTypes.select)
        .withPlaceholder('Quarter')
        .withOptions(quarterOptions)
        .withRules([requiredValidation])
        .build(),
    ],
  },
];

export const foundersAndEmployeesFields = [
  {
    fields: [
      getFormFieldBuilder('numberOfFounder', 'Number of startup founders', inputTypes.number)
        .min(0)
        .withRules([positiveNumbersOnly])
        .build(),
    ],
  },
  {
    fields: [
      getFormFieldBuilder('numberOfFemaleFounders', 'Number of startup female founders', inputTypes.number)
        .min(0)
        .withRules([positiveNumbersOnly])
        .build(),
    ],
  },
  {
    fields: [
      getFormFieldBuilder('numberOfFullTimeEmployees', 'Number of full-time employees', inputTypes.number)
        .min(0)
        .withRules([positiveNumbersOnly])
        .build(),
    ],
  },
  {
    fields: [
      getFormFieldBuilder('numberOfFemaleFullTimeEmployees', 'Number of female FTEs', inputTypes.number)
        .min(0)
        .withRules([positiveNumbersOnly])
        .build(),
    ],
  },
  {
    fields: [
      getFormFieldBuilder('numOfAbuDhabiVisaHolders', 'Number of full-time employees to be based in Abu Dhabi', inputTypes.number)
        .min(0)
        .withRules([positiveNumbersOnly])
        .build(),
    ],
  },
];

export const raisingInformationFields = [
  {
    fields: [
      getFormFieldBuilder('lastQuarterRevenue', 'Last quarter revenue(USD)', inputTypes.number)
        .min(0)
        .withRules([positiveNumbersOnly])
        .build(),
    ],
  },
  {
    fields: [
      getFormFieldBuilder('lastYearRevenue', 'Last year revenue(USD)', inputTypes.number)
        .min(0)
        .withRules([positiveNumbersOnly])
        .build(),
    ],
  },
  {
    fields: [
      getFormFieldBuilder('totalFundingRaised', 'Capital raised till date(USD)', inputTypes.number)
        .min(0)
        .withRules([positiveNumbersOnly])
        .build(),
    ],
  },
];

export const investmentInformationFields = [
  {
    fields: [
      getFormFieldBuilder('lastValuation', 'Last valuation (USD)', inputTypes.number)
        .min(0)
        .withRules([positiveNumbersOnly])
        .build(),
    ],
  },
  {
    fields: [
      getFormFieldBuilder('leadInvestorInCurrentRound', 'Lead investor name (current round)')
        .build(),
    ],
  },
];

export const goalsAndChallengesFields = [
  {
    fields: [
      getFormFieldBuilder('keyMilestones', 'Key milestones', inputTypes.textArea)
        .withRows(4)
        .build(),
    ],
  },
  {
    fields: [
      getFormFieldBuilder('topChallenges', 'What are your current top three challenges?', inputTypes.textArea)
        .withRows(4)
        .build(),
    ],
  },
];

export const pitchDeckFields = [
  {
    fields: [
      getFormFieldBuilder('sharePitchDeckWithPartners', 'Do you mind sharing your latest pitch deck with Hub71 VC Partners?', inputTypes.radio)
        .withRadioValues(sharePitchDeckOptions)
        .build(),
    ],
  },
  {
    fields: [
      getFormFieldBuilder('comments', 'Would you like to tell us something else?', inputTypes.textArea)
        .withRows(4)
        .build(),
    ],
  },
]
