import React, { useEffect, useState } from 'react';
// import { identity } from 'utils';
import './ServiceRequestTotal.scss';
import { getTotalPrice } from 'packages/service_request_repository/api';
import { TotalPriceType } from 'types/services/servicesDrawer';

type DataPayload<DataType> = {
  data: DataType
}

const ServiceRequestTotal = (props: TotalPriceType) => {
  const [isLoading, setIsLoading] = useState(false)
  const [price, setPrice] = useState<number>()
  const [errorMsg, setErrorMsg] = useState<string>('')

  const { setTotalPrice, setPlan } = props;

  useEffect(() => {
    // const tPrice = identity.isTruthyNumber(props.price) ? price! * quantity! + props.price! : 0
    setIsLoading(true)
    const accessToken = localStorage.getItem('access_token')
    const MEMBER_ID = props.memberId ? `memberID=${props.memberId}` : null
    const PLAN = props.plan ? `&plan=${props.plan}` : null
    const PASSPORT = props.passport ? `&passportNo=${props.passport}` : null
    const EMIRATE = '&emirate=Abu Dhabi'
    if (accessToken && MEMBER_ID && PASSPORT && PLAN) {
      const fetchData = async () => {
        setIsLoading(true)
        const etp = {} as DataPayload<number>
        setTotalPrice(etp)
        const data = await getTotalPrice({
          searchParams: `${MEMBER_ID}${PLAN}${PASSPORT}${EMIRATE}`,
          accessToken,
        })
        if (data.error) {
          setIsLoading(false)
          setErrorMsg(data.error.message)
          setPlan({ data: '' })
        } else if (data?.data) {
          const tp = { data: data?.data?.amount } as DataPayload<number>
          setIsLoading(false)
          setErrorMsg('')
          if (data?.data?.amount) {
            setPrice(data.data.amount)
            setTotalPrice(tp)
          }
        }
      }
      fetchData()
    } else {
      setIsLoading(false)
    }
  }, [props.memberId, props.passport, props.plan, props.price]);

  return (
    <div className="service-request-total--container">
      <div className="service-request__total">
        <div className="service-request__total-text">Total</div>
        <div className="service-request__total-amount">
          {
            isLoading && (
              <div className="service-request__spinner">
                <div className="service-request__loader" />
              </div>
            )
          }
          {price && !errorMsg && `AED ${price}`}
          {!price && !errorMsg && `AED ${props.price}`}
          {errorMsg && `${errorMsg}`}
        </div>
      </div>
      {/* {
        !props.plan && (
          <div className="details-overview">
            <p className="details-overview__subheader alert_text">Plan is not set or Invalid</p>
          </div>
        )
      } */}
    </div>
  );
};

export default ServiceRequestTotal;
