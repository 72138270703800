import { AxiosRequestConfig } from 'axios';

import httpClient, {
  ApiResponseType,
  queryBuilder,
  ParamsPayload,
} from 'packages/http_client';
import { identity } from 'utils';
import { FORM_DATA_CONFIG } from 'packages/http_client/utils';
import {
  GetStartupsWorkingWithIdCountResponse,
  CreateStartupPayload,
  CreateStartupApiResponseType,
  GetStartupListCountApiResponseType,
  GetStartupListResponse,
  GetStartupsCountResponse,
  StartupQuery,
  StartupType,
  GetStartupApiResponseType,
  GetStartupsApiResponseType,
  UpdateStartupApiResponseType,
  EditStartupPayload,
} from '../types';
import {
  formatQuery,
  STARTUP_API,
  convertCreateStartupRequestPayload,
  convertGetStartupResponse,
  convertGetStartupListResponse,
  convertEditStartupRequestPayload,
} from '../utils';

const getStartupsListApi = (query: StartupQuery) => {
  const url = `${STARTUP_API}`;
  const config: AxiosRequestConfig = { params: {} };
  config.params = formatQuery(query);
  return httpClient.get<ApiResponseType<GetStartupsApiResponseType>>(url, config);
}

const getStartupsListCount = (query: StartupQuery) => {
  const url = `${STARTUP_API}`;
  const config: AxiosRequestConfig = { params: {} };
  const countQuery: StartupQuery = { ...query, filters: { ...query.filters, fieldSelection: [{ field: 'id', aggregation: 'count' }] } };
  config.params = formatQuery(countQuery);
  return httpClient.get<ApiResponseType<GetStartupListCountApiResponseType>>(url, config);
}

const getStartupsCountWorkingWithIdCountApi = (query: ParamsPayload) => {
  const url = STARTUP_API;
  const params = formatQuery({ ...query, fieldSelection: ['startup_id'], distinct: true });
  const config: AxiosRequestConfig = { params };
  return httpClient.get<ApiResponseType<GetStartupsWorkingWithIdCountResponse>>(url, config);
}

const createStartupApi = (payload: CreateStartupPayload) => {
  const url = STARTUP_API;
  const requestPayload = convertCreateStartupRequestPayload(payload);
  return httpClient.post<ApiResponseType<CreateStartupApiResponseType>>(url, requestPayload, FORM_DATA_CONFIG);
};

const updateStartupApi = (id: number, payload: Partial<EditStartupPayload>) => {
  const url = `${STARTUP_API}/${id}`;
  const requestPayload = convertEditStartupRequestPayload(payload);
  return httpClient.put<ApiResponseType<UpdateStartupApiResponseType>>(url, requestPayload, FORM_DATA_CONFIG);
};

export const createStartup = async (payload: CreateStartupPayload) => {
  const response = await createStartupApi(payload);
  const { data, error, httpStatus } = response;
  return { error, httpStatus, data: data?.id[0] };
}

const getStartupApi = (id: number, isPreload: boolean) => {
  const url = `${STARTUP_API}/${id}`;
  const params = queryBuilder();
  if (isPreload) {
    params.preload();
  }
  const config: AxiosRequestConfig = { params: params.toObject() };
  return httpClient.get<ApiResponseType<GetStartupApiResponseType>>(url, config);
};

const getStartupsInCohortApi = (id: number) => {
  const url = `${STARTUP_API}`;
  const config: AxiosRequestConfig = { params: queryBuilder({ cohort_id: id }).toObject() };
  return httpClient.get<ApiResponseType<GetStartupsApiResponseType>>(url, config);
};

export const getStartupById = async (id: number, isPreload: boolean): Promise<ApiResponseType<StartupType>> => {
  const response = await getStartupApi(id, isPreload);
  const { data: startup, error, httpStatus } = response;
  return { error, httpStatus, data: convertGetStartupResponse(startup?.result) };
};

export const getStartupsInCohort = async (id: number): Promise<ApiResponseType<StartupType[]>> => {
  const response = await getStartupsInCohortApi(id);
  const { data, error, httpStatus } = response;
  return { error, httpStatus, data: convertGetStartupListResponse(data) };
};

export const updateStartup = async (id: number, payload: Partial<StartupType>) => {
  const response = await updateStartupApi(id, payload);
  const { error, httpStatus } = response;
  return { error, httpStatus };
};

export const getStartupsCountApi = async (query: StartupQuery): Promise<GetStartupsCountResponse> => {
  const response = await getStartupsListCount(query);
  const { error, data, httpStatus } = response;
  return { error, httpStatus, data: data?.result![0].id__count };
}

export const getStartupsCountWorkingWithIdCount = async (query: ParamsPayload): Promise<GetStartupsCountResponse> => {
  const response = await getStartupsCountWorkingWithIdCountApi(query);
  const { error, data, httpStatus } = response;
  return { error, httpStatus, data: identity.isFullArray(data?.result) ? data?.result!.length : 0 };
}

export const getStartupsList = async (query?: StartupQuery): Promise<GetStartupListResponse> => {
  const response = await getStartupsListApi(query || {});
  const { data, error, httpStatus } = response;
  return { error, httpStatus, data: convertGetStartupListResponse(data) };
}
