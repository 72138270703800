import React, { useState } from 'react';
import { Button } from 'antd';

import { identity } from 'utils';
import { AcceptanceLetterProps } from 'types/addDetails';
import { Input } from 'components/components';
import { AcceptanceLetterPreview, AcceptedLetter } from './components';

import './AcceptanceLetter.scss';

const AcceptanceLetter = (props: AcceptanceLetterProps) => {
  const { letterAcceptance, letterAcceptanceStatus, fileUrl } = props;
  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <div className="acceptance-letter">
      <Input label="Acceptance letter" layout="vertical" required>
        <div className="acceptance-letter__container">
          <div className="acceptance-letter__action-buttons">
            <Button disabled={identity.isEmptyString(fileUrl)} className="secondary-button" onClick={() => setIsOpen(true)}>View</Button>
          </div>
          <AcceptanceLetterPreview
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            showFooter={false}
            fileUrl={fileUrl}
            letterAcceptance={letterAcceptance}
          />
          <div className="acceptance-letter__acceptance-container">
            {letterAcceptance
              ? <AcceptedLetter />
              : (
                <Button
                  type="text"
                  className="acceptance-letter__accept-button"
                >
                  {letterAcceptanceStatus}
                </Button>
              )}
          </div>
        </div>
      </Input>
    </div>
  );
}

AcceptanceLetter.defaultProps = {
  letterAcceptance: false,
}

export default AcceptanceLetter;
