import React from 'react';

import requiredValidation from '../../../utils/constants/validationRules';
import { OfferPriceInputProps } from '../../../types/serviceProviders/serviceProvidersOffer';
import Row from '../../components/FormBuilder/components/Row';
import PriceInput from './components/PriceInput';
import RangePriceInput from './components/RangePriceInput';

const OfferPriceInput = (props: OfferPriceInputProps) => {
  const { priceType } = props;

  if (priceType === 'range') {
    return <RangePriceInput />;
  }

  return (
    <Row>
      <PriceInput
        label="Amount"
        name="amount"
        suffix={priceType === 'variable' ? 'per hour' : null}
        rules={[requiredValidation]}
      />
    </Row>
  );
};

export default OfferPriceInput;
