import { API_ROOT } from 'packages/http_client';

export const NOTIFICATION_API = `${API_ROOT}/notification`;

export const NOTIFICATION_TYPE = {
  cohort: 'Cohort',
  startup: 'Startup',
  serviceRequest: 'Service Request',
  partnerRequest: 'Partner Request',
}
export const NUMBER_TO_NOTIFICATION_TYPE: { [key: number]: string} = {
  1: NOTIFICATION_TYPE.cohort,
  2: NOTIFICATION_TYPE.startup,
  3: NOTIFICATION_TYPE.serviceRequest,
  4: NOTIFICATION_TYPE.partnerRequest,
}

export const NOTIFICATION_TYPE_TO_NUMBER = {
  [NOTIFICATION_TYPE.cohort]: 1,
  [NOTIFICATION_TYPE.startup]: 2,
  [NOTIFICATION_TYPE.serviceRequest]: 3,
  [NOTIFICATION_TYPE.partnerRequest]: 4,
}
