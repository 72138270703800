import React, { useEffect } from 'react'
import { Form, Drawer, Button } from 'antd';
import classNames from 'classnames';

import FormBuilder from '../../../../../components/FormBuilder';
import { ServiceDrawerProps } from '../../../../../../types/serviceProviders/serviceDrawer';
import { editServiceDetailsFields } from './utils/constants';
import Input from '../../../../../components/Input';
import { ServiceProviderServicesType } from '../../../../../../types/serviceProviders/ServiceProviderDetails';

import './ServiceDrawer.scss';

const ServiceDrawer = (props: ServiceDrawerProps) => {
  const {
    open,
    onClose,
    service,
    serviceId,
    actions: {
      createRequestedService,
    },
  } = props;

  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue(service);
  }, [service, form])

  const onSubmit = (data: ServiceProviderServicesType) => {
    data.serviceStatus = 'added';
    data.serviceProvider = service.serviceProvider;
    data.id = service.id;
    data.name = service.name;
    data.type = service.type;
    createRequestedService({ id: serviceId, data });
    onClose();
  }

  return (
    <Drawer
      className={classNames('drawer', 'service-drawer')}
      open={open}
      onClose={() => onClose()}
    >
      <Form
        form={form}
        className="form-standalone"
        onFinish={onSubmit}
      >
        <div className="service-drawer__headings">
          <Input.Heading title={service.serviceProvider.name} />
          <Input.Heading title={service.name} />
        </div>
        <FormBuilder
          oneColumn
          formFragment
          layout="horizontal"
          fields={editServiceDetailsFields}
        />
        <div className="service-drawer__buttons">
          <Button className="primary-button" htmlType="submit">Add to request</Button>
        </div>
      </Form>
    </Drawer>
  )
}

export default ServiceDrawer;
