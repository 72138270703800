import { connect } from 'react-redux';
import { Action, bindActionCreators, Dispatch } from 'redux';

import { addNewPartnerMember, getPartners } from 'redux/actions/partners';
import { StateType } from 'types';
import { selectPartners } from 'redux/selectors/partners';

const mapStateToProps = (state: StateType) => ({
  partners: selectPartners(state),

});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  actions: bindActionCreators({
    addNewPartnerMember,
    getPartners,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps);
