import React, { useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Tabs } from 'antd';
import { ColumnsType } from 'antd/es/table';

import { composePeopleDetailsUrl } from 'routes/routeComposers';
import { PeopleType } from 'types/people';
import { PeopleOverviewProps } from 'types/people/peopleOverview';
import { IPeopleQuery } from 'types/reduxTypes/ActionTypes/PeopleTypes';
import { MemberType } from 'types/memberDetails';
import {
  DRAWERS,
  getOffset,
  useTableHook,
  getRowKey,
} from 'utils';
import config from 'assets/config';
import { peopleOverviewColumns } from '../utils/constants/columns';
import { Table, gridPaginationOptions, paginationOptions } from '../components';
import { FILTERS_MAP } from '../components/SearchBar/utils/constants';

const PeopleOverview = (props: PeopleOverviewProps) => {
  const {
    founders,
    teamMembers,
    activeTab,
    peopleCount,
    isLoading,
    all,
    filters,
    filtersActive,
    isRowTableLayout,
  } = props;
  const {
    getFounders,
    getTeamMembers,
    setIsDrawerOpen,
    setActiveTab,
    setDrawerBody,
    getAllMembers,
    getPeopleCount,
    setTableLayout,
  } = props.actions;

  const {
    state,
    setLimit,
    setCurrentPage,
    setPreviousTab,
  } = useTableHook();

  const { limit, currentPage, previousTab } = state;
  const history = useHistory();

  const tabChanged = previousTab !== activeTab;

  useEffect(() => {
    let limitDefault;
    if (!isRowTableLayout) {
      limitDefault = paginationOptions.includes(limit) ? config.GRID_TABLE_DEFAULT_LIMIT : limit;
    } else {
      limitDefault = gridPaginationOptions.includes(limit) ? config.TABLE_DEFAULT_LIMIT : limit;
    }
    setLimit(limitDefault);
    setCurrentPage(1);
  }, [isRowTableLayout]);

  useEffect(() => {
    setCurrentPage(1);
  }, [filters]);

  useEffect(() => {
    setTableLayout({ isRowTableLayout: false })
  }, [])

  useEffect(() => {
    if ((isRowTableLayout && paginationOptions.includes(limit))
      || (!isRowTableLayout && gridPaginationOptions.includes(limit))) {
      const query: IPeopleQuery = { limit, filters, offset: tabChanged ? 0 : getOffset(limit, currentPage) };
      if (tabChanged) {
        setPreviousTab(activeTab);
      }
      switch (activeTab) {
        case 'founders': {
          getFounders({ query });
          break;
        }
        case 'all': {
          getAllMembers({ query });
          break;
        }
        case 'team-members': {
          getTeamMembers({ query });
          break;
        }
        default: {
          break;
        }
      }
      getPeopleCount(filters);
    }
  }, [limit, currentPage, activeTab, filters, isRowTableLayout]);

  const openPeopleDetails = ({ id }: PeopleType) => history.push(composePeopleDetailsUrl(id, 'startup'));

  const handleOnChange = (activeKey: string) => {
    if (['founders', 'team-members', 'all'].includes(activeKey)) {
      setActiveTab({ activeTab: activeKey });
    }
  }

  const onAddButtonClick = () => {
    setDrawerBody({ drawerBody: DRAWERS.addMember })
    setIsDrawerOpen({ isDrawerOpen: true });
  }

  const tableContent = useCallback(
    (dataSource: MemberType[], total: number, columns: ColumnsType<MemberType>, initialKey: string) => (
      <Table
        isLoading={isLoading}
        onRow={(record) => ({ onClick: () => openPeopleDetails(record) })}
        onGridCardClick={openPeopleDetails}
        showSearchBar
        leftContent="add-button"
        addButtonText="member"
        placeholder="people"
        dataSource={dataSource}
        columns={columns}
        showDisplayButtons
        onAddButtonClick={onAddButtonClick}
        rowKey={getRowKey(dataSource, initialKey, 'id')}
        showCustomPagination
        total={total}
        limit={limit}
        setLimit={setLimit}
        setCurrentPage={setCurrentPage}
        currentPage={currentPage}
        filterButtonType="dropdown"
        filterDropdownElements={FILTERS_MAP.people}
        filtersActive={filtersActive}
      />
    ),
    [currentPage, limit, filtersActive, isLoading],
  );

  return (
    <div className="people-overview content-container">
      <Tabs tabBarGutter={0} activeKey={activeTab} onChange={handleOnChange} className="tab-buttons-lg">
        <Tabs.TabPane tab={`All (${peopleCount.all})`} key="all">
          {tableContent(all, peopleCount.all, peopleOverviewColumns, 'people-overview-people')}
        </Tabs.TabPane>
        <Tabs.TabPane tab={`Founders (${peopleCount.founders})`} key="founders">
          {tableContent(founders, peopleCount.founders, peopleOverviewColumns, 'people-overview-founder')}
        </Tabs.TabPane>
        <Tabs.TabPane tab={`Team members (${peopleCount.teamMembers})`} key="team-members">
          {tableContent(teamMembers, peopleCount.teamMembers, peopleOverviewColumns, 'people-overview-team-member')}
        </Tabs.TabPane>
      </Tabs>
    </div>
  )
}
export default PeopleOverview;
