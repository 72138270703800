import { UserQuery } from 'packages/user_repository/types/users';
import { GetAllQueryPayload } from 'types/reduxTypes/ActionTypes';
import { SetUsersPayload } from 'types/reduxTypes/ActionTypes/UserTypes';

export const types = {
  GET_USERS: 'GET_USERS',
  SET_USERS: 'SET_USERS',
  RESET_USERS: 'RESET_USERS',
}

export const getUsers = (payload: GetAllQueryPayload<UserQuery>) => ({
  type: types.GET_USERS,
  payload,
});

export const setUsers = (payload: SetUsersPayload) => ({
  type: types.SET_USERS,
  payload,
});

export const resetUsers = (payload: object = {}) => ({
  type: types.RESET_USERS,
  payload,
});
