import React, { useCallback, useEffect } from 'react';
import { Spin } from 'antd';
import { useParams } from 'react-router';

import routes from 'routes';
import { CreatePerformanceReportProps } from 'types/performance';
import { identity } from 'utils';
import { DiscardModalConfig } from 'types/componentTypes/StepsForm';
import { PerformanceTypePayload, REPORT_STATUS } from 'packages/performance_repository';
import StepsForm from '../../../components/StepsForm';
import { defaultValues, discardModalText, steps } from './utils/constants';

import './CreatePerformanceReport.scss';

const CreatePerformanceReport = (props: CreatePerformanceReportProps) => {
  const {
    actions,
    startupId,
    isLoading,
    performanceReportDetails,
  } = props;

  const params: { id?: string } = useParams();
  const { id } = params;

  const isEdit = identity.isTruthyString(id);

  useEffect(() => {
    actions.getStartupInvestmentStages();

    if (isEdit) {
      actions.getPerformanceReportById({ id: Number(id) })
    }

    return () => actions.resetAll();
  }, [id]);

  const onFinish = useCallback((formData: PerformanceTypePayload) => {
    const data: PerformanceTypePayload = {
      ...formData,
      status: REPORT_STATUS.submitted,
      startupId,
    };
    if (isEdit) {
      actions.updatePerformanceReportById({ data })
    } else {
      actions.createPerformanceReport({ data });
    }
  }, []);

  const onDiscard = useCallback((formData: PerformanceTypePayload) => {
    const data: PerformanceTypePayload = {
      ...formData,
      status: REPORT_STATUS.draft,
      startupId,
    };
    actions.createPerformanceReport({ data });
  }, []);

  const modalConfig: DiscardModalConfig = {
    okButtonText: 'Save as draft',
    cancelButtonText: 'Discard report',
    modalTitle: 'Close report',
    text: discardModalText,
    onDiscard,
  }

  return (
    <div className="content-container">
      <Spin spinning={isLoading}>
        <div className="create-performance-report">
          <StepsForm
            steps={steps}
            backButtonText="performance"
            onFinish={onFinish}
            submitButtonText="Submit"
            modalConfig={isEdit ? undefined : modalConfig}
            defaultValues={isEdit ? performanceReportDetails : defaultValues}
            backButtonPath={routes.performanceUrl}
          />
        </div>
      </Spin>
    </div>
  )
};

export default CreatePerformanceReport;
