import config from 'assets/config';
import { REPORT_STATUS } from 'packages/performance_repository';
import { PerformanceStateType } from 'types/reduxTypes/StateTypes';

export const defaultPerformanceState: PerformanceStateType = {
  isLoading: false,
  limit: config.TABLE_DEFAULT_LIMIT,
  offset: 0,
  performanceReports: [],
  activeTab: 'All',
  performanceReportId: 0,
  counts: {
    [REPORT_STATUS.approved]: 0,
    [REPORT_STATUS.rejected]: 0,
    [REPORT_STATUS.submitted]: 0,
    [REPORT_STATUS.draft]: 0,
    All: 0,
  },
  details: {},
};

export default null;
