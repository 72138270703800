import React, { useEffect, useMemo, useState } from 'react'
import { Form } from 'antd';
import { RcFile } from 'antd/es/upload/interface';
import Editor from '@monaco-editor/react';

import { FormBuilder, Input, RenderIf } from 'components/components';
import DrawerButton from 'components/components/Drawer/components/DrawerButton/View';
import { EditTemplateDataType, EditTemplateDrawerProps } from 'types/reduxTypes/ActionTypes/SettingsOverviewTypes'
import { getImageLink, identity } from 'utils';
import { EditTemplateDrawerFields, defaultValue, pdfFileSubTabs } from './utils/constants';

import './EditTemplateDrawer.scss';

const EditTemplateDrawer = (props: EditTemplateDrawerProps) => {
  const { templateDetail, activeSubTab, actions } = props;
  const [form] = Form.useForm();
  const [templateFile, setTemplateFile] = useState(templateDetail?.url);
  const [fileContent, setFileContent] = useState('');

  const isHtmlTab = !pdfFileSubTabs.includes(activeSubTab);

  const handleSubmit = (value: EditTemplateDataType) => {
    const data = { ...value };
    data.url = identity.isObjWithChildren(templateFile) ? templateFile : undefined;
    actions.updateTemplateById({ data });
  }

  const readHTMLContent = (file: Blob) => {
    const reader = new FileReader();
    reader.readAsText(file);
    reader.onload = () => {
      setFileContent(reader.result as string);
      form.setFieldValue('fileContent', reader.result as string);
    }
  }

  const handleFileUpload = (file: RcFile) => {
    form.setFieldValue('url', file as Blob);
    setTemplateFile(file as Blob);
    if (isHtmlTab) {
      readHTMLContent(file as Blob);
    }
  }

  const defaultFileList = useMemo(() => {
    const idSplit = identity.isTruthyString(templateFile) ? (templateFile as string).split('/') : '';
    const url = getImageLink(templateFile as string);
    const name = idSplit[idSplit.length - 1];
    return (
      identity.isTruthyString(name)
        ? [{
          name,
          size: 0,
          url,
          uid: '1',
        }]
        : []
    )
  }, [templateFile]);

  useEffect(() => {
    form.setFieldsValue(templateDetail);
    setFileContent(templateDetail?.fileContent);
    setTemplateFile(templateDetail?.url);
  }, [templateDetail]);

  return (
    <div className="add-template-drawer">
      <Form
        form={form}
        onFinish={handleSubmit}
        initialValues={defaultValue}
      >
        <FormBuilder
          oneColumn
          formFragment
          defaultValues={defaultValue}
          fields={EditTemplateDrawerFields}
        />
        <Input name="url" label="Template Document" layout="vertical">
          <Input.DraggerUpload
            name="url"
            accept={isHtmlTab ? '.html' : '.pdf'}
            defaultFileList={defaultFileList}
            beforeUpload={(file) => handleFileUpload(file)}
            isRoundableButton
            allowChange
          />
        </Input>
        <RenderIf condition={!identity.isFalsy(templateFile) && isHtmlTab}>
          <div className="add-template-drawer__html">
            <Input label="Edit HTML template" layout="vertical" name="fileContent">
              <Editor
                height="300px"
                defaultLanguage="html"
                options={{ minimap: { enabled: false } }}
                onChange={(value) => setFileContent(value!)}
                value={fileContent}
              />
            </Input>
          </div>
        </RenderIf>
        <RenderIf condition={identity.isTruthyString(fileContent) && isHtmlTab}>
          <iframe className="html-preview" title="email-template" srcDoc={fileContent!} />
        </RenderIf>
        <DrawerButton>Save changes</DrawerButton>
      </Form>
    </div>
  )
}

export default EditTemplateDrawer
