/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Collapse as AntdCollapse } from 'antd';
import classnames from 'classnames';
import { CollapsePanelProps } from 'antd/lib/collapse/CollapsePanel';

import { ChevronDownIcon, ChevronUpIcon } from '../../../assets/icons';

import './Collapse.scss';

const Collapse = (props: Omit<CollapsePanelProps, 'key'>) => {
  const expandIcon = (isActive: boolean | undefined) => (isActive ? <ChevronUpIcon /> : <ChevronDownIcon />);

  return (
    <div className={classnames('collapsable-container', props.className)}>
      <AntdCollapse
        defaultActiveKey={['1']}
        expandIconPosition="end"
        className="collapse"
        expandIcon={({ isActive }) => expandIcon(isActive)}
      >
        <AntdCollapse.Panel {...props} key="1" className="expandable">
          {props.children}
        </AntdCollapse.Panel>
      </AntdCollapse>
    </div>
  );
}

export default Collapse;
