import { UserRoleType } from '../../../../types/auth';
import requiredValidation, { emailValidation } from '../../../../utils/constants/validationRules';
import { isStartup } from '../../../../utils/helpers/roles';
import { inputTypes } from '../../../components/FormBuilder/utils/constants';
import { getFormFieldBuilder } from '../../../components/FormBuilder/utils/FormFieldBuilder';

export const defaultValues = {
  id: 0,
  name: '',
  firstName: '',
  lastName: '',
  imageLink: '',
  websiteLink: '',
  role: '',
  phoneNumber: '',
  email: '',
  category: '',
  about: '',
  companyDeck: '',
}

export const editServiceProviderDetailsAdminFields = (userType: UserRoleType) => [
  {
    fields: [
      getFormFieldBuilder('about', 'Description', inputTypes.textArea)
        .withRows(4)
        .withRules([requiredValidation])
        .disabled(isStartup(userType))
        .build(),
    ],
  },
  {
    fields: [
      getFormFieldBuilder('category', 'Category')
        .disabled(true)
        .build(),
    ],
  },
  {
    fields: [
      getFormFieldBuilder('websiteLink', 'Website')
        .withRules([requiredValidation])
        .disabled(isStartup(userType))
        .build(),
    ],
  },
];

export const editServiceProviderDetailsStartupFields = (userType: UserRoleType) => [
  {
    fields: [
      getFormFieldBuilder('about', 'Description', inputTypes.textArea)
        .disabled(isStartup(userType))
        .withRows(4)
        .withRules([requiredValidation])
        .build(),
    ],
  },
  {
    fields: [
      getFormFieldBuilder('category', 'Category')
        .disabled(isStartup(userType))
        .build(),
    ],
  },
  {
    fields: [
      getFormFieldBuilder('howToObtain', 'How to obtain', inputTypes.textArea)
        .disabled(isStartup(userType))
        .withRows(5)
        .withRules([requiredValidation])
        .build(),
    ],
  },
  {
    fields: [
      getFormFieldBuilder('websiteLink', 'Website')
        .withRules([requiredValidation])
        .disabled(isStartup(userType))
        .build(),
    ],
  },
];

export const editContactPersonFields = (userType: UserRoleType) => [
  {
    heading:'Contact Person',
    fields: [
      getFormFieldBuilder('firstName', 'First Name')
        .withRules([requiredValidation])
        .disabled(isStartup(userType))
        .build(),
    ],
  },
  {
    fields: [
      getFormFieldBuilder('lastName', 'Last Name')
        .withRules([requiredValidation])
        .disabled(isStartup(userType))
        .build(),
    ],
  },
  {
    fields: [
      getFormFieldBuilder('email', 'Email')
        .withRules([requiredValidation, emailValidation])
        .disabled(isStartup(userType))
        .build(),
    ],
  },
  {
    fields: [
      getFormFieldBuilder('phoneNumber', 'Phone no.')
        .withRules([requiredValidation])
        .disabled(isStartup(userType))
        .build(),
    ],
  },
  {
    fields: [
      getFormFieldBuilder('role', 'Role')
        .withRules([requiredValidation])
        .disabled(isStartup(userType))
        .build(),
    ],
  },
]

export const editPerksFields = (userType: UserRoleType) => (
  [
    {
      heading:'Perks',
      fields: [
        getFormFieldBuilder('perksDescription', 'Description', inputTypes.textArea)
          .withRows(4)
          .disabled(isStartup(userType))
          .build(),
      ],
    },
    {
      fields: [
        getFormFieldBuilder('perksWebLink', 'Link')
          .withRules([requiredValidation])
          .disabled(isStartup(userType))
          .build(),
      ],
    },
  ]
)

export const getServiceProviderDetailsFields = (userType: UserRoleType) => {
  if (isStartup(userType)) {
    return editServiceProviderDetailsStartupFields(userType);
  }
  return editServiceProviderDetailsAdminFields(userType);
}
