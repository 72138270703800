import { DataPayload } from 'types/reduxTypes/ActionTypes';
import { StartupFiltersType } from 'types/startups';

export const types = {
  SET_INDUSTRIES: 'SET_INDUSTRIES',
  RESET_FILTERS: 'RESET_FILTERS',
  SET_COHORTS: 'SET_COHORTS',
  SET_STAGE: 'SET_STAGE',
  SET_CAPITAL_RAISED: 'SET_CAPITAL_RAISED',
  SET_INCENTIVE_AMOUNT: 'SET_INCENTIVE_AMOUNT',
  SET_FILTERS: 'SET_FILTERS',
};

export const setIndustries = (payload: DataPayload<number[]>) => ({
  type: types.SET_INDUSTRIES,
  payload,
});

export const resetFilters = (payload: object = {}) => ({
  type: types.RESET_FILTERS,
  payload,
});

export const setCohorts = (payload: DataPayload<number[]>) => ({
  type: types.SET_COHORTS,
  payload,
});

export const setStage = (payload: DataPayload<number>) => ({
  type: types.SET_STAGE,
  payload,
});

export const setCapitalRaised = (payload: DataPayload<string>) => ({
  type: types.SET_CAPITAL_RAISED,
  payload,
});

export const setIncentiveAmount = (payload: DataPayload<string>) => ({
  type: types.SET_INCENTIVE_AMOUNT,
  payload,
});

export const setFilters = (payload: StartupFiltersType) => ({
  type: types.SET_FILTERS,
  payload,
});
