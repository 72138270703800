import React from 'react';

import identity from '../../../../utils/identity';
import { MemberAttributeProps } from '../../../../types/memberDetails';
import { RenderIf } from '../../../components';

import './MemberAttributes.scss';

const MemberAttributes = (props: MemberAttributeProps) => {
  const { attributeTitle, attributes } = props;

  return (
    <div className="attributes">
      <RenderIf condition={identity.isTruthyString(attributeTitle)}>
        <p className="attributes__title">{attributeTitle}</p>
      </RenderIf>
      <div className="attributes__list">
        {identity.isFullArray(attributes)
          ? attributes.map((attribute) => <div className="tag--blue" key={attribute.value}>{attribute.label || 'Not Specified'}</div>)
          : `No ${attributeTitle} added.`}
      </div>
    </div>
  );
};

MemberAttributes.defaultProps = {
  attributeTitle: '',
};

export default MemberAttributes;
