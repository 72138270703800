import { createSelector } from 'reselect';

import { PartnersStateType } from 'types/reduxTypes/StateTypes';
import { StateType } from 'types';

const selectRequestState = (state: StateType) => state.partnersReducer;

export const selectPartners = createSelector(
  selectRequestState,
  (state: PartnersStateType) => state.partners,
);

export const selectCurrentPartnerTab = createSelector(
  selectRequestState,
  (state: PartnersStateType) => state.currentTab,
);

export const selectPartnerDetails = createSelector(
  selectRequestState,
  (state: PartnersStateType) => state.details.partner,
);

export const selectIsLoading = createSelector(
  selectRequestState,
  (state: PartnersStateType) => state.isLoading,
);

export const selectPartnerMembers = createSelector(
  selectRequestState,
  (state: PartnersStateType) => state.details.members,
);

export const selectPartnerData = createSelector(
  selectRequestState,
  (state: PartnersStateType) => state.partnerData,
);

export const selectPartnerRequestDetails = createSelector(
  selectRequestState,
  (state: PartnersStateType) => state.details.request,
);

export const selectPartnersFiltersActive = createSelector(
  selectRequestState,
  (state: PartnersStateType) => state.filtersActive,
);
