import { connect } from 'react-redux';
import { Action, bindActionCreators, Dispatch } from 'redux';

import { setIsDrawerFilterTableOpen } from '../../../../redux/actions/workspace';

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  actions: bindActionCreators({
    setIsDrawerFilterTableOpen,
  }, dispatch),
});

export default connect(null, mapDispatchToProps);
