import React, { useState } from 'react';
import { Button } from 'antd';

import { WalletTransactionDetailsProps } from 'types/wallet';
import { Modal } from 'components/components';
import { WalletModalContent } from './components';

const WalletTransactionDetailsModal = (props: WalletTransactionDetailsProps) => {
  const [isDetailsModalOpen, setIsDetailsModalOpen] = useState<boolean>(false);

  const toggleModal = () => {
    setIsDetailsModalOpen(!isDetailsModalOpen);
  }

  return (
    <>
      <Button onClick={toggleModal} className="secondary-button">View Details</Button>
      <Modal
        title="Transactions Details"
        footer={null}
        open={isDetailsModalOpen}
        onCancel={toggleModal}
        isEditButton={false}
      >
        <WalletModalContent transaction={props.transaction} />
      </Modal>
    </>
  )
}

export default WalletTransactionDetailsModal
