import httpClient, { ApiResponseType, GetResourceSuccessResponseType, ResponseType } from '../http_client';
import { CountryType } from './types';
import { COUNTRY_API } from './utils';

const getNationalitiesApi = () => httpClient.get<ApiResponseType<GetResourceSuccessResponseType<CountryType[]>>>(
  COUNTRY_API,
);

export const getNationalities = async (): Promise<ResponseType<CountryType[]>> => {
  const response = await getNationalitiesApi();
  const { error, httpStatus, data } = response;
  return { error, httpStatus, data: data?.result };
}

export default null;
