import { connect } from 'react-redux';

import { StateType } from 'types';
import { selectIsLoading, selectUserType } from 'redux/selectors/auth';

const mapStateToProps = (state: StateType) => ({
  userType: selectUserType(state),
  isLoading: selectIsLoading(state),
});

export default connect(mapStateToProps);
