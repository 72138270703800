/* eslint-disable @typescript-eslint/no-explicit-any */
import { GetParamsType, ConditionObject, SelectFieldsType } from 'packages/http_client/types';
import { identity } from 'utils';
import { OPERATION_TYPE } from '../constants';
import conditions from '../queryParamsHelpers/conditions';
import { formatParams } from '../queryParamsHelpers';

export class QueryParamsBuilder {
  constructor(queryObject: GetParamsType = {}) {
    for (const key in queryObject) {
      if (queryObject[key] === undefined) {
        delete queryObject[key];
      }
    }
    this.queryObject = queryObject;
  }

  queryObject: GetParamsType;

  private addSuffixToKeys = (object: ConditionObject) => {
    const { field, value, operation } = object;
    this.queryObject[`${field}__${OPERATION_TYPE[operation!]}`] = value;
  }

  in(values: {[key: string]: string[] | number[] | undefined}) {
    for (const key in values) {
      if (values[key] !== undefined) {
        this.queryObject[`${key}__in`] = identity.isEmptyArray(values[key]) ? undefined : values[key]!.toString();

        if (this.queryObject[key] !== undefined) {
          delete this.queryObject[key];
        }
      }
    }
    return this;
  }

  or(orObject: ConditionObject[]) {
    this.queryObject.$or = formatParams.or(orObject);
    return this;
  }

  orderBy(param: string | string[] | undefined) {
    if (!identity.isFalsy(param)) {
      this.queryObject.$order = formatParams.orderBy(param!);
    }
    return this;
  }

  orderByDescending(param: string | string[]) {
    this.orderBy(param).queryObject.$order = formatParams.orderByDescending(param);
    return this;
  }

  limit(value: number | undefined) {
    this.queryObject.$limit = value;
    return this;
  }

  offset(value: number | undefined) {
    this.queryObject.$offset = value;
    return this;
  }

  preload() {
    this.queryObject.$preload = 1;
    return this;
  }

  select(fields: SelectFieldsType | undefined) {
    this.queryObject.$f = formatParams.select(fields);
    return this;
  }

  greaterThan(field: string, value: any) {
    this.addSuffixToKeys(conditions.greaterThan(field, value));
    return this;
  }

  greaterThanOrEqual(field: string, value: any) {
    this.addSuffixToKeys(conditions.greaterThanOrEqual(field, value));
    return this;
  }

  lessThan(field: string, value: any) {
    this.addSuffixToKeys(conditions.lessThan(field, value));
    return this;
  }

  lessThanOrEqual(field: string, value: any) {
    this.addSuffixToKeys(conditions.lessThanOrEqual(field, value));
    return this;
  }

  contains(field: string, value: string | undefined) {
    if (identity.isTruthyString(value)) {
      this.addSuffixToKeys(conditions.contains(field, value!));
    }
    return this;
  }

  startsWith(field: string, value: string) {
    this.addSuffixToKeys(conditions.startsWith(field, value));
    return this;
  }

  endsWith(field: string, value: string) {
    this.addSuffixToKeys(conditions.endsWith(field, value));
    return this;
  }

  groupBy(params: string | string[] | undefined) {
    if (identity.isTruthyString(params) || identity.isFullArray(params)) {
      this.queryObject.$groupby = formatParams.groupBy(params!);
    }
    return this;
  }

  searchQuery(value: string) {
    this.queryObject.$q = value;
    return this;
  }

  queryRelatedTable(fields: { tableName: string, field: string, value: string | undefined }[]) {
    for (const key in fields) {
      const { value, tableName, field } = fields[key];
      const isStringifiedJSON = value?.startsWith('{') && value?.endsWith('}');
      if (identity.isTruthyString(value)) {
        if (isStringifiedJSON) {
          const json = JSON.parse(value!);
          const { value: jsonValue, from, to } = json;
          switch (jsonValue) {
            case 'range': {
              this.lessThanOrEqual(`${tableName}.${field}`, to);
              this.greaterThanOrEqual(`${tableName}.${field}`, from);
              break;
            }
            case 'less': {
              this.lessThanOrEqual(`${tableName}.${field}`, to);
              break;
            }
            case 'greater': {
              this.greaterThanOrEqual(`${tableName}.${field}`, from);
              break;
            }
            default: {
              break;
            }
          }
        } else {
          this.queryObject[`${tableName}.${field}`] = value!;
        }
      }
    }
    return this;
  }

  join(fields: { tableName: string, onId: string, leftJoin?: boolean }[]) {
    if (identity.isFullArray(fields)) {
      this.queryObject.$join = formatParams.join(fields);
    }
    return this;
  }

  distinct() {
    this.queryObject.$distinct = 1;
    return this;
  }

  toObject() {
    return this.queryObject;
  }
}

export const queryBuilder = (queryObject: any = {}) => new QueryParamsBuilder(queryObject);
