import { connect } from 'react-redux';
import { bindActionCreators, Dispatch, Action } from 'redux';

import { setShowInvoiceFields, updateServiceRequest } from 'redux/actions/request';

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  actions: bindActionCreators({ setShowInvoiceFields, updateServiceRequest }, dispatch),
});

export default connect(null, mapDispatchToProps);
