import { getFormFieldBuilder } from 'components/components/FormBuilder/utils/FormFieldBuilder';
import { inputTypes } from 'components/components/FormBuilder/utils/constants';
import { AddNewTemplateFieldsCreatorParams } from 'types/reduxTypes/ActionTypes/SettingsOverviewTypes';
import requiredValidation from '../../../../utils/constants/validationRules';

export const GetInTouchDrawerFields = ({
  handleFileUpload,
  fileType,
}: AddNewTemplateFieldsCreatorParams) => [
  {
    fields: [
      getFormFieldBuilder('message', 'Message', inputTypes.textArea)
        .withPlaceholder('Message')
        .withRows(8)
        .required()
        .withRules([requiredValidation])
        .build(),
    ],
  },
  {
    fields: [
      getFormFieldBuilder('attachment', 'Attachment', inputTypes.dragger)
        .withBeforeUpload(handleFileUpload)
        .withAccept(fileType)
        .build(),
    ],
  },
];

export default null;
