import React from 'react';

import SVGWrap from './SVGWrap';

const StartupIconV2 = () => (
  <SVGWrap>
    <path d="M7.2969 10.4885L7.2969 15.0321H8.2969V10.4885H7.2969Z" />
    <path d="M13.1022 15.0815C13.0576 15.1264 13.0048 15.1624 12.9467 15.1875C12.8656 15.2224 12.7769 15.2352 12.6893 15.2246C12.6018 15.214 12.5186 15.1805 12.4481 15.1274L9.61972 13.0061L10.2278 12.2L12.3562 13.7981L12.579 11.7298L9.92024 9.07107C9.87344 9.02481 9.83624 8.96976 9.81075 8.90909C9.78527 8.84842 9.77202 8.78332 9.77175 8.71751V5.83959C9.77376 5.24859 9.65856 4.66307 9.4328 4.11689C9.20705 3.5707 8.87522 3.07471 8.45653 2.65761L7.79892 2L7.14131 2.65761C6.72262 3.07471 6.3908 3.5707 6.16504 4.11689C5.93929 4.66307 5.82408 5.24859 5.82609 5.83959L5.82609 8.71751C5.82583 8.78332 5.81257 8.84842 5.78709 8.90909C5.76161 8.96976 5.7244 9.02481 5.6776 9.07107L3.01888 11.7298L3.24869 13.8051L5.37708 12.2071L5.98519 13.0132L3.15677 15.1345C3.08635 15.1876 3.00315 15.2211 2.9156 15.2317C2.82805 15.2422 2.73927 15.2294 2.65826 15.1946C2.57655 15.1597 2.50564 15.1036 2.45282 15.0321C2.4 14.9606 2.36718 14.8764 2.35774 14.788L2.00418 11.606C1.99444 11.5312 2.00178 11.455 2.02566 11.3834C2.04954 11.3118 2.08934 11.2464 2.14207 11.1924L4.82554 8.50892L4.822 5.83959C4.81998 5.1169 4.96144 4.40101 5.23819 3.73341C5.51494 3.06581 5.92147 2.4598 6.43421 1.9505L7.09182 1.29289C7.27935 1.10536 7.53371 1 7.79892 1C8.06414 1 8.31849 1.10536 8.50603 1.29289L9.16364 1.9505C9.67637 2.4598 10.0829 3.06581 10.3597 3.73341C10.6364 4.40101 10.7779 5.1169 10.7758 5.83959L10.7723 8.50892L13.4558 11.1924C13.5086 11.2457 13.5488 11.3103 13.5733 11.3813C13.5978 11.4523 13.6059 11.5279 13.5972 11.6025L13.2436 14.7845C13.2313 14.8966 13.1815 15.0013 13.1022 15.0815Z" />
  </SVGWrap>
);

export default StartupIconV2;
