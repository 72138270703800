import { PayloadAction } from '@reduxjs/toolkit';

import { CohortType } from 'types/cohorts/cohortOverview';
import { defaultSelectedCohort } from 'redux/utils';
import { DataPayload } from 'types/reduxTypes/ActionTypes';
import { SetFieldType, types } from './actions';

type AddNewCohortHookState = {
  cohort: CohortType,
  csvFile: string | Blob,
  logo: string | Blob,
};

const initialState: AddNewCohortHookState = {
  cohort: defaultSelectedCohort,
  csvFile: '',
  logo: '',
};

const handleSetField = (state: AddNewCohortHookState, payload: SetFieldType) => ({
  ...state,
  cohort: {
    ...state.cohort,
    [payload.field]: payload.value,
  },
});

const handeSetCsvFile = (state: AddNewCohortHookState, payload: DataPayload<string | Blob>) => ({
  ...state, csvFile: payload.data,
});

const handleSetLogo = (state: AddNewCohortHookState, payload: DataPayload<string | Blob>) => ({
  ...state, logo: payload.data,
});

const reducer = (state: AddNewCohortHookState, action: PayloadAction<SetFieldType | DataPayload<string | Blob>>) => {
  const { type, payload } = action;
  switch (type) {
    case types.SET_FIELD: {
      return handleSetField(state, <SetFieldType>payload);
    }
    case types.SET_CSV_FILE: {
      return handeSetCsvFile(state, <DataPayload<string | Blob>>payload);
    }
    case types.SET_LOGO: {
      return handleSetLogo(state, <DataPayload<string | Blob>>payload);
    }
    default:
      return state;
  }
}

export { reducer, initialState };
