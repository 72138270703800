import React, { useState } from 'react';
import { Button } from 'antd';

import { RenderIf } from 'components/components';
import { ServicesSubCategoriesOverviewTabsProps, ServiceSubCategoryType } from 'types/services';
import SubCategoryTabButton from './SubCategoryButton';
import ServicesDropdownFilters from '../ServicesDropdownFilters';

import './ServiceSubCategoriesTabs.scss';

const ServicesSubCategoriesOverviewTabs = (props: ServicesSubCategoriesOverviewTabsProps) => {
  const { tabs } = props;
  const [showDropdown, setShowDropdown] = useState<boolean>(false);

  return (
    <div className="subcategories-overview">
      <div className="subcategories-overview__subcategories-overview-tabs">
        {tabs?.map((tab: ServiceSubCategoryType) => (
          <SubCategoryTabButton key={tab.id} value={tab?.id!}>
            {tab?.name!}
          </SubCategoryTabButton>
        ))}
      </div>
      <div className="subcategories-overview__filter-buttons">
        <RenderIf condition={!showDropdown}>
          <Button className="secondary-button" onClick={() => setShowDropdown(true)}>Filter</Button>
        </RenderIf>
        <RenderIf condition={showDropdown}>
          <ServicesDropdownFilters setShowDropdown={setShowDropdown} />
        </RenderIf>
      </div>
    </div>
  );
}

export default ServicesSubCategoriesOverviewTabs;
