import React from 'react';

import SVGWrap from './SVGWrap';

const CloseIcon = () => (
  <SVGWrap>
    <path xmlns="http://www.w3.org/2000/svg" d="M14 3.05L12.95 2L8 6.95L3.05 2L2 3.05L6.95 8L2 12.95L3.05 14L8 9.05L12.95 14L14 12.95L9.05 8L14 3.05Z" />
  </SVGWrap>
);

export default CloseIcon;
