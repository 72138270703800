import { getFormFieldBuilder } from 'components/components/FormBuilder/utils/FormFieldBuilder';
import requiredValidation from 'utils/constants/validationRules';

export const defaultValue = {
  name: '',
};

export const EditDataEntryDrawerFields = [
  {
    fields: [
      getFormFieldBuilder('name', 'Name of Data entry item')
        .withRules([requiredValidation])
        .build(),
    ],
  },
];
