import React, { useEffect, useMemo } from 'react'
import { Spin } from 'antd'
import { useParams } from 'react-router-dom';
import { useLocation } from 'react-router';

import { PartnerType as Partner } from 'packages/partner_repository';
import { getRowKey, identity, isEitherAdmin } from 'utils';
import { NOT_SPECIFIED } from 'components/CohortDetails/utils/constants';
import { PartnerDetailsProps } from 'types/partners/partnersOverview';
import {
  ExpandableTable,
  ProfileOverview,
  TitleWithActions,
  Breadcrumb,
  About,
  RenderIf,
} from 'components/components'
import routes from 'routes';
import { startupTeamMembersColumns } from 'components/utils/constants/columns';
import { composeEditPartnerDetailsUrl } from 'routes/routeComposers';

import './PartnerDetails.scss';
import { LabeledValue } from '../../types';

const getRightContent = (info: Partner) => {
  const rightContentArray: LabeledValue[] = [
    { label: 'name', value: info?.name! || NOT_SPECIFIED },
    { label: 'type', value: info?.type! || NOT_SPECIFIED },
    { label: 'website', value: info?.website! || NOT_SPECIFIED },
    // Missing partnership status from BE, MOU stared date and eng date
    { label: 'contact person name', value: `${info?.contactFirstName!} ${info?.contactLastName!}` || NOT_SPECIFIED },
    { label: 'contact person email', value: info?.contactEmail || NOT_SPECIFIED },
    { label: 'contact person phone', value: info?.contactPhone! || NOT_SPECIFIED },
    { label: 'contact person role', value: info?.contactRole || NOT_SPECIFIED },
  ]
  return rightContentArray;
}

const PartnerDetails = (props: PartnerDetailsProps) => {
  const {
    details, isLoading, actions, members, userType, userPartnerId,
  } = props;

  const params: { id?: string } = useParams();
  const { id } = params;
  const location = useLocation();
  const isCompanyProfile = location.pathname === routes.companyProfileUrl && identity.isTruthyNumber(userPartnerId);

  const partnerId = useMemo(() => (isCompanyProfile ? userPartnerId! : parseInt(id!, 10)), [id]);

  useEffect(() => {
    actions.getPartnerById({ id: partnerId });
    actions.getPartnerTeamMembers({ id: partnerId })
  }, [id]);

  const rightContentArray = getRightContent(details!);

  return (
    <div className="content-container">
      <Spin spinning={isLoading}>
        <div className="partner-details">
          <RenderIf condition={!isCompanyProfile}>
            <Breadcrumb navigateTo={routes.partnersUrl} routeName="partners" />
          </RenderIf>
          <TitleWithActions
            title="Partner overview"
            buttonTitle="profile"
            link={isCompanyProfile ? routes.editCompanyProfileUrl : composeEditPartnerDetailsUrl(Number(id))}
            isEditButton
            showButton={isEitherAdmin(userType) || isCompanyProfile}
          />
          <ProfileOverview
            heading={details?.name! || NOT_SPECIFIED}
            imageUrl={details?.logo.toString()!}
            subheadingIsLocation={identity.isTruthyString(details?.location)}
            subheading={details?.location || ''}
            mainSubheading={details?.type}
            rightContent={rightContentArray}
          />
          {/** TODO: Add to do list, for now it's excluded since the client did not prioritize it */ }
          <About title={`About ${details?.name}`} description={details?.description!} />
          {/** TODO: Add requests after BE fixes */ }
          <ExpandableTable
            collapseHeader={`Members (${members?.length})`}
            dataSource={members}
            columns={startupTeamMembersColumns}
            rowKey={getRowKey(members!, 'partner-details-team-member', 'id')}
          />
        </div>
      </Spin>
    </div>
  )
}

export default PartnerDetails;
