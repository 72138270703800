import { CreateHub71EmployeePayload } from 'packages/people_repository/types/hub71_employee';
import { CreateTeamMemberPayload, InvolvementType } from 'packages/people_repository';
import {
  PeopleQuery,
  ActiveTabPayload,
  UpdateMemberPayload,
  PeopleCountPayload,
} from 'types/reduxTypes/ActionTypes/PeopleTypes';
import { DataPayload, IsLoadingPayload } from 'types/reduxTypes/ActionTypes';
import { LabeledValue } from 'types';
import { MemberType } from 'types/memberDetails';
import { PeopleFilters } from 'types/people';
import { peopleFiltersDefault } from '../../utils';

export const types = {
  GET_FOUNDERS: 'GET_FOUNDERS',
  SET_FOUNDERS: 'SET_FOUNDERS',
  GET_TEAM_MEMBERS: 'GET_TEAM_MEMBERS',
  SET_TEAM_MEMBERS: 'SET_TEAM_MEMBERS',
  GET_INVOLVEMENT_TYPES: 'GET_INVOLVEMENT_TYPES',
  SET_INVOLVEMENT_TYPES: 'SET_INVOLVEMENT_TYPES',
  ADD_MEMBER: 'ADD_MEMBER',
  ADD_HUB71_EMPLOYEE: 'ADD_HUB71_EMPLOYEE',
  SET_ACTIVE_TAB: 'SET_ACTIVE_TAB',
  UPDATE_MEMBER_BY_ID: 'UPDATE_MEMBER_BY_ID',
  SET_PEOPLE_COUNT: 'SET_PEOPLE_COUNT',
  SET_PEOPLE_IS_LOADING: 'SET_PEOPLE_IS_LOADING',
  GET_PEOPLE_COUNT: 'GET_PEOPLE_COUNT',
  GET_ALL_MEMBERS: 'GET_ALL_MEMBERS',
  SET_ALL_MEMBERS: 'SET_ALL_MEMBERS',
  GET_PEOPLE_ROLES: 'GET_PEOPLE_ROLES',
  GET_PEOPLE_SKILLS: 'GET_PEOPLE_SKILLS',
  SET_PEOPLE_ROLES: 'SET_PEOPLE_ROLES',
  SET_PEOPLE_SKILLS: 'SET_PEOPLE_SKILLS',
  SET_PEOPLE_FILTERS: 'SET_PEOPLE_FILTERS',
  RESET_PEOPLE: 'RESET_PEOPLE',
};

export const resetPeople = (payload: object = {}) => ({
  type: types.RESET_PEOPLE,
  payload,
});

export const setPeopleCount = (payload: PeopleCountPayload) => ({
  type: types.SET_PEOPLE_COUNT,
  payload,
});

export const getPeopleCount = (payload: PeopleFilters = peopleFiltersDefault) => ({
  type: types.GET_PEOPLE_COUNT,
  payload,
});

export const addHub71Employee = (payload: CreateHub71EmployeePayload) => ({
  type: types.ADD_HUB71_EMPLOYEE,
  payload,
});

export const getInvolvementTypes = (payload: object = {}) => ({
  type: types.GET_INVOLVEMENT_TYPES,
  payload,
});

export const setInvolvementTypes = (payload: DataPayload<InvolvementType[]>) => ({
  type: types.SET_INVOLVEMENT_TYPES,
  payload,
});

export const getFounders = (payload: PeopleQuery) => ({
  type: types.GET_FOUNDERS,
  payload,
});

export const setFounders = (payload: DataPayload<MemberType[]>) => ({
  type: types.SET_FOUNDERS,
  payload,
});

export const getTeamMembers = (payload: PeopleQuery) => ({
  type: types.GET_TEAM_MEMBERS,
  payload,
});

export const setTeamMembers = (payload: DataPayload<MemberType[]>) => ({
  type: types.SET_TEAM_MEMBERS,
  payload,
});

export const addMember = (payload: CreateTeamMemberPayload) => ({
  type: types.ADD_MEMBER,
  payload,
});

export const setActiveTab = (payload: ActiveTabPayload) => ({
  type: types.SET_ACTIVE_TAB,
  payload,
});

export const updateMemberById = (payload: UpdateMemberPayload) => ({
  type: types.UPDATE_MEMBER_BY_ID,
  payload,
});

export const setIsLoading = (payload: IsLoadingPayload) => ({
  type: types.SET_PEOPLE_IS_LOADING,
  payload,
});

export const getAllMembers = (payload: PeopleQuery) => ({
  type: types.GET_ALL_MEMBERS,
  payload,
});

export const setAllMembers = (payload: DataPayload<MemberType[]>) => ({
  type: types.SET_ALL_MEMBERS,
  payload,
});

export const getPeopleRoles = (payload: object = {}) => ({
  type: types.GET_PEOPLE_ROLES,
  payload,
});

export const getPeopleSkills = (payload: object = {}) => ({
  type: types.GET_PEOPLE_SKILLS,
  payload,
});

export const setPeopleRoles = (payload: DataPayload<LabeledValue[]>) => ({
  type: types.SET_PEOPLE_ROLES,
  payload,
});

export const setPeopleSkills = (payload: DataPayload<LabeledValue[]>) => ({
  type: types.SET_PEOPLE_SKILLS,
  payload,
});

export const setPeopleFilters = (payload: DataPayload<PeopleFilters>) => ({
  type: types.SET_PEOPLE_FILTERS,
  payload,
});
