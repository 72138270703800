import React from 'react';

import SVGWrap from './SVGWrap';

const ChevronDownIcon = () => (
  <SVGWrap>
    <path xmlns="http://www.w3.org/2000/svg" d="M8 11L3 6.00002L3.7 5.30002L8 9.60002L12.3 5.30002L13 6.00002L8 11Z" />
  </SVGWrap>
);

export default ChevronDownIcon;
