import { DataPayload } from 'types/reduxTypes/ActionTypes';
import { MemberType } from 'types/memberDetails';
import { PriceType } from 'types/services';
import { ServiceRequestItemDetailsType, ServiceRequestDocumentType, ServiceRequestType } from 'packages/service_request_repository';

export const types = {
  SET_SERVICE: 'SET_SERVICE',
  SET_QUANTITY: 'SET_QUANTITY',
  SET_PRICE_TYPE: 'SET_PRICE_TYPE',
  SET_ADD_EXTRA: 'SET_ADD_EXTRA',
  SET_TEAM_MEMBER: 'SET_TEAM_MEMBER',
  SET_PLAN: 'SET_PLAN',
  SET_FURNISHED: 'SET_FURNISHED',
  SET_ACCOMMODATION_TYPE: 'SET_ACCOMMODATION_TYPE',
  SET_OFFICE_TYPE: 'SET_OFFICE_TYPE',
  SET_SERVICE_REQUEST: 'SET_SERVICE_REQUEST',
  SET_TOTAL_PRICE: 'SET_TOTAL_PRICE',
  SET_DETAILS: 'SET_DETAILS',
  SET_IS_USER_UAE_RES: 'SET_IS_USER_UAE_RES',
  SET_RELATION_TO_TM: 'SET_RELATION_TO_TM',
  SET_IS_DEPENDANT_UAE: 'SET_IS_DEPENDANT_UAE',
  SET_DEPENDANT_GENDER: 'SET_DEPENDANT_GENDER',
  SET_DEPENDANT_FULL_NAME: 'SET_DEPENDANT_FULL_NAME',
  SET_DEPENDANT_DATE_OF_BIRTH: 'SET_DEPENDANT_DATE_OF_BIRTH',
  SET_CHILD_PLACE_OF_BIRTH: 'SET_CHILD_PLACE_OF_BIRTH',
  SET_IS_APPLYING_FOR_DEPENDANT: 'SET_IS_APPLYING_FOR_DEPENDANT',
  SET_PASSPORT_FILE: 'SET_PASSPORT_FILE',
  SET_VISA_FILE: 'SET_VISA_FILE',
  SET_VISA_PROOF_FILE: 'SET_VISA_PROOF_FILE',
  SET_EMIRATES_ID_FILE: 'SET_EMIRATES_ID_FILE',
  SET_REQUEST_DOCUMENTS: 'SET_REQUEST_DOCUMENTS',
  SET_DISPLAY_DOCUMENTS: 'SET_DISPLAY_DOCUMENTS',
  SET_ETA_WITH_SIGN_FILE: 'SET_ETA_WITH_SIGN_FILE',
  SET_EMIRATES_ID_NO: 'SET_EMIRATES_ID_NO',
  SET_PASSPORT_NO: 'SET_PASSPORT_NO',
}

export const setService = (payload: DataPayload<ServiceRequestType>) => ({
  type: types.SET_SERVICE,
  payload,
});

export const setQuantity = (payload: DataPayload<number>) => ({
  type: types.SET_QUANTITY,
  payload,
});

export const setPriceType = (payload: DataPayload<PriceType>) => ({
  type: types.SET_PRICE_TYPE,
  payload,
});

export const setTeamMember = (payload: DataPayload<MemberType | undefined>) => ({
  type: types.SET_TEAM_MEMBER,
  payload,
});

export const setPlan = (payload: DataPayload<string>) => ({
  type: types.SET_PLAN,
  payload,
});

export const setFurnished = (payload: DataPayload<boolean>) => ({
  type: types.SET_FURNISHED,
  payload,
});

export const setAccommodationType = (payload: DataPayload<string>) => ({
  type: types.SET_ACCOMMODATION_TYPE,
  payload,
});

export const setOfficeType = (payload: DataPayload<string>) => ({
  type: types.SET_OFFICE_TYPE,
  payload,
});

export const setTotalPrice = (payload: DataPayload<number>) => ({
  type: types.SET_TOTAL_PRICE,
  payload,
});

export const setDetails = (payload: DataPayload<ServiceRequestItemDetailsType>) => ({
  type: types.SET_DETAILS,
  payload,
});

export const setIsUserUAERes = (payload: DataPayload<string>) => ({
  type: types.SET_IS_USER_UAE_RES,
  payload,
});

export const setRelationToTM = (payload: DataPayload<string>) => ({
  type: types.SET_RELATION_TO_TM,
  payload,
});

export const setIsDependantUAE = (payload: DataPayload<string>) => ({
  type: types.SET_IS_DEPENDANT_UAE,
  payload,
});

export const setDependantGender = (payload: DataPayload<string>) => ({
  type: types.SET_DEPENDANT_GENDER,
  payload,
});

export const setDependantFullName = (payload: DataPayload<string>) => ({
  type: types.SET_DEPENDANT_FULL_NAME,
  payload,
});

export const setDependantDateOfBirth = (payload: DataPayload<string>) => ({
  type: types.SET_DEPENDANT_DATE_OF_BIRTH,
  payload,
});

export const setDependantChildPlaceOfBirth = (payload: DataPayload<string>) => ({
  type: types.SET_CHILD_PLACE_OF_BIRTH,
  payload,
});

export const setIsApplyingForDependant = (payload: DataPayload<boolean>) => ({
  type: types.SET_IS_APPLYING_FOR_DEPENDANT,
  payload,
});

export const setPassportFile = (payload: DataPayload<string | Blob>) => ({
  type: types.SET_PASSPORT_FILE,
  payload,
});

export const setPassportNo = (payload: DataPayload<string>) => ({
  type: types.SET_PASSPORT_NO,
  payload,
});

export const setEmiratesIDNo = (payload: DataPayload<string | Blob>) => ({
  type: types.SET_EMIRATES_ID_NO,
  payload,
});

export const setVisaFile = (payload: DataPayload<string | Blob>) => ({
  type: types.SET_VISA_FILE,
  payload,
});

export const setVisaProofFile = (payload: DataPayload<string | Blob>) => ({
  type: types.SET_VISA_PROOF_FILE,
  payload,
});

export const setEmiratesIDFile = (payload: DataPayload<string | Blob>) => ({
  type: types.SET_EMIRATES_ID_FILE,
  payload,
});

export const setRequestDocuments = (payload: DataPayload<ServiceRequestDocumentType[]>) => ({
  type: types.SET_REQUEST_DOCUMENTS,
  payload,
});

export const setDisplayDocuments = (payload: DataPayload<boolean>) => ({
  type: types.SET_DISPLAY_DOCUMENTS,
  payload,
});

export const setETAWithSignFile = (payload: DataPayload<string | Blob>) => ({
  type: types.SET_ETA_WITH_SIGN_FILE,
  payload,
});
