import React, { useEffect, useState } from 'react'
import { Button, Spin } from 'antd';

import { REPORT_STATUS } from 'packages/performance_repository';
import { ButtonWithPrompt, RenderIf } from 'components/components';
import { PerformanceDrawersProps } from 'types/performance'
import PerformanceDetails from '../components/PerformanceDetails'

import './PerformanceReviewDrawer.scss';

const PerformanceReviewDrawer = (props: PerformanceDrawersProps) => {
  const {
    id,
    performanceReport,
    isLoading,
    actions,
  } = props;

  const [displayApproveBtn, setDisplayApproveBtn] = useState(true);

  useEffect(() => {
    actions.getPerformanceReportById({ id })
  }, [id])

  const onReturn = (reason: string) => {
    const data = {
      status: REPORT_STATUS.rejected,
      rejectionReason: reason,
    }
    actions.updatePerformanceReportById({ data })
  }

  const onApprove = () => {
    const data = {
      status: REPORT_STATUS.approved,
    }
    actions.updatePerformanceReportById({ data })
  }

  return (
    <Spin spinning={isLoading}>
      <div className="performance-review-drawer">
        <PerformanceDetails performanceReport={performanceReport} />
        <div className="submit__wrap">
          <ButtonWithPrompt
            promptActiveClassName="danger-button"
            className="secondary-button"
            promptLabel="Reason to return"
            promptPlaceholder="Write a reason..."
            onFinish={onReturn}
            promptActiveBtnText="Return to Startup"
            onPromptDisplay={() => setDisplayApproveBtn(false)}
            onCancel={() => setDisplayApproveBtn(true)}
          >
            Return to Startup
          </ButtonWithPrompt>
          <RenderIf condition={displayApproveBtn}>
            <Button type="primary" htmlType="submit" className="primary-button" onClick={onApprove}>
              Approve
            </Button>
          </RenderIf>
        </div>
      </div>
    </Spin>
  )
}

export default PerformanceReviewDrawer;
