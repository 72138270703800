import { AddNewCohortFieldType } from '../../../../types/reduxTypes/ActionTypes/CohortTypes';
import { DataPayload } from '../../../../types/reduxTypes/ActionTypes';

export const types = {
  SET_FIELD: 'SET_FIELD',
  SET_CSV_FILE: 'SET_CSV_FILE',
  SET_LOGO: 'SET_LOGO',
}

export type SetFieldType = {
   field: AddNewCohortFieldType,
   value: string,
}

export const setField = (payload: SetFieldType) => ({
  type: types.SET_FIELD,
  payload,
});

export const setCsvFile = (payload: DataPayload<string | Blob>) => ({
  type: types.SET_CSV_FILE,
  payload,
});

export const setLogo = (payload: DataPayload<string | Blob>) => ({
  type: types.SET_LOGO,
  payload,
});
