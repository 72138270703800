import React, { useEffect, useMemo } from 'react';
import { Button } from 'antd';
import { LabeledValue } from 'antd/lib/select';

import { ServiceCategoryType } from 'packages/service_repository';
import { CloseIcon } from 'assets/icons';
import { ServiceProviderType } from 'types/serviceProviders';
import { identity, offerPriceTypeValues } from 'utils';
import { Input } from 'components/components';
import { ServicesDropdownFiltersProps } from 'types/services'
import { useServicesFiltersHook } from '../../utils';

const ServicesDropdownFilters = (props: ServicesDropdownFiltersProps) => {
  const { setShowDropdown, serviceProviders, categories } = props;

  const { getWorkspaceServiceProviders, getServiceCategories, setFilters } = props.actions;

  useEffect(() => {
    if (identity.isEmptyArray(serviceProviders)) {
      const query = { isPreload: true };
      getWorkspaceServiceProviders({ query });
    }
    if (identity.isEmptyArray(categories)) {
      getServiceCategories();
    }
  }, []);

  const {
    state,
    initialState,
    setServiceProviders,
    setCategories,
    setOfferPrice,
    resetFilters,
    setFilters: setAllFilters,
  } = useServicesFiltersHook();

  const { serviceProviderId, categoryId, priceTypeId } = state;

  useEffect(() => setAllFilters(props.filters), [props.filters]);

  const onClose = () => {
    setAllFilters(props.filters);
    setShowDropdown(false);
  }

  const onClearAll = () => {
    resetFilters();
    setFilters({ data: initialState });
    onClose();
  }

  const onFilter = () => {
    setFilters(state);
    onClose();
  };

  const serviceProvidersOptions = useMemo(() => serviceProviders.map((serviceProvider: ServiceProviderType) => ({
    value: serviceProvider.id,
    label: serviceProvider.name,
  })), [serviceProviders]);

  const categoriesOptions: LabeledValue[] = useMemo(() => (
    categories?.map(({ name: label, id: value }: ServiceCategoryType) => ({ label, value }))
  ), [categories]);

  return (
    <div className="filters-dropdown">
      <div className="filters-dropdown__title-container">
        <h3 className="filters-dropdown__title">Filters</h3>
        <Button className="filters-buttons--button" onClick={onClose}><CloseIcon /></Button>
      </div>
      <Input label="Service provider" layout="vertical">
        <Input.Select
          showSearch
          options={serviceProvidersOptions}
          value={serviceProviderId}
          mode="multiple"
          placeholder="Select service providers"
          maxTagCount="responsive"
          onChange={(data) => setServiceProviders({ data })}
        />
      </Input>
      <div className="filters-dropdown--divider" />
      <Input label="Service category" layout="vertical">
        <Input.Select
          showSearch
          mode="multiple"
          placeholder="Select categories"
          maxTagCount="responsive"
          value={categoryId}
          onChange={(data) => setCategories({ data })}
          options={categoriesOptions}
        />
      </Input>
      <div className="filters-dropdown--divider" />
      <Input label="Offer price" layout="vertical">
        <Input.Radio
          values={offerPriceTypeValues}
          value={priceTypeId ? priceTypeId[0] : undefined}
          onChange={({ target: { value: data } }) => setOfferPrice({ data })}
        />
      </Input>
      <div className="filters-buttons">
        <Button className="secondary-button" onClick={onClearAll}>Clear all</Button>
        <Button className="primary-button" onClick={onFilter}>Apply</Button>
      </div>
    </div>
  );
}

export default ServicesDropdownFilters;
