import React from 'react';

import SVGWrap from './SVGWrap';

const DashboardIcon = () => (
  <SVGWrap>
    <path xmlns="http://www.w3.org/2000/svg" d="M13 10.5H12V13H13V10.5Z" />
    <path xmlns="http://www.w3.org/2000/svg" d="M11 8H10V13H11V8Z" />
    <path xmlns="http://www.w3.org/2000/svg" d="M5.5 13C4.8372 12.9992 4.20177 12.7356 3.7331 12.2669C3.26442 11.7982 3.00078 11.1628 3 10.5H4C4 10.7967 4.08797 11.0867 4.2528 11.3334C4.41762 11.58 4.65189 11.7723 4.92597 11.8858C5.20006 11.9994 5.50166 12.0291 5.79264 11.9712C6.08361 11.9133 6.35088 11.7704 6.56066 11.5607C6.77044 11.3509 6.9133 11.0836 6.97118 10.7926C7.02906 10.5017 6.99935 10.2001 6.88582 9.92597C6.77229 9.65189 6.58003 9.41762 6.33336 9.2528C6.08668 9.08797 5.79667 9 5.5 9V8C6.16304 8 6.79893 8.26339 7.26777 8.73223C7.73661 9.20107 8 9.83696 8 10.5C8 11.163 7.73661 11.7989 7.26777 12.2678C6.79893 12.7366 6.16304 13 5.5 13Z" />
    <path xmlns="http://www.w3.org/2000/svg" d="M14 1H2C1.73486 1.00026 1.48066 1.10571 1.29319 1.29319C1.10571 1.48066 1.00026 1.73486 1 2V14C1.0003 14.2651 1.10576 14.5193 1.29323 14.7068C1.4807 14.8942 1.73488 14.9997 2 15H14C14.2651 14.9996 14.5193 14.8942 14.7067 14.7067C14.8942 14.5193 14.9996 14.2651 15 14V2C14.9997 1.73488 14.8942 1.4807 14.7068 1.29323C14.5193 1.10576 14.2651 1.0003 14 1ZM14 5.5H7V2H14V5.5ZM6 2V5.5H2V2H6ZM2 14V6.5H14.0003L14.001 14H2Z" />
  </SVGWrap>
);

export default DashboardIcon;
