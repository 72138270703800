import React from 'react';

import { EventType } from 'packages/events_repository';
import { identity } from 'utils';
import routes from 'routes';
import { TitleWithActions, Event } from 'components/components';
import { UpcomingEventsProps } from 'types/componentTypes/Dashboard';

import './UpcomingEvents.scss';

const UpcomingEvents = (props: UpcomingEventsProps) => {
  const { events } = props;

  return (
    <div className="upcoming-events">
      <TitleWithActions
        title="Upcoming events"
        size="xs"
        showButton
        buttonTitle="View all"
        link={routes.eventsUrl}
      />
      <div className="upcoming-events__container">
        {identity.isFullArray(events)
          ? events.map((event: EventType) => <Event rightContent="chevron" key={event.id} event={event} id={event.id} />)
          : 'No upcoming events'}
      </div>
    </div>
  );
}

export default UpcomingEvents;
