import React, { useEffect, useMemo } from 'react';
import { Form } from 'antd';
import moment from 'moment';
import { RcFile } from 'antd/lib/upload';

import DrawerButtons from 'components/components/Drawer/components/DrawerButton';
import { scrollToFirstError } from 'components/components/FormBuilder/utils/helpers';
import { DataPayload } from 'types/reduxTypes/ActionTypes';
import { Input } from 'components/components';
import Title from 'components/components/TitleWithActions/components/Title';
import { AddNewCohortProps, ProgramType } from 'types/cohorts/addNewCohort';
import { EmailTemplateType } from 'types/settingsOverview';
import { ChevronDownIcon, InformationIcon } from 'assets/icons';
import { identity, positiveNumbersOnly } from 'utils';
import useAddNewCohortHook from './utils/addNewCohortHook';
import { formatDateDashYMDTimeHMS, formatSlashMDY } from '../utils/constants/formatters';
import requiredValidation from '../../utils/constants/validationRules';

import './AddNewCohorts.scss';

const AddNewCohort = (props: AddNewCohortProps) => {
  const { emailTemplates, programs } = props;
  const { createCohort, getEmailTemplates, getPrograms } = props.actions;

  const {
    state, setField, setLogo, setCsvFile,
  } = useAddNewCohortHook();

  const emailTemplateOptions = useMemo(() => (
    emailTemplates.map((template: EmailTemplateType) => ({ value: template.id, label: template.title }))
  ), [emailTemplates]);
  const programOptions = useMemo(() => (
    programs?.map((program: ProgramType) => ({ value: program.id, label: program.name }))
  ), [programs]);

  const validateStartupFile = async () => {
    if (identity.isEmptyString(state.csvFile)) {
      throw new Error();
    }
  };

  useEffect(() => {
    if (identity.isEmptyArray(emailTemplates)) {
      getEmailTemplates();
    }

    if (identity.isEmptyArray(programs)) {
      getPrograms();
    }
  }, []);

  const handleSubmit = () => {
    const formValues = {
      name: state.cohort.name,
      from: state.cohort.from,
      to: state.cohort.to,
      amount: state.cohort.amount,
      type: state.cohort.type,
      acceptanceLetterTemplate: state.cohort.acceptanceLetterTemplate,
      csvFile: state.csvFile,
      logo: state.logo,
      selectionFrom: state.cohort.selectionFrom,
      selectionTo: state.cohort.selectionTo,
    };

    createCohort({ data: formValues });
  }

  const handleBeforeUpload = (file: RcFile, functionCall: (_payload: DataPayload<string | Blob>) => void) => {
    functionCall({ data: file as Blob });
    return false;
  }

  return (
    <div className="add-cohort">
      <Form onFinish={handleSubmit} onFinishFailed={scrollToFirstError}>
        <Input name="name" label="Name" layout="vertical" required rules={[requiredValidation]}>
          <Input.InputField
            name="name"
            type="text"
            onChange={(e) => setField({ field: 'name', value: e.target.value })}
          />
        </Input>
        <Input name="type" label="Program" layout="vertical" required rules={[requiredValidation]}>
          <Input.Select
            options={programOptions}
            onChange={(value) => setField({ field: 'type', value })}
          />
        </Input>
        <Title title="Cohort Selection Period" />
        <div className="form-row">
          <Input name="selectionFrom" label="From" layout="vertical">
            <Input.DatePicker
              name="selectionFrom"
              suffixIcon={<ChevronDownIcon />}
              format={formatSlashMDY}
              onChange={(e) => setField({ field: 'selectionFrom', value: moment(e).utc().format(formatDateDashYMDTimeHMS) })}
            />
          </Input>
          <Input name="selectionTo" label="To" layout="vertical">
            <Input.DatePicker
              name="selectionTo"
              suffixIcon={<ChevronDownIcon />}
              format={formatSlashMDY}
              onChange={(e) => setField({ field: 'selectionTo', value: moment(e).utc().format(formatDateDashYMDTimeHMS) })}
            />
          </Input>
        </div>
        <Title title="Cohort Period" />
        <div className="form-row">
          <Input name="from" label="From" layout="vertical">
            <Input.DatePicker
              name="from"
              suffixIcon={<ChevronDownIcon />}
              format={formatSlashMDY}
              onChange={(e) => setField({ field: 'from', value: moment(e).utc().format(formatDateDashYMDTimeHMS) })}
            />
          </Input>
          <Input name="to" label="To" layout="vertical">
            <Input.DatePicker
              name="to"
              suffixIcon={<ChevronDownIcon />}
              format={formatSlashMDY}
              onChange={(e) => setField({ field: 'to', value: moment(e).utc().format(formatDateDashYMDTimeHMS) })}
            />
          </Input>
        </div>
        <Title title="Amount" />
        <Input name="amount" label="Enter amount" layout="vertical" rules={[positiveNumbersOnly]}>
          <Input.InputNumber
            min={0}
            name="amount"
            addonBefore="AED"
            value={parseInt(state.cohort.amount, 10)}
            onChange={(e) => setField({ field: 'amount', value: e!.toString() })}
          />
        </Input>
        <Title title="Acceptance Letter Template" />
        <Input
          name="acTemplate"
          label="Choose email template"
          layout="vertical"
          required
          rules={[requiredValidation]}
        >
          <Input.Select
            options={emailTemplateOptions}
            onChange={(value) => setField({ field: 'acceptanceLetterTemplate', value })}
          />
        </Input>
        <Title title="Startups" />
        <Input
          label="Startup details"
          layout="vertical"
          tooltip={{ title: 'Info', icon: <InformationIcon /> }}
          name="csvFile"
          required
          rules={[{ message: 'Startup File cannot be missing', validator: validateStartupFile }]}
        >
          <Input.DraggerUpload
            accept=".csv"
            name="csvFile"
            beforeUpload={(file) => handleBeforeUpload(file, setCsvFile)}
            isRoundableButton
          />
        </Input>
        <Input name="logo" label="Program logo" layout="vertical">
          <Input.UploadImage
            beforeUpload={(file) => handleBeforeUpload(file, setLogo)}
          />
        </Input>
        <DrawerButtons>Add cohort</DrawerButtons>
      </Form>
    </div>
  );
};

export default AddNewCohort;
