import React, { useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from 'antd';

import routes from 'routes';
import { NotFoundProps } from 'types/AppType';

const NotFound = (props: NotFoundProps) => {
  const { isAuthenticated } = props;
  const history = useHistory();

  const onClick = useCallback(() => (
    isAuthenticated ? history.push(routes.dashboardUrl) : history.push(routes.loginUrl)
  ), [isAuthenticated]);

  const buttonText = useMemo(() => (isAuthenticated ? 'Go to Dashboard' : 'Go to Login'), [isAuthenticated]);

  return (
    <div className="not-found">
      <span>This page does not exist.</span>
      <Button className="secondary-button" onClick={onClick}>{buttonText}</Button>
    </div>
  );
}

export default NotFound;
