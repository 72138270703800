import {
  all, call, put, select, takeLatest,
} from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';

import { DataPayload, IdPayload } from 'types/reduxTypes/ActionTypes';
import { queryBuilder } from 'packages/http_client/utils/queryBuilder';
import {
  createProgramType,
  CreateProgramType,
  deleteProgramTypeById,
  deleteProgramTypes,
  getProgramsList,
  ProgramType,
  ProgramTypeParams,
  updateProgramTypeById,
} from 'packages/cohort_repository';
import {
  selectActiveSubTab, selectDataEntryPointDetail, selectDeleteIds, selectTemplateDetail,
} from 'redux/selectors/settingsOverview';
import {
  createTemplate,
  deleteTemplateById,
  deleteTemplates,
  getAcceptanceLetterTemplates,
  getIncentiveAgreementTemplates,
  getTemplateById,
  getWorkOrderTemplates,
  TemplateParams,
  TemplateTypeEnum,
  updateTemplateById,
} from 'packages/template_repository';
import { setDestroyDrawerOnClose, setIsDrawerLoading, setIsDrawerOpen } from 'redux/actions/workspace';
import { TEMPLATE_TABS, DATA_ENTRY_POINTS_TABS } from 'components/Settings/utils';
import {
  AddTemplatePayload,
  DeleteEmailTemplatePayload,
  AddDataEntryPayload,
  EditTemplateDataType,
} from 'types/reduxTypes/ActionTypes/SettingsOverviewTypes';
import { RequestDetailsTabs } from 'types/requests';
import { identity } from 'utils';
import {
  deleteEducation,
  getEducation,
  getTitles,
  deleteTitles,
  createStartupStage,
  createTitle,
  createEducation,
  DataEntryPointType,
  updateEducationById,
  updateTitleById,
  updateStartupStageById,
} from 'packages/settings_repository';
import { GetParamsType } from 'packages/http_client';
import { getStagesList, deleteStartupStages } from 'packages/startup_repository';
import showNotification from '../../../services/utils/showNotification';
import {
  getProgramTypes,
  setDeleteIds,
  setEmailTemplates,
  setProgramTypes,
  setIncentiveTemplates,
  types,
  setIsLoading,
  setWorkOrderTemplates,
  setStartupStages,
  setEducation,
  getEducation as getEducationAC,
  getStartupStages,
  getTitles as getTitlesAC,
  setTitles,
  getEmailTemplates,
  getIncentiveTemplates,
  getWorkOrderTemplates as getWorkOrderTemplatesAC,
  setActiveSubTab,
  setTemplateDetail,
} from '../../actions/settingsOverview';

function * handleGetEmailTemplates() {
  yield put(setIsLoading({ isLoading: true }));
  const { data: emailTemplates, error, httpStatus } = yield call(getAcceptanceLetterTemplates);
  if (identity.isObjWithChildren(error)) {
    showNotification(`Error fetching email templates: ${error.message}`, true, httpStatus);
  } else {
    yield put(setEmailTemplates({ emailTemplates }));
  }
  yield put(setIsLoading({ isLoading: false }));
}

function * handleGetIncentiveAgreementTemplates() {
  yield put(setIsLoading({ isLoading: true }));
  const { data: emailTemplates, error, httpStatus } = yield call(getIncentiveAgreementTemplates);
  if (identity.isObjWithChildren(error)) {
    showNotification(`Error fetching Incentive Agreement templates: ${error.message}`, true, httpStatus);
  } else {
    yield put(setIncentiveTemplates({ emailTemplates }));
  }
  yield put(setIsLoading({ isLoading: false }));
}

function * handleGetProgramTypes() {
  yield put(setIsLoading({ isLoading: true }));

  const { error, httpStatus, data } = yield call(getProgramsList);

  if (identity.isObjWithChildren(error)) {
    showNotification(error?.message, true, httpStatus);
  } else {
    yield put(setProgramTypes({ data }));
  }

  yield put(setIsLoading({ isLoading: false }));
}

function * handleDeleteProgramTypes() {
  yield put(setIsLoading({ isLoading: true }));
  const deleteIds: number[] = yield select(selectDeleteIds);

  const params: ProgramTypeParams = queryBuilder()
    .in({ id: deleteIds })
    .toObject();

  const { error, httpStatus } = yield call(deleteProgramTypes, params);

  if (identity.isObjWithChildren(error)) {
    showNotification(error?.message, true, httpStatus);
  } else {
    yield put(setDeleteIds({ data: [] }));
    yield put(getProgramTypes());
  }
  yield put(setIsLoading({ isLoading: false }));
}

function * handleAddEmailTemplate(action: PayloadAction<AddTemplatePayload>) {
  yield put(setIsDrawerLoading({ isLoading: true }));

  const { template } = action.payload;
  const { error } = yield call(createTemplate, template);

  if (error) {
    showNotification('error');
  } else {
    showNotification('Template Added successfully');
    switch (template.type) {
      case TemplateTypeEnum['Acceptance Letter']: {
        yield put(getEmailTemplates());
        yield put(setActiveSubTab({ tab: TEMPLATE_TABS.acceptanceLetter as RequestDetailsTabs }));
        break;
      }
      case TemplateTypeEnum['Incentive Agreement']: {
        yield put(getIncentiveTemplates());
        yield put(setActiveSubTab({ tab: TEMPLATE_TABS.incentiveAgreement as RequestDetailsTabs }));
        break;
      }
      case TemplateTypeEnum.Other: {
        yield put(getWorkOrderTemplatesAC());
        yield put(setActiveSubTab({ tab: TEMPLATE_TABS.workOrder as RequestDetailsTabs }));
        break;
      }
      default: {
        break;
      }
    }
    yield put(setDestroyDrawerOnClose({ destroyDrawerOnClose: true }));
    yield put(setIsDrawerOpen({ isDrawerOpen: false }));
  }
  yield put(setIsDrawerLoading({ isLoading: false }));
}

function * handleDeleteMultipleTemplates() {
  yield put(setIsLoading({ isLoading: true }));
  const deleteIds: number[] = yield select(selectDeleteIds);
  const subTab: string = yield select(selectActiveSubTab);
  const params: TemplateParams = queryBuilder()
    .in({ id: deleteIds })
    .toObject();

  const { error, httpStatus } = yield call(deleteTemplates, params);

  if (identity.isObjWithChildren(error)) {
    showNotification(error?.message, true, httpStatus);
  } else {
    switch (subTab) {
      case TEMPLATE_TABS.acceptanceLetter: {
        yield put(getEmailTemplates());
        break;
      }
      case TEMPLATE_TABS.incentiveAgreement: {
        yield put(getIncentiveTemplates());
        break;
      }
      case TEMPLATE_TABS.workOrder: {
        yield put(getWorkOrderTemplatesAC());
        break;
      }
      default: {
        break;
      }
    }
    showNotification('Templates Deleted SuccessFully');
  }
  yield put(setIsLoading({ isLoading: false }));
}

function * handleDeleteEmailTemplate(action: PayloadAction<DeleteEmailTemplatePayload>) {
  yield put(setIsLoading({ isLoading: true }));
  const subTab: string = yield select(selectActiveSubTab);
  const { id } = action.payload;
  const { error, httpStatus } = yield call(deleteTemplateById, id);
  if (identity.isObjWithChildren(error)) {
    showNotification(error?.message, true, httpStatus);
  } else {
    showNotification('Template Deleted successfully');
    switch (subTab) {
      case TEMPLATE_TABS.acceptanceLetter: {
        yield put(getEmailTemplates());
        break;
      }
      case TEMPLATE_TABS.incentiveAgreement: {
        yield put(getIncentiveTemplates());
        break;
      }
      case TEMPLATE_TABS.workOrder: {
        yield put(getWorkOrderTemplatesAC());
        break;
      }
      default: {
        break;
      }
    }
  }
  yield put(setIsLoading({ isLoading: false }));
}

function * handleDeleteEducation(action: PayloadAction<DataPayload<number>>) {
  yield put(setIsLoading({ isLoading: true }));
  const deleteIds: number[] = yield select(selectDeleteIds);
  const deleteOne = identity.isObjWithChildren(action.payload);

  const params: GetParamsType = queryBuilder()
    .in({ id: deleteOne ? [action.payload.data] : deleteIds })
    .toObject();

  const { error, httpStatus } = yield call(deleteEducation, params);

  if (identity.isObjWithChildren(error)) {
    showNotification(error?.message, true, httpStatus);
  } else {
    yield put(setDeleteIds({ data: [] }));
    yield put(getEducationAC());
  }

  yield put(setIsLoading({ isLoading: false }));
}

function * handleGetStartupStages() {
  yield put(setIsLoading({ isLoading: true }));
  const { data, error, httpStatus } = yield call(getStagesList);
  if (identity.isObjWithChildren(error)) {
    showNotification(`Something went wrong: ${error.message}`, true, httpStatus);
  } else {
    yield put(setStartupStages({ data }));
  }
  yield put(setIsLoading({ isLoading: false }));
}

function * handleGetEducation() {
  yield put(setIsLoading({ isLoading: true }));
  const { data, error, httpStatus } = yield call(getEducation);
  if (identity.isObjWithChildren(error)) {
    showNotification(`Something went wrong: ${error.message}`, true, httpStatus);
  } else {
    yield put(setEducation({ data }));
  }
  yield put(setIsLoading({ isLoading: false }));
}

function * handleDeleteStartupStages(action: PayloadAction<DataPayload<number>>) {
  yield put(setIsLoading({ isLoading: true }));
  const deleteIds: number[] = yield select(selectDeleteIds);
  const deleteOne = identity.isObjWithChildren(action.payload);

  const params: GetParamsType = queryBuilder()
    .in({ id: identity.isTruthyNumber(deleteOne) ? [action.payload.data] : deleteIds })
    .toObject();

  const { error, httpStatus } = yield call(deleteStartupStages, params);

  if (identity.isObjWithChildren(error)) {
    showNotification(error?.message, true, httpStatus);
  } else {
    yield put(setDeleteIds({ data: [] }));
    yield put(getStartupStages());
  }

  yield put(setIsLoading({ isLoading: false }));
}

function * handleDeleteTitles(action: PayloadAction<DataPayload<number>>) {
  yield put(setIsLoading({ isLoading: true }));
  const deleteIds: number[] = yield select(selectDeleteIds);
  const deleteOne = identity.isObjWithChildren(action.payload);

  const params: GetParamsType = queryBuilder()
    .in({ id: deleteOne ? [action.payload.data] : deleteIds })
    .toObject();

  const { error, httpStatus } = yield call(deleteTitles, params);

  if (identity.isObjWithChildren(error)) {
    showNotification(error?.message, true, httpStatus);
  } else {
    yield put(setDeleteIds({ data: [] }));
    yield put(getTitlesAC());
  }

  yield put(setIsLoading({ isLoading: false }));
}

function * handleGetTitles() {
  const { error, httpStatus, data } = yield call(getTitles);
  yield put(setIsLoading({ isLoading: true }));
  if (identity.isObjWithChildren(error)) {
    showNotification(`Something went wrong: ${error.message}`, true, httpStatus);
  } else {
    yield put(setTitles({ data }));
  }
  yield put(setIsLoading({ isLoading: false }));
}

function * handleDeleteProgramTypeById(action: PayloadAction<IdPayload>) {
  yield put(setIsLoading({ isLoading: true }));

  const { id } = action.payload;

  const { error, httpStatus } = yield call(deleteProgramTypeById, id);

  if (identity.isObjWithChildren(error)) {
    showNotification(error?.message, true, httpStatus);
  } else {
    yield put(getProgramTypes());
  }

  yield put(setIsLoading({ isLoading: false }));
}

function * handleCreateProgramType(action: PayloadAction<DataPayload<CreateProgramType>>) {
  yield put(setIsDrawerLoading({ isLoading: true }));

  const { data } = action.payload;

  const { error, httpStatus } = yield call(createProgramType, data);

  if (identity.isObjWithChildren(error)) {
    showNotification(error?.message, true, httpStatus);
  } else {
    yield put(setDestroyDrawerOnClose({ destroyDrawerOnClose: true }));
    yield put(setIsDrawerOpen({ isDrawerOpen: false }));
    yield put(setDeleteIds({ data: [] }));
    yield put(getProgramTypes());
  }

  yield put(setIsDrawerLoading({ isLoading: false }));
}

function * handleUpdateProgramTypeById(action: PayloadAction<DataPayload<ProgramType>>) {
  yield put(setIsDrawerLoading({ isLoading: true }));

  const { id } = action.payload.data;

  const { error, httpStatus } = yield call(updateProgramTypeById, id, action.payload.data);

  if (identity.isObjWithChildren(error)) {
    showNotification(error?.message, true, httpStatus);
  } else {
    yield put(setDestroyDrawerOnClose({ destroyDrawerOnClose: true }));
    yield put(setIsDrawerOpen({ isDrawerOpen: false }));
    yield put(setDeleteIds({ data: [] }));
    yield put(getProgramTypes());
  }

  yield put(setIsDrawerLoading({ isLoading: false }));
}

function * handleGetWorkOrderTemplates() {
  yield put(setIsLoading({ isLoading: true }));
  const { data: emailTemplates, error, httpStatus } = yield call(getWorkOrderTemplates);
  if (identity.isObjWithChildren(error)) {
    showNotification(`Error fetching Work Order templates: ${error.message}`, true, httpStatus);
  } else {
    yield put(setWorkOrderTemplates({ emailTemplates }));
  }
  yield put(setIsLoading({ isLoading: false }));
}

function * handleAddEducation(action: PayloadAction<DataPayload<AddDataEntryPayload>>) {
  yield put(setIsDrawerLoading({ isLoading: true }));
  const { data } = action.payload;
  const { error, httpStatus } = yield call(createEducation, data);

  if (identity.isObjWithChildren(error)) {
    showNotification(error?.message, true, httpStatus);
  } else {
    yield put(setDestroyDrawerOnClose({ destroyDrawerOnClose: true }));
    yield put(setIsDrawerOpen({ isDrawerOpen: false }));
    yield put(getEducationAC())
    showNotification('Education added successfully');
    yield put(setActiveSubTab({ tab: DATA_ENTRY_POINTS_TABS.education as RequestDetailsTabs }));
  }

  yield put(setIsDrawerLoading({ isLoading: false }));
}

function * handleAddTitle(action: PayloadAction<DataPayload<AddDataEntryPayload>>) {
  yield put(setIsDrawerLoading({ isLoading: true }));
  const { data } = action.payload;
  const { error, httpStatus } = yield call(createTitle, data);

  if (identity.isObjWithChildren(error)) {
    showNotification(error?.message, true, httpStatus);
  } else {
    yield put(setDestroyDrawerOnClose({ destroyDrawerOnClose: true }));
    yield put(setIsDrawerOpen({ isDrawerOpen: false }));
    yield put(getTitlesAC());
    showNotification('Title added successfully');
    yield put(setActiveSubTab({ tab: DATA_ENTRY_POINTS_TABS.title as RequestDetailsTabs }));
  }

  yield put(setIsDrawerLoading({ isLoading: false }));
}

function * handleAddStartupStage(action: PayloadAction<DataPayload<AddDataEntryPayload>>) {
  yield put(setIsDrawerLoading({ isLoading: true }));
  const { data } = action.payload;
  const { error, httpStatus } = yield call(createStartupStage, data);

  if (identity.isObjWithChildren(error)) {
    showNotification(error?.message, true, httpStatus);
  } else {
    yield put(setDestroyDrawerOnClose({ destroyDrawerOnClose: true }));
    yield put(setIsDrawerOpen({ isDrawerOpen: false }));
    yield put(getStartupStages())
    showNotification('Startup Stage added successfully');
    yield put(setActiveSubTab({ tab: DATA_ENTRY_POINTS_TABS.startupStage as RequestDetailsTabs }));
  }

  yield put(setIsDrawerLoading({ isLoading: false }));
}

function * handleUpdateEducation(action: PayloadAction<DataPayload<DataEntryPointType>>) {
  yield put(setIsDrawerLoading({ isLoading: true }));

  const { id } = yield select(selectDataEntryPointDetail);
  const { error, httpStatus } = yield call(updateEducationById, id, action.payload.data);

  if (identity.isObjWithChildren(error)) {
    showNotification(error?.message, true, httpStatus);
  } else {
    showNotification('Education updated');
    yield put(setDestroyDrawerOnClose({ destroyDrawerOnClose: true }));
    yield put(setIsDrawerOpen({ isDrawerOpen: false }));
    yield put(getEducationAC());
  }

  yield put(setIsDrawerLoading({ isLoading: false }));
}

function * handleUpdateTitle(action: PayloadAction<DataPayload<DataEntryPointType>>) {
  yield put(setIsDrawerLoading({ isLoading: true }));

  const { id } = yield select(selectDataEntryPointDetail);
  const { error, httpStatus } = yield call(updateTitleById, id, action.payload.data);

  if (identity.isObjWithChildren(error)) {
    showNotification(error?.message, true, httpStatus);
  } else {
    showNotification('Title updated');
    yield put(setDestroyDrawerOnClose({ destroyDrawerOnClose: true }));
    yield put(setIsDrawerOpen({ isDrawerOpen: false }));
    yield put(getTitlesAC());
  }

  yield put(setIsDrawerLoading({ isLoading: false }));
}

function * handleUpdateStartupStage(action: PayloadAction<DataPayload<DataEntryPointType>>) {
  yield put(setIsDrawerLoading({ isLoading: true }));

  const { id } = yield select(selectDataEntryPointDetail);
  const { error, httpStatus } = yield call(updateStartupStageById, id, action.payload.data);

  if (identity.isObjWithChildren(error)) {
    showNotification(error?.message, true, httpStatus);
  } else {
    showNotification('Startup stage updated');
    yield put(setDestroyDrawerOnClose({ destroyDrawerOnClose: true }));
    yield put(setIsDrawerOpen({ isDrawerOpen: false }));
    yield put(getStartupStages());
  }

  yield put(setIsDrawerLoading({ isLoading: false }));
}

function * handleUpdateTemplateById(action: PayloadAction<DataPayload<EditTemplateDataType>>) {
  yield put(setIsDrawerLoading({ isLoading: true }));

  const { data } = action.payload;
  const { id } = yield select(selectTemplateDetail);
  const subActiveTab:string = yield select(selectActiveSubTab);
  const { error, httpStatus } = yield call(updateTemplateById, id, data);

  if (identity.isObjWithChildren(error)) {
    showNotification(error?.message, true, httpStatus);
  } else {
    showNotification('Template Updated successfully');
    switch (subActiveTab) {
      case 'Acceptance letter': {
        yield put(getEmailTemplates());
        break;
      }
      case 'Incentive agreement': {
        yield put(getIncentiveTemplates());
        break;
      }
      case 'Work order': {
        yield put(getWorkOrderTemplatesAC());
        break;
      }
      default: {
        break;
      }
    }
    yield put(setDestroyDrawerOnClose({ destroyDrawerOnClose: true }));
    yield put(setIsDrawerOpen({ isDrawerOpen: false }));
  }
  yield put(setIsDrawerLoading({ isLoading: false }));
}

function * handleGetTemplateById(action: PayloadAction<IdPayload>) {
  yield put(setIsDrawerLoading({ isLoading: true }));
  const { id } = action.payload;

  const { error, httpStatus, data } = yield call(getTemplateById, id);

  if (identity.isObjWithChildren(error)) {
    showNotification(error?.message, true, httpStatus);
  } else {
    yield put(setTemplateDetail({ data }));
  }

  yield put(setIsDrawerLoading({ isLoading: false }));
}

export default function * settingsOverviewSagas() {
  yield all([
    takeLatest(types.UPDATE_TEMPLATE_BY_ID, handleUpdateTemplateById),
    takeLatest(types.UPDATE_EDUCATION, handleUpdateEducation),
    takeLatest(types.UPDATE_STARTUP_STAGE, handleUpdateStartupStage),
    takeLatest(types.UPDATE_TITLE, handleUpdateTitle),
    takeLatest(types.ADD_STARTUP_STAGE, handleAddStartupStage),
    takeLatest(types.ADD_TITLE, handleAddTitle),
    takeLatest(types.ADD_EDUCATION, handleAddEducation),
    takeLatest(types.GET_WORK_ORDER_TEMPLATES, handleGetWorkOrderTemplates),
    takeLatest(types.GET_INCENTIVE_TEMPLATES, handleGetIncentiveAgreementTemplates),
    takeLatest(types.GET_EMAIL_TEMPLATES, handleGetEmailTemplates),
    takeLatest(types.ADD_EMAIL_TEMPLATE, handleAddEmailTemplate),
    takeLatest(types.DELETE_EMAIL_TEMPLATE, handleDeleteEmailTemplate),
    takeLatest(types.GET_PROGRAM_TYPES, handleGetProgramTypes),
    takeLatest(types.DELETE_PROGRAM_TYPES, handleDeleteProgramTypes),
    takeLatest(types.GET_STARTUP_STAGES, handleGetStartupStages),
    takeLatest(types.DELETE_EDUCATION, handleDeleteEducation),
    takeLatest(types.GET_EDUCATION, handleGetEducation),
    takeLatest(types.DELETE_STARTUP_STAGES, handleDeleteStartupStages),
    takeLatest(types.GET_TITLES, handleGetTitles),
    takeLatest(types.DELETE_TITLES, handleDeleteTitles),
    takeLatest(types.DELETE_PROGRAM_TYPE_BY_ID, handleDeleteProgramTypeById),
    takeLatest(types.CREATE_PROGRAM_TYPE, handleCreateProgramType),
    takeLatest(types.UPDATE_PROGRAM_TYPE_BY_ID, handleUpdateProgramTypeById),
    takeLatest(types.DELETE_MULTIPLE_TEMPLATES, handleDeleteMultipleTemplates),
    takeLatest(types.GET_EMAIL_TEMPLATE_BY_ID, handleGetTemplateById),
  ]);
}
