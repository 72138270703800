import React, { useMemo } from 'react';

import { DrawerTitleProps } from 'types/componentTypes';
import { DRAWERS, identity } from 'utils';
import { RenderIf, Text } from 'components/components';
import { RequestDetailsStatus, PartnerRequestDetailsStatus } from './components';

import './DrawerTitle.scss';

const DrawerTitle = (props: DrawerTitleProps) => {
  const {
    items,
    drawerBody,
    serviceName,
    partnerRequest,
    performanceReport,
    eventName,
  } = props;

  const name = items.length > 1 ? 'Request details' : items[0]?.service?.name;

  const partnerRequestSubject = partnerRequest?.subject;
  const performanceReportTitle = `${
    performanceReport?.quarters
      ? `Q${performanceReport?.quarters}` : ''
  } ${
    identity.isTruthyNumber(performanceReport?.year)
      ? performanceReport?.year : ''
  } Report`;

  const isRequestDetails = drawerBody === DRAWERS.requestDetails;
  const isPartnerRequestDetails = drawerBody === DRAWERS.partnerRequestDetails;

  const title = useMemo(() => {
    switch (drawerBody) {
      case DRAWERS.requestService: {
        return serviceName;
      }
      case DRAWERS.requestDetails: {
        return name;
      }
      case DRAWERS.partnerRequestDetails: {
        return partnerRequestSubject;
      }
      case DRAWERS.performanceReviewDrawer: {
        return performanceReportTitle;
      }
      case DRAWERS.performanceViewDrawer: {
        return performanceReportTitle;
      }
      case DRAWERS.editEvent: {
        return eventName;
      }
      default: {
        return drawerBody;
      }
    }
  }, [drawerBody, name, serviceName, partnerRequestSubject, performanceReport, eventName]);

  return (
    <div className="drawer-title">
      <Text text={title} size="l" />
      <RenderIf condition={isRequestDetails}>
        <RequestDetailsStatus />
      </RenderIf>
      <RenderIf condition={isPartnerRequestDetails}>
        <PartnerRequestDetailsStatus status={partnerRequest?.status} requestId={partnerRequest?.id} />
      </RenderIf>
    </div>
  )
}

export default DrawerTitle;
