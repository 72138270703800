import httpClient, { ApiResponseType, GetParamsType } from '../../http_client';
import { convertGetStagesListResponse, STAGE_API } from '../utils';
import { GetStagesApiResponseType, GetStagesListResponse } from '../types';

const getStagesApi = () => {
  const url = `${STAGE_API}`;
  return httpClient.get<ApiResponseType<GetStagesApiResponseType>>(url);
}

const deleteStartupStagesApi = (params: GetParamsType) => {
  const url = STAGE_API;
  const config = { params };
  return httpClient.delete(url, config);
}

export const getStagesList = async (): Promise<GetStagesListResponse> => {
  const response = await getStagesApi();
  const { error, data, httpStatus } = response;
  return { error, httpStatus, data: convertGetStagesListResponse(data) };
}

export const deleteStartupStages = async (params: GetParamsType) => {
  const response = await deleteStartupStagesApi(params);
  const { error, data, httpStatus } = response;
  return { error, httpStatus, data };
}
