import React from 'react';

import { RequestListProps } from '../../../types/startups/startupDetails/serviceProviderOverview';
import Overview from '../Overview';
import identity from '../../../utils/identity';
import RequestContainer from './components/RequestContainer';

import './RequestList.scss';

const RequestList = (props: RequestListProps) => {
  const { startupName, requestsList, showTitle } = props;

  return (
    <Overview title={showTitle ? `Services with ${startupName}` : undefined}>
      <div className="service-requests_list_container">
        {identity.isFullArray(requestsList)
          ? requestsList.map((service) => (
            <RequestContainer key={`R${service.requestNumber}`} request={service} />
          ))
          : <p className="no_service">There are no service requests.</p>}
      </div>
    </Overview>
  );
}

RequestList.defaultProps = {
  showTitle: false,
  startupName: '',
}

export default RequestList;
