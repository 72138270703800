import { UploadFile } from 'antd';
import { inputTypes } from '../../../../components/FormBuilder/utils/constants';
import { createFormField } from '../../../../components/FormBuilder/utils/FormField';
import { getFormFieldBuilder } from '../../../../components/FormBuilder/utils/FormFieldBuilder';

export const defaultFileList: UploadFile[] = [{
  uid: '-1',
  name: 'request-details.pdf',
  status: 'done',
  url: 'https://s3.eu-west-1.wasabisys.com/misc-ac/sample-pdf-with-images.pdf',
  thumbUrl: 'https://s3.eu-west-1.wasabisys.com/misc-ac/sample-pdf-with-images.pdf',
  size: 2,
}];

export const requestDetailsFieldsTop = [
  {
    fields: [
      createFormField('requestNumber', 'Request number'),
    ],
  },
  {
    fields: [
      createFormField('date', 'Date', inputTypes.datetime),
    ],
  },
  {
    fields: [
      createFormField('to', 'To', inputTypes.datetime),
    ],
  },
  {
    fields: [
      createFormField('type', 'Type'),
    ],
  },
  {
    fields: [
      createFormField('category', 'Category'),
    ],
  },
  {
    fields: [
      createFormField('serviceProvider', 'Service provider'),
    ],
  },
  {
    fields: [
      createFormField('offer', 'Offer'),
    ],
  },
  {
    fields: [
      getFormFieldBuilder('description', 'Description', inputTypes.textArea)
        .withRows(2)
        .build(),
    ],
  },
  {
    fields: [
      createFormField('status', 'Status'),
    ],
  },
];

export const requestDetailsFieldsBottom = [
  {
    heading: 'Proposal',
    fields: [
      createFormField('title', 'Title'),
    ],
  },
  {
    fields: [
      createFormField('duration', 'Duration'),
    ],
  },
  {
    fields: [
      createFormField('amount', 'Amount'),
    ],
  },
  {
    fields: [
      getFormFieldBuilder('otherDetails', 'Other details', inputTypes.textArea)
        .withRows(2)
        .build(),
    ],
  },
  {
    fields: [
      getFormFieldBuilder('proposalDocument', 'Proposal document', inputTypes.dragger)
        .withAccept('png')
        .withPreviewType('image')
        .withDefaultFiles(defaultFileList)
        .build(),
    ],
  },
];
