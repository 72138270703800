import React from 'react'
import { Radio, RadioGroupProps } from 'antd';

import './RadioTags.scss';

const RadioTags = (props: RadioGroupProps) => {
  const {
    options, defaultValue, onChange, value,
  } = props;

  return (
    <Radio.Group
      className="tag-select"
      optionType="button"
      buttonStyle="solid"
      options={options}
      defaultValue={defaultValue}
      onChange={onChange}
      value={value}
    />
  );
}

export default RadioTags;
