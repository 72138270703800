import { connect } from 'react-redux';
import { bindActionCreators, Dispatch, Action } from 'redux';

import {
  selectCategoryID,
  selectHasMainCategory,
  selectServiceCategories,
  selectServiceCategoryDetails,
  selectServiceSubCategoryDetails,
} from 'redux/selectors/services';
import { StateType } from 'types';
import {
  getServiceCategories,
  addServiceSubCategory,
  updateServiceCategoryById,
  updateServiceSubCategoryById,
  deleteServiceCategoryById,
  deleteServiceSubCategoryById,
  addServiceCategory,
} from 'redux/actions/services';
import { setDrawerBody, setIsDrawerOpen } from 'redux/actions/workspace';

const mapStateToProps = (state: StateType) => ({
  categories: selectServiceCategories(state),
  serviceCategoryDetails: selectServiceCategoryDetails(state),
  serviceSubCategoryDetails: selectServiceSubCategoryDetails(state),
  hasMainCategory: selectHasMainCategory(state),
  categoryID: selectCategoryID(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  actions: bindActionCreators({
    getServiceCategories,
    addServiceSubCategory,
    setDrawerBody,
    setIsDrawerOpen,
    updateServiceCategoryById,
    updateServiceSubCategoryById,
    deleteServiceCategoryById,
    deleteServiceSubCategoryById,
    addServiceCategory,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps);
