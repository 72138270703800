/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
  RefObject,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Button, Select } from 'antd';
import { AiOutlinePlus } from 'react-icons/ai';
import { BaseSelectRef } from 'rc-select';
import { TableRowSelection } from 'antd/lib/table/interface';
import { EventSubscription } from 'fbemitter';

import { AddButtonProps, LayoutType, SelectDrawerProps } from 'types/componentTypes';
import { identity } from 'utils';
import EventEmitter from 'event_emitter';
import Input from '../Input';
import { FilterTableDrawer } from '../Drawer';
import RenderIf from '../RenderIf';

const AddButtonDrawer = (props: AddButtonProps & SelectDrawerProps) => {
  const {
    name,
    label,
    dataSource,
    rowKeyName,
    columns,
    form,
    drawerTitle,
    filterBy,
    placeholder,
    options,
    actions,
    isFilterTableOpen,
    layout,
    eventName,
    buttonText,
  } = props;

  const value = form.getFieldValue(name);
  const [checked, setChecked] = useState<string[]>(value);
  const [displaySelect, setDisplaySelect] = useState(identity.isFullArray(value));
  const [openDrawer, setDrawerOpen] = useState(false);
  const selectRef = useRef<BaseSelectRef>();

  useEffect(() => {
    if (!isFilterTableOpen) {
      setDrawerOpen(false);

      if (identity.isFalsy(form.getFieldValue(name))) {
        setDisplaySelect(true);
      }
    }
  }, [isFilterTableOpen, form, name]);

  useEffect(() => {
    let listener: EventSubscription;
    if (identity.isTruthyString(eventName)) {
      listener = EventEmitter.addListener(eventName as string, () => setDisplaySelect(false));
    }

    return () => {
      if (identity.isTruthyString(eventName)) {
        listener.remove();
      }
    }
  }, [eventName]);

  const onClick = () => {
    setDisplaySelect(true);
    actions.setIsDrawerFilterTableOpen({ isDrawerFilterTableOpen: true });
    setDrawerOpen(true);
  }

  const onChange = () => {
    if (identity.isEmptyArray(form.getFieldValue(name))) {
      setDisplaySelect(false);
    }
  }

  const onBlur = () => {
    if (identity.isEmptyArray(checked)) {
      setDisplaySelect(false);
    }
  }

  const onResetAll = () => {
    form.setFieldValue(name, []);
    setChecked([]);
  }

  const rowSelection: TableRowSelection<any> = {
    type: 'checkbox',
    selectedRowKeys: value,
    onChange: (selectedRowKeys: React.Key[]) => {
      form.setFieldValue(name, selectedRowKeys as string[]);
      setChecked(selectedRowKeys as string[]);
    },
    getCheckboxProps: (record: any) => ({
      [rowKeyName]: record?.[rowKeyName],
    }),
    preserveSelectedRowKeys: true && identity.isFullArray(value),
  }

  return (
    <div className="input">
      <div className="add-button-container">
        <label className="add-button-container__label">{label}</label>
        <RenderIf condition={displaySelect}>
          <Input name={name} layout={layout as LayoutType}>
            {identity.isEmptyArray(value)
              ? <span>{`No ${placeholder} added.`}</span>
              : (
                <Select
                  ref={selectRef as RefObject<BaseSelectRef>}
                  value={value}
                  onBlur={onBlur}
                  onFocus={() => selectRef.current?.blur()}
                  mode="multiple"
                  onChange={onChange}
                  options={options}
                  open={false}
                />
              )}
          </Input>
        </RenderIf>
        <FilterTableDrawer
          drawerTitle={drawerTitle}
          columns={columns}
          dataSource={dataSource}
          rowKey={(record) => record?.[rowKeyName]}
          showButtons
          rowSelection={rowSelection}
          filterBy={filterBy}
          placeholder={placeholder}
          type="checkbox"
          openDrawer={openDrawer}
          onClose={() => setDrawerOpen(false)}
          onResetAll={onResetAll}
        />
        <Button
          type="link"
          icon={<AiOutlinePlus />}
          onClick={onClick}
        >
          {`Add ${buttonText || label}`}
        </Button>
      </div>
    </div>
  )
};

AddButtonDrawer.defaultProps = {
  name: '',
  label: '',
  layout: 'vertical',
  buttonText: '',
}

export default AddButtonDrawer;
