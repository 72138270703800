import {
  all, call, put, select, takeLatest,
} from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';

import {
  createCohort,
  getCohortById,
  getCohortsCount,
  getCohortsList,
  getProgramCohortsList,
  getProgramsList,
  updateCohort,
} from 'packages/cohort_repository';
import { getStartupsList } from 'packages/startup_repository';
import { navigateToRoute, identity } from 'utils';
import showNotification from 'services/utils/showNotification';
import { IdPayload } from 'types/reduxTypes/ActionTypes';
import {
  PatchCohortPayload,
  CreateCohortPayload,
  CohortQuery,
} from 'types/reduxTypes/ActionTypes/CohortTypes';
import { CohortType } from 'types/cohorts/cohortOverview';
import { composeCohortsDetailsUrl } from 'routes/routeComposers';
import {
  setDestroyDrawerOnClose,
  setIsDrawerLoading,
  setIsDrawerOpen,
  setTableLayout,
} from '../../actions/workspace';
import { selectCohortData } from '../../selectors/cohort';
import {
  getCohorts,
  setCohortById,
  setCohorts, setCohortsCount,
  setIsLoading,
  setProgramCohorts,
  setPrograms,
  setReviewDocumentRequests,
  types,
} from '../../actions/cohort';

function * handleGetCohorts(action: PayloadAction<CohortQuery>) {
  yield put(setTableLayout({ isRowTableLayout: true }));
  yield put(setIsLoading({ isLoading: true }));

  const { data: cohorts, error, httpStatus } = yield call(getCohortsList, action.payload.query);

  if (identity.isObjWithChildren(error)) {
    showNotification(`Unable to fetch cohorts: ${error.message}`, true, httpStatus);
  } else {
    yield put(setCohorts({ cohorts }));
  }

  yield put(setIsLoading({ isLoading: false }));
}

function * handleGetCohortsCount() {
  const { data, error, httpStatus } = yield call(getCohortsCount, { f: 'id__count' });

  if (identity.isObjWithChildren(error)) {
    showNotification(`Unable to fetch cohorts count: ${error.message}`, true, httpStatus);
  } else {
    const [{ id__count }] = data;
    yield put(setCohortsCount({ data: id__count }));
  }
}

function * handleGetCohortById(action: PayloadAction<IdPayload>) {
  const { id, isUpdate } = action.payload;
  const selectedCohort: CohortType = yield select(selectCohortData);
  const { id: selectedCohortId } = selectedCohort;

  if (id === selectedCohortId && identity.isFalsy(isUpdate)) {
    /* Requested cohort is already persisted in local state and has not been updated locally */
    return;
  }

  const {
    data: cohort, error, httpStatus,
  } = yield call(getCohortById, id, true);

  if (identity.isObjWithChildren(error)) {
    showNotification(`Unable to fetch cohort: ${error.message}`, true, httpStatus);
  } else {
    yield put(setCohortById({ cohort }));
  }
}

function * handlePatchCohortById(action: PayloadAction<PatchCohortPayload>) {
  const { id, data } = action.payload;
  yield put(setIsLoading({ isLoading: true }));

  const { error, httpStatus } = yield call(updateCohort, id, data);

  if (identity.isObjWithChildren(error)) {
    showNotification(`Error updating cohort: ${error.message}`, true, httpStatus);
  } else {
    showNotification(`Cohort ${id} updated successfully`);
    yield put(setCohortById({ cohort: data }));
    // Temporary patch until pagination is implemented on Get Cohorts API
    yield put(setCohorts({ cohorts: [] }));
    navigateToRoute(composeCohortsDetailsUrl(id));
  }
  yield put(setIsLoading({ isLoading: false }));
}

function * handleCreateCohort(action: PayloadAction<CreateCohortPayload>) {
  const { data } = action.payload;
  yield put(setIsDrawerLoading({ isLoading: true }));

  const { error, httpStatus } = yield call(createCohort, data);

  if (identity.isObjWithChildren(error)) {
    showNotification(`Unable to create cohort: ${error.message}`, true, httpStatus);
  } else {
    const { name } = data;
    showNotification(`Successfully created cohort: ${name}`);
    yield put(setDestroyDrawerOnClose({ destroyDrawerOnClose: true }));
    yield put(setIsDrawerOpen({ isDrawerOpen: false }));
    yield put(getCohorts({ query: { isPreload: true } }));
  }

  yield put(setIsDrawerLoading({ isLoading: false }));
}

function * handleGetDocumentReviewRequests(action: PayloadAction<IdPayload>) {
  const { id } = action.payload;
  const {
    data: startups, error, httpStatus,
  } = yield call(getStartupsList, { isPreload: true, filters: { cohorts: [id] } });

  if (error) {
    showNotification(`Unable to fetch document requests: ${error.message}`, true, httpStatus);
  } else {
    yield put(setReviewDocumentRequests({ startups }));
  }
}

function * handleGetPrograms() {
  const { data: programs, error } = yield call(getProgramsList);

  if (identity.isObjWithChildren(error)) {
    showNotification(error.message, true);
  } else {
    yield put(setPrograms({ programs }));
  }
}

function * handleGetProgramCohorts(action: PayloadAction<IdPayload>) {
  const { id } = action.payload;
  yield put(setIsLoading({ isLoading: true }));

  const { data: cohorts, error, httpStatus } = yield call(getProgramCohortsList, id);
  if (identity.isObjWithChildren(error)) {
    showNotification(`Unable to fetch program cohorts: ${error.message}`, true, httpStatus);
  } else {
    yield put(setProgramCohorts({ cohorts }));
  }

  yield put(setIsLoading({ isLoading: false }));
}

export default function * cohortSagas() {
  yield all([
    takeLatest(types.GET_COHORTS, handleGetCohorts),
    takeLatest(types.GET_COHORT_BY_ID, handleGetCohortById),
    takeLatest(types.PATCH_COHORT_BY_ID, handlePatchCohortById),
    takeLatest(types.CREATE_COHORT, handleCreateCohort),
    takeLatest(types.GET_DOCUMENT_REVIEW_REQUESTS, handleGetDocumentReviewRequests),
    takeLatest(types.GET_PROGRAMS, handleGetPrograms),
    takeLatest(types.GET_PROGRAM_COHORTS, handleGetProgramCohorts),
    takeLatest(types.GET_COHORTS_COUNT, handleGetCohortsCount),
  ]);
}
