import React from 'react';

import SVGWrap from './SVGWrap';

const DigitalMarketingIcon = () => (
  <SVGWrap size={24}>
    <path d="M16.1016 18.6549H3.21094C3.09434 18.6549 3 18.5606 3 18.444V5.55322C3 5.43662 3.09434 5.34229 3.21094 5.34229H16.1016C16.2182 5.34229 16.3125 5.43662 16.3125 5.55322V9.28861L20.683 6.75089C20.7475 6.71339 20.8283 6.71222 20.8939 6.75089C20.959 6.78839 21 6.85812 21 6.9337V17.0828C21 17.1584 20.9596 17.2281 20.8934 17.2656C20.8289 17.3025 20.7475 17.3025 20.6824 17.2645L16.3125 14.7103V18.444C16.3125 18.5606 16.2182 18.6549 16.1016 18.6549ZM3.42188 18.233H15.8906V14.3424C15.8906 14.2668 15.9311 14.197 15.9973 14.1595C16.0617 14.1226 16.1437 14.1226 16.2082 14.1607L20.5781 16.7148V7.29933L16.2076 9.83705C16.1432 9.87455 16.0623 9.87513 15.9967 9.83705C15.9311 9.80013 15.8906 9.73041 15.8906 9.65482V5.76416H3.42188V18.233ZM7.89844 15.2453C7.86211 15.2453 7.82519 15.2353 7.79297 15.2166C7.72793 15.1791 7.6875 15.1094 7.6875 15.0338V8.94524C7.6875 8.86966 7.72734 8.79993 7.79297 8.76243C7.85801 8.72493 7.93887 8.72493 8.00391 8.76243L13.2773 11.8158C13.3424 11.8533 13.3828 11.9236 13.3828 11.9986C13.3822 12.0736 13.3424 12.1439 13.2768 12.1814L8.00332 15.2166C7.97109 15.2353 7.93477 15.2453 7.89844 15.2453ZM8.10937 9.31087V14.6693L12.75 11.998L8.10937 9.31087Z" strokeWidth="0.58594" />
  </SVGWrap>
);

export default DigitalMarketingIcon;
