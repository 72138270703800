import { RcFile } from 'antd/lib/upload';

import { requiredValidation, emailValidation } from '../../../../utils/constants/validationRules';
import { getFormFieldBuilder } from '../../../components/FormBuilder/utils/FormFieldBuilder';
import { inputTypes } from '../../../components/FormBuilder/utils/constants';

export const defaultValues = [
  {
    name: '',
    type: '',
    website: '',
    status: '',
    mubadala: '',
    imageLink: '',
    about: '',
    contactPersonFirstName: '',
    contactPersonLastName: '',
    email: '',
    phoneNumber: '',
    role: '',
  },
];

export type AddPartnerFieldsParams = {
  beforeLogoUpload: (_file: RcFile) => boolean,
}

export const PARTNER_TYPES = [
  { value: 1, label: 'Corporate' },
  { value: 2, label: 'Government' },
  { value: 3, label: 'VC' },
];

export const mouSignedValues = [
  {
    value: 1,
    title: 'Yes',
    key: 'yes',
  },
  {
    value: 0,
    title: 'No',
    key: 'no',
  },
];

export const addPartnerMouFields = ({ beforeLogoUpload }: AddPartnerFieldsParams) => [
  {
    fields: [
      getFormFieldBuilder('logo', 'Image', inputTypes.image)
        .withBeforeUpload(beforeLogoUpload)
        .build(),
    ],
  },
  {
    fields: [
      getFormFieldBuilder('description', 'Description', inputTypes.textArea)
        .withRules([requiredValidation])
        .required()
        .withRows(3)
        .build(),
    ],
  },
  {
    heading: 'Contact Person',
    fields:  [
      getFormFieldBuilder('contactFirstName', 'First name')
        .withRules([requiredValidation])
        .required()
        .build(),
      getFormFieldBuilder('contactLastName', 'Last name')
        .withRules([requiredValidation])
        .required()
        .build(),
    ],
  },
  {
    fields:  [
      getFormFieldBuilder('contactEmail', 'Email ID', inputTypes.email)
        .withRules([requiredValidation, emailValidation])
        .required()
        .build(),
      getFormFieldBuilder('contactPhone', 'Mobile number', inputTypes.number)
        .withRules([requiredValidation])
        .required()
        .build(),
    ],
  },
  {
    fields:  [
      getFormFieldBuilder('contactRole', 'Role')
        .withRules([requiredValidation])
        .required()
        .build(),
    ],
  },
]

export const addPartnerFields = [
  {
    fields: [
      getFormFieldBuilder('name', 'Partner Name')
        .withRules([requiredValidation])
        .required()
        .build(),
    ],
  },
  {
    fields: [
      getFormFieldBuilder('type', 'Type', inputTypes.select)
        .withOptions(PARTNER_TYPES)
        .withRules([requiredValidation])
        .required()
        .build(),
      getFormFieldBuilder('website', 'Website')
        .withRules([requiredValidation])
        .required()
        .build(),
    ],
  },
];
