import { connect } from 'react-redux';
import { Action, bindActionCreators, Dispatch } from 'redux';

import { getProgramCohorts } from 'redux/actions/cohort';
import { selectCohortData, selectIsLoading, selectProgramCohorts } from 'redux/selectors/cohort';
import { StateType } from 'types';

const mapStateToProps = (state: StateType) => ({
  selectedCohort: selectCohortData(state),
  programCohorts: selectProgramCohorts(state),
  isLoading: selectIsLoading(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  actions: bindActionCreators({
    getProgramCohorts,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps);
