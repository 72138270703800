import React from 'react';
import { Button } from 'antd';

import { RenderIf } from 'components/components';
import { AddIcon, NoDataIcon } from 'assets/icons';
import { NoDataProps } from 'types/settingsOverview';
import { identity } from 'utils';

import './NoData.scss';

const NoData = (props: NoDataProps) => (
  <div className="no-data">
    <NoDataIcon size={64} />
    <RenderIf condition={identity.isTruthyString(props.text)}>
      <p>{props.text}</p>
    </RenderIf>
    <RenderIf condition={identity.isFunction(props.onAddNew)}>
      <Button icon={<AddIcon />} className="secondary-button" onClick={props.onAddNew}>Add new</Button>
    </RenderIf>
  </div>
);

export default NoData;
