import { connect } from 'react-redux';

import { StateType } from 'types';
import { selectDashboardRequests } from 'redux/selectors/dashboard';

const mapStateToProps = (state: StateType) => ({
  requests: selectDashboardRequests(state),
});

export default connect(mapStateToProps);
