import { UploadFile } from 'antd';

import { DataPayload } from '../../../../../../../types/reduxTypes/ActionTypes';

export const types = {
  SET_FILES: 'SET_FILES',
  SET_FILE_NAME: 'SET_FILE_NAME',
  SET_FILE_SIZE: 'SET_FILE_SIZE',
  SET_FILE_TYPE: 'SET_FILE_TYPE',
  SET_FILE_CONTENT: 'SET_FILE_CONTENT',
}

export const setFiles = (payload: DataPayload<UploadFile[]>) => ({
  type: types.SET_FILES,
  payload,
});

export const setFileName = (payload: DataPayload<string>) => ({
  type: types.SET_FILE_NAME,
  payload,
});

export const setFileType = (payload: DataPayload<string>) => ({
  type: types.SET_FILE_TYPE,
  payload,
});

export const setFileSize = (payload: DataPayload<string>) => ({
  type: types.SET_FILE_SIZE,
  payload,
});

export const setFileContent = (payload: DataPayload<string>) => ({
  type: types.SET_FILE_CONTENT,
  payload,
});
