import identity from 'utils/identity';

import { GenderType } from '../../../../types/people';

export const parseGenderValue = (gender?: GenderType): 0 | 1 | 2 => {
  switch (gender) {
    case 'Male': {
      return 1;
    }
    case 'Female': {
      return 2;
    }
    default: {
      return 0;
    }
  }
}

export const parseGender = (gender: 0 | 1 | 2) => {
  switch (gender) {
    case 1: {
      return 'Male';
    }
    case 2: {
      return 'Female';
    }
    default: {
      return 'Not Specified';
    }
  }
}

export const parseStartupCapacity = (capacity: 0 | 1 | boolean) => (identity.isFalsy(capacity) ? 'Employee' : 'Founder');

export const parsePreloadValue = (preload: boolean = false) => Number(preload);
