import { connect } from 'react-redux';
import { Action, bindActionCreators, Dispatch } from 'redux';
import {
  selectIsLoading, selectPerformanceReports, selectLimit, selectOffset, selectActiveTab, selectCounts,
} from 'redux/selectors/performance';
import {
  getPerformanceReports,
  setLimit,
  setOffset,
  setActiveTab,
  getCounts,
  deletePerformanceReportById,
  setPerformanceReportId,
} from 'redux/actions/performance';
import {
  setIsDrawerOpen,
  setDrawerBody,
} from 'redux/actions/workspace';

import { StateType } from 'types';
import { selectUser } from 'redux/selectors/auth';

const mapStateToProps = (state: StateType) => ({
  isLoading: selectIsLoading(state),
  performanceReports: selectPerformanceReports(state),
  limit: selectLimit(state),
  offset: selectOffset(state),
  activeTab: selectActiveTab(state),
  user: selectUser(state),
  counts: selectCounts(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  actions: bindActionCreators({
    getPerformanceReports,
    setLimit,
    setOffset,
    setActiveTab,
    getCounts,
    deletePerformanceReportById,
    setIsDrawerOpen,
    setDrawerBody,
    setPerformanceReportId,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps);
