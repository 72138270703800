import { connect } from 'react-redux';
import { bindActionCreators, Dispatch, Action } from 'redux';

import { StateType } from 'types';
import { selectUserType } from 'redux/selectors/auth';
import {
  selectFiltersActive,
  selectIsLoading,
  selectServiceAndSpActiveTab,
  selectServiceCategories,
  selectServicesFilters,
  selectServiceSubCategories,
} from 'redux/selectors/services';
import { getServiceProvidersCount } from 'redux/actions/serviceProviders';
import {
  getAllServiceSubCategories,
  getServiceCategories,
  getServicesCount,
} from 'redux/actions/services';

const mapStateToProps = (state: StateType) => ({
  userType: selectUserType(state),
  activeTab: selectServiceAndSpActiveTab(state),
  isLoading: selectIsLoading(state),
  filters: selectServicesFilters(state),
  categories: selectServiceCategories(state),
  subCategories: selectServiceSubCategories(state),
  filtersActive: selectFiltersActive(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  actions: bindActionCreators({
    getServicesCount,
    getServiceProvidersCount,
    getServiceCategories,
    getAllServiceSubCategories,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps);
