import React, {
  RefObject, useCallback, useEffect, useRef, useState,
} from 'react';
import { Button, Select } from 'antd';
import { BaseSelectRef } from 'rc-select';
import { AiOutlinePlus } from 'react-icons/ai';

import { AddButtonProps } from '../../../../../types/componentTypes';
import identity from '../../../../../utils/identity';
import Input from '../../index';
import { validateCharLength } from '../../../../../utils';

import './AddButton.scss';

const AddButton = (props: AddButtonProps) => {
  const { name, label } = props;

  const [displaySelect, setDisplaySelect] = useState(false);
  const [values, setValues] = useState<string[]>([]);
  const selectRef = useRef<BaseSelectRef>();

  const focus = useCallback(() => {
    if (displaySelect) {
      selectRef.current?.focus();
    }
  }, [displaySelect]);

  useEffect(() => {
    focus();
  }, [focus]);

  const onClick = () => {
    setDisplaySelect(true);
    focus();
  }

  const onChange = (selectedValues: string[]) => {
    setValues(selectedValues);
    if (identity.isEmptyArray(selectedValues)) {
      setDisplaySelect(false);
    }
  }

  const onBlur = () => {
    if (identity.isEmptyArray(values)) {
      setDisplaySelect(false);
    }
  }

  return (
    <div className="input">
      <div className="add-button-container">
        <label className="add-button-container__label">{label}</label>
        {displaySelect ? (
          <Input name={name} rules={[validateCharLength]}>
            <Select
              ref={selectRef as RefObject<BaseSelectRef>}
              tokenSeparators={[',']}
              value={values}
              onBlur={onBlur}
              mode="tags"
              placeholder={`Add ${label?.toLowerCase()}`}
              onChange={onChange}
              getPopupContainer={(trigger) => trigger.parentNode}
            />
          </Input>
        ) : null}
        <Button
          type="link"
          icon={<AiOutlinePlus />}
          onClick={onClick}
        >
          {`Add ${label}`}
        </Button>
      </div>
    </div>
  )
}

AddButton.defaultProps = {
  name: '',
  label: '',
}

export default AddButton;
