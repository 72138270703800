import React, { useMemo } from 'react';
import { Spin } from 'antd';

import {
  RenderIf,
  ServiceCard,
  Card,
  PartnerCard,
} from 'components/components';
import { PartnerType } from 'packages/partner_repository';
import { GridTableProps } from 'types/componentTypes/TableProps';
import { identity } from 'utils';
import CustomPagination from '../CustomPagination';
import NoDataContainer from './components/noDataContainer';

const GridTable = (props: GridTableProps) => {
  const {
    dataSource,
    columns,
    onGridCardClick,
    onDeleteService,
    onEditService,
    onRequestService,
    firstButtonAction,
    secondButtonAction,
    component,
    displayLogoImage,
  } = props;

  const componentRender = useMemo(() => {
    if (!identity.isEmptyArray(dataSource)) {
      switch (component) {
        case 'partners': {
          return (
            <>
              <PartnerCard
                columns={columns}
                dataSource={dataSource as PartnerType[]}
                onGridCardClick={onGridCardClick}
                numOfCard={props.limit}
                onDeleteService={onDeleteService}
                onEditService={onEditService}
                onRequestService={onRequestService}
                firstButtonTitle={props.firstButtonTitle}
                secondButtonTitle={props.secondButtonTitle}
                hasDeleteOption={props.hasDeleteOption}
                firstButtonAction={firstButtonAction}
                secondButtonAction={secondButtonAction}
                isFirstButtonDisabled={props.isFirstButtonDisabled}
                isSecondButtonDisabled={props.isSecondButtonDisabled}
              />
              <RenderIf condition={props.showCustomPagination}>
                <CustomPagination
                  limit={props.limit}
                  setLimit={props.setLimit}
                  currentPage={props.currentPage}
                  setCurrentPage={props.setCurrentPage}
                  total={props.total}
                  gridLayout
                />
              </RenderIf>
            </>
          )
        }
        case 'startup': {
          return (
            <>
              <ServiceCard
                columns={columns}
                dataSource={dataSource as PartnerType[]}
                onGridCardClick={onGridCardClick}
                numOfCard={props.limit}
                onDeleteService={onDeleteService}
                onEditService={onEditService}
                onRequestService={onRequestService}
                firstButtonTitle={props.firstButtonTitle}
                secondButtonTitle={props.secondButtonTitle}
                hasDeleteOption={props.hasDeleteOption}
                firstButtonAction={firstButtonAction}
                secondButtonAction={secondButtonAction}
                isFirstButtonDisabled={props.isFirstButtonDisabled}
                isSecondButtonDisabled={props.isSecondButtonDisabled}
              />
              <RenderIf condition={props.showCustomPagination}>
                <CustomPagination
                  limit={props.limit}
                  setLimit={props.setLimit}
                  currentPage={props.currentPage}
                  setCurrentPage={props.setCurrentPage}
                  total={props.total}
                  gridLayout
                />
              </RenderIf>
            </>
          )
        }
        case 'startup-sp': {
          return (
            <>
              <ServiceCard
                columns={columns}
                dataSource={dataSource}
                onGridCardClick={onGridCardClick}
                numOfCard={props.numOfCard}
                onDeleteService={onDeleteService}
                onEditService={onEditService}
                onRequestService={onRequestService}
                firstButtonTitle={props.firstButtonTitle}
                secondButtonTitle={props.secondButtonTitle}
                hasDeleteOption={props.hasDeleteOption}
                firstButtonAction={firstButtonAction}
                secondButtonAction={secondButtonAction}
                isFirstButtonDisabled={props.isFirstButtonDisabled}
                isSecondButtonDisabled={props.isSecondButtonDisabled}
                unsentRequests={props.unsentRequests}
                displayLogoImage={displayLogoImage}
              />
              <RenderIf condition={props.showCustomPagination}>
                <CustomPagination
                  limit={props.limit}
                  setLimit={props.setLimit}
                  currentPage={props.currentPage}
                  setCurrentPage={props.setCurrentPage}
                  total={props.total}
                  gridLayout
                />
              </RenderIf>
            </>
          )
        }
        default: {
          return (
            <>
              <Card
                columns={columns}
                dataSource={dataSource}
                onGridCardClick={onGridCardClick}
                numOfCard={props.numOfCard}
                component={props.component}
                onDeleteService={onDeleteService}
                onEditService={onEditService}
                onRequestService={onRequestService}
                unsentRequests={props.unsentRequests}
              />
              <RenderIf condition={props.showCustomPagination}>
                <CustomPagination
                  limit={props.limit}
                  setLimit={props.setLimit}
                  currentPage={props.currentPage}
                  setCurrentPage={props.setCurrentPage}
                  total={props.total}
                  gridLayout
                />
              </RenderIf>
            </>
          )
        }
      }
    } else {
      return <NoDataContainer />;
    }
  }, [props]);

  return <Spin spinning={props.isLoading}>{componentRender}</Spin>;
}

GridTable.defaultProps = {
  isLoading: false,
}

export default GridTable;
