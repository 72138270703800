import { GetPerformanceParams, PerformanceType, PerformanceTypePayload } from 'packages/performance_repository'
import { DataPayload, IdPayload, IsLoadingPayload } from 'types/reduxTypes/ActionTypes'
import { SetCountByStatusPayload } from 'types/reduxTypes/ActionTypes/PerformanceTypes';

export const types = {
  GET_PERFORMANCE_REPORTS: 'GET_PERFORMANCE_REPORTS',
  GET_PERFORMANCE_REPORT_BY_ID: 'GET_PERFORMANCE_REPORT_BY_ID',
  SET_PERFORMANCE_REPORTS: 'SET_PERFORMANCE_REPORTS',
  SET_IS_LOADING_PERFORMANCE: 'SET_IS_LOADING_PERFORMANCE',
  SET_OFFSET_PERFORMANCE: 'SET_OFFSET_PERFORMANCE',
  SET_LIMIT_PERFORMANCE: 'SET_LIMIT_PERFORMANCE',
  SET_ACTIVE_TAB_PERFORMANCE: 'SET_ACTIVE_TAB_PERFORMANCE',
  SET_COUNT_BY_STATUS_PERFORMANCE: 'SET_COUNT_BY_STATUS_PERFORMANCE',
  GET_COUNTS_PERFORMANCE: 'GET_COUNTS_PERFORMANCE',
  DELETE_PERFORMANCE_REPORT_BY_ID: 'DELETE_PERFORMANCE_REPORT_BY_ID',
  REMOVE_PERFORMANCE_REPORT_BY_ID: 'REMOVE_PERFORMANCE_REPORT_BY_ID',
  SET_PERFORMANCE_REPORT_ID: 'SET_PERFORMANCE_REPORT_ID',
  SET_PERFORMANCE_REPORT_BY_ID: 'SET_PERFORMANCE_REPORT_BY_ID',
  UPDATE_PERFORMANCE_REPORT_BY_ID: 'UPDATE_PERFORMANCE_REPORT_BY_ID',
  CREATE_PERFORMANCE_REPORT: 'CREATE_PERFORMANCE_REPORT',
  RESET_ALL_PERFORMANCE: 'RESET_ALL_PERFORMANCE',
  RESET_COUNTS_PERFORMANCE: 'RESET_COUNTS_PERFORMANCE',
}

export const setPerformanceReportById = (payload: DataPayload<PerformanceType>) => ({
  type: types.SET_PERFORMANCE_REPORT_BY_ID,
  payload,
});

export const updatePerformanceReportById = (payload: DataPayload<PerformanceTypePayload>) => ({
  type: types.UPDATE_PERFORMANCE_REPORT_BY_ID,
  payload,
});

export const setPerformanceReportId = (payload: IdPayload) => ({
  type: types.SET_PERFORMANCE_REPORT_ID,
  payload,
});

export const getPerformanceReports = (payload: GetPerformanceParams = {}) => ({
  type: types.GET_PERFORMANCE_REPORTS,
  payload,
});

export const getPerformanceReportById = (payload: IdPayload) => ({
  type: types.GET_PERFORMANCE_REPORT_BY_ID,
  payload,
});

export const setPerformanceReports = (payload: DataPayload<PerformanceType[]>) => ({
  type: types.SET_PERFORMANCE_REPORTS,
  payload,
});

export const setIsLoading = (payload: IsLoadingPayload) => ({
  type: types.SET_IS_LOADING_PERFORMANCE,
  payload,
});

export const setOffset = (payload: DataPayload<number>) => ({
  type: types.SET_OFFSET_PERFORMANCE,
  payload,
});

export const setLimit = (payload: DataPayload<number>) => ({
  type: types.SET_LIMIT_PERFORMANCE,
  payload,
});

export const setActiveTab = (payload: DataPayload<string>) => ({
  type: types.SET_ACTIVE_TAB_PERFORMANCE,
  payload,
});

export const getCounts = (payload: GetPerformanceParams = {}) => ({
  type: types.GET_COUNTS_PERFORMANCE,
  payload,
});

export const setCountByStatus = (payload: SetCountByStatusPayload) => ({
  type: types.SET_COUNT_BY_STATUS_PERFORMANCE,
  payload,
});

export const deletePerformanceReportById = (payload: IdPayload) => ({
  type: types.DELETE_PERFORMANCE_REPORT_BY_ID,
  payload,
});

export const removePerformanceReportById = (payload: IdPayload) => ({
  type: types.REMOVE_PERFORMANCE_REPORT_BY_ID,
  payload,
});

export const createPerformanceReport = (payload: DataPayload<PerformanceTypePayload>) => ({
  type: types.CREATE_PERFORMANCE_REPORT,
  payload,
});

export const resetAll = (payload: object = {}) => ({
  type: types.RESET_ALL_PERFORMANCE,
  payload,
});

export const resetCounts = (payload: object = {}) => ({
  type: types.RESET_COUNTS_PERFORMANCE,
  payload,
});
