import React from 'react'

import { WelcomeHeaderProps } from '../../../../types/submitOverview';

import './WelcomeHeader.scss';

const WelcomeHeader = (props: WelcomeHeaderProps) => {
  const { userName } = props;

  return (
    <div className="welcome-header">
      <div className="welcome-header__text">
        <h1 className="welcome-header__title">{`Welcome ${userName}!`}</h1>
        <p className="welcome-header__description">View some of your status, notifications and performance below</p>
      </div>
      <img className="welcome-header__startup-logo" alt="startup-logo" src="https://srasolutions.com.au/wp-content/uploads/2017/02/Logos-fake-mock-up-illust-ss143531671-2.png" />
    </div>
  )
}

export default WelcomeHeader
