import { connect } from 'react-redux';
import { Action, bindActionCreators, Dispatch } from 'redux';

import { selectCohorts, selectCohortsCount, selectIsLoading } from 'redux/selectors/cohort';
import { StateType } from 'types';
import { getCohorts, getCohortsCount } from 'redux/actions/cohort';
import { selectIsRowTableLayout } from 'redux/selectors/workspace';
import { selectUserType } from 'redux/selectors/auth';
import { setDrawerBody, setIsDrawerOpen } from 'redux/actions/workspace';

const mapStateToProps = (state: StateType) => ({
  cohorts: selectCohorts(state),
  isLoading: selectIsLoading(state),
  isRowTableLayout: selectIsRowTableLayout(state),
  userType: selectUserType(state),
  cohortsCount: selectCohortsCount(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  actions: bindActionCreators({
    getCohorts,
    setDrawerBody,
    setIsDrawerOpen,
    getCohortsCount,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps);
