import { identity } from '../../../utils';

export const downloadFile = (documentUrl: string, contentType: string) => {
  fetch(documentUrl, {
    method: 'GET',
    headers: {
      'Content-Type': `application/${contentType}`,
    },
  })
    .then((response) => response.blob())
    .then((blob) => {
      // Create blob link to download
      const url = window.URL.createObjectURL(
        new Blob([blob]),
      );
      const link = document.createElement('a');
      link.href = url;
      const fileName = identity.isTruthyString(documentUrl)
        ? documentUrl.split('/').at(-1)?.replace(`.${contentType}`, '')
        : url.split('/').at(-1);
      link.setAttribute(
        'download',
        `${fileName}.${contentType}`,
      );

      // Start download
      link.click();
    });
}

export default null;
