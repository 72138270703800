import { UserRoleType } from 'types/auth';

type RoleKeys = 'employee' | 'startupFounder' | 'startupTeamMember' | 'serviceProvider' | 'superAdmin' | 'admin' | 'partner'

const ROLES: {[key in RoleKeys]: UserRoleType} = {
  employee: 'Employee',
  startupFounder: 'Startup founder',
  startupTeamMember: 'Startup team member',
  serviceProvider: 'Service provider',
  superAdmin: 'Super admin',
  admin: 'Admin',
  partner: 'Partner',
};

export default ROLES;
