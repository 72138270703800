import { DashboardStateType } from 'types/reduxTypes/StateTypes';

export const defaultDashboardState: DashboardStateType = {
  isLoading: false,
  events: [],
  requests: [],
  count: {
    startups: 0,
    events: 0,
    members: 0,
    requests: 0,
  },
}

export default null;
