import { connect } from 'react-redux';
import { bindActionCreators, Dispatch, Action } from 'redux';

import { StateType } from 'types';
import {
  selectCurrentServicesFilterTab,
  selectServiceAndSpActiveTab,
  selectServiceCategories,
  selectServiceSubCategories,
  selectServiceFilters,
  selectFiltersActive,
} from 'redux/selectors/services';
import {
  getServices,
  setActiveTab,
  setCurrentServicesTab,
  setFilters,
} from 'redux/actions/services';
import { selectUserType } from 'redux/selectors/auth';

const mapStateToProps = (state: StateType) => ({
  activeTab: selectServiceAndSpActiveTab(state),
  categories: selectServiceCategories(state),
  subCategories: selectServiceSubCategories(state),
  servicesFilterTab: selectCurrentServicesFilterTab(state),
  userType: selectUserType(state),
  filters: selectServiceFilters(state),
  filtersActive: selectFiltersActive(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  actions: bindActionCreators({
    setActiveTab,
    getServices,
    setCurrentServicesTab,
    setFilters,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps);
