import React, {
  RefObject, useCallback, useEffect, useRef, useState,
} from 'react';
import {
  Form, Button, Spin, InputRef,
} from 'antd';
import { Link } from 'react-router-dom';
import { Redirect } from 'react-router';

import { requiredValidation } from '../../utils/constants/validationRules';
import { LoginProps } from '../../types/auth/LoginProps';
import logo from '../../assets/images';
import Input from '../components/Input';
import routes from '../../routes';

import './Login.scss';

const Login = (props: LoginProps) => {
  const { isLoading, actions, isAuthenticated } = props;
  const usernameRef = useRef<InputRef>();

  const onFinish = useCallback(() => (data: object) => actions.login(data), []);
  const hub71_Images = [
    'https://www.hub71.com/storage/media/680/conversions/hompage-slide-image4-converted-converted-webp.webp?v=1',
    'https://www.hub71.com/storage/media/791/conversions/healthtech_icon_5-converted-webp.webp?v=1',
    'https://www.hub71.com/storage/media/677/conversions/2-converted-converted-webp.webp?v=1',
    'https://www.hub71.com/storage/media/679/conversions/hompage-slide-image2-converted-converted-webp.webp?v=1',
  ]
  const [hub71_image, setImage] = useState<number>(0)
  const goToNextSlide = () => {
    setImage((prevIndex) => (prevIndex === hub71_Images.length - 1 ? 0 : prevIndex + 1));
  };

  useEffect(() => {
    const timer = setInterval(goToNextSlide, 5000);

    return () => {
      clearInterval(timer);
    };
  }, [])

  useEffect(() => usernameRef.current?.focus(), []);

  if (isAuthenticated) {
    return <Redirect to={routes.dashboardUrl} />
  }

  return (
    <div className="login_page">
      <div className="form_area">
        <Spin spinning={isLoading}>
          <div className="login__container">
            <img src={logo} alt="hub71-logo" className="login__logo" />
            <Form
              name="basic"
              onFinish={onFinish()}
              autoComplete="off"
              validateTrigger="onSubmit"
            >
              <Input
                label="Username"
                name="username"
                labelCol={{ span: 24 }}
                requiredMark={false}
                rules={[requiredValidation]}
                layout="vertical"
              >
                <Input.InputField ref={usernameRef as RefObject<InputRef>} />
              </Input>
              <Input
                label="Password"
                name="password"
                labelCol={{ span: 24 }}
                rules={[requiredValidation]}
                layout="vertical"
              >
                <Input.InputField type="password" />
              </Input>
              <div className="login__reset-password">
                <Link className="login__reset-password__link" to={routes.resetPasswordUrl}>RESET PASSWORD?</Link>
              </div>
              <Form.Item>
                <Button type="primary" htmlType="submit" className="login__container__submit-button primary-button">
                  LOGIN
                </Button>
              </Form.Item>
            </Form>
          </div>
        </Spin>
      </div>
      <div className="image_area">
        <img
          alt="login"
          width={hub71_image === 3 ? 500 : 450}
          height={410}
          src={hub71_Images[hub71_image]}
        />
      </div>
    </div>
  );
}

export default Login;
