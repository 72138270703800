import { LabeledValue } from 'antd/lib/select';

import getLabelValueObject from 'utils/helpers/getLabelValueObject';
import { EVENT_FREQUENCY, EVENT_TYPE } from 'packages/events_repository';
import { inputTypes } from '../../../../components/FormBuilder/utils/constants';
import { getFormFieldBuilder } from '../../../../components/FormBuilder/utils/FormFieldBuilder';

export const getValues = (options: {[key: number]: string}): LabeledValue[] => {
  const values: LabeledValue[] = [];
  Object.values(options).forEach((label, value) => {
    if (label !== '-') {
      values.push({ label, value });
    }
  });
  return values;
}

export const categoriesOptions = [
  getLabelValueObject('Category 1', 'Category 1'),
  getLabelValueObject('Category 2', 'Category 2'),
  getLabelValueObject('Category 3', 'Category 3'),
  getLabelValueObject('Category 4', 'Category 4'),
];

export const eventFilterFields = [
  {
    fields: [
      getFormFieldBuilder('frequency', 'Frequency', inputTypes.checkboxGroup)
        .withOptions(getValues(EVENT_FREQUENCY))
        .build(),
    ],
  },
  {
    fields: [
      getFormFieldBuilder('type', 'Type', inputTypes.checkboxGroup)
        .withOptions(getValues(EVENT_TYPE))
        .build(),
    ],
  },
];

export const resetFiltersEvent = 'events-filters-reset';
