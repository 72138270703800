import React, { useEffect, useMemo } from 'react';
import { Button } from 'antd';

import { CloseIcon } from 'assets/icons';
import { CohortType } from 'types/cohorts';
import { StartupsDropdownFiltersProps } from 'types/startups';
import { identity } from 'utils';
import { Input } from 'components/components';
import { useStartupsFiltersHook, capitalRaisedOptions, startupStageOptions } from '../../utils';

const StartupsDropdownFilters = (props: StartupsDropdownFiltersProps) => {
  const {
    setShowDropdown,
    industriesOptions,
    stagesOptions,
    cohorts,
    filters,
  } = props;
  const {
    getStartupStages,
    getStartupSectors,
    getCohorts,
    setStartupFilters,
  } = props.actions;

  useEffect(() => {
    if (identity.isEmptyArray(industriesOptions)) {
      getStartupSectors();
    }
    if (identity.isEmptyArray(stagesOptions)) {
      getStartupStages();
    }
    if (identity.isEmptyArray(cohorts)) {
      getCohorts();
    }
  }, []);

  const {
    state,
    initialState,
    resetFilters,
    setIndustries,
    setCohorts,
    setStage,
    setCapitalRaised,
    setIncentiveAmount,
    setFilters,
  } = useStartupsFiltersHook();

  useEffect(() => setFilters(filters), [filters]);

  const {
    industries,
    cohorts: cohortIDs,
    capitalRaised,
    incentiveAmount,
    stage,
  } = state;

  const onClose = () => {
    setFilters(filters);
    setShowDropdown(false);
  }

  const onClearAll = () => {
    resetFilters();
    setStartupFilters(initialState);
    onClose();
  };

  const onFilter = () => {
    setStartupFilters(state);
    onClose();
  };

  const cohortOptions = useMemo(() => cohorts.map(
    ({ name: label, id: value }: CohortType) => ({ label, value }),
  ), [cohorts]);

  const stageOptions = useMemo(() => startupStageOptions(stagesOptions), [stagesOptions])

  return (
    <div className="filters-dropdown">
      <div className="filters-dropdown__title-container">
        <h3 className="filters-dropdown__title">Filters</h3>
        <Button className="filters-buttons--button" onClick={onClose}><CloseIcon /></Button>
      </div>
      <Input label="Primary sector" layout="vertical">
        <Input.Select
          showSearch
          options={industriesOptions}
          value={industries}
          mode="multiple"
          placeholder="Select service providers"
          maxTagCount="responsive"
          onChange={(data) => setIndustries({ data })}
        />
      </Input>
      <div className="filters-dropdown--divider" />
      <Input label="Cohorts" layout="vertical">
        <Input.Select
          showSearch
          options={cohortOptions}
          value={cohortIDs}
          mode="multiple"
          placeholder="Select service providers"
          maxTagCount="responsive"
          onChange={(data) => setCohorts({ data })}
        />
      </Input>
      <div className="filters-dropdown--divider" />
      <Input label="Startup stage" layout="vertical">
        <Input.Radio
          values={stageOptions}
          value={stage}
          onChange={({ target: { value: data } }) => setStage({ data })}
        />
      </Input>
      <div className="filters-dropdown--divider" />
      <Input label="Capital raised" layout="vertical">
        <Input.Radio
          values={capitalRaisedOptions}
          value={capitalRaised}
          onChange={({ target: { value: data } }) => setCapitalRaised({ data })}
        />
      </Input>
      <div className="filters-dropdown--divider" />
      <Input label="Incentive amount raised" layout="vertical">
        <Input.Radio
          values={capitalRaisedOptions}
          value={incentiveAmount}
          onChange={({ target: { value: data } }) => setIncentiveAmount({ data })}
        />
      </Input>
      <div className="filters-buttons">
        <Button className="secondary-button" onClick={onClearAll}>Clear all</Button>
        <Button className="primary-button" onClick={onFilter}>Apply</Button>
      </div>
    </div>
  );
}

export default StartupsDropdownFilters;
