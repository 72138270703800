import React, { useEffect, useState } from 'react';
import { Drawer as AntDrawer, Spin } from 'antd';
import classNames from 'classnames';

import { DrawerProps } from 'types/componentTypes';
import drawersMap from 'utils/constants/drawersMap';
import { identity } from 'utils';
import { CloseIcon } from 'assets/icons';

import './Drawer.scss';

const Drawer = (props: DrawerProps) => {
  const {
    isLoading,
    isOpen,
    isFilterTableOpen,
    drawerBody,
    showInvoiceFields,
  } = props;

  const { setIsDrawerOpen, setShowInvoiceFields } = props.actions;

  const [drawerRef, setDrawerRef] = useState<Element | null>(null);

  useEffect(() => {
    const shouldGetElement = identity.isFalsy(drawerRef) && identity.isTruthyString(drawerBody);
    if (shouldGetElement) {
      setDrawerRef(document.querySelector('.ant-drawer-body'));
    }
  }, [drawerBody]);

  if (identity.isEmptyString(drawerBody)) {
    return null;
  }

  const onClose = () => {
    setIsDrawerOpen({ isDrawerOpen: false });
    if (showInvoiceFields) {
      setShowInvoiceFields({ data: false });
    }
    drawerRef?.scroll(0, 0);
  }

  const Body = drawersMap[drawerBody]?.body;
  const Title = drawersMap[drawerBody]?.title;
  const titleClassName = drawersMap[drawerBody]?.titleClassName;

  return (
    <AntDrawer
      title={Title !== undefined ? <Title /> : drawerBody}
      placement="right"
      destroyOnClose
      closable
      className={classNames('drawer', { 'drawer--hidden': isFilterTableOpen, [titleClassName as string]: identity.isTruthyString(titleClassName) })}
      onClose={onClose}
      open={isOpen}
      closeIcon={<CloseIcon />}
    >
      <Spin spinning={isLoading}>
        <Body />
      </Spin>
    </AntDrawer>
  )
}

export default Drawer;
