import React from 'react';

import { EventIcon, PeopleIcon, ServicesIcon } from '../../../../assets/icons';
import routes from '../../../../routes';

export const CARD_TYPES = {
  serviceProviders: 'service providers',
  upcomingEvents: 'upcoming events',
  members: 'members',
  founders: 'founders',
};

export const cardTypesMap = {
  [CARD_TYPES.serviceProviders]: {
    icon: <ServicesIcon />,
    path: routes.serviceProvidersUrl,
  },
  [CARD_TYPES.upcomingEvents]: {
    icon: <EventIcon />,
    color: 'green',
    path: routes.eventsUrl,
  },
  [CARD_TYPES.members]: {
    icon: <PeopleIcon />,
    color: 'purple',
    path: routes.teamMembersUrl,
  },
  [CARD_TYPES.founders]: {
    icon: <PeopleIcon />,
    color: 'blue',
    path: '',
  },
}
