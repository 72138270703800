import React, { useEffect, useMemo } from 'react';

import routes from 'routes';
import { ProfileOverview, TitleWithActions } from 'components/components';
import { identity, isEitherAdmin, isPartner } from 'utils';
import { LabeledValue } from 'types';
import { SPPartnerUserProps } from 'types/user';

const SPPartnerUser = (props: SPPartnerUserProps) => {
  const {
    firstName,
    lastName,
    photo,
    id,
    email,
    phone,
    jobTitle,
    partner,
    mobile,
    serviceProvider,
  } = props.user;

  useEffect(() => {
    if (!identity.isPositiveNumber(id)) {
      props.actions.getMyProfileData();
    }
  }, [id]);

  const rightContent: LabeledValue[] = useMemo(() => {
    const content: LabeledValue[] = [{ label: 'Email', value: email }];
    if (!isEitherAdmin(props.userType)) {
      content.push({ label: 'Phone', value: phone });
      content.push({ label: 'Job title', value: jobTitle });
      content.push({ label: 'Mobile', value: mobile });
    }
    return content;
  }, [props.userType]);

  if (!identity.isPositiveNumber(id)) {
    return null;
  }

  return (
    <div className="my-profile">
      <TitleWithActions
        title="My profile"
        link={routes.editMyProfileUrl}
        buttonTitle="profile"
        isEditButton
        showButton
      />
      <ProfileOverview
        heading={`${firstName} ${lastName}`}
        imageUrl={photo}
        rightContent={rightContent}
        subheading={isEitherAdmin(props.userType) ? 'Admin' : `${isPartner(props.userType) ? partner.name : serviceProvider.name} member`}
      />
    </div>
  );
}

export default SPPartnerUser;
