import { connect } from 'react-redux';

import { StateType } from 'types';
import { selectFiltersActive } from 'redux/selectors/services';

const mapStateToProps = (state: StateType) => ({
  filtersActive: selectFiltersActive(state),
});

export default connect(mapStateToProps);
