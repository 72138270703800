import { AxiosRequestConfig } from 'axios'

import { identity } from 'utils'
import httpClient, {
  ApiResponseType,
  DeleteResourceSuccessResponseType,
  FORM_DATA_CONFIG,
  ParamsPayload,
  queryBuilder,
  ResponseType,
} from '../http_client'
import {
  convertCreateEventAttendeeRequestPayload,
  convertCreateEventRequestPayload,
  convertCreateEventRSVPPayload,
  convertEditEventRequestPayload,
  convertEditEventRSVPPayload,
  convertEvent,
  convertEventAttendeeList,
  convertEventCategories,
  convertEventRSVPResponse,
  convertEventRSVPToEvent,
  convertEvents,
  convertEventsCountResponse,
  convertEventsRSVPToEvents,
  eventRSVPQuery,
  EVENTS_API,
  EVENTS_ATTENDEE_API,
  EVENTS_CATEGORY,
  EVENTS_RSVP_API,
} from './utils'
import {
  CreateEventApiResponseType,
  CreateEventAttendeeListApiResponseType,
  CreateEventAttendeePayload,
  CreateEventPayload,
  CreateEventRSVPApiResponseType,
  CreateEventRSVPPayload,
  CreateEventRSVPRequestPayload,
  EventAttendeeType,
  EventCategory,
  EventRSVPBeType,
  EventRSVPType,
  EventType,
  GetEventAttendessListResponse,
  GetEventResponse,
  GetEventsCountApiResponse,
  GetEventsCountResponse,
  GetEventsResponse,
  GetEventsRSVPApiResponse,
  UpdateEventApiResponse,
  UpdateEventPayload,
  UpdateEventRequestPayload,
} from './types'

const getEventsApi = (query?: ParamsPayload) => {
  const url = EVENTS_API;
  const params = queryBuilder({ status: query?.eventStatus })
    .limit(query?.limit)
    .offset(query?.offset)
    .in({ frequency: query?.frequency as number[], category_id: query?.categories, type: query?.type as number[] })
    .contains('starting_time', query?.startingTime as string)
    .contains('ending_time', query?.endingTime as string)
    .preload()
    .toObject();
  const config = { params };
  return httpClient.get<ApiResponseType<GetEventsResponse>>(url, config);
}

const getEventsCategoriesApi = () => httpClient.get<ApiResponseType<GetEventsResponse>>(EVENTS_CATEGORY);

const getRSVPEventByIdApi = (id: number) => {
  const url = `${EVENTS_RSVP_API}/${id}`;
  const params = queryBuilder({}).preload().toObject();
  const config = { params };
  return httpClient.get<ApiResponseType<GetEventResponse>>(url, config);
}

const getEventByIdApi = (id: number) => {
  const url = `${EVENTS_API}/${id}`;
  const params = queryBuilder({}).preload().toObject();
  const config = { params };
  return httpClient.get<ApiResponseType<GetEventResponse>>(url, config);
}

const getEventsCountApi = (query?: ParamsPayload) => {
  const url = EVENTS_API;
  const params = queryBuilder({ status: query?.eventStatus })
    .in({ frequency: query?.frequency as number[], category_id: query?.categories, type: query?.type as number[] })
    .contains('starting_time', query?.startingTime as string)
    .contains('ending_time', query?.endingTime as string);
  if (identity.isFullArray(query?.fieldSelection)) {
    params
      .select([{ field: 'id', aggregation: 'count' }, ...query?.fieldSelection as string[]])
      .groupBy('status');
  } else {
    params.select([{ field: 'id', aggregation: 'count' }]);
  }
  const config = { params: params.toObject() };
  return httpClient.get<ApiResponseType<GetEventsCountApiResponse>>(url, config);
}

const getEventsRSVPApi = (query?: ParamsPayload) => {
  const url = EVENTS_RSVP_API;
  const config = { params: eventRSVPQuery(query) };
  return httpClient.get<ApiResponseType<GetEventsRSVPApiResponse>>(url, config);
}

const getEventRSVPApi = (query?: ParamsPayload) => {
  const url = EVENTS_RSVP_API;
  const qParams = queryBuilder({ user_id: query?.userId, event_id: query?.eventId })
  const config = { params: qParams.preload().toObject() };
  return httpClient.get<ApiResponseType<GetEventsRSVPApiResponse>>(url, config);
}

const updateEventRSVPApi = (id: number, query?: ParamsPayload) => {
  const url = `${EVENTS_RSVP_API}/${id}`;
  const qParams = queryBuilder({ user_id: query?.userId, event_id: query?.eventId })
  const config = { params: qParams.toObject() };
  return httpClient.put<ApiResponseType<GetEventsRSVPApiResponse>>(url, config);
}

const getEventsRSVPCountApi = (query?: ParamsPayload) => {
  const url = EVENTS_RSVP_API;
  const config = { params: eventRSVPQuery(query, true) };
  return httpClient.get<ApiResponseType<GetEventsCountApiResponse>>(url, config);
}

const createEventApi = (payload: CreateEventPayload) => {
  const url = EVENTS_API;
  const requestPayload = convertCreateEventRequestPayload(payload);
  return httpClient.post<ApiResponseType<CreateEventApiResponseType>>(url, requestPayload, FORM_DATA_CONFIG);
};

const addEventAttendeeApi = (payload: CreateEventAttendeePayload) => {
  const url = EVENTS_ATTENDEE_API;
  const requestPayload = convertCreateEventAttendeeRequestPayload(payload);
  return httpClient.post<
    ApiResponseType<CreateEventAttendeeListApiResponseType>>(url, requestPayload);
}

const createEventRSVPApi = (payload: CreateEventRSVPRequestPayload) => {
  const url = EVENTS_RSVP_API;
  const requestPayload = convertCreateEventRSVPPayload(payload);
  return httpClient.post<ApiResponseType<CreateEventRSVPApiResponseType>>(url, requestPayload, FORM_DATA_CONFIG);
}

const deleteEventByIdApi = (id: number) => {
  const url = `${EVENTS_API}/${id}`;
  return httpClient.delete<ApiResponseType<DeleteResourceSuccessResponseType>>(url);
}

const getListOfAttendeesByEventIdApi = (id: number) => {
  const url = `${EVENTS_ATTENDEE_API}`;
  const config: AxiosRequestConfig = {
    params: {
      event_id: id,
      $preload: '1',
    },
  };
  return httpClient.get<ApiResponseType<GetEventAttendessListResponse>>(url, config);
}

const updateEventByIdApi = (id: number, payload: UpdateEventRequestPayload) => {
  const url = `${EVENTS_API}/${id}`;
  return httpClient.put<ApiResponseType<UpdateEventApiResponse>>(url, payload);
};

const updateEventRSVPByIdApi = (id: number, payload: CreateEventRSVPPayload) => {
  const url = `${EVENTS_RSVP_API}/${id}`;
  return httpClient.put<ApiResponseType<UpdateEventApiResponse>>(url, payload);
};

export const getEvents = async (query?: ParamsPayload): Promise<ResponseType<EventType[]>> => {
  const response = await getEventsApi(query);
  const { error, httpStatus, data } = response;
  return { error, httpStatus, data: convertEvents(data?.result) };
}

export const getEventsCategories = async (): Promise<ResponseType<EventCategory[]>> => {
  const response = await getEventsCategoriesApi();
  const { error, httpStatus, data } = response;
  return { error, httpStatus, data: convertEventCategories(data?.result) };
}

export const getEventById = async (id: number): Promise<ResponseType<EventType>> => {
  const response = await getEventByIdApi(id);
  const { error, httpStatus, data } = response;
  return { error, httpStatus, data: convertEvent(data?.result) };
}

export const getRSVPEventById = async (id: number): Promise<ResponseType<EventType>> => {
  const response = await getRSVPEventByIdApi(id);
  const { error, httpStatus, data } = response;
  return { error, httpStatus, data: convertEventRSVPToEvent(data?.result) };
}

export const getEventsCount = async (type: 'all' | 'status' | 'response', query?: ParamsPayload): Promise<GetEventsCountResponse> => {
  const response = await getEventsCountApi(query);
  const { error, httpStatus, data } = response;
  return { error, httpStatus, data: convertEventsCountResponse(type, data?.result) };
}

export const getEventsForUser = async (query?: ParamsPayload): Promise<ResponseType<EventType[]>> => {
  const response = await getEventsRSVPApi(query);
  const { error, httpStatus, data } = response;
  return { error, httpStatus, data: convertEventsRSVPToEvents(data?.result) };
}

export const getEventRSVP = async (query?: ParamsPayload): Promise<ResponseType<EventRSVPType>> => {
  const response = await getEventRSVPApi(query);
  const { error, httpStatus, data } = response;
  return { error, httpStatus, data: convertEventRSVPResponse(data?.result![0]) };
}

export const updateEventRSVP = async (id: number, query?: ParamsPayload): Promise<ResponseType<EventRSVPType>> => {
  const response = await updateEventRSVPApi(id, query);
  const { error, httpStatus, data } = response;
  return { error, httpStatus, data: convertEventRSVPResponse(data?.result![0]) };
}

export const getEventsRSVPCount = async (type: 'all' | 'status' | 'response', query?: ParamsPayload): Promise<GetEventsCountResponse> => {
  const response = await getEventsRSVPCountApi(query);
  const { error, httpStatus, data } = response;
  return { error, httpStatus, data: convertEventsCountResponse(type, data?.result) };
}

export const createEvent = async (payload: CreateEventPayload) => {
  const response = await createEventApi(payload);
  const { data, error, httpStatus } = response;
  return { error, httpStatus, data: data?.id[0] };
}

export const createEventAttendeeList = async (payload: CreateEventAttendeePayload) => {
  const response = await addEventAttendeeApi(payload);
  const { data, error, httpStatus } = response;
  return { error, httpStatus, data: data?.id[0] };
}

export const createEventRSVP = async (payload: CreateEventRSVPRequestPayload) => {
  const response = await createEventRSVPApi(payload);
  const { data, error, httpStatus } = response;
  return { error, httpStatus, data: data?.id[0] };
}

export const deleteEventById = async (id: number) => {
  const response = await deleteEventByIdApi(id);
  const { error, httpStatus } = response;
  return { error, httpStatus };
}

export const getEventAttendessList = async (id: number): Promise<ResponseType<EventAttendeeType[]>> => {
  const response = await getListOfAttendeesByEventIdApi(id);
  const { error, httpStatus, data } = response;
  return { error, httpStatus, data: convertEventAttendeeList(data?.result) };
}

export const updateEventById = async (id: number, payload: UpdateEventPayload) => {
  const response = await updateEventByIdApi(id, convertEditEventRequestPayload(payload));
  const { error, httpStatus } = response;
  return { error, httpStatus };
}

export const updateEventRSVPById = async (id: number, payload: EventRSVPBeType) => {
  const response = await updateEventRSVPByIdApi(id, convertEditEventRSVPPayload(payload));
  const { error, httpStatus } = response;
  return { error, httpStatus };
}
