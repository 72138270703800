import React from 'react'

import { ServicesFormProps } from 'types/serviceProviders/addServiceProvider';
import { FormBuilder } from 'components/components';
import { addFields, defaultValues } from '../../utils/constants';

const ServicesForm = (props: ServicesFormProps) => {
  const {
    serviceType, handleLogoUpload, handleServiceDocUpload, handleAgreementDocUpload,
  } = props;

  return (
    <FormBuilder
      formFragment
      fields={addFields[serviceType]({ handleLogoUpload, handleServiceDocUpload, handleAgreementDocUpload })}
      defaultValues={defaultValues[serviceType]}
    />
  )
}

export default ServicesForm;
