import React, { useEffect } from 'react'
import { Spin } from 'antd';

import { PerformanceDrawersProps } from 'types/performance'
import PerformanceDetails from '../components/PerformanceDetails'

const PerformanceViewDetailsDrawer = (props: PerformanceDrawersProps) => {
  const {
    id,
    performanceReport,
    isLoading,
    actions,
  } = props;

  useEffect(() => {
    actions.getPerformanceReportById({ id })
  }, [id])

  return (
    <Spin spinning={isLoading}>
      <PerformanceDetails performanceReport={performanceReport} />
    </Spin>
  )
}

export default PerformanceViewDetailsDrawer
