import React, { useEffect, useMemo, useState } from 'react';
import { Button, Tabs } from 'antd';

import history from 'history_instance';
import routes from 'routes';
import { GetPerformanceColumnsType, PerformanceProps } from 'types/performance';
import { AddIcon } from 'assets/icons';
import { DRAWERS, identity, isStartup } from 'utils';
import { getPerformanceColumns } from 'components/utils/constants/columns';
import { GetPerformanceParams, REPORT_STATUS_ORIGINAL_NAMES, REPORT_STATUS_TO_NUMBER } from 'packages/performance_repository';
import {
  RenderIf,
  NoData,
  Input,
  Row,
  CustomPagination,
  RowTable,
} from 'components/components';
import {
  performanceYearOptions,
  quarterOptions,
  staticParams,
  TABS_BY_ROLE,
} from './utils/constants';
import { getStatusInParamsByRole, getTabWithCountByUserType } from './utils/helpers';

import './Performance.scss';

const Performance = (props: PerformanceProps) => {
  const {
    isLoading,
    performanceReports,
    actions,
    limit,
    offset,
    activeTab,
    user,
    counts,
  } = props;

  const [shouldUpdateCount, setShouldUpdateCount] = useState(true);
  const [quarters, setQuarters] = useState<string | undefined>();
  const [year, setYear] = useState<number | undefined>();

  useEffect(() => {
    const params: GetPerformanceParams = {
      ...staticParams,
      quarters,
      year,
      $limit: limit,
      $offset: offset,
      status: REPORT_STATUS_TO_NUMBER[activeTab!],
      status__in: getStatusInParamsByRole(user.userType),
      startup_id: Number(user.startupId) || undefined,
    };
    actions.getPerformanceReports(params);
  }, [limit, offset, activeTab, quarters, year]);

  useEffect(() => {
    const params: GetPerformanceParams = {
      startup_id: Number(user.startupId) || undefined,
      status__in: getStatusInParamsByRole(user.userType),
      quarters,
      year,
    }
    actions.getCounts(params);
  }, [performanceReports]);

  useEffect(() => {
    if (shouldUpdateCount) {
      const params: GetPerformanceParams = {
        startup_id: Number(user.startupId) || undefined,
        status__in: getStatusInParamsByRole(user.userType),
      }

      actions.getCounts(params);
      setShouldUpdateCount(false);
    }
  }, [shouldUpdateCount]);

  const onTabChange = (data: string) => {
    actions.setActiveTab({ data });
    actions.setOffset({ data: 0 });
  }

  const setCurrentPage = (page: number) => {
    actions.setOffset({ data: (page - 1) * limit })
  }

  const setLimit = (data: number) => {
    actions.setLimit({ data })
  }

  const onDelete = (id: number) => {
    actions.deletePerformanceReportById({ id });
    setShouldUpdateCount(true);
  };

  const onQuarterChange = (value: string) => {
    actions.setOffset({ data: 0 });
    if (value === 'All') {
      setQuarters(undefined)
    } else {
      setQuarters(value);
    }
  }

  const onYearChange = (value: number) => {
    actions.setOffset({ data: 0 });
    if (value === 0) {
      setYear(undefined)
    } else {
      setYear(value);
    }
  }

  // TODO
  const onUpdate = () => {};

  const onReview = (id: number) => {
    actions.setDrawerBody({ drawerBody: DRAWERS.performanceReviewDrawer })
    actions.setIsDrawerOpen({ isDrawerOpen: true });
    actions.setPerformanceReportId({ id });
  };

  const onView = (id: number) => {
    actions.setDrawerBody({ drawerBody: DRAWERS.performanceViewDrawer })
    actions.setIsDrawerOpen({ isDrawerOpen: true });
    actions.setPerformanceReportId({ id });
  };

  const getColumnsArgs: GetPerformanceColumnsType = {
    userType: user.userType,
    onDelete,
    onUpdate,
    onReview,
    onView,
  }

  const tabs = useMemo(() => TABS_BY_ROLE[user.userType], [user.userType]);

  return (
    <div className="content-container">
      <div className="performance">
        <div className="performance__header">
          <Tabs tabBarGutter={0} activeKey={activeTab} onChange={onTabChange}>
            {tabs.map((tab) => (
              <Tabs.TabPane tab={getTabWithCountByUserType(counts, tab, user.userType)} key={tab} />
            ))}
          </Tabs>
          <div className="performance__actions">
            <Row>
              <Input name="year" layout="vertical">
                <Input.Select options={performanceYearOptions} onChange={onYearChange} placeholder="Year" />
              </Input>
              <Input name="quarter" layout="vertical">
                <Input.Select options={quarterOptions} onChange={onQuarterChange} placeholder="Quarter" />
              </Input>
              <RenderIf condition={isStartup(user.userType)}>
                <Button
                  className="secondary-button"
                  icon={<AddIcon />}
                  onClick={() => history.push(routes.createPerformanceReportUrl)}
                >
                  New report
                </Button>
              </RenderIf>
            </Row>
          </div>
        </div>
        {identity.isFullArray(performanceReports)
          ? (
            <RowTable
              isLoading={isLoading}
              className="table"
              dataSource={performanceReports}
              columns={getPerformanceColumns(getColumnsArgs)}
              rowKey={(record) => record.id}
            />
          )
          : <NoData text="No Performance reports submitted yet." />}
        <CustomPagination
          currentPage={(offset / limit) + 1}
          limit={limit}
          total={counts[REPORT_STATUS_ORIGINAL_NAMES[activeTab!] || activeTab!]}
          setCurrentPage={setCurrentPage}
          setLimit={setLimit}
        />
      </div>
    </div>
  )
}

export default Performance
