import React from 'react';
import classnames from 'classnames';
import { Button } from 'antd';

import { identity } from 'utils';
import { StaticNotificationProps } from 'types/componentTypes';
import NotificationIcon from '../components/NotificationIcon';
import RenderIf from '../../RenderIf';

import './StaticNotification.scss';
import '../Notification.scss';

const StaticNotification = (props: StaticNotificationProps) => {
  const {
    type, message, primaryButton, secondaryButton, description,
  } = props;

  return (
    <div className={classnames('static-notification', `notification-${type}`, { 'static-notification--with-description': identity.isTruthyString(description) })}>
      <div className="static-notification__container">
        <div className="static-notification__border" />
        <div className="static-notification__message-container">
          <NotificationIcon type={type} />
          <div className="static-notification__message-container-right">
            <span className="static-notification__message">{message}</span>
            <span className="static-notification__description">{description}</span>
          </div>
        </div>
      </div>
      <div className="notification-buttons">
        <RenderIf condition={!identity.isFalsy(primaryButton)}>
          <Button className="notification-primary-button" onClick={primaryButton?.onClick}>{primaryButton?.text}</Button>
        </RenderIf>
        <RenderIf condition={!identity.isFalsy(secondaryButton)}>
          <Button className="notification-secondary-button" onClick={secondaryButton?.onClick}>{secondaryButton?.text}</Button>
        </RenderIf>
      </div>
    </div>
  );
}

StaticNotification.defaultProps = {
  primaryButton: undefined,
  secondaryButton: undefined,
  description: undefined,
}

export default StaticNotification;
