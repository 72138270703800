import React, { useMemo } from 'react';
import classnames from 'classnames';

import { RenderIf, Text } from 'components/components';
import { STATUS_COLOR_CLASS } from 'components/RequestsOverview/components/RequestDetailsDrawer/utils/constants';
import { identity } from 'utils';
import { REQUEST_STATUS } from 'utils/constants/requestStatusTypes';
import { AcceptanceLetterADGMProps } from 'types/cohorts';
import DocumentRequestRowButton from './components/DocumentRequestRowButton';
import { getDocumentNameFromType, getDocumentType } from '../../utils/helpers';

import './RequestRow.scss';

const RequestRow = (props: AcceptanceLetterADGMProps) => {
  const { id, name } = props.startup;

  const documentType = useMemo(() => getDocumentType(props.startup), [props.startup]);
  const documentName = useMemo(() => getDocumentNameFromType(documentType), [documentType]);
  const startupName = useMemo(() => name || 'Startup', [name]);

  return (
    <RenderIf condition={identity.isTruthyNumber(id)}>
      <div className={classnames('request-row', { 'highlighted-row': props.highlight })}>
        <div className="request-row__left-container">
          <div className="request-row__status-container">
            <p className={classnames('request-row__status', STATUS_COLOR_CLASS()[REQUEST_STATUS.pendingQuotation])}>Pending</p>
          </div>
          <div className="request-row__request-info">
            <Text text={startupName} size="xs" />
            <div className="request-row__request-info">
              <div className={classnames('request-row__request-info--row')}>
                <span className="request-row__request-info--row__details">
                  {`${startupName} has submitted the ${documentName}`}
                </span>
              </div>
            </div>
          </div>
        </div>
        <DocumentRequestRowButton documentType={documentType} startup={props.startup} />
      </div>
    </RenderIf>
  );
}

export default RequestRow;
