import React, { useCallback, useEffect, useState } from 'react';
import { Button, Form } from 'antd';

import { FormBuilder, RenderIf } from 'components/components';
import history from 'history_instance';
import { StepCustomFieldComponent, StepsFormProps } from 'types/componentTypes/StepsForm';
import { identity } from 'utils';
import Steps from './components/Steps';
import { scrollToCurrentStep, scrollToTopOfTheForm } from '../../Performance/components/CreatePerformanceReport/utils/helpers';
import DiscardModal from './components/DiscardModal';

import './StepsForm.scss';

const StepsForm = (props: StepsFormProps) => {
  const [step, setStep] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [form] = Form.useForm();

  const {
    steps,
    backButtonText,
    onFinish,
    submitButtonText,
    modalConfig,
    defaultValues,
    backButtonPath,
  } = props;

  const currentStep = steps[step];
  const isFirstStep = identity.isZero(step);
  const isLastStep = step === steps.length - 1;

  useEffect(() => {
    form.setFieldsValue(defaultValues);
  }, [defaultValues]);

  const scrollForm = () => {
    scrollToTopOfTheForm();
    setTimeout(scrollToCurrentStep);
  }

  const onBack = () => {
    setStep((prev) => prev - 1);
    scrollForm();
  };

  const onSubmit = (data: unknown) => {
    form.setFieldsValue(data);

    if (isLastStep) {
      onFinish(form.getFieldsValue(true));
      return;
    }
    setStep((prev) => prev + 1);
    scrollForm();
  }

  const onConfirm = useCallback(() => {
    modalConfig?.onDiscard!(form.getFieldsValue(true));
    setIsModalOpen(false);
  }, []);

  const onClose = useCallback(() => setIsModalOpen(false), []);

  const onCancel = useCallback(() => history.push(backButtonPath), []);

  const onBackLink = useCallback(identity.isObjWithChildren(modalConfig)
    ? () => setIsModalOpen(true)
    : onCancel, []);

  const CustomFieldsComponent = currentStep.customFieldsComponent as StepCustomFieldComponent;

  return (
    <div className="steps-form">
      <Steps
        selected={step}
        steps={steps}
        backButtonText={backButtonText}
        onBack={onBackLink}
      />
      <div className="steps-form__fields">
        <h1 className="steps-form__title">{currentStep.title}</h1>
        <Form
          className="form-standalone"
          form={form}
          onFinish={onSubmit}
        >
          {identity.isObjWithChildren(currentStep.stepFields)
            ? (
              <FormBuilder
                fields={currentStep.stepFields!}
                formFragment
                oneColumn
              />
            )
            : <CustomFieldsComponent form={form} />}
          <div className="steps-form__buttons">
            <RenderIf condition={!isFirstStep}>
              <Button className="secondary-button" onClick={onBack}>Back</Button>
            </RenderIf>
            <Button className="primary-button" htmlType="submit">
              {isLastStep ? submitButtonText : 'Continue'}
            </Button>
          </div>
        </Form>
      </div>
      <RenderIf condition={identity.isObjWithChildren(modalConfig)}>
        <DiscardModal
          isOpen={isModalOpen}
          okButtonText={modalConfig?.okButtonText!}
          cancelButtonText={modalConfig?.cancelButtonText!}
          modalTitle={modalConfig?.modalTitle!}
          onDiscard={onConfirm}
          onCancel={onCancel}
          text={modalConfig?.text!}
          onClose={onClose}
        />
      </RenderIf>
    </div>
  );
}

export default StepsForm;
