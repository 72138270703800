import React from 'react';
import { Button } from 'antd';
import classnames from 'classnames';

import { SubCategoryTabButtonProps } from 'types/services';

import '../ServiceSubCategoriesTabs.scss';

const SubCategoryTabButton = (props: SubCategoryTabButtonProps) => {
  const {
    servicesSubCategoryFilterTab,
    value,
    children,
  } = props;
  const { setCurrentSubCategoryTab } = props.actions;

  return (
    <Button
      className={classnames('subcategories-overview-tabs--tab', { 'active-tab': servicesSubCategoryFilterTab === value })}
      onClick={() => setCurrentSubCategoryTab({ servicesSubCategoryFilterTab: value })}
    >
      <p className="subcategories-overview-tabs--tab-text">
        {children}
      </p>
    </Button>
  );
}

SubCategoryTabButton.defaultProps = {
  servicesSubCategoryFilterTab: 'All',
}

export default SubCategoryTabButton;
