import React from 'react';
import { Modal } from 'antd';

import './DateValidationModal.scss';

type DateValidationModalProps = {
  date: string,
  open: boolean,
  onCancel: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void,
  onOk: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void,
}

const DateValidationModal = (props: DateValidationModalProps) => {
  const {
    date,
    open,
    onCancel,
    onOk,
  } = props;

  return (
    <Modal
      className="date-validation-modal"
      open={open}
      onCancel={onCancel}
      onOk={onOk}
      okText="Yes, it's correct"
      cancelText="No"
    >
      <div>
        <span>This person is not </span>
        <b>18 years old</b>
        <span>. Is this correct?</span>
      </div>
      <div>
        <span>Chosen date: </span>
        <b>{date}</b>
      </div>
    </Modal>
  );
}

export default DateValidationModal;
