import { connect } from 'react-redux';
import { Action, bindActionCreators, Dispatch } from 'redux';

import { StateType } from 'types';
import { selectServiceCategories } from 'redux/selectors/services';
import {
  getServiceCategories,
  updateServiceCategoryById,
  deleteServiceCategoryById,
  setServiceCategoryDetails,
  setIsMainCategory,
  setCategoryID,
} from 'redux/actions/services';
import { setDrawerBody, setIsDrawerOpen } from 'redux/actions/workspace';

const mapStateToProps = (state: StateType) => ({
  categories: selectServiceCategories(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  actions: bindActionCreators({
    getServiceCategories,
    updateServiceCategoryById,
    deleteServiceCategoryById,
    setDrawerBody,
    setIsDrawerOpen,
    setServiceCategoryDetails,
    setIsMainCategory,
    setCategoryID,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps);
