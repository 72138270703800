import identity from 'utils/identity';
import { removeUserFromLocal } from './user_storage';

const accessTokenKey = 'access_token';
const sessionIdKey = 'session_id';

export const getUserTokens = () => {
  const accessToken = localStorage.getItem(accessTokenKey);
  const sessionId = localStorage.getItem(sessionIdKey);

  return { accessToken, sessionId };
};

export const clearUserTokens = () => {
  localStorage.removeItem(accessTokenKey);
  localStorage.removeItem(sessionIdKey);
};

export const clearUserSession = () => {
  clearUserTokens();
  removeUserFromLocal();
  window.location.reload();
};

export const getRefreshedTokens = () => {
  const { accessToken, sessionId } = getUserTokens();
  if (identity.isEmptyString(accessToken) || identity.isEmptyString(sessionId)) {
    clearUserSession();
  }

  return { accessToken, sessionId };
};

export const saveUserTokens = (accessToken: string, sessionId: string) => {
  localStorage.setItem(accessTokenKey, accessToken);
  localStorage.setItem(sessionIdKey, sessionId);
};
