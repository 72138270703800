import React from 'react'

import { ServiceProviderChildFormProps } from '../../../../types/serviceProviders/editServiceProvider';
import FormBuilder from '../../../components/FormBuilder'
import { editPerksFields } from '../../utils/constants'

const ServiceProviderPerksForm = (props: ServiceProviderChildFormProps) => {
  const { userType } = props;

  return (
    <FormBuilder
      oneColumn
      formFragment
      layout="horizontal"
      fields={editPerksFields(userType)}
    />
  )
}

export default ServiceProviderPerksForm
