import React from 'react';

import {
  ListIcon,
  InsuranceIconV2,
  HousingIconV2,
  AdvisoryIcon,
  PayrollIcon,
  CloudServicesIcon,
  AdvertisementIcon,
  LegalIcon,
  OfficeSpaceIcon,
  RecruitmentIcon,
  DigitalMarketingIcon,
  DesignIconV2,
  IncorporationIcon,
  AuditAndAccountingIcon,
  LeadershipIcon,
} from 'assets/icons';
import { FilterIconType } from 'types/services';

export const categoriesIconsVol2: FilterIconType[] = [
  {
    id: 0,
    key: 'list-all',
    names: ['all'],
    icon: <ListIcon />,
  },
  {
    id: 1,
    key: 'office-space',
    names: ['office space'],
    icon: <OfficeSpaceIcon />,
  },
  {
    id: 2,
    key: 'housing',
    names: ['housing'],
    icon: <HousingIconV2 />,
  },
  {
    id: 3,
    key: 'insurance',
    names: ['insurance'],
    icon: <InsuranceIconV2 />,
  },
  {
    id: 4,
    key: 'audit-and-accounting',
    names: ['audit & accounting'],
    icon: <AuditAndAccountingIcon />,
  },
  {
    id: 5,
    key: 'incorporation-and-pro',
    names: ['incorporation & pro', 'incorporation'],
    icon: <IncorporationIcon />,
  },
  {
    id: 6,
    key: 'leadership-coaching',
    names: ['leadership coaching'],
    icon: <LeadershipIcon />,
  },
  {
    id: 7,
    key: 'ui-ux-and-research',
    names: ['ui/ux & research', 'ui/ux', 'design', 'ui/ux design'],
    icon: <DesignIconV2 />,
  },
  {
    id: 8,
    key: 'digital-marketing',
    names: ['digital marketing'],
    icon: <DigitalMarketingIcon />,
  },
  {
    id: 9,
    key: 'recruitment-and-hr-consultancy',
    names: ['recruitment & hr consultancy', 'hr', 'recruitment', 'consultancy'],
    icon: <RecruitmentIcon />,
  },
  {
    id: 10,
    key: 'legal',
    names: ['legal'],
    icon: <LegalIcon />,
  },
  {
    id: 11,
    key: 'advertising-agency',
    names: ['advertising', 'advertising agency'],
    icon: <AdvertisementIcon />,
  },
  {
    id: 12,
    key: 'it-consultants',
    names: ['information technology consultants', 'it consultants'],
    icon: <AdvisoryIcon />,
  },
  {
    id: 13,
    key: 'relocation-and-payroll',
    names: ['relocation & payroll'],
    icon: <PayrollIcon />,
  },
  {
    id: 14,
    key: 'cloud-services',
    names: ['cloud services'],
    icon: <CloudServicesIcon />,
  },
  {
    id: 15,
    key: 'business-advisory',
    names: ['business advisory'],
    icon: <AdvisoryIcon />,
  },
];

export default null;
