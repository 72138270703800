import React from 'react';
import { Modal } from 'antd';

import classNames from 'classnames';
import history from 'history_instance';
import { NoticeModalProps } from 'types/componentTypes';

import './NoticeModal.scss';

const NoticeModal = (props: NoticeModalProps) => {
  const {
    isOpen,
    actions,
    details,
  } = props;

  const {
    path,
    okText,
    text,
    title,
    closable,
  } = details || {};

  const onOk = () => {
    history.push(path!);
    actions.setIsNoticeModalOpen({ isOpen: false });
  }

  const onDissmis = () => {
    actions.setIsNoticeModalOpen({ isOpen: false });
  }

  return (
    <Modal
      open={isOpen}
      onOk={onOk}
      onCancel={closable ? onDissmis : undefined}
      cancelButtonProps={{ className: 'secondary-button' }}
      okButtonProps={{ className: 'primary-button' }}
      okText={okText}
      cancelText={closable ? 'Dissmis' : undefined}
      title={title}
      className={classNames('notice-modal', { 'notice-modal--no-close': !closable })}
      closable={false}
    >
      {text}
    </Modal>
  );
}

export default NoticeModal;
