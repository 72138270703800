import React from 'react'
import { Form } from 'antd'

import useSettingsForm from 'components/Settings/hooks/useSettingsForm';
import DrawerButton from 'components/components/Drawer/components/DrawerButton';
import { AddServiceCategoryPayload } from 'types/reduxTypes/ActionTypes/ServiceTypes';
import { FormBuilder } from 'components/components';
import { AddNewCategoryDrawerProps } from 'types/settingsOverview';
import { AddNewCategoryFormFields } from './utils/constants';

import './AddNewCategoryDrawer.scss';

const AddNewCategoryDrawer = (props: AddNewCategoryDrawerProps) => {
  const { actions } = props;

  const { form } = useSettingsForm({ defaultValues: {} });

  const handleSubmit = (data: AddServiceCategoryPayload) => {
    const addDataValues = {
      ...data,
    };
    actions.addServiceCategory({ data: addDataValues });
  }

  return (
    <Form
      form={form}
      onFinish={handleSubmit}
      className="new-category-container"
      scrollToFirstError
    >
      <FormBuilder
        oneColumn
        formFragment
        fields={AddNewCategoryFormFields}
      />
      <DrawerButton>Add category</DrawerButton>
    </Form>

  )
}
export default AddNewCategoryDrawer;
