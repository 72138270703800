import { connect } from 'react-redux';
import { Action, bindActionCreators, Dispatch } from 'redux';

import { StateType } from 'types';
import { selectIsLoading, selectPartnerDetails } from 'redux/selectors/partners';
import { getPartnerById, updatePartner } from 'redux/actions/partners';
import { selectPartnerId } from 'redux/selectors/auth';

const mapStateToProps = (state: StateType) => ({
  details: selectPartnerDetails(state),
  isLoading: selectIsLoading(state),
  userPartnerId: selectPartnerId(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  actions: bindActionCreators({
    getPartnerById,
    updatePartner,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps);
