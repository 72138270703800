import React from 'react';

import SVGWrap from './SVGWrap';

const DatePickerIcon = () => (
  <SVGWrap>
    <path
      d="M11 1H9V0H8V1H4V0H3V1H1C0.45 1 0 1.45 0 2V12C0 12.55 0.45 13 1 13H11C11.55 13 12 12.55 12 12V2C12 1.45 11.55 1 11 1ZM11 12H1V5H11V12ZM11 4H1V2H3V3H4V2H8V3H9V2H11V4Z"
    />

  </SVGWrap>
);

export default DatePickerIcon;
