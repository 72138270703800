import React, { useEffect, useMemo } from 'react';
import moment from 'moment';

import { identity } from 'utils';
import { Input } from 'components/components';
import { ADGMLicenseDetailsDrawerProps } from 'types/startups/editStartup';
import { NOT_SPECIFIED } from '../../../CohortDetails/utils/constants';
import { formatSlashMDY } from '../../../utils/constants/formatters';
import { getStartupFile } from '../../utils';
import { DetailsDrawerRow, ADGMLicenseUploadForm } from '..';

import './ADGMLicenseDetailsDrawer.scss';

const ADGMLicenseDetailsDrawer = (props: ADGMLicenseDetailsDrawerProps) => {
  const { adgm, userStartupId, id } = props;
  const isCreateOrEditMode = useMemo(() => !identity.isTruthyString(adgm?.aDGMLicenseUrl)
    || (identity.isZero(adgm?.approvedRejectedStatus) && identity.isTruthyString(adgm?.approvedRejectedDate)), [adgm])

  const startupId = useMemo(() => (identity.isTruthyNumber(userStartupId) ? userStartupId : id), [id, userStartupId]);

  useEffect(() => {
    props.actions.getADGMLicenseByStartupId({ id: startupId });
  }, [startupId]);

  if (isCreateOrEditMode) {
    return <ADGMLicenseUploadForm adgm={adgm} startupId={startupId} />;
  }

  return (
    <div className="adgm-license-details-drawer">
      <DetailsDrawerRow label="ADGM License No." item={adgm.aDGMLicenseNo || NOT_SPECIFIED} />
      <DetailsDrawerRow label="ADGM Legal Name" item={adgm.legalADGMName || NOT_SPECIFIED} />
      <DetailsDrawerRow label="ADGM Legal Signatory Name" item={adgm.legalSignatoryName || NOT_SPECIFIED} />
      <DetailsDrawerRow label="ADGM Legal Signatory Title" item={adgm.legalSignatoryTitle || NOT_SPECIFIED} />
      <DetailsDrawerRow label="ADGM Legal Signatory Email" item={adgm.legalSignatoryEmail || NOT_SPECIFIED} />
      <DetailsDrawerRow label="Registered WeWork Address" item={adgm.address || NOT_SPECIFIED} />
      <DetailsDrawerRow label="ADGM Issue Date" item={identity.isTruthyString(adgm.issueDate) ? moment(adgm.issueDate).format(formatSlashMDY) : NOT_SPECIFIED} />
      <DetailsDrawerRow label="ADGM Expiry Date" item={identity.isTruthyString(adgm.expiryDate) ? moment(adgm.expiryDate).format(formatSlashMDY) : NOT_SPECIFIED} />
      <DetailsDrawerRow label="Hub71 Acceptance Letter Date" item={identity.isTruthyString(adgm.acceptanceDate) ? moment(adgm.acceptanceDate).format(formatSlashMDY) : NOT_SPECIFIED} />
      <Input.DraggerUpload
        previewType="image"
        defaultFileList={[...getStartupFile(adgm?.aDGMLicenseUrl!)]}
        isRoundableButton
      />
    </div>
  );
}

export default ADGMLicenseDetailsDrawer;
