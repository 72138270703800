import React, { useCallback, useEffect, useState } from 'react'
import { RcFile } from 'antd/lib/upload';

import { StepCustomFieldComponentProps } from 'types/componentTypes/StepsForm';
import { FormBuilder, Input } from 'components/components';
import { identity } from 'utils';
import { Row } from 'components/components/FormBuilder/components';
import { pitchDeckFields } from '../../utils/formFields';
import { formatBlob, getBlobFromUrl } from '../../utils/helpers';

const PitchDeckFields = (props: StepCustomFieldComponentProps) => {
  const { form } = props;
  const pitchDeckName = 'pitchDeck';

  const [pitchDeckFile, setPitchDeckFile] = useState(form?.getFieldValue(pitchDeckName))

  useEffect(() => {
    if (identity.isTruthyString(pitchDeckFile)) {
      getBlobFromUrl(pitchDeckFile as string)
        .then((blob) => setPitchDeckFile(formatBlob(blob)));
    }
  }, []);

  const beforeUpload = useCallback((file: RcFile) => {
    form?.setFieldValue(pitchDeckName, file as File);
    return false;
  }, []);

  return (
    <>
      <Row oneColumn>
        <Input
          layout="vertical"
          label="Current Pitch Deck (please upload a new one if applicable)"
          name={pitchDeckName}
        >
          <Input.DraggerUpload
            accept=".pdf"
            beforeUpload={beforeUpload}
            isRoundableButton
            defaultFileList={identity.isTruthyString(pitchDeckFile?.name) ? [pitchDeckFile] : undefined}
            allowChange
          />
        </Input>
      </Row>
      <FormBuilder
        fields={pitchDeckFields}
        formFragment
        oneColumn
      />
    </>
  )
};

export default PitchDeckFields;
