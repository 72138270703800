import { connect } from 'react-redux';
import { Action, bindActionCreators, Dispatch } from 'redux';

import { selectUserType } from 'redux/selectors/auth';
import {
  selectCommentsLoading,
  selectRequestComments,
  selectRequestDetails,
  selectRequestDetailsActiveTab,
  selectServiceRequestId,
} from 'redux/selectors/request';
import {
  sendNewRequestComment,
  changeRequestStatus,
  getCommentsByRequestId,
  setActiveTab,
  getRequestItemsByRequestId,
  getRequestById,
} from 'redux/actions/request';
import { StateType } from 'types';
import { getWallet } from 'redux/actions/wallet';

const mapStateToProps = (state: StateType) => ({
  activeTab: selectRequestDetailsActiveTab(state),
  comments: selectRequestComments(state),
  commentsLoading: selectCommentsLoading(state),
  userType: selectUserType(state),
  requestId: selectServiceRequestId(state),
  request: selectRequestDetails(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  actions: bindActionCreators({
    sendNewRequestComment,
    getRequestItemsByRequestId,
    changeRequestStatus,
    getCommentsByRequestId,
    setActiveTab,
    getRequestById,
    getWallet,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps);
