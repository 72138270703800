import React, { useEffect } from 'react';

import { showRequestedButton, identity } from 'utils';
import { ServicesType } from 'types/services';
import { Text } from 'components/components';
import { AddMoreServicesProps } from 'types/requests';
import ServiceCard from './components/ServiceCard';

import './AddMoreServices.scss';

const AddMoreServices = (props: AddMoreServicesProps) => {
  const { services, unsentRequests, actions } = props;

  useEffect(() => {
    actions.getServicesBySPId({ id: unsentRequests[0].service.serviceProviderId });
  }, []);

  const noServicesList = [1, 2, 3].includes(unsentRequests[0].service.categoryId);

  if (identity.isEmptyArray(services) || noServicesList) {
    return null;
  }

  return (
    <div className="add-more-services">
      <Text text={`Add more services from ${services[0].serviceProvider.name || 'this Service Provider'}`} />
      <div className="add-more-services__service-cards--container">
        {services.map((service: ServicesType) => (
          <ServiceCard
            key={`service-${service.id}`}
            service={service}
            requestedService={showRequestedButton(service.id, unsentRequests)}
            disabledRequest={[1, 2, 3].includes(service.categoryId)}
          />
        ))}
      </div>
    </div>
  );
}

export default AddMoreServices;
