import { LabeledValue } from 'antd/lib/select';

import { inputTypes } from 'components/components/FormBuilder/utils/constants';
import { createFormField } from 'components/components/FormBuilder/utils/FormField';
import { getFormFieldBuilder } from 'components/components/FormBuilder/utils/FormFieldBuilder';
// import { getValues } from 'components/Events/components/FiltersDrawer/utils/constants';
// import { EVENT_TYPE } from 'packages/events_repository';

export type EventFieldsParams = {
  eventCategories: LabeledValue[],
}

const attendeeGroupTypeOptions: LabeledValue[] = [
  { value: 1, label: 'virtual' },
  { value: 2, label: 'physical' },
  { value: 3, label: 'hybrid' },
];

export const editEventFields = ({ eventCategories }: EventFieldsParams) => [
  {
    fields: [
      createFormField('name', 'Name'),
    ],
  },
  {
    fields: [
      getFormFieldBuilder('type', 'Type', inputTypes.select)
        .withOptions(attendeeGroupTypeOptions)
        .build(),
      getFormFieldBuilder('category', 'Category', inputTypes.select)
        .withOptions(eventCategories)
        .withPlaceholder('Select event category')
        .build(),
    ],
  },
  {
    fields: [
      getFormFieldBuilder('description', 'Description', inputTypes.textArea)
        .withRows(5)
        .build(),
    ],
  },
];

export default null;
