import React from 'react'
import { FaCheckCircle } from 'react-icons/fa';

import './InfoTrack.scss';

type InfoTrackProps = {
  title: string,
  description: string,
}
const InfoTrack = (props: InfoTrackProps) => {
  const { title, description } = props;

  return (
    <div className="info-track">
      <FaCheckCircle />
      <div className="info-track__text">
        <h3 className="info-track__title">{title}</h3>
        <p className="info-track__description">{description}</p>
      </div>
    </div>
  )
}

export default InfoTrack;
