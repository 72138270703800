export const formatSlashMDY = 'MM/DD/YYYY';
export const formatDashDMY = 'DD-MM-YYYY';
export const formatWordMonth = 'DD MMMM, YYYY';
export const formatDashYMD = 'YYYY-MM-DD';
export const formatTimeHMS = 'HH:mm:ss';
export const formatSlashMDYHD = `${formatSlashMDY} hh:mm A`;
export const formatDateDashYMDTimeHMS = `${formatDashYMD}T${formatTimeHMS}`;
export const formatDateName = 'MMMM Do';
export const formatDateTimeName = `${formatDateName}, hh:mm A`;
export const formatTimeHM = 'hh:mm A';
