/* eslint-disable @typescript-eslint/no-explicit-any, react/jsx-props-no-spreading  */
import React, { useState } from 'react';
import { Button, Upload } from 'antd';

import { CloseIcon } from 'assets/icons';
import { FilePreviewButtonsProps } from 'types/componentTypes';
import { RenderIf } from 'components/components/index';
import { FilePreviewModal } from '../index';
import { getFileType } from '../FilePreviewModal/utils/helpers';

import './Buttons.scss';

const Buttons = (props: FilePreviewButtonsProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const uploadProps = { ...props };
  delete uploadProps.buttonsProps;
  delete uploadProps.previewType;
  const { isRoundableButton } = props;

  const handleOpenFilePreviewModal = () => {
    setIsOpen(true);
  }

  const fullButtonProps = {
    ...props.buttonsProps,
    fileType: getFileType(props.buttonsProps!.fileContent.split('.').at(-1)!),
    fileContent: props.buttonsProps?.fileContent!,
    title: props.buttonsProps?.title!,
  }

  return (
    <div className="button-container">
      <RenderIf condition={props.previewType === 'image'}>
        <>
          <Button
            shape="round"
            type="primary"
            className="preview-button"
            onClick={handleOpenFilePreviewModal}
          >
            preview
          </Button>
          <FilePreviewModal
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            {...fullButtonProps || { title: '', fileContent: '', fileType: undefined }}
          />
        </>
      </RenderIf>
      <RenderIf condition={props.buttonsProps?.allowChange}>
        <Upload
          {...uploadProps}
          beforeUpload={props.beforeUpload}
          onChange={props.onChange}
          showUploadList={false}
          multiple={false}
          fileList={props.fileList}
          className="dragger"
          accept={props.accept}
        >
          { isRoundableButton ? (
            <Button shape="round" type="primary" className="change-button">change</Button>
          ) : (
            <Button
              className="document-box__delete-button"
              size="small"
              danger
              icon={<CloseIcon />}
            />
          )}
        </Upload>
      </RenderIf>
    </div>
  );
}

Buttons.defaultProps = {
  allowChange: true,
  isRoundableButton: true,
}

export default Buttons;
