import { useReducer } from 'react';
import { LabeledValue } from 'antd/lib/select';

import { DataPayload } from 'types/reduxTypes/ActionTypes';
import { initialState, reducer } from './reducer';
import {
  setADGMAll as setADGMAllAC,
  setADGMField as setADGMFieldAC,
  setHasLicense as setHasLicenseAC,
  setFilteredSectorOptions as setFilteredSectorOptionsAC,
} from './actions';
import { ADGMLicenseType } from '../../../../packages/startup_repository';
import { SetADGMFieldType } from '../../../AddMissingDetails/utils/addMissingDetailsHook/actions';

const useAddStartupHook = () => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const setHasLicense = (payload: DataPayload<boolean>) => {
    dispatch(setHasLicenseAC(payload));
  }

  const setFilteredSectorOptions = (payload: DataPayload<LabeledValue[]>) => {
    dispatch(setFilteredSectorOptionsAC(payload));
  }

  const setADGMAll = (payload: DataPayload<ADGMLicenseType>) => {
    dispatch(setADGMAllAC(payload));
  }

  const setADGMField = (payload: SetADGMFieldType) => {
    dispatch(setADGMFieldAC(payload));
  }

  return {
    state,
    setADGMAll,
    setADGMField,
    setHasLicense,
    setFilteredSectorOptions,
  };
}

export default useAddStartupHook;
