import React, { useEffect } from 'react';

import Modal from '../components/Modal';
import { RequestDetailsModalProps } from '../../types/requestDetailsModal';
import RequestDetails from '../RequestDetails';

import './RequestDetailsModal.scss';

const RequestDetailsModal = (props: RequestDetailsModalProps) => {
  const {
    actions, id, isOpen, setIsOpen,
  } = props;

  useEffect(() => {
    if (isOpen) {
      actions.getRequestById({ id });
    }
  }, [id, isOpen]);

  return (
    <Modal
      open={isOpen}
      onCancel={() => setIsOpen(false)}
      footer={null}
      title="Request Details"
      className="request-details-modal"
    >
      <RequestDetails isModal />
    </Modal>
  );
}

export default RequestDetailsModal;
