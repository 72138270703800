/**
 * @function parsePrice
 * parses price string to floating point number.
 * @param {string} price
 * @param {string} currency
 */
const parsePrice = (price: string, currency: string = 'AED') => {
  const formattedPrice = price
    ?.replace(currency, '')
    ?.replace(',', '')
    ?.replace(' ', '');
  return parseFloat(formattedPrice);
}

const parseAmountPrice = (price: string) => parseFloat(price);

const CURRENCY_SPACE: {[key: string]: string} = {
  AED: ' ',
  $: '',
  '': '',
}
/**
 * @function parseNumberToPrice
 * parses number to string price with comma separators and decimal points
 * @param {string} price
 * @param {string} currency
 */
const parseNumberToPrice = (price: number, currency: string = '') => {
  const parsedPrice = price?.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  return `${currency}${CURRENCY_SPACE[currency]}${parsedPrice}`;
}

export {
  parsePrice,
  parseNumberToPrice,
  parseAmountPrice,
}
