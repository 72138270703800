import React from 'react';
import { Slider as AntdSlider } from 'antd';

import { SliderProps } from 'types/componentTypes';
import Handle from './components/Handle';

import './Slider.scss';

const Slider = (props: SliderProps) => (
  <>
    <AntdSlider
      className="slider"
      min={props.min}
      max={props.max}
      defaultValue={props.defaultValue}
      onChange={props.onChange}
      tooltip={{ open: props.tooltipVisible, formatter: props.tooltipFormatter }}
      value={props.value}
    />
    <Handle value={props.value} />
  </>
);

Slider.defaultProps = {
  tooltipVisible: false,
  tooltipFormatter: null,
};

export default Slider;
