import {
  all, call, put, takeLatest,
} from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';

import showNotification from 'services/utils/showNotification';
import { getStartupTeamMemberById } from 'packages/people_repository';
import { IdPayload } from 'types/reduxTypes/ActionTypes';
import { identity } from 'utils';
import {
  setMemberById,
  types,
} from '../../actions/memberDetails';

export function * handleGetMemberById(action: PayloadAction<IdPayload>) {
  const { id } = action.payload;

  const { data: member, error, httpStatus } = yield call(getStartupTeamMemberById, id);

  if (identity.isObjWithChildren(error)) {
    showNotification(`Something went wrong: ${error.message}`, true, httpStatus);
  }

  yield put(setMemberById({ member }));
}

export default function * memberDetailsSagas() {
  yield all([
    takeLatest(types.GET_MEMBER_BY_ID, handleGetMemberById),
  ]);
}
