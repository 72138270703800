import { identity, parseAmountPrice } from 'utils';
import { WalletType } from 'types/wallet';
import { StartupType } from 'packages/startup_repository';

export const validateStartupApproval = (totalPrice: number, wallet: WalletType) => {
  const { balance } = wallet;
  return totalPrice <= parseAmountPrice(balance);
};

export const validateHub71AdminApproval = (wallet: WalletType) => {
  const { balance, totalWalletAmount } = wallet;
  const remainingAmount = (parseAmountPrice(balance) / parseAmountPrice(totalWalletAmount)) * 100 || 0;

  return remainingAmount > 10;
};

export const getRequestSenderName = (startup?: StartupType | null): string => {
  let senderName = 'Not Specified';
  if (identity.isTruthyString(startup?.firstName)) {
    senderName = startup?.firstName!;
    if (identity.isTruthyString(startup?.lastName)) {
      senderName += ` ${startup?.lastName}`;
    }
    if (identity.isTruthyString(startup?.name)) {
      senderName += ` - ${startup?.name}`;
    }
    if (identity.isTruthyString(startup?.founderEmail)) {
      senderName += ` (${startup?.founderEmail})`;
    }
  } else {
    senderName = startup?.name ? startup.name : senderName;
    if (identity.isTruthyString(startup?.founderEmail)) {
      senderName += ` (${startup?.founderEmail})`;
    }
  }

  return senderName;
};
