/* eslint-disable @typescript-eslint/no-explicit-any */
import { AggregationObject } from 'packages/http_client/types';

export const max = (field: string): AggregationObject => ({ field, aggregation: 'max' });
export const min = (field: string): AggregationObject => ({ field, aggregation: 'min' });
export const count = (field: string): AggregationObject => ({ field, aggregation: 'count' });
export const avg = (field: string): AggregationObject => ({ field, aggregation: 'avg' });

export default {
  max,
  min,
  count,
  avg,
}
