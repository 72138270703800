import React from 'react';
import { Button } from 'antd';
import classnames from 'classnames';

import { RenderIf, Text } from 'components/components';
import { isStartup } from 'utils';
import { RequestItemRowProps } from 'types/requests';
import { STATUS_COLOR_CLASS } from '../RequestDetailsDrawer';
import { getStatusName, isRejected } from '../../utils';
import { RequestRowButtons, RequestSecondInfo } from './components';

import './RequestItemRow.scss';

const RequestItemRow = (props: RequestItemRowProps) => {
  const { userType, highlight, isUnsent } = props;
  const {
    id, price, service, quantity, serviceRequest, priceType,
  } = props.request;

  const status = serviceRequest?.status!;

  return (
    <div className={classnames('request-row', { 'highlighted-row': highlight })}>
      <div className="request-row__left-container">
        <RenderIf condition={!isUnsent}>
          <div className="request-row__status-container">
            <p className={classnames('request-row__status', STATUS_COLOR_CLASS(userType)[status])}>
              {getStatusName(status, userType)}
            </p>
            <RenderIf condition={isStartup(userType) && isRejected(status)}>
              <Button className="request-row__denied-see-why">See Why?</Button>
            </RenderIf>
          </div>
        </RenderIf>
        <Text text={service?.name!} size="xs" />
        <div className="request-row__request-info">
          <div className={classnames('request-row__request-info--row', { unsent: isUnsent })}>
            <span className="request-row__request-info--row__details">Total</span>
            <div className="dot" />
            <span className="request-row__request-info--row__details">{price}</span>
          </div>
          <RequestSecondInfo
            status={status}
            priceType={priceType!}
            date={serviceRequest?.applicationDate!}
            requestNumber={id}
            quantity={quantity!}
          />
        </div>
      </div>
      <RequestRowButtons type="service" status={isUnsent ? 'unsent' : status} id={id!} />
    </div>
  );
}

RequestItemRow.defaultProps = {
  highlight: false,
  isUnsent: false,
};

export default RequestItemRow;
