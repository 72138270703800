import { connect } from 'react-redux';
import { bindActionCreators, Dispatch, Action } from 'redux';

import { deleteDraftRequest, sendRequest } from '../../../../../../redux/actions/request';

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  actions: bindActionCreators({ deleteDraftRequest, sendRequest }, dispatch),
});

export default connect(null, mapDispatchToProps);
