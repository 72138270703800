import { inputTypes } from 'components/components/FormBuilder/utils/constants';
import { createFormField } from 'components/components/FormBuilder/utils/FormField';
import { getFormFieldBuilder } from 'components/components/FormBuilder/utils/FormFieldBuilder';
import { PARTNER_TYPES } from 'utils';

export const editPartnerFields = [
  {
    fields: [
      createFormField('name', 'Name'),
    ],
  },
  {
    fields: [
      getFormFieldBuilder('type', 'Type', inputTypes.select)
        .withOptions(PARTNER_TYPES)
        .build(),
    ],
  },
  {
    fields: [
      createFormField('website', 'Website'),
    ],
  },
  {
    fields: [
      getFormFieldBuilder('description', 'Description', inputTypes.textArea)
        .withRows(3)
        .build(),
    ],
  },
  {
    fields: [
      createFormField('moU', 'MoU'),
    ],
  },
  {
    fields: [
      getFormFieldBuilder('contactFirstName', 'Contact first name')
        .disabled()
        .build(),
    ],
  },
  {
    fields: [
      getFormFieldBuilder('contactLastName', 'Contact last name')
        .disabled()
        .build(),
    ],
  },
  {
    fields: [
      getFormFieldBuilder('contactEmail', 'Contact email')
        .disabled()
        .build(),
    ],
  },
  {
    fields: [
      getFormFieldBuilder('contactPhone', 'Contact phone')
        .disabled()
        .build(),
    ],
  },
];

export default null;
