import { connect } from 'react-redux';
import { bindActionCreators, Dispatch, Action } from 'redux';

import { StateType } from 'types';
import {
  updateEducation,
  updateTitle,
  updateStartupStage,
} from 'redux/actions/settingsOverview';
import {
  selectActiveSubTab, selectDataEntryPointDetail,
} from 'redux/selectors/settingsOverview';

const mapStateToProps = (state: StateType) => ({
  activeSubTab: selectActiveSubTab(state),
  dataEntryPoint: selectDataEntryPointDetail(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  actions: bindActionCreators({
    updateEducation,
    updateTitle,
    updateStartupStage,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps);
