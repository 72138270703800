import React, { useEffect, useMemo, useState } from 'react';
import { Spin } from 'antd';
import { useLocation, useParams } from 'react-router';

import { ServiceProviderDetailsProps } from 'types/serviceProviders/ServiceProviderDetails';
import { StartupType } from 'types/startups';
import { isEitherAdmin, getRowKey, identity } from 'utils';
import {
  Breadcrumb,
  RequestList,
  Collapse,
  Requests,
  ExpandableTable,
  RenderIf,
} from 'components/components';
import routes from 'routes';
import { StartupDetails, ServiceProviderInfo, AboutService } from './components';
import { startupsInCohortColumns } from '../utils/constants/columns';

import './ServiceProviderDetails.scss';

const ServiceProviderDetails = (props: ServiceProviderDetailsProps) => {
  const {
    getServiceProviderById,
    getStartupsByOwnerId,
    getRequestsByOwnerId,
    getStartupById,
    getProviderServicesWithStartup,
    getServicesByServiceProviderId,
  } = props.actions;
  const {
    startups, requests, aboutInfo, userType, userServiceProviderId,
  } = props;

  const location = useLocation();

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [currentStartupId, setCurrentStartupId] = useState<number>();

  const params: { id?: string } = useParams();
  const { id } = params;

  const isMyCompanyProfile = identity.isTruthyNumber(userServiceProviderId)
    && location.pathname === routes.companyProfileUrl;

  const serviceProviderId = useMemo(() => (
    isMyCompanyProfile ? userServiceProviderId : parseInt(id!, 10)
  ), [id]);

  const openStartupModal = (record: StartupType) => {
    setCurrentStartupId(record.id);
    setIsOpen(true);
  }

  useEffect(() => {
    if (identity.isTruthyNumber(currentStartupId)) {
      getStartupById({ id: currentStartupId!, owner: 'service-provider' });
      getProviderServicesWithStartup({ id: serviceProviderId, startupId: currentStartupId!, requestedBy: 'service-provider' });
    }
  }, [currentStartupId, serviceProviderId]);

  useEffect(() => {
    getServiceProviderById({ id: serviceProviderId });
    getStartupsByOwnerId({ id: serviceProviderId, owner: 'service-provider' });
    getRequestsByOwnerId({ id: serviceProviderId, owner: 'service-provider' });
    getServicesByServiceProviderId({ id: serviceProviderId });
  }, [serviceProviderId]);

  if (props.isLoading) {
    return <Spin className="spinner" />;
  }

  const collapseHeader = `Requests with ${aboutInfo.name}`;

  return (
    <div className="service-provider-details content-container">
      <RenderIf condition={!isMyCompanyProfile}>
        <Breadcrumb navigateTo={isEitherAdmin(userType) ? routes.servicesAndSpsOverviewUrl : routes.servicesUrl} routeName="Service Providers List" />
      </RenderIf>
      <ServiceProviderInfo />
      { (isEitherAdmin(userType) || isMyCompanyProfile) ? (
        <>
          <AboutService serviceProvider={aboutInfo} />
          <Collapse header="Requests" className="service-provider-details__requests">
            <Requests component="service-provider" requests={requests} showTitle={false} />
          </Collapse>
          <ExpandableTable
            collapseHeader="Startups with this service provider"
            dataSource={startups}
            columns={startupsInCohortColumns}
            rowKey={getRowKey(startups, 'service-provider-details-startup', 'id')}
            onRow={(record) => ({ onClick: () => openStartupModal(record) })}
          />
        </>
      ) : (
        <Collapse header={collapseHeader} className="service-provider-details__requests">
          <RequestList requestsList={requests} />
        </Collapse>
      ) }
      <StartupDetails serviceName={aboutInfo.name} isOpen={isOpen} setIsOpen={setIsOpen} />
    </div>
  );
}

export default ServiceProviderDetails;
