import React from 'react';

import { identity } from 'utils';
import { DataContainer } from 'components/components';
import { DocumentRequestsProps } from 'types/cohorts/cohortDetails';
import RequestRow from './components/RequestRow';

const AcceptanceLetterADGM = (props: DocumentRequestsProps) => {
  const { showTitle, startups } = props;

  return (
    <div className="acceptanceLetter-ADGM">
      <DataContainer
        title={showTitle ? 'Documents for Approval' : ''}
        noDataText="requests"
        buttonText="request"
        hideButton
        data={identity.isEmptyArray(startups) ? 'No documents for approval' : (
          <div className="acceptanceLetter-ADGM__container">
            {startups.map((startup) => <RequestRow startup={startup} key={`#R${startup.id}`} />)}
          </div>
        )}
      />
    </div>
  );
}

AcceptanceLetterADGM.defaultProps = {
  showTitle: true,
}

export default AcceptanceLetterADGM;
