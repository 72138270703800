import React from 'react';
import { Button } from 'antd';
import classnames from 'classnames';

import { DRAWERS } from 'utils';
import { RequestRowButtonsProps } from 'types/requests';
import { isUnsent } from '../../utils';

const RequestRowButtons = (props: RequestRowButtonsProps) => {
  const { status, id, type } = props;
  const {
    setDrawerBody,
    setIsDrawerOpen,
    setRequestId,
    deleteDraftRequest,
    setPartnerRequestId,
    setPartnerRequestById,
  } = props.actions;

  const isPartner = type === 'partner';

  const onViewDetails = () => {
    const drawerBody = isPartner ? DRAWERS.partnerRequestDetails : DRAWERS.requestDetails;
    setDrawerBody({ drawerBody });
    setIsDrawerOpen({ isDrawerOpen: true });
    if (isPartner) {
      setPartnerRequestId({ id });
      setPartnerRequestById({ id });
    } else {
      setRequestId({ id });
    }
  }

  return (
    <div className="request-row__buttons-container">
      <Button
        className={classnames('md', {
          'secondary-button': !isUnsent(status) || isPartner,
          'secondary-delete-button': isUnsent(status) && !isPartner,
        })}
        onClick={() => ((isUnsent(status) && !isPartner) ? deleteDraftRequest() : onViewDetails())}
      >
        {(isUnsent(status) && !isPartner) ? 'Discard' : 'View details'}
      </Button>
    </div>
  );
}

export default RequestRowButtons;
