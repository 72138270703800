import React from 'react';

import SVGWrap from './SVGWrap';

const LegalIcon = () => (
  <SVGWrap size={24} fill="none">
    <path d="M17.8594 21.0806H6.14063V20.6587H10.0424C10.1402 19.7417 10.8715 19.0105 11.7891 18.9126V10.5179C11.1264 10.4159 10.6172 9.84171 10.6172 9.15089C10.6172 8.46007 11.1264 7.88585 11.7891 7.78389V5.79874L6.1582 7.30577L8.66777 11.6974C8.68594 11.7296 8.69531 11.7653 8.69531 11.8023C8.69531 13.3726 7.41797 14.6499 5.84766 14.6499C4.27734 14.6499 3 13.372 3 11.8023C3 11.7659 3.00937 11.7296 3.02754 11.6974L5.66426 7.08311C5.69355 7.03213 5.7416 6.99639 5.79727 6.98292L5.79258 6.96651L18.0979 3.67354C18.1834 3.64893 18.2883 3.69053 18.3352 3.77256L20.9719 8.38682C20.99 8.41905 21 8.45479 21 8.49171C21 10.062 19.7221 11.3394 18.1523 11.3394C16.5826 11.3394 15.3047 10.062 15.3047 8.49171C15.3047 8.45538 15.3146 8.41905 15.3328 8.38682L17.7188 4.21143L12.2109 5.68565V7.78389C12.8736 7.88585 13.3828 8.46007 13.3828 9.15089C13.3828 9.84171 12.8736 10.4159 12.2109 10.5179V18.9126C13.1285 19.0105 13.8598 19.7417 13.9576 20.6587H17.8594V21.0806ZM10.4672 20.6587H13.5322C13.4291 19.9052 12.7816 19.3228 12 19.3228C11.2189 19.3228 10.5709 19.9052 10.4672 20.6587ZM3.43828 12.0858C3.57949 13.29 4.60605 14.2275 5.84766 14.2275C7.08926 14.2275 8.11582 13.29 8.25703 12.0858H3.43828ZM3.53262 11.6646H8.1627L5.84766 7.6128L3.53262 11.6646ZM15.7436 8.77589C15.8842 9.97999 16.9107 10.9175 18.1523 10.9175C19.3939 10.9175 20.4205 9.97999 20.5611 8.77589H15.7436ZM12 8.18995C11.4703 8.18995 11.0391 8.6212 11.0391 9.15089C11.0391 9.68057 11.4703 10.1118 12 10.1118C12.5297 10.1118 12.9609 9.68057 12.9609 9.15089C12.9609 8.6212 12.5297 8.18995 12 8.18995ZM15.8373 8.3546H20.4674L18.1523 4.30284L15.8373 8.3546Z" strokeWidth="0.585938" />
  </SVGWrap>
);

export default LegalIcon;
