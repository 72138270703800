import { identity } from 'utils';
import {
  MemberUpdateType,
  PartnerMemberType,
  SPMemberType,
  UserType,
} from '../types';

export const convertUser = (data?: UserType): UserType | undefined => {
  if (identity.isObjWithChildren(data)) {
    return ({
      id: data?.id!,
      username: data?.username!,
      firstName: data?.firstName!,
      lastName: data?.lastName!,
      email: data?.email!,
      active: data?.active!,
      admin: data?.admin!,
      remote_access: data?.remote_access!,
      user_group_id: data?.user_group_id!,
      photo: data?.photo!,
    });
  }
}

export const convertUsers = (data?: UserType[]): UserType[] => {
  const events: UserType[] = [];
  if (identity.isFullArray(data)) {
    for (const i in data!) {
      if (identity.isObjWithChildren(data[i])) {
        events.push(convertUser(data[i])!);
      }
    }
  }
  return events;
}

export const convertSPPartner = (data: PartnerMemberType | SPMemberType): MemberUpdateType | undefined => {
  if (identity.isObjWithChildren(data)) {
    return ({
      _first_name: data.firstName,
      _last_name: data.lastName,
      _mobile: data.mobile,
      _phone: data.phone,
      _email: data.email,
      _photo: data.photo,
      _job_title: data.jobTitle,
    });
  }
}
