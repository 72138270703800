import httpClient, {
  ApiResponseType,
  queryBuilder,
  ResponseType,
  UpdateResourceSuccessResponseType,
} from 'packages/http_client';
import { identity } from 'utils';
import {
  GetHub71EmployeeResponse,
  GetHub71EmployeeResponseType,
  GetPartnerMemberResponse,
  GetPartnerMemberResponseType,
  GetSPMemberResponse,
  GetSpMemberResponseType,
  GetUserResponse,
  GetUsersResponse, Hub71Employee,
  PartnerMemberType,
  SPMemberType,
  UserQuery,
  UserType,
} from './types';
import {
  defaultPartnerMember,
  PARTNER_MEMBER_API,
  SP_MEMBER_API,
  USERS_API,
  convertUsers,
  defaultSPMember,
  convertSPPartner,
  defaultHub71Employee,
} from './utils';
import { HUB_71_EMPLOYEE_API } from '../people_repository';

const getUsersApi = (query?: UserQuery) => {
  const url = USERS_API;
  const params = queryBuilder()
    .preload()
    .in({ user_group_id: query?.userGroup })
    .toObject()
  const config = { params };
  return httpClient.get<ApiResponseType<GetUsersResponse>>(url, config);
}

const getUserByIdApi = (id: number) => {
  const url = `${USERS_API}/${id}`;
  return httpClient.get<ApiResponseType<GetUserResponse>>(url, { params: { $preload: 1 } });
}

const updateUserByIdApi = (id: number, query: UserType) => {
  const url = `${USERS_API}/${id}`;
  const data = { _first_name: query.firstName, _last_name: query.lastName, _photo: query.photo };
  return httpClient.put<ApiResponseType<UpdateResourceSuccessResponseType>>(url, data);
}

const updateHub71ByIdApi = (id: number, data: Hub71Employee) => {
  const url = `${HUB_71_EMPLOYEE_API}/${id}`;
  const payload = { _first_name: data.firstName, _last_name: data.lastName, _photo: data.photo };
  return httpClient.put<ApiResponseType<UpdateResourceSuccessResponseType>>(url, payload);
}

const getHub71ByUserIdApi = (id: number) => {
  const url = `${HUB_71_EMPLOYEE_API}`;
  const config = { params: queryBuilder({ user_id: id }).toObject() };
  return httpClient.get<ApiResponseType<GetHub71EmployeeResponse>>(url, config);
}

const getPartnerMemberByUserIdApi = (id: number) => {
  const url = PARTNER_MEMBER_API;
  const config = { params: queryBuilder({ user_id: id }).preload().toObject() };
  return httpClient.get<ApiResponseType<GetPartnerMemberResponse>>(url, config);
}

const getSPMemberByUserIdApi = (id: number) => {
  const url = SP_MEMBER_API;
  const config = { params: queryBuilder({ user_id: id }).preload().toObject() };
  return httpClient.get<ApiResponseType<GetSPMemberResponse>>(url, config);
}

const updatePartnerMemberApi = (id: number, data: PartnerMemberType) => {
  const url = `${PARTNER_MEMBER_API}/${id}`;
  return httpClient.put<ApiResponseType<UpdateResourceSuccessResponseType>>(url, convertSPPartner(data));
}

const updateSPMemberApi = (id: number, data: SPMemberType) => {
  const url = `${SP_MEMBER_API}/${id}`;
  return httpClient.put<ApiResponseType<UpdateResourceSuccessResponseType>>(url, convertSPPartner(data));
}

export const getUsers = async (query?: UserQuery): Promise<ResponseType<UserType[]>> => {
  const response = await getUsersApi(query);
  const { error, httpStatus, data } = response;
  return { error, httpStatus, data: convertUsers(data?.result) };
}

export const getUserById = async (id: number): Promise<ResponseType<UserType>> => {
  const response = await getUserByIdApi(id);
  const { error, httpStatus, data } = response;
  return { error, httpStatus, data: data?.result };
}

export const getPartnerMemberByUserId = async (id: number): Promise<GetPartnerMemberResponseType> => {
  const response = await getPartnerMemberByUserIdApi(id);
  const { error, httpStatus, data } = response;
  return { error, httpStatus, data: identity.isFullArray(data?.result) ? data?.result![0] : defaultPartnerMember };
}

export const getSPMemberByUserId = async (id: number): Promise<GetSpMemberResponseType> => {
  const response = await getSPMemberByUserIdApi(id);
  const { error, httpStatus, data } = response;
  return { error, httpStatus, data: identity.isFullArray(data?.result) ? data?.result![0]! : defaultSPMember };
}

export const updatePartnerMember = async (id: number, data: PartnerMemberType) => {
  const response = await updatePartnerMemberApi(id, data);
  const { error, httpStatus } = response;
  return { error, httpStatus };
}

export const updateSPMember = async (id: number, data: SPMemberType) => {
  const response = await updateSPMemberApi(id, data);
  const { error, httpStatus } = response;
  return { error, httpStatus };
}

export const updateUserById = async (id: number, data: UserType) => {
  const response = await updateUserByIdApi(id, data);
  const { error, httpStatus } = response;
  return { error, httpStatus };
}

export const getHub71ByUserId = async (id: number): Promise<GetHub71EmployeeResponseType> => {
  const response = await getHub71ByUserIdApi(id);
  const { error, httpStatus, data } = response;
  return { error, httpStatus, data: identity.isFullArray(data?.result) ? data?.result![0]! : defaultHub71Employee };
}

export const updateHub71EmployeeById = async (id: number, data: Hub71Employee) => {
  const response = await updateHub71ByIdApi(id, data);
  const { error, httpStatus } = response;
  return { error, httpStatus };
}
