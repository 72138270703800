import { PayloadAction } from '@reduxjs/toolkit';

import { ADGMLicenseType, StartupType } from 'packages/startup_repository';
import { defaultADGMLicense, startupDef } from 'redux/utils';
import { DataPayload } from 'types/reduxTypes/ActionTypes';
import { SetADGMFieldType, SetFieldType, types } from './actions';

type MissingDetailsHook = {
  startup: StartupType,
  hasLicense: boolean,
  fileName: string,
  adgmLicense: ADGMLicenseType,
};

const initialState: MissingDetailsHook = {
  startup: startupDef,
  hasLicense: false,
  fileName: '',
  adgmLicense: defaultADGMLicense,
};

const handleSetAll = (state: MissingDetailsHook, payload: DataPayload<StartupType>) => ({
  ...state, startup: payload.data,
});

const handleSetField = (state: MissingDetailsHook, payload: SetFieldType) => ({
  ...state, startup: { ...state.startup, [payload.field]: payload.data },
});

const handleSetAdgmField = (state: MissingDetailsHook, payload: SetADGMFieldType) => ({
  ...state, adgmLicense: { ...state.adgmLicense, [payload.field]: payload.data },
});

const handleSetAdgmAll = (state: MissingDetailsHook, payload: DataPayload<ADGMLicenseType>) => ({
  ...state, adgmLicense: { ...state.adgmLicense, ...payload.data },
});

const handleSetHasLicense = (state: MissingDetailsHook, payload: DataPayload<boolean>) => ({
  ...state, hasLicense: payload.data,
});

const handleSetFileName = (state: MissingDetailsHook, payload: DataPayload<string>) => ({
  ...state, fileName: payload.data,
});

const reducer = (
  state: MissingDetailsHook,
  action: PayloadAction<
    | SetFieldType
    | DataPayload<StartupType | string | boolean | ADGMLicenseType>
    | SetADGMFieldType
  >,
) => {
  const { type, payload } = action;
  switch (type) {
    case types.SET_ALL: {
      return handleSetAll(state, <DataPayload<StartupType>>payload);
    }
    case types.SET_FIELD: {
      return handleSetField(state, <SetFieldType>payload);
    }
    case types.SET_ADGM_FIELD: {
      return handleSetAdgmField(state, <SetADGMFieldType>payload);
    }
    case types.SET_ADGM_ALL: {
      return handleSetAdgmAll(state, <DataPayload<ADGMLicenseType>>payload);
    }
    case types.SET_HAS_LICENSE: {
      return handleSetHasLicense(state, <DataPayload<boolean>>payload);
    }
    case types.SET_FILE_NAME: {
      return handleSetFileName(state, <DataPayload<string>>payload);
    }
    default:
      return state;
  }
}

export { reducer, initialState };
