import React, { useEffect, useMemo } from 'react';
import { useParams } from 'react-router';

import { identity, isStartup } from 'utils';
import { composeEditPeopleUrl } from 'routes/routeComposers';
import { peopleUrl, teamMembersUrl } from 'routes/urls';
import { Breadcrumb } from 'components/components';
import routes from 'routes';
import { MemberDetailsProps } from '../../types/memberDetails';
import { Details } from './components';

import './MemberDetails.scss';

const MemberDetails = (props: MemberDetailsProps) => {
  const {
    selectedMember, userType, userId, profileId,
  } = props;
  const { getMemberById, getPartnerById, getMyProfileData } = props.actions;

  const params: { id?: string, type?: string } = useParams();
  const { id, type } = params;
  const memberId = useMemo(() => parseInt(id!, 10), [id]);
  const isPartners = type === 'partners';

  useEffect(() => {
    if (!identity.isTruthyNumber(profileId)) {
      getMyProfileData();
    }
  }, [profileId]);

  useEffect(() => {
    const functionCall = isPartners ? getPartnerById : getMemberById;
    if (identity.isTruthyString(id)) {
      functionCall({ id: memberId });
    }
  }, [id, type]);

  return (
    <div className="member-details content-container">
      <Breadcrumb navigateTo={isStartup(userType) ? teamMembersUrl : peopleUrl} routeName="People List" />
      <Details user={selectedMember} link={userId === selectedMember.userId ? routes.editMyProfileUrl : composeEditPeopleUrl(memberId, isPartners ? type : 'startup')} />
    </div>
  );
};

export default MemberDetails;
