import React, { useMemo, useState } from 'react';
import { Form } from 'antd';
import moment from 'moment';

import { disabledDateFuture, requiredValidation } from 'utils';
import { Input } from 'components/components';
import { DatePickerIcon } from 'assets/icons';
import { DependantFormProps } from 'types/services/requestService';
import { formatDateDashYMDTimeHMS, formatSlashMDY } from 'components/utils/constants/formatters';
import {
  relationTypeOptions,
  genderOptions,
  yesNoOptions,
  placeOfBirthOptions,
} from 'components/RequestServiceDrawer/utils/constants';
import DocumentUploadForm from '../UploadDocumentForm';
import DateValidationModal from '../DateValidationModal';

const DependantForm = (props: DependantFormProps) => {
  const {
    state,
    setRelationToTM,
    setIsDependantUAE,
    setDependantGender,
    setDependantFullName,
    setDependantDateOfBirth,
    setChildPlaceOfBirth,
    setPassportFile,
    setVisaFile,
    setVisaProofFile,
    setEmiratesIDFile,
    setRequestDocuments,
    setETAWithSignFile,
  } = props;

  const [showModal, setShowModal] = useState(false);
  const [date, setDate] = useState<string>('');

  const validateDate = (e: moment.Moment | null) => {
    setDate(moment(e).format(formatSlashMDY));
    if (moment().diff(moment(e), 'years') < 18) {
      setShowModal(true);
    } else {
      setDependantDateOfBirth({ data: moment(e).utc().format(formatDateDashYMDTimeHMS) });
    }
  }

  const {
    isDependantUAE,
    dependantGender,
    relationToTM,
    childPlaceOfBirth,
    displayDocuments,
  } = state.request;

  const displayPlaceOfBirthSection = useMemo(() => relationToTM === 'child', [relationToTM]);

  if (displayDocuments) {
    return (
      <div>
        <DocumentUploadForm
          state={state}
          setPassportFile={setPassportFile}
          setVisaFile={setVisaFile}
          setVisaProofFile={setVisaProofFile}
          setEmiratesIDFile={setEmiratesIDFile}
          setRequestDocuments={setRequestDocuments}
          setETAWithSignFile={setETAWithSignFile}
        />
      </div>
    );
  }

  const onCancel = () => setShowModal(false);

  const onOk = () => {
    setDependantDateOfBirth({ data: moment(date).utc().format(formatDateDashYMDTimeHMS) });
    setDate(moment(state.request.dependantDateOfBirth!).format(formatDateDashYMDTimeHMS));
    setShowModal(false);
  }

  return (
    <div>
      <Form className="dependant-container">
        <Input
          name="relation"
          label="Relation to the team member"
          layout="vertical"
          required
          rules={[requiredValidation]}
        >
          <Input.Radio
            values={relationTypeOptions}
            value={relationToTM}
            onChange={(data) => setRelationToTM(({ data: data.target.value }))}
          />
        </Input>
        { displayPlaceOfBirthSection ? (
          <Input
            name="dependant"
            label="Place of birth"
            layout="vertical"
            required
            rules={[requiredValidation]}
          >
            <Input.Radio
              values={placeOfBirthOptions}
              onChange={(data) => setChildPlaceOfBirth({ data: data.target.value })}
              value={childPlaceOfBirth}
            />
          </Input>
        ) : (
          <Input
            name="dependant"
            label="Is your dependant a UAE resident?"
            layout="vertical"
            required
            rules={[requiredValidation]}
          >
            <Input.Radio
              values={yesNoOptions}
              onChange={(data) => setIsDependantUAE({ data: data.target.value })}
              value={isDependantUAE}
            />
          </Input>
        )}
        <Input
          name="gender"
          label="Gender"
          layout="vertical"
          required
          rules={[requiredValidation]}
        >
          <Input.Radio
            values={genderOptions}
            onChange={(data) => setDependantGender({ data: data.target.value })}
            value={dependantGender}
          />
        </Input>
        <Input
          label="Full name of the dependant"
          layout="vertical"
          required
          rules={[requiredValidation]}
        >
          <Input.InputField
            name="dependant"
            type="text"
            size="large"
            onChange={(e) => setDependantFullName({ data: e.target.value })}
            placeholder="Enter full name of the dependant"
          />
        </Input>
        <Input
          name="dateOfBirth"
          label="Date of birth"
          layout="vertical"
          required
          rules={[requiredValidation]}
        >
          <Input.DatePicker
            name="dateOfBirth"
            size="large"
            value={moment(date)}
            disabledDate={disabledDateFuture}
            placeholder={formatSlashMDY}
            suffixIcon={<DatePickerIcon />}
            format={formatSlashMDY}
            onChange={validateDate}
          />
        </Input>
      </Form>
      <DateValidationModal open={showModal} date={date} onCancel={onCancel} onOk={onOk} />
    </div>
  );
}

export default DependantForm;
