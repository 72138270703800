import { Button, Popover } from 'antd';
import React, { useState } from 'react'

import { orderByDescending } from 'packages/http_client';
import SortByPopover from './components/SortByPopover';
import { FiltersProps, SortBy } from '../../../../../../types/activities';

import './Filters.scss';

const ORDER_BY = {
  'Old to new': 'date_time',
  'New to old': orderByDescending('date_time'),
}

const PARSE_ORDER_BY: {[key: string]: string} = {
  date_time: 'Old to new',
  '-date_time': 'New to old',
}

const Filters = (props: FiltersProps) => {
  const { orderBy, actions } = props;
  const [openSortBy, setOpenSortBy] = useState<boolean>(false);
  // const [openFilterActivities, setOpenFilterActivities] = useState<boolean>(false);

  // useEffect(() => {
  //   actions.resetActivitiesPagination();
  //   actions.getActivities();
  // }, [filters]);

  const onSortByClick = (value: SortBy) => {
    const data = ORDER_BY[value!];
    actions.setOrderBy({ data })
    actions.getNotifications();
    setOpenSortBy(false);
  }

  // const onTypesSelect = (data: SetFiltersPayload) => {
  //   actions.setFilters(data);
  //   setOpenFilterActivities(false);
  // }

  return (
    <div className="activity-header-filters">
      <Popover
        open={openSortBy}
        onOpenChange={() => setOpenSortBy((prev) => !prev)}
        trigger="click"
        content={<SortByPopover onSortByClick={onSortByClick} />}
        placement="bottomLeft"
      >
        <Button className="secondary-button">
          {PARSE_ORDER_BY[orderBy] || 'Sort by'}
        </Button>
      </Popover>

      {/* <Popover
        open={openFilterActivities}
        onOpenChange={() => setOpenFilterActivities((prev) => !prev)}
        trigger="click"
        content={<FilterActivitiesPopover onFinish={onTypesSelect} />}
        placement="bottomLeft"
      >
        <Button className="secondary-button">Filter</Button>
      </Popover> */}
    </div>
  )
}

export default Filters;
