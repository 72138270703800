import React from 'react';

import { DetailsRowProps } from '../../../../../../types/startups/startupDetails';
import Overview from '../../../../../components/Overview';

import './DetailsRow.scss';

const DetailsRow = (props: DetailsRowProps) => {
  const {
    leftInfoContent, leftInfoTag, rightInfoTag, rightInfoContent, invert,
  } = props;

  return (
    <div className="startup-details__cohort-details__info-row">
      <Overview.DataColumn text={leftInfoContent} label={leftInfoTag} invert={invert} />
      <Overview.DataColumn text={rightInfoContent} label={rightInfoTag} invert={invert} />
    </div>
  );
}

export default DetailsRow;
