import { connect } from 'react-redux';

import { StateType } from 'types';
import { selectIsAuthenticated, selectStartupStatus, selectUserType } from 'redux/selectors/auth';
import { selectIsOpen } from 'redux/selectors/notifications';

const mapStateToProps = (state: StateType) => ({
  isAuthenticated: selectIsAuthenticated(state),
  isOpen: selectIsOpen(state),
  startupStatus: selectStartupStatus(state),
  userType: selectUserType(state),
});

export default connect(mapStateToProps);
