import React, { useMemo } from 'react';

import { FieldItemType, RadioValue } from 'types/componentTypes/Input';
import { FormInputProps } from 'types/componentTypes/FormBuilder';
import { Input } from '../../..';
import { inputComponents, inputTypes } from '../../utils';
import SelectDrawer from '../../../SelectDrawer';

const FormInput = (props: FormInputProps) => {
  const {
    name, label, rules, type, options, rows, accept, layout, values, disabled, componentProps,
    previewType, defaultFileList, placeholder, beforeUpload, onChange, required,
  } = props;

  const Component = useMemo(() => inputComponents[type || inputTypes.text], [type]);

  switch (type) {
    case inputTypes.addButton:
    case inputTypes.selectTags: {
      return (
        <Component
          type={type as FieldItemType}
          name={name}
          label={label}
          disabled={disabled}
          required={required}
        />
      );
    }
    case inputTypes.radio: {
      return (
        <Input
          label={label}
          layout={layout}
          rules={rules}
          name={name}
          required={required}
        >
          <Input.Radio disabled={disabled} values={values as RadioValue[]} />
        </Input>
      );
    }
    case inputTypes.selectDrawer: {
      return (
        <SelectDrawer
          form={componentProps!.form}
          drawerTitle={componentProps!.drawerTitle}
          columns={componentProps!.columns}
          dataSource={componentProps!.dataSource}
          options={componentProps!.options}
          filterBy={componentProps!.filterBy}
          placeholder={placeholder}
          name={name}
          rowKeyName={componentProps!.rowKeyName}
          label={label}
          required={required}
        />
      )
    }
    default: {
      return (
        <Input
          label={label}
          layout={layout}
          rules={rules}
          name={name}
          required={required}
        >
          <Component
            type={type as FieldItemType}
            options={options}
            rows={rows}
            disabled={disabled}
            placeholder={placeholder}
            accept={accept}
            previewType={previewType}
            defaultFileList={defaultFileList}
            autoComplete="off"
            beforeUpload={beforeUpload}
            onChange={onChange}
            showSearch={type === inputTypes.selectWithSearch}
          />
        </Input>
      );
    }
  }
}

FormInput.defaultProps = {
  options: null,
  type: 'text',
  accept: '.pdf',
  rules: [],
}

export default FormInput;
