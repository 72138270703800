import { identity } from 'utils';
import { ADGMLicenseType, StartupType } from 'packages/startup_repository';

const licenseValidation = (hasLicense: boolean, adgm: ADGMLicenseType) => hasLicense && (
  identity.isEmptyString(adgm.aDGMLicenseNo)
    || identity.isEmptyString(adgm.aDGMLicenseUrl)
    || identity.isEmptyString(adgm.acceptanceDate)
    || identity.isEmptyString(adgm.expiryDate)
    || identity.isEmptyString(adgm.issueDate)
    || identity.isEmptyString(adgm.legalSignatoryTitle)
    || identity.isEmptyString(adgm.address)
    || identity.isEmptyString(adgm.legalSignatoryEmail)
    || identity.isEmptyString(adgm.legalADGMName)
    || identity.isEmptyString(adgm.legalSignatoryName)
);

export const disableAddMissingDetailsSubmitButton = (
  startup: StartupType,
  hasLicense: boolean,
  adgm: ADGMLicenseType,
) => (
  identity.isEmptyString(startup?.name)
  || identity.isEmptyString(startup?.primarySectorID)
  || !identity.isTruthyNumber(startup?.stage)
  || identity.isEmptyString(startup?.about)
  || identity.isEmptyString(startup?.imageLink)
  || identity.isEmptyString(startup?.location)
  || !identity.isTruthyNumber(startup?.numberOfEmployees)
  || identity.isEmptyString(startup?.website)
  || startup?.status.id === 1
  || licenseValidation(hasLicense, adgm)
);

export const getFileName = (value: string): string => {
  if (identity.isTruthyString(value)) {
    const data = value?.split('/');
    return identity.isFullArray(data) ? data.pop()! : '';
  }
  return '';
}
