import { connect } from 'react-redux';
import { bindActionCreators, Dispatch, Action } from 'redux';
import { StateType } from 'types';
import { selectServiceCategoryDetails } from 'redux/selectors/services';
import { addServiceCategory, updateServiceCategoryById } from 'redux/actions/services';

const mapStateToProps = (state: StateType) => ({
  serviceCategoryDetails: selectServiceCategoryDetails(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  actions: bindActionCreators({
    addServiceCategory,
    updateServiceCategoryById,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps);
