import { connect } from 'react-redux';
import { Action, bindActionCreators, Dispatch } from 'redux';

import { addServiceProvider } from '../../redux/actions/serviceProviders';

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  actions: bindActionCreators({
    addServiceProvider,
  }, dispatch),
});

export default connect(null, mapDispatchToProps);
