import React from 'react';
import moment from 'moment';
import { Col, Row } from 'antd';

import { identity } from 'utils';
import { CohortDetailsModalProps } from 'types/startups/startupDetails';
import { Modal, Overview, ExpandableTable } from 'components/components';
import { formatSlashMDY } from '../../../../../utils/constants/formatters';
import { startupsInCohortColumns } from '../../../../../utils/constants/columns';

import './CohortDetailsModal.scss';

const CohortDetailsModal = (props: CohortDetailsModalProps) => {
  const {
    cohort,
    showCohortDetails,
    setShowCohortDetails,
    startups,
  } = props;

  const aboutCohortText = identity.isTruthyString(cohort.about) ? cohort.about : 'Missing description about this cohort!';
  const noOfStartups = (startups?.length)?.toString();

  return (
    <Modal
      open={showCohortDetails}
      title={cohort.name}
      onCancel={() => setShowCohortDetails(false)}
      footer={null}
    >
      <div className="cohort-details__modal">
        <Overview title="Overview">
          <div className="overview__card-data">
            <Overview.DataColumn text={cohort.type} label="Program" />
            <Overview.DataColumn text={noOfStartups!} label="Number of startups" />
          </div>
          <div className="overview__card-data">
            <Col>
              <h4 className="overview__subtitle">Period</h4>
              <Row>
                <Overview.DataColumn text={moment(cohort.from).format(formatSlashMDY)} label="From" />
                <Overview.DataColumn text={moment(cohort.to).format(formatSlashMDY)} label="To" />
              </Row>
            </Col>
            <Col>
              <h4 className="overview__subtitle">Incentives</h4>
              <Row>
                <Overview.DataColumn text={cohort.amount} label="Amount" />
                <Overview.DataColumn text={`${cohort.period} years`} label="Duration" />
              </Row>
            </Col>
          </div>
        </Overview>
        <Overview title={`About ${cohort.name}`}>{aboutCohortText}</Overview>
        <ExpandableTable
          collapseHeader="Startups in this cohort"
          columns={startupsInCohortColumns}
          dataSource={startups}
        />
      </div>
    </Modal>
  );
}

export default CohortDetailsModal;
