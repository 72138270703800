/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect } from 'react'
import { Form } from 'antd'
import moment from 'moment';

import { EVENT_FREQUENCY } from 'packages/events_repository';
import { EventDetailsOverviewProps } from 'types/events/eventsProps';
import { getImageLink, identity, isEitherAdmin } from 'utils';
import { ImageIcon } from 'assets/icons';
import { RenderIf } from 'components/components';
import DataColumn from 'components/components/DataColumns/components/DataColumn';
import { formatDateTimeName, formatTimeHM } from 'components/utils/constants/formatters';
import { NOT_SPECIFIED } from 'components/CohortDetails/utils/constants';

import '../../EditEventDrawer.scss';

const EventDetailsOverview = (props: EventDetailsOverviewProps) => {
  const {
    actions, event, id, eventCategories,
  } = props;
  const [form] = Form.useForm();

  useEffect(() => {
    if (identity.isEmptyArray(eventCategories)) {
      actions.getEventCategories();
    }
  }, [eventCategories])

  useEffect(() => {
    actions.getEventById({ id: isEitherAdmin(props.userType) ? event?.id! : id! });
  }, [])

  useEffect(() => {
    form.setFieldsValue(event);
  }, [event]);

  const eventTime = moment(event?.startingTime).format(formatDateTimeName);
  const endingTime = moment(event?.endingTime).format(formatTimeHM);

  const link = getImageLink(event?.image.toString()!);

  return (
    <div className="edit-event-drawer">
      <div className="edit-event-drawer__image-container">
        { identity.isTruthyString(event?.image) ? (
          <img
            alt="example"
            src={link}
            className="card-logo"
          />
        ) : (
          <div className="empty-container">
            <div className="empty-image">
              <ImageIcon />
            </div>
          </div>
        )}
      </div>
      <div className="edit-event-drawer__evet-details">
        <div className="edit-event-drawer__evet-details__categories">
          <RenderIf condition={identity.isTruthyNumber(event?.frequency)}>
            <p className="category-tag__blue-tag">{ EVENT_FREQUENCY[event?.frequency!] }</p>
          </RenderIf>
          <RenderIf condition={identity.isTruthyString(event?.category.name)}>
            <p className="category-tag__border-tag">{ event?.category.name }</p>
          </RenderIf>
          <RenderIf condition={identity.isTruthyString(event?.type)}>
            <p className="category-tag__purple-tag">{ event?.type }</p>
          </RenderIf>
        </div>
        <RenderIf condition={identity.isTruthyString(event?.startingTime)}>
          <div className="edit-event-drawer__evet-details__label">
            <p>{ `${eventTime} - ${endingTime}` || NOT_SPECIFIED }</p>
            <p>{`${event?.location!}` || NOT_SPECIFIED}</p>
          </div>
        </RenderIf>
        <RenderIf condition={identity.isTruthyString(event?.description)}>
          <DataColumn label="Description">
            {event?.description!}
          </DataColumn>
        </RenderIf>
      </div>
    </div>
  )
}

export default EventDetailsOverview;
