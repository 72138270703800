import produce from 'immer';

import {
  SetRequestedServicePayload,
  ServiceProviderActionType,
  SetServiceProviderPayload,
} from 'types/reduxTypes/ActionTypes/ServiceProviderTypes';
import { ServiceProviderStateType } from 'types/reduxTypes/StateTypes';
import { ChangeRequestStatusPayload } from 'types/reduxTypes/ActionTypes/RequestsTypes';
import { SetStartupByIdPayload } from 'types/reduxTypes/ActionTypes/StartupsTypes';
import { DataPayload, IsLoadingPayload } from 'types/reduxTypes/ActionTypes';
import { ServiceProviderType } from 'types/serviceProviders';
import { StatusType } from 'types/requests';
import { changeRequestStatusHelper, serviceProviderDefaultState, setRequestedServiceStatusHelper } from '../../utils';
import { types } from '../../actions/serviceProviders';

const defaultState: ServiceProviderStateType = serviceProviderDefaultState;

const handleSetServiceProviders = (state: ServiceProviderStateType, payload: DataPayload<ServiceProviderType[]>) => {
  state.serviceProviders = payload.data;
  return state;
}

const handleSetServiceProviderById = (state: ServiceProviderStateType, payload: SetServiceProviderPayload) => {
  state.details.data = payload.serviceProvider;
  return state;
}

const handleSetStartupDetails = (state: ServiceProviderStateType, payload: SetStartupByIdPayload) => {
  state.details.startup.details = payload.startup;
  return state;
}

const handleSetServiceProviderLoading = (state: ServiceProviderStateType, payload: IsLoadingPayload) => {
  state.isLoading = payload.isLoading;
  return state;
}

const handleSetRequestStatusChange = (state: ServiceProviderStateType, payload: ChangeRequestStatusPayload) => {
  const { id, status } = payload;
  const { details: { requests } } = state;
  state.details.requests = changeRequestStatusHelper(id, requests, status as StatusType);
  return state;
}

const handleSetRequestedServiceStatus = (state: ServiceProviderStateType, payload: SetRequestedServicePayload) => {
  const { id, serviceStatus } = payload;
  const { details } = state;
  state.details.services = setRequestedServiceStatusHelper(id, details.services, serviceStatus);
}

const handleSetServiceProvidersCount = (state: ServiceProviderStateType, payload: DataPayload<number>) => {
  state.serviceProvidersCount = payload.data;
  return state;
}

// eslint-disable-next-line default-param-last
export default produce((state: ServiceProviderStateType = defaultState, action: ServiceProviderActionType) => {
  const { type, payload } = action;
  switch (type) {
    case types.SET_SERVICE_PROVIDERS: {
      return handleSetServiceProviders(state, <DataPayload<ServiceProviderType[]>>payload);
    }
    case types.SET_SERVICE_PROVIDER_BY_ID: {
      return handleSetServiceProviderById(state, <SetServiceProviderPayload>payload);
    }
    case types.SET_STARTUP_DETAILS_ON_SPD: {
      return handleSetStartupDetails(state, <SetStartupByIdPayload>payload);
    }
    case types.SET_SERVICE_PROVIDER_LOADING: {
      return handleSetServiceProviderLoading(state, <IsLoadingPayload>payload)
    }
    case types.SET_SERVICE_PROVIDER_REQUEST_STATUS: {
      return handleSetRequestStatusChange(state, <ChangeRequestStatusPayload>payload);
    }
    case types.SET_REQUESTED_SERVICE: {
      return handleSetRequestedServiceStatus(state, <SetRequestedServicePayload>payload);
    }
    case types.SET_SERVICE_PROVIDERS_COUNT: {
      return handleSetServiceProvidersCount(state, <DataPayload<number>>payload);
    }
    case types.RESET_SP: {
      return defaultState;
    }
    default: {
      return state;
    }
  }
});
