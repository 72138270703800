import React from 'react';

import { TitleWithActions } from 'components/components';
import { RecentRequestsProps } from 'types/componentTypes/Dashboard';
import { RequestRow } from 'components/RequestsOverview/components';
import routes from 'routes';
import { identity } from 'utils';

import './RecentRequests.scss';

const RecentRequests = (props: RecentRequestsProps) => {
  const { requests } = props;

  return (
    <div className="recent-requests">
      <TitleWithActions
        title="Recent requests"
        size="xs"
        showButton
        buttonTitle="View all"
        link={routes.requestsUrl}
      />
      <div className="requests-overview">
        <div className="requests-container">
          {identity.isFullArray(requests)
            ? requests.map((request, index) => <RequestRow request={request} key={`request-${request.id || index}`} />)
            : 'No requests'}
        </div>
      </div>
    </div>
  );
}

export default RecentRequests;
