import React, { useMemo } from 'react';
import { Radio as AntdRadio, Space } from 'antd';

import { RadioProps } from 'types/componentTypes';

import './Radio.scss';

const Radio = (props: RadioProps) => {
  const { value, defaultValue, onChange } = props;

  const radioButtons = useMemo(() => props.values?.map(({
    value: val, key, title, disabled,
  }) => (
    <AntdRadio
      disabled={disabled}
      value={val}
      className="input-radio"
      key={key}
      checked={val === value}
    >
      {title}
    </AntdRadio>
  )), [props.values]);

  return (
    <AntdRadio.Group
      value={value}
      defaultValue={defaultValue}
      onChange={onChange}
    >
      <Space direction={props.direction}>
        {radioButtons}
      </Space>
    </AntdRadio.Group>
  )
}

Radio.defaultProps = {
  defaultValue: null,
  direction: 'vertical',
}

export default Radio;
