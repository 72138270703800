import { DEFAULT_API_HOST } from 'packages/http_client';
import identity from '../identity';
import config from '../../assets/config';

const getImageLink = (fileUrl: string) => {
  if (!identity.isTruthyString(fileUrl)) {
    return '';
  }

  let url = fileUrl;
  if (fileUrl.indexOf('://') === -1) { // removing https from match in case BE sends S3 URLs
    url = `${config.DEFAULT_APPLICATION_HOST_URL}${fileUrl}`;
  }

  return url;
};

const getCurrencyAmountString = (amount: string) => {
  if (identity.isTruthyString(amount)) {
    // TODO: Make improvements by adding commas to the currency amount
    return `AED ${amount}`;
  }

  return '';
};

const getLabelBasedOnValueCount = (label: string, count: number) => {
  if (count <= 0) {
    return `0 ${label}`;
  }

  if (count > 1) {
    return `${count} ${label}s`;
  }

  return `${count} ${label}`;
};

const stringToArray = (value: string): string[] => {
  if (identity.isEmptyString(value)) {
    return [];
  }
  return value.split(',').filter((item) => identity.isTruthyString(item.trim())).map((item) => item.trim());
}

const arrayToString = (values?: Array<string | number>): string => {
  if (identity.isEmptyArray(values)) {
    return '';
  }
  const valuesWithoutEmptyStrings = values!
    .filter((item: string | number) => identity.isTruthyString(item.toString().trim()));
  return valuesWithoutEmptyStrings!.join(', ');
}

const arrayToLabelValue = (array: string[]) => array.map((item) => ({ label: item, value: item }));

const getFullPhotoUrl = (photo: string) => (photo?.includes(DEFAULT_API_HOST) ? photo : `${DEFAULT_API_HOST}${photo}`);

export {
  arrayToString,
  stringToArray,
  getLabelBasedOnValueCount,
  getCurrencyAmountString,
  getImageLink,
  arrayToLabelValue,
  getFullPhotoUrl,
}
