import { connect } from 'react-redux';

import { StateType } from '../../../../types';
import { selectCohortData } from '../../../../redux/selectors/cohort';

const mapStateToProps = (state: StateType) => ({
  selectedCohort: selectCohortData(state),
});

export default connect(mapStateToProps);
