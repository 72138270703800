import { connect } from 'react-redux';

import { StateType } from 'types';
import { selectIsAuthenticated } from 'redux/selectors/auth';

const mapStateToProps = (state: StateType) => ({
  isAuthenticated: selectIsAuthenticated(state),
});

export default connect(mapStateToProps);
