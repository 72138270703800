export const checkBoxOptions = [
  {
    label: 'Same as contact person',
    value: 'Same as contact person',
  },
];

export const roleOptions = [
  {
    label: 'Team member',
    value: 'team member',
  },
  {
    label: 'Founder',
    value: 'founder',
  },
];
