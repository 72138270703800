import { connect } from 'react-redux';
import { Action, bindActionCreators, Dispatch } from 'redux';

import { StateType } from 'types';
import { setDrawerBody, setIsDrawerOpen } from 'redux/actions/workspace';
import { getServiceById } from 'redux/actions/services';
import { selectStartupStatus } from 'redux/selectors/auth';

const mapStateToProps = (state: StateType) => ({
  startupStatus: selectStartupStatus(state),
})

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  actions: bindActionCreators({
    setIsDrawerOpen,
    setDrawerBody,
    getServiceById,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps);
