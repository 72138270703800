import React from 'react';
import { ImageIcon } from 'assets/icons';

import { identity } from 'utils';
import { StartupLogoProps } from 'types/startups/editStartup';

import '../../EditStartup.scss';

const StartupLogo = (props: StartupLogoProps) => {
  const { imageUrl, name, onClick } = props;
  return (
    <div className="edit-startup__image-container" onClick={onClick} onKeyDown={() => null}>
      {identity.isTruthyString(imageUrl)
        ? <img className="startup_image" src={imageUrl} alt={`${name} logo`} loading="lazy" />
        : (
          <div className="startup__no-image">
            <ImageIcon />
          </div>
        )}
    </div>
  );
}

export default StartupLogo;
