import { connect } from 'react-redux';
import { Action, bindActionCreators, Dispatch } from 'redux';

import { getStartupInvestmentStages } from 'redux/actions/startups';
import {
  createPerformanceReport,
  getPerformanceReportById,
  resetAll,
  updatePerformanceReportById,
} from 'redux/actions/performance';
import { StateType } from 'types';
import { selectIsLoading, selectPerformanceReportDetails } from 'redux/selectors/performance';
import { selectUserStartupId } from 'redux/selectors/auth';

const mapStateToProps = (state: StateType) => ({
  isLoading: selectIsLoading(state),
  startupId: selectUserStartupId(state),
  performanceReportDetails: selectPerformanceReportDetails(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  actions: bindActionCreators({
    getStartupInvestmentStages,
    createPerformanceReport,
    getPerformanceReportById,
    updatePerformanceReportById,
    resetAll,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps);
