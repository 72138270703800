import React, { useCallback, useEffect } from 'react'
import { useParams } from 'react-router-dom';

import history from 'history_instance';
import { composeServiceProviderDetailsUrl } from 'routes/routeComposers';
import { DRAWERS, getRowKey } from 'utils';
import { servicesColumns } from 'components/utils/constants/columns';
import { SingleServiceOverviewProps } from 'types/services';
import { GridTable } from '../components/Table/components';
import { ROLE_BASED_COMPONENT_TYPE } from './utils/constants';

const SingleServiceOverview = (props: SingleServiceOverviewProps) => {
  const {
    service,
    unsentRequests,
    isLoading,
    actions,
    userType,
  } = props;

  const params: { id?: string } = useParams();
  const { id } = params;

  useEffect(() => {
    actions.getServiceById({ id: Number(id) });
    actions.getUnsentRequests();
  }, [id]);

  const handleDelete = useCallback(() => {
    actions.deleteService({ id: service.id });
  }, [service]);

  const openServiceDrawer = useCallback(() => {
    actions.setDrawerBody({ drawerBody: DRAWERS.editService });
    actions.setIsDrawerOpen({ isDrawerOpen: true });
  }, [])

  const handleRequestService = useCallback(() => {
    actions.setDrawerBody({ drawerBody: DRAWERS.requestService })
    actions.setIsDrawerOpen({ isDrawerOpen: true });
  }, [])

  return (
    <div className="single-service-overview content-container">
      <GridTable
        isLoading={isLoading}
        unsentRequests={unsentRequests}
        dataSource={[service]}
        columns={servicesColumns}
        rowKey={getRowKey([service], 'services', 'id')}
        component={ROLE_BASED_COMPONENT_TYPE[userType]}
        onDeleteService={handleDelete}
        onEditService={openServiceDrawer}
        onRequestService={handleRequestService}
        firstButtonTitle="View profile"
        firstButtonAction={() => history.push(composeServiceProviderDetailsUrl(service.serviceProvider.id))}
      />
    </div>
  )
}

export default SingleServiceOverview;
