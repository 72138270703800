import React, { useEffect, useState } from 'react';
import classnames from 'classnames';

import { ListIcon } from 'assets/icons';
import IconWrapper from 'components/LayoutWithSidebar/components/Sidebar/IconWrapper';
import { PartnerTabButtonProps } from 'types/partners/partnersOverview';
import { getOffset } from '../../../../../utils';

const PartnersTabButton = (props: PartnerTabButtonProps) => {
  const { value, currentTab, tableState } = props;
  const { setCurrentPartnerTab, getPartners } = props.actions;
  const { limit, currentPage } = tableState;

  const [previousTab, setPreviousTab] = useState('all');

  useEffect(() => {
    const offset = previousTab === currentTab ? getOffset(limit, currentPage) : 0;
    getPartners({ limit, offset });
  }, [currentTab, limit]);

  useEffect(() => {
    setPreviousTab(currentTab);
  }, [currentTab]);

  // temporary solution until this we get more information about categories and functionallity
  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events
    <div className="partners-overview-tabs-container" onClick={() => setCurrentPartnerTab({ currentTab: value })}>
      <div className={classnames('partners-overview-tabs-container__tab', { 'active-tab': currentTab === value })}>
        <IconWrapper><ListIcon /></IconWrapper>
        <p className="tab-title">{ props.value }</p>
      </div>
    </div>
  );
}
export default PartnersTabButton;
