/* Add route composer helper functions here */
import {
  cohortUrls,
  startupUrls,
  peopleUrls,
  serviceProvidersUrls,
  requestUrls,
  partnerUrls,
  performanceUrls,
  servicesUrl, walletUrl,
} from './urls'

export const composeCohortsDetailsUrl = (id: number) => `${cohortUrls.cohortsDetailUrl}/${id}`;

export const composeEditCohortUrl = (id: number) => `${cohortUrls.editCohortUrl}/${id}`;

export const composeStartupDetailsUrl = (id: number | string) => `${startupUrls.startupDetailsUrl}/${id}`;

export const composeEditStartupUrl = (id: number) => `${startupUrls.editStartupUrl}/${id}`;

export const composeEditPeopleUrl = (id: number, personType: string) => `${peopleUrls.editPeopleUrl}/${personType}/${id}`;

export const composeEditServiceProviderUrl = (id: number) => `${serviceProvidersUrls.editServiceProviderUrl}/${id}`;

export const composePeopleDetailsUrl = (id: number, personType: string) => `${peopleUrls.peopleDetailsUrl}/${personType}/${id}`;

export const composeServiceProviderDetailsUrl = (id: number) => `${serviceProvidersUrls.serviceProviderDetailsUrl}/${id}`;

export const composeServiceProviderMoreDetailsUrl = (id: number) => `${serviceProvidersUrls.serviceProviderMoreDetailsUrl}/${id}`;

export const composeRequestMoreDetailsUrl = (id: number) => `${requestUrls.requestMoreDetailsUrl}/${id}`;

export const composePartnerDetailsUrl = (id: number) => `${partnerUrls.partnerDetailsUrl}/${id}`;

export const composeEditPartnerDetailsUrl = (id: number) => `${partnerUrls.editPartnerUrl}/${id}`;

export const composeEditPerformanceReportUrl = (id: number) => `${performanceUrls.editPerformanceReportUrl}/${id}`;

export const composeServiceOverview = (id: number) => `${servicesUrl}/${id}`;

export const composeStartupWalletUrl = (id: number | string) => `${walletUrl}/${id}`;
