import React from 'react';
import { Modal } from 'antd';

import { CloseIcon } from 'assets/icons';
import { DeleteConfirmModalProps } from 'types/componentTypes';

import './DeleteConfirmModal.scss';

const DeleteConfirmModal = (props: DeleteConfirmModalProps) => {
  const {
    isOpen,
    onCancel,
    itemName,
    onConfirm,
    isDeleteAll,
  } = props;

  return (
    <Modal
      open={isOpen}
      onCancel={onCancel}
      onOk={onConfirm}
      okText="Delete"
      cancelText="Cancel"
      closeIcon={<CloseIcon />}
      title={`Delete ${itemName}`}
      className="delete-confirm-modal"
    >
      {`You are about to delete ${isDeleteAll ? 'all' : ''} ${itemName}. You can't undo this action.`}
    </Modal>
  );
}

DeleteConfirmModal.defaultProps = {
  isDeleteAll: false,
}

export default DeleteConfirmModal;
