import {
  all, call, put, select, takeLatest,
} from 'redux-saga/effects';

import {
  count, isIn, orderByDescending, queryBuilder,
} from 'packages/http_client';
import {
  getNotifications, GetNotificationsParams, NotificationsParams, NotificationType, updateNotifications,
} from 'packages/notification_repository';
import {
  setAllFetched,
  setIsLoading, setNewNotificationsCount, setNotifications, types,
} from 'redux/actions/notification';
import { selectUserId } from 'redux/selectors/auth';
import {
  selectLimit, selectNotifications, selectOffset, selectOrderBy,
} from 'redux/selectors/notifications';
import showNotification from 'services/utils/showNotification';
import { identity } from 'utils';

function * handleGetNotifications() {
  yield put(setIsLoading({ isLoading: true }));
  yield put(setAllFetched({ data: false }));

  const [userId, offset, limit, orderBy]
  :[userId: number, offset: number, limit: number, orderBy: string] = yield all([
    select(selectUserId),
    select(selectOffset),
    select(selectLimit),
    select(selectOrderBy),
  ]);

  const params: GetNotificationsParams = {
    user_id: userId,
    $preload: 1,
    $limit: limit,
    $offset: offset,
    $order: orderBy || orderByDescending('date_time'),
  }

  const { error, data } = yield call(getNotifications, params);

  if (identity.isObjWithChildren(error)) {
    yield put(setIsLoading({ isLoading: false }));
    return;
  } if (identity.isFalsy(data?.length)) {
    yield put(setAllFetched({ data: true }));
  } else {
    yield put(setNotifications({ data }));
  }

  yield put(setIsLoading({ isLoading: false }));
}

function * handleGetNewNotificationsCount() {
  const userId: number = yield select(selectUserId);
  const params: GetNotificationsParams = queryBuilder({ user_id: userId, seen: false })
    .select([count('id')])
    .toObject();

  const { error, data } = yield call(getNotifications, params);

  if (!identity.isObjWithChildren(error)) {
    yield put(setNewNotificationsCount({ data: data[0].id__count }));
  }
}

function * handleSetNotificationsSeen() {
  const notifications: NotificationType[] = yield select(selectNotifications);
  const ids = notifications.filter((n) => !n.seen).map((n) => n.id);
  const params: NotificationsParams = {
    id__in: isIn(ids),
  }

  const payload = { seen: true };

  const { error, httpStatus } = yield call(updateNotifications, payload, params);
  if (identity.isObjWithChildren(error)) {
    showNotification(error?.message, true, httpStatus);
  } else {
    yield put(setNewNotificationsCount({ data: 0 }));
  }
}

export default function * notificationsSaga() {
  yield all([
    takeLatest(types.GET_NOTIFICATIONS, handleGetNotifications),
    takeLatest(types.GET_NEW_NOTIFICATIONS_COUNT, handleGetNewNotificationsCount),
    takeLatest(types.SET_NOTIFICATIONS_SEEN, handleSetNotificationsSeen),
  ]);
}
