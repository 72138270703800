import React, { useMemo } from 'react';
import { Button } from 'antd';

import { identity, parseNumberToPrice } from 'utils';
import { RequestsTotalProps } from 'types/requests';

const RequestsTotal = (props: RequestsTotalProps) => {
  const { unsentRequests } = props;
  const { deleteDraftRequest, sendRequest } = props.actions;

  const grandTotal = useMemo(() => {
    const prices = unsentRequests.map((item) => item.price);
    return prices.reduce((previous, current) => previous + current, 0);
  }, [unsentRequests]);

  const id = useMemo(() => (
    identity.isFullArray(unsentRequests) ? unsentRequests[0].serviceRequestId : 0
  ), [unsentRequests]);

  if (identity.isEmptyArray(unsentRequests)) {
    return null;
  }

  return (
    <div className="requests-total">
      <div className="requests-total--row">
        <span className="requests-total--row-item">Total</span>
        <span className="requests-total--row-item">{parseNumberToPrice(grandTotal, 'AED')}</span>
      </div>
      <div className="requests-total--row buttons-container">
        <Button onClick={() => deleteDraftRequest()} className="secondary-button">Discard all requests</Button>
        <Button className="primary-button" onClick={() => sendRequest({ id, totalPrice: grandTotal })}>Send request</Button>
      </div>
    </div>
  );
}

export default RequestsTotal;
