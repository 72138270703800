import React from 'react';
import { Checkbox } from 'antd';
import { CheckboxGroupProps } from 'antd/lib/checkbox';
import classNames from 'classnames';

import './CheckboxGroup.scss';

const CheckBoxGroup = (props: CheckboxGroupProps) => {
  const { onChange, options, value } = props;

  return (
    <Checkbox.Group
      className={classNames('checkbox-group', props.className)}
      onChange={onChange}
      options={options}
      value={value}
    />
  )
}

export default CheckBoxGroup
