/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react';

import { AllCategoriesButtonProps } from '../../../../../types/componentTypes/SearchBarProps';
import Input from '../../../Input';
import { allCategories } from '../../utils/constants';

const AllCategoriesButton = (props: AllCategoriesButtonProps) => {
  const { showAllCategories } = props;
  const [checkedValue, setCheckedValue] = useState([]);

  if (!showAllCategories) {
    return null;
  }

  const onChange = (checkedValues: any) => {
    setCheckedValue(checkedValues)
  };

  const typeMenu = (
    <div className="search-bar-dropdown">
      <Input.CheckboxGroup
        onChange={onChange}
        options={allCategories}
        value={checkedValue}
      />
    </div>
  );

  return (
    <Input className="type-button-container" layout="vertical">
      <Input.Dropdown
        className="type-button"
        overlay={typeMenu}
      >
        All categories
      </Input.Dropdown>
    </Input>
  )
}

AllCategoriesButton.defaultProps = {
  showTypeButton: false,
}

export default AllCategoriesButton;
