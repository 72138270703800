import React from 'react';

import { AddEmployeeFieldsCreatorParams, AddEmployeeMemberFormProps } from 'types/people';
import FormBuilder from '../../../components/FormBuilder';
import { addEmployeeFields, defaultValues, MEMBER_TYPES } from '../../utils/constants';

const AddEmployeeForm = (props: AddEmployeeMemberFormProps) => {
  const { beforeHeadshotUpload } = props;

  const memberFieldsCreateData: AddEmployeeFieldsCreatorParams = { beforeHeadshotUpload };

  return (
    <FormBuilder
      formFragment
      fields={addEmployeeFields(memberFieldsCreateData)}
      defaultValues={defaultValues[MEMBER_TYPES.employee]}
    />
  )
}

export default AddEmployeeForm;
