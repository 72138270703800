import React from 'react';

import SVGWrap from './SVGWrap';

const WalletIcon = () => (
  <SVGWrap>
    <path d="M12 8.5H11V9.5H12V8.5Z" />
    <path d="M14 4H2V2.5H13V1.5H2C1.73478 1.5 1.48043 1.60536 1.29289 1.79289C1.10536 1.98043 1 2.23478 1 2.5V13C1 13.2652 1.10536 13.5196 1.29289 13.7071C1.48043 13.8946 1.73478 14 2 14H14C14.2652 14 14.5196 13.8946 14.7071 13.7071C14.8946 13.5196 15 13.2652 15 13V5C15 4.73478 14.8946 4.48043 14.7071 4.29289C14.5196 4.10536 14.2652 4 14 4ZM2 13V5H14V6.5H10C9.73478 6.5 9.48043 6.60536 9.29289 6.79289C9.10536 6.98043 9 7.23478 9 7.5V10.5C9 10.7652 9.10536 11.0196 9.29289 11.2071C9.48043 11.3946 9.73478 11.5 10 11.5H14V13H2ZM14 7.5V10.5H10V7.5H14Z" />
  </SVGWrap>
);

export default WalletIcon;
