import React from 'react'
import { Button, Form } from 'antd';
import classNames from 'classnames';

import { FormBuilderProps } from 'types/componentTypes/FormBuilder';
import { FormRows } from './components';

import './FormBuilder.scss';

const FormBuilder = (props: FormBuilderProps) => {
  const {
    fields, formClassName, onSubmit, defaultValues, buttonText, formFragment, oneColumn, layout,
  } = props;

  const [form] = Form.useForm();

  if (formFragment) {
    return <FormRows fields={fields} oneColumn={oneColumn} form={form} layout={layout} />
  }

  const onFinish = onSubmit || (() => {});

  return (
    <Form
      className={classNames('form-builder', formClassName)}
      initialValues={defaultValues}
      onFinish={onFinish}
      scrollToFirstError
      form={form}
    >
      <FormRows fields={fields} oneColumn={oneColumn} form={form} />
      <Button htmlType="submit" className="primary-button form-builder-button">{buttonText}</Button>
    </Form>
  );
}

FormBuilder.defaultProps = {
  formClassName: '',
  defaultValues: {},
  oneColumn: false,
  layout: 'vertical',
  onSubmit: () => {},
}

export default FormBuilder;
