import React, { useEffect, useState } from 'react';
import { Form } from 'antd';
import { RcFile } from 'antd/lib/upload';

import { ServiceRequestDocumentType } from 'packages/service_request_repository';
import { DataPayload } from 'types/reduxTypes/ActionTypes';
import { Input } from 'components/components';
import { DocumentsFormProps } from 'types/services/requestService';
import Row from 'components/components/FormBuilder/components/Row';
import { identity } from 'utils';
import DocumentBox from '../DocumentBox';

const DocumentUploadForm = (props: DocumentsFormProps) => {
  const {
    state,
    setPassportFile,
    setVisaFile,
    setVisaProofFile,
    setEmiratesIDFile,
    setRequestDocuments,
    setETAWithSignFile,
  } = props;

  const { teamMember } = state.request;
  const handleBeforeUpload = (file: RcFile, functionCall: (_payload: DataPayload<string | Blob>) => void) => {
    functionCall({ data: file as Blob });
    return false;
  }

  const [passport, setPassport] = useState(teamMember?.passport);
  const [visa, setVisa] = useState(teamMember?.visa);
  const [viseProof, setVisaProof] = useState(teamMember?.visaProof);
  const [emiratesID, setEmiratesID] = useState(teamMember?.emiratesId);
  const [ETAWithSign, setETAWithSign] = useState(teamMember?.ETAWithSign);

  const documents: ServiceRequestDocumentType[] = [];
  useEffect(() => {
    if (identity.isObjWithChildren(state.request.passportFile)) {
      documents.push({ name: 'Passport', document: state.request.passportFile });
    }
    if (identity.isTruthyString(teamMember?.passport)) {
      documents.push({ name: 'Passport', document: teamMember?.passport });
    }
    if (identity.isObjWithChildren(state.request.visaFile)) {
      documents.push({ name: 'Visa', document: state.request.visaFile });
    }
    if (identity.isTruthyString(teamMember?.visa)) {
      documents.push({ name: 'Visa', document: teamMember?.visa });
    }
    if (identity.isObjWithChildren(state.request.visaProofFile)) {
      documents.push({ name: 'Visa Proof', document: state.request.visaProofFile });
    }
    if (identity.isTruthyString(teamMember?.visaProof)) {
      documents.push({ name: 'Visa Proof', document: teamMember?.visaProof });
    }
    if (identity.isObjWithChildren(state.request.emiratesIdFile)) {
      documents.push({ name: 'Emirates ID', document: state.request.emiratesIdFile });
    }
    if (identity.isTruthyString(teamMember?.emiratesId)) {
      documents.push({ name: 'Emirates ID', document: teamMember?.emiratesId });
    }
    if (identity.isObjWithChildren(state.request.ETAWithSign)) {
      documents.push({ name: 'ETA With Sign', document: state.request.ETAWithSign });
    }
    if (identity.isTruthyString(teamMember?.ETAWithSign)) {
      documents.push({ name: 'ETA With Sign', document: teamMember?.ETAWithSign });
    }
    setRequestDocuments({ ...state.request.documents, data: documents });
  }, [state.request.visaFile, state.request.visaProofFile]);

  const displayEvisa = state.request.relationToTM === 'spouse' && state.request.isDependantUAE === 'no';
  const isSpouse = state.request.relationToTM === 'spouse';
  const isUserUAEResident = state.request.childPlaceOfBirth === 'other' && state.request.relationToTM === 'child';

  return (
    <div>
      {isSpouse ? (
        <Form>
          <Row oneColumn>
            { identity.isTruthyString(passport) ? (
              <div>
                <p className="label1">Passport</p>
                <DocumentBox fileName="Passport" onDelete={() => setPassport('')} />
              </div>
            ) : (
              <Input name="passport" label="Passport" layout="vertical" required>
                <Input.DraggerUpload
                  previewType="text"
                  accept=".pdf"
                  beforeUpload={(file) => handleBeforeUpload(file, setPassportFile)}
                  allowChange
                  isRoundableButton={false}
                />
              </Input>
            )}
          </Row>
          { displayEvisa ? (
            <Row oneColumn>
              { identity.isTruthyString(visa) ? (
                <div>
                  <p className="label1">E-Visa / Entry permit</p>
                  <DocumentBox fileName="E-Visa" onDelete={() => setVisa('')} />
                </div>
              ) : (
                <Input name="visa" label="E-Visa / Entry permit" layout="vertical" required>
                  <Input.DraggerUpload
                    previewType="text"
                    accept=".pdf"
                    beforeUpload={(file) => handleBeforeUpload(file, setVisaFile)}
                    allowChange
                    isRoundableButton={false}
                  />
                </Input>
              )}
            </Row>
          ) : (
            <Row oneColumn>
              { identity.isTruthyString(teamMember?.visa) ? (
                <div>
                  <p className="label1">Visa</p>
                  <DocumentBox fileName="Visa" onDelete={() => setVisa('')} />
                </div>
              ) : (
                <Input name="visa" label="Visa" layout="vertical" required>
                  <Input.DraggerUpload
                    previewType="text"
                    accept=".pdf"
                    beforeUpload={(file) => handleBeforeUpload(file, setVisaFile)}
                    allowChange
                    isRoundableButton={false}
                  />
                </Input>
              )}
            </Row>
          ) }
          { displayEvisa ? (
            <Row oneColumn>
              { identity.isTruthyString(ETAWithSign) ? (
                <div>
                  <p className="label1">Visit visa copy / Passport visa copy with entry stamp</p>
                  <DocumentBox
                    fileName="Visa copy"
                    onDelete={() => setETAWithSign('')}
                  />
                </div>
              ) : (
                <Input name="visaProof" label="Visit visa copy / Passport visa copy with entry stamp" layout="vertical" required>
                  <Input.DraggerUpload
                    previewType="text"
                    accept=".pdf"
                    beforeUpload={(file) => handleBeforeUpload(file, setETAWithSignFile)}
                    allowChange
                    isRoundableButton={false}
                  />
                </Input>
              ) }
            </Row>
          ) : (
            <Row oneColumn>
              { identity.isTruthyString(viseProof) ? (
                <div>
                  <p className="label1">Continuity of insurance / Previous visa copy</p>
                  <DocumentBox
                    fileName="Previous_visa_copy"
                    onDelete={() => setVisaProof('')}
                  />
                </div>
              ) : (
                <Input name="visaProof" label="Continuity of insurance / Previous visa copy" layout="vertical" required>
                  <Input.DraggerUpload
                    previewType="text"
                    accept=".pdf"
                    beforeUpload={(file) => handleBeforeUpload(file, setVisaProofFile)}
                    allowChange
                    isRoundableButton={false}
                  />
                </Input>
              ) }
            </Row>
          ) }
          { displayEvisa ? (
            <Row oneColumn>
              { identity.isTruthyString(emiratesID) ? (
                <div>
                  <p className="label1">Emirates ID Application</p>
                  <DocumentBox fileName="Emirates_ID_Application" onDelete={() => setEmiratesID('')} />
                </div>
              ) : (
                <Input name="emiratesId" label="Emirates ID Application" layout="vertical" required>
                  <Input.DraggerUpload
                    previewType="text"
                    accept=".pdf"
                    beforeUpload={(file) => handleBeforeUpload(file, setEmiratesIDFile)}
                    allowChange
                    isRoundableButton={false}
                  />
                </Input>
              ) }
            </Row>
          ) : (
            <Row oneColumn>
              { identity.isTruthyString(emiratesID) ? (
                <div>
                  <p className="label1">Emirates ID</p>
                  <DocumentBox fileName="Emirates_ID" onDelete={() => setEmiratesID('')} />
                </div>
              ) : (
                <Input name="emiratesId" label="Emirates ID" layout="vertical" required>
                  <Input.DraggerUpload
                    previewType="text"
                    accept=".pdf"
                    beforeUpload={(file) => handleBeforeUpload(file, setEmiratesIDFile)}
                    allowChange
                    isRoundableButton={false}
                  />
                </Input>
              ) }
            </Row>
          ) }
        </Form>
      ) : (
        <Form>
          { !isUserUAEResident ? (
            <Row oneColumn>
              { identity.isTruthyString(passport) ? (
                <div>
                  <p className="label1">Birth certificate or Passport</p>
                  <DocumentBox fileName="Passport" onDelete={() => setPassport('')} />
                </div>
              ) : (
                <Input name="passport" label="Birth certificate or Passport" layout="vertical" required>
                  <Input.DraggerUpload
                    previewType="text"
                    accept=".pdf"
                    beforeUpload={(file) => handleBeforeUpload(file, setPassportFile)}
                    allowChange
                    isRoundableButton={false}
                  />
                </Input>
              )}
            </Row>
          ) : (
            <div>
              <Row oneColumn>
                { identity.isTruthyString(passport) ? (
                  <div>
                    <p className="label1">Passport</p>
                    <DocumentBox
                      fileName="Passport"
                      onDelete={() => setPassport('')}
                    />
                  </div>
                ) : (
                  <Input name="passport" label="Passport" layout="vertical" required>
                    <Input.DraggerUpload
                      previewType="text"
                      accept=".pdf"
                      beforeUpload={(file) => handleBeforeUpload(file, setPassportFile)}
                      allowChange
                      isRoundableButton={false}
                    />
                  </Input>
                )}
              </Row>
              <Row oneColumn>
                { identity.isTruthyString(visa) ? (
                  <div>
                    <p className="label1">E-Visa / Entry permit</p>
                    <DocumentBox fileName="Entry_permit" onDelete={() => setVisa('')} />
                  </div>
                ) : (
                  <Input name="visa" label="E-Visa / Entry permit" layout="vertical" required>
                    <Input.DraggerUpload
                      previewType="text"
                      accept=".pdf"
                      beforeUpload={(file) => handleBeforeUpload(file, setVisaFile)}
                      allowChange
                      isRoundableButton={false}
                    />
                  </Input>
                )}
              </Row>
              <Row oneColumn>
                { identity.isTruthyString(ETAWithSign) ? (
                  <div>
                    <p className="label1">Visit visa copy / Passport visa copy with entry stamp</p>
                    <DocumentBox
                      fileName="Visa_copy"
                      onDelete={() => setETAWithSign('')}
                    />
                  </div>
                ) : (
                  <Input name="visaProof" label="Visit visa copy / Passport visa copy with entry stamp" layout="vertical" required>
                    <Input.DraggerUpload
                      previewType="text"
                      accept=".pdf"
                      beforeUpload={(file) => handleBeforeUpload(file, setETAWithSignFile)}
                      allowChange
                      isRoundableButton={false}
                    />
                  </Input>
                ) }
              </Row>
              <Row oneColumn>
                { identity.isTruthyString(emiratesID) ? (
                  <div>
                    <p className="label1">Emirates ID Application</p>
                    <DocumentBox fileName="Emirates_ID_Application" onDelete={() => setEmiratesID('')} />
                  </div>
                ) : (
                  <Input name="emiratesId" label="Emirates ID Application" layout="vertical" required>
                    <Input.DraggerUpload
                      previewType="text"
                      accept=".pdf"
                      beforeUpload={(file) => handleBeforeUpload(file, setEmiratesIDFile)}
                      allowChange
                      isRoundableButton={false}
                    />
                  </Input>
                ) }
              </Row>
            </div>
          )}
        </Form>
      )}
    </div>

  );
}

export default DocumentUploadForm;
