import identity from 'utils/identity'
import {
  CohortType,
  CreateCohortApiRequestPayload,
  CreateCohortPayload,
  CreateProgramPayload,
  CreateProgramType,
  GetCohortsListApiResponseType,
  ResponseCohortType,
  UpdateCohortApiRequestPayload,
} from '../types'

export const convertCreateCohortRequestPayload = (payload: CreateCohortPayload): CreateCohortApiRequestPayload => ({
  _about: payload.about,
  _acceptance_letter_template_id: payload.acceptanceLetterTemplate,
  _amount: payload.amount,
  _logo: payload.logo,
  _csv_file: payload.csvFile,
  _name: payload.name,
  _program_from: payload.from,
  _program_to: payload.to,
  _program_id: payload.type,
  _nomination_from: payload.selectionFrom,
  _nomination_to: payload.selectionTo,
});

export const convertEditCohortRequestPayload = (payload: CohortType): UpdateCohortApiRequestPayload => ({
  _program_from: payload.from,
  _name: payload.name,
  _program_to: payload.to,
  _nomination_from: payload.selectionFrom,
  _nomination_to: payload.selectionTo,
  _amount: payload.amount,
});

export const convertGetCohortResponse = (cohort?: ResponseCohortType): CohortType | undefined => {
  if (identity.isObjWithChildren(cohort)) {
    return {
      id: cohort?.id!,
      name: cohort?.name!,
      type: cohort?.program?.name!,
      from: cohort?.programFrom!,
      to: cohort?.programTo!,
      amount: `${cohort?.amount}`,
      about: cohort?.about!,
      startups: 0,
      acceptanceLetterTemplate: cohort?.acceptanceLetterTemplate?.name,
      startupCsvFile: cohort?.cSVFile!,
      program: cohort?.program,
      imageLink: cohort?.logo!,
      selectionFrom: cohort?.nominationFrom,
      selectionTo: cohort?.nominationTo,
    };
  }
}

export const convertGetCohortsListResponse = (response: GetCohortsListApiResponseType | undefined): CohortType[] => {
  const data: CohortType[] = [];
  if (identity.isFullArray(response?.result)) {
    response?.result!.forEach((cohort: ResponseCohortType) => {
      const mappedCohort = convertGetCohortResponse(cohort);
      if (identity.isObjWithChildren(mappedCohort)) {
        data.push(mappedCohort!);
      }
    });
  }

  return data;
}

export const convertProgramTypeBody = (payload: CreateProgramType): CreateProgramPayload => ({
  _name: payload.name,
  _amount: payload.amount,
  _description: payload.description,
  _image: payload.image,
});
