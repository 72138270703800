import React from 'react';
import { Link } from 'react-router-dom';
import { Avatar, Popover } from 'antd';

import { ProfilePopoverProps } from 'types/reduxTypes/ActionTypes/WorkspaceTypes';
import routes from 'routes';
import { UserIcon } from 'assets/icons';
import { isEitherAdmin, isStartup } from 'utils';
import { RenderIf } from 'components/components';

import './ProfilePopover.scss';

const ProfilePopover = (props: ProfilePopoverProps) => {
  const { actions, loggedInUser, userType } = props;

  const onLogout = () => {
    actions.logout();
  }

  const content = (
    <div className="profile-content-col">
      <Link className="avatar-options" to={routes.myProfileUrl}>My profile</Link>
      <RenderIf condition={!isEitherAdmin(userType)}>
        <Link
          className="avatar-options"
          to={isStartup(userType) ? routes.startupProfileUrl : routes.companyProfileUrl}
        >
          {`${isStartup(userType) ? 'Startup' : 'Company'} profile`}
        </Link>
      </RenderIf>
      <Link className="sign-out" onClick={onLogout} type="link" to={routes.loginUrl}>SIGN OUT</Link>
    </div>
  );

  return (
    <Popover
      overlayClassName="popover-inner-style"
      placement="bottomRight"
      content={content}
      trigger="click"
    >
      <Avatar
        className="avatar"
        size="default"
        icon={<UserIcon />}
        src={loggedInUser?.photo}
      />
    </Popover>
  );
};

export default ProfilePopover;
