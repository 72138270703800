import React from 'react';
import {
  DatePicker,
} from 'antd';
import { Moment } from 'moment';

import { isEndingBeforeStartingDateRule } from 'utils/constants/validationRules';
import { Row } from 'components/components/FormBuilder';
import Input from 'components/components/Input';

import './DateTimeFromTo.scss'

const DateTimeFromTo = (props: {
  required?: boolean,
  onChange: ((value: Moment | null, dateString: string) => void) | undefined
}) => (
  <Row>
    <Input label="Starting at" layout="vertical" name="startingTime" required={props.required}>
      <DatePicker showTime onChange={props.onChange} showSecond={false} minuteStep={15} />
    </Input>
    <Input label="Ending at" layout="vertical" name="endingTime" rules={[isEndingBeforeStartingDateRule]} required={props.required}>
      <DatePicker showTime onChange={props.onChange} showSecond={false} minuteStep={15} />
    </Input>
    {/* <Input name="startingDate" layout="vertical" rules={[isStartAfterEndingTimeRule]}>
        <TimePicker format="HH:mm" showSecond={false} minuteStep={15} />
      </Input> */ }
    {/* <Input name="startingDate" layout="vertical" rules={[isStartAfterEndingTimeRule]}>
        <TimePicker format="HH:mm" showSecond={false} minuteStep={15} />
      </Input> */ }
    {/** Hiddedn for now */}
    {/* <Input label="Ending at" layout="vertical" name="endingTime" rules={[isEndingBeforeStartingDateRule]}>
        <DatePicker showTime onChange={props.onChange} showSecond={false} />
      </Input>
      <Input name="endingDate" layout="vertical" rules={[isEndingBeforeStartTimeRule]}>
        <TimePicker format="HH:mm" showSecond={false} minuteStep={15} />
      </Input> */}
  </Row>
);

DateTimeFromTo.defaultProps = {
  required: false,
}

export default DateTimeFromTo;
