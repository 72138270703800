import { connect } from 'react-redux';
import { Action, bindActionCreators, Dispatch } from 'redux';

import { StateType } from 'types';
import {
  getEmailTemplates,
  setDeleteIds,
  getIncentiveTemplates,
  getWorkOrderTemplates,
  deleteTemplate,
  getTemplateById,
} from 'redux/actions/settingsOverview';
import {
  selectActiveSubTab,
  selectActiveTab,
  selectEmailTemplates,
  selectIncentiveTemplates,
  selectWorkOrderTemplates,
} from 'redux/selectors/settingsOverview';
import { setDrawerBody, setIsDrawerOpen } from 'redux/actions/workspace';

const mapStateToProps = (state: StateType) => ({
  emailTemplates: selectEmailTemplates(state),
  incentiveTemplates: selectIncentiveTemplates(state),
  workOrderTemplates: selectWorkOrderTemplates(state),
  activeSubTab: selectActiveSubTab(state),
  activeTab: selectActiveTab(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  actions: bindActionCreators({
    getEmailTemplates,
    getIncentiveTemplates,
    getWorkOrderTemplates,
    setDeleteIds,
    setDrawerBody,
    setIsDrawerOpen,
    deleteTemplate,
    getTemplateById,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps);
