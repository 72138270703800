import produce from 'immer';

import { EventsActionType, SetActiveEventTabPayload } from 'types/reduxTypes/ActionTypes/EventsTypes';
import { EventsStateType } from 'types/reduxTypes/StateTypes';
import { DataPayload, IsLoadingPayload } from 'types/reduxTypes/ActionTypes';
import {
  EventRSVPResponseType,
  EventCategory,
  EventAttendeeType,
  EventRSVPType,
  EventType,
} from 'packages/events_repository';
import {
  EventResponseCount,
  EventsCount,
  EventsFilters,
  EventState,
  EventType as Event,
} from 'types/events';
import { checkEventFiltersActive } from 'utils';
import { defaultEventsState } from '../../utils';
import { types } from '../../actions/events';

const defaultState: EventsStateType = defaultEventsState;

const handleSetEvents = (state: EventsStateType, payload: DataPayload<Event[]>) => {
  state.events = payload.data;
  state.filtersActive = checkEventFiltersActive(state.filters);
  return state;
}

const handleSetResponseCount = (state: EventsStateType, payload: DataPayload<EventResponseCount>) => {
  state.responseCount = payload.data;
  return state;
}

const handleSetIsLoading = (state: EventsStateType, payload: IsLoadingPayload) => {
  state.isLoading = payload.isLoading;
  return state;
}

const handleSetEventTab = (state: EventsStateType, payload: DataPayload<EventState>) => {
  state.currentTab = payload.data;
  return state;
}

const handleSetFilters = (state: EventsStateType, payload: DataPayload<EventsFilters>) => {
  state.filters = payload.data;
  return state;
}

const handleSetCurrentSubTab = (state: EventsStateType, payload: DataPayload<EventRSVPResponseType>) => {
  state.currentSubTab = payload.data;
  return state;
}

const handleSetEventsCount = (state: EventsStateType, payload: DataPayload<EventsCount>) => {
  state.count = payload.data;
  return state;
}

const handleSetEventCategories = (state: EventsStateType, payload: DataPayload<EventCategory[]>) => {
  state.eventCategories = payload.data;
  return state;
}

const handleSetEventById = (state: EventsStateType, payload: DataPayload<EventType>) => {
  state.event = payload.data;
  return state;
}

const handleSetEventId = (state: EventsStateType, payload: DataPayload<number>) => {
  state.id = payload.data;
  return state;
}

const handleSetActiveTab = (state: EventsStateType, payload: SetActiveEventTabPayload) => {
  state.details.activeTab = payload.tab;
  return state;
}

const handelSetEventAttendees = (state: EventsStateType, payload: DataPayload<EventAttendeeType[]>) => {
  state.details.eventAttendees = payload.data;
  return state;
}

const handleSetEventRSVP = (state: EventsStateType, payload: DataPayload<EventRSVPType>) => {
  state.details.eventRSVP = payload.data;
  return state;
}
// eslint-disable-next-line default-param-last
export default produce((state: EventsStateType = defaultState, action: EventsActionType) => {
  const { type, payload } = action;
  switch (type) {
    case types.SET_EVENTS: {
      return handleSetEvents(state, <DataPayload<EventType[]>>payload);
    }
    case types.SET_EVENT_TAB: {
      return handleSetEventTab(state, <DataPayload<EventState>>payload);
    }
    case types.SET_FILTERS: {
      return handleSetFilters(state, <DataPayload<EventsFilters>>payload);
    }
    case types.SET_EVENTS_IS_LOADING: {
      return handleSetIsLoading(state, <IsLoadingPayload>payload);
    }
    case types.SET_EVENT_SUB_TAB: {
      return handleSetCurrentSubTab(state, <DataPayload<EventRSVPResponseType>>payload);
    }
    case types.SET_EVENTS_COUNT: {
      return handleSetEventsCount(state, <DataPayload<EventsCount>>payload);
    }
    case types.SET_EVENTS_RESPONSE_COUNT: {
      return handleSetResponseCount(state, <DataPayload<EventResponseCount>>payload);
    }
    case types.SET_EVENT_CATEGORIES: {
      return handleSetEventCategories(state, <DataPayload<EventCategory[]>>payload);
    }
    case types.SET_EVENT_BY_ID: {
      return handleSetEventById(state, <DataPayload<EventType>>payload);
    }
    case types.SET_EVENT_ID: {
      return handleSetEventId(state, <DataPayload<number>>payload);
    }
    case types.SET_ACTIVE_EVENT_DETAILS_TAB: {
      return handleSetActiveTab(state, <SetActiveEventTabPayload>payload);
    }
    case types.SET_EVENT_ATTENDEES: {
      return handelSetEventAttendees(state, <DataPayload<EventAttendeeType[]>> payload);
    }
    case types.SET_EVENT_RSVP: {
      return handleSetEventRSVP(state, <DataPayload<EventRSVPType>>payload);
    }
    case types.RESET_EVENTS: {
      return defaultState;
    }
    default:
      return state;
  }
});
