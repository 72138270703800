import React from 'react';

import SVGWrap from './SVGWrap';

const ChevronLeftIcon = () => (
  <SVGWrap>
    <path xmlns="http://www.w3.org/2000/svg" d="M5 8L10 3L10.7 3.7L6.4 8L10.7 12.3L10 13L5 8Z" />
  </SVGWrap>
);

export default ChevronLeftIcon;
