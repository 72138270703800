import React from 'react';

import { InvoiceProps } from 'types/wallet';
import { CompanyDeckFile } from 'components/components';

import './Invoice.scss';

const Invoice = (props: InvoiceProps) => (
  <div className="invoice">
    <CompanyDeckFile fileContent={props.fileLink} />
  </div>
);

export default Invoice;
