import React from 'react'
import { Button, Form } from 'antd'
import { RcFile } from 'antd/lib/upload';

import { FormBuilder } from 'components/components'
import { AddNewTemplateProps } from 'types/reduxTypes/ActionTypes/SettingsOverviewTypes';
import { AddTemplateType } from 'packages/template_repository';
import { AddNewTemplateDrawerFields, defaultValue, pdfFileTabs } from './utils/constants'

import './AddNewTemplateDrawer.scss';

const AddNewTemplateDrawer = (props: AddNewTemplateProps) => {
  const { actions } = props;

  const [form] = Form.useForm();
  const formType = Form.useWatch('type', form);

  const handleSubmit = (data: AddTemplateType) => {
    actions.addEmailTemplate({ template: data })
  }

  const onCancel = () => {
    actions.setIsDrawerOpen({ isDrawerOpen: false });
    actions.setDestroyDrawerOnClose({ destroyDrawerOnClose: true });
  }

  const handleFileUpload = (file: RcFile) => {
    form.setFieldValue('file', file as Blob);
    return false;
  }

  return (
    <Form
      form={form}
      className="form-standalone add-new-template"
      onFinish={handleSubmit}
      initialValues={defaultValue}
    >
      <FormBuilder
        formFragment
        oneColumn
        fields={AddNewTemplateDrawerFields({ handleFileUpload, fileType: pdfFileTabs.includes(formType) ? '.pdf' : '.html' })}
        defaultValues={defaultValue}
      />
      <div className="submit__wrap">
        <Button type="primary" className="secondary-button" onClick={onCancel}>
          Cancel
        </Button>
        <Button type="primary" htmlType="submit" className="primary-button">
          Add Template
        </Button>
      </div>
    </Form>
  )
}

export default AddNewTemplateDrawer;
